import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@mui/material/Chip'
import deleteImageX from 'assets/images/remove_icon_x.png'
import checkmarkImage from 'assets/images/checkmark.png'

const useStyles = makeStyles((theme) => ({
    rowFlex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
    },

    conceptChip: {
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        margin: 2,
        marginBottom: '5px',
        padding: '3px',
        borderRadius: 16,
        border: '4px solid rgba(204, 133, 198, 0.50)',
        background: 'var(--White, #FFF)',
    },

    conceptChipSelected: {
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        margin: 2,
        marginBottom: '5px',
        padding: '3px',
        borderRadius: 16,
        border: '4px solid rgba(204, 133, 198, 0.50)',
        background: 'rgba(204, 133, 198, 0.50)',
    },

    label: {
        color: 'var(--Dark-text, #35323C)',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
    },
}))

const ACCVocabSelectionChip = (props) => {
    // expected props
    // props.content
    // props.deleteVocab
    // props.clicked
    // props.element
    // props.preSelected

    const classes = useStyles()

    const [isSelected, setIsSelected] = useState(false)

    const handleOnSelected = () => {
        setIsSelected(true)
        props.selectElement(props.element)
    }

    const handleOnDeSelected = () => {
        setIsSelected(false)
        props.deSelectElement(props.element)
    }

    useEffect(() => {
        setIsSelected(props.preSelected)
    }, [])

    return (
        <Fragment>
            {props.content && (
                <Chip
                    icon={isSelected ? <img src={checkmarkImage} /> : <Fragment />}
                    label={<span className={classes.label}> {props.content} </span>}
                    onDelete={props.deleteVocab}
                    deleteIcon={<img src={deleteImageX} />}
                    onClick={!isSelected ? handleOnSelected : handleOnDeSelected}
                    style={
                        !isSelected
                            ? {
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'black',
                                  margin: 2,
                                  marginBottom: '5px',
                                  padding: '3px',
                                  borderRadius: 16,
                                  border: '1px solid rgba(160, 67, 159, 0.50)',
                                  background: 'var(--White, #FFF)',
                              }
                            : {
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'black',
                                  margin: 2,
                                  marginBottom: '5px',
                                  padding: '3px',
                                  borderRadius: 16,
                                  border: '1px solid rgba(160, 67, 159, 0.50)',
                                  background: 'rgba(204, 133, 198, 0.50)',
                              }
                    }
                />
            )}
        </Fragment>
    )
}

ACCVocabSelectionChip.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCVocabSelectionChip)
