import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import spinnerImage from 'assets/images/spinner-large.svg'
import ACCScrollingTexts from './ACCScrollingTexts'

const useStyles = makeStyles((theme) => ({
    mainComponentStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'column',
    },
    spinnerDivComponentStyle: {
        position: 'relative',
        display: 'flex',
        width: 160,
        height: 160,
        marginBottom: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },
    percentageText: {
        textAlign: 'center',
        color: 'white',
        fontSize: 36,
        fontFamily: 'Rubik',
        fontWeight: '400',
    },
    mainTextStyle: {
        alignSelf: 'stretch',
        textAlign: 'center',
        color: 'white',
        fontSize: 32,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: 8,
        marginTop: 8,
    },
    infoTextStyle: {
        width: 462,
        height: 72,
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    percentageTextContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    spinning: {
        animation: '$spin 2s linear infinite',
        transformOrigin: '50% 49%',
    },
    '@keyframes spin': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    spinnerDiv: {
        width: 160,
        height: 160,
    },
}))

const ACCLanguageLoadingSpinner = (props) => {
    // props.mainText -> string
    // props.infoTexts -> List
    // props.estimatedDuration -> number (seconds)

    const classes = useStyles()

    const [currentInfoText, setCurrentInfoText] = useState('')
    const [loadingProgress, setLoadingProgress] = useState(0)
    let currentTimer = 0
    let progressId = ''
    let usedInfoTextNumbers = []

    const [extraWaitingTime, setExtraWaitingTime] = useState(60)

    const handleGetImprovedRandom = () => {
        let allPossibleNumbers = handleGetPossibleNumbersInSequence()

        let currentNumbersLeft = allPossibleNumbers.filter((item) => !usedInfoTextNumbers.includes(item))

        if (currentNumbersLeft.length <= 0) {
            currentNumbersLeft = handleGetPossibleNumbersInSequence()
        }

        if (usedInfoTextNumbers.length >= props.infoTexts.length) {
            usedInfoTextNumbers = []
        }

        let randomNumber = Math.floor(Math.random() * currentNumbersLeft.length)
        let chosenNumber = currentNumbersLeft[randomNumber]

        usedInfoTextNumbers.push(chosenNumber)

        return props.infoTexts[chosenNumber]
    }

    const handleGetPossibleNumbersInSequence = () => {
        let allPossibleNumbers = []
        for (let i = 0; i < props.infoTexts.length; i++) {
            allPossibleNumbers.push(i)
        }

        return allPossibleNumbers
    }

    const handleSetPercentage = () => {
        let newTimer = currentTimer + 1

        const actualProgress = Math.round(loadingProgress + (newTimer / props.estimatedDuration) * 100)
        let progression = 0
        if (newTimer < props.estimatedDuration) {
            progression = Math.round(loadingProgress + (newTimer / props.estimatedDuration) * 90)
        } else {
            const extraWaitTimer = newTimer - props.estimatedDuration
            const afterNintyProgression = (extraWaitTimer / extraWaitingTime) * 100 * 0.1
            progression = Math.round(90 + afterNintyProgression)
        }
        if (progression > 99) {
            progression = 99
        }
        setLoadingProgress(progression)
        currentTimer = newTimer
    }

    useEffect(() => {
        setCurrentInfoText(handleGetImprovedRandom())
        if (props.defaultExtraWaitingTimeAfterNintyPercetage) {
            setExtraWaitingTime(props.defaultExtraWaitingTimeAfterNintyPercetage)
        }
        let infoTextPartId = setInterval(() => setCurrentInfoText(handleGetImprovedRandom()), 30000)

        return () => {
            clearInterval(infoTextPartId)
        }
    }, [])

    useEffect(() => {
        progressId = setInterval(() => {
            handleSetPercentage()
        }, 1000)

        return () => clearInterval(progressId)
    }, [])

    return (
        <div className={classes.mainComponentStyle}>
            <div className={classes.spinnerDivComponentStyle}>
                <div className={classes.percentageTextContainer}>
                    <span className={classes.percentageText}>{loadingProgress}%</span>
                </div>
                <div className={classes.spinning}>
                    <img className={classes.spinnerDiv} src={spinnerImage} />
                </div>
            </div>
            <Typography className={classes.mainTextStyle}>{props.mainText}</Typography>
            <ACCScrollingTexts text={currentInfoText} textStyle={classes.infoTextStyle} />
        </div>
    )
}

ACCLanguageLoadingSpinner.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCLanguageLoadingSpinner)
