import { configWithAuth } from '../helpers'
import { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } from '../helpers/api'

export const courseGroupService = {
    getCourseGroup: (courseGroupId) => makeGetRequest('admin/course/group/' + courseGroupId, configWithAuth()),
    createCourseGroup: (payload) => makePostRequest('admin/course/group/', payload, configWithAuth()),
    updateCourseGroup: (payload) => makePutRequest('admin/course/group/', payload, configWithAuth()),
    deleteCourseGroup: (courseGroupId) =>
        makeDeleteRequest('admin/course/group/' + courseGroupId, null, configWithAuth()),
    deleteCourseGroupSubPartToTopicMapper: (payload) =>
        makeDeleteRequest(
            'admin/course/group/' +
                payload.courseGroupId +
                '/sub/part/' +
                payload.courseGroupSubPartId +
                '/topic/mapper/' +
                payload.groupSubPartToTopicMapperId,
            null,
            configWithAuth()
        ),
}
