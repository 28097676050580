import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { ScenarioImageSelection } from '../../Logic/ScenarioConstants'
import Dropzone from 'react-dropzone'
import { TextField } from '@mui/material'
import ImageSearch from '../../Views/Private/Scenarios/Images/ImageSearch'
import { ImageCropType } from '../../Logic/CourseGroupConstants'
import { v4 as uuidv4 } from 'uuid'
import imageCompression from 'browser-image-compression'
import ReactCrop from 'react-image-crop'
import { makeGetRequest, makePostRequest } from '../../_state/helpers/api'
import { configWithAuth } from '../../_state/helpers'

const useStyles = makeStyles((theme) => ({
    imageLibraryButton: {
        textTransform: 'none',
        border: '1px solid #BDBDBD',
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '150px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },

    imageViewer: {
        maxWidth: '150px',
        minWidth: '50px',
        height: 'auto',
    },
    dropZone: {
        position: 'relative',
        marginTop: '10px',
        marginRight: '10px',
        height: '115px',
        width: '500px',
        paddingRight: '10px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        transition: 'all 200ms ease-out',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
}))

const ImageUploadWithCropComponent = (props) => {
    // expected props:
    // props.aspectRatioType

    const classes = useStyles()

    const [imageSelectionType, setImageSelectionType] = useState(
        props.defaultSelectedOption ?? ScenarioImageSelection.Upload
    )

    // image
    const [src, setSrc] = useState(null)
    const [fileType, setFileType] = useState(null)
    const [alertFileSize, setAlertFileSize] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState('')
    const [fileExtension, setFileExtension] = useState(null)
    const [imageFile, setImageFile] = useState(null)
    const [imageFileUrl, setImageFileUrl] = useState(null)
    const [imageAddress, setImageAddress] = useState('')

    const [imageFilename, setImageFilename] = useState(null)

    const imgRef = useRef(null)

    // crop related attributes
    const [isCroppedComplete, setIsCroppedComplete] = useState(false)
    const [isCorrectAspectRatio, setIsCorrectAspectRatio] = useState(true)

    const [cropSquare, setCropSquare] = useState({
        unit: '%',
        width: 30,
        aspect: 1 / 1,
    })

    switch (props.aspectRatioType) {
        case ImageCropType.Square:
            cropSquare.aspect = 1.0
            break
        case ImageCropType.Course:
            cropSquare.aspect = 1.8
            break
        case ImageCropType.CourseGroup:
            cropSquare.aspect = 1.6
            break
        default:
            cropSquare.aspect = 1.0
            break
    }

    useEffect(() => {
        var img = new Image()
        // let desiredAspectRatio = cropSquare;
        img.onload = function () {
            if ((this.width / this.height).toFixed(1) !== cropSquare.aspect.toFixed(1)) {
                setFileType('image/jpeg')
                setSrc(imageAddress)
                setIsCorrectAspectRatio(false)
                setIsCroppedComplete(false)
            } else {
                setIsCorrectAspectRatio(true)
            }
        }
        img.src = imageAddress
    }, [imageAddress])

    const onDrop = (fileUploadResult) => {
        let organizationId = props.organization.selectedOrganization
        const formData = new FormData()
        formData.append(fileUploadResult.imageFileName, fileUploadResult.image)

        // Upload Image
        makePostRequest(
            'admin/organization/' + organizationId + '/image/?sharable=true',
            formData,
            configWithAuth('multipart/form-data')
        )
            .then((result) => {
                const imageFileReferenceId = result.data['id']

                // Get uploaded Image.
                makeGetRequest(
                    'admin/organization/' +
                        organizationId +
                        '/image/file/reference/' +
                        imageFileReferenceId +
                        '/base64',
                    configWithAuth()
                )
                    .then((result) => {
                        props.saveImage(imageFileReferenceId)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleFinishCrop = (event, value) => {
        setIsCroppedComplete(true)
        setIsCorrectAspectRatio(true)

        if (imageFileUrl) {
            let imageResult = {
                type: fileType,
                image: imageFile,
                imageUrl: imageFileUrl,
                imageFileName: imageFilename,
                shouldUpload: true,
            }

            onDrop(imageResult)
            props.setSelectedImageBlob(imageResult)
        }
    }

    const handleCancelCrop = (event, value) => {
        setIsCroppedComplete(false)
        setSrc(null)
        setImageFile(null)
        setImageFileUrl(null)
    }

    const getCroppedImg = async (image, crop, fileName, state) => {
        const canvasPrimary = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        var originWidth = crop.width * scaleX
        var originHeight = crop.height * scaleY
        var targetWidth = originWidth,
            targetHeight = originHeight

        // set canvas size
        canvasPrimary.width = targetWidth
        canvasPrimary.height = targetHeight
        const ctx = canvasPrimary.getContext('2d')
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            targetWidth,
            targetHeight
        )
        return new Promise((resolve, reject) => {
            canvasPrimary.toBlob((blob) => {
                if (!blob) {
                    console.error('Capeesh canvas is empty')
                    return
                }
                blob.name = fileName
                blob.lastModifiedDate = new Date()
                window.URL.revokeObjectURL(imageFileUrl)
                fileUrl = window.URL.createObjectURL(blob)
                let imageGuid = uuidv4()
                var thefile = new File([blob], imageGuid, {
                    type: fileType,
                    lastModified: new Date(),
                })
                var fileUrl = window.URL.createObjectURL(thefile)
                setImageFile(thefile)
                setImageFileUrl(fileUrl)
                resolve(fileUrl)
            })
        })
    }

    const onCropChange = (crop, percentCrop) => {
        setCropSquare(crop)
    }

    const handleImageFromExternalSource = (image, imageDescription = null) => {
        let imageFile = image[0]
        var blobUrlLocal = URL.createObjectURL(image)
        let imageGuid = uuidv4()
        setSrc(blobUrlLocal)
        setImageFile(imageFile)
        setImageFileUrl(blobUrlLocal)
        setImageAddress(blobUrlLocal)
        switch (image.type) {
            case 'image/jpeg':
                setFileExtension('jpeg')
                break
            case 'image/png':
                setFileExtension('png')
                break
        }
        var finalisedFile = new File([image], imageGuid, {
            type: image.type,
            lastModified: new Date(),
        })
        setUploadedFileName(finalisedFile.name.replace(/\.[^/.]+$/, ''))
        setIsCroppedComplete(false)
        setFileType(finalisedFile.type)
        if (imageDescription != null && !imageFilename) {
            setImageFilename(imageDescription)
        }
    }

    const onImageLoaded = (image) => {
        imgRef.current = image
    }

    const onCropComplete = (crop) => {
        makeClientCrop(crop, 'newFile.png')
    }

    const makeClientCrop = async (crop, fileName) => {
        if (imgRef.current && crop.width && crop.height) {
            const newCroppedImageUrl = await getCroppedImg(imgRef.current, crop, fileName, this)
        }
    }

    const onImageDrop = async (files) => {
        const imageFile = files[0]
        const options = {
            maxSizeMB: 0.5,
            //maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            if (imageFile.size / 1024 / 1024 > 1.5) {
                // is compressing a fairly large file, might take a while so notify user
                setAlertFileSize(true)
            }
            const compressedFile = await imageCompression(imageFile, options)
            setAlertFileSize(false)
            // await uploadToServer(compressedFile); // write your own logic
            setUploadedFileName(files[0].name.replace(/\.[^/.]+$/, ''))
            setIsCroppedComplete(false)
            if (files.length > 0) {
                const reader = new FileReader()
                reader.addEventListener(
                    'load',
                    () => setSrc(reader.result) //this.setState({ src: reader.result })
                )
                reader.readAsDataURL(compressedFile)
                setFileType(files[0].type)
                switch (files[0].type) {
                    case 'image/jpeg':
                        setFileExtension('jpeg')
                        break
                    case 'image/png':
                        setFileExtension('png')
                        break
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px', width: '520px' }}>
                {isCorrectAspectRatio === false && (
                    <Typography
                        variant="h6"
                        style={{
                            //fontSize: 36,
                            //color: '#8E8E93',
                            fontFamily: 'Lato',
                            marginBottom: '10px',
                        }}>
                        The image aspect ratio needs updating to fit the stage type.
                    </Typography>
                )}
                <Typography
                    variant="body1"
                    style={{
                        color: '#8E8E93',
                        fontFamily: 'Lato',
                        marginBottom: '10px',
                    }}>
                    {src && isCroppedComplete === false
                        ? 'Crop the image to the right shape, give it a name and click "finish crop".'
                        : 'Choose how to select the image'}
                </Typography>
                {alertFileSize && (
                    <Typography
                        variant="body1"
                        style={{
                            color: '#8E8E93',
                            fontFamily: 'Lato',
                            marginBottom: '10px',
                        }}>
                        Your image has a large file size. Please wait whilst we compress it...
                    </Typography>
                )}

                {(src === null || (src && isCroppedComplete === true)) && (
                    <Grid
                        container
                        style={{ marginTop: '10px' }}
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}>
                        <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            style={{
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Button
                                onClick={() => setImageSelectionType(ScenarioImageSelection.Search)}
                                style={
                                    imageSelectionType === ScenarioImageSelection.Search
                                        ? {
                                              backgroundColor: '#143349',
                                              fontColor: 'white',
                                              color: 'white',
                                          }
                                        : {
                                              backgroundColor: 'white',
                                              fontColor: 'black',
                                              color: 'black',
                                          }
                                }
                                className={classes.imageLibraryButton}
                                variant="outlined">
                                Search
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            style={{
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Button
                                onClick={() => setImageSelectionType(ScenarioImageSelection.Upload)}
                                style={
                                    imageSelectionType === ScenarioImageSelection.Upload
                                        ? {
                                              backgroundColor: '#143349',
                                              fontColor: 'white',
                                              color: 'white',
                                          }
                                        : {
                                              backgroundColor: 'white',
                                              fontColor: 'black',
                                              color: 'black',
                                          }
                                }
                                className={classes.imageLibraryButton}
                                variant={
                                    imageSelectionType === ScenarioImageSelection.Upload ? 'contained' : 'outlined'
                                }>
                                Upload image
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {imageSelectionType === ScenarioImageSelection.Upload && (
                    <Grid item xs={12} sm={12} md={12} className={classes.dropzoneContainer}>
                        <div
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {(src === null || (src && isCroppedComplete === true)) && (
                                <Dropzone accept="image/jpeg,image/png" onDrop={(file) => onImageDrop(file)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()} className={classes.dropZone}>
                                                <input {...getInputProps()} />
                                                {
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    color: '#35323C',
                                                                    marginTop: '10px',
                                                                }}>
                                                                Drag your voice image files here
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    color: '#35323C',
                                                                }}>
                                                                Or
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                style={{
                                                                    textTransform: 'none',
                                                                    fontFamily: 'Lato',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 'bold',
                                                                    background: '#FFFFFF',
                                                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                                    borderRadius: '10px',
                                                                    marginBottom: '15px',
                                                                }}>
                                                                Browse files
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            )}
                        </div>
                    </Grid>
                )}
                <React.Fragment>
                    <Grid item xs={6} sm={6} md={6} className={classes.dropzoneContainer}>
                        {src && !!!isCroppedComplete && (
                            <React.Fragment>
                                <ReactCrop
                                    className={classes.imageViewer}
                                    src={src}
                                    //crop={cropListenSpeak}
                                    crop={cropSquare}
                                    ruleOfThirds
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                                <Typography
                                    style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold',
                                    }}
                                    className={classes.answerHeaders}
                                    variant="body1">
                                    Image description
                                </Typography>
                                <TextField
                                    placeholder={'Choose a desciption'}
                                    value={imageFilename}
                                    onChange={(event) => setImageFilename(event.target.value)}
                                    style={{
                                        borderRadius: '10px',
                                        minWidth: '500px',
                                    }}
                                    className={classes.textField}
                                    InputProps={{
                                        disableUnderline: true,
                                        shrink: 'false',
                                    }}
                                    variant="filled"
                                />
                            </React.Fragment>
                        )}
                    </Grid>
                </React.Fragment>

                <Grid item xs={12} sm={12} md={12} className={classes.dropzoneContainer}>
                    {src && !!!isCroppedComplete && (
                        <React.Fragment>
                            <Button
                                onClick={() => handleFinishCrop()}
                                style={{
                                    backgroundColor:
                                        imageFilename === null || imageFilename === '' ? 'grey' : '#143349',
                                    fontColor: 'white',
                                    color: 'white',
                                    marginRight: '5px',
                                }}
                                variant="outlined"
                                disabled={imageFilename === null || imageFilename === ''}>
                                Finish Crop
                            </Button>
                            <Button
                                onClick={() => handleCancelCrop()}
                                style={{
                                    backgroundColor: '#143349',
                                    fontColor: 'white',
                                    color: 'white',
                                }}
                                variant="outlined">
                                Cancel
                            </Button>
                        </React.Fragment>
                    )}
                </Grid>

                {src == null && imageSelectionType === ScenarioImageSelection.Search && (
                    <div
                        style={{
                            marginTop: '10px',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <ImageSearch onSelectImage={handleImageFromExternalSource} />
                    </div>
                )}
            </Grid>
        </div>
    )
}

ImageUploadWithCropComponent.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        topic: state.topic,
        user: state.user,
        metadata: state.metadata,
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadWithCropComponent)
