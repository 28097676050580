import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import ClassroomCard from '../../Classroom/ClassroomCard'
import { organizationActions, usergroupActions } from '../../../../_state/actions'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { history } from '../../../../_state/helpers'

const useStyles = makeStyles((theme) => ({
    ccard: {
        marginBottom: '5px',
    },
    marginEmptyText: {
        margin: '20px',
    },

    courseGroupUsageCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '24px',
        alignSelf: 'stretch',
        borderRadius: '16px',
        backgroundColor: CapeeshColors.Gray5,
    },

    cardHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    cardTitle: {
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontFamily: 'Rubik',
        color: CapeeshColors.DarkText,
        // textAlign: 'left',

        textTransform: 'none',
        justifyContent: 'flex-start',
    },
    cardAction: {
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontFamily: 'Rubik',
        color: CapeeshColors.Primary600,
        // textAlign: 'right',

        textTransform: 'none',
        justifyContent: 'flex-end',
    },

    courseGroupUsageHeader: {
        display: 'flex',
        height: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    },

    tableHeaderCell: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: 500,
        textAlign: 'left',
        lineHeight: '140%',
    },
    tableHeaderCellCompletedLessons: {
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'left',
        minWidth: '120px',
    },

    tableRow: {
        display: 'flex',
        paddingBottom: '8px',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
    },

    tableRowCellName: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        minWidth: '130px',
        maxWidth: '180px',
    },
}))

const OrganizationCourseUsageList = (props) => {
    // expected props:
    // props.dispatch
    const classes = useStyles()

    const [courseGroupUsageStats, setCourseGroupUsageStats] = useState([])

    useEffect(() => {
        props.dispatch(organizationActions.getOrganizationCourseGroupUsage(props.organization.selectedOrganization))
    }, [])

    useEffect(() => {
        if (
            props.organization?.organizationCourseGroupUsage?.organizationId === props.organization.selectedOrganization
        ) {
            const tempCourseGroupUsageStats = props.organization?.organizationCourseGroupUsage.courseGroups
            tempCourseGroupUsageStats.sort((a, b) => b.numberOfLessonsPlayed - a.numberOfLessonsPlayed)
            if (tempCourseGroupUsageStats.length > 5) {
                setCourseGroupUsageStats(tempCourseGroupUsageStats.slice(0, 5))
            } else {
                setCourseGroupUsageStats(tempCourseGroupUsageStats)
            }
        } else {
            setCourseGroupUsageStats([])
        }
    }, [props.organization])

    const handleGoToAllCourses = () => {
        history.push('/dashboard/courses/')
    }

    return (
        <div className={classes.courseGroupUsageCard}>
            <div className={classes.cardHeader}>
                <Typography className={classes.cardTitle}>
                    <FormattedMessage id="dashboard.course.group.usage.title" defaultMessage="Most played courses" />
                </Typography>

                <Button onClick={() => handleGoToAllCourses()} variant={'text'} className={classes.cardAction}>
                    <FormattedMessage
                        id="dashboard.course.group.usage.viewallcourses"
                        defaultMessage="View all courses"
                    />
                    <ArrowForwardIosIcon
                        sx={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontFamily: 'Rubik',
                            fontWeight: '500',
                            marginTop: '2px',
                            marginLeft: '8px',
                            color: CapeeshColors.Primary600,
                        }}
                    />
                </Button>
            </div>

            <TableContainer>
                <Table
                    sx={{ minWidth: '350px', backgroundColor: CapeeshColors.Gray5 }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%">
                                <Typography className={classes.tableHeaderCell}>#</Typography>
                            </TableCell>
                            <TableCell align="right" width="60%">
                                <Typography className={classes.tableHeaderCell} style={{ textAlign: 'right' }}>
                                    <FormattedMessage
                                        id="dashboard.course.group.usage.coursetitle"
                                        defaultMessage="Course title"
                                    />
                                </Typography>
                            </TableCell>
                            <TableCell align="right" width="30%">
                                <Typography
                                    className={classes.tableHeaderCellCompletedLessons}
                                    style={{ textAlign: 'right' }}>
                                    <FormattedMessage
                                        id="dashboard.course.group.usage.completedlessons"
                                        defaultMessage="Completed lessons"
                                    />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseGroupUsageStats.map((courseGroupUsage, index) => (
                            <TableRow
                                key={courseGroupUsage.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell width="10%" component="th" scope="row">
                                    #{index + 1}
                                </TableCell>
                                <TableCell width="60%" align="right">
                                    <Typography style={{ textAlign: 'right' }} className={classes.tableRowCellName}>
                                        {courseGroupUsage.name}
                                    </Typography>
                                </TableCell>
                                <TableCell width="30%" align="right">
                                    {courseGroupUsage.numberOfLessonsPlayed}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCourseUsageList)
