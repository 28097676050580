import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {},
}))

const PublicLayout = (props) => {
    const classes = useStyles()
    const { component: Component } = props

    return (
        <div className={classes.root}>
            <Component />
        </div>
    )
}

PublicLayout.propTypes = {}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout)
