import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import ClassroomSidebar from './Components/ClassroomSidebar'
import { usergroupActions } from '_state/actions'

import { organizationActions } from '../../../_state/actions'
import DayOfTheWeekPieChart from '../../../Components/Graphs/DayOfTheWeekPieChart'
import HourOfDayGraph from '../../../Components/Graphs/HourOfDayGraph'
import DailyUsageGraph, { DailyUsageYLabelType } from '../../../Components/Graphs/DailyUsageGraph'
import DailySessionGraph from '../../../Components/Graphs/DailySessionsGraph'
import { FormattedMessage } from 'react-intl'

const styles = (theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    button: {
        margin: theme.spacing(1),
    },
    graph: {
        marginTop: 20,
    },
    leaderBoardIndex: {
        background: theme.palette.primary.main,
    },
})

class ClassroomStatistics extends React.Component {
    state = {
        leaderBoardTab: 0,
    }

    componentDidMount = () => {
        const { computedMatch, dispatch, organization } = this.props
        dispatch(
            usergroupActions.getUserGroupStats(organization.selectedOrganization, computedMatch.params.classroomId)
        )
        dispatch(
            organizationActions.getGraphDataDayOfWeekUserGroup(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        dispatch(
            organizationActions.getGraphDataHourlyUserGroupId(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        dispatch(
            organizationActions.getGraphDataDailyUsageAggregatedUserGroup(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        dispatch(
            organizationActions.getGraphDataDailyLessonAggregatedUserGroup(
                organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
    }

    handleChange = (event, value, stateProp) => {
        this.setState({ [stateProp]: value })
    }

    render() {
        const { classes, organization, computedMatch } = this.props

        let dayOfWeekUsageStats = []
        let showDayOfWeekUsageStats = false
        if (
            organization.graphDataDayOfWeekUserGroup &&
            organization.graphDataDayOfWeekUserGroup.userGroupId === computedMatch.params.classroomId &&
            organization.graphDataDayOfWeekUserGroup.canShowGraphData
        ) {
            dayOfWeekUsageStats = organization.graphDataDayOfWeekUserGroup.graphStatsDayOfWeekDtos.map((pie) => ({
                name: pie.dayOfWeekShort,
                value: pie.secondsCombined,
            }))
            showDayOfWeekUsageStats = true
        }

        let dailyUsageAggregated = []
        let showDailyUsageAggregated = false

        let dailyUsage = []
        let showDailyUsage = false

        let aggregatedSessions = []
        let sessions = []

        let showAggregatedSessions = false
        let showSessions = false

        if (
            organization.graphDataDailyUsageAggregatedUserGroup &&
            organization.graphDataDailyUsageAggregatedUserGroup.userGroupId == computedMatch.params.classroomId &&
            organization.graphDataDailyUsageAggregatedUserGroup.canShowGraphData
        ) {
            dailyUsageAggregated = organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map(
                (x) => ({ name: x.day, value: x.aggregatedMinutes })
            )
            showDailyUsageAggregated = true

            dailyUsage = organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map((x) => ({
                name: x.day,
                value: x.minutes,
            }))
            showDailyUsage = true

            aggregatedSessions = organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map(
                (x) => ({ name: x.day, value: x.aggregatedNumberOfSessions })
            )
            showAggregatedSessions = true

            sessions = organization.graphDataDailyUsageAggregatedUserGroup.graphStatsAggregateDays.map((x) => ({
                name: x.day,
                value: x.numberOfSessions,
            }))
            showSessions = true
        }

        let showLessonRelatedGraphs = false

        let graphDataNumberOfLessonsPlayed = []
        let graphAggregatedNumberOfLessonsPlayed = []
        let graphDataNumberOfUniqueLessonsPlayed = []
        let graphDataAggregatedNumberOfUniqueLessonsPlayed = []
        let graphDataNumberOfCompletedLessons = []
        let graphDataAggregatedNumberOfCompletedLessons = []
        let graphDataNumberOfFailedLessons = []
        let graphDataAggregatedNumberOfFailedLessons = []
        let graphDataNumberOfCorrectAnswersInLessons = []
        let graphDataAggregatedNumberOfCorrectAnswersInLessons = []
        let graphDataNumberOfIncorrectAnswersInLessons = []
        let graphDataAggregatedNumberOfIncorrectAnswersInLessons = []
        let graphDataNumberOfStars = []
        let graphDataAggregatedNumberOfStars = []

        if (
            organization.graphDataDailyLessonAggregatedUserGroup &&
            organization.graphDataDailyLessonAggregatedUserGroup.userGroupId == computedMatch.params.classroomId &&
            organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos?.length > 0 &&
            organization.graphDataDailyLessonAggregatedUserGroup.canShowGraphData
        ) {
            showLessonRelatedGraphs = true

            graphDataNumberOfLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfLessonsPlayed,
                }))

            graphAggregatedNumberOfLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfLessonsPlayed,
                }))

            graphDataNumberOfUniqueLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfUniqueLessonsPlayed,
                }))

            graphDataAggregatedNumberOfUniqueLessonsPlayed =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfUniqueLessonsPlayed,
                }))

            graphDataNumberOfCompletedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfCompletedLessons,
                }))

            graphDataAggregatedNumberOfCompletedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfCompletedLessons,
                }))

            graphDataNumberOfFailedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfFailedLessons,
                }))

            graphDataAggregatedNumberOfFailedLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfFailedLessons,
                }))

            graphDataNumberOfCorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfCorrectAnswersInLessons,
                }))

            graphDataAggregatedNumberOfCorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfCorrectAnswersInLessons,
                }))
            graphDataNumberOfIncorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfIncorrectAnswersInLessons,
                }))

            graphDataAggregatedNumberOfIncorrectAnswersInLessons =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfIncorrectAnswersInLessons,
                }))
            graphDataNumberOfStars =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.numberOfStars,
                }))

            graphDataAggregatedNumberOfStars =
                organization.graphDataDailyLessonAggregatedUserGroup.aggregatedLessonDailyItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedNumberOfStars,
                }))
        }

        let showTimeOfDayStats = false
        let timeOfDayStats = []
        let timeOfDayStatsIndex = {}
        if (
            organization.graphDataHourlyUserGroup &&
            organization.graphDataHourlyUserGroup.userGroupId === computedMatch.params.classroomId &&
            organization.graphDataHourlyUserGroup.canShowGraphData
        ) {
            timeOfDayStats = organization.graphDataHourlyUserGroup.graphStatsHourlyDtos
            timeOfDayStats.forEach((el, index) => (timeOfDayStatsIndex[el.hourOfTheDay] = el.minutesCombined))
            showTimeOfDayStats = true
        }

        return (
            <div className={classes.root}>
                <ClassroomSidebar userGroupId={computedMatch.params.classroomId} path={computedMatch.path} />
                <Grid container spacing={5} style={{ marginTop: '25px' }}>
                    <Grid item xs={12} sm={6}>
                        <DailyUsageGraph
                            defaultHeaderMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.addmoreusers"
                                    defaultMessage="Add more users to see graph"
                                />
                            }
                            headerMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailyusage.desc"
                                    defaultMessage="Shows how much time the users have spent in the app"
                                />
                            }
                            toolTipYLabelType={DailyUsageYLabelType.AggreatedUsageOverTime}
                            graphData={dailyUsageAggregated}
                            canShowChart={showDailyUsageAggregated}
                            showReferenceLine={false}
                            title={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailyusage.title"
                                    defaultMessage="Time spent in app aggregated"
                                />
                            }
                            yLabel={'minutes'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DayOfTheWeekPieChart
                            dayOfWeekChartData={dayOfWeekUsageStats}
                            canShowChart={showDayOfWeekUsageStats}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <HourOfDayGraph
                            graphData={timeOfDayStats}
                            graphDataIndex={timeOfDayStatsIndex}
                            canShowChart={showTimeOfDayStats}
                            showYAxisLabel={false}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DailyUsageGraph
                            defaultHeaderMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.addmoreusers"
                                    defaultMessage="Add more users to see graph"
                                />
                            }
                            headerMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailyusage.headermessage"
                                    defaultMessage="Shows how much time is spent per day"
                                />
                            }
                            toolTipYLabelType={DailyUsageYLabelType.DailyUsage}
                            graphData={dailyUsage}
                            canShowChart={showDailyUsage}
                            showReferenceLine={false}
                            title={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailyusage.title"
                                    defaultMessage="Daily usage"
                                />
                            }
                            yLabel={'minutes'}
                            isMinutes={false}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DailySessionGraph
                            defaultHeaderMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.addmoreusers"
                                    defaultMessage="Add more users to see graph"
                                />
                            }
                            headerMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailysesssions.header"
                                    defaultMessage="Shows how many sessions users have in the app over time"
                                />
                            }
                            graphData={aggregatedSessions}
                            canShowChart={showAggregatedSessions}
                            showReferenceLine={false}
                            title={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailysesssions.title"
                                    defaultMessage="Sessions"
                                />
                            }
                            yLabel={'sessions'}
                            isMinutes={false}
                        />
                    </Grid>

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailySessionGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Daily sessions"}*/}
                    {/*    graphData={sessions}*/}
                    {/*    canShowChart={showSessions}*/}
                    {/*    showReferenceLine={true}*/}
                    {/*    title={"Session per day"}*/}
                    {/*    yLabel={"number of sessions"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Number of stars"}*/}
                    {/*    graphData={graphDataAggregatedNumberOfStars}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={true}*/}
                    {/*    title={"Number of stars per day aggregated"}*/}
                    {/*    yLabel={"stars"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Number of stars"}*/}
                    {/*    graphData={graphDataNumberOfStars}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={true}*/}
                    {/*    title={"Number of stars per day"}*/}
                    {/*    yLabel={"stars"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Number of lessons"}*/}
                    {/*    graphData={graphDataNumberOfLessonsPlayed}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Lessons played per day"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} sm={6}>
                        <DailyUsageGraph
                            defaultHeaderMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.addmoreusers"
                                    defaultMessage="Add more users to see graph"
                                />
                            }
                            headerMessage={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailylessons.header"
                                    defaultMessage="Shows lessons have been played over time"
                                />
                            }
                            toolTipYLabelType={DailyUsageYLabelType.NumberOfLessons}
                            graphData={graphAggregatedNumberOfLessonsPlayed}
                            canShowChart={showLessonRelatedGraphs}
                            showReferenceLine={false}
                            title={
                                <FormattedMessage
                                    id="dashboard.classroom.graphs.dailylessons.title"
                                    defaultMessage="Lessons played"
                                />
                            }
                            yLabel={'lessons'}
                        />
                    </Grid>

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Unique lessons"}*/}
                    {/*    graphData={graphDataNumberOfUniqueLessonsPlayed}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Unique lessons per day"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Unique lessons"}*/}
                    {/*    graphData={graphDataAggregatedNumberOfUniqueLessonsPlayed}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Unique lessons per day aggregated"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Completed lessons"}*/}
                    {/*    graphData={graphDataNumberOfCompletedLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of completed lesson"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Completed lessons"}*/}
                    {/*    graphData={graphDataAggregatedNumberOfCompletedLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of completed lesson aggregated"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Failed lessons"}*/}
                    {/*    graphData={graphDataNumberOfFailedLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of failed lesson"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Failed lessons"}*/}
                    {/*    graphData={graphDataAggregatedNumberOfFailedLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of failed lesson aggregated"}*/}
                    {/*    yLabel={"lessons"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Answers"}*/}
                    {/*    graphData={graphDataNumberOfCorrectAnswersInLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of correct answers"}*/}
                    {/*    yLabel={"answers"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Answers"}*/}
                    {/*    graphData={graphDataAggregatedNumberOfCorrectAnswersInLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of correct answers aggregated"}*/}
                    {/*    yLabel={"answers"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Answers"}*/}
                    {/*    graphData={graphDataNumberOfIncorrectAnswersInLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of incorrect answers"}*/}
                    {/*    yLabel={"answers"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <DailyUsageGraph*/}
                    {/*    defaultHeaderMessage={"Add more users to see graph"}*/}
                    {/*    toolTipPrefix={"Answers"}*/}
                    {/*    graphData={graphDataAggregatedNumberOfIncorrectAnswersInLessons}*/}
                    {/*    canShowChart={showLessonRelatedGraphs}*/}
                    {/*    showReferenceLine={false}*/}
                    {/*    title={"Number of incorrect answers aggregated"}*/}
                    {/*    yLabel={"answers"}*/}
                    {/*  />*/}
                    {/*</Grid>*/}
                </Grid>
            </div>
        )
    }
}

ClassroomStatistics.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { organization, usergroup, user } = state
    return {
        user,
        organization,
        usergroup,
    }
}

const enhance = compose(connect(mapStateToProps), withStyles(styles), withRouter)

export default enhance(ClassroomStatistics)
