import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
    button: {
        backgroundColor: 'primary',
        fontColor: 'white',
        color: 'white',
        borderRadius: 4,
    },
})

class CapeeshResponsiveListDialog extends React.Component {
    state = {
        open: false,
        checked: [],
        originChecked: [],
    }

    componentDidMount() {
        this.initializeChecked()
    }

    initializeChecked() {
        let preChecked = []
        this.props.originList && this.props.originList.map((item) => preChecked.push(item.id))
        this.setState({ checked: preChecked })
    }

    handleButton1 = () => {
        const { handleButton1 } = this.props
        const { checked } = this.state

        if (handleButton1) handleButton1(checked)

        this.setState({ open: false })
    }

    handleButton2 = () => {
        const { handleButton2 } = this.props
        const { checked } = this.state
        if (handleButton2) handleButton2(checked)

        this.setState({ open: false })
    }

    handleCancel() {
        this.initializeChecked()
        const { handleCancel } = this.props

        if (handleCancel) handleCancel()

        this.setState({ open: false })
    }

    handleEnter() {
        this.initializeChecked()
    }

    handleToggle = (value) => () => {
        const { checked } = this.state
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        this.setState({
            checked: newChecked,
        })
    }

    render() {
        const {
            fullScreen,
            dialogContentText,
            dialogTitle,
            open,
            button1Text,
            button2Text,
            classes,
            list,
            emptyMessage = 'None available',
            button1Variant = 'contained',
            button2Variant = 'contained',
        } = this.props
        const { checked } = this.state

        let displayList = list
        if (!displayList) {
            displayList = []
        }

        const checkedItems = displayList.filter((listItem) => {
            return checked.find((x) => x === listItem.id)
        })
        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={() => this.handleCancel()}
                    aria-labelledby="responsive-dialog-title"
                    onEnter={() => this.handleEnter()}>
                    <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {displayList.length === 0 ? emptyMessage : dialogContentText}
                        </DialogContentText>
                        <div id="contained" style={{ visibility: 'visible' }}>
                            <List>
                                {displayList.map((listItem) => (
                                    <React.Fragment>
                                        {listItem.invalid && (
                                            <ListItem
                                                disabled
                                                key={listItem.id}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={this.handleToggle(listItem.id)}>
                                                <Checkbox
                                                    checked={this.state.checked.indexOf(listItem.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                                <ListItemText primary={listItem.name + ' ' + listItem.invalidReason} />
                                            </ListItem>
                                        )}
                                        {!!!listItem.invalid && (
                                            <ListItem
                                                key={listItem.id}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={this.handleToggle(listItem.id)}>
                                                <Checkbox
                                                    checked={this.state.checked.indexOf(listItem.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                                <ListItemText primary={listItem.name} />
                                            </ListItem>
                                        )}
                                    </React.Fragment>
                                ))}
                            </List>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ visibility: 'visible', marginBottom: '10px', marginTop: '10px' }}>
                        <Button
                            variant={button1Variant}
                            onClick={this.handleButton1}
                            color="primary"
                            style={{
                                marginLeft: '10px',
                            }}>
                            {button1Text}
                        </Button>
                        <div style={{ flex: '1 0 0' }} />
                        <Button
                            variant={button2Variant}
                            onClick={this.handleButton2}
                            color="primary"
                            autoFocus
                            style={{
                                marginRight: '10px',
                            }}>
                            {button2Text}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

CapeeshResponsiveListDialog.propTypes = {}

function mapStateToProps(state) {
    return {}
}

const enhance = compose(connect(mapStateToProps), withStyles(styles))
export default enhance(CapeeshResponsiveListDialog)
