import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'
import { EditOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { ReactComponent as TopicLessonOrderEmpty } from './TopicLessonOrderEmpty.svg'
import { AddOutlined } from '@material-ui/icons'
import { CapeeshSizes as CapeesSizes } from '../../../../../assets/SizePresets'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '32px',
        paddingBottom: '32px',
        gap: '16px',
        borderRadius: '16px',
        background: 'white',
        marginLeft: CapeesSizes.CreatorSideBar,
        marginRight: CapeesSizes.CreatorSideBar,
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: '100%',
        },
    },
    mainTitle: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '500',
        textAlign: 'center',
    },
    subTitle: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        textAlign: 'center',
    },
    description: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
    },
    button: {
        color: 'white',
        background: CapeeshColors.Primary600,
        height: '40px',
        marginTop: '16px',
    },
    emptyGraphic: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
    },
}))

const TopicLessonOrderNewTopic = ({ onCreateNewLesson }) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div className={classes.emptyGraphic}>
                <TopicLessonOrderEmpty style={{ marginBottom: 16, alignSelf: 'center' }} />
            </div>

            <div></div>
            <Typography className={classes.mainTitle} align="center">
                <FormattedMessage
                    id="dashboard.creator.topic.lesson.order.empty.title"
                    defaultMessage={'Create your lessons'}
                />
            </Typography>
            <Typography className={classes.subTitle} align="center">
                <FormattedMessage
                    id="dashboard.creator.topic.lesson.order.empty.subtitle"
                    defaultMessage={'No lesson created yet in this topic.'}
                />
            </Typography>
            {/*<Typography className={classes.description} align="center">*/}
            {/*    This is a new page!*/}
            {/*</Typography>*/}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button
                    className={classes.button}
                    style={{
                        textTransform: 'none',
                        maxWidth: '200px',
                    }}
                    variant={'contained'}
                    onClick={() => onCreateNewLesson()}>
                    <AddOutlined
                        sx={{
                            marginRight: '15px',
                            fontSize: '15px',
                            color: 'white',
                        }}
                    />
                    <FormattedMessage
                        id="dashboard.creator.topic.lesson.order.button.new.lesson.title"
                        defaultMessage={'Create new lesson'}
                    />
                </Button>
            </div>
        </div>
    )
}

TopicLessonOrderNewTopic.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicLessonOrderNewTopic)
