import React, { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import RulecardRule from './RulecardRule'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AudioPlayer from '../ScenarioComponents/AudioPlayer'
import TextFieldWithLanguageSelector from '../ScenarioComponents/TextFieldWithLanguageSelector'

const useStyles = makeStyles((theme) => ({
    textFields: {
        width: '380px',
        textColor: 'white',
    },
    nodeTitle: {
        boxShadow: 'none',
        color: 'black',
        fontFamily: 'Lato',
        fontSize: 22,
        fontWeight: 'bold',
    },
    scenario: {
        paddingTop: '30px',
    },
    scenarioDescriptionText: {
        color: '#8e8e93',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    ruleTitle: {
        paddingTop: '30px',
        marginBottom: '10px',
    },
    stageHeader: {
        boxShadow: 'none',
        color: 'black',
        fontFamily: 'Lato',
        fontSize: 32,
        fontWeight: 'bold',
    },
    sectionHeader: {
        boxShadow: 'none',
        color: 'black',
        fontFamily: 'Lato',
        fontSize: 22,
        fontWeight: 'bold',
    },
}))

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging && 'lightgreen',
    ...draggableStyle,
})

const RulecardModalContent = (props) => {
    const classes = useStyles()
    const [shouldMinimise, setShouldMinimise] = useState(false)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const itemsNew = reorder(props.node.testOptions, result.source.index, result.destination.index)
        props.onReorderTestOptions(itemsNew)
    }

    const handleOnNodeInteractionScoringChanged = (testOptionIndex, shouldBeScored) => {
        props.handleOnNodeInteractionScoringChanged(testOptionIndex, shouldBeScored)
    }

    return (
        <Grid item xs={7} sm={7} md={7} style={{ minWidth: '518px' }}>
            <Grid item xs={12} style={{ marginTop: '80px' }}>
                <Typography variant="h6" className={classes.stageHeader}>
                    Rule card
                </Typography>
                <Typography
                    style={{
                        boxShadow: 'none',
                        color: 'black',
                        fontFamily: 'Lato',
                        letterSpacing: '0.02em',
                        fontSize: 14,
                        color: '#8E8E93',
                        fontFamily: 'Lato',
                    }}>
                    A versatile stage. Add rules or concepts that you wish to teach. Each rule can contain Interactives
                    to make it more engaging for your learners.
                </Typography>
                <Typography
                    style={{
                        boxShadow: 'none',
                        color: 'black',
                        fontFamily: 'Lato',
                        letterSpacing: '0.02em',
                        fontSize: 14,
                        color: '#8E8E93',
                        fontFamily: 'Lato',
                        marginTop: '10px',
                    }}>
                    Rule cards are also great ways to introduce your learners to a new section.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ paddingRight: '50px', marginTop: '20px' }}>
                <Typography className={classes.nodeTitle}>Title</Typography>
            </Grid>
            {props.node?.texts?.map((text, index) => (
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} className={classes.ruleTextField}>
                        <TextFieldWithLanguageSelector
                            text={props.node.texts[index].text}
                            disabled={!props.canEditLesson}
                            onChange={(event) => props.onRuleCardTitleChange(event, 0)}
                            l2Id={null}
                            l1Id={null}
                            soloLanguageId={props.languageId}
                            canChangeLanguage={false}
                            displayL1OrL2={null}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid container>
                <Grid item xs={8} sm={8} md={8} className={classes.scenario}>
                    <Typography className={classes.nodeTitle} style={{ marginBottom: '20px' }}>
                        Rules
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} className={classes.scenario}>
                    <FormControlLabel
                        control={
                            <Switch checked={shouldMinimise} onChange={() => setShouldMinimise(!!!shouldMinimise)} />
                        }
                        label="Reorder rules"
                    />
                </Grid>
            </Grid>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {props.node.testOptions &&
                                props.node.testOptions.length > 0 &&
                                props.node.testOptions.map((testOption, index) => (
                                    <Draggable
                                        isDragDisabled={!!!shouldMinimise}
                                        key={testOption.testOptionId}
                                        draggableId={testOption.testOptionId}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                <RulecardRule
                                                    onChangeTextImageQuestionL2={props.onChangeTextImageQuestionL2}
                                                    onChangeWhenSpoken={props.onChangeWhenSpoken}
                                                    onChangeInteraction={props.onChangeInteraction}
                                                    languageId={props.languageId}
                                                    onTestOptionTextLanguageTypeChange={
                                                        props.onTestOptionTextLanguageTypeChange
                                                    }
                                                    onVoiceDrop={props.onVoiceDrop}
                                                    onMicVoiceDrop={props.onMicVoiceDrop}
                                                    onRemoveVoice={props.onRemoveVoice}
                                                    audioPlaying={props.audioPlaying}
                                                    isAudioDownloading={props.isAudioDownloading}
                                                    onPlayVoice={props.onPlayVoice}
                                                    testOption={testOption}
                                                    index={index}
                                                    node={props.node}
                                                    detectInteractionMode={props.detectInteractionMode}
                                                    displayInteraction={props.displayInteraction}
                                                    canEditLesson={props.canEditLesson}
                                                    shouldMinimise={shouldMinimise}
                                                    onNodeInteractionScoringChanged={
                                                        handleOnNodeInteractionScoringChanged
                                                    }
                                                    currentAudioBlobUrl={props.currentAudioBlobUrl}
                                                    currentAudioTracker={props.currentAudioTracker}
                                                    onAudioLibraryClick={props.onAudioLibraryClick}
                                                    lesson={props.lesson}
                                                    l1Id={props.l1Id}
                                                    noiseSuppression={props.noiseSuppression}
                                                    onToggleNoiseSuppression={() => props.onToggleNoiseSuppression()}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {
                <React.Fragment>
                    {props.node.testOptions && props.node.testOptions.length === 0 && (
                        <Typography variant="body1" className={classes.scenarioDescriptionText}>
                            No rules added.
                        </Typography>
                    )}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            alignItems: 'center',
                            marginTop: '20px',
                            justifyContent: 'left',
                        }}>
                        {props.canEditLesson && (
                            <Button
                                onClick={() => props.onAddRule()}
                                style={{
                                    backgroundColor: '#143349',
                                    fontColor: 'white',
                                    color: 'white',
                                    marginRight: '5px',
                                    textTransform: 'none',
                                }}
                                variant="outlined">
                                Add Rule
                            </Button>
                        )}
                    </Grid>
                </React.Fragment>
            }

            {props.node?.validationErrors?.length > 0 && props.displayErrorsSection()}
            {props.saveOrCancelStage()}
        </Grid>
    )
}

RulecardModalContent.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(RulecardModalContent)
