let backendApiUrl = 'https://devapi.capeesh.com/api/'
let autoCourseCreationApi = 'https://devacc.capeesh.com/acc/'

if (process.env.REACT_APP_API_ENDPOINT) backendApiUrl = process.env.REACT_APP_API_ENDPOINT

// Override here to point API to localhost
// backendApiUrl = 'http://127.0.0.1:5000/api/'

export const API_ROOT = backendApiUrl

if (process.env.REACT_APP_AUTO_COURSE_CREATION_API_ENDPOINT)
    autoCourseCreationApi = process.env.REACT_APP_AUTO_COURSE_CREATION_API_ENDPOINT

// Override here to point Auto Course Create to localhost
// autoCourseCreationApi = 'http://127.0.0.1:5024/acc/';

export const AUTO_COURSE_CREATION_API_ROOT = autoCourseCreationApi
