import React, { useEffect, useState, useRef } from 'react'
import { Card, Button } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
const mimeType = 'audio/mp3'

const useStyles = makeStyles((theme) => ({
    recordArea: {
        position: 'relative',
        marginTop: '10px',
        marginLeft: '10px',
        height: '145px',
        width: '500px',
        paddingRight: '10px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        transition: 'all 200ms ease-out',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
    },
}))

const AudioRecorder = (props) => {
    const classes = useStyles()
    const [blobURL, setBlobURL] = useState(null)
    const [isRecording, setIsRecording] = React.useState(false)
    const [noiseSuppression, setNoiseSuppression] = React.useState(false)
    const [mediaStream, setMediaStream] = useState(null)

    useEffect(() => {
        setNoiseSuppression(props.noiseSuppression)
    }, [])

    useEffect(() => {
        return () => {
            // Cleanup function to stop the media stream when the component is unmounted
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop())
            }
        }
    }, [mediaStream])

    const toggleNoiseSuprresion = async () => {
        console.log('clicked')
        setNoiseSuppression((prevNoiseSuppression) => {
            const newNoiseSuppression = !prevNoiseSuppression

            ;(async () => {
                // Stop the previous media stream tracks before requesting a new stream
                if (mediaStream) {
                    mediaStream.getTracks().forEach((track) => track.stop())
                }

                const newMediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        autoGainControl: false,
                        echoCancellation: false,
                        googAutoGainControl: false,
                        noiseSuppression: newNoiseSuppression,
                        channelCount: 2,
                    },
                    video: false,
                })
                const audioTrack = newMediaStream.getAudioTracks()[0]
                await audioTrack.applyConstraints({
                    noiseSuppression: newNoiseSuppression,
                })

                // Update the media stream state
                setMediaStream(newMediaStream)

                props.onToggleNoiseSuppression(newNoiseSuppression)
            })()
            return newNoiseSuppression
        })
    }

    const startRecording = async () => {
        mediaRecorder.current = null

        // Get the media stream with the current noiseSuppression value
        const mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                autoGainControl: false,
                echoCancellation: false,
                googAutoGainControl: false,
                noiseSuppression: noiseSuppression,
                channelCount: 2,
            },
            video: false,
        })

        let track = mediaStream.getAudioTracks()[0]
        const settings = track.getSettings()
        console.log('browser performance:', settings.noiseSuppression)

        setRecordingStatus('recording')
        const media = new MediaRecorder(mediaStream, {
            type: mimeType,
            audioBitsPerSecond: 320000,
        })

        mediaRecorder.current = media
        mediaRecorder.current.start()

        let localAudioChunks = []
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === 'undefined') return
            if (event.data.size === 0) return
            localAudioChunks.push(event.data)
        }

        setAudioChunks(localAudioChunks)
    }

    const stopRecording = () => {
        setRecordingStatus('inactive')
        mediaRecorder.current.stop()
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType })
            const audioUrl = URL.createObjectURL(audioBlob)
            setAudioChunks([])
            const blobURL = URL.createObjectURL(audioBlob)
            setBlobURL(blobURL)
            setIsRecording(false)
            props.onMicVoiceDrop([audioBlob], blobURL, props.testOptionId, props.niceName)
            const mediaStream = mediaRecorder.current.stream
            mediaStream.getTracks().forEach((track) => track.stop())
        }
    }

    const mediaRecorder = useRef(null)
    const [recordingStatus, setRecordingStatus] = useState('inactive')
    const [audio, setAudio] = useState(null)
    const [audioChunks, setAudioChunks] = useState([])

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        '& .MuiLinearProgress-bar': {
            animationDuration: '8s',
        },
        marginLeft: '30px',
        marginRight: '30px',
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }))

    return (
        <Grid item xs={12} sm={12} md={12} className={classes.buttonGroup}>
            <Card className={classes.recordArea}>
                <Grid container justifyContent="space-evenly" alignItems="center" spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                            marginTop: '5px',
                            alignItems: 'right',
                            textAlign: 'right',
                        }}>
                        <FormControlLabel
                            control={<Checkbox checked={props.noiseSuppression} onClick={toggleNoiseSuprresion} />}
                            disabled={recordingStatus !== 'inactive' || props.audioPlaying === true}
                            label="Reduce noise"
                            sx={{ color: 'black', fontFamily: 'Lato' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{}}>
                        {
                            <BorderLinearProgress
                                variant={isRecording ? 'indeterminate' : 'determinate'}
                                value={props.humanAudioAdded ? 100 : 0}
                            />
                        }
                    </Grid>
                    {!!!isRecording && props.humanAudioAdded && (
                        <Grid item xs={4} sm={4} md={4} className={classes.buttonGroup}>
                            <Button
                                className={classes.button}
                                onClick={(event) => props.onRemoveVoice(props.scenarioNodeType, props.testOptionId)}
                                disabled={isRecording || blobURL === null || props.lesson.isDownloadingAudio}
                                variant="outlined"
                                size="small"
                                color="primary"
                                style={{
                                    border: 0,
                                    shadow: 'none',
                                    boxShadow: 'none',
                                    shadows: 'none',
                                    textDecorationLine: 'underline',
                                    textTransform: 'none',
                                    color: '#143349',
                                }}>
                                Cancel
                            </Button>
                        </Grid>
                    )}
                    {!!!isRecording && props.humanAudioAdded && (
                        <Grid item xs={4} sm={4} md={4} className={classes.buttonGroup}>
                            <Button
                                className={classes.button}
                                onClick={(event) => props.onPlayVoice(props.textAudioUrl)}
                                disabled={isRecording || blobURL === null || props.lesson.isDownloadingAudio}
                                variant="outlined"
                                size="small"
                                style={{
                                    border: '1px solid #C7C7CC',
                                    boxSizing: 'border-box',
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                                    borderRadius: '10px',
                                    textTransform: 'none',
                                    color: '#143349',
                                }}>
                                {props.audioPlaying ? 'Stop' : 'Play'}
                            </Button>
                        </Grid>
                    )}
                    {
                        <Grid item xs={4} sm={4} md={4} className={classes.buttonGroup}>
                            <Button
                                onClick={recordingStatus === 'inactive' ? startRecording : stopRecording}
                                className={classes.button}
                                style={{
                                    border: '1px solid #C7C7CC',
                                    boxSizing: 'border-box',
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                                    borderRadius: '10px',
                                    textTransform: 'none',
                                    color: '#143349',
                                }}
                                variant="outlined"
                                size="small">
                                {recordingStatus === 'inactive' ? 'Record' : 'Stop recording'}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Card>
        </Grid>
    )
}

AudioRecorder.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
        lesson: state.lesson,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(AudioRecorder)
