import React, { useEffect } from 'react'

const HubspotContactForm = (props) => {
    // expected props
    // props.region
    // props.portalId
    // props.formId
    // props.dashboardRequest (optional)
    // props.email // optional for forms with "email" field
    // props.organizationName (opional for forms with "company" field

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/embed/v2.js'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: props.region,
                    portalId: props.portalId,
                    formId: props.formId,
                    target: '#hubspotForm',
                    onFormReady: function (form, ctx) {
                        const changeEvent = new Event('change')

                        if (props.email) {
                            let emailField = form.querySelector('input[name="email"]')
                            if (emailField == null) {
                                console.log('could not find email field')
                            } else {
                                emailField.value = props.email
                                emailField.dispatchEvent(new Event('input', { bubbles: true }))
                            }
                        }

                        if (props.organizationName) {
                            let companyNameField = form.querySelector('input[name="company"]')
                            if (companyNameField == null) {
                                console.log('could not find company field')
                            } else {
                                companyNameField.value = props.organizationName
                                companyNameField.dispatchEvent(new Event('input', { bubbles: true }))
                            }
                        }

                        if (props.dashboardRequest) {
                            let dashboardRequestField = form.querySelector('textArea[name="dashboard_request"]')
                            if (dashboardRequestField == null) {
                                console.log('could not find dashboard request field')
                            } else {
                                dashboardRequestField.value =
                                    'I want access to the course: "' + props.dashboardRequest + '"'
                                dashboardRequestField.dispatchEvent(new Event('input', { bubbles: true }))
                            }
                        }
                    },
                    onSubmit: function (form) {
                        if (props.onSubmit) {
                            props.onSubmit()
                        }
                    },
                    onBeforeFormSubmit: function (form, submissionValues) {
                        console.log(submissionValues)
                    },
                })
            }
        })
    }, [])

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    )
}

export default HubspotContactForm
