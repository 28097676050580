export const ScenarioNodeType = {
    EmptyPlaceHolder: 0,
    Watch: 1,
    Listen: 2,
    Speak: 3,
    Debrief: 4,
    BranchingMultiChoice: 5,
    Brief: 6,
    TestComprehension: 7,
    TestRule: 8,
    TestListenWithSlider: 9,
    TestDefinition: 10,
    TestListenFillInBlank: 11,
    TestImage: 12,
    TestDialog: 13,
    TestAssociationPuzzle: 14,
    MultiChoice: 15,
    ListenMultiChoice: 16,
    Instruction: 17,
    PronunciationSelfAssessment: 18,
    Pin: 999,
}
Object.freeze(ScenarioNodeType)

export const AdminPreviewScenarioNodeType = {
    EmptyPlaceHolder: 100,
    Watch: 101, // check
    Listen: 102, // check
    Speak: 103, //check
    Debrief: 104,
    BranchingMultiChoice: 105, // check
    Brief: 106,
    TestComprehension: 107,
    TestRule: 108, // check
    TestListenWithSlider: 109, // check
    TestDefinition: 1010,
    TestListenFillInBlank: 1011, // check
    TestImage: 1012,
    TestDialog: 1013, // check
    TestAssociationPuzzle: 1014,
    Instruction: 1017,
}
Object.freeze(AdminPreviewScenarioNodeType)

export const AdminSketchScenarioNodeType = {
    Watch: 201,
    Listen: 202,
    Speak: 203,
    Debrief: 204,
    Brief: 206,
    Multichoice: 215,
    ListenMultichoice: 216,
}
Object.freeze(AdminSketchScenarioNodeType)

export const ScenarioInteractiveElementType = {
    NotDefined: 0,
    MultiChoice: 1,
    FillInBlankWriting: 2,
    FillInBlankDistractors: 3,
    Text: 4,
    ListenButton: 6,
    Image: 7,
    ListenButtonWithSlider: 8,
    Definition: 10,
    AssociationPuzzle: 11,
    AssociationPuzzleSequential: 12,
    Instruction: 13,
    PronunciationSelfAssessment: 14,
    MentorAnimation: 15,
    AssociationPuzzleSequentialKeepRight: 16,
}
Object.freeze(ScenarioInteractiveElementType)

export const ScenarioTextType = {
    NotDefined: 0,
    Title: 1,
    Header: 2,
    Body: 3,
    Subtitle: 4,
    Question: 5,
}
Object.freeze(ScenarioTextType)

export const ScenarioDeepLinkType = {
    BriefAndDebrief: 0,
    Brief: 1,
    Debrief: 2,
}
Object.freeze(ScenarioDeepLinkType)

export const ScenarioAudioPlayType = {
    DontPlay: 0,
    PlayBefore: 1,
    PlayAfter: 2,
}
Object.freeze(ScenarioAudioPlayType)

export const ScenarioElementAlignmentType = {
    Left: 0,
    Middle: 1,
    Right: 2,
}
Object.freeze(ScenarioInteractiveElementType)

export const MentorAnimationType = {
    Idle: 0,
    Welcome: 1,
    Celebrate: 2,
    Point: 3,
    Disappointed: 4,
    Walk: 5,
}
Object.freeze(MentorAnimationType)

export const MentorEnum = {
    NoMentor: 100,
    Abe: 0,
    Lin: 1,
    Bell: 2,
    Ron: 3,
    Solina: 4,
}
Object.freeze(MentorEnum)

export const CustomLessonType = {
    CustomLessonMultiChoiceV1: 0,
    CustomLessonScenarioV1: 1,
}
Object.freeze(CustomLessonType)

export const CustomLessonAuthorNoteType = {
    Created: 0,
    Accepted: 1,
    Solved: 2,
    Deleted: 3,
}
Object.freeze(CustomLessonAuthorNoteType)

export const CustomLessonSubtitleType = {
    None: 0,
    L1Only: 1,
    L2Only: 2,
}
Object.freeze(CustomLessonSubtitleType)

export const UserMessageType = {
    NotDefined: 0,
    StartScenarioLesson: 1,
}
Object.freeze(UserMessageType)

export const TutorialSection = {
    EmptyPlaceHolder: 0,
    Watch: 1,
    Listen: 2,
    Speak: 3,
    Debrief: 4,
    BranchingMultiChoice: 5,
    Brief: 6,
    TestComprehension: 7,
    TestRule: 8,
    TestListenWithSlider: 9,
    TestDefinition: 10,
    TestListenFillInBlank: 11,
    TestImage: 12,
    TestDialog: 13,
    TestAssociationPuzzle: 14,
    GeneralTips: 15,
    Home: 16,
    AnatomyOfLesson: 17,
    Comments: 18,
    Roleplay: 19,
    Strutural: 20,
    Learning: 21,
    Connecting: 22,
}
Object.freeze(TutorialSection)

export const SupportedNodeSuggestionTypes = {
    Listen: 2,
    Speak: 3,
    TestRule: 8,
    TestDefinition: 10,
    TestImage: 12,
}
Object.freeze(SupportedNodeSuggestionTypes)

export const GeniusTypes = {
    Roleplay: 1,
    Vocabulary: 2,
    Multiple: 3,
    Compliance: 4,
}
Object.freeze(GeniusTypes)

export const PromptTypes = {
    ConversationalRules: 1,
    Translation: 2,
    ComplianceRulesList: 3,
    Roleplay: 4,
    LessonTitle: 5,
    ImageSearch: 6,
    Vocabulary: 7,
    SectorName: 8,
    PhraseForWord: 9,
    AisteinDefinitions: 10,
}
Object.freeze(PromptTypes)

export const PromptLanguages = {
    English_UK: '1',
    English_US: '26',
    Norwegian: '3',
    Polish: '10',
}
Object.freeze(PromptLanguages)

export const PromptRolePlayRoles = {
    1: 'Employee',
    26: 'Employee',
    3: 'Ansatt',
    10: 'Pracownik',
}
Object.freeze(PromptRolePlayRoles)

export const PromptCodeToLanguageEnglish = {
    1: 'English',
    26: 'English',
    3: 'Norwegian',
    10: 'Polish',
}
Object.freeze(PromptCodeToLanguageEnglish)

export const PromptCodeToLanguageNorwegian = {
    1: 'Engelsk',
    26: 'Engelsk',
    3: 'Norsk',
    10: 'Polsk',
}
Object.freeze(PromptCodeToLanguageNorwegian)

export const IntroductionToPhrasesPerLanguage = {
    1: "Great! Now, let's practice some more useful phrases! Are you ready?",
    26: "Great! Now, let's practice some more useful phrases! Are you ready?",
    3: 'Flott! La oss nå øve på noen flere nyttige fraser! Er du klar?',
    10: 'Świetny! Teraz przećwiczmy kilka bardziej przydatnych zwrotów! Jesteś gotowy?',
}
Object.freeze(IntroductionToPhrasesPerLanguage)

export const AudioSelectionOptionType = {
    UploadAudio: 1,
    RecordAudio: 2,
    LibraryAudio: 3,
}

export const GPTPrompt = (promptType, language, inputObject) => {
    let languageWarningText =
        'Did not find language support for constituent part ' +
        promptType +
        ' of the Capeesh AI model. Defaulting to English. Results may be suboptimal.'
    let finalPrompt = ''
    switch (promptType) {
        case PromptTypes.AisteinDefinitions:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Create a long conversation based on the text. Make the conversation at least 10 parts.' +
                        '\nRelate the conversation to some of the following: ' +
                        inputObject.smartRules +
                        '\n' +
                        'Employee' +
                        ':'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Selskap: ' +
                        inputObject.smartCompanyName +
                        '.\Tekst: ' +
                        inputObject.smartDescription +
                        '\n' +
                        inputObject.smartPreamble +
                        '\nRelater samtalen til noe av det følgende: ' +
                        inputObject.smartRules +
                        '\n' +
                        'Ansatt' +
                        ':'
                    break
                default:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Create a long conversation based on the text. Make the conversation at least 10 parts.' +
                        '\nRelate the conversation to some of the following: ' +
                        inputObject.smartRules +
                        '\n' +
                        'Employee' +
                        ':'
                    console.warn(languageWarningText)
            }
            break
        case PromptTypes.Roleplay:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Create a long conversation based on the text. Make the conversation at least 10 parts.' +
                        '\nRelate the conversation to some of the following: ' +
                        inputObject.smartRules +
                        '\n' +
                        'Employee' +
                        ':'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Selskap: ' +
                        inputObject.smartCompanyName +
                        '.\Tekst: ' +
                        inputObject.smartDescription +
                        '\n' +
                        inputObject.smartPreamble +
                        '\nRelater samtalen til noe av det følgende: ' +
                        inputObject.smartRules +
                        '\n' +
                        'Ansatt' +
                        ':'
                    break
                default:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Create a long conversation based on the text. Make the conversation at least 10 parts.' +
                        '\nRelate the conversation to some of the following: ' +
                        inputObject.smartRules +
                        '\n' +
                        'Employee' +
                        ':'
                    console.warn(languageWarningText)
            }
            break
        case PromptTypes.ConversationalRules:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company name: "' +
                        inputObject.smartCompanyName +
                        '"\n' +
                        'Title: "' +
                        inputObject.smartDescription +
                        '"\nRules: "' +
                        inputObject.smartRules +
                        '"\nList the rules in simple language. Turn each rule into a friendly, informal and simplified conversation script that teaches it to the reader. Add "::" between each sentence.\nHi, colleague!::'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Navn på selskap: "' +
                        inputObject.smartCompanyName +
                        '"\n' +
                        'Tittel: "' +
                        inputObject.smartDescription +
                        '"\Regler: "' +
                        inputObject.smartRules +
                        '"\nLag ei liste med reglene på et enkelt språk. Gjør om hver regel til et vennlig, uformelt og enkelt samtalemanus som skal lære innholdet bort til leseren. Legg til "::" mellom hver enkelt setning.\nHei, kollega!::'
                    break
                default:
                    finalPrompt =
                        'Company name: "' +
                        inputObject.smartCompanyName +
                        '"\n' +
                        'Title: "' +
                        inputObject.smartDescription +
                        '"\nRules: "' +
                        inputObject.smartRules +
                        '"\nList the rules in simple language. Turn each rule into a friendly, informal and simplified conversation script that teaches it to the reader. Add "::" between each sentence.\nHi, colleague!::'
                    console.warn(languageWarningText)
                    break
            }
            break
        case PromptTypes.Translation:
            let languageOriginFriendlyName = inputObject.languages
                .filter((x) => x.id === Number(inputObject.originLanguage))
                .sort((a, b) => a.name.localeCompare(b.name))
            let languageToFriendlyName = inputObject.languages
                .filter((x) => x.id === Number(language))
                .sort((a, b) => a.name.localeCompare(b.name))
            finalPrompt =
                'Translate the ' +
                languageOriginFriendlyName[0].name +
                ' Text to ' +
                languageToFriendlyName[0].name +
                ': \n' +
                languageOriginFriendlyName[0].name +
                ' Text: ' +
                inputObject.textToTranslate +
                '\n' +
                languageToFriendlyName[0].name +
                ' Text:'
            //finalPrompt = 'Translate "' +inputObject.textToTranslate + '" to ' + languageToFriendlyName[0].name + ":\n" + languageToFriendlyName[0].name + ":"
            break
        case PromptTypes.ComplianceRulesList:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company name: ' +
                        inputObject.smartCompanyName +
                        '\n' +
                        'Title: ' +
                        inputObject.smartDescription +
                        '\nRules: ' +
                        inputObject.smartRules +
                        '\nList the rules in simple language. Add "::" between each rule.\n::'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Navn på selskap: ' +
                        inputObject.smartCompanyName +
                        '\n' +
                        'Tittel: ' +
                        inputObject.smartDescription +
                        '\Regler: ' +
                        inputObject.smartRules +
                        '\nLag ei liste med reglene på et enkelt språk. Legg til "::" mellom hver regel.\n::'
                    break
                default:
                    finalPrompt =
                        'Company name: ' +
                        inputObject.smartCompanyName +
                        '\n' +
                        'Title: ' +
                        inputObject.smartDescription +
                        '\nRules: ' +
                        inputObject.smartRules +
                        '\nList the rules in simple language. Add "::" between each rule.\n::'
                    console.warn(languageWarningText)
                    break
            }
            break
        case PromptTypes.LessonTitle:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Write a lesson title for the following: ' +
                        '\nCompany: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Teach at least ten suitable words and phrases about ' +
                        inputObject.smartRules +
                        '\n' +
                        'Lesson title:'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Skriv en leksjonstittel for det følgende: ' +
                        '\Selskap: ' +
                        inputObject.smartCompanyName +
                        '.\Tekst: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Lær bort minst 10 passende ord og setninger om ' +
                        inputObject.smartRules +
                        '\n' +
                        'Leksjonstittel:'
                    break
                default:
                    finalPrompt =
                        'Write a lesson title for the following: ' +
                        '\nCompany: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        'Teach at least ten suitable words and phrases about ' +
                        inputObject.smartRules +
                        '\n' +
                        'Lesson title:'
                    console.warn(languageWarningText)
            }
            break
        case PromptTypes.ImageSearch:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        inputObject.smartRules +
                        '\n' +
                        '. List key nouns and noun phrases.'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        inputObject.smartRules +
                        '\n' +
                        '. List key nouns and noun phrases in English.'
                    //finalPrompt = "Selskap: " + inputObject.smartCompanyName + ".\Tekst: " + inputObject.smartDescription +  "\n" + inputObject.smartPreamble + "\n" + inputObject.smartRules + "\n" + ". Lag ei liste med de viktigste substantivene og substantivfrasene på engelsk."
                    break
                default:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\nText: ' +
                        inputObject.smartDescription +
                        '\n' +
                        inputObject.smartRules +
                        '\n' +
                        '. List key nouns and noun phrases.'
                    console.warn(languageWarningText)
            }
            break
        case PromptTypes.Vocabulary:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\n' +
                        'Teach at least ten suitable words and phrases about ' +
                        inputObject.smartDescription +
                        '\n1: ' +
                        inputObject.smartRules +
                        '\n2:'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Selskap: ' +
                        inputObject.smartCompanyName +
                        '.\n' +
                        'Lær bort minst 10 passende ord og setninger om ' +
                        inputObject.smartDescription +
                        '\n1: ' +
                        inputObject.smartRules +
                        '\n2:'
                    break
                default:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\n' +
                        'Teach at least ten suitable words and phrases about ' +
                        inputObject.smartDescription +
                        '\n1: ' +
                        inputObject.smartRules +
                        '\n2:'
                    console.warn(languageWarningText)
            }
            break
        case PromptTypes.SectorName:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\n.Write only the industry sector name.\nIndustry sector name:'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Selskap: ' + inputObject.smartCompanyName + '.\n.Skriv bare navn på bransjen.\nBransjen:'
                    break
                default:
                    finalPrompt =
                        'Company: ' +
                        inputObject.smartCompanyName +
                        '.\n.Write only the industry sector name.\nIndustry sector name:'
                    console.warn(languageWarningText)
            }
            break
            // this is for an image search, so only need English output.
            break
        case PromptTypes.PhraseForWord:
            switch (language) {
                case PromptLanguages.English_UK:
                case PromptLanguages.English_US:
                    finalPrompt =
                        'Write a sentence containing the word "' +
                        inputObject.word +
                        '" in the context of ' +
                        inputObject.sector +
                        '.'
                    break
                case PromptLanguages.Norwegian:
                    finalPrompt =
                        'Skriv en setning som inneholder ordet "' +
                        inputObject.word +
                        '" i sammenheng med ' +
                        inputObject.sector +
                        '.'
                    break
                default:
                    finalPrompt =
                        'Write a sentence containing the word "' +
                        inputObject.word +
                        '" in the context of ' +
                        inputObject.sector +
                        '.'
                    console.warn(languageWarningText)
            }
            break
        default:
            console.warn('A prompt type was received that did not match.')
            break
    }
    return finalPrompt
}
Object.freeze(GPTPrompt)

export const ToggleModeViewTypes = {
    NormalEdit: 1,
    SketchMode: 2,
    DisplayTemplate: 3,
}
Object.freeze(ToggleModeViewTypes)

export const LessonType = {
    NotDefined: 0,
    Roleplay: 1,
    Vocabulary: 2,
    Compliance: 4,
    Exam: 5,
}

Object.freeze(LessonType)

export const AudioLibraryType = {
    NotDefined: 0,
    OptionsVoice: 1,
    TestOptionVoice: 2,
    BackgroundAudio: 3,
    SoundEffect: 4,
}
Object.freeze(AudioLibraryType)

export const AudioFieldType = {
    NotDefined: -1,
    Option: 0,
    BackgroundAudioUrl: 1,
    SfxAudioUrl: 2,
    TestOption: 3,
}
Object.freeze(AudioFieldType)

export const ImageFieldType = {
    NotDefined: -1,
    Option: 0,
    BackgroundImageUrl: 1,
    TestOption: 3,
}
Object.freeze(AudioFieldType)

export const ScenarioAudioSelection = {
    DragAndDropUpload: 0,
    ChooseFromLibrary: 1,
}
Object.freeze(ScenarioAudioSelection)

export const ScenarioImageSelection = {
    InitialState: -1,
    Upload: 0,
    Search: 1,
    Library: 2,
}
Object.freeze(ScenarioImageSelection)

export const ScenarioL1L2SelectorOptions = {
    L1: 0,
    L2: 1,
}
