import { Button, Card, CardContent, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { TopicLessonType } from '../../TopicConstants'
import IconButton from '@material-ui/core/IconButton'
import Cancel from '@material-ui/icons/Cancel'
import Switch from '@material-ui/core/Switch'
import Info from '@material-ui/icons/Info'
import React, { useEffect, useState } from 'react'
import { makeGetRequest } from '../../../../_state/helpers/api'
import { configWithAuth } from '../../../../_state/helpers'
import { convertDataURIToBinary } from '../../../../_state/helpers/base64Logic'
import FallbackImageLesson from '../../../../assets/images/courseGroup/FallbackImageLesson.jpeg'
import { CapeeshColors } from '../../../../assets/ColorPalette'

import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { LessonType } from '../../../../Logic/ScenarioConstants'
import { EditOutlined } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
    scenarioCard: {
        background: 'white',
        paddingBottom: '20px',
    },
    scenarioDescription: {
        color: CapeeshColors.Gray900,
        marginTop: '10px',
        marginBottom: '20px',
        width: '100%',
    },
    editLessonButton: {
        height: '40px',
        paddingLeft: '16px',
        paddingRight: '16px',
        align: 'right',
        textAlign: 'center',
    },
    publishLessonButton: {
        height: '40px',
        width: '170px',
        align: 'right',
        textAlign: 'center',
    },
    scenarioContent: {
        marginTop: '16px',
        background: 'white',
    },
    cardContentStyle: {
        backgroundColor: '#F6F7F9 !important',
    },
    publishedLanguagesText: {
        boxShadow: 'none',
        flex: 1,
        fontSize: 14,
        color: 'black',
        fontFamily: 'Lato',
        textAlign: 'left',
        marginTop: '10px',
    },
    publishedInfoText: {
        boxShadow: 'none',
        flex: 1,
        fontSize: 14,
        color: 'black',
        fontFamily: 'Lato',
        textAlign: 'right',
        marginTop: '10px',
    },
    imageContainer: {
        marginTop: '10px',
        position: 'relative',
        overflow: 'hidden',
        height: '100px',
        width: '110px',
    },

    roundedTextInfo: {
        borderRadius: '10px',
        color: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
    },

    previewButtonWithText: {
        borderRadius: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '30px',
        display: 'flex',
        justifyContent: 'right',
    },
    examBox: {
        backgroundColor: CapeeshColors.OrangeBright,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 8,
        marginTop: 6,
        height: 22,
    },
    examText: {
        color: '#fff',
        fontFamily: 'Rubik',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.25,
        paddingBottom: 2,
        paddingTop: 4,
        paddingLeft: 10,
        paddingRight: 10,
    },
}))

const CustomLessonOrderCard = (props) => {
    // expected props:
    // props.lessonOrderItem
    // props.provided
    // props.index
    // callback function
    // props.onToggleGateable
    // props.onPreviewLesson
    // props.onEditScenario

    const classes = useStyles()

    const [imageUrl, setImageUrl] = useState('')
    const [imageBlobUrl, setImageBlobUrl] = useState('')

    const [imageBorderStyle, setImageBorderStyle] = useState('4px solid ' + CapeeshColors.OrangeBright)
    const [cardBorderStyle, setCardBorderStyle] = useState('4px solid ' + CapeeshColors.Primary600)

    let canDeleteLesson =
        props.lessonOrderItem.topicLessonType === TopicLessonType.Scenario &&
        (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher')
    let showPublicationInformation = props.showAdminCard

    useEffect(() => {
        if (props.lessonOrderItem?.imageUrl && props.lessonOrderItem.imageUrl !== imageUrl) {
            setImageUrl(props.lessonOrderItem.imageUrl)
        }

        canDeleteLesson =
            props.lessonOrderItem.topicLessonType === TopicLessonType.Scenario &&
            (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher')
        showPublicationInformation = props.user.organizationRole === 'admin'
    }, [])

    useEffect(() => {
        if (!imageUrl) {
            return
        }

        if (imageUrl && !imageBlobUrl) {
            let imageReferenceId = imageUrl.replace('image/file/', '')
            makeGetRequest(
                'admin/organization/' +
                    props.organization.selectedOrganization +
                    '/image/file/reference/' +
                    imageReferenceId +
                    '/base64',
                configWithAuth()
            )
                .then((result) => {
                    let type = null
                    const typeImage = result.data.split(';')[0].split(':')[1]
                    switch (typeImage) {
                        case 'image/jpeg':
                            type = 'jpeg'
                            break
                        case 'image/png':
                            type = 'png'
                            break
                        default:
                            type = 'unknown'
                            break
                    }
                    var binary = convertDataURIToBinary(result.data)
                    var blob = new Blob([binary], { type: type })
                    var blobUrl = URL.createObjectURL(blob)
                    setImageBlobUrl(blobUrl)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }, [imageUrl])

    return (
        <Card
            ref={props.provided.innerRef}
            className={classes.scenarioContent}
            {...props.provided.draggableProps}
            {...props.provided.dragHandleProps}>
            <CardContent
                className={classes.cardContentStyle}
                style={{
                    borderRadius: '10px',
                    borderLeft: props.lessonOrderItem.published ? cardBorderStyle : '',
                    borderBottom: props.lessonOrderItem.published ? cardBorderStyle : '',
                }}>
                <Grid container>
                    <Grid
                        item
                        xs={2}
                        className={classes.imageContainer}
                        style={{
                            borderRadius: '10px',
                            borderLeft: imageBorderStyle,
                            borderBottom: imageBorderStyle,
                        }}>
                        {imageUrl && imageBlobUrl && (
                            <img
                                src={imageBlobUrl}
                                style={{
                                    maxWidth: '150%',
                                    maxHeight: '150%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        )}
                        {imageUrl && !imageBlobUrl && <Typography style={{ marginTop: '70px' }}></Typography>}
                        {!imageUrl && (
                            <img
                                src={FallbackImageLesson}
                                style={{
                                    maxWidth: '150%',
                                    maxHeight: '150%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container style={{ marginLeft: '15px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={7}>
                                <Grid item xs={12} style={{ marginTop: '5px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}>
                                        <Typography variant={'h6'} style={{ color: CapeeshColors.Gray900 }}>
                                            {props.lessonOrderItem.published ? props.index + 1 + '.' : ''}{' '}
                                            {props.lessonOrderItem.name}
                                        </Typography>
                                        {props.lessonOrderItem.lessonType === LessonType.Exam && (
                                            <div className={classes.examBox}>
                                                <Typography className={classes.examText}>Exam</Typography>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                }}>
                                {canDeleteLesson && (
                                    <IconButton
                                        size="medium"
                                        style={{
                                            color: CapeeshColors.Gray2,
                                            marginTop: '-10px',
                                            marginLeft: '-20px',
                                            height: '50px',
                                        }}
                                        aria-label="delete"
                                        onClick={() => {
                                            props.onDetachScenario(props.lessonOrderItem.customLessonId)
                                        }}>
                                        <Cancel size={48} />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {props.lessonOrderItem.topicLessonType === TopicLessonType.Scenario && (
                                    <Typography variant={'body2'} className={classes.scenarioDescription}>
                                        {props.lessonOrderItem.description}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} style={{ marginRight: '15px' }}>
                                {props.lessonOrderItem.topicLessonType === TopicLessonType.Scenario && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}>
                                                        <div>
                                                            {(props.lessonOrderItem.published ||
                                                                props.lessonOrderItem.canBePublished) && (
                                                                <React.Fragment>
                                                                    <div
                                                                        className={classes.previewButtonWithText}
                                                                        onClick={() =>
                                                                            props.onPreviewLesson(
                                                                                props.lessonOrderItem.customLessonId
                                                                            )
                                                                        }>
                                                                        <Typography
                                                                            style={{
                                                                                width: '50px',
                                                                                height: '30px',
                                                                                marginTop: '2px',
                                                                            }}>
                                                                            Preview
                                                                        </Typography>
                                                                        <IconButton
                                                                            size="medium"
                                                                            style={{
                                                                                color: CapeeshColors.Primary600,
                                                                                marginTop: '-10px',
                                                                                height: '50px',
                                                                            }}
                                                                            aria-label="preview">
                                                                            <PlayCircleIcon size={48} />
                                                                        </IconButton>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: '10px',
                                                            }}>
                                                            <Button
                                                                className={classes.editLessonButton}
                                                                variant={'contained'}
                                                                onClick={(event) =>
                                                                    props.onEditScenario(
                                                                        props.lessonOrderItem.customLessonId
                                                                    )
                                                                }
                                                                style={{
                                                                    textTransform: 'none',
                                                                    background: 'white',
                                                                    color: CapeeshColors.Gray900,
                                                                }}>
                                                                <EditOutlined
                                                                    sx={{
                                                                        marginRight: '8px',
                                                                        fontSize: '15px',
                                                                        color: CapeeshColors.Primary600,
                                                                    }}
                                                                />
                                                                View lesson
                                                            </Button>

                                                            {props.lessonOrderItem.published &&
                                                                props.lessonOrderItem.canBePublished && (
                                                                    <Button
                                                                        className={classes.editLessonButton}
                                                                        variant={'contained'}
                                                                        disabled={!props.lessonOrderItem.canBePublished}
                                                                        onClick={(event) =>
                                                                            props.onPublishLesson(
                                                                                props.lessonOrderItem.customLessonId
                                                                            )
                                                                        }
                                                                        style={{
                                                                            textTransform: 'none',
                                                                            background: CapeeshColors.Primary600,
                                                                            color: 'white',
                                                                        }}>
                                                                        Republish
                                                                    </Button>
                                                                )}
                                                            {!props.lessonOrderItem.published && (
                                                                <Button
                                                                    className={classes.editLessonButton}
                                                                    variant={'contained'}
                                                                    disabled={!props.lessonOrderItem.canBePublished}
                                                                    onClick={() =>
                                                                        props.onPublishLesson(
                                                                            props.lessonOrderItem.customLessonId
                                                                        )
                                                                    }
                                                                    style={{
                                                                        textTransform: 'none',
                                                                        background: CapeeshColors.Primary600,
                                                                        color: 'white',
                                                                    }}>
                                                                    Publish
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {props.lessonOrderItem.published && props.showAdminCard && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                    <React.Fragment>
                                        <Typography variant="body1" color="primary">
                                            Progression
                                        </Typography>
                                        <Switch
                                            checked={props.lessonOrderItem.gateable}
                                            onChange={() => {
                                                props.onToggleGateable(props.lessonOrderItem.customLessonId)
                                            }}
                                            color="primary"
                                        />
                                    </React.Fragment>
                                </Grid>
                            )}
                            {showPublicationInformation && (
                                <Grid item xs={12} style={{ marginRight: '15px' }}>
                                    {props.lessonOrderItem.topicLessonType === TopicLessonType.Scenario && (
                                        <Grid container>
                                            <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                                                <div className={classes.divider} />
                                            </Grid>

                                            <Grid item xs={8}>
                                                {props.lessonOrderItem.published && (
                                                    <Grid item xs={12}>
                                                        {props.user.organizationRole === 'admin' && (
                                                            <Typography
                                                                variant="body1"
                                                                className={classes.publishedLanguagesText}>
                                                                {props.lessonOrderItem.numberOfLanguagesWithContent}/
                                                                {props.lessonOrderItem.numberOfSupportedLanguages}{' '}
                                                                Available languages
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                )}
                                                {props.lessonOrderItem.published && (
                                                    <Grid item xs={12}>
                                                        {props.user.organizationRole === 'admin' && (
                                                            <Typography
                                                                variant="body1"
                                                                className={classes.publishedLanguagesText}>
                                                                {
                                                                    props.lessonOrderItem
                                                                        .numberOfLanguagesPublishedLatestContent
                                                                }
                                                                /{props.lessonOrderItem.numberOfSupportedLanguages}{' '}
                                                                Available for latest content
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid item xs={12}>
                                                    {!props.lessonOrderItem.published && (
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.publishedInfoText}>
                                                            Not published
                                                        </Typography>
                                                    )}
                                                    {props.lessonOrderItem.published &&
                                                        props.lessonOrderItem.hasUnpublishedChanges && (
                                                            <Typography
                                                                variant="body1"
                                                                className={classes.publishedInfoText}>
                                                                <IconButton size="small">
                                                                    <Info size={15} />
                                                                </IconButton>
                                                                Has unpublished changes
                                                            </Typography>
                                                        )}
                                                    {props.lessonOrderItem.published &&
                                                        !props.lessonOrderItem.hasUnpublishedChanges && (
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.publishedInfoText}>
                                                                    Published
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                                {props.lessonOrderItem.lastPublishedDate && (
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.publishedInfoText}>
                                                            Last published:{' '}
                                                            <b>{props.lessonOrderItem.lastPublishedDate}</b>
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

CustomLessonOrderCard.propTypes = {}

const mapStateToProps = (state) => {
    return {
        languages: state.metadata.metadata.languages,
        user: state.user,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomLessonOrderCard)
