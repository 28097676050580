import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const FocusAreaListeningToken = (props) => {
    const color = !props.disabled
        ? props.selected
            ? props.hover
                ? 'rgba(174, 89, 173, 0.32)'
                : '#E5E5EA'
            : 'url(#paint0_linear_1433_23307)'
        : '#E5E5EA'
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill={color} />
            <g clip-path="url(#clip0_1433_23307)">
                <path
                    d="M17.3733 14.0133L17.9867 14.6267L11.9467 20.6667H11.3333V20.0533L17.3733 14.0133ZM19.7733 10C19.6067 10 19.4333 10.0667 19.3067 10.1933L18.0867 11.4133L20.5867 13.9133L21.8067 12.6933C22.0667 12.4333 22.0667 12.0133 21.8067 11.7533L20.2467 10.1933C20.1133 10.06 19.9467 10 19.7733 10ZM17.3733 12.1267L10 19.5V22H12.5L19.8733 14.6267L17.3733 12.1267Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1433_23307"
                    x1="0"
                    y1="0"
                    x2="32"
                    y2="32"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CC85C6" />
                    <stop offset="1" stop-color="#AE59AD" />
                </linearGradient>
                <clipPath id="clip0_1433_23307">
                    <rect width="16" height="16" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    )
}

FocusAreaListeningToken.propTypes = {}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreaListeningToken)
