import React, { useEffect, useState } from 'react'
import { Grid, Divider } from '@material-ui/core'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TutorialSection } from '../../../../Logic/ScenarioConstants'
import queryString from 'query-string'
import { history } from '_state/helpers'
import GuideCommentsButton from '../../../../assets/images/GuideCommentsButton.png'
import TutorialSpeak from './TutorialSpeak'
import TutorialWatch from './TutorialWatch'
import TutorialComments from './TutorialComments'
import TutorialAnatomy from './TutorialAnatomy'
import TutorialListen from './TutorialListen'
import TutorialDefinition from './TutorialDefinition'
import TutorialConnecting from './TutorialConnecting'
import TutorialTestImage from './TutorialTestImage'
import TutorialRulecard from './TutorialRulecard'
import TutorialBrief from './TutorialBrief'
import TutorialDebrief from './TutorialDebrief'
import TutorialPuzzle from './TutorialPuzzle'

const useStyles = makeStyles((theme) => ({
    leftPanel: {
        minWidth: '400px',
    },
    mainContainer: {
        paddingLeft: '25px',
        paddingRight: '25px',
        minWidth: '1400px',
    },
    rightPaper: {
        marginTop: '30px',
        background: '#FFFFFF',
        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        borderRadius: '15px',
    },
}))

const TutorialMain = (props) => {
    const classes = useStyles()
    const [mainSection, setMainSection] = useState(TutorialSection.EmptyPlaceHolder)
    const [selectedSection, setSelectedSection] = useState(TutorialSection.Home)
    const [isGeneralAccordionOpen, setIsGeneralAccordionOpen] = useState(true)
    const [isLessonsAccordionOpen, setIsLessonsAccordionOpen] = useState(true)
    const [visitedFrom, setVisitedFrom] = useState(null)
    const [previousName, setPreviousName] = useState('previous page')

    useEffect(() => {
        const values = queryString.parse(props.location.search)
        updatePage(values.section)
        if (values.visitedFrom) {
            setVisitedFrom(values.visitedFrom)
        }
        if (values.previousName) {
            setPreviousName(values.previousName.replace(/["]+/g, ''))
        }
    }, [])

    useEffect(() => {
        const values = queryString.parse(props.location.search)
        updatePage(values.section)
    }, [props.location.search])

    const updatePage = (section) => {
        if (section) {
            switch (section) {
                case 'welcome':
                    setSelectedSection(TutorialSection.Home)
                    setMainSection(TutorialSection.EmptyPlaceHolder)
                    break
                case 'watch':
                    setSelectedSection(TutorialSection.Watch)
                    setMainSection(TutorialSection.Roleplay)
                    break
                case 'listen':
                    setSelectedSection(TutorialSection.Listen)
                    setMainSection(TutorialSection.Roleplay)
                    break
                case 'speak':
                    setSelectedSection(TutorialSection.Speak)
                    setMainSection(TutorialSection.Roleplay)
                    break
                case 'lesson-stage-anatomy':
                    setSelectedSection(TutorialSection.AnatomyOfLesson)
                    setMainSection(TutorialSection.EmptyPlaceHolder)
                    break
                case 'connecting-stages':
                    setSelectedSection(TutorialSection.Connecting)
                    setMainSection(TutorialSection.EmptyPlaceHolder)
                    break
                case 'comments':
                    setSelectedSection(TutorialSection.Comments)
                    setMainSection(TutorialSection.EmptyPlaceHolder)
                    break
                case 'definition':
                    setSelectedSection(TutorialSection.TestDefinition)
                    setMainSection(TutorialSection.Learning)
                    break
                case 'test':
                    setSelectedSection(TutorialSection.TestImage)
                    setMainSection(TutorialSection.Learning)
                    break
                case 'rulecard':
                    setSelectedSection(TutorialSection.TestRule)
                    setMainSection(TutorialSection.Learning)
                    break
                case 'puzzle':
                    setSelectedSection(TutorialSection.TestAssociationPuzzle)
                    setMainSection(TutorialSection.Learning)
                    break
                case 'brief':
                    setSelectedSection(TutorialSection.Brief)
                    setMainSection(TutorialSection.Strutural)
                    break
                case 'debrief':
                    setSelectedSection(TutorialSection.Debrief)
                    setMainSection(TutorialSection.Strutural)
                    break
            }
            window.scrollTo(0, 0)
        }
    }

    const tutorialParagraph = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        marginBottom: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialSubHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '10px',
                        marginTop: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '32px',
                        marginBottom: '20px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const sectionController = (section) => {
        switch (section) {
            case TutorialSection.Home:
                return generalSection()
            case TutorialSection.Speak:
                return speakSection()
            case TutorialSection.Watch:
                return watchSection()
            case TutorialSection.Listen:
                return listenSection()
            case TutorialSection.AnatomyOfLesson:
                return anatomySection()
            case TutorialSection.Connecting:
                return connectingSection()
            case TutorialSection.Comments:
                return commentsSection()
            case TutorialSection.TestDefinition:
                return definitionSection()
            case TutorialSection.TestImage:
                return testImageSection()
            case TutorialSection.TestRule:
                return ruleCardSection()
            case TutorialSection.TestAssociationPuzzle:
                return puzzleSection()
            case TutorialSection.Brief:
                return briefSection()
            case TutorialSection.Debrief:
                return debriefSection()
        }
    }

    const speakSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialSpeak />
            </div>
        )
    }

    const watchSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialWatch />
            </div>
        )
    }

    const generalSection = () => {
        return (
            <React.Fragment>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{
                                paddingLeft: '40px',
                                paddingTop: '40px',
                                paddingRight: '20px',
                            }}>
                            <Grid container>
                                <React.Fragment>
                                    {tutorialHeader('The Cutscene Stage')}
                                    {tutorialSubHeader('How it works')}
                                    {tutorialParagraph(
                                        'Cutscene stages can be thought of giving us the opportunity to help build a narrative for a role play. They help set the scene, or create more engaging stories.'
                                    )}
                                </React.Fragment>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                            <Grid
                                container
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                    position: 'relative',
                                    top: '-50px',
                                }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: '300px',
                                        }}>
                                        <iframe
                                            src="https://player.vimeo.com/video/684241985?h=25ccc60d8a"
                                            style={{ justifyContent: 'start' }}
                                            width="640"
                                            height="360"
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowfullscreen></iframe>
                                    </div>
                                </Grid>
                                <Grid
                                    container
                                    direction="row-reverse"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    style={{ width: '238px' }}>
                                    <Grid item xs={2} sm={2} md={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => history.push('?section=watch')}
                                            style={{
                                                width: '140px',
                                                marginRight: '100px',
                                            }}>
                                            Read more
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Listen Stage')}
                                        {tutorialSubHeader('How it works')}
                                        {tutorialParagraph(
                                            'The other side of a conversation to a Branching stage, the Listen stage gives the learner the opportunity to listen to somebody talking to them.'
                                        )}
                                        {tutorialParagraph(
                                            'This is typically a face-to-face conversation, but also be a telephone conversation. It might also be a case of the learner listening to the radio, for instance.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/686713992?h=91d67a1c65"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=listen')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Branching Stage')}
                                        {tutorialSubHeader('How it works')}
                                        {tutorialParagraph(
                                            "Together with the Listen stage, the Branching stage represents the role play's conversational feature. It allows the learner to engage in conversation within the app."
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/687958781?h=f8dcc0ba59"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=speak')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Definition Stage')}
                                        {tutorialSubHeader('How it works')}
                                        {tutorialParagraph(
                                            'The Definition Stage is used for teaching words, phrases or simple concepts. It is a great stage to use for a first introduction of a new vocabulary item or important sentence.'
                                        )}
                                        {tutorialParagraph(
                                            'It contains four core areas: An image of what is being taught, the definition itself, the explanation of the definition, and finally a voice sample of the definition being spoken out.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/677684714?h=c3156a80f2"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=definition')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Test Stage')}
                                        {tutorialSubHeader('How it works')}
                                        {tutorialParagraph(
                                            'It tests the spelling and comprehension of a word, a phrase, sentence or an instruction. It always contains a matching picture.'
                                        )}
                                        {tutorialParagraph(
                                            'The test stage is typically, but not always, used after a word, phrase or concept has previously been introduced. The stage has multiple different interaction methods.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/689320556?h=36cb0d4727"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=test')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Rulecard Stage')}
                                        {tutorialSubHeader('How it works')}
                                        {tutorialParagraph(
                                            'Explain rules, instructions and grammar, or present longer pieces of text and information in either L1 or L2.'
                                        )}
                                        {tutorialParagraph(
                                            'The test stage is typically, but not always, used after a word, phrase or concept has previously been introduced. The stage has multiple different interaction methods.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/688766534?h=06c323642d"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=rulecard')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Puzzle Stage')}
                                        {tutorialSubHeader('What is it?')}
                                        {tutorialParagraph(
                                            'Practice comprehension and retention by match pairs of words, short phrases, sentences or instructions in a sliding puzzle game.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/692172812?h=35dc55e622"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=puzzle')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Brief Stage')}
                                        {tutorialSubHeader('What is it?')}
                                        {tutorialParagraph(
                                            'Every lesson has to start with a Brief stage that introduces the lesson in either L1 or L2. The brief stage gives the learner their objective for the lesson.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/692173743?h=4206363567"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=brief')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('The Debrief Stage')}
                                        {tutorialSubHeader('What is it?')}
                                        {tutorialParagraph(
                                            'Every lesson has to end with a Debrief stage that shows the result and gives feedback in either L1 or L2.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/692174555?h=c715460a81"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=debrief')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('Connecting lesson stages together')}
                                        {tutorialParagraph(
                                            'How do we specify the order in which our lesson stages should play? This is a simple task that takes a matter of seconds. In this section, we will explore this process.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: '300px',
                                            }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/677699817?h=20d39993f3"
                                                style={{
                                                    justifyContent: 'start',
                                                }}
                                                width="640"
                                                height="360"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=connecting-stages')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
                <div className={classes.rightPaper}>
                    <Grid container>
                        <React.Fragment>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '40px',
                                    paddingRight: '20px',
                                }}>
                                <Grid container>
                                    <React.Fragment>
                                        {tutorialHeader('Adding reviewer comments')}
                                        {tutorialParagraph(
                                            'It is possible to add reviewer comments to each stage of a lesson. All authors are able to add and view comments.'
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ paddingRight: '40px' }}>
                                <Grid
                                    container
                                    style={{
                                        paddingLeft: '40px',
                                        paddingTop: '40px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        top: '-50px',
                                    }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '30px',
                                            marginBottom: '30px',
                                        }}>
                                        <img
                                            style={{
                                                flex: 1,
                                                color: 'black',
                                                height: '220px',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                            }}
                                            src={GuideCommentsButton}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{ width: '238px' }}>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push('?section=comments')}
                                                style={{
                                                    width: '140px',
                                                    marginRight: '100px',
                                                }}>
                                                Read more
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }

    const definitionSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialDefinition />
            </div>
        )
    }

    const connectingSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialConnecting />
            </div>
        )
    }

    const testImageSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialTestImage />
            </div>
        )
    }

    const ruleCardSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialRulecard />
            </div>
        )
    }

    const briefSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialBrief />
            </div>
        )
    }

    const debriefSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialDebrief />
            </div>
        )
    }

    const puzzleSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialPuzzle />
            </div>
        )
    }

    const listenSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialListen />
            </div>
        )
    }

    const anatomySection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialAnatomy />
            </div>
        )
    }

    const commentsSection = () => {
        return (
            <div className={classes.rightPaper}>
                <TutorialComments />
            </div>
        )
    }

    const handleExitGuide = (event, section) => {
        event.stopPropagation()
        event.preventDefault()
        history.push(visitedFrom)
    }

    const handleSectionClick = (event, section) => {
        event.stopPropagation()
        event.preventDefault()
        history.push('?section=' + section)
    }
    return (
        <Grid container className={classes.mainContainer} spacing={5}>
            <Grid className={classes.leftPanel} item xs={4} sm={4} md={4}>
                <Grid container className={classes.root} spacing={3}>
                    {visitedFrom && (
                        <Grid item xs={12} sm={12} md={12}>
                            <Button variant="contained" onClick={handleExitGuide}>
                                Back to {previousName}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                        <Accordion
                            expanded={isGeneralAccordionOpen}
                            disableGutters={true}
                            style={{
                                marginTop: '20px',
                                padding: '0px',
                                background: '#EFEFEF',
                                border: '1px solid #D1D1D6',
                                boxSizing: 'border-box',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
                                borderRadius: '20px',
                            }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontFamily: 'Lato',
                                        fontStyle: 'bold',
                                        fontSize: '26px',
                                    }}>
                                    General
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Button
                                    onClick={(e) => handleSectionClick(e, 'welcome')}
                                    sx={{
                                        width: '100%',
                                        backgroundColor:
                                            selectedSection === TutorialSection.GeneralTips ? '#C4C4C4' : '#EFEFEF',
                                        display: 'flex',
                                        alignItems: 'left',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                        color: 'black',
                                        borderRadius: 0,
                                    }}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginLeft: '20px',
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                        }}>
                                        Welcome
                                    </Typography>
                                </Button>
                                <Divider style={{ width: '100%' }} />
                                <Button
                                    onClick={(e) => handleSectionClick(e, 'lesson-stage-anatomy')}
                                    sx={{
                                        width: '100%',
                                        backgroundColor:
                                            selectedSection === TutorialSection.AnatomyOfLesson ? '#C4C4C4' : '#EFEFEF',
                                        display: 'flex',
                                        alignItems: 'left',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                        color: 'black',
                                        borderRadius: 0,
                                    }}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginLeft: '20px',
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                        }}>
                                        What is a lesson stage?
                                    </Typography>
                                </Button>
                                <Divider style={{ width: '100%' }} />
                                <Button
                                    onClick={(e) => handleSectionClick(e, 'connecting-stages')}
                                    sx={{
                                        width: '100%',
                                        backgroundColor:
                                            selectedSection === TutorialSection.Connecting ? '#C4C4C4' : '#EFEFEF',
                                        display: 'flex',
                                        alignItems: 'left',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                        color: 'black',
                                        borderRadius: 0,
                                    }}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginLeft: '20px',
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                        }}>
                                        Connecting stages together
                                    </Typography>
                                </Button>
                                <Divider style={{ width: '100%' }} />
                                <Button
                                    onClick={(e) => handleSectionClick(e, 'comments')}
                                    sx={{
                                        width: '100%',
                                        backgroundColor:
                                            selectedSection === TutorialSection.Comments ? '#C4C4C4' : '#EFEFEF',
                                        display: 'flex',
                                        alignItems: 'left',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                        color: 'black',
                                        borderRadius: 0,
                                    }}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginLeft: '20px',
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                        }}>
                                        Adding comments
                                    </Typography>
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Accordion
                            expanded={isLessonsAccordionOpen}
                            disableGutters={true}
                            style={{
                                padding: '0px',
                                background: '#EFEFEF',
                                border: '1px solid #D1D1D6',
                                boxSizing: 'border-box',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
                                borderRadius: '20px',
                            }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontFamily: 'Lato',
                                        fontStyle: 'bold',
                                        fontSize: '26px',
                                    }}>
                                    Lesson Stages
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Accordion
                                    expanded={mainSection === TutorialSection.Strutural}
                                    onClick={() =>
                                        setMainSection(
                                            mainSection === TutorialSection.Strutural
                                                ? TutorialSection.EmptyPlaceHolder
                                                : TutorialSection.Strutural
                                        )
                                    }
                                    disableGutters={true}
                                    style={{
                                        padding: '0px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        background: '#EFEFEF',
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                            marginTop: '0px',
                                            marginBottom: '0px',
                                            paddingTop: '0px',
                                            paddingNottom: '0px',
                                        }}>
                                        <div style={{ width: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontSize: '18px',
                                                }}>
                                                Structural Stages
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'brief')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.Brief ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Brief Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'debrief')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.Debrief ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Debrief Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                </Accordion>
                                <Accordion
                                    expanded={mainSection === TutorialSection.Learning}
                                    onClick={() =>
                                        setMainSection(
                                            mainSection === TutorialSection.Learning
                                                ? TutorialSection.EmptyPlaceHolder
                                                : TutorialSection.Learning
                                        )
                                    }
                                    disableGutters={true}
                                    style={{
                                        padding: '0px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        background: '#EFEFEF',
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                            marginTop: '0px',
                                            marginBottom: '0px',
                                            paddingTop: '0px',
                                            paddingNottom: '0px',
                                        }}>
                                        <div style={{ width: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontSize: '18px',
                                                }}>
                                                Learning Stages
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'definition')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.TestDefinition
                                                    ? '#C4C4C4'
                                                    : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Definition Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'test')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.TestImage ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Test Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'rulecard')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.TestRule ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Rulecard Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'puzzle')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.TestAssociationPuzzle
                                                    ? '#C4C4C4'
                                                    : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Puzzle Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                </Accordion>

                                <Accordion
                                    expanded={mainSection === TutorialSection.Roleplay}
                                    onClick={() =>
                                        setMainSection(
                                            mainSection === TutorialSection.Roleplay
                                                ? TutorialSection.EmptyPlaceHolder
                                                : TutorialSection.Roleplay
                                        )
                                    }
                                    disableGutters={true}
                                    style={{
                                        padding: '0px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        background: '#EFEFEF',
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                            marginTop: '0px',
                                            marginBottom: '0px',
                                            paddingTop: '0px',
                                            paddingNottom: '0px',
                                        }}>
                                        <div style={{ width: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontSize: '18px',
                                                }}>
                                                Role Play Stages
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'watch')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.Watch ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Cutscene Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'listen')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.Listen ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Listen Stage
                                        </Typography>
                                    </Button>
                                    <Divider style={{ width: '100%' }} />
                                    <Button
                                        onClick={(e) => handleSectionClick(e, 'speak')}
                                        sx={{
                                            width: '100%',
                                            backgroundColor:
                                                selectedSection === TutorialSection.Speak ? '#C4C4C4' : '#EFEFEF',
                                            display: 'flex',
                                            alignItems: 'left',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: 0,
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginLeft: '40px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            - Branching Stage
                                        </Typography>
                                    </Button>
                                </Accordion>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} sm={8} md={8} style={{ minWidth: '500px' }}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '32px',
                        marginLeft: '20px',
                    }}>
                    The Capeesh Guidebook
                </Typography>
                {sectionController(selectedSection)}
            </Grid>
        </Grid>
    )
}

TutorialMain.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(TutorialMain)
