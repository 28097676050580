import { makeStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { ScenarioNodeType, ScenarioInteractiveElementType } from '../../../../Logic/ScenarioConstants'
import NodeView from './NodeView'
import { CapeeshColors } from '../../../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
}))

const DataPickerLessonNode = (props) => {
    const getInteractionStyle = (scenarioInteractiveElementType, alternatives, text) => {
        switch (scenarioInteractiveElementType) {
            case ScenarioInteractiveElementType.FillInBlankDistractors:
                if (isSentenceBuilder(alternatives, text)) {
                    return 'Sentence Builder'
                }
                return 'Multiple Choice'
            case ScenarioInteractiveElementType.Text:
                return 'Keyboard'
            case ScenarioInteractiveElementType.FillInBlankWriting:
                return 'Keyboard'

            default:
                return 'Unknown interaction'
        }
    }

    const sentenceBuildWordGroups = (alternatives) => {
        let isSentenceBuilder = true
        alternatives.sort((a, b) => b.startIndex + a.startIndex).forEach((alternative) => {})
    }

    const isSentenceBuilder = (alternatives, text) => {
        if (text === '') return false
        sentenceBuildWordGroups(alternatives)
        let total = text.length
        let next = 0
        let areMultipleAtStartIndex = false
        alternatives.forEach((alt) => {
            for (let x = 0; x <= total; x++) {
                if (x >= alt.startIndex && x <= alt.startIndex + alt.text.length) {
                    next++
                }
            }
            alternatives.forEach((alt2) => {
                if (alt.startIndex === alt2.startIndex && alt.textSnippetId !== alt2.textSnippetId) {
                    areMultipleAtStartIndex = true
                }
            })
        })
        return next >= total && !!!areMultipleAtStartIndex && alternatives.length > 1
    }
    const displayCorrectNode = (scenarioNodeType) => {
        switch (scenarioNodeType) {
            case ScenarioNodeType.Speak:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#9BD8A0'}
                        showInteraction={false}
                        title={'Branching (speak) stage'}
                        l2Array={props.node.options}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.Watch:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={CapeeshColors.Blue}
                        showInteraction={false}
                        title={'Cutscene stage'}
                        l2Array={props.node.options}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.MultiChoice:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#9BD8A0'}
                        showInteraction={false}
                        title={'Multichoice stage'}
                        l2Array={props.node.testOptions.slice(1)}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.BranchingMultiChoice:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#9BD8A0'}
                        showInteraction={false}
                        title={'Branching (tap) stage'}
                        l2Array={props.node.options}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.Listen:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#86C4F0'}
                        showInteraction={false}
                        title={'Listen stage'}
                        l2Array={props.node.options}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.TestRule:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#FFDD81'}
                        showInteraction={false}
                        title={'Rulecard stage'}
                        l2Array={props.node.testOptions}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.TestImage:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#B1BDAE'}
                        showInteraction={true}
                        interaction={
                            'Interaction: ' +
                            getInteractionStyle(
                                props.node.testOptions[2].scenarioInteractiveElementType,
                                props.node.testOptions[2].alternatives,
                                props.node.testOptions[2].text
                            )
                        }
                        title={'Test stage'}
                        l2Array={[props.node.testOptions[2]]}
                        node={props.node}
                    />
                )
            case ScenarioNodeType.TestDefinition:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'purple'}
                        showInteraction={false}
                        title={'Definition stage'}
                        l2Array={
                            props.node.testOptions.length > 0
                                ? [props.node.testOptions[1]]
                                : [{ text: 'Node is malformed' }]
                        }
                        node={props.node}
                    />
                )
            case ScenarioNodeType.TestAssociationPuzzle:
                return (
                    <NodeView
                        selectionPosition={props.selectionPosition}
                        isAdded={props.isAdded}
                        onToggleAddNode={props.onToggleAddNode}
                        color={'#ffaf66'}
                        showInteraction={false}
                        title={'Puzzle stage'}
                        l2Array={
                            props.node.testOptions.length > 0 && props.node.testOptions[0].alternatives
                                ? props.node.testOptions[0].alternatives
                                : [{ text: 'Node is malformed' }]
                        }
                        node={props.node}
                    />
                )
            default:
                return <div></div> // <p>unsupported node type</p>
        }
    }
    const classes = useStyles()
    return displayCorrectNode(props.node.scenarioNodeType)
}

function mapStateToProps(state) {
    const {} = state
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPickerLessonNode)
