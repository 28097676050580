import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { MentorEnum } from '../../../../Logic/ScenarioConstants'
import { ReactComponent as CalloutLeft } from '../../../../assets/images/CalloutLeft.svg'
import { ReactComponent as CalloutRight } from '../../../../assets/images/CalloutRight.svg'
import LinHead from '../../../../assets/images/LinHead.svg'
import RonHead from '../../../../assets/images/RonHead.svg'
import AbeHead from '../../../../assets/images/AbeHead.svg'
import BellHead from '../../../../assets/images/BellHead.svg'
import SolinaHead from '../../../../assets/images/SolinaHead.svg'

const useStyles = makeStyles((theme) => ({
    underscoreReplacement: {
        display: 'inline-block',
        background: 'rgba(68, 166, 239, 0.3)',
        width: '8px',
        height: '16px',
        verticalAlign: 'middle',
        position: 'relative',
        boxShadow: 'inset 0px 2px 0px rgba(102, 67, 160, 0.12)',
    },
}))

const highlightIterator = (alternatives) => {
    let highlightArray = []
    let addedStartIndeces = []
    alternatives.forEach((alternative) => {
        if (!!!addedStartIndeces.includes(alternative.startIndex)) {
            highlightArray.push([alternative.startIndex, alternative.startIndex + alternative.text.length])
            addedStartIndeces.push(alternative.startIndex)
        }
    })
    return highlightArray
}

const setCharAt = (str, index, chr) => {
    if (index > str.length - 1) return str
    return str.substring(0, index) + chr + str.substring(index + 1)
}

const displayL2WithGapForDistractors = (text, alternatives) => {
    let textWithGaps = text
    let distractorGaps = highlightIterator(alternatives)
    distractorGaps.forEach((gap) => {
        for (let i = gap[0]; i < gap[1]; i++) {
            textWithGaps = setCharAt(textWithGaps, i, '_')
        }
    })
    return textWithGaps
}

const DialogueStageUtterance = (props) => {
    const classes = useStyles()
    let textWithGapsForDistractors = displayL2WithGapForDistractors(props.text, props.alternatives).split('_')
    console.log('herehere', props.translation)
    return (
        <Grid container spacing={0} style={{ marginBottom: '5px' }}>
            {props.position === 'middle' && (
                <Typography
                    style={{
                        borderRadius: 10,
                        background: '#9CA8B8',
                        width: '90%',
                        marginLeft: '5px',
                        marginRight: '5px',
                        color: 'var(--Color-Burn, #4D4D4D)',
                        fontFamily: 'Nunito',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        letterSpacing: 0.41,
                        minHeight: '21px',
                        padding: '3px',
                    }}>
                    {textWithGapsForDistractors.map((part, index) => (
                        <React.Fragment key={index}>
                            {part}
                            {index < textWithGapsForDistractors.length - 1 && (
                                <span className={classes.underscoreReplacement}> </span>
                            )}
                        </React.Fragment>
                    ))}
                </Typography>
            )}
            {props.position === 'left' && (
                <React.Fragment>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        {props.mentor === MentorEnum.Ron && (
                            <img component="img" style={{ width: '43px' }} src={RonHead} />
                        )}
                        {props.mentor === MentorEnum.Lin && (
                            <img component="img" style={{ width: '43px' }} src={LinHead} />
                        )}
                        {props.mentor === MentorEnum.Abe && (
                            <img component="img" style={{ width: '43px' }} src={AbeHead} />
                        )}
                        {props.mentor === MentorEnum.Bell && (
                            <img component="img" style={{ width: '43px' }} src={BellHead} />
                        )}
                        {props.mentor === MentorEnum.Solina && (
                            <img component="img" style={{ width: '43px' }} src={SolinaHead} />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        style={{
                            paddingLeft: '25px',
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}>
                        <div style={{ position: 'relative' }}>
                            <CalloutLeft
                                style={{
                                    position: 'relative',
                                    top: '-7px',
                                    marginRight: '-7px',
                                    height: '12px' /* other properties */,
                                }}
                            />
                        </div>
                        <Typography
                            style={{
                                borderRadius: 10,
                                width: '194px',
                                paddingBottom: '20px',
                                background: 'white',
                                color: 'var(--Color-Burn, #4D4D4D)',
                                fontFamily: 'Nunito',
                                fontSize: 11,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                letterSpacing: 0.41,
                                padding: '3px',
                                minHeight: '51px',
                                marginLeft: '1px',
                            }}>
                            {textWithGapsForDistractors.map((part, index) => (
                                <React.Fragment key={index}>
                                    {part}
                                    {index < textWithGapsForDistractors.length - 1 && (
                                        <span className={classes.underscoreReplacement}> </span>
                                    )}
                                </React.Fragment>
                            ))}
                            {props.translation !== '' && (
                                <div
                                    style={{
                                        color: 'var(--Gray-1, #8E8E93)',
                                        marginTop: '10px',
                                    }}>
                                    {props.translation}
                                </div>
                            )}
                        </Typography>
                    </Grid>
                </React.Fragment>
            )}
            {props.position === 'right' && (
                <React.Fragment>
                    <Grid
                        item
                        xs={9}
                        style={{
                            padding: '2px',
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}>
                        <Typography
                            style={{
                                borderRadius: 10,
                                width: '194px',
                                paddingBottom: '20px',
                                background: 'white',
                                color: 'var(--Color-Burn, #4D4D4D)',
                                fontFamily: 'Nunito',
                                fontSize: 11,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                letterSpacing: 0.41,
                                minHeight: '51px',
                                padding: '3px',
                                marginLeft: '1px',
                            }}>
                            {textWithGapsForDistractors.map((part, index) => (
                                <React.Fragment key={index}>
                                    {part}
                                    {index < textWithGapsForDistractors.length - 1 && (
                                        <span className={classes.underscoreReplacement}> </span>
                                    )}
                                </React.Fragment>
                            ))}
                            {props.translation !== '' && (
                                <div
                                    style={{
                                        color: 'var(--Gray-1, #8E8E93)',
                                        marginTop: '10px',
                                    }}>
                                    {props.translation}
                                </div>
                            )}
                        </Typography>
                        <div style={{ position: 'relative' }}>
                            <CalloutRight
                                style={{
                                    position: 'relative',
                                    top: '-7px',
                                    marginLeft: '-7px',
                                    height: '12px' /* other properties */,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        {props.mentor === MentorEnum.Ron && (
                            <img component="img" style={{ width: '43px' }} src={RonHead} />
                        )}
                        {props.mentor === MentorEnum.Lin && (
                            <img component="img" style={{ width: '43px' }} src={LinHead} />
                        )}
                        {props.mentor === MentorEnum.Abe && (
                            <img component="img" style={{ width: '43px' }} src={AbeHead} />
                        )}
                        {props.mentor === MentorEnum.Bell && (
                            <img component="img" style={{ width: '43px' }} src={BellHead} />
                        )}
                        {props.mentor === MentorEnum.Solina && (
                            <img component="img" style={{ width: '43px' }} src={SolinaHead} />
                        )}
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
}

DialogueStageUtterance.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(DialogueStageUtterance)
