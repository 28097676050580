import React, { useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './_state/helpers'
import PublicLayout from './Layouts/PublicLayout'
import privateRoutes from 'routes/privateRoutes'
import publicRoutes from 'routes/publicRoutes'
import PrivateRoute from 'routes/PrivateRoute'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Offline, Online } from 'react-detect-offline'
import tinycolor from 'tinycolor2'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import Notifier from './Components/Notifier'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { snackbarActions } from './_state/actions/snackbar.actions'
import { makeStyles } from '@material-ui/core'
import localeNorData from 'messages/l10n/nb.json'
import localeEnData from 'messages/l10n/en.json'
import localeDeData from 'messages/l10n/de.json'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'assets/react_dates_overrides.css'
import 'react-datasheet/lib/react-datasheet.css'
import { CapeeshColors } from './assets/ColorPalette'
import GlobalLessonPreview from './Components/GlobalLessonPreview'

const getCurrentTheme = (organization) => {
    const pathName = window.location.pathname

    let primaryColor = CapeeshColors.Primary600
    // if (pathName === "/" || pathName == '') {
    //     primaryColor = CapeeshColors.OffWhite;
    // }
    // else
    if (pathName.startsWith('/creator')) {
        primaryColor = CapeeshColors.Purple
    } else if (pathName.startsWith('/admin')) {
        primaryColor = CapeeshColors.Blue
    }
    let secondaryColor = tinycolor(primaryColor).complement().toHexString()
    // let primaryColor = '#FF5159';
    let lightTextColor = '#FFFFFF'
    let darkTextColor = '#232B35'
    // let secondaryColor = "#2278CF";
    let primaryTextColor = lightTextColor
    let secondaryTextColor = lightTextColor

    // if (organization.myOrganizations && organization.selectedOrganization) {
    //     const currentOrganization = organization.myOrganizations.find(x => x.id === organization.selectedOrganization);
    //     primaryColor = currentOrganization?.themePrimaryColor ? currentOrganization.themePrimaryColor : primaryColor;
    //     secondaryColor = tinycolor(primaryColor).complement().toHexString();
    // }

    const primaryTextColorReadability = tinycolor.readability(primaryColor, primaryTextColor)
    // if(primaryTextColorReadability < 3.05)
    //     primaryTextColor = darkTextColor;
    // const secondaryTextColorReadability = tinycolor.readability(secondaryColor, secondaryTextColor);
    // if(secondaryTextColorReadability < 3.05)
    //     secondaryTextColor = darkTextColor;

    return createMuiTheme({
        palette: {
            primary: {
                main: primaryColor,
                contrastText: primaryTextColor,
            },
            background: {
                default: CapeeshColors.OffWhite,
            },
        },
        typography: {
            useNextVariants: true,
            fontFamily: [
                'Lato',
                'Rubik',
                'Roboto',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        overrides: {
            MuiTypography: {
                colorTextPrimary: CapeeshColors.DarkText,
                root: {
                    color: CapeeshColors.DarkText,
                },
            },
            MuiButton: {
                contained: {
                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.12)',
                    borderRadius: '10px',
                    padding: '10px 20px',
                    '&:hover': {
                        boxShadow: '0px 8px 5px rgba(0, 0, 0, 0.2)',
                    },
                    '&:disabled': {
                        opacity: 0.5,
                    },
                },
                outlined: {
                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.12)',
                    borderRadius: '10px',
                    padding: '10px 20px',
                    '&:hover': {
                        boxShadow: '0px 8px 5px rgba(0, 0, 0, 0.2)',
                    },
                    '&:disabled': {
                        opacity: 0.5,
                    },
                },
            },
            MuiListSubheader: {
                root: {
                    paddingLeft: '16px !important',
                },
            },
            MuiMenuItem: {
                root: {
                    fontSize: '14px',
                    transition: 'all 150ms linear',
                    '&:hover': {
                        backgroundColor: `${primaryColor} !important`,
                        color: primaryTextColor,
                    },
                },
                // selected: {
                //     backgroundColor: `${primaryColor} !important`,
                //     color: primaryTextColor
                // },
            },
            MuiCard: {
                root: {
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.12)',
                    borderRadius: '10px',
                },
            },
            MuiCardHeader: {
                root: {
                    background: '#FFFFFF00',
                    alignItems: 'normal',
                    maxHeight: '52px',
                },
                title: {
                    fontFamily: 'Rubik',
                    fontWeight: 'medium',
                    fontSize: '24px',
                    lineHeight: '22px',
                    color: '#35323C',
                    height: '30px',
                    margin: '10px 0px 5px 0px',
                },
                subheader: {
                    lineHeight: '22px',
                    fontSize: '18px',
                    color: '#8E8E93',
                },
            },
            MuiCardContent: {
                root: {
                    //background: '#FFFBF2',
                    background: 'white',

                    borderRadius: '5px',
                },
            },
            MuiCardActions: {
                root: {
                    borderTop: 'solid 1px #eee',
                    background: 'white',
                    //background: '#FFFBF2',
                    justifyContent: 'space-between',
                },
            },
            MuiPaper: {
                elevation2: {
                    boxShadow: 'none',
                },
            },
            MuiTabs: {
                root: {
                    borderBottom: '1px solid #e8e8e8',
                },
                indicator: {
                    backgroundColor: primaryColor,
                    height: '3px',
                },
            },
            MuiTab: {
                root: {
                    textTransform: 'initial',
                    transition: 'all 250ms linear',
                    '&:hover': {
                        color: primaryColor,
                        opacity: 1,
                    },
                    '&$selected': {
                        color: primaryColor,
                        fontStyle: 'bold',
                    },
                    '&:focus': {
                        color: primaryColor,
                        fontStyle: 'bold',
                    },
                },
            },
            MuiStepper: {
                root: {
                    backgroundColor: '#ffffff00',
                    iconColor: primaryColor,
                },
            },
            MuiTableHead: {
                root: {
                    background: primaryColor,
                },
            },
            MuiTableSortLabel: {
                root: {
                    color: '#FFFFFF !important',
                },
                icon: {
                    color: '#FFFFFF !important',
                },
            },
            MuiCheckbox: {
                root: {
                    color: 'inherit !important',
                },
            },
            MTableToolbar: {
                root: {
                    backgroundColor: `${primaryColor} !important`,
                    color: primaryTextColor,
                },
            },
            MuiTableCell: {
                root: {
                    padding: '10px !important',
                },
                head: {
                    backgroundColor: 'inherit !important',
                    color: `${primaryTextColor} !important`,
                },
            },
            MuiChip: {
                root: {
                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.12)',
                    borderRadius: '8px',
                    backgroundColor: '#FFFFFF',
                    fontWeight: 'bold',
                },
                deleteIcon: {
                    fill: primaryColor,
                    color: primaryTextColor,
                    '&:hover': {
                        fill: tinycolor(primaryColor).darken(10),
                    },
                },
            },
            MuiExpansionPanel: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '10px !important',
                    backgroundColor: '#EFEFF4',
                },
            },
            MuiExpansionPanelSummary: {
                root: {
                    height: '65px',
                    borderRadius: '10px',
                    backgroundColor: primaryColor,
                    color: primaryTextColor,
                },
                expandIcon: {
                    color: primaryTextColor,
                },
                content: {
                    color: primaryTextColor,
                },
            },
        },
    })
}

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: '#4caf50',
    },
    error: {
        backgroundColor: '#f44336',
    },
    info: {
        backgroundColor: '#2196f3',
    },
    warning: {
        backgroundColor: '#ff9800',
    },
    closeButton: {
        color: '#FFFFFF',
    },
}))

const App = (props) => {
    const { snackbar, dispatch, organization, user } = props
    const snackbarRef = React.createRef()
    const classes = useStyles()

    const [languageWithoutRegionCode, setLanguageWithoutRegionCode] = useState('en')
    const [messages, setMessages] = useState(localeEnData)
    const [showLessonPreview, setShowLessonPreview] = useState(false)

    const [previousOffline, setpreviousOffline] = useState(false)

    const onClickDismiss = (key) => () => {
        snackbarRef.current.closeSnackbar(key)
    }

    const checkAndSetLocalizedLanguages = () => {
        if (props.user?.userDashboardLanguageId === 1 && languageWithoutRegionCode !== 'en') {
            setLanguageWithoutRegionCode('en')
            setMessages(localeEnData)
        }

        if (props.user?.userDashboardLanguageId === 3 && languageWithoutRegionCode !== 'nb') {
            setLanguageWithoutRegionCode('nb')
            setMessages(localeNorData)
        }

        if (props.user?.userDashboardLanguageId === 6 && languageWithoutRegionCode !== 'de') {
            setLanguageWithoutRegionCode('de')
            setMessages(localeDeData)
        }
    }

    useEffect(() => {
        checkAndSetLocalizedLanguages()
    }, [props.user.userDashboardLanguageId])

    useEffect(() => {
        props.clearSnackbar()

        if (props.user?.userDashboardLanguageId) {
            checkAndSetLocalizedLanguages()
        } else {
            let language =
                (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

            let browserlanguageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
            if (browserlanguageWithoutRegionCode === 'de') {
                setLanguageWithoutRegionCode('de')
                setMessages(localeDeData)
            } else if (browserlanguageWithoutRegionCode === 'no' || browserlanguageWithoutRegionCode === 'nb') {
                setLanguageWithoutRegionCode('de')
                setMessages(localeDeData)
            }
        }
    }, [])

    useEffect(() => {
        document.addEventListener('unityLessonPreviewHideShowToggle', () => {
            setShowLessonPreview(!showLessonPreview)
        })
    }, [])

    const handleOnChange = (event) => {
        if (!event) {
            setpreviousOffline(true)
        }
        if (event && previousOffline) {
            // to get a new instance of the preview to make sure it works, reload the page when coming back from offline
            window.location.reload()
        }
    }

    return (
        <IntlProvider locale={languageWithoutRegionCode} messages={messages} textComponent="span">
            <MuiThemeProvider theme={getCurrentTheme(organization)}>
                <SnackbarProvider
                    ref={snackbarRef}
                    action={(key) => (
                        <IconButton onClick={onClickDismiss(key)} className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    )}
                    classes={{
                        variantSuccess: classes.success,
                        variantError: classes.error,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Notifier />
                    <Online onChange={handleOnChange}>
                        <ConnectedRouter history={history}>
                            <GlobalLessonPreview
                                isOpen={showLessonPreview}
                                closeDialog={() => setShowLessonPreview(false)}
                            />
                            <Switch>
                                {privateRoutes.map((route, key) => {
                                    return (
                                        <PrivateRoute
                                            exact
                                            path={route.path}
                                            component={route.component}
                                            render={(props) => route.component(...props)}
                                            key={key}
                                            title={'Capeesh - ' + route.id}
                                        />
                                    )
                                })}
                                {publicRoutes.map((route, key) => {
                                    return (
                                        <PublicLayout
                                            exact
                                            path={route.path}
                                            component={route.component}
                                            render={(props) => route.component(...props)}
                                            key={key}
                                            title={'Capeesh - ' + route.id}
                                        />
                                    )
                                })}
                            </Switch>
                        </ConnectedRouter>
                    </Online>
                    <Offline>
                        <FormattedMessage
                            id="globalwords"
                            defaultMessage="Your computer is not currently connected to the internet."
                        />
                    </Offline>
                </SnackbarProvider>
            </MuiThemeProvider>
        </IntlProvider>
    )
}

App.propTypes = {}

const mapStateToProps = (state) => {
    const { alert, snackbar, organization, user } = state
    return {
        alert,
        snackbar,
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSnackbar: () => dispatch(snackbarActions.clearSnackbar()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
