export const voiceTypeSearch = (type, testOptions) => {
    let voiceTypeFound = -1
    switch (type) {
        case 'robot':
            testOptions.forEach((to) => {
                if (to.textAudioUrl) {
                    if (to.textAudioUrl?.includes('snippet') && to.audioPlayType !== 0) {
                        voiceTypeFound = 1
                    }
                }
            })
            break
        case 'human':
            testOptions.forEach((to) => {
                if (to.textAudioUrl) {
                    if (to.textAudioUrl?.includes('file') && to.audioPlayType !== 0) {
                        voiceTypeFound = 1
                    }
                }
            })
            break
        case 'none':
            testOptions.forEach((to) => {
                if (to.audioPlayType === 0 || to.textAudioUrl === undefined) {
                    voiceTypeFound = 1
                }
            })
            break
    }
    return voiceTypeFound
}

export const isNodeExitsOccupied = (nodeId, nodes) => {
    // todo - remove backgroundimage url from testimage stage
    let found = -2
    nodes.forEach((node) => {
        if (nodeId === node.nodeId) {
            node.options.forEach((option, index) => {
                if (found < 0) {
                    if (option.nextScenarioNodeId) found = -1
                    else found = index
                }
            })
        }
    })
    return found
}
