import React, { useState } from 'react'

import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    playIcon: {
        color: 'black',
        fontSize: 36,
        cursor: 'pointer',
    },
}))

const AudioPlayerMini = (props) => {
    const classes = useStyles()

    const onPlayButtonClicked = () => {
        props.onPlayRequest(props.textAudioUrl)
        props.onPlayVoice(props.textAudioUrl)
    }
    return (
        <React.Fragment>
            {props.audioPlaying && props.currentAudioUrl === props.textAudioUrl ? (
                props.isAudioDownloading ? (
                    <CircularProgress className={classes.circularProgress} />
                ) : (
                    <StopIcon className={classes.playIcon} onClick={() => onPlayButtonClicked()} />
                )
            ) : (
                <PlayArrowIcon className={classes.playIcon} onClick={() => onPlayButtonClicked()} />
            )}
        </React.Fragment>
    )
}

AudioPlayerMini.propTypes = {}

const mapToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(AudioPlayerMini)
