import React from 'react'
import { connect } from 'react-redux'

const ImageMatcher = (props) => {
    return <div> RND IMAGE SEACH REMOVED</div>
}

ImageMatcher.propTypes = {}

const mapToProps = (state) => {
    return {
        randd: state.randd,
        organization: state.organization,
        lesson: state.lesson,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(ImageMatcher)
