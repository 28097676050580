import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { auth } from './auth.reducer'
import { user } from './user.reducer'
import { organization } from './organization.reducer'
import { topic } from './topic.reducer'
import { metadata } from './metadata.reducer'
import { snackbar } from './snackbar.reducer'
import { usergroup } from './usergroup.reducer'
import { translator } from './translator.reducer'
import { lesson } from './lesson.reducer'
import { randd } from './randd.reducer'
import { courseGroup } from './coursegroup.reducer'
import { overall } from './overall.reducer'
import { autoCourseCreation } from './autocoursecreation.reducer'

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        auth,
        user,
        organization,
        topic,
        metadata,
        snackbar,
        usergroup,
        translator,
        lesson,
        randd,
        overall,
        courseGroup,
        autoCourseCreation,
    })
export default createRootReducer
