import React, { useState, useRef, useEffect } from 'react'
import { Typography, TextField, makeStyles, CircularProgress } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import AutoCourseSuggestLessonDetailsQuizView from '../AutoCourseCreateContentEditMode/AutoCourseSuggestLessonDetailsQuizView'
import AutoCourseSuggestLessonDetailsComplianceView from '../AutoCourseCreateContentEditMode/AutoCourseSuggestLessonDetailsComplianceView'
import AutoCourseSuggestLessonDetailsSimulation from '../AutoCourseCreateContentEditMode/AutoCourseSuggestLessonDetailsSimulation'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { v4 as uuidv4 } from 'uuid'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import Zoom from '@mui/material/Zoom'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import PreviewLessonClapper from '../AutoCourseCreateLanguage/Components/PreviewLessonClapper'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
    topicCard: {
        display: 'flex',
        padding: '20px',
        boxShadow: 'none',
    },
    topicCardAction: {
        right: '15px',
        height: 'fit-content',
        margin: 'auto',
    },
    editable: {
        border: '2px solid transparent',
        transition: 'all 0.3s ease',
        '&:focus, &:hover, &$active': {
            border: '2px solid #AAC8E',
        },
    },
}))

const ACCLessonCardSimplifiedImproved = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    const [focusedAttribute, setFocusedAttribute] = useState(null)
    const focusedAttributeRef = useRef()
    focusedAttributeRef.current = focusedAttribute
    const [focusedText, setFocusedText] = useState('')
    const focusedTextRef = useRef()
    focusedTextRef.current = focusedText
    const [openGenericDialog, setOpenGenericDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogMessage, setDialogMessage] = useState('')
    const [dialogExistingText, setDialogExistingText] = useState('')
    const [dialogType, setDialogType] = useState(null)
    const [hoveredGrid, setHoveredGrid] = useState(null)
    const [makeLessonButtonText, setMakeLessonButtonText] = useState('Create Lesson')

    useEffect(() => {
        if (props.lessonSuggestionDto.createdLessonId) {
            setMakeLessonButtonText('Recreate Lesson')
        } else {
            setMakeLessonButtonText('Create Lesson')
        }
    }, [props.lessonSuggestionDto])

    const handleMouseEnter = (id, snapshot) => {
        setHoveredGrid(id)
    }

    const handleMouseLeave = () => {
        setHoveredGrid(null)
    }

    const [newQuestion, setNewQuestion] = useState({
        questionId: '',
        conceptId: '',
        correctAnswer: '',
        incorrectAnswer1: '',
        incorrectAnswer2: '',
        incorrectAnswer3: '',
        question: '',
    })

    const classes = useStyles()

    const handleWriteQuizQuestion = (element) => {
        setOpenGenericDialog(true)
        setDialogMessage('Write a question and the answer. Also add at least one incorrect answer.')
        setDialogTitle('Add a new quiz question')
        setDialogType('quiz')
        setFocusedAttribute('quizQuestion')
    }

    const handleUpdateLessonSuggestionDto = (lessonPlanId, attribute, text, shouldRefreshLessonDetails = false) => {
        props.onUpdateLessonSuggestionDto(lessonPlanId, attribute, text, shouldRefreshLessonDetails)
    }

    const handleReorderQuiz = (newOrder) => {
        setOpenGenericDialog(false)
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, 'quizReorder', newOrder)
    }

    const handleOnClick = () => {
        if (props.isDragging === false) {
            props.onClick(props.lessonSuggestionDto.lessonPlanId)
        }
    }
    const stopEvent = (event) => {
        event.stopPropagation()
        event.preventDefault()
    }

    const handleCloseModal = () => {
        setOpenGenericDialog(false)
        setDialogExistingText('')
        setNewQuestion({ conceptId: '', correctAnswer: '', incorrectAnswer1: '', incorrectAnswer2: '' })
    }

    const handleRemoveConcept = (e, lessonPlanId, concept) => {
        e.stopPropagation()
        props.onRemoveConcept(lessonPlanId, concept.conceptId)
    }

    const handleRemoveLesson = (e) => {
        e.stopPropagation()
        props.onRemoveLesson(
            props.lessonSuggestionDto.lessonPlanId,
            props.lessonSuggestionDto.lessonName,
            props.lessonPlanDto
        )
    }

    const handleSaveModal = () => {
        setOpenGenericDialog(false)
        let tempNewQuestion = newQuestion
        let guid = uuidv4()
        tempNewQuestion.questionId = guid

        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, focusedAttributeRef.current, newQuestion)
    }

    const handleMakeLesson = (event) => {
        event.stopPropagation()

        if (props.lessonPlansId && props.lessonSuggestionDto.lessonPlanId) {
            props.onLessonFromLessonPlan(props.lessonPlansId, props.lessonSuggestionDto.lessonPlanId)
        }
    }

    const handleCanPlayLessonButton = () => {
        if (props.lessonSuggestionDto.lessonSubType === 'Simulation') {
            if (props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.plot === '') return CapeeshColors.Gray5
            if (props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.roleAi === '') return CapeeshColors.Gray5
            if (props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.roleLearner === '')
                return CapeeshColors.Gray5
        }
        if (
            props.autoCourseCreation.isGeneratingNameAndDescriptionForLessonPlanIds?.includes(
                props.lessonSuggestionDto.lessonPlanId
            )
        )
            return CapeeshColors.Gray5
        if (props.lessonSuggestionDto.concepts.length == 0) return CapeeshColors.Gray5
        if (
            props.lessonSuggestionDto.lessonSubType === 'Normal' &&
            props.lessonSuggestionDto.lessonPlanConfigurationDto?.configurationType === 'Traditional'
        ) {
            if (
                !(
                    props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises?.length > 0 ||
                    props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises === false
                )
            )
                return CapeeshColors.Gray5
        }
        if (
            props.lessonSuggestionDto.lessonSubType === 'Normal' &&
            props.lessonSuggestionDto.lessonPlanConfigurationDto?.configurationType === 'Quiz'
        ) {
            if (!(props.lessonSuggestionDto?.lessonPlanConfigurationDto?.quizQuestions?.length > 0)) {
                return CapeeshColors.Gray5
            }

            return CapeeshColors.Green
        }

        return CapeeshColors.Green
    }

    const handleCanPlayLesson = () => {
        if (props.lessonSuggestionDto.lessonSubType === 'Simulation') {
            if (props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.plot === '') return true
            if (props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.roleAi === '') return true
            if (props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.roleLearner === '') return true
        }
        if (
            props.autoCourseCreation.isGeneratingNameAndDescriptionForLessonPlanIds?.includes(
                props.lessonSuggestionDto.lessonPlanId
            )
        )
            return true
        if (props.lessonSuggestionDto.concepts.length == 0) return true
        if (
            props.lessonSuggestionDto.lessonSubType === 'Normal' &&
            props.lessonSuggestionDto.lessonPlanConfigurationDto?.configurationType === 'Traditional'
        ) {
            if (
                !(
                    props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises?.length > 0 ||
                    props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises === false
                )
            )
                return true
        }
        if (
            props.lessonSuggestionDto.lessonSubType === 'Normal' &&
            props.lessonSuggestionDto.lessonPlanConfigurationDto?.configurationType === 'Quiz'
        ) {
            if (!(props.lessonSuggestionDto?.lessonPlanConfigurationDto?.quizQuestions?.length > 0)) {
                return true
            }

            return false
        }
    }

    const handlePreviewLesson = (event) => {
        event.stopPropagation()
        if (props.lessonSuggestionDto.createdLessonId?.length > 0) {
            let numberOfLessons = props.lessonSuggestionDto.createdLessonId.length
            let lastCustomLessonId = props.lessonSuggestionDto.createdLessonId[numberOfLessons - 1]
            props.onPreviewLessson(lastCustomLessonId)
        }
    }

    const handleTextChangeQuizQuestion = (attribute, event) => {
        let newQuestionId = newQuestion.questionId

        switch (attribute) {
            case 'question':
                setNewQuestion({ ...newQuestion, question: event.target.value })
                break
            case 'correctAnswer':
                setNewQuestion({ ...newQuestion, correctAnswer: event.target.value })
                break
            case 'incorrectAnswer1':
                setNewQuestion({ ...newQuestion, incorrectAnswer1: event.target.value })
                break
            case 'incorrectAnswer2':
                setNewQuestion({ ...newQuestion, incorrectAnswer2: event.target.value })
                break
            case 'incorrectAnswer3':
                setNewQuestion({ ...newQuestion, incorrectAnswer3: event.target.value })
                break
        }
    }

    return (
        <React.Fragment>
            <div
                onClick={handleOnClick}
                style={{
                    width: 'auto',
                    borderRadius: '5px',
                    background: 'white',
                    border: '1px #D7DDE4 solid',
                    marginBottom: props.isLastInTopic === true ? '10px' : '20px',
                    padding: '10px',
                    maxHeight:
                        props.selectedLesson?.lessonPlanId === props.lessonSuggestionDto.lessonPlanId
                            ? '1000px'
                            : '240px',
                }}>
                <Dialog open={openGenericDialog} onClose={handleCloseModal} onClick={stopEvent}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                        {dialogType === 'quiz' && (
                            <React.Fragment>
                                <TextField
                                    label="Question"
                                    multiline
                                    defaultValue={dialogExistingText}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => handleTextChangeQuizQuestion('question', e)}
                                />
                                <TextField
                                    label="Correct answer"
                                    multiline
                                    defaultValue={dialogExistingText}
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => handleTextChangeQuizQuestion('correctAnswer', e)}
                                />
                                <TextField
                                    label="Incorrect answer 1"
                                    multiline
                                    defaultValue={dialogExistingText}
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => handleTextChangeQuizQuestion('incorrectAnswer1', e)}
                                />

                                <TextField
                                    label="Incorrect answer 2"
                                    multiline
                                    defaultValue={dialogExistingText}
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => handleTextChangeQuizQuestion('incorrectAnswer2', e)}
                                />
                                <TextField
                                    label="Incorrect answer 3"
                                    multiline
                                    defaultValue={dialogExistingText}
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => handleTextChangeQuizQuestion('incorrectAnswer3', e)}
                                />
                            </React.Fragment>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} style={{ textTransform: 'none' }}>
                            Cancel
                        </Button>
                        <Button onClick={handleSaveModal} style={{ textTransform: 'none' }}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Zoom in={true}>
                    <Grid
                        container
                        onMouseEnter={() => setIsFocus(true)}
                        onMouseLeave={() => setIsFocus(false)}
                        style={{
                            cursor:
                                props.selectedLesson?.lessonPlanId !== props.lessonSuggestionDto.lessonPlanId
                                    ? 'pointer'
                                    : '',
                        }}>
                        <Grid item xs={12} style={{ cursor: 'pointer' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '28px',
                                            height: 28,
                                            borderRadius: 28 / 2,
                                            backgroundColor:
                                                props.lessonSuggestionDto.lessonType === 'Introduction'
                                                    ? '#15D0BC'
                                                    : props.lessonSuggestionDto.lessonType === 'Repetition'
                                                      ? '#A0439F'
                                                      : '#FF7A3B',
                                            marginRight: 5,
                                            textAlign: 'center',
                                            color: 'white',
                                            fontSize: '18.781px',
                                            fontFamily: 'Nunito',
                                            fontWeight: 700,
                                            lineHeight: '19.824px',
                                        }}>
                                        {props.lessonNumber}
                                    </div>
                                    <Typography component="div">
                                        <span
                                            style={{
                                                marginLeft: '8px',
                                                color: 'var(--Gray-1, #8E8E93)',
                                                textAlign: 'center',
                                                fontFamily: 'Rubik',
                                                fontSize: 20,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                letterSpacing: 0.41,
                                                marginRight: '12px',
                                            }}>
                                            {props.lessonSuggestionDto.lessonType}
                                        </span>
                                        <span
                                            style={{
                                                color: '#000',
                                                textAlign: 'center',
                                                fontFamily: 'Rubik',
                                                fontSize: 20,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                letterSpacing: 0.41,
                                            }}>
                                            {props.lessonSuggestionDto.lessonName}
                                        </span>

                                        {props.lessonSuggestionDto.lessonSubType === 'Normal' &&
                                            props.lessonSuggestionDto.lessonPlanConfigurationDto?.configurationType ===
                                                'Traditional' &&
                                            !(
                                                props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises
                                                    ?.length > 0 ||
                                                props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises ===
                                                    false
                                            ) && (
                                                <Typography
                                                    style={{
                                                        display: 'inline',
                                                        color: 'var(--Dark-Text, #35323C)',
                                                        textAlign: 'left',
                                                        fontFamily: 'Nunito',
                                                        fontSize: 16,
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        marginLeft: '10px',
                                                    }}>
                                                    <i>The lesson contains no stages.</i>
                                                </Typography>
                                            )}
                                        {props.lessonSuggestionDto.lessonSubType === 'Simulation' &&
                                        !(
                                            props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto.plot !== ''
                                        ) ? (
                                            <Typography
                                                style={{
                                                    display: 'inline',
                                                    color: 'var(--Dark-Text, #35323C)',
                                                    textAlign: 'left',
                                                    fontFamily: 'Nunito',
                                                    fontSize: 16,
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    marginLeft: '10px',
                                                }}>
                                                <i>Please choose a plot.</i>
                                            </Typography>
                                        ) : (
                                            !(
                                                props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto
                                                    ?.roleLearner !== '' &&
                                                props.lessonSuggestionDto.lessonPlanSimulationConfigurationDto
                                                    ?.roleAi !== ''
                                            ) &&
                                            props.lessonSuggestionDto.lessonSubType === 'Simulation' && (
                                                <Typography
                                                    style={{
                                                        display: 'inline',
                                                        color: 'var(--Dark-Text, #35323C)',
                                                        textAlign: 'left',
                                                        fontFamily: 'Nunito',
                                                        fontSize: 16,
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        marginLeft: '10px',
                                                    }}>
                                                    <i>Choose a role for the learner and AI.</i>
                                                </Typography>
                                            )
                                        )}
                                        {props.lessonSuggestionDto.lessonSubType === 'Normal' &&
                                            props.lessonSuggestionDto.lessonPlanConfigurationDto?.configurationType ===
                                                'Quiz' &&
                                            !(
                                                props.lessonSuggestionDto?.lessonPlanConfigurationDto?.quizQuestions
                                                    ?.length > 0 ||
                                                props.lessonSuggestionDto?.lessonPlanConfigurationDto?.quizQuestions ===
                                                    false
                                            ) && (
                                                <Typography
                                                    style={{
                                                        display: 'inline',
                                                        color: 'var(--Dark-Text, #35323C)',
                                                        textAlign: 'left',
                                                        fontFamily: 'Nunito',
                                                        fontSize: 16,
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        marginLeft: '10px',
                                                    }}>
                                                    <i>The quiz has no questions.</i>
                                                </Typography>
                                            )}

                                        {props.autoCourseCreation.isGeneratingNameAndDescriptionForLessonPlanIds?.includes(
                                            props.lessonSuggestionDto.lessonPlanId
                                        ) && (
                                            <React.Fragment>
                                                <CircularProgress
                                                    style={{
                                                        marginLeft: '10px',
                                                        color: 'grey',
                                                        height: '18px',
                                                        width: '18px',
                                                    }}
                                                />
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {props.lessonSuggestionDto.createdLessonId && (
                                        <PreviewLessonClapper onClickFunction={handlePreviewLesson} />
                                    )}

                                    <IconButton
                                        onClick={(e) => handleRemoveLesson(e)}
                                        //onMouseDown={(event) => handlePlayClick()}
                                        sx={{
                                            fontSize: '2px',
                                            display: 'center',
                                            alignItems: 'left',
                                            justifyContent: 'center',
                                            height: '2px',
                                            color: '#8E8E93',
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            style={{ marginLeft: '40px', marginTop: '5px', marginBottom: '3px' }}>
                            <Droppable
                                droppableId={'listLesson' + props.lessonSuggestionDto.lessonPlanId}
                                direction="horizontal">
                                {(provided, snapshotDrop) => (
                                    <div
                                        {...provided.droppableProps}
                                        style={{ display: 'flex' }}
                                        ref={provided.innerRef}>
                                        <Grid container direction="row" style={{ flexWrap: 'nowrap' }}>
                                            <React.Fragment>
                                                {props.isDragging === true && (
                                                    <Draggable
                                                        key={'lesson' + props.lessonSuggestionDto.lessonPlanId}
                                                        draggableId={'lesson' + props.lessonSuggestionDto.lessonPlanId}
                                                        index={0}>
                                                        {(provided, snapshot) => (
                                                            <Grid
                                                                item
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <Grid
                                                                    onMouseEnter={() =>
                                                                        handleMouseEnter(
                                                                            props.lessonSuggestionDto.lessonPlanId,
                                                                            snapshotDrop
                                                                        )
                                                                    }
                                                                    onMouseLeave={handleMouseLeave}
                                                                    item
                                                                    style={{
                                                                        border: '2px dashed lightgrey',
                                                                        borderRadius: 5,
                                                                        width: snapshotDrop.isDraggingOver
                                                                            ? '210px'
                                                                            : '120px',
                                                                        height: snapshotDrop.isDraggingOver
                                                                            ? '48px'
                                                                            : '38px',
                                                                        transition: 'height 0.3s ease',
                                                                        background: 'var(--Off-white, #F8F8F8)',
                                                                        padding: '6px',
                                                                        paddingLeft: '7px',
                                                                        paddingRight: '0px',
                                                                        marginRight: '10px',
                                                                    }}></Grid>
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                )}

                                                {props.lessonSuggestionDto.concepts.length === 0 ? (
                                                    <React.Fragment>
                                                        {props.isDragging === false && (
                                                            <Typography
                                                                style={{
                                                                    color: 'var(--Dark-Text, #35323C)',
                                                                    textAlign: 'left',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: 16,
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                }}>
                                                                No teaching material added. Drag and drop it into this
                                                                lesson.
                                                            </Typography>
                                                        )}
                                                        <Grid style={{ width: '100px', height: '80px' }}></Grid>
                                                    </React.Fragment>
                                                ) : (
                                                    props.lessonSuggestionDto.concepts.map((item, index) => (
                                                        <Grid
                                                            item
                                                            style={{
                                                                minHeight: '38px',
                                                                borderRadius: 16,
                                                                background: 'var(--White, #FFF)',
                                                                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                                                                padding: '6px',
                                                                paddingLeft: '16px',
                                                                paddingRight: '16px',
                                                                marginRight: '8px',
                                                                border: '1px #D7DDE4 solid',
                                                            }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    alignSelf: 'center',
                                                                }}>
                                                                <Typography
                                                                    style={{
                                                                        color: 'var(--Dark-Text, #35323C)',
                                                                        textAlign: 'center',
                                                                        fontFamily: 'Nunito',
                                                                        fontSize: 16,
                                                                        fontStyle: 'normal',
                                                                        fontWeight: 400,
                                                                    }}>
                                                                    {props.selectedLesson?.lessonPlanId !==
                                                                        props.lessonSuggestionDto.lessonPlanId &&
                                                                    props.lessonSuggestionDto.concepts.length > 1
                                                                        ? item.text.length > 35 ||
                                                                          props.lessonSuggestionDto.concepts.length > 3
                                                                            ? item.text.substring(
                                                                                  0,
                                                                                  (80 -
                                                                                      props.lessonSuggestionDto.concepts
                                                                                          .length *
                                                                                          3) /
                                                                                      props.lessonSuggestionDto.concepts
                                                                                          .length
                                                                              ) + '...'
                                                                            : item.text
                                                                        : item.text}
                                                                    {!!!props.isDragging && (
                                                                        <IconButton
                                                                            onClick={(e) =>
                                                                                handleRemoveConcept(
                                                                                    e,
                                                                                    props.lessonSuggestionDto
                                                                                        .lessonPlanId,
                                                                                    item
                                                                                )
                                                                            }
                                                                            //onMouseDown={(event) => handlePlayClick()}
                                                                            sx={{
                                                                                fontSize: '2px',
                                                                                display: 'center',
                                                                                alignItems: 'left',
                                                                                justifyContent: 'center',
                                                                                height: '2px',
                                                                                color: '#8E8E93',
                                                                                width: '4px',
                                                                                marginLeft: '4px',
                                                                            }}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Typography>
                                                            </div>
                                                            <div
                                                                style={{ backgroundColor: '#EFEFF4', width: '41px' }}
                                                                sx={{ float: 'left' }}
                                                            />
                                                        </Grid>
                                                    ))
                                                )}
                                            </React.Fragment>
                                        </Grid>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Grid>
                        {props.selectedLesson?.lessonPlanId === props.lessonSuggestionDto.lessonPlanId && (
                            <React.Fragment>
                                <Grid container onClick={stopEvent}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            padding: '30px',
                                            paddingTop: '10px',
                                            maxHeight: '490px',
                                            overflowY: 'auto',
                                            alignContent: 'flex-start',
                                        }}>
                                        <Grid xs={12}>
                                            {props.lessonSuggestionDto.lessonSubType === 'Simulation' && (
                                                <AutoCourseSuggestLessonDetailsSimulation
                                                    onSuggestScenario={props.onSuggestScenario}
                                                    isScenarioGenerating={props.isScenarioGenerating}
                                                    onUpdateLessonSuggestionDto={handleUpdateLessonSuggestionDto}
                                                    onMakeLesson={props.onMakeLesson}
                                                    selectedLesson={props.lessonSuggestionDto}
                                                />
                                            )}
                                            {props.lessonSuggestionDto.lessonSubType === 'Normal' &&
                                                props.lessonSuggestionDto.lessonPlanConfigurationDto
                                                    ?.configurationType === 'Traditional' && (
                                                    <AutoCourseSuggestLessonDetailsComplianceView
                                                        onDeleteQuizQuestion={props.onDeleteQuizQuestion}
                                                        onAddNewStages={props.onAddNewStages}
                                                        onReorderQuiz={handleReorderQuiz}
                                                        onUpdateStagePlotSelection={props.onUpdateStagePlotSelection}
                                                        isDialogueGenerating={props.isDialogueGenerating}
                                                        onSuggestQuiz={props.onSuggestQuiz}
                                                        onGetStageDetails={props.onGetStageDetails}
                                                        isScenarioGenerating={props.isScenarioGenerating}
                                                        onMakeLesson={props.onMakeLesson}
                                                        isQuizGenerating={props.isQuizGenerating}
                                                        onUpdateLessonSuggestionDto={handleUpdateLessonSuggestionDto}
                                                        onChangeSelectedStage={props.onChangeSelectedStage}
                                                        selectedStage={props.selectedStage}
                                                        selectedLesson={props.lessonSuggestionDto}
                                                        onWriteQuizQuestion={handleWriteQuizQuestion}
                                                    />
                                                )}
                                            {props.lessonSuggestionDto.lessonSubType === 'Normal' &&
                                                props.lessonSuggestionDto.lessonPlanConfigurationDto
                                                    ?.configurationType === 'Quiz' && (
                                                    <React.Fragment>
                                                        <AutoCourseSuggestLessonDetailsQuizView
                                                            selectedLesson={props.lessonSuggestionDto}
                                                            onReorderQuiz={handleReorderQuiz}
                                                            onSuggestQuiz={props.onSuggestQuiz}
                                                            isQuizGenerating={props.isQuizGenerating}
                                                            onWriteQuizQuestion={handleWriteQuizQuestion}
                                                            onUpdateLessonSuggestionDto={
                                                                handleUpdateLessonSuggestionDto
                                                            }
                                                            onDeleteQuizQuestion={props.onDeleteQuizQuestion}
                                                        />
                                                    </React.Fragment>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Zoom>
                <div style={{ display: 'flex', justifyContent: 'right', padding: 6 }}>
                    {props.lessonSuggestionDto?.lessonPlanId && (
                        <Button
                            onClick={(event) => handleMakeLesson(event)}
                            disabled={handleCanPlayLesson()}
                            style={{
                                textTransform: 'none',
                                fontSize: '11px',
                                background: handleCanPlayLessonButton(), //(props.autoCourseCreation.isGeneratingNameAndDescriptionForLessonPlanIds?.includes(props.lessonSuggestionDto.lessonPlanId) || props.lessonSuggestionDto.concepts.length == 0 || !(props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises.length > 0 || props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises === false)) ? CapeeshColors.Gray5: CapeeshColors.Green,
                                color: 'white',
                                fontFamily: 'Rubik',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                padding: '10px',
                                lineHeight: '12px',
                                marginRight: '15px',
                                cursor:
                                    props.autoCourseCreation.isGeneratingNameAndDescriptionForLessonPlanIds?.includes(
                                        props.lessonSuggestionDto.lessonPlanId
                                    ) ||
                                    props.lessonSuggestionDto?.concepts?.length == 0 ||
                                    !(
                                        props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises?.length > 0 ||
                                        props.lessonSuggestionDto?.lessonPlanConfigurationDto?.exercises === false
                                    )
                                        ? 'default'
                                        : 'pointer',
                            }}>
                            {props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(
                                props.lessonSuggestionDto.lessonPlanId
                            ) ? (
                                <CircularProgress style={{ color: 'white', height: '11px', width: '11px' }} />
                            ) : (
                                makeLessonButtonText
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

ACCLessonCardSimplifiedImproved.propTypes = {}

const mapStateToProps = (state) => {
    return {
        languages: state.metadata.metadata.languages,
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCLessonCardSimplifiedImproved)
