import { AudioFieldType, ScenarioNodeType } from './ScenarioConstants'
import { FileReferenceAudioUsageType, FileReferenceImageUsageType } from './FileReferenceConstants'

export const FileReferenceAudioUsageTypeFromAudioFieldType = (audioFieldType) => {
    switch (audioFieldType) {
        case AudioFieldType.Option:
            return FileReferenceAudioUsageType.TextAudio
        case AudioFieldType.BackgroundAudioUrl:
            return FileReferenceAudioUsageType.BackgroundAudio
        case AudioFieldType.SfxAudioUrl:
            return FileReferenceAudioUsageType.SfxAudio
        case AudioFieldType.TestOption:
            return FileReferenceAudioUsageType.TextAudio
        default:
            return FileReferenceAudioUsageType.NotDefined
    }
}

export const GetFileReferenceImageUsageTypeForNode = (nodeType) => {
    switch (nodeType) {
        case ScenarioNodeType.Brief:
            return FileReferenceImageUsageType.BriefStageBackground
        case ScenarioNodeType.TestImage:
            return FileReferenceImageUsageType.TestImage
        case ScenarioNodeType.TestDefinition:
            return FileReferenceImageUsageType.TestImage
        case ScenarioNodeType.MultiChoice:
            return FileReferenceImageUsageType.ListenStageBackground
        case ScenarioNodeType.ListenMultiChoice:
            return FileReferenceImageUsageType.ListenStageBackground
        case ScenarioNodeType.Debrief:
            return FileReferenceImageUsageType.BriefStageBackground
        case ScenarioNodeType.Listen:
            return FileReferenceImageUsageType.ListenStageBackground
        case ScenarioNodeType.Speak:
            return FileReferenceImageUsageType.SpeakStageBackground
        case ScenarioNodeType.BranchingMultiChoice:
            return FileReferenceImageUsageType.SpeakStageBackground
        case ScenarioNodeType.Watch:
            return FileReferenceImageUsageType.WatchStageBackground
        case ScenarioNodeType.Instruction:
            return FileReferenceImageUsageType.TestImage
    }
    return FileReferenceImageUsageType.NotDefined
}
