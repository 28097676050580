import { asyncRequest } from './asyncRequest'

export const metadataConstants = {
    GET_METADATA: new asyncRequest('GET_METADATA'),
}

export const metadataActions = {
    getMetadata() {
        return {
            type: metadataConstants.GET_METADATA.REQUEST,
            payload: {},
        }
    },
}
