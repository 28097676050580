import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Input, Typography, Card } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { ScenarioNodeType, ScenarioInteractiveElementType } from '../../../Logic/ScenarioConstants'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import Switch from '@mui/material/Switch'
import Select from '@material-ui/core/Select'
import MenuItem from '@mui/material/MenuItem'
import { getFlagAssetForLanguageId } from '../../../Logic/FlagUnicodeLogic'

import EditorState from 'draft-js'

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: '#8E8E93',
        fontSize: 14,
        fontColor: '#8E8E93',
    },
    body: {
        fontSize: 18,
        color: '#8E8E93',
        fontColor: '#8E8E93',
    },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
    ruleCardTextField: {
        marginBottom: '10px',
    },
    outerLayer: {
        marginBottom: '20px',
    },
    table: {
        minWidth: 150,
    },
    tableHead: {
        background: '#F8F8F8',
    },
    nodeTitle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '30px',
        lineHeight: '22px',
        paddingBottom: '8px',
        color: '#35323C',
    },
    button: {
        backgroundColor: '#143349',
        fontColor: 'white',
        color: 'white',
    },
}))

const RuleCardRule = (props) => {
    const classes = useStyles()
    const [value, setValue] = useState('')
    const [selectionStart, setSelectionStart] = React.useState(-1)
    const [selectionEnd, setSelectionEnd] = React.useState(-1)
    const [isSelection, setIsSelection] = React.useState(false)
    const [highlighter, setHighlighter] = React.useState([])
    const [firstWordInRange, setFirstWordInRange] = React.useState([0, 0])
    const [firstSelectedAlternative, setFirstSelectedAlternative] = React.useState(null)
    const [isDialogueOpen, setIsDialogueOpen] = React.useState(false)
    const [currentRuleLanguageType, setCurrentRuleLanguageType] = React.useState(1)

    const [prevStartSelection, setPrevStartSelection] = React.useState(-1)
    const [prevEndSelection, setPrevEndSelection] = React.useState(-1)

    const editor = useRef(null)
    const [editorPlaceHolder, setEditorPlaceHolder] = React.useState(props.placeholder)

    useEffect(() => {
        setHighlighter(props.onHighlightIterator(props.testOption.alternatives))
    }, [props.testOption.alternatives])

    useEffect(() => {
        if (props.testOption.text === '') {
            setSelectionStart(-1)
            setSelectionEnd(-1)
        }
    }, [props.testOption.text])

    const handleDeleteRule = (testOptionId) => {
        props.onDeleteRule(testOptionId)
    }

    const preventPropagation = (event) => {
        event.stopPropagation()
        event.preventDefault()
    }

    const checkExistingAlternative = (alternatives, selectionStart, selectionEnd) => {
        let isAvailableWord = true

        alternatives.forEach((alternative) => {
            let firstAlternative = alternative
            let foundFirstAlternativeWithIndex = false
            alternatives.forEach((alt) => {
                if (!!!foundFirstAlternativeWithIndex && alt.startIndex === alternative.startIndex) {
                    firstAlternative = alt
                    foundFirstAlternativeWithIndex = true
                }
            })
            if (
                selectionStart >= firstAlternative.startIndex &&
                selectionStart <= firstAlternative.startIndex + firstAlternative.text.length
            ) {
                isAvailableWord = false
            }
            if (
                selectionStart < firstAlternative.startIndex &&
                selectionEnd > firstAlternative.startIndex + firstAlternative.text.length &&
                selectionEnd - selectionStart >
                    firstAlternative.startIndex + firstAlternative.text.length - firstAlternative.startIndex
            ) {
                isAvailableWord = false
            }
            if (
                selectionEnd > firstAlternative.startIndex &&
                selectionEnd < firstAlternative.startIndex + firstAlternative.text.length
            ) {
                isAvailableWord = false
            }
        })

        return isAvailableWord
    }

    const firstInRange = (localStartIndex, localEndIndex) => {
        let firstInRangeStartIndex = localStartIndex
        let firstInRangeEndIndex = localEndIndex
        let isFound = false
        props.testOption.alternatives.forEach((alternative) => {
            if (
                !!!isFound &&
                localStartIndex >= alternative.startIndex &&
                localEndIndex <= alternative.startIndex + alternative.text.length
            ) {
                firstInRangeStartIndex = alternative.startIndex
                firstInRangeEndIndex = alternative.startIndex + alternative.text.length
                isFound = true
            }
        })
        return [firstInRangeStartIndex, firstInRangeEndIndex]
    }

    const primaryAlternatives = (alternative) => {
        let found = false
        let foundFirstAlternativeWithIndex = false
        let firstAlternative = alternative
        let highlightArray = []
        let addedStartIndeces = []
        props.testOption.alternatives.forEach((alternative) => {
            if (!!!addedStartIndeces.includes(alternative.startIndex)) {
                highlightArray.push(alternative)
                addedStartIndeces.push(alternative.startIndex)
            }
        })
        highlightArray.forEach((highlight) => {
            if (alternative.textSnippetId === highlight.textSnippetId) {
                found = true
            }
        })
        return found
    }

    const isValidAlternativeToDisplay = (alternative) => {
        let foundFirstAlternativeWithIndex = false
        let firstAlternative = alternative
        let highlightArray = []
        let addedStartIndeces = []
        props.testOption.alternatives.forEach((alternative) => {
            if (!!!addedStartIndeces.includes(alternative.startIndex)) {
                highlightArray.push(alternative)
                addedStartIndeces.push(alternative.startIndex)
            }
        })
        highlightArray.forEach((alt) => {
            if (foundFirstAlternativeWithIndex === false && alt.startIndex === alternative.startIndex) {
                firstAlternative = alt
                foundFirstAlternativeWithIndex = true
            }
        })
        return (
            alternative.textSnippetId !== firstAlternative.textSnippetId &&
            selectionStart >= firstAlternative.startIndex &&
            selectionStart <= firstAlternative.startIndex + firstAlternative.text.length
        )
    }

    const handleChangeCorrect = (alternative) => {
        props.onToggleRuleCardDistractorCorrect(props.testOption.testOptionId, alternative)
    }

    const handleDeleteAlternativeDistractor = (alternative) => {
        props.onDeleteAlternativeDistractor(props.testOption.testOptionId, alternative)
    }

    const handleAddDistractor = (event) => {
        event.stopPropagation()
        event.preventDefault()

        let fixedSelectionStart = selectionStart
        let text = props.node.testOptions[props.index].text.substring(selectionStart, selectionEnd)
        const numberOfPrefixWhiteSpace = text.search(/\S/)

        if (numberOfPrefixWhiteSpace > 0) {
            const removdPrefixWhiteSpaceText = text.slice(numberOfPrefixWhiteSpace, text.length)
            fixedSelectionStart += numberOfPrefixWhiteSpace
            const trimmedText = removdPrefixWhiteSpaceText.trim()
            props.onAddAlternative(fixedSelectionStart, trimmedText, props.testOption)
        } else {
            props.onAddAlternative(selectionStart, text.trim(), props.testOption)
        }

        // setIsSelection(false)
    }

    const onChangeRuleCardDistractor = (value, alternative) => {
        props.onChangeRuleCardDistractor(value, alternative, props.testOption)
    }

    const updateWritingStageDistractorLetters = (event) => {
        // the keyboard test's distractors are presented to the author as making additional letters on the keyboard that are clickable.
        // for instance allowing the s key to be pressed to see if the learner types run or runs.
        // Therefore does not need the existing table component. Have replaced with an (ugly) textfield entry component, that will need to be made to look nice later on

        let textToAdd = event.target.value
        event.stopPropagation()
        event.preventDefault()
        let highlightArray = []
        let addedStartIndeces = []
        props.testOption.alternatives.forEach((alternative) => {
            if (!!!addedStartIndeces.includes(alternative.startIndex)) {
                highlightArray.push(alternative)
                addedStartIndeces.push(alternative.startIndex)
            }
        })
        let foundPrimaryMatch = false
        highlightArray.forEach((alternative) => {
            if (
                !!!foundPrimaryMatch &&
                selectionStart >= alternative.startIndex &&
                selectionStart <= alternative.startIndex + alternative.text.length
            ) {
                foundPrimaryMatch = true
                props.onAddKeyboardTestDistractorLetters(alternative.startIndex, textToAdd, props.testOption)
            }
        })
    }

    const getDistractorText = (shouldSplitByNewline) => {
        let text =
            props.testOption.alternatives.find(
                (e) => selectionStart >= e.startIndex && selectionEnd <= e.startIndex + e.text.length
            ) &&
            props.testOption.alternatives.find(
                (e) =>
                    selectionStart >= e.startIndex &&
                    selectionEnd <= e.startIndex + e.text.length &&
                    primaryAlternatives(e)
            ).text
        if (shouldSplitByNewline === true) return text.split('').join(' ') + ' '
        return text
    }

    const getWritingDistractor = () => {
        let text = ''
        props.testOption.alternatives.forEach((alt) => {
            if (isValidAlternativeToDisplay(alt)) {
                text = alt.text
            }
        })
        return text
    }

    const cleanString = (event, selection) => {
        // Remove newlines and replace with a space
        // const noNewlines = event.replace(/(?:\r\n|\r|\n)/g, ' ')

        // Remove double spaces
        // const noDoubleSpaces = noNewlines.replace(/\s\s+/g, ' ')

        // Trim the string
        //const trimmed = noDoubleSpaces.trim();

        const customEvent = {
            target: {
                value: event,
                selectionStart: selection?.start,
                selectionEnd: selection?.end,
            },
        }
        return customEvent
    }

    const addDistractor = (textToAdd, event) => {
        event.stopPropagation()
        event.preventDefault()
        let highlightArray = []
        let addedStartIndeces = []
        props.testOption.alternatives.forEach((alternative) => {
            if (!!!addedStartIndeces.includes(alternative.startIndex)) {
                highlightArray.push(alternative)
                addedStartIndeces.push(alternative.startIndex)
            }
        })
        let foundPrimaryMatch = false
        highlightArray.forEach((alternative) => {
            if (
                !!!foundPrimaryMatch &&
                selectionStart >= alternative.startIndex &&
                selectionStart <= alternative.startIndex + alternative.text.length
            ) {
                foundPrimaryMatch = true
                props.onAddAlternative(alternative.startIndex, textToAdd, props.testOption)
                //(type, text, index, testOptionId
                // {() => props.onAddDistractor("rulecardMultichoice", newAnswer, props.testOption.testOptionId)}
                // to do - change onaddalternative name to onMakeGap or something, as it is used for marking existing text
                // to do - use the alternative start index above and "" for the text
            }
        })
    }

    const handleHightlightAreaClicked = () => {
        if (editor) {
            setEditorPlaceHolder('')
            editor.current.focus()
        }
    }

    const highlighterOnChange = (event, selection) => {
        let hasNewSelection = true
        let emptySelection = false
        let singleSelection = false

        if (selection?.start === 0 && selection?.end === 0) {
            hasNewSelection = false
            emptySelection = true
        }

        if (selection?.start - selection?.end === 0 && !emptySelection) {
            hasNewSelection = false
            singleSelection = true
        }
        const trimmedEvent = cleanString(event, selection)
        const hasChanges = trimmedEvent.target.value !== value

        if (selection?.start === prevStartSelection && selection?.end === prevEndSelection) {
            hasNewSelection = false
        } else {
            setPrevStartSelection(selection?.start)
            setPrevEndSelection(selection?.end)
        }

        setValue(trimmedEvent.target.value)

        if (hasNewSelection || hasChanges) {
            props.onRulecardTextChange(
                props.testOption.testOptionId,
                trimmedEvent.target.value,
                trimmedEvent.target.selectionStart,
                trimmedEvent.target.selectionEnd
            )
        }

        if (!emptySelection) {
            setSelectionStart(trimmedEvent.target.selectionStart) // should this be removed?
            setSelectionEnd(trimmedEvent.target.selectionEnd)
        }

        if (trimmedEvent.target.selectionEnd - trimmedEvent.target.selectionStart > 0) {
            setIsSelection(
                checkExistingAlternative(
                    props.testOption.alternatives,
                    trimmedEvent.target.selectionStart,
                    trimmedEvent.target.selectionStart
                )
            )
        } else {
            setIsSelection(false)
        }
        setPrevStartSelection(selection?.start)
        setPrevEndSelection(selection?.end)
    }

    return (
        <div className={classes.outerLayer} onClick={(event) => {}} onBlur={(event) => {}}>
            <div>
                <Dialog
                    open={isDialogueOpen}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">{"Remove the rule's distractors?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Changing to Reading will remove the distractors for this rule. Continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(event) => {
                                setIsDialogueOpen(false)
                                event.stopPropagation()
                                event.preventDefault()
                                props.onDistractorTypeChange('4', props.testOption.testOptionId)
                            }}
                            color="primary">
                            Yes
                        </Button>
                        <Button
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setIsDialogueOpen(false)
                            }}
                            color="primary">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Grid
                container
                onClick={(event) => {
                    preventPropagation(event)
                }}>
                <Grid item xs={12} sm={12} md={12} className={classes.displayDistractors}>
                    <div style={{}}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={11} sm={10} md={11}>
                                <div
                                    style={{
                                        border: '1px solid lightgrey',
                                        boxSizing: 'border-box',
                                        borderRadius: '5px',
                                        backgroundColor: 'white',
                                    }}>
                                    <Grid container alignItems="center" spacing="2">
                                        <Grid
                                            item
                                            style={{
                                                height: '100%',
                                                marginLeft: props.allowLanguageSelection === true ? '15px' : '15px',
                                            }}>
                                            <span>
                                                {props.allowLanguageSelection === true ? (
                                                    <Select
                                                        disableEnforceFocus
                                                        sx={{
                                                            width: '70px',
                                                            height: '60px',
                                                            boxShadow: 'none',
                                                            outline: 'none',
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: 'none',
                                                                outline: 'none',
                                                            },
                                                        }}
                                                        disableUnderline
                                                        value={
                                                            props.testOption.textLanguageId === props.languageId ? 1 : 0
                                                        }
                                                        autoWidth
                                                        onChange={(e) => props.changeLanguage(e)}>
                                                        <MenuItem disabled={!props.canEditLesson} value={0}>
                                                            <img
                                                                src={getFlagAssetForLanguageId(props.l1Id)}
                                                                width="25"
                                                                alt={'L1'}
                                                            />
                                                        </MenuItem>
                                                        <MenuItem disabled={!props.canEditLesson} value={1}>
                                                            <img
                                                                src={getFlagAssetForLanguageId(props.languageId)}
                                                                width="25"
                                                                alt={'L2'}
                                                            />
                                                        </MenuItem>
                                                    </Select>
                                                ) : (
                                                    <img
                                                        src={getFlagAssetForLanguageId(props.languageId)}
                                                        width="25"
                                                        alt={'Language'}
                                                    />
                                                )}
                                            </span>
                                        </Grid>
                                        <Grid item>
                                            <div
                                                style={{
                                                    width: '350px',
                                                    marginTop: editorPlaceHolder ? '20px' : '10px',
                                                    marginBottom: '0px',
                                                    minHeight:
                                                        Math.floor(
                                                            (props.node.testOptions[props.index].text.length / 60) * 40
                                                        ) +
                                                        15 +
                                                        'px',
                                                    boxShadow: 'none',
                                                    outline: 'none',
                                                }}
                                                onClick={handleHightlightAreaClicked}>
                                                <HighlightWithinTextarea
                                                    stripPastedStyles="true"
                                                    disabled={!props.canEditLesson}
                                                    multiline={true}
                                                    ref={editor}
                                                    placeholder={editorPlaceHolder}
                                                    value={
                                                        props.node.testOptions[props.index].text
                                                            ? props.node.testOptions[props.index].text
                                                            : ''
                                                    }
                                                    highlight={highlighter.length > 0 ? highlighter : null}
                                                    onChange={highlighterOnChange}
                                                    onBlur={(event) => {
                                                        event.stopPropagation()
                                                    }}
                                                    autoCorrect={'false'}
                                                    autoComplete={'false'}
                                                    autoCapitalize={'false'}
                                                    readOnly={!props.canEditLesson}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                {props.localScenarioNodeType !== ScenarioNodeType.TestImage && (
                                    <IconButton aria-label="delete" style={{}}>
                                        <DeleteIcon
                                            onClick={(event) => {
                                                handleDeleteRule(props.testOption.testOptionId)
                                            }}
                                            fontSize="small"
                                        />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    {isSelection && props.testOption.text !== '' && (
                        <React.Fragment>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                }}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(event) => {
                                        handleAddDistractor(event)
                                    }}
                                    style={{
                                        textTransform: 'none',
                                        fontFamily: 'Lato',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        background: '#FFFFFF',
                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '10px',
                                        top: '-5px',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        paddingLeft: '10px',
                                        paddingRight: '20px',
                                    }}>
                                    {props.addAlternativeText || 'Make into multiple choice test'}
                                </Button>
                            </div>
                        </React.Fragment>
                    )}
                </Grid>
                {props.isInFocus && //TODO: remove props.isInFocus, not handled anyway
                    props.testOption.alternatives.some(
                        (e) =>
                            selectionStart >= e.startIndex &&
                            selectionEnd <= e.startIndex + e.text.length &&
                            primaryAlternatives(e)
                    ) && (
                        <React.Fragment
                            onClick={(event) => {
                                preventPropagation(event)
                            }}>
                            {props.scenarioInteractiveElementType ===
                            ScenarioInteractiveElementType.FillInBlankWriting ? (
                                <React.Fragment
                                    onClick={(event) => {
                                        preventPropagation(event)
                                    }}>
                                    <Grid
                                        item
                                        xs={12}
                                        onClick={(event) => {
                                            preventPropagation(event)
                                        }}>
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: '#8E8E93',
                                                fontFamily: 'Lato',
                                                marginBottom: '10px',
                                                marginTop: '20px',
                                            }}>
                                            Only the letters needed to spell "{getDistractorText(false)}" are available
                                            on the keyboard. To add other letters (to make the test more difficult),
                                            type them in below.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        onClick={(event) => {
                                            preventPropagation(event)
                                        }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                //width: '300px',
                                                boxShadow: 'none',
                                                //flex: 1,
                                                //fontSize: 36,
                                                color: 'black',
                                                fontFamily: 'Lato',
                                                marginTop: '10px',
                                            }}>
                                            Available keyboard letters
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        onClick={(event) => {
                                            preventPropagation(event)
                                        }}
                                        style={{ justifyContent: 'center' }}>
                                        <Grid container>
                                            <Grid item style={{ marginRight: '5px' }}>
                                                {' '}
                                                <KeyboardIcon sx={{ fontSize: 36 }} />{' '}
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        fontStyle: 'normal',
                                                        fontWeight: 'bold',
                                                        fontSize: '22px',
                                                        lineHeight: '18px',
                                                        marginTop: '7px',
                                                        color: '#35323C',
                                                    }}>
                                                    {getDistractorText(true)}
                                                </Typography>
                                            </Grid>
                                            {props.testOption?.alternatives?.map(
                                                (alternative, index) =>
                                                    isValidAlternativeToDisplay(alternative) &&
                                                    index > 0 && (
                                                        <Grid item>
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontFamily: 'Lato',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 'bold',
                                                                    fontSize: '22px',
                                                                    lineHeight: '18px',
                                                                    color: '#35323C',
                                                                    marginTop: '7px',
                                                                    marginLeft: '3px',
                                                                }}>
                                                                {alternative.text.split('').join(' ')}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={'12'}
                                        onClick={(event) => {
                                            preventPropagation(event)
                                        }}>
                                        <TextField
                                            multiline
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                width: '300px',
                                            }}
                                            style={{ backgroundColor: 'white' }}
                                            value={getWritingDistractor()}
                                            disabled={!props.canEditLesson}
                                            onChange={(e) => updateWritingStageDistractorLetters(e)}
                                            placeholder="Add extra keyboard letters"
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Card
                                        style={{
                                            marginTop: '5px',
                                            paddingBottom: '40px',
                                            backgroundColor: '#E5E5EA',
                                        }}
                                        onClick={(event) => {
                                            preventPropagation(event)
                                        }}>
                                        <Typography
                                            variant="body1"
                                            style={{
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontWeight: 'bold',
                                                fontSize: '26px',
                                                lineHeight: '18px',
                                                paddingBottom: '18px',
                                                paddingLeft: '15px',
                                                paddingTop: '15px',
                                                color: '#35323C',
                                                backgroundColor: '#F8F8F8',
                                            }}>
                                            {props.testOption.alternatives.find(
                                                (e) =>
                                                    selectionStart >= e.startIndex &&
                                                    selectionEnd <= e.startIndex + e.text.length
                                            ) &&
                                                props.testOption.alternatives.find(
                                                    (e) =>
                                                        selectionStart >= e.startIndex &&
                                                        selectionEnd <= e.startIndex + e.text.length &&
                                                        primaryAlternatives(e)
                                                ).text}
                                        </Typography>
                                        <TableContainer>
                                            <Table
                                                style={{
                                                    width: '520px',
                                                    paddingBottom: '20px',
                                                }}
                                                size="small">
                                                <TableHead className={classes.tableHead}>
                                                    <TableRow>
                                                        <StyledTableCell
                                                            style={{
                                                                color: 'red',
                                                                fontColor: 'red',
                                                                backgroundColor: 'red',
                                                            }}>
                                                            {' '}
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontFamily: 'Lato',
                                                                    fontStyle: 'normal',
                                                                    fontSize: '12px',
                                                                    lineHeight: '18px',
                                                                    color: '#8E8E93',
                                                                }}>
                                                                Answers to choose from
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontFamily: 'Lato',
                                                                    fontStyle: 'normal',
                                                                    fontSize: '12px',
                                                                    lineHeight: '18px',
                                                                    color: '#8E8E93',
                                                                }}>
                                                                Is answer correct
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody
                                                    style={{
                                                        backgroundColor: '#E5E5EA',
                                                    }}>
                                                    <TableRow
                                                        style={{
                                                            backgroundColor: '#E5E5EA',
                                                        }}>
                                                        <TableCell>
                                                            <Typography className={classes.input}>
                                                                {props.testOption.alternatives.find(
                                                                    (e) =>
                                                                        selectionStart >= e.startIndex &&
                                                                        selectionEnd <= e.startIndex + e.text.length
                                                                ) &&
                                                                    props.testOption.alternatives.find(
                                                                        (e) =>
                                                                            selectionStart >= e.startIndex &&
                                                                            selectionEnd <=
                                                                                e.startIndex + e.text.length &&
                                                                            primaryAlternatives(e)
                                                                    ).text}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControlLabel
                                                                control={<Switch checked="true" disabled="true" />}
                                                                label=""
                                                            />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    {props.testOption?.alternatives?.map(
                                                        (alternative, index) =>
                                                            //   selectionStart >= alternative.startIndex && selectionEnd <= (alternative.startIndex + alternative.text.length) &&
                                                            // find the FIRST alternative where the current cursor position is greater than start index and less than start index + length
                                                            isValidAlternativeToDisplay(alternative) &&
                                                            index > 0 && (
                                                                <TableRow
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor: '#E5E5EA',
                                                                    }}>
                                                                    <TableCell>
                                                                        <Input
                                                                            value={alternative.text}
                                                                            onChange={(e) =>
                                                                                onChangeRuleCardDistractor(
                                                                                    e.target.value,
                                                                                    alternative
                                                                                )
                                                                            }
                                                                            className={classes.input}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={
                                                                                        alternative.correct === true
                                                                                    }
                                                                                    onClick={(e) =>
                                                                                        handleChangeCorrect(alternative)
                                                                                    }
                                                                                />
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton aria-label="delete" style={{}}>
                                                                            <DeleteIcon
                                                                                onClick={(event) => {
                                                                                    handleDeleteAlternativeDistractor(
                                                                                        alternative
                                                                                    )
                                                                                }}
                                                                                fontSize="small"
                                                                            />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Card>
                                    <Grid item xs={12} sm={12} md={12} className={classes.scenario}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <Button
                                                disabled={!props.canEditLesson}
                                                variant="outlined"
                                                size="small"
                                                onClick={(event) => {
                                                    addDistractor('', event)
                                                }}
                                                style={{
                                                    textTransform: 'none',
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontWeight: 'bold',
                                                    background: '#FFFFFF',
                                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    borderRadius: '10px',
                                                    top: '-20px',
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                }}>
                                                Add other answers
                                            </Button>
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
            </Grid>
        </div>
    )
}

RuleCardRule.propTypes = {}

const mapToProps = (state) => {
    return {
        lesson: state.lesson,
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(RuleCardRule)
