import React from 'react'
import { useNodes, BezierEdge, getBezierPath } from 'react-flow-renderer'
import { getSmartEdge } from '@tisoap/react-flow-smart-edge'

const foreignObjectSize = 40

const onEdgeClick = (evt, id) => {
    // propagation to LessonBuilderController occurs...
}
export default function SmartEdgeWithButtonLabel(props) {
    const { id, sourcePosition, targetPosition, sourceX, sourceY, targetX, targetY, style, markerStart, markerEnd } =
        props

    const nodes = useNodes()

    const myOptions = {
        nodePadding: props.style.pathfindingPaddingIndex,
        gridRatio: 32,
    }

    if (props.data.needsSmartEdge === false) {
        const edgePath = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
            curvature: 0.3, //targetX < sourceX ? 0.5 : 0
        })

        return (
            <>
                <path
                    style={style}
                    className="react-flow__edge-path"
                    d={edgePath}
                    markerEnd={markerEnd}
                    markerStart={markerStart}
                />
                {props.data.showLabels && (
                    <foreignObject
                        width={foreignObjectSize}
                        height={foreignObjectSize}
                        x={sourceX}
                        y={sourceY - 12}
                        className="edgebutton-foreignobject"
                        requiredExtensions="http://www.w3.org/1999/xhtml">
                        <div>
                            <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
                                ×
                            </button>
                        </div>
                    </foreignObject>
                )}
            </>
        )
    } else {
        const getSmartEdgeResponse = getSmartEdge({
            sourcePosition,
            targetPosition,
            sourceX,
            sourceY,
            targetX,
            targetY,
            nodes,
            options: myOptions,
        })

        // If the value returned is null, it means "getSmartEdge" was unable to find
        // a valid path, and you should do something else instead
        if (getSmartEdgeResponse === null) {
            return <BezierEdge {...props} />
        }
        const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse
        return (
            <>
                <path
                    style={style}
                    className="react-flow__edge-path"
                    d={svgPathString}
                    markerEnd={markerEnd}
                    markerStart={markerStart}
                />
                {props.data.showLabels && (
                    <foreignObject
                        width={foreignObjectSize}
                        height={foreignObjectSize}
                        x={sourceX}
                        y={sourceY - 12}
                        className="edgebutton-foreignobject"
                        requiredExtensions="http://www.w3.org/1999/xhtml">
                        <div>
                            <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
                                ×
                            </button>
                        </div>
                    </foreignObject>
                )}
            </>
        )
    }
}
