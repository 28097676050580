import React, { useState } from 'react'
import { Card, Grid } from '@material-ui/core'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import { connect } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MentorLinIcon from '../../../assets/images/MentorLinIcon.png'
import MentorRonIcon from '../../../assets/images/MentorRonIcon.png'
import MentorNoneIcon from '../../../assets/images/MentorNoneIcon.png'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        width: 119.58,
        height: 128.58,
        borderRadius: '8px',
    },
    lin: {
        backgroundColor: '#FF666E',
    },
    ron: {
        backgroundColor: '#44A6EF',
    },
    none: {
        backgroundColor: 'white',
    },
    info: {
        position: 'absolute',
        backgroundColor: 'rgba(10,10,10,0.5)',
        //opacity: 0.5,
        maxWidth: 345,
        borderRadius: 6.52365,
        width: 50,
        height: 29.6,
        left: 18,
        top: 18,
    },
    imageContainer: {
        paddingTop: '7px',
    },
    imageContainerNone: {
        paddingTop: '22px',
        width: '81px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        color: 'white',
    },

    image: {
        height: '100%',
        width: '81px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
    },
}))

const ScenarioScenePreview = (props) => {
    const classes = useStyles()

    const [userPicture, setUserPicture] = useState(null)
    const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null)

    const handleClickedSettingsMenu = (event) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorSettingsMenu(event.currentTarget)
    }

    const handleCloseMenus = (event) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorSettingsMenu(null)
    }

    return (
        <Card className={classes.root} onClick={props.onSelectMentor}>
            <CardActionArea>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <div
                            className={`${classes.imageContainer} ${props.mentor === -1 ? classes.imageContainerNone : props.mentor === 1 ? classes.lin : classes.ron}`}>
                            <CardMedia
                                src={
                                    props.mentor === -1
                                        ? MentorNoneIcon
                                        : props.mentor === 1
                                          ? MentorLinIcon
                                          : MentorRonIcon
                                }
                                className={classes.image}
                                component="img"
                                title="Some title"
                            />
                        </div>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}

ScenarioScenePreview.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(ScenarioScenePreview)
