import axios from 'axios'
import { API_ROOT } from './api-config'

export const makeRanddPostRequest = (urlExtension, data = {}, config) => axios.post(urlExtension, data, config)
export const makePostRequest = (urlExtension, data = {}, config) => axios.post(API_ROOT + urlExtension, data, config)
export const makeGetRequest = (urlExtension, config) => axios.get(API_ROOT + urlExtension, config)
export const makePutRequest = (urlExtension, data = {}, config) => axios.put(API_ROOT + urlExtension, data, config)
export const makeDeleteRequest = (urlExtension, data = {}, config) =>
    axios.delete(API_ROOT + urlExtension, { ...config, data: data })
export const makePatchRequest = (urlExtension, data = {}, config) => axios.patch(API_ROOT + urlExtension, data, config)
