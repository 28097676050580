import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { TutorialSection } from '../../../../Logic/ScenarioConstants'
import GuideCommentsSidebar from '../../../../assets/images/GuideCommentsSidebar.jpg'
import GuideCommentsNotification from '../../../../assets/images/GuideCommentsNotification.png'
import GuideCommentsButton from '../../../../assets/images/GuideCommentsButton.png'

const useStyles = makeStyles((theme) => ({
    playIcon: {
        color: '#eee',
        fontSize: 36,
        marginLeft: '15px',
        //marginRight: '15px',
        cursor: 'pointer',
    },
    leftBox: {
        /* Gray 3 */
        background: '#EFEFEF',
        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
        borderRadius: '20px',
    },
    leftPanel: {
        minWidth: '400px',
    },
    mainContainer: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    rightPaper: {
        /* White */
        //  marginLeft: '20px',
        marginTop: '30px',
        background: '#FFFFFF',
        /* Gray 3 */

        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        borderRadius: '15px',
    },
}))

const TutorialComments = (props) => {
    const classes = useStyles()
    const [selectedSection, setSelectedSection] = useState(TutorialSection.Home)
    const CarouselItem = (props) => {
        return (
            <Grid container>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'left' }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={12}>
                            {tutorialSubHeader(props.item.name)}
                            {tutorialParagraph(props.item.text1)}
                            {tutorialParagraph(props.item.text2)}
                            {tutorialParagraph(props.item.text3)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '5px' }}>
                    <img
                        style={{
                            flex: 1,
                            color: 'black',
                            height: '400px',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}
                        src={props.item.imageAddress}
                    />
                </Grid>
            </Grid>
        )
    }

    const tutorialParagraph = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        marginBottom: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialSubHeader = (text) => {
        return (
            <Typography
                variant="h6"
                style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    marginBottom: '10px',
                    marginTop: '10px',
                }}>
                {text}
            </Typography>
        )
    }

    const tutorialHeader = (text) => {
        return (
            <Typography
                variant="h6"
                style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '32px',
                    marginBottom: '20px',
                }}>
                {text}
            </Typography>
        )
    }

    return (
        <Grid
            container
            style={{
                paddingLeft: '50px',
                paddingTop: '40px',
                paddingRight: '20px',
            }}>
            {tutorialHeader('Adding reviewer comments to a lesson stage')}
            {tutorialParagraph(
                'It is possible to add reviewer comments to each stage of a lesson. All users are able to add and view comments.'
            )}
            {tutorialSubHeader('What are comments used for?')}
            {tutorialParagraph(
                "If a course author or course content quality evaluator has a query or concern regarding a lesson stage's content, they can add a comment to the screen."
            )}
            {tutorialParagraph('Top add or view comments, click the View Comments button')}
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    textAlign: 'center',
                    marginTop: '30px',
                    marginBottom: '30px',
                }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '400px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideCommentsButton}
                />
            </Grid>
            {tutorialParagraph(
                'Clicking View Comments will open the Comments sidebar. Here, we can view, add and delete comments.'
            )}
            {tutorialParagraph(
                'Add a comment by clicking the + button at the bottom right of the sidebar. After adding a comment, it of course begins life as being classed as unresolved. Comments can be resolved by clicking the tick icon.'
            )}
            {tutorialParagraph('Comments are saved automatically - We don’t need to save changes to the stage itself.')}

            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    textAlign: 'center',
                    marginTop: '30px',
                    marginBottom: '30px',
                }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '600px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideCommentsSidebar}
                />
            </Grid>
            {tutorialSubHeader('Identifying lesson stages that contain comments')}
            {tutorialParagraph('We can see at-a-glance which lesson stages have unresolved comments.')}
            {tutorialParagraph(
                'Each stage with an unresolved comment has a notification icon. You can see in the image below that the first Definition Stage has unresolved comments, but none of this other stages do.'
            )}
            {tutorialParagraph(
                'We can also see to the top of the image a notification that shows that the lesson overall has unresolved comments in its stages. This will remain on the lesson stage until the all the stage comments have been resolved.'
            )}

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '30px' }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '600px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideCommentsNotification}
                />
            </Grid>
        </Grid>
    )
}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(TutorialComments)
