import React, { useEffect, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import TopicLessonOrder from 'Views/Private/CreatorTopic/TopicLessonOrder'
import { autoCourseCreationActions, topicActions } from '_state/actions'
import Typography from '@material-ui/core/Typography'
import { lessonActions } from '../../../_state/actions'
import { history } from '_state/helpers'
import CourseSupportedLanguagePane from './Components/CourseSupportedLanguagePane'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'
import CapeeshStyledModal from '../../../Components/CapeeshStyledModal'
import { AutoCourseCreateType } from '../../../Logic/AutoCourseCreationConstants'
import { GlobalEvents } from '../../../assets/GlobalEvents'
import { TopicLessonOrderDisplayType } from '../../../Logic/TopicConstants'
import TopicLessonOrderNewTopic from './Components/TopicLessonOrderEmptyStates/TopicLessonOrderNewTopic'
import TopicLessonOrderHeader from './Components/TopicLessonOrderHeader'
import LessonCreationTypeModal from '../Creator/LessonCreationTypeModal'
import AdjustableModal from '../Creator/AdjustableModal'
import ComponentStepper from '../Creator/ComponentStepper'
import TopicCreationPreviewMockup from '../Creator/TopicCreationPreviewMockup'
import CreateTopicNameAndImage from '../Creator/CreateTopicNameAndImage'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: CapeeshColors.EggShell2,
        backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(225, 225, 221) 1px, transparent 0)',
        backgroundSize: '12px 12px',
        minHeight: 'calc(100vh - 110px)',
    },
    lessonOrderContainer: {
        paddingLeft: '20px',
        paddingRight: '20px',

        [theme.breakpoints.down('lg')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
    topicLessonOrderContainer: {
        paddingLeft: '25px',

        [theme.breakpoints.down('lg')]: {
            paddingLeft: '8px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '25px',
        },
    },
    editTopicContainer: {
        paddingLeft: '200px',
        paddingRight: '200px',

        [theme.breakpoints.down('lg')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '200px',
            paddingRight: '200px',
        },
    },
    titleContainer: {
        paddingLeft: '20px',
        paddingRight: '20px',

        [theme.breakpoints.down('md')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '75px',
            paddingRight: '75px',
        },
    },
    dialogTitle: {
        backgroundColor: '#143349',
        fontColor: 'white',
    },
    previewDialogTitle: {
        fontColor: CapeeshColors.DeepBlue,
    },
    dialogTitleDiv: {
        display: 'flex',
    },
    editTopicHeading: {
        alignSelf: 'stretch',
        color: '#232B35',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    editTopicBase: {
        paddingTop: 32,
    },
}))

const CreatorEditTopic = (props) => {
    const classes = useStyles()
    const { computedMatch, user, metadata, topic, lesson, location, role } = props
    const difficultyFirst = 0
    const [scenarios, setScenarios] = useState([])
    const [showCreateLesson, setShowCreateLesson] = useState(false)
    const [showLanguagesPaneModal, setShowLanguagesPaneModal] = useState(false)

    let tempTopicId = parseInt(computedMatch.params.topicId)
    if (tempTopicId <= 0) {
        tempTopicId = null
    }
    const topicId = tempTopicId

    const courseGroupId = computedMatch.params.courseGroupId
    const [topicData, setTopicData] = useState(null)
    const [courseGroup, setCourseGroup] = useState(null)

    // preview mode
    const unityStagePreviewControllerRef = useRef(null)

    // delete lessons from the list
    const [showDeleteLessonConfirmation, setShowDeleteLessonConfirmation] = useState(false)
    const [customLessonIdToDelete, setCustomLessonIdToDelete] = useState(null)

    // unpublish lesson from the list
    const [showUnpublishLessonConfirmation, setShowUnpublishLessonConfirmation] = useState(false)
    const [customLessonIdToUnpublish, setCustomLessonIdToUnpublish] = useState(null)

    // reset acc confirmation and state handling
    const [showResetACCConfirmation, setShowResetACCConfirmation] = useState(false)
    const [transitionToACCType, setTransitionToACCType] = useState(null)

    const [showAddLanguageDialogue, setShowAddLanguageDialogue] = useState(false)

    // toogle Admin cards
    const [showAdminCardSection, setShowAdminCardSection] = useState(false)

    const [hasFetchTopic, setHasFetchedTopic] = useState(false)

    // edit topic infomration
    const [showEditTopic, setShowEditTopic] = useState(false)
    const [newTopicName, setNewTopicName] = useState('')
    const [newTopicDescription, setNewTopicDescription] = useState('')
    const [newTopicImage, setNewTopicImage] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() => {
        setHasFetchedTopic(false)
        props.getCurrentTopic(computedMatch.params.topicId)
        props.dispatch(lessonActions.getOrganizationLessons(props.organization.selectedOrganization))
    }, [])

    useEffect(() => {
        if (topicId === props.topic.currentTopicId && !props.topic.fetchingCurrentLessonOrder) {
            setHasFetchedTopic(true)
        }
    }, [props.topic.fetchingCurrentLessonOrder, props.topic.loadingCurrentTopic, props.topic.currentTopicId])

    useEffect(() => {
        let topicDataFromDataLayer = props.topic.topics.find((x) => x.id.toString() == topicId)
        if (topicDataFromDataLayer) {
            setTopicData(topicDataFromDataLayer)

            const filteredScenarios = props.lesson.organizationLessons?.filter(
                (x) => x.customLessonTypeName === 'Scenario' && x.languageId === topicDataFromDataLayer.l2Id
            )
            setScenarios(filteredScenarios)
        }
    }, [props.topic.topics])

    useEffect(() => {
        let topicDataFromDataLayer = props.topic.topics.find((x) => x.id.toString() == topicId)
        if (topicDataFromDataLayer) {
            const filteredScenarios = props.lesson.organizationLessons?.filter(
                (x) => x.customLessonTypeName === 'Scenario' && x.languageId === topicDataFromDataLayer.l2Id
            )
            setScenarios(filteredScenarios)
        }
    }, [props.lesson.organizationLessons])

    const handleBackToCourseGroup = () => {
        history.push('/creator/course/group/' + courseGroupId)
    }

    const handleCloseTopicSupportedLanguages = () => {
        setShowLanguagesPaneModal(false)
    }

    const handleOnDragEnd = (items, displayType) => {
        // only resets the published lessons
        console.log('items', items, displayType)
        let updatedArray = []
        items.forEach((element, index) => {
            let newElement = { ...element }
            newElement.index = index
            updatedArray.push(newElement)
        })
        if (displayType === TopicLessonOrderDisplayType.Published) {
            const unpublishedLessonOrderItems = props.topic.lessonOrder.filter((x) => !x.published)
            let index = updatedArray.length
            for (const unpublishedItem of unpublishedLessonOrderItems) {
                unpublishedItem.index = index
                index++
                updatedArray.push(unpublishedItem)
            }
        } else if (displayType === TopicLessonOrderDisplayType.Unpublished) {
            let publishedFirstArray = props.topic.lessonOrder.filter((x) => x.published)
            let index = publishedFirstArray.length
            for (const unpublishedLesosnOrderItem of updatedArray) {
                unpublishedLesosnOrderItem.index = index
                index++
                publishedFirstArray.push(unpublishedLesosnOrderItem)
            }
            updatedArray = publishedFirstArray
        }
        if (updatedArray.length !== props.topic.lessonOrder.length) {
            return
        }
        props.updateLocalLessonOrder(updatedArray, computedMatch.params.topicId, difficultyFirst)
    }

    const handleDetachLessonConfirmed = () => {
        if (!customLessonIdToDelete) {
            return
        }

        let tempNewLessonOrderList = []
        props.topic.lessonOrder.forEach((element, index) => {
            if (customLessonIdToDelete !== element.customLessonId) {
                tempNewLessonOrderList.push(element)
            }
        })
        props.updateLocalLessonOrder(tempNewLessonOrderList, computedMatch.params.topicId, difficultyFirst)

        setShowDeleteLessonConfirmation(false)
    }

    const handleDetachLessonCanceled = () => {
        setCustomLessonIdToDelete(null)
        setShowDeleteLessonConfirmation(false)
    }

    const handleUnpublishLessonConfirmed = () => {
        props.unpublishLesson(customLessonIdToUnpublish, props.organization.selectedOrganization, topicId, true)
        setCustomLessonIdToUnpublish(null)
        setShowUnpublishLessonConfirmation(false)
    }

    const handleUnpublishLessonCanceled = () => {
        setCustomLessonIdToUnpublish(null)
        setShowUnpublishLessonConfirmation(false)
    }

    const handleDetachScenario = (customLessonId) => {
        setCustomLessonIdToDelete(customLessonId)
        setShowDeleteLessonConfirmation(true)
    }
    const handleUnpublishLesson = (customLessonId) => {
        const customLesson = props.topic.lessonOrder.find((x) => x.customLessonId === customLessonId)
        if (customLesson?.published) {
            setCustomLessonIdToUnpublish(customLessonId)
            setShowUnpublishLessonConfirmation(true)
        }
    }

    const handleToggleGateable = (customLessonId) => {
        props.topic.lessonOrder.forEach((element, index) => {
            if (element.customLessonId === customLessonId) {
                element.gateable = !element.gateable
            }
        })

        props.updateLocalLessonOrder(props.topic.lessonOrder, computedMatch.params.topicId, difficultyFirst)
    }

    const handleCreateLesson = (e) => {
        setShowCreateLesson(true)
    }

    const handleEditTopic = () => {
        setNewTopicName(topicData?.name ? topicData.name : '')
        setNewTopicDescription(topicData?.description ? topicData.description : '')
        setSelectedImage(topicData?.imageFileReferenceId ? topicData.imageFileReferenceId : null)

        if (props.courseGroup?.currentCourseGroup?.id === courseGroupId) {
            setCourseGroup(props.courseGroup.currentCourseGroup)
        }

        setShowEditTopic(true)
    }

    const handleSetImageBlobFromHeader = (imageBlob) => {
        let imageResult = {
            type: 'image/jpeg',
            image: { name: topicData?.imageFileReferenceId },
            imageUrl: imageBlob,
            imageFileName: 'placeholder',
            shouldUpload: false,
        }
        console.log('imageResult', imageResult)
        setNewTopicImage(imageResult)
    }
    const handleSaveTopic = () => {
        // update the topic
        let changes = false

        let imageFileReferenceId = topicData?.imageFileReferenceId ? topicData.imageFileReferenceId : null
        console.log('save topic', newTopicImage, selectedImage)
        if (selectedImage && selectedImage !== imageFileReferenceId) {
            changes = true
            imageFileReferenceId = selectedImage
        }
        let topicName = topicData?.name

        if (newTopicName !== topicName) {
            changes = true
            topicName = newTopicName
        }
        let topicDescription = topicData?.description
        if (newTopicDescription !== topicDescription) {
            changes = true
            topicDescription = newTopicDescription
        }
        if (!changes) {
            setShowEditTopic(false)
            return
        }

        props.updateTopic(
            props.organization.selectedOrganization,
            topicData.id,
            topicName,
            topicDescription,
            imageFileReferenceId
        )

        setShowEditTopic(false)
    }
    const handleCloseEditTopicModal = () => {
        setShowEditTopic(false)
        setSelectedImage(null)
        setNewTopicImage(null)
    }

    useEffect(() => {
        console.log('selectedImage', selectedImage)
        console.log('newTopicImage', newTopicImage)
    }, [selectedImage, newTopicImage])

    const handleSaveToServer = () => {
        props.updateLessonOrderForDifficulty(computedMatch.params.topicId, difficultyFirst, props.topic.lessonOrder)
    }

    const handleShowLanguagesPane = () => {
        setShowLanguagesPaneModal(true)
    }
    const handleExportAllTranslations = () => {
        handleExportOfAllTranslations(false)
    }

    const handleExportOfAllTranslations = (ignoreAlreadyTranslated) => {
        const { metadata } = props

        let now = new Date()
        let dateString = now.toISOString().substring(0, 10)
        let l1Ids = []
        let filenames = []

        var downloadTypeName = ignoreAlreadyTranslated ? '_delta_' : '_full_'
        var zipFileName = topicData.name + downloadTypeName + 'translations_' + dateString + '.zip'

        for (const supportedLanguage of topicData.supportedLanguages) {
            if (supportedLanguage.l1Id === topicData.defaultL1Id) {
                continue
            }

            var l1Language = metadata.metadata.languages.find((x) => x.id === supportedLanguage.l1Id)

            if (l1Language) {
                var filename =
                    topicData.name +
                    '_translation_' +
                    l1Language.languageTag +
                    '_' +
                    l1Language.friendlyName +
                    '_' +
                    dateString +
                    '.xlsx'
                l1Ids.push(supportedLanguage.l1Id)
                filenames.push(filename)
            }
        }
        props.exportExcelTopicTranslationForAllLanguages(
            topicData.id,
            l1Ids,
            filenames,
            false,
            false,
            ignoreAlreadyTranslated,
            zipFileName
        )
    }

    const handleExportAllDeltaTranslations = () => {
        handleExportOfAllTranslations(true)
    }

    const handleToggleAttachScenarioCancel = (event) => {
        setShowCreateLesson(false)
    }

    const handleDeleteTopicSupportedL1Id = (l1Id) => {
        //TODO: fill in when deleting topic languages are supported
    }
    const handleAddTopicSupportedL1Id = (l1Id) => {
        props.addSupportedLanguage(topicData.id, l1Id, true)

        if (props.user.organizationRole === 'teacher') {
            setShowAddLanguageDialogue(true)
        }
    }

    const handleTopicCustomLessonTranslationExport = (
        l1Id,
        ignoreAlreadyTranslated,
        noCrossLessonDuplicates,
        translationVersion
    ) => {
        const { metadata } = props

        var now = new Date()
        var dateString = now.toISOString().substring(0, 10)

        var l1Language = metadata.metadata.languages.find((x) => x.id === l1Id)

        let filename = topicData.name + '_translation_' + l1Id + '_' + dateString + '.xlsx'
        if (l1Language) {
            filename =
                topicData.name +
                '_translation_' +
                l1Language.languageTag +
                '_' +
                l1Language.friendlyName +
                '_' +
                dateString +
                '.xlsx'
        }
        var useEnglishTranslationText = false

        props.exportExcelTopicTranslation(
            topicData.id,
            l1Id,
            filename,
            noCrossLessonDuplicates,
            useEnglishTranslationText,
            ignoreAlreadyTranslated,
            translationVersion
        )
    }

    const handleTopicCustomLessonTranslationImport = (l1Id, version) => {
        var input = document.createElement('input')
        input.type = 'file'
        input.onchange = (event) => {
            // getting a hold of the file reference
            var file = event.target.files[0]
            props.importExcelTopicTranslation(topicData.id, l1Id, file, version)
        }
        input.click()
    }

    const handleResetUnityState = async () => {
        if (unityStagePreviewControllerRef.current) {
            await unityStagePreviewControllerRef.current.unloadUnity()
        }
    }

    const handleAddLesson = (customLessonId) => {
        if (!customLessonId) {
            // TODO SHOW ERROR
            return
        }

        props.dispatch(topicActions.attachCustomLessonToTopic(topicId, 0, customLessonId))
    }

    const handleSelectDifficulty = () => {
        //TODO: need to remove from passed in props also
    }

    // const handleToggleAttachScenario = (event) => {
    //     setShowCreateLesson(false)
    //     if (event.length > 0) {
    //         let newItems = []
    //         props.lesson.organizationLessons.map((scenario) => {
    //             event.forEach((selectedScenario) => {
    //                 if (selectedScenario != null && selectedScenario === scenario.id) {
    //                     let found = false
    //                     props.topic.lessonOrder.forEach((item) => {
    //                         if (item.customLessonId === scenario.id) {
    //                             found = true
    //                         }
    //                     })
    //                     if (found === false) {
    //                         let temp = {
    //                             customLessonId: scenario.id,
    //                             tempId: scenario.id,
    //                             content: scenario.name,
    //                             name: scenario.name,
    //                             description: scenario.description,
    //                             topicLessonType: TopicLessonType.Scenario,
    //                             topicLessonTypeName: 'Scenario',
    //                         }
    //                         newItems.push(temp)
    //                     }
    //                 }
    //             })
    //         })
    //         newItems.push(...props.topic.lessonOrder)
    //         props.updateLocalLessonOrder(newItems, computedMatch.params.topicId, 0)
    //     }
    // }

    const goToCreateAiLanguage = () => {
        setTransitionToACCType(AutoCourseCreateType.Language)

        let needsAccReset = checkIfACCNeedsReset(AutoCourseCreateType.Language)
        if (needsAccReset) {
            setShowCreateLesson(false)
            setShowResetACCConfirmation(true)
        } else {
            transitionToAccLanguage()
        }
    }

    const checkIfACCNeedsReset = (autoCourseCreateType) => {
        if (!props.autoCourseCreation?.authorInputMetadata) {
            return false
        }

        if (props.autoCourseCreation.authorInputMetadata.topicId !== topicId) {
            return true
        }

        if (props.autoCourseCreation.authorInputMetadata.autoCourseCreateType !== autoCourseCreateType) {
            return true
        }

        return false
    }

    const handlePublishLesson = (customLessonId) => {
        props.publishTopicLesson(props.organization.selectedOrganization, topicId, 0, customLessonId)
    }
    const handlePublishAllLessons = () => {
        for (const topicLessonOrderItem of props.topic.lessonOrder) {
            if (!topicLessonOrderItem.published && topicLessonOrderItem.canBePublished) {
                props.publishTopicLesson(
                    props.organization.selectedOrganization,
                    topicId,
                    0,
                    topicLessonOrderItem.customLessonId
                )
            }
        }
    }

    const handleCreateLessonManually = () => {
        const queryParams =
            '?createFromTopic=true&topicId=' +
            topicId +
            '&l2Id=' +
            topicData.l2Id +
            '&defaultL1Id=' +
            topicData.defaultL1Id

        let scenarioCreateUrl = ''
        if (courseGroupId && topicId) {
            scenarioCreateUrl =
                '/creator/course/group/' + courseGroupId + '/topic/' + topicId + '/lesson/setup' + queryParams
        } else {
            scenarioCreateUrl = '/creator/topic/list/' + topicId + '/lesson/setup' + queryParams
        }
        history.push(scenarioCreateUrl)
    }

    const handleOpenLessonPreview = (customLessonId) => {
        document.dispatchEvent(GlobalEvents.UnityLessonPreviewHideShowToggle)
        document.dispatchEvent(
            new CustomEvent('initLessonPreviewWithInfo', {
                detail: {
                    customLessonId: customLessonId,
                    l1Id: topicData.defaultL1Id,
                    nodeId: null,
                    clickableSkipButton: true,
                    l2Id: topicData.l2Id,
                },
            })
        )
    }

    const goToCreateAiComplience = () => {
        setTransitionToACCType(AutoCourseCreateType.Complience)

        let needsAccReset = checkIfACCNeedsReset(AutoCourseCreateType.Complience)
        if (needsAccReset) {
            setShowCreateLesson(false)
            setShowResetACCConfirmation(true)
        } else {
            transitionToAccComplience()
        }
    }

    const transitionToAccLanguage = () => {
        const queryParams =
            '?createFromTopic=true&topicId=' +
            topicId +
            '&l2Id=' +
            topicData.l2Id +
            '&defaultL1Id=' +
            topicData.defaultL1Id

        let accUrl = '/creator/course/group/' + courseGroupId + '/topic/' + topicId + '/acc/language/' + queryParams
        history.push(accUrl)
    }

    const transitionToAccComplience = () => {
        const queryParams =
            '?createFromTopic=true&topicId=' +
            topicId +
            '&l2Id=' +
            topicData.l2Id +
            '&defaultL1Id=' +
            topicData.defaultL1Id

        let accUrl = '/creator/course/group/' + courseGroupId + '/topic/' + topicId + '/acc' + queryParams
        history.push(accUrl)
    }

    const resetAutoCourseCreateFromAnotherTopicConfirmed = () => {
        setShowResetACCConfirmation(false)

        props.dispatch(autoCourseCreationActions.accResetAll())
        props.dispatch(autoCourseCreationActions.accResetAllLessonPlans())
        props.dispatch(autoCourseCreationActions.accResetAllSections())

        if (transitionToACCType == AutoCourseCreateType.Language) {
            transitionToAccLanguage()
        }
        if (transitionToACCType === AutoCourseCreateType.Complience) {
            transitionToAccComplience()
        }
    }

    const stepperComponents = [
        {
            component: (
                <CreateTopicNameAndImage
                    l2Id={topicData?.l2Id}
                    setNewTopicName={setNewTopicName}
                    setNewTopicDescription={setNewTopicDescription}
                    setNewTopicImage={setNewTopicImage}
                    newTopicImage={newTopicImage}
                    newTopicName={newTopicName}
                    newTopicDescription={newTopicDescription}
                    setSelectedTopicImage={setSelectedImage}
                />
            ),
            stepCondition: newTopicName.length > 0 && newTopicDescription.length > 0 && newTopicImage,
        },
    ]

    if (!topicData) return <div></div>

    return (
        <div className={classes.main}>
            {showAddLanguageDialogue && (
                <CapeeshStyledModal
                    open={showAddLanguageDialogue}
                    textTitle={'Adding Language'}
                    textLine1={
                        'The language has been added. This process is automatic and will take between 2-10 minutes to finish and be available in the app.'
                    }
                    onButton1={() => setShowAddLanguageDialogue(false)}
                    button1Variant={'outlined'}
                    button1Text={'Ok'}
                    onClose={() => setShowAddLanguageDialogue(false)}
                />
            )}

            {showResetACCConfirmation && (
                <CapeeshStyledModal
                    open={showResetACCConfirmation}
                    textTitle={'Do you want to remove your current AI-assisted project and start again?'}
                    textLine1={'Currently, you can only get AI assistance to work on one project at a time.'}
                    onButton1={() => setShowResetACCConfirmation(false)}
                    onButton2={() => resetAutoCourseCreateFromAnotherTopicConfirmed()}
                    button1Variant={'outlined'}
                    button1Text={'Cancel'}
                    button2Text={'Yes'}
                    onClose={() => setShowResetACCConfirmation(false)}
                />
            )}

            {showDeleteLessonConfirmation && (
                <CapeeshStyledModal
                    open={showDeleteLessonConfirmation}
                    textTitle={'Lesson Detach Confirmation'}
                    textLine1={
                        'Do you want to detach  the lesson? It will not be deleted, but cannot be added to the topic again.'
                    }
                    onButton2={() => handleDetachLessonConfirmed()}
                    onButton1={() => handleDetachLessonCanceled()}
                    button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                    button1Variant={'outlined'}
                    button2Text={<FormattedMessage id="globalwords.detach" defaultMessage="Detach" />}
                    onClose={() => handleDetachLessonCanceled()}
                />
            )}
            {showUnpublishLessonConfirmation && (
                <CapeeshStyledModal
                    open={showUnpublishLessonConfirmation}
                    textTitle={'Lesson Unpublish Confirmation'}
                    textLine1={
                        'Do you want to unpublish the lesson? It will removed from the topic. You can edit and republish from the draft section.'
                    }
                    onButton2={() => handleUnpublishLessonConfirmed()}
                    onButton1={() => handleUnpublishLessonCanceled()}
                    button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                    button1Variant={'outlined'}
                    button2Text={<FormattedMessage id="globalwords.unpublish" defaultMessage="Unpublish" />}
                    onClose={() => handleUnpublishLessonCanceled()}
                />
            )}

            {showLanguagesPaneModal && (
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={showLanguagesPaneModal}
                    onClose={handleCloseTopicSupportedLanguages}>
                    <DialogTitle className={classes.dialogTitle}>
                        <div className={classes.dialogTitleDiv}>
                            <Typography
                                variant="body2"
                                style={{
                                    marginTop: '3px',
                                    boxShadow: 'none',
                                    flex: 1,
                                    fontSize: 36,
                                    color: 'white',
                                    justifyContent: 'center',
                                    paddingLeft: '15px',
                                    textAlign: 'left',
                                    alignItems: 'center',
                                }}>
                                Topic Supported Languages
                            </Typography>
                            <IconButton onClick={() => setShowLanguagesPaneModal(false)} size="medium" color={'white'}>
                                <CancelOutlinedIcon
                                    className={classes.closeBustton}
                                    style={{
                                        fill: 'white',
                                        width: 38,
                                        height: 38,
                                    }}
                                    size="medium"
                                />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <CourseSupportedLanguagePane
                            open={showLanguagesPaneModal}
                            topicSupportedLanguages={topicData.supportedLanguages}
                            topicId={topicData.id}
                            onDeleteL1Id={handleDeleteTopicSupportedL1Id}
                            onAddL1Id={handleAddTopicSupportedL1Id}
                            onExportTranslationForL1Id={handleTopicCustomLessonTranslationExport}
                            onImportTranslationForL1Id={handleTopicCustomLessonTranslationImport}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {showCreateLesson && (
                <LessonCreationTypeModal
                    isOpen={showCreateLesson}
                    onClose={() => setShowCreateLesson(false)}
                    onCreateManual={() => {
                        handleCreateLessonManually()
                    }}
                    onCreateLanguage={() => {
                        goToCreateAiLanguage()
                    }}
                    onCreateKnowledge={() => {
                        goToCreateAiComplience()
                    }}
                />
            )}

            {showEditTopic && (
                <AdjustableModal
                    closeDialog={handleCloseEditTopicModal}
                    isOpen={showEditTopic}
                    leftContent={
                        <div className={classes.editTopicBase}>
                            <div className={classes.editTopicHeading}>EDIT TOPIC</div>
                            <ComponentStepper
                                stepperComponents={stepperComponents}
                                onFinishStepper={handleSaveTopic}
                                onCancel={handleCloseEditTopicModal}
                            />
                        </div>
                    }
                    centerContent={
                        <TopicCreationPreviewMockup
                            language={topicData?.l2Id}
                            courseHeading={courseGroup?.name}
                            courseDescription={courseGroup?.description}
                            topicName={newTopicName}
                            topicDescription={newTopicDescription}
                            topicImage={newTopicImage}
                        />
                    }
                />
            )}

            {/*<CapeeshResponsiveListDialog*/}
            {/*    open={showCreateLesson}*/}
            {/*    dialogTitle="Lesson Library"*/}
            {/*    dialogContentText="Select a lesson to attach to this course."*/}
            {/*    handleCancel={handleToggleAttachScenarioCancel} //TODO: Was not linking to anything*/}
            {/*    handleButton2={handleToggleAttachScenario}*/}
            {/*    handleButton1={handleToggleAttachScenarioCancel}*/}
            {/*    button1Text="Cancel"*/}
            {/*    button1Variant={'outlined'}*/}
            {/*    button2Text="Attach Lesson"*/}
            {/*    list={scenarios?.map((entry) => {*/}
            {/*        const singleObj = {}*/}
            {/*        const nameAndType = entry.name*/}
            {/*        singleObj['id'] = entry.id*/}
            {/*        singleObj['name'] = nameAndType*/}
            {/*        singleObj['invalid'] = entry.languageId !== topicData.l2Id*/}
            {/*        singleObj['invalidReason'] = '(The scenario teaches a different language)'*/}
            {/*        return singleObj*/}
            {/*    })}*/}
            {/*    originList={topic.lessonOrder.map(function (elem) {*/}
            {/*        return { id: elem.customLessonId }*/}
            {/*    })}*/}
            {/*/>*/}

            <TopicLessonOrderHeader
                organization={props.organization}
                topicData={topicData}
                onAddLesson={handleCreateLesson}
                onShowLanguageModal={handleShowLanguagesPane}
                courseGroupId={courseGroupId}
                onBackToCourseGroup={handleBackToCourseGroup}
                showActionButtons={hasFetchTopic && props.topic.lessonOrder.length > 0}
                onCreateNewLesson={() => handleCreateLesson()}
                onEditTopic={() => handleEditTopic()}
                setImageBlob={handleSetImageBlobFromHeader}
            />

            <div style={{ paddingTop: '32px' }}>
                {hasFetchTopic && props.topic.lessonOrder?.length > 0 && (
                    <div>
                        <Grid container className={classes.lessonOrderContainer}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                mb={6}
                                lg={6}
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: '16px',
                                }}>
                                <div
                                    style={{
                                        padding: '32px',
                                    }}>
                                    <TopicLessonOrder
                                        selectedDifficulty={difficultyFirst}
                                        difficulties={props.topic.topicDifficulties}
                                        onDragEnd={handleOnDragEnd}
                                        onDetachScenario={handleDetachScenario}
                                        onToggleGateable={handleToggleGateable}
                                        courseGroupId={courseGroupId}
                                        topicId={topicId}
                                        onSaveToServer={handleSaveToServer}
                                        topicData={topicData}
                                        computedMatch={computedMatch}
                                        role={role}
                                        l2Id={topicData.l2Id}
                                        defaultL1Id={topicData.defaultL1Id}
                                        onPreviewLesson={handleOpenLessonPreview}
                                        dispatch={props.dispatch}
                                        displayType={TopicLessonOrderDisplayType.Unpublished}
                                        showAdminCard={showAdminCardSection}
                                        onPublishLesson={handlePublishLesson}
                                        onPublishAllLessons={handlePublishAllLessons}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} mb={6} lg={6} className={classes.topicLessonOrderContainer}>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: '16px',
                                    }}>
                                    <div
                                        style={{
                                            padding: '32px',
                                        }}>
                                        <TopicLessonOrder
                                            selectedDifficulty={difficultyFirst}
                                            difficulties={props.topic.topicDifficulties}
                                            onDragEnd={handleOnDragEnd}
                                            onDetachScenario={handleUnpublishLesson}
                                            onToggleGateable={handleToggleGateable}
                                            courseGroupId={courseGroupId}
                                            topicId={topicId}
                                            onSaveToServer={handleSaveToServer}
                                            topicData={topicData}
                                            computedMatch={computedMatch}
                                            role={role}
                                            l2Id={topicData.l2Id}
                                            defaultL1Id={topicData.defaultL1Id}
                                            onPreviewLesson={handleOpenLessonPreview}
                                            dispatch={props.dispatch}
                                            displayType={TopicLessonOrderDisplayType.Published}
                                            showAdminCard={showAdminCardSection}
                                            onPublishLesson={handlePublishLesson}
                                            onToggleAdminSection={() => setShowAdminCardSection(!showAdminCardSection)}
                                            showAdminToggle={props.user.organizationRole === 'admin'}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {hasFetchTopic && props.topic.currentTopicEmpty && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TopicLessonOrderNewTopic onCreateNewLesson={() => handleCreateLesson()} />
                    </div>
                )}
            </div>
        </div>
    )
}

CreatorEditTopic.propTypes = {}

const mapToProps = (state) => {
    return {
        organization: state.organization,
        topic: state.topic,
        user: state.user,
        metadata: state.metadata,
        backgroundJobs: state.backgroundJobs,
        auth: state.auth,
        role: state.role,
        lesson: state.lesson,
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentTopic: (topicId) => dispatch(topicActions.getCurrentTopic(topicId)),
        addSupportedLanguage: (topicId, l1Id, autoPublish) =>
            dispatch(topicActions.addSupportedLanguage(topicId, l1Id, autoPublish)),
        getTopicDifficulties: (topicId) => dispatch(topicActions.getTopicDifficulties(topicId)),
        getLessonOrderForDifficulty: (topicId, topicLessonDifficultyId) =>
            dispatch(topicActions.getLessonOrderForDifficulty(topicId, topicLessonDifficultyId)),
        updateLocalLessonOrder: (lessonArray, topicId, difficultyFirst, lessonOrder) =>
            dispatch(topicActions.updateLocalLessonOrder(lessonArray, topicId, difficultyFirst, lessonOrder)),
        unpublishLesson: (customLessonId, organizationId, topicId, reloadTopic) =>
            dispatch(lessonActions.unPublishLesson(customLessonId, organizationId, topicId, reloadTopic)),
        updateLessonOrderForDifficulty: (topicId, difficulty, topicLessonOrder) =>
            dispatch(topicActions.updateLessonOrderForDifficulty(topicId, difficulty, topicLessonOrder)),
        exportExcelTopicTranslation: (
            topicId,
            l1Id,
            filename,
            noCrossLessonDuplicates,
            useEnglishTranslationText,
            ignoreAlreadyTranslated,
            translationVersion
        ) =>
            dispatch(
                topicActions.exportExcelTopicTranslation(
                    topicId,
                    l1Id,
                    filename,
                    noCrossLessonDuplicates,
                    useEnglishTranslationText,
                    ignoreAlreadyTranslated,
                    translationVersion
                )
            ),
        importExcelTopicTranslation: (topicId, l1Id, file, version) =>
            dispatch(topicActions.importExcelTopicTranslation(topicId, l1Id, file, version)),
        exportSentenceSuggestionsFromFile: (l2Id, file, filename) =>
            dispatch(topicActions.exportSentenceSuggestionsFromFile(l2Id, file, filename)),
        exportExcelTopicTranslationForAllLanguages: (
            topicId,
            l1Ids,
            filenames,
            noCrossLessonDuplicates,
            useEnglishTranslationText,
            ignoreAlreadyTranslated,
            zipFileName
        ) =>
            dispatch(
                topicActions.exportExcelTopicTranslationForAllLanguages(
                    topicId,
                    l1Ids,
                    filenames,
                    noCrossLessonDuplicates,
                    useEnglishTranslationText,
                    ignoreAlreadyTranslated,
                    zipFileName
                )
            ),
        deleteTopic: (organizationId, topicId) => dispatch(topicActions.deleteTopic(organizationId, topicId)),
        publishTopicLesson: (organizationId, topicId, topicLessonDifficultyId, customLessonId) =>
            dispatch(topicActions.publishTopicLesson(organizationId, topicId, topicLessonDifficultyId, customLessonId)),
        updateTopic: (organizationId, topicId, name, description, imageFileReferenceId) =>
            dispatch(topicActions.updateTopic(organizationId, topicId, name, description, imageFileReferenceId)),
    }
}

export default connect(mapToProps, mapDispatchToProps)(CreatorEditTopic)
