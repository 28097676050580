import { ScenarioNodeType } from '../../../../Logic/ScenarioConstants'

export default function TeachingItems(nodes, language1, language2, shouldFilterExisting, existingItems) {
    let teachingItems = []

    function comparer(otherArray) {
        return function (current) {
            return (
                otherArray.filter(function (other) {
                    return (
                        other.textSnippetId == current.l2TextSnippetId || other.textSnippetId == current.l1TextSnippetId
                    )
                }).length == 0
            )
        }
    }

    function hasCorrectLanguageCombinations(nodeLanguageId1, nodeLanguageId2) {
        return nodeLanguageId1 + nodeLanguageId2 === language1 + language2
    }

    nodes.forEach((node) => {
        switch (node.scenarioNodeType) {
            case ScenarioNodeType.TestImage:
                if (
                    node.testOptions &&
                    hasCorrectLanguageCombinations(
                        node.testOptions[1].textLanguageId,
                        node.testOptions[2].textLanguageId
                    )
                ) {
                    teachingItems.push({
                        l1: node.testOptions[1].text,
                        l1LanguageId: 1,
                        l1TextLanguageId: node.testOptions[1].textLanguageId,
                        l1TextSnippetId: node.testOptions[1].textSnippetId,
                        textAudioUrl: node.testOptions[2].textAudioUrl,
                        l2: node.testOptions[2].text,
                        l2TextLanguageId: node.testOptions[2].textLanguageId,
                        l2TextSnippetId: node.testOptions[2].textSnippetId,
                    })
                }
                break
            case ScenarioNodeType.TestDefinition:
                if (
                    node.testOptions &&
                    hasCorrectLanguageCombinations(
                        node.testOptions[1].explanationLanguageId,
                        node.testOptions[1].textLanguageId
                    )
                ) {
                    teachingItems.push({
                        l2: node.testOptions[1].text,
                        l2LanguageId: 1,
                        l2TextLanguageId: node.testOptions[1].textLanguageId,
                        l2TextSnippetId: node.testOptions[1].textSnippetId,
                        textAudioUrl: node.testOptions[1].textAudioUrl,
                        l1: node.testOptions[1].explanation,
                        l1TextLanguageId: node.testOptions[1].explanationLanguageId,
                        l1TextSnippetId: node.testOptions[1].explanationSnippetId,
                    })
                }
                break
            default:
                break
        }
    })

    if (shouldFilterExisting) {
        teachingItems = teachingItems.filter(comparer(existingItems))
    }

    return teachingItems
}
