import { autoCourseCreationService } from '../services/autocoursecreation.service'
import { autoCourseCreationConstants } from '../actions/autocoursecreation.actions'
import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects'
import { topicService } from '../services'
import { snackbarActions, topicConstants } from '../actions'

export function* parseTextStatus(action) {
    try {
        const response = yield call(autoCourseCreationService.accParseTextStatus)
        yield put({
            type: autoCourseCreationConstants.ACC_PARSE_TEXT_STATUS.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Parsing the initial text failed. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({
            type: autoCourseCreationConstants.ACC_PARSE_TEXT_STATUS.FAILURE,
            message: e.message,
        })
    }
}

export function* accCreateStory(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreateStory)
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_STORY.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to create a story. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_STORY.FAILURE,
            message: e.message,
        })
    }
}

export function* suggestTextSections(action) {
    try {
        const response = yield call(autoCourseCreationService.accSuggestTextSections, action.payload)
        //const [response, responseQuiz, responseDialogue, responseFillInBlanks, responseStories] = yield all([
        //  call(autoCourseCreationService.accSuggestLessonPlan, action.payload),
        //  call(autoCourseCreationService.accCreateQuiz, quizObject),
        //  call(autoCourseCreationService.accCreateDialogue, quizObject),
        //  call(autoCourseCreationService.accCreateFillInBlanks, quizObject),
        //  call(autoCourseCreationService.accCreateStory, quizObject)
        //]);

        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to suggest Teaching Material.',
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.FAILURE,
            message: e.message,
        })
    }
}

export function* accSuggestLessonPlanForSection(action) {
    try {
        //const response = yield call(autoCourseCreationService.accSuggestLessonPlan, action.payload);
        const payloadObject = action.payload

        const quizObject = {
            ...payloadObject.parseTextRequestDto,
            section: payloadObject.parseTextRequestDto.section,
            lesson: payloadObject.lesson,
        }

        const imageObject = {
            ...payloadObject.parseTextRequestDto,
            section: payloadObject.parseTextRequestDto.section,
        }
        action.payload.isSuggestLessonPlanRequest = true

        const images = yield call(autoCourseCreationService.accSuggestImages, imageObject)

        let promises = images.data.images.map(async (image) => {
            console.log('suggesting image', image)
            const result = await autoCourseCreationService.accSuggestImage({
                authorInputMetadata: payloadObject.parseTextRequestDto.authorInputMetadata,
                concept: image,
            })
            return result
        })

        console.log('BEGINNING LESSON REPOPULATION')

        const [response, responseQuiz, responseDialogue, responseFillInBlanks, responseStories, responseImages] =
            yield all([
                call(autoCourseCreationService.accSuggestLessonPlan, action.payload),
                call(autoCourseCreationService.accCreateQuiz, quizObject),
                call(autoCourseCreationService.accCreateDialogue, quizObject),
                call(autoCourseCreationService.accCreateFillInBlanks, quizObject),
                call(autoCourseCreationService.accCreateStory, quizObject),
                Promise.all(promises.flat()),
            ])

        const conceptsWithImagesAsync = responseImages.map((result) => result.data)
        console.log('FINISHED LESSON REPOPULATION', conceptsWithImagesAsync)

        // get quiz questions
        const quizQuestions = responseQuiz.data.questions

        // get dialogues
        const dialogues = responseDialogue.data

        // get fill in the blanks
        const fillInBlanks = responseFillInBlanks.data.questions

        // get stories
        const stories = responseStories.data

        // add objects to each lesson that was created.
        let lessonsContainer = response.data
        lessonsContainer.lessons.forEach((lesson) => {
            lesson.concepts.forEach((concept) => {
                conceptsWithImagesAsync.forEach((conceptWithImage) => {
                    if (concept.conceptId === conceptWithImage.conceptId) {
                        concept.imageDescription = conceptWithImage.imageDescription
                        concept.imageUrl = conceptWithImage.imageUrl
                    }
                })
            })
        })
        lessonsContainer.lessons.forEach((lesson) => {
            if (lesson.lessonPlanConfigurationDto) {
                lesson.lessonPlanConfigurationDto.quizQuestions = quizQuestions
                lesson.lessonPlanConfigurationDto.dialogue = dialogues
                lesson.lessonPlanConfigurationDto.fillInBlanksQuestions = fillInBlanks
                lesson.lessonPlanConfigurationDto.story = stories
            } else {
                // lesson created was of type simulation, but might as well add objects in case author changes lesson type
                lesson.lessonPlanConfigurationDto = {
                    quizQuestions: quizQuestions,
                    dialogue: dialogues,
                    fillInBlanksQuestions: fillInBlanks,
                    story: stories,
                }
            }
        })

        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.SUCCESS,
            data: {
                data: lessonsContainer,
                sectionId: action.payload.parseTextRequestDto.section.sectionId,
                concepts: conceptsWithImagesAsync,
            },
        })
    } catch (e) {
        let error = e

        if (error.config?.url?.endsWith('acc/suggest/lesson/plan/v2')) {
            yield put(
                snackbarActions.enqueueSnackbar({
                    message: 'Failed to create a lesson plan from the concepts.',
                    options: {
                        variant: 'warning',
                    },
                })
            )
            let sectionPlanFailureData = {
                sectionId: action.payload.parseTextRequestDto.section.sectionId,
            }
            yield put({
                type: autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.FAILURE,
                data: sectionPlanFailureData,
                message: e.message,
            })
        }
    }
}

export function* accCreateTopic(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreteTopic, action.payload)
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_TOPIC.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Creating full course failed. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_TOPIC.FAILURE,
            message: e.message,
        })
    }
}

export function* accCreateLesson(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreateLesson, action.payload)
        let customLessonId = response.data.customLessonId

        const successResponseData = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
            customLessonId: customLessonId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_LESSON.SUCCESS,
            data: successResponseData,
        })

        if (response.data?.customLessonId && action.payload.attachToTopicId) {
            // attach the lesson to the topic

            const attachNewLessonToTopicPayload = {
                topicId: action.payload.attachToTopicId,
                topicLessonDifficultyId: 0,
                customLessonId: customLessonId,
            }
            const attachToTopicResponse = yield call(
                topicService.attachCustomLessonToTopic,
                attachNewLessonToTopicPayload
            )
            yield put({
                type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.SUCCESS,
                data: {},
            })
        }
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'We were unable to create lesson for ' + action.payload.lesson.lessonName + ', please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )

        const failureResponseData = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_LESSON.FAILURE,
            data: failureResponseData,
            message: e.message,
        })
    }
}

export function* accCreateQuiz(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreateQuiz, action.payload)
        let dataObject = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
            responseData: response.data,
        }
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_QUIZ.SUCCESS,
            data: dataObject,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Quiz generation failed. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )

        let errorDataObject = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_QUIZ.FAILURE,
            data: errorDataObject,
            message: e.message,
        })
    }
}

export function* accCreateDialogue(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreateDialogue, action.payload)

        if (response.data) {
            response.data.lessonPlanId = action.payload.lesson.lessonPlanId
        }
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_DIALOGUE.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Dialogue generation failed. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
        let errorCreateDialogueData = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
        }
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_DIALOGUE.FAILURE,
            data: errorCreateDialogueData,
            message: e.message,
        })
    }
}

export function* accCreateFillInBlanks(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreateFillInBlanks, action.payload)
        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.SUCCESS,
            data: {
                lessonPlanId: action.payload.lesson.lessonPlanId,
                fillInTheBlanksQuestions: response.data.lessonPlanConfigurationDto.fillInBlanksQuestions,
            },
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Fill in the blanks quiz generation failed. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.FAILURE,
            message: e.message,
        })
    }
}

export function* accSuggestScenario(action) {
    try {
        const response = yield call(autoCourseCreationService.accSuggestScenario, action.payload)

        if (response.data) {
            response.data.lessonPlanId = action.payload.lesson.lessonPlanId
        }

        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Fill in the blanks quiz generation failed. Please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )

        let errorSuggestScenarioData = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.FAILURE,
            data: errorSuggestScenarioData,
            message: e.message,
        })
    }
}

export function* accUpdateLessonSuggestionDto(action) {
    try {
        let responseNewTitleAndDescription = {}
        yield put({
            type: autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.SUCCESS,
            data: { ...action.payload, ...responseNewTitleAndDescription.data },
        })

        if (action.payload?.localLessonSuggestionDto?.shouldRefreshLessonDetails === true) {
            responseNewTitleAndDescription = yield call(
                autoCourseCreationService.accUpdateTitleAndDescription,
                action.payload.localLessonSuggestionDto.additionals
            )
            yield put({
                type: autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.SUCCESS,
                data: {
                    lessonPlanId: action.payload.localLessonSuggestionDto.additionals.lesson.lessonPlanId,
                    ...responseNewTitleAndDescription.data,
                },
            })
        }
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.FAILURE,
            message: e.message,
        })
    }
}

export function* accLocalUpdateSuggestSectionsReturnDto(action) {
    try {
        //const response = yield call(autoCourseCreationService.accUpdateLessonSuggestionDto, action.payload);
        yield put({
            type: autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.SUCCESS,
            data: action.payload,
        })
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.FAILURE,
            message: e.message,
        })
    }
}

export function* accSuggestImages(action) {
    // TODO wire up
    try {
        //const response = yield call(autoCourseCreationService.accUpdateLessonSuggestionDto, action.payload);
        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_IMAGES.SUCCESS,
            data: action.payload,
        })
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_IMAGES.FAILURE,
            message: e.message,
        })
    }
}

export function* accSuggestImage(action) {
    try {
        //TODO not yet in use
        //const response = yield call(autoCourseCreationService.accUpdateLessonSuggestionDto, action.payload);
        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_IMAGE.SUCCESS,
            data: action.payload,
        })
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_SUGGEST_IMAGE.FAILURE,
            message: e.message,
        })
    }
}

const generateChunkVocabularyStage = async () => {
    let newTitleAndDescription = {}

    return newTitleAndDescription
}

export function* accUpdateTitleAndDescription(action) {
    try {
        let responseNewTitleAndDescription = null
        if (action.payload.localLessonSuggestionDto.objectToSend.lesson.concepts.length > 0) {
            const [responseQuiz, responseDialogue, responseFillInBlanks, responseStories, responseTitleAndDescription] =
                yield all([
                    call(autoCourseCreationService.accCreateQuiz, action.payload.localLessonSuggestionDto.objectToSend),
                    call(
                        autoCourseCreationService.accCreateDialogue,
                        action.payload.localLessonSuggestionDto.objectToSend
                    ),
                    call(
                        autoCourseCreationService.accCreateFillInBlanks,
                        action.payload.localLessonSuggestionDto.objectToSend
                    ),
                    call(
                        autoCourseCreationService.accCreateStory,
                        action.payload.localLessonSuggestionDto.objectToSend
                    ),
                    call(
                        autoCourseCreationService.accUpdateTitleAndDescription,
                        action.payload.localLessonSuggestionDto.objectToSend
                    ),
                ])

            const quizQuestions = responseQuiz.data.questions
            const dialogues = responseDialogue.data
            const fillInBlanks = responseFillInBlanks.data.questions
            const stories = responseStories.data

            const lessonPlanConfigurationDto = {
                story: stories,
                quizQuestions: quizQuestions,
                dialogue: dialogues,
                fillInBlanksQuestions: fillInBlanks,
            }

            responseNewTitleAndDescription = responseTitleAndDescription
            yield put({
                type: autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.SUCCESS,
                data: {
                    lessonPlanId: action.payload.localLessonSuggestionDto.objectToSend.lesson.lessonPlanId,
                    ...responseNewTitleAndDescription.data,
                    lessonPlanConfigurationDto,
                },
            })
        } else {
            // all concepts removed so just remove title and description rather than asking the ai for a name
            responseNewTitleAndDescription = {
                data: { lessonName: '', lessonDescription: '' },
            }
            yield put({
                type: autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.SUCCESS,
                data: {
                    lessonPlanId: action.payload.localLessonSuggestionDto.objectToSend.lesson.lessonPlanId,
                },
            })
        }
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.FAILURE,
            message: e.message,
        })
    }
}

export function* accCreateLessonWithSelectedStages(action) {
    try {
        const response = yield call(autoCourseCreationService.accCreateLessonWithSelectedStages, action.payload)

        let customLessonId = response.data.customLessonId
        const successResponseData = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
            customLessonId: customLessonId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.SUCCESS,
            data: successResponseData,
        })

        if (response.data?.customLessonId && action.payload.attachToTopicId) {
            // attach the lesson to the topic

            const attachNewLessonToTopicPayload = {
                topicId: action.payload.attachToTopicId,
                topicLessonDifficultyId: 0,
                customLessonId: customLessonId,
            }
            const attachToTopicResponse = yield call(
                topicService.attachCustomLessonToTopic,
                attachNewLessonToTopicPayload
            )
            yield put({
                type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.SUCCESS,
                data: {},
            })
        }
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message:
                    'We were unable to create lesson for ' + action.payload.lesson.lessonName + ', please try again.',
                options: {
                    variant: 'warning',
                },
            })
        )

        const failureResponseData = {
            lessonPlanId: action.payload.lesson.lessonPlanId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.FAILURE,
            data: failureResponseData,
            message: e.message,
        })
    }
}

export function* accLanguageUpdateData(action) {
    try {
        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.SUCCESS,
            data: action.payload,
        })
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.FAILURE,
            message: e.message,
        })
    }
}

export function* accSuggestLanguageVocabSections(action) {
    try {
        const response = yield call(autoCourseCreationService.accLanguageSuggestVocabSection, action.payload)
        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to suggest Vocabulary.',
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.FAILURE,
            message: e.message,
        })
    }
}

export function* accLanguageAddVocabItem(action) {
    try {
        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.SUCCESS,
            data: action.payload,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to add to the vocabulary.',
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.FAILURE,
            message: e.message,
        })
    }
}

export function* accSuggestLanguageChaptersSection(action) {
    try {
        const response = yield call(autoCourseCreationService.accLanguageChapterOptions, action.payload)

        let chapterSuggestionList = []
        if (response.data.chapterSuggestions?.length > 0) {
            chapterSuggestionList = response.data.chapterSuggestions
        }

        let chapterId = action.payload.narrativeRequestDto.chapterRequestId
        let chapterSuggestionObject = {
            chapterId: chapterId,
            chapterSuggestions: chapterSuggestionList,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.SUCCESS,
            data: chapterSuggestionObject,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to suggest Chapters.',
                options: {
                    variant: 'warning',
                },
            })
        )

        let errorData = {
            chapterId: action.payload.narrativeRequestDto.chapterRequestId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.FAILURE,
            data: errorData,
            message: e.message,
        })
    }
}

export function* accLanguageSuggestImagesForSelected(action) {
    try {
        const images = yield call(autoCourseCreationService.accSuggestImages, action.payload)

        let conceptsWithImages = []

        const partitions = []
        const partitionSize = 5
        let currentPartition = []

        images.data.images.forEach((conceptWithImageInfo) => {
            if (currentPartition.length >= partitionSize) {
                partitions.push(currentPartition)
                currentPartition = []
            }
            currentPartition.push(conceptWithImageInfo)
        })
        if (currentPartition.length > 0) {
            partitions.push(currentPartition)
        }
        console.log('BEGINNING LANGUAGE IMAGE GENERATION with ' + partitions.length + ' paritions')
        for (let i = 0; i < partitions.length; i++) {
            let partition = partitions[i]
            let promises = partition.map(async (conceptWithImageInfo) => {
                console.log('suggesting image', conceptWithImageInfo)
                return await autoCourseCreationService.accSuggestImage({
                    authorInputMetadata: action.payload.authorInputMetadata,
                    concept: conceptWithImageInfo,
                })
            })

            console.log('BEGINNING LANGUAGE IMAGE GENERATION for partition' + i + ' of size' + partition.length)

            const [responseImages] = yield all([Promise.all(promises.flat())])
            const conceptsWithImagesAsync = responseImages.map((result) => result.data)

            conceptsWithImagesAsync.forEach((x) => conceptsWithImages.push(x))
        }
        console.log('FINISHED LANGUAGE IMAGE GENERATION')

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.SUCCESS,
            data: conceptsWithImages,
        })
    } catch (e) {
        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.FAILURE,
            message: e.message,
        })
    }
}

export function* accLanguageLessonDistribution(action) {
    try {
        const response = yield call(autoCourseCreationService.accLanguageLessonDistribution, action.payload)

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.SUCCESS,
            data: response.data,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to generate lesson plans...',
                options: {
                    variant: 'warning',
                },
            })
        )

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.FAILURE,
            message: e.message,
        })
    }
}

export function* accLessonPlanChapterExpand(action) {
    try {
        const previousChapters = action.payload.previousChapters
        const chapterDto = action.payload.chapterDto
        const concepts = action.payload.concepts
        if (concepts.length == 0) {
            //TODO: fail on this
        }

        let currentConcepts = concepts

        const translatePayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            concepts: currentConcepts,
        }

        const translateResponse = yield call(autoCourseCreationService.accChapterConceptTranslate, translatePayload)

        if (translateResponse?.data?.translatedConcepts?.length === currentConcepts.length) {
            currentConcepts = translateResponse.data.translatedConcepts
        }

        const inflectedFormsPayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            concepts: currentConcepts,
        }

        const inflectedFormsResponse = yield call(
            autoCourseCreationService.accChapterConceptsInflectedForms,
            inflectedFormsPayload
        )
        if (inflectedFormsResponse?.data?.concepts?.length == currentConcepts.length) {
            currentConcepts = inflectedFormsResponse.data.concepts
        }

        const examplesPayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            concepts: currentConcepts,
            chapter: chapterDto,
            previousChapters: previousChapters,
        }

        const examplesResponse = yield call(autoCourseCreationService.accChapterConceptExamples, examplesPayload)

        if (examplesResponse.data?.conceptsWithExamples?.length === currentConcepts.length) {
            currentConcepts = examplesResponse.data.conceptsWithExamples
        }

        const dialoguePayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            concepts: currentConcepts,
            chapter: chapterDto,
            previousChapters: previousChapters,
        }
        const dialogueResponse = yield call(autoCourseCreationService.accChapterNarrativeDialogue, dialoguePayload)

        const lessonPlanChapterDto = dialogueResponse.data

        // generate image for repeat lesson

        // TODO: add back when we need the narrative text data
        // const narrativePayload = {
        //   authorInputMetadata: action.payload.authorInputMetadata,
        //   concepts: currentConcepts,
        //   chapter: chapterDto,
        //   previousChapters: previousChapters
        // }
        //
        // const narrativeResponse = yield call(autoCourseCreationService.accChapterNarrative, narrativePayload);
        //
        // const narrativeLessonPlanChapterDto = narrativeResponse.data;
        // lessonPlanChapterDto.chapterText = narrativeLessonPlanChapterDto.chapterText;
        //

        // generate image for intro lesson
        const introText =
            'Introduction lesson ' +
            lessonPlanChapterDto.name +
            '. With the summary following. ' +
            lessonPlanChapterDto.chapterSummary
        const repetitionText =
            'Repetition lesson ' +
            lessonPlanChapterDto.name +
            '. With the summary following. ' +
            lessonPlanChapterDto.chapterSummary

        const introImageName = 'Intro_' + lessonPlanChapterDto.name
        const repeatImageName = 'Repeat_' + lessonPlanChapterDto.name
        const introImagePayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            imageType: 'Generic',
            text: introText,
            imageName: introImageName,
        }

        const repeatImagePayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            imageType: 'Generic',
            text: repetitionText,
            imageName: repeatImageName,
        }

        const translateNarrativePayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            chapter: lessonPlanChapterDto,
        }

        const [introImageResponse, repeatImageReponse, translatedNarrativeResponse] = yield all([
            call(autoCourseCreationService.accSuggestGenericImage, introImagePayload),
            call(autoCourseCreationService.accSuggestGenericImage, repeatImagePayload),
            call(autoCourseCreationService.accChapterTranslateNarrative, translateNarrativePayload),
        ])

        const introImageUrl = introImageResponse.data.image
        const repeatImageUrl = repeatImageReponse.data.image

        const translatedNarrativeLessonPlanChapterDto = translatedNarrativeResponse.data
        translatedNarrativeLessonPlanChapterDto.chapterId = chapterDto.chapterId
        translatedNarrativeLessonPlanChapterDto.chapterIntroLessonImage = introImageUrl
        translatedNarrativeLessonPlanChapterDto.chapterRepeatLessonImage = repeatImageUrl

        yield put({
            type: autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.SUCCESS,
            data: translatedNarrativeLessonPlanChapterDto,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to post process chapter.',
                options: {
                    variant: 'warning',
                },
            })
        )
        const failureData = {
            chapterId: action.payload.chapterDto.chapterId,
        }
        yield put({
            type: autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.FAILURE,
            data: failureData,
            message: e.message,
        })
    }
}

export function* accLanguageLessonGeneration(action) {
    try {
        const response = yield call(autoCourseCreationService.accGenerateLesson, action.payload)

        const languageLessonGenerationData = {
            lessonPlanId: action.payload.languageLessonPlanDto.lessonPlanId,
            customLessonId: response.data.lessonId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.SUCCESS,
            data: languageLessonGenerationData,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to generate lesson plans...',
                options: {
                    variant: 'warning',
                },
            })
        )

        const languageLessonGenerationFailureData = {
            lessonPlanId: action.payload.languageLessonPlanDto.lessonPlanId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.FAILURE,
            data: languageLessonGenerationFailureData,
            message: e.message,
        })
    }
}

export function* accRecreateLanguageSingleLesson(action) {
    try {
        const requestLanguageLessonPlanDto = {
            ...action.payload.languageLessonPlanDto,
        }
        const translateNarrativePayload = {
            authorInputMetadata: action.payload.authorInputMetadata,
            chapter: requestLanguageLessonPlanDto.lessonPlanChapters[0],
        }

        if (action.payload.languageLessonPlanDto.lastChange) {
            const translatedNarrativeResponse = yield call(
                autoCourseCreationService.accChapterTranslateNarrative,
                translateNarrativePayload
            )

            const translatedNarrativeLessonPlanChapterDto = translatedNarrativeResponse.data

            let updatedLessonPlanChapters = []
            updatedLessonPlanChapters.push(translatedNarrativeLessonPlanChapterDto)
            requestLanguageLessonPlanDto.lessonPlanChapters = updatedLessonPlanChapters
        }

        let languageLessonPlanDto = requestLanguageLessonPlanDto
        if (action.payload.languageLessonPlanDto.majorChanges) {
            const recreateSingleLanguageLessonPlanRequestData = {
                authorInputMetadata: action.payload.authorInputMetadata,
                languageLessonPlanDto: languageLessonPlanDto,
            }

            const lessonPlanResponse = yield call(
                autoCourseCreationService.accRecreateLanguageSingleLessonPlan,
                recreateSingleLanguageLessonPlanRequestData
            )
            languageLessonPlanDto = lessonPlanResponse.data
        }

        const lessonGenerationData = {
            authorInputMetadata: action.payload.authorInputMetadata,
            languageLessonPlanDto: languageLessonPlanDto,
        }
        const response = yield call(autoCourseCreationService.accGenerateLesson, lessonGenerationData)

        const recreateLanguageSingleLessonData = {
            lessonPlanId: action.payload.languageLessonPlanDto.lessonPlanId,
            customLessonId: response.data.lessonId,
            languageLessonPlanDto: languageLessonPlanDto,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.SUCCESS,
            data: recreateLanguageSingleLessonData,
        })
    } catch (e) {
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'Failed to recreate single lesson and plan',
                options: {
                    variant: 'warning',
                },
            })
        )

        const languageLessonGenerationFailureData = {
            lessonPlanId: action.payload.languageLessonPlanDto.lessonPlanId,
        }

        yield put({
            type: autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.FAILURE,
            data: languageLessonGenerationFailureData,
            message: e.message,
        })
    }
}

export function* autoCourseCreationSagas() {
    yield takeLatest(autoCourseCreationConstants.ACC_CREATE_TOPIC.REQUEST, accCreateTopic)
    yield takeLatest(autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.REQUEST, suggestTextSections)
    yield takeEvery(autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.REQUEST, accSuggestLessonPlanForSection)
    yield takeEvery(autoCourseCreationConstants.ACC_PARSE_TEXT_STATUS.REQUEST, parseTextStatus)
    yield takeEvery(autoCourseCreationConstants.ACC_CREATE_LESSON.REQUEST, accCreateLesson)
    yield takeEvery(autoCourseCreationConstants.ACC_CREATE_QUIZ.REQUEST, accCreateQuiz)
    yield takeEvery(autoCourseCreationConstants.ACC_CREATE_DIALOGUE.REQUEST, accCreateDialogue)
    yield takeEvery(
        autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.REQUEST,
        accUpdateLessonSuggestionDto
    )
    yield takeEvery(
        autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.REQUEST,
        accLocalUpdateSuggestSectionsReturnDto
    )
    yield takeEvery(
        autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.REQUEST,
        accCreateLessonWithSelectedStages
    )
    yield takeEvery(autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.REQUEST, accSuggestScenario)
    yield takeEvery(autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.REQUEST, accCreateFillInBlanks)
    yield takeEvery(autoCourseCreationConstants.ACC_CREATE_STORY.REQUEST, accCreateStory)
    yield takeLatest(autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.REQUEST, accUpdateTitleAndDescription)
    yield takeEvery(autoCourseCreationConstants.ACC_SUGGEST_IMAGES.REQUEST, accSuggestImages)
    yield takeEvery(autoCourseCreationConstants.ACC_SUGGEST_IMAGE.REQUEST, accSuggestImage)
    yield takeLatest(
        autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.REQUEST,
        accSuggestLanguageVocabSections
    )
    yield takeEvery(
        autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.REQUEST,
        accSuggestLanguageChaptersSection
    )
    yield takeLatest(autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.REQUEST, accLanguageAddVocabItem)
    yield takeLatest(
        autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.REQUEST,
        accLanguageLessonDistribution
    )
    yield takeEvery(autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.REQUEST, accLessonPlanChapterExpand)
    yield takeEvery(autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.REQUEST, accLanguageUpdateData)
    yield takeEvery(autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.REQUEST, accLanguageLessonGeneration)
    yield takeLatest(
        autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.REQUEST,
        accLanguageSuggestImagesForSelected
    )
    yield takeEvery(
        autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.REQUEST,
        accRecreateLanguageSingleLesson
    )
}
