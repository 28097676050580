import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, Button, makeStyles } from '@material-ui/core'
import { organizationActions } from '_state/actions'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { UserStatisticsVisibility } from '../CapeeshConstants'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import OrganizationClassroomList from './Components/OrganizationClassroomList'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    main: {
        position: 'relative',
        flexGrow: 1,
        direction: 'column',
    },
    mainSection: {
        marginBottom: '20px',
    },
}))

const OrganizationClassrooms = (props) => {
    const classes = useStyles()
    const [showAddStudentsOption, setShowAddStudentsOption] = useState(false)
    const [canCreateNewClassroom, setCanCreateNewClassroom] = useState(false)

    const [currentOrganization, setCurrentOrganization] = useState(null)

    useEffect(() => {
        props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(props.organization.selectedOrganization))
        props.dispatch(organizationActions.getGraphDataHourlyOrganization(props.organization.selectedOrganization))

        recalculateViewData()
    }, [])

    useEffect(() => {
        recalculateViewData()
    }, [props.organization])

    const recalculateViewData = () => {
        let tempCurrentOrganization

        if (props.organization.myOrganizations && props.organization.selectedOrganization)
            tempCurrentOrganization = props.organization.myOrganizations.find(
                (x) => x.id === props.organization.selectedOrganization
            )

        setCurrentOrganization(tempCurrentOrganization)

        let tempShowAddStudentsOption = false
        if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') {
            tempShowAddStudentsOption = true
        } else if (props.user.organizationRole === 'reporting') {
            if (
                !props.user.classroomOnlyReportingUser &&
                tempCurrentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode
            ) {
                tempShowAddStudentsOption = true
            } else if (props.user.classroomOnlyReportingUser) {
                tempShowAddStudentsOption = true
            }
        }
        setShowAddStudentsOption(tempShowAddStudentsOption)

        let tempCanCreateNewClassroom = false
        // if (props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher' && tempCurrentOrganization?.customerCreateNewClassroom) {
        //   tempCanCreateNewClassroom = true;
        // } else if (tempCurrentOrganization?.customerCreateNewClassroom && props.user.organizationRole === 'reporting' && !props.user.classroomOnlyReportingUser) {
        //   tempCanCreateNewClassroom = true;
        // }
        if (tempCurrentOrganization?.customerCreateNewClassroom) {
            tempCanCreateNewClassroom = true
        }
        setCanCreateNewClassroom(tempCanCreateNewClassroom)
    }
    return (
        <div className={classes.root} style={{ marginTop: '25px' }}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />
            <div>
                <div className={classes.main}>
                    <Grid container spacing={3}>
                        <Grid items xs={12} style={{ marginBottom: '0px', marginLeft: '20px', marginTop: '25px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <Typography style={{ justifyContent: 'flex-start' }} variant="h4">
                                    <FormattedMessage id="dashboard.classrooms" defaultMessage="Classrooms" />
                                </Typography>
                                {canCreateNewClassroom && (
                                    <Button
                                        style={{ justifyContent: 'flex-end' }}
                                        color={'primary'}
                                        component={Link}
                                        to="/dashboard/new-classroom">
                                        <FormattedMessage
                                            id="dashboard.create.classroom"
                                            defaultMessage="Create a new classroom"
                                        />
                                    </Button>
                                )}
                            </div>
                            <Grid item xs={12}></Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <OrganizationClassroomList
                                showAddStudentsOption={showAddStudentsOption}
                                dispatch={props.dispatch}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { user, organization, usergroup, translator, metadata, lesson, topic } = state
    return {
        user,
        organization,
        usergroup,
        translator,
        metadata,
        lesson,
        topic,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationClassrooms)
