import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { history } from '../../../_state/helpers'
import moment from 'moment/moment'
import CourseCard from '../Courses/CourseCard'

const useStyles = makeStyles((theme) => ({
    newCourseIcon: {
        height: '50px',
        width: '50px',
        margin: 'auto auto',
    },
}))

const CreatorCoursesComponent = (props) => {
    const classes = useStyles()

    const handleEditCourse = (topicId) => {
        history.push('/creator/topic/list/' + topicId)
    }

    return (
        <Grid item xs={12} sm={12} style={{ marginTop: '30px' }}>
            {props.topics?.length > 0 && (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.mainSection} style={{ marginTop: '10px' }}>
                                <FormattedMessage id="dashboard.courses.title" defaultMessage="Courses" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container spacing={1}>
                            {props.topics
                                ?.filter((x) => x.organizationId === props.organization.selectedOrganization)
                                .sort(
                                    (a, b) =>
                                        moment(b.modifiedAt).format('YYYYMMDD') -
                                        moment(a.modifiedAt).format('YYYYMMDD')
                                )
                                .map((topicInstance, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} key={index}>
                                            <CourseCard
                                                topic={topicInstance}
                                                index={index}
                                                slideDirection={'left'}
                                                handleEditCourse={handleEditCourse}
                                                disabled={props.user?.organizationRole === 'reporting'}
                                                selectedOrganization={props.organization.selectedOrganization} // all these are already from the organization
                                            />
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorCoursesComponent)
