import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Grid, makeStyles } from '@material-ui/core'
import DialogContentText from '@mui/material/DialogContentText'
import { TextField } from '@mui/material'
import ImageUploadWithCropComponent from '../../../Components/ImageUpload/ImageUploadWithCropComponent'
import { ImageCropType } from '../../../Logic/CourseGroupConstants'
import { ScenarioImageSelection } from '../../../Logic/ScenarioConstants'
import TextFieldWithLanguageSelector from '../Scenarios/ScenarioComponents/TextFieldWithLanguageSelector'

const useStyles = makeStyles((theme) => ({
    topicTextField: {
        minWidth: '200px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
    },
    mainContainer: {
        padding: 16,
    },
    textField: {
        paddingTop: 8,
        paddingBottom: 32,
    },
    largeTexts: {
        alignSelf: 'stretch',
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 500,
        marginBottom: 8,
    },
    largeSubTexts: {
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
    },
}))

const CreateTopicNameAndImage = (props) => {
    const classes = useStyles()

    const [topicName, setTopicName] = useState(props.newTopicName)
    const [topicDescription, setTopicDescription] = useState(props.newTopicDescription)
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedImageBlob, setSelectedImageBlob] = useState(props.newTopicImage)

    useEffect(() => {
        props.setNewTopicName(topicName)
    }, [topicName])

    useEffect(() => {
        props.setNewTopicDescription(topicDescription)
    }, [topicDescription])

    useEffect(() => {
        props.setNewTopicImage(selectedImageBlob)
    }, [selectedImageBlob])

    useEffect(() => {
        props.setSelectedTopicImage(selectedImage)
    }, [selectedImage])

    return (
        <div className={classes.mainContainer}>
            <div>
                <div className={classes.largeTexts}>
                    {'What is the topic called? '}
                    <span className={classes.largeSubTexts}>(required)</span>
                </div>
                <div className={classes.textField}>
                    <TextFieldWithLanguageSelector
                        fullWidth={true}
                        text={topicName}
                        onChange={(event) => setTopicName(event.target.value)}
                        style={{
                            borderRadius: '10px',
                            paddingRight: '5px',
                        }}
                        soloLanguageId={props.l2Id}
                        canChangeLanguage={false}
                    />
                </div>
                <div className={classes.largeTexts}>
                    {'Give a short description of the topic '}
                    <span className={classes.largeSubTexts}>(required)</span>
                </div>
                <div className={classes.textField}>
                    <TextFieldWithLanguageSelector
                        fullWidth={true}
                        text={topicDescription}
                        onChange={(event) => setTopicDescription(event.target.value)}
                        style={{
                            borderRadius: '10px',
                            paddingRight: '5px',
                        }}
                        soloLanguageId={props.l2Id}
                        canChangeLanguage={false}
                    />
                </div>
            </div>
            <div className={classes.largeTexts}>
                {'Add a topic image '}
                <span className={classes.largeSubTexts}>(required)</span>
            </div>
            <div>
                <ImageUploadWithCropComponent
                    defaultSelectedOption={ScenarioImageSelection.Search}
                    aspectRatioType={ImageCropType.Course}
                    saveImage={setSelectedImage}
                    setSelectedImageBlob={setSelectedImageBlob}
                />
            </div>
        </div>
    )
}

CreateTopicNameAndImage.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTopicNameAndImage)
