import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
    Card,
    Grid,
    CardHeader,
    CardContent,
    CardActions,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { userActions } from '_state/actions'
import { FormattedMessage } from 'react-intl'

const styles = (theme) => ({
    languageDropdown: {
        width: '100%',
    },
})

class Settings extends React.Component {
    state = {
        name: '',
        email: '',
        l1Id: 1,
        l2Id: 1,
        completedOnboarding: false,
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleCheckboxChange = (event) => {
        this.setState({
            [event.target.id]: event.target.checked,
        })
    }

    formHasChanges = () => {
        const { name, email, l1Id, l2Id, completedOnboarding } = this.state
        const { user } = this.props

        return (
            name !== user.info.name ||
            email !== user.info.email ||
            l1Id !== user.info.l1Id ||
            l2Id !== user.info.l2Id ||
            completedOnboarding !== user.info.completedOnboarding
        )
    }

    handleLanguageChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSaveChangesClicked = () => {
        const { name, email, l1Id, l2Id, completedOnboarding } = this.state

        this.props.dispatch(userActions.updateUser(name, l1Id, l2Id, email, completedOnboarding))
    }

    componentWillReceiveProps = (nextProps) => {
        const { user } = nextProps

        this.setState({
            name: user.info.name,
            email: user.info.email,
            l1Id: user.info.l1Id,
            l2Id: user.info.l2Id,
            completedOnboarding: user.info.completedOnboarding,
        })
    }

    render() {
        const { classes, metadata } = this.props

        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader title={'Your profile'} />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="name"
                                                label="Name"
                                                className={classes.textField}
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                className={classes.textField}
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                                type="email"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="completedOnboarding"
                                                        color={'primary'}
                                                        checked={this.state.completedOnboarding}
                                                        onChange={this.handleCheckboxChange}
                                                        value="completedOnboarding"
                                                    />
                                                }
                                                label="Completed onboarding"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl className={classes.languageDropdown}>
                                                <InputLabel htmlFor="language-simple">
                                                    {
                                                        <FormattedMessage
                                                            id="cap.courses.coursegeneral.l1"
                                                            defaultMessage="Native language"
                                                        />
                                                    }
                                                </InputLabel>
                                                <Select
                                                    value={this.state.l1Id}
                                                    onChange={this.handleLanguageChange}
                                                    inputProps={{
                                                        name: 'l1Id',
                                                        id: 'l1Id',
                                                    }}
                                                    autoWidth>
                                                    {metadata?.metadata?.languages
                                                        ?.sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((language, index) => (
                                                            <MenuItem key={language.id} value={language.id}>
                                                                {language.fullName}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl className={classes.languageDropdown}>
                                                <InputLabel htmlFor="language-simple">
                                                    {
                                                        <FormattedMessage
                                                            id="cap.courses.coursegeneral.l2"
                                                            defaultMessage="Target language"
                                                        />
                                                    }
                                                </InputLabel>
                                                <Select
                                                    value={this.state.l2Id}
                                                    onChange={this.handleLanguageChange}
                                                    inputProps={{
                                                        name: 'l2Id',
                                                        id: 'l2Id',
                                                    }}
                                                    autoWidth>
                                                    {metadata.metadata?.languages
                                                        ?.filter((x) => x.canBeUsedAsL2)
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((language, index) => (
                                                            <MenuItem key={language.id} value={language.id}>
                                                                {language.fullName}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button
                                disabled={!this.formHasChanges()}
                                color={'primary'}
                                variant={'contained'}
                                onClick={this.handleSaveChangesClicked}>
                                Save
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { user, metadata } = state
    return {
        user,
        metadata,
    }
}

const enhance = compose(connect(mapStateToProps), withStyles(styles))

export default enhance(Settings)
