export const OrganizationFileAssetTypes = {
    NotDefined: 0,
    Logo: 1,
    MainMenu: 2,
    Review: 3,
    SplashLogo: 4,
}
Object.freeze(OrganizationFileAssetTypes)

export const OrganizationFileAssetLoadTypes = {
    NotDefined: 0,
    Computer: 1,
    FileReference: 2,
}
Object.freeze(OrganizationFileAssetLoadTypes)

export const InviteRolesToDisplayName = {
    Teacher: 'Creator',
    QA: 'QA Tester',
    Reporting: 'Administrator',
    Admin: 'Capeesh Admin',
    Student: 'Student',
}
Object.freeze(InviteRolesToDisplayName)

export const ReverseInviteRolesToDisplayName = {
    Creator: 'Teacher',
    'QA Tester': 'QA',
    Administrator: 'Reporting',
    'Capeesh Admin': 'Admin',
    Student: 'Student',
}
Object.freeze(ReverseInviteRolesToDisplayName)
