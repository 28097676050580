import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { organizationActions } from '_state/actions'
import ClassroomSidebar from './Components/ClassroomSidebar'
import StudentListCard from 'Views/Private/Students/StudentListCard'
import { history } from '_state/helpers'
import { usergroupActions } from '../../../_state/actions'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { UserStatisticsVisibility } from '../CapeeshConstants'
import { FormattedMessage } from 'react-intl'
import EmailInvite from '../../../Components/EmailInvite'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    inviteButton: {
        float: 'right',
        clear: 'both',
    },
    divider: {
        margin: '25px 0px',
    },
}))

const ClassroomStudentListView = (props) => {
    const classes = useStyles()

    const { computedMatch } = props

    const [orderBy, setOrderBy] = useState('Time Spent')

    const [orderByOptions, setOrderByOptions] = useState(['Time Spent', 'Last active', 'Name', 'Language points'])

    const [searchInput, setSearchInput] = useState('')
    const [showOnlySignedUp, setShowOnlySignedUp] = useState(false)

    const [classroomId, setClassroomId] = useState(null)

    // student list
    const [displayClassroomUsers, setDisplayClassroomUsers] = useState([])
    const [showUserUsageStatistics, setShowUserUsageStatistics] = useState(false)

    // student invite
    const [inviteEmailsOpen, setInviteEmailsOpen] = useState(false)

    useEffect(() => {
        recalculateStudentList()
        props.dispatch(organizationActions.getOrganizationUsers(props.organization.selectedOrganization))
        props.dispatch(
            usergroupActions.getUserGroupUsers(
                props.organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
        props.dispatch(
            usergroupActions.getUserGroupStats(
                props.organization.selectedOrganization,
                computedMatch.params.classroomId
            )
        )
    }, [])

    useEffect(() => {
        recalculateStudentList()
    }, [props.usergroup, props.organization, props.user, orderBy, searchInput, showOnlySignedUp])

    const handleInviteEmailsClose = () => {
        setInviteEmailsOpen(false)
    }

    useEffect(() => {
        if (!showUserUsageStatistics) {
            setOrderByOptions(['Language points', 'Name'])
            if (orderBy !== 'Name' && orderBy !== 'Language points') {
                // fallback for first time
                setOrderBy('Language points')
            }
        } else {
            setOrderByOptions(['Time Spent', 'Last active', 'Name', 'Language points'])
        }
    }, [showUserUsageStatistics])

    const recalculateStudentList = () => {
        let classroomUsers = []
        if (props.usergroup?.userGroupStats?.users) {
            classroomUsers = removeDuplicates(props.usergroup.userGroupStats.users, 'id')
        }

        var isQaUser = props.user.organizationRole === 'qa'
        var isAdminuser = props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher'

        if (isQaUser) {
            classroomUsers = classroomUsers.filter((x) => x.email === props.user.info?.email)
        } else if (
            !isAdminuser ||
            (!props.user.info.email.includes('@itsoundsgood.no') && !props.user.info.email.includes('@capeesh.com'))
        ) {
            classroomUsers = classroomUsers.filter((x) => !x.email.endsWith('@itsoundsgood.no'))
            classroomUsers = classroomUsers.filter((x) => !x.email.endsWith('@capeesh.com'))
        }

        if (searchInput.length > 0) {
            classroomUsers = classroomUsers.filter(
                (x) =>
                    x.email.toLowerCase().includes(searchInput.toLowerCase()) ||
                    x.name.toLowerCase().includes(searchInput.toLowerCase())
            )
        }
        if (showOnlySignedUp) {
            classroomUsers = classroomUsers.filter((x) => x.signUpDate != '0001-01-01T00:00:00+00:00')
        }

        var tempShowUserUsageStatistics = false
        var currentOrganization = props.organization.myOrganizations.find(
            (x) => x.id === props.organization.selectedOrganization
        )
        if (
            props.user.organizationRole !== 'reporting' ||
            currentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode
        ) {
            tempShowUserUsageStatistics = true
        }
        setShowUserUsageStatistics(tempShowUserUsageStatistics)

        if (classroomUsers) {
            switch (orderBy) {
                case 'Last active':
                    classroomUsers = classroomUsers.sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive))
                    break
                case 'Name':
                    classroomUsers = classroomUsers.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                    break
                case 'Language points':
                    classroomUsers = classroomUsers.sort((a, b) => b.experience - a.experience)
                    break
                case 'Time spent':
                    classroomUsers = classroomUsers.sort((a, b) => b.appUseSeconds - a.appUseSeconds)
                    break
            }
        }

        setDisplayClassroomUsers(classroomUsers)
        setClassroomId(computedMatch.params.classroomId)
    }

    const handleDeleteUser = (event, userId) => {
        event.stopPropagation()
        event.preventDefault()
        const { computedMatch } = props

        props.dispatch(
            organizationActions.deleteOrganizationUser(
                props.organization.selectedOrganization,
                userId,
                computedMatch.params.classroomId
            )
        )
    }

    const handleEditStudent = (userId) => {
        history.push('/dashboard/classroom/' + classroomId + '/students/user/' + userId)
    }

    const handleRemoveStudentFromClassroom = (event, userId) => {
        event.stopPropagation()
        event.preventDefault()

        let userIds = []
        userIds.push(userId)
        props.dispatch(
            usergroupActions.deleteUserGroupUsers(classroomId, userIds, props.organization.selectedOrganization)
        )
    }

    const handleAddQaUserToClassroom = (event) => {
        props.dispatch(
            usergroupActions.addUserGroupUsers(props.organization.selectedOrganization, classroomId, [
                props.user.info.id,
            ])
        )
    }

    const removeDuplicates = (myArr, prop) => {
        if (!myArr || myArr.length === 0) {
            return []
        }
        return myArr.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos
        })
    }

    return (
        <div className={classes.root}>
            {inviteEmailsOpen && classroomId && (
                <EmailInvite
                    inviteEmailsOpen={inviteEmailsOpen}
                    handleInviteEmailsClose={handleInviteEmailsClose}
                    userGroupId={classroomId}
                    title={
                        <FormattedMessage
                            id="dashboard.classroom.students.invitemodal.title"
                            defaultMessage="Invite new students"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="dashboard.classroom.students.invitemodal.description"
                            defaultMessage="Enter emails for user to invite. Use comma to separate and add more than one."
                        />
                    }
                    availableRoles={['Student']}
                />
            )}

            <ClassroomSidebar userGroupId={computedMatch.params.classroomId} path={computedMatch.path} />
            <Grid container spacing={5} style={{ marginTop: '25px' }}>
                <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="orderBy">Order by</InputLabel>
                        <Select
                            value={orderBy}
                            onChange={(event) => setOrderBy(event.target.value)}
                            inputProps={{
                                name: 'orderBy',
                                id: 'orderBy',
                            }}>
                            {orderByOptions.map((option, index) => (
                                <MenuItem value={option} key={index}>
                                    <em>{option}</em>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        id="search"
                        label="Search for student"
                        fullWidth
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    {(props.user.organizationRole === 'admin' ||
                        props.user.organizationRole === 'teacher' ||
                        props.user.organizationRole === 'reporting') && (
                        <Button
                            variant="contained"
                            className={classes.inviteButton}
                            color={'primary'}
                            onClick={() => setInviteEmailsOpen(true)}>
                            <FormattedMessage
                                id="dashboard.classroom.students.invitenewstudents.button"
                                defaultMessage="Invite students"
                            />
                        </Button>
                    )}
                    {props.user.organizationRole === 'qa' && displayClassroomUsers.length === 0 && (
                        <Button
                            variant="contained"
                            className={classes.inviteButton}
                            color={'primary'}
                            onClick={() => handleAddQaUserToClassroom()}>
                            Enroll classroom
                        </Button>
                    )}
                </Grid>
            </Grid>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={showOnlySignedUp}
                        onChange={() => setShowOnlySignedUp(!showOnlySignedUp)}
                        value="showOnlySignedUp"
                    />
                }
                label="Show only signed up users"
            />
            <div className={classes.divider} />
            <Grid container spacing={2}>
                {props.usergroup.fetchingStudents && (
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                )}
                {!props.usergroup.fetchingStudents &&
                    displayClassroomUsers.map((user, index) => (
                        <Grid item xs={12} key={user.id}>
                            <StudentListCard
                                user={user}
                                index={index}
                                handleEdit={handleEditStudent}
                                handleRemove={handleRemoveStudentFromClassroom}
                                handleDelete={handleDeleteUser}
                                showUserUsageStatistics={showUserUsageStatistics}
                                showDeleteFromClassroom={true}
                                showAppUsage={true}
                            />
                        </Grid>
                    ))}
            </Grid>
        </div>
    )
}

ClassroomStudentListView.propTypes = {}

const mapToProps = (state) => {
    return {
        organization: state.organization,
        user: state.user,
        metadata: state.metadata,
        usergroup: state.usergroup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(ClassroomStudentListView)
