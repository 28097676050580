import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Button, Icon, Paper, Grid, Typography, CircularProgress } from '@material-ui/core'
import { authActions } from '_state/actions'
import CapeeshStyledModal from 'Components/CapeeshStyledModal'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { FormattedMessage } from 'react-intl'

const styles = (theme) => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
})
function getSteps() {
    return [
        <FormattedMessage
            id="dashboard.forgotpasswordpage.step1enterEmail"
            defaultMessage="Enter your email address"
        />,
        <FormattedMessage
            id="dashboard.forgotpasswordpage.step2enterPin"
            defaultMessage="Enter PIN and choose new password"
        />,
    ]
}

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailAddress: '',
            pin: '',
            emailSuccess: false,
            errorPIN: '',
            password: '',
        }
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <FormattedMessage
                        id="dashboard.forgotpasswordpage.typeinemail"
                        defaultMessage="To reset your password, please enter your email address."
                    />
                )
            case 1:
                return (
                    <FormattedMessage
                        id="dashboard.forgotpasswordpage.wehavesentpinemailmsg"
                        defaultMessage="We have sent you an email containing a PIN code. Please
                  enter the PIN code below and choose a new password."
                    />
                )
            default:
                return 'Unknown step'
        }
    }

    handleNext = () => {
        const { dispatch, resetStep = 0 } = this.props
        switch (resetStep) {
            case 0:
                this.props.dispatch(authActions.forgotPasswordPin(this.state.emailAddress))
                break
            case 1:
                this.props.dispatch(
                    authActions.updateForgotPassword(this.state.emailAddress, this.state.pin, this.state.password)
                )
                break
            default:
                console.log('password authaction error occurred. Reach undefined step.')
        }
    }

    handleBack = () => {
        this.props.dispatch(authActions.startOver())
        this.setState((state) => ({
            resetStep: 0,
        }))
    }

    handleGoToLogin = () => {
        this.setState({
            resetStep: 0,
        })
    }

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState({ [id]: value })
    }

    handleButtonAction = () => {
        this.props.dispatch(authActions.clearErrors())
    }

    render() {
        const { classes, resetStep = 0, notifyError = false, errorMessage = '' } = this.props
        const steps = getSteps()
        const { activeStep, emailAddress, pin, password } = this.state
        return (
            <div className={classes.root}>
                <CapeeshStyledModal
                    open={notifyError}
                    textTitle={
                        <FormattedMessage
                            id="dashboard.forgotpasswordpage.oopssomethingwrong"
                            defaultMessage="Oops, something went wrong."
                        />
                    }
                    textLine1={errorMessage}
                    onButton1={() => this.handleButtonAction()}
                    button1Text={<FormattedMessage id="globalwords.ok" defaultMessage="OK" />}
                />
                <Stepper activeStep={resetStep} orientation="vertical">
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    <Typography>{this.getStepContent(index)}</Typography>
                                    <Grid container direction="column" justify="center">
                                        {resetStep === 0 && (
                                            <TextField
                                                id="emailAddress"
                                                label={
                                                    <FormattedMessage
                                                        id="dashboard.forgotpasswordpage.enteremailaddress"
                                                        defaultMessage="Enter your email address"
                                                    />
                                                }
                                                placeholder="john.smith@yourcompany.com"
                                                className={classes.textField}
                                                value={emailAddress}
                                                onChange={this.handleChange}
                                            />
                                        )}
                                        {resetStep === 1 && (
                                            <React.Fragment>
                                                <TextField
                                                    id="pin"
                                                    label={
                                                        <FormattedMessage
                                                            id="dashboard.forgotpasswordpage.enterpinfromemail"
                                                            defaultMessage="Enter the PIN code from email"
                                                        />
                                                    }
                                                    placeholder=""
                                                    className={classes.textField}
                                                    value={pin}
                                                    onChange={this.handleChange}
                                                    autoComplete="nothanks"
                                                />
                                                <TextField
                                                    id="password"
                                                    label={
                                                        <FormattedMessage
                                                            id="dashboard.forgotpasswordpage.enternewpassword"
                                                            defaultMessage="Enter a new password"
                                                        />
                                                    }
                                                    className={classes.textField}
                                                    type="password"
                                                    margin="normal"
                                                    value={password}
                                                    onChange={this.handleChange}
                                                    autoComplete="nothanks"
                                                />
                                                {password.length <= 7 && (
                                                    <Typography>
                                                        <FormattedMessage
                                                            id="dashboard.forgotpasswordpage.passwordlengthmsg"
                                                            defaultMessage="Password must be at least 8 characters in length"
                                                        />
                                                    </Typography>
                                                )}
                                            </React.Fragment>
                                        )}
                                        <div>
                                            {resetStep != 0 && (
                                                <Button onClick={this.handleBack} className={classes.button}>
                                                    <FormattedMessage id="globalwords.back" defaultMessage="Back" />
                                                </Button>
                                            )}
                                            <Button
                                                disabled={!(resetStep != 1 || password.length > 7)}
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleNext}
                                                className={classes.button}>
                                                {activeStep === steps.length - 1 ? (
                                                    <FormattedMessage id="globalwords.finish" defaultMessage="Finish" />
                                                ) : (
                                                    <FormattedMessage id="globalwords.next" defaultMessage="Next" />
                                                )}
                                            </Button>
                                        </div>
                                    </Grid>
                                </StepContent>
                            </Step>
                        )
                    })}
                </Stepper>
                {resetStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>Your password has successfully been changed. Please click Login below</Typography>
                        <Button onClick={this.handleReset} className={classes.button} component={Link} to="/login">
                            <FormattedMessage id="globalwords.login" defaultMessage="Login" />
                        </Button>
                    </Paper>
                )}
            </div>
        )
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { user, messaging } = state
    const { loggingIn, sendingPin, errorPIN, emailSuccess, resetStep, notifyError, errorMessage } = state.auth
    return {
        loggingIn,
        messaging,
        user,
        sendingPin,
        errorPIN,
        emailSuccess,
        resetStep,
        notifyError,
        errorMessage,
    }
}

const enhance = compose(connect(mapStateToProps), withStyles(styles))

export default enhance(ForgotPassword)
