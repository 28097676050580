import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'
import { EditOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import { ReactComponent as TopicLessonOrderEmptyPublished } from './TopicLessonOrderEmptyPublished.svg'
import { AddOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        gap: '16px',
        borderRadius: '16px',
        minWidth: '400px',
        maxWidth: '800px',
    },
    mainTitle: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '500',
        textAlign: 'center',
    },
    subTitle: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        textAlign: 'center',
    },
    description: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
    },
    button: {
        color: 'white',
        background: CapeeshColors.Primary600,
        height: '40px',
        marginTop: '16px',
    },
    emptyGraphic: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
    },
}))

const TopicLessonOrderDraftsEmpty = ({ onCreateNewLesson }) => {
    const classes = useStyles()

    return <div className={classes.main}></div>
}

TopicLessonOrderDraftsEmpty.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicLessonOrderDraftsEmpty)
