import React, { useEffect, useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeGetRequest } from '../../../_state/helpers/api'
import { configWithAuth } from '../../../_state/helpers'
import { convertDataURIToBinary } from '../../../_state/helpers/base64Logic'
import { CapeeshColors } from '../../../assets/ColorPalette'
import Zoom from '@mui/material/Zoom'

import FallbackCourseImage from '../../../assets/images/courseGroup/FallbackCourseImage.jpeg'
import LinearProgress from '@mui/material/LinearProgress'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import checkmarkImage from '../../../assets/images/Lessonstatusribbons-1.png'
import checkmarkImageGray from '../../../assets/images/Lessonstatusribbons.png'
import linePattern from '../../../assets/images/Line18.png'
import LimitedTypography from '../../LimitedTypography'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
    verticalContainer: {
        flex: 1,
    },
    topicCard: {
        display: 'flex',
        paddingTop: '16px',
        paddingBottom: '16px',
        transition: 'all .25s ease',
        height: '90px',
        width: '744px',
    },
    courseGroupCardAction: {
        right: '15px',
        height: 'fit-content',
        margin: 'auto',
    },
    button: {
        backgroundColor: 'primary',
        fontColor: 'white',
        color: 'white',
        borderRadius: 4,
        marginRight: '10px',
        textTransform: 'none',
    },
    imageContainer: {
        marginLeft: '10px',
        marginBottom: '10px',
        position: 'relative',
        overflow: 'hidden',
        height: '100px',
        width: '110px',
        borderRadius: '10px',
    },
    descriptionText: {
        fontSize: '14px',
        color: '#6E6C71',
        marginBottom: '5px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
    },
    mainText: {
        fontSize: '16px',
        color: '#35323C',
        marginBottom: '5px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '140%',
    },
    expandButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}))

const CourseViewTopicCard = (props) => {
    // expected props
    // props.courseGroupSubPartToTopicMapperDto
    // props.onTopicCardClicked function
    // props.topicProgression

    const classes = useStyles()

    const [imageUrl, setImageUrl] = useState('')
    const [imageBlobUrl, setImageBlobUrl] = useState('')

    useEffect(() => {
        if (imageUrl && !imageBlobUrl) {
            let imageReferenceId = imageUrl.replace('image/file/', '')
            makeGetRequest(
                'admin/organization/' +
                    props.organization.selectedOrganization +
                    '/image/file/reference/' +
                    imageReferenceId +
                    '/base64',
                configWithAuth()
            )
                .then((result) => {
                    let type = null
                    const typeImage = result.data.split(';')[0].split(':')[1]
                    switch (typeImage) {
                        case 'image/jpeg':
                            type = 'jpeg'
                            break
                        case 'image/png':
                            type = 'png'
                            break
                        default:
                            type = 'unknown'
                            break
                    }
                    var binary = convertDataURIToBinary(result.data)
                    var blob = new Blob([binary], { type: type })
                    var blobUrl = URL.createObjectURL(blob)
                    setImageBlobUrl(blobUrl)
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }, [imageUrl])

    useEffect(() => {
        if (props.courseGroupSubPartToTopicMapperDto.imageUrl) {
            setImageUrl(props.courseGroupSubPartToTopicMapperDto.imageUrl)
        }
    }, [])

    const handleCardClick = () => {
        if (props.onTopicCardClicked && props.courseGroupSubPartToTopicMapperDto?.topicId) {
            props.onTopicCardClicked(props.courseGroupSubPartToTopicMapperDto.topicId)
        }
    }

    return (
        <div>
            <div>
                <div key={props.courseGroupSubPartToTopicMapperDto?.topicId} className={classes.topicCard}>
                    <div className={classes.cardContent}>
                        <Grid
                            container
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                            }}>
                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.imageContainer}>
                                {imageUrl && imageBlobUrl && (
                                    <img
                                        src={imageBlobUrl}
                                        style={{
                                            borderRadius: '8px',
                                            padding: '1px',
                                            maxWidth: '100%',
                                            maxHeight: '150%',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translateY(-50%) translateX(-50%)',
                                        }}
                                    />
                                )}
                                {imageUrl && !imageBlobUrl && <Typography style={{ marginTop: '70px' }}></Typography>}
                                {!imageUrl && (
                                    <img
                                        src={FallbackCourseImage}
                                        style={{
                                            borderRadius: '8px',
                                            padding: '1px',
                                            maxWidth: '100%',
                                            maxHeight: '150%',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translateY(-50%) translateX(-50%)',
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item lg={8} md={8} sm={8} xs={8}>
                                <Grid container style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.mainText}>
                                            {props.courseGroupSubPartToTopicMapperDto?.topicName}
                                        </Typography>
                                    </Grid>

                                    {props.courseGroupSubPartToTopicMapperDto?.topicDescription && (
                                        <Grid item xs={12}>
                                            <LimitedTypography
                                                customStyle={{
                                                    fontSize: '14px',
                                                    color: '#6E6C71',
                                                    marginBottom: '5px',
                                                }}
                                                limit={50}
                                                text={
                                                    props.courseGroupSubPartToTopicMapperDto?.topicDescription
                                                }></LimitedTypography>
                                        </Grid>
                                    )}
                                    {!props.courseGroupSubPartToTopicMapperDto?.topicDescription && (
                                        <Grid item xs={12}>
                                            <Typography className={classes.descriptionText}>
                                                <FormattedMessage
                                                    id="course.modal.topic.description.notset"
                                                    defaultMessage="not set"
                                                />
                                            </Typography>
                                        </Grid>
                                    )}

                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
                                        {props.showProgression && props.topicProgression && (
                                            <div style={{ marginTop: 8, width: 385 }}>
                                                <LinearProgress
                                                    style={{
                                                        height: '17px',
                                                        borderRadius: '50px',
                                                        width: '385px',
                                                    }}
                                                    sx={{
                                                        backgroundColor: CapeeshColors.Gray2,
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor:
                                                                props.topicProgression?.courseNormalizedProgression >
                                                                0.99
                                                                    ? CapeeshColors.GreenBright
                                                                    : CapeeshColors.BlueBright,
                                                            borderRadius: '50px',
                                                            backgroundImage: `url(${linePattern})`,
                                                            backgroundRepeat: 'repeat-x',
                                                            backgroundSize: '10px 30px',
                                                        },
                                                    }}
                                                    variant="determinate"
                                                    value={props.topicProgression?.courseNormalizedProgression * 100}
                                                />
                                            </div>
                                        )}
                                        {props.showProgression && !props.topicProgression && (
                                            <div style={{ marginTop: 7, width: 385 }}>
                                                <LinearProgress
                                                    style={{
                                                        height: '17px',
                                                        borderRadius: '50px',
                                                        width: '385px',
                                                    }}
                                                    sx={{
                                                        backgroundColor: CapeeshColors.Gray2,
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: CapeeshColors.Gray2,
                                                        },
                                                    }}
                                                    variant="determinate"
                                                    value={0}
                                                />
                                            </div>
                                        )}

                                        {props.showProgression && (
                                            <div style={{ marginLeft: 16 }}>
                                                {props.topicProgression?.courseNormalizedProgression > 0.99 ? (
                                                    <img src={checkmarkImage} style={{ height: '32px' }} />
                                                ) : (
                                                    <img src={checkmarkImageGray} style={{ height: '32px' }} />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {props.expanded && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: 40,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleCardClick()}>
                                            <Typography
                                                style={{
                                                    color: '#FF5159',
                                                    fontFamily: 'Rubik',
                                                    fontSize: 12,
                                                    fontStyle: 'normal',
                                                    fontWeight: 500,
                                                    lineHeight: '140%',
                                                    width: 87,
                                                    marginTop: 4,
                                                }}>
                                                <FormattedMessage
                                                    id="course.modal.topic.hidelessons"
                                                    defaultMessage="Hide Lessons"
                                                />
                                            </Typography>
                                            <KeyboardArrowDownRoundedIcon style={{ color: '#FF5159' }} />
                                        </div>
                                    )}
                                    {!props.expanded && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: 40,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleCardClick()}>
                                            <Typography
                                                style={{
                                                    color: CapeeshColors.Primary600,
                                                    fontFamily: 'Rubik',
                                                    fontSize: 12,
                                                    fontStyle: 'normal',
                                                    fontWeight: 500,
                                                    lineHeight: '140%',
                                                    width: 87,
                                                    marginTop: 4,
                                                }}>
                                                <FormattedMessage
                                                    id="course.modal.topic.showlessons"
                                                    defaultMessage="Show Lessons"
                                                />
                                            </Typography>
                                            <ChevronRightRoundedIcon style={{ color: CapeeshColors.Primary600 }} />
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

CourseViewTopicCard.propTypes = {}

const mapStateToProps = (state) => {
    const { user, organization, metadata } = state
    return {
        metadata,
        user,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseViewTopicCard)
