import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { getFlagAssetForLanguageId } from '../../../Logic/FlagUnicodeLogic'
import { CapeeshLanguage } from '../../../Logic/CapeeshConstants'
import ImageUploadWithCrop from '../../../Components/ImageUpload/ImageUploadWithCrop'
import { ImageCropType } from '../../../Logic/CourseGroupConstants'
import ImageUploadWithCropComponent from '../../../Components/ImageUpload/ImageUploadWithCropComponent'
import { makeGetRequest, makePostRequest } from '../../../_state/helpers/api'
import { configWithAuth } from '../../../_state/helpers'

const useStyles = makeStyles((theme) => ({
    courseGroupCreateTextField: {
        minWidth: '200px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
    },
    languageForm: {
        margin: '16px 0px',
        width: '300px',
    },
}))

const CreatorCourseGroupComponent = (props) => {
    // expected props
    // props.onCreateCourseGroup (name, description, l2Id, l1Id)

    const classes = useStyles()

    const [courseGroupName, setCourseGroupName] = useState('')
    const [courseGroupDescription, setCourseGroupDescription] = useState('')

    const [l2Id, setL2Id] = useState(-1)
    const [defaultL1Id, setDefaultL1Id] = useState(-1)
    const [selectedImage, setSelectedImage] = useState(null)

    const availableL1LanguagesForNonEnglishL2 = [
        CapeeshLanguage.German,
        CapeeshLanguage.Spanish,
        CapeeshLanguage.French,
        CapeeshLanguage.Norwegian,
    ]

    const setL2IdAndRecheckL1Id = (newl2Id) => {
        setL2Id(newl2Id)
        if (newl2Id !== CapeeshLanguage.EnglishUK) {
            setDefaultL1Id(CapeeshLanguage.EnglishUK)
        }

        if (newl2Id === defaultL1Id) {
            setDefaultL1Id(-1)
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onCancel()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Create a new course'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={12}>
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                width: '200px',
                                textAlign: 'left',
                                marginLeft: '1px',
                            }}>
                            Course name
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            defaultValue={courseGroupName}
                            onChange={(event) => setCourseGroupName(event.target.value)}
                            label={'Type the course name'}
                            style={{
                                borderRadius: '10px',
                                paddingRight: '5px',
                            }}
                            InputProps={{ shrink: 'false' }}
                            className={classes.courseGroupCreateTextField}
                            variant="filled"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            style={{
                                width: '200px',
                                textAlign: 'left',
                                marginTop: '15px',
                                marginLeft: '1px',
                            }}>
                            Course Description
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            defaultValue={courseGroupDescription}
                            onChange={(event) => setCourseGroupDescription(event.target.value)}
                            label={'Type the course description'}
                            style={{
                                borderRadius: '10px',
                                paddingRight: '5px',
                            }}
                            I
                            nputProps={{
                                disableUnderline: true,
                                shrink: 'false',
                            }}
                            className={classes.courseGroupCreateTextField}
                            variant="filled"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            style={{
                                maxWidth: '600px',
                                textAlign: 'left',
                                marginTop: '40px',
                            }}>
                            Select the main course language. For language courses, this is the language being taught.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.languageForm}>
                            <InputLabel htmlFor="language-simple">
                                {
                                    <FormattedMessage
                                        id="cap.courses.coursegeneral.targetlanguage2"
                                        defaultMessage="Main course language"
                                    />
                                }
                            </InputLabel>
                            <Select
                                value={l2Id}
                                onChange={(event) => setL2IdAndRecheckL1Id(event.target.value)}
                                inputProps={{
                                    name: 'languageTarget',
                                    id: 'languageTarget',
                                }}
                                style={{
                                    width: '300px',
                                    height: '40px',
                                    backgroundColor: 'white',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    marginRight: '20px',
                                    borderRadius: '5px',
                                    marginLeft: 'auto',
                                }}>
                                {props.metadata?.metadata?.languages
                                    ?.filter((x) => x.canBeUsedAsL2)
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((language, index) => (
                                        <MenuItem key={language.id} value={language.id}>
                                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '32px',
                                                            marginLeft: '5px',
                                                        }}>
                                                        <img width="30" src={getFlagAssetForLanguageId(language.id)} />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{}}>
                                                    <Typography
                                                        style={{
                                                            maxWidth: '600px',
                                                            textAlign: 'left',
                                                            marginLeft: '1px',
                                                            marginTop: '10px',
                                                        }}>
                                                        {language.friendlyName}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography style={{ maxWidth: '600px', textAlign: 'left' }}>
                            Select the default language for translations. You can add as many additional translation
                            languages as you wish later.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {defaultL1Id !== CapeeshLanguage.EnglishUK && (
                            <FormControl className={classes.languageForm}>
                                <InputLabel htmlFor="language-simple">
                                    {
                                        <FormattedMessage
                                            id="cap.courses.coursegeneral.defaultlanguage"
                                            defaultMessage="Default translation language"
                                        />
                                    }
                                </InputLabel>
                                <Select
                                    value={defaultL1Id}
                                    onChange={(event) => setDefaultL1Id(event.target.value)}
                                    inputProps={{
                                        name: 'languageDefault',
                                        id: 'languageDefault',
                                    }}
                                    autoWidth>
                                    {props.metadata?.metadata?.languages
                                        ?.filter(
                                            (x) =>
                                                x.id !== l2Id &&
                                                availableL1LanguagesForNonEnglishL2.indexOf(x.id) !== -1
                                        )
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((language, index) => (
                                            <MenuItem key={language.id} value={language.id}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    className={classes.languageSelectorItem}>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            style={{
                                                                fontSize: '32px',
                                                                marginLeft: '5px',
                                                            }}>
                                                            <img
                                                                width="30"
                                                                src={getFlagAssetForLanguageId(language.id)}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} style={{}}>
                                                        <Typography
                                                            style={{
                                                                width: '200px',
                                                                textAlign: 'left',
                                                                marginLeft: '1px',
                                                                marginTop: '10px',
                                                            }}>
                                                            {language.friendlyName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}

                        {defaultL1Id === CapeeshLanguage.EnglishUK && (
                            <FormControl className={classes.languageForm}>
                                <InputLabel htmlFor="language-simple">
                                    {
                                        <FormattedMessage
                                            id="cap.courses.coursegeneral.defaultlanguage"
                                            defaultMessage="Default native language"
                                        />
                                    }
                                </InputLabel>
                                <Select
                                    value={defaultL1Id}
                                    disabled={true}
                                    inputProps={{
                                        name: 'languageDefault',
                                        id: 'languageDefault',
                                    }}
                                    autoWidth>
                                    <MenuItem key={CapeeshLanguage.EnglishUK} value={CapeeshLanguage.EnglishUK}>
                                        <Grid container direction="row" className={classes.languageSelectorItem}>
                                            <Grid item xs={4}>
                                                <Typography
                                                    style={{
                                                        fontSize: '32px',
                                                        marginLeft: '5px',
                                                    }}>
                                                    <img
                                                        width="30"
                                                        src={getFlagAssetForLanguageId(CapeeshLanguage.EnglishUK)}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{}}>
                                                <Typography
                                                    style={{
                                                        width: '120px',
                                                        textAlign: 'left',
                                                        marginLeft: '1px',
                                                        marginTop: '10px',
                                                    }}>
                                                    English
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
                <div>
                    <ImageUploadWithCropComponent aspectRatioType={ImageCropType.Course} saveImage={setSelectedImage} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{ textTransform: 'none' }} onClick={() => props.onCancel()} autoFocus>
                    {' '}
                    Cancel{' '}
                </Button>
                <Button
                    style={{ textTransform: 'none' }}
                    variant={'contained'}
                    color={'primary'}
                    disabled={
                        courseGroupName === '' ||
                        courseGroupDescription === '' ||
                        l2Id === -1 ||
                        defaultL1Id === -1 ||
                        selectedImage === null
                    }
                    onClick={() =>
                        props.onCreateCourseGroup(
                            courseGroupName,
                            courseGroupDescription,
                            l2Id,
                            defaultL1Id,
                            selectedImage
                        )
                    }>
                    <FormattedMessage id="creator.create.course.group.button.title" defaultMessage="Create" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    const { metadata } = state
    return {
        metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorCourseGroupComponent)
