import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import LessonPreview from './LessonPreview'
import { GlobalEvents } from '../assets/GlobalEvents'
import { GlobalLessonPreviewContentSides } from '../assets/GlobalLessonPreviewContentSides'
import { ReactComponent as IPhoneFrame } from '../assets/images/iphone_14_clay_3.svg'

const useStyles = makeStyles((theme) => ({
    customDialog: {
        display: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000000,
    },
    open: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        display: 'flex',
    },
    dialog: {
        backgroundColor: 'transparent',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        overflow: 'hidden',
        maxWidth: 'fit-content',
        width: '80%',
        maxHeight: '80%',
        minHeight: 'fit-content',
        overflowY: 'auto',
        placeSelf: 'center',
    },
    header: {
        padding: theme.spacing(2),
        background: 'transparent',
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'end',
        top: 0,
        position: 'sticky',
        zIndex: 2,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    closeButton: {
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
    },
    content: {
        padding: theme.spacing(2),
    },
    horizontalContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    verticalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const GlobalLessonPreview = (props) => {
    // props
    // props.isOpen
    // props.closeDialog

    const classes = useStyles()

    const isInit = useRef(true)

    const [topContent, setTopContent] = useState(<Fragment />)
    const [bottomContent, setBottomContent] = useState(<Fragment />)
    const [leftContent, setLeftContent] = useState(<Fragment />)
    const [rightContent, setRightContent] = useState(<Fragment />)

    const handleClose = (event) => {
        if (event.target === document.getElementById('overlay')) {
            handleCloseDialog()
        }
    }

    const handleCloseDialog = () => {
        props.closeDialog()

        setTopContent(<Fragment />)
        setBottomContent(<Fragment />)
        setRightContent(<Fragment />)
        setLeftContent(<Fragment />)
    }

    const handleSetDialogContent = (event) => {
        switch (event.detail.contentPlacement) {
            case GlobalLessonPreviewContentSides.top:
                setTopContent(event.detail.content)
                break
            case GlobalLessonPreviewContentSides.bottom:
                setBottomContent(event.detail.content)
                break
            case GlobalLessonPreviewContentSides.left:
                setLeftContent(event.detail.content)
                break
            case GlobalLessonPreviewContentSides.right:
                setRightContent(event.detail.content)
                break
        }
    }

    useEffect(() => {
        if (isInit.current) return

        document.dispatchEvent(
            props.isOpen ? GlobalEvents.UnityLessonPreviewOnShow : GlobalEvents.UnityLessonPreviewOnHide
        )
    }, [props.isOpen])

    useEffect(() => {
        document.addEventListener('unityLessonPreviewOnLoad', () => {
            document.dispatchEvent(GlobalEvents.UnityLessonPreviewOnHide)
            isInit.current = false
        })
    }, [])

    useEffect(() => {
        document.addEventListener('setGlobalLessonPreviewDialogContent', (e) => handleSetDialogContent(e))

        return () => {
            document.removeEventListener('setGlobalLessonPreviewDialogContent', (e) => handleSetDialogContent(e))
        }
    }, [])

    return (
        <div
            hidden={!props.isOpen}
            className={`${classes.customDialog} ${props.isOpen ? classes.open : ''}`}
            onClick={handleClose}>
            <div className={classes.overlay} id={'overlay'}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <div className={classes.verticalContainer}>
                        {topContent}
                        <div className={classes.horizontalContainer}>
                            {leftContent}
                            <div className={classes.content}>
                                <div className={classes.header}>
                                    <div className={classes.closeButton}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <IconButton
                                                onClick={() => handleCloseDialog()}
                                                style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                <CancelOutlinedIcon
                                                    style={{ fill: 'black', width: 24, height: 24 }}
                                                    size="medium"
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ zIndex: 1, position: 'relative', pointerEvents: 'none' }}>
                                        <IPhoneFrame />
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: 'transparent',
                                            position: 'absolute',
                                            top: '26px',
                                            right: '49px',
                                            zIndex: 0,
                                        }}>
                                        <div style={{ width: 'fit-content' }}>
                                            <LessonPreview />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {rightContent}
                        </div>
                        {bottomContent}
                    </div>
                </div>
            </div>
        </div>
    )
}

GlobalLessonPreview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalLessonPreview)
