import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Button, makeStyles } from '@material-ui/core'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import IconButton from '@mui/material/IconButton'
import { CapeeshColors } from '../../assets/ColorPalette'
import Dialog from '@mui/material/Dialog'

import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

const useStyles = makeStyles((theme) => ({
    body: {
        minWidth: '400px',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
    },

    dialogTitle: {
        backgroundColor: CapeeshColors.DarkText,
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
    },

    dialogMessage: {
        backgroundColor: CapeeshColors.DarkText,
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
}))

const CapeeshOkInformation = (props) => {
    // expected props
    // props.shouldOpen
    // props.onClose
    // props.maxWidth
    // props.titleText
    // props.messageText
    // props.okButtonText
    // props.buttonColor

    const classes = useStyles()

    return (
        <Dialog maxWidth={props.maxWidth} open={props.shouldOpen} onClose={() => props.onClose()}>
            <div className={classes.body}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <IconButton
                        onClick={() => props.onClose()}
                        style={{ justifyContent: 'flex-start', display: 'flex' }}>
                        <CancelRoundedIcon style={{ fill: CapeeshColors.Gray1, width: 30, height: 30 }} size="medium" />
                    </IconButton>
                </div>

                {props.titleText && (
                    <div className={classes.dialogTitleDiv}>
                        {props.titleText && (
                            <Typography
                                variant="h1"
                                style={{
                                    boxShadow: 'none',
                                    fontSize: 24,
                                    color: CapeeshColors.DarkText,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Rubik',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                }}>
                                {props.titleText}
                            </Typography>
                        )}

                        {props.messageText && (
                            <Typography
                                variant="h1"
                                style={{
                                    boxShadow: 'none',
                                    fontSize: 14,
                                    color: CapeeshColors.DarkText,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Rubik',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    marginTop: '4px',
                                }}>
                                {props.messageText}
                            </Typography>
                        )}

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                onClick={() => props.onClose()}
                                style={{
                                    backgroundColor: props.buttonColor,
                                    color: 'white',
                                    fontSize: 16,
                                    fontFamily: 'Rubik',
                                    fontWeight: '400',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    textTransform: 'none',
                                    marginLeft: '10px',
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}>
                                {props.okButtonText}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    )
}

CapeeshOkInformation.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CapeeshOkInformation)
