import React from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

class NotFound extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <Paper elevation={4}>
                    <Typography variant="h5" component="h3" align="center">
                        <FormattedMessage
                            id="notfound.page"
                            defaultMessage="This page appears to not exist. please check and try again."
                        />
                    </Typography>
                </Paper>
            </div>
        )
    }
}

NotFound.propTypes = {}

function mapStateToProps(state) {
    return {}
}

const enhance = compose(connect(mapStateToProps))

export default enhance(NotFound)

// export default NotFound;
