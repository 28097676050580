import { asyncRequest } from './asyncRequest'

export const topicConstants = {
    UPDATE_TOPIC: new asyncRequest('UPDATE_TOPIC'),
    CREATE_TOPIC: new asyncRequest('ORGANIZATION_CREATE_TOPIC'),
    PUBLISH_TOPIC: new asyncRequest('ORGANIZATION_PUBLISH_TOPIC'),
    GET_ALL_TOPICS: new asyncRequest('ORGANIZATION_GET_ALL_TOPICS'),
    CLEAR_TOPIC_DATA: new asyncRequest('CLEAR_TOPIC_DATA'),
    DELETE_TOPIC: new asyncRequest('DELETE_TOPIC'),
    GET_ALL_USERGROUPS_FOR_TOPIC: new asyncRequest('GET_ALL_USERGROUPS_FOR_TOPIC'),
    ADD_SUPPORTED_LANGUAGE: new asyncRequest('ADD_SUPPORTED_LANGUAGE'),
    CLONE_COURSE: new asyncRequest('CLONE_COURSE'),
    GET_TOPIC: new asyncRequest('GET_TOPIC'),
    EDIT_CREATE_SNIPPET: new asyncRequest('EDIT_CREATE_SNIPPET'),
    GET_SHARABLE_COURSES: new asyncRequest('GET_SHARABLE_COURSES'),
    UPDATE_TOGGLE: new asyncRequest('UPDATE_TOGGLE'),
    GET_CURRENT_TOPIC: new asyncRequest('GET_CURRENT_TOPIC'),
    UPDATE_LOCAL_COURSE_NAME: new asyncRequest('UPDATE_LOCAL_COURSE_NAME'),
    GET_REQUESTED_L1S: new asyncRequest('GET_REQUESTED_L1S'),
    CHANGE_DESCRIPTION: new asyncRequest('CHANGE_DESCRIPTION'),

    EDIT_TOPIC_RESET: new asyncRequest('EDIT_TOPIC_RESET'),

    GET_TOPIC_DIFFICULTIES: new asyncRequest('GET_TOPIC_DIFFICULTIES'),
    GET_LESSON_ORDER_FOR_DIFFICULTY: new asyncRequest('GET_LESSON_ORDER_FOR_DIFFICULTY'),
    UPDATE_LOCAL_LESSON_ORDER: new asyncRequest('UPDATE_LOCAL_LESSON_ORDER'),
    UPDATE_LESSON_ORDER_FOR_DIFFICULTY: new asyncRequest('UPDATE_LESSON_ORDER_FOR_DIFFICULTY'),
    ATTACH_CUSTOM_LESSON_TO_TOPIC: new asyncRequest('ATTACH_CUSTOM_LESSON_TO_TOPIC'),
    ATTACH_MANY_CUSTOM_LESSON_TO_TOPIC: new asyncRequest('ATTACH_MANY_CUSTOM_LESSON_TO_TOPIC'),

    IMPORT_EXCEL_TOPIC_TRANSLATION: new asyncRequest('IMPORT_EXCEL_TOPIC_TRANSLATION'),
    EXPORT_EXCEL_TOPIC_TRANSLATION: new asyncRequest('EXPORT_EXCEL_TOPIC_TRANSLATION'),
    EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION: new asyncRequest('EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION'),

    EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE: new asyncRequest('EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE'),

    // publish
    PUBLISH_TOPIC_LESSON: new asyncRequest('PUBLISH_TOPIC_LESSON'),

    // lesson builder data picker
    GET_TOPIC_DATA_PICKER_FOR_NLP: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_NLP'),
    GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION'),
    GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA'),
    GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS: new asyncRequest('GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS'),

    GET_LESSON_ORDER_FOR_INDEXING: new asyncRequest('GET_LESSON_ORDER_FOR_INDEXING'),
}

export const topicActions = {
    addUrl(organizationId, topicId, name, url, doRecursive) {
        let recursive = ''
        if (doRecursive) recursive = 'recursive::'
        return {
            type: topicConstants.ADD_URL.REQUEST,
            payload: {
                name: name + '.url',
                url: recursive + url,
                organizationId: organizationId,
                topicId: topicId,
            },
        }
    },

    updateTopic(
        organizationId,
        topicId,
        name,
        description = '',
        imageFileReferenceId
    ) {
        return {
            type: topicConstants.UPDATE_TOPIC.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
                name: name,
                description,
                imageFileReferenceId: imageFileReferenceId,
            },
        }
    },

    createTopic(
        organisationId,
        name,
        l2Id,
        defaultL1Id = null,
        classroomId = null,
        demoCourse = false,
        autoPublish = false,
        lessonsOnly = false
    ) {
        return {
            type: topicConstants.CREATE_TOPIC.REQUEST,
            payload: {
                organizationId: organisationId,
                name: name,
                l2Id: l2Id,
                defaultL1Id: defaultL1Id,
                classroomId: classroomId,
                demoTopic: demoCourse,
                autoPublish: autoPublish,
                lessonsOnly: lessonsOnly,
            },
        }
    },

    publishTopic(topicId, l1Id, reroute) {
        return {
            type: topicConstants.PUBLISH_TOPIC.REQUEST,
            payload: {
                topicId,
                l1Id,
                reroute: reroute,
            },
        }
    },

    getAllTopicsForOrganisation(organizationId) {
        return {
            type: topicConstants.GET_ALL_TOPICS.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    deleteTopic(organizationId, topicId) {
        return {
            type: topicConstants.DELETE_TOPIC.REQUEST,
            payload: {
                organizationId: organizationId,
                topicId: topicId,
            },
        }
    },

    getAllUserGroupsForTopic(topicId) {
        return {
            type: topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST,
            payload: {
                topicId,
            },
        }
    },

    addSupportedLanguage(topicId, l1Id, autoPublish) {
        return {
            type: topicConstants.ADD_SUPPORTED_LANGUAGE.REQUEST,
            payload: {
                topicId,
                l1Id,
                autoPublish: autoPublish,
            },
        }
    },
    cloneCourse(topicId, copyAllowedWords, CopyApprovedWords, CopySupportedLanguages) {
        return {
            type: topicConstants.CLONE_COURSE.REQUEST,
            payload: {
                topicId,
                copyAllowedWords,
                CopyApprovedWords,
                CopySupportedLanguages,
            },
        }
    },
    getTopic(topicId) {
        return {
            type: topicConstants.GET_TOPIC.REQUEST,
            payload: {
                topicId,
                l1Id: 1,
            },
        }
    },

    getSharableCourses() {
        return {
            type: topicConstants.GET_SHARABLE_COURSES.REQUEST,
            payload: {},
        }
    },

    getCurrentTopic(topicId) {
        return {
            type: topicConstants.GET_CURRENT_TOPIC.REQUEST,
            payload: {
                topicId,
            },
        }
    },

    updateLocalCourseName(name) {
        return {
            type: topicConstants.UPDATE_LOCAL_COURSE_NAME.REQUEST,
            payload: {
                name,
            },
        }
    },

    changeDescription(l2WordId, description, topicId, l1Id) {
        return {
            type: topicConstants.CHANGE_DESCRIPTION.REQUEST,
            payload: {
                l2WordId,
                description,
                topicId,
                l1Id,
            },
        }
    },

    getTopicDifficulties(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DIFFICULTIES.REQUEST,
            payload: {
                topicId,
            },
        }
    },
    getLessonOrderForDifficulty(topicId, topicLessonDifficultyId) {
        return {
            type: topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId,
            },
        }
    },

    getLessonOrderForIndexing(topicId) {
        return {
            type: topicConstants.GET_LESSON_ORDER_FOR_INDEXING.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId: 0,
            },
        }
    },

    updateLessonOrderForDifficulty(topicId, topicLessonDifficultyId, topicLessonOrderItems) {
        return {
            type: topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId,
                topicLessonOrderItems,
            },
        }
    },

    attachCustomLessonToTopic(topicId, topicLessonDifficultyId, customLessonId) {
        return {
            type: topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.REQUEST,
            payload: {
                topicId,
                topicLessonDifficultyId,
                customLessonId,
            },
        }
    },

    publishTopicLesson(organizationId, topicId, topicLessonDifficultyId, customLessonId) {
        return {
            type: topicConstants.PUBLISH_TOPIC_LESSON.REQUEST,
            payload: {
                organizationId,
                topicId,
                topicLessonDifficultyId,
                customLessonId,
            },
        }
    },

    attachManyCustomLessonsToTopic(
        courseGroupId,
        topicId,
        topicLessonDifficultyId,
        customLessonIds,
        transitionToTopic
    ) {
        return {
            type: topicConstants.ATTACH_MANY_CUSTOM_LESSON_TO_TOPIC.REQUEST,
            payload: { courseGroupId, topicId, topicLessonDifficultyId, customLessonIds, transitionToTopic },
        }
    },

    updateLocalLessonOrder(lessonArray, topicId, difficultyFirst) {
        return {
            type: topicConstants.UPDATE_LOCAL_LESSON_ORDER.REQUEST,
            payload: {
                topicId: topicId,
                topicLessonDifficultyId: difficultyFirst,
                topicLessonOrderItems: lessonArray,
            },
        }
    },

    exportExcelTopicTranslationForAllLanguages(
        topicId,
        l1Ids,
        filenames,
        noCrossLessonDuplicates,
        useEnglishTranslationText,
        ignoreAlreadyTranslated,
        zipFileName
    ) {
        if (l1Ids.length != filenames.length) {
            return
        }

        var queryParams = ['useBase64=true']
        if (noCrossLessonDuplicates) {
            queryParams.push('noCrossLessonDuplicates=true')
        }
        if (useEnglishTranslationText) {
            queryParams.push('useEnglishTranslationText=true')
        }
        if (ignoreAlreadyTranslated) {
            queryParams.push('ignoreAlreadyTranslated=true')
        }

        var queryParamAsText = ''
        if (queryParams.length > 0) {
            queryParamAsText = '?' + queryParams.join('&')
        }

        let exportLanguageConfigs = []
        l1Ids.map((l1Id, index) => {
            exportLanguageConfigs.push({
                l1Id,
                filename: filenames[index],
            })
        })

        return {
            type: topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.REQUEST,
            payload: {
                topicId,
                exportLanguageConfigs: exportLanguageConfigs,
                queryParams: queryParamAsText,
                zipFileName,
            },
        }
    },

    exportExcelTopicTranslation(
        topicId,
        l1Id,
        filename,
        noCrossLessonDuplicates,
        useEnglishTranslationText,
        ignoreAlreadyTranslated,
        translationVersion
    ) {
        var queryParams = ['useBase64=true']
        if (noCrossLessonDuplicates) {
            queryParams.push('noCrossLessonDuplicates=true')
        }
        if (useEnglishTranslationText) {
            queryParams.push('useEnglishTranslationText=true')
        }
        if (ignoreAlreadyTranslated) {
            queryParams.push('ignoreAlreadyTranslated=true')
        }

        var queryParamAsText = ''
        if (queryParams.length > 0) {
            queryParamAsText = '?' + queryParams.join('&')
        }

        return {
            type: topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.REQUEST,
            payload: {
                topicId,
                l1Id,
                filename,
                queryParams: queryParamAsText,
                translationVersion: translationVersion,
            },
        }
    },

    importExcelTopicTranslation(topicId, l1Id, file, version) {
        const formData = new FormData()
        var newfile = new File([file], file.name)
        formData.append(file.name, newfile)
        return {
            type: topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.REQUEST,
            payload: {
                topicId,
                l1Id,
                formData: formData,
                version: version,
            },
        }
    },

    exportSentenceSuggestionsFromFile(l2Id, file, filename) {
        const formData = new FormData()
        var newfile = new File([file], file.name)
        formData.append(file.name, newfile)
        return {
            type: topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.REQUEST,
            payload: {
                l2Id,
                filename,
                formData: formData,
            },
        }
    },

    getTopicDataPickerForLessonData(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.REQUEST,
            payload: {
                topicId,
            },
        }
    },
    getTopicDataPickerForNLP(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.REQUEST,
            payload: {
                topicId,
            },
        }
    },
    getTopicDataPickerForNLPDistractors(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.REQUEST,
            payload: {
                topicId,
            },
        }
    },
    getTopicDataPickerForSentenceSuggestion(topicId) {
        return {
            type: topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.REQUEST,
            payload: {
                topicId,
            },
        }
    },
}
