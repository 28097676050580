import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { TextField, Button, Grid, Typography, CircularProgress, MenuItem } from '@material-ui/core'
import { authActions } from '_state/actions'
import { ReactComponent as CapeeshLogo } from 'assets/images/capeesh_logo.svg'
import { FormattedMessage } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import EmailValidator from 'email-validator'
import Footer from '../../Components/Footer'
import Select from '@material-ui/core/Select'
import { userActions } from '../../_state/actions'
import { CapeeshColors } from '../../assets/ColorPalette'
import { getFlagAssetForLanguageId } from '../../Logic/FlagUnicodeLogic'

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: 30,
        backgroundColor: '#F8F8F8',
    },
    loginCard: {
        marginTop: '20px',
        marginBottom: '300px',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    headerText: {
        padding: '10px 0px 10px 0px',
    },
    textFieldHeader: {
        marginLeft: '10px',
        textAlign: 'left',
    },
    forgotpassword: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px auto',
        cursor: 'pointer',
        '&:hover': {
            color: '#3273dc',
            textDecoration: 'underline',
        },
        fontSize: '16px',
    },
    loader: {
        margin: 'auto auto',
        display: 'block',
    },
    inputField: {
        marginTop: '7px',
        marginLeft: '10px',
        marginRight: '20px',
        width: '95%',
        variant: 'outlined',
        align: 'center',
    },
    capeeshLogo: {
        fill: '#FF5159',
        display: 'inherit',
        marginTop: '20px',
    },
    centerMidDiv: {
        display: 'flex',
        justifyContent: 'center',
    },
    callToActionButton: {
        width: '300px',
        marginTop: '25px',
    },
    languageSelectorItem: {
        display: 'flex',
        alignItems: 'center',
    },
}))

export const LoginTabType = {
    Login: 1,
    Register: 0,
}
Object.freeze(LoginTabType)

const LoginPage = (props) => {
    const { auth, user } = props
    const classes = useStyles()

    const location = useLocation()

    const [email, setEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(true)
    const [activeTab, setActiveTab] = useState(LoginTabType.Login)
    const [allowUserNotifications, setAllowUserNotifications] = useState(false)

    const [showTooShortPasswordNotice, setShowTooShortPasswordNotice] = useState(false)
    const [showInvalidEmailNotice, setShowInvalidEmailNotice] = useState(false)

    useEffect(() => {
        props.logout()
        const params = new URLSearchParams(location.search)
        const emailFromParams = params.get('email')
        const register = params.get('register')
        if (emailFromParams != null) {
            setEmail(emailFromParams)
            validateEmail(emailFromParams)
        }
        if (register === 'true') {
            setActiveTab(LoginTabType.Register)
        }
        if (location.pathname === '/register') {
            setActiveTab(LoginTabType.Register)
        }
    }, [])

    const handleChangeTab = (event, value) => {
        if (value === LoginTabType.Login || value === LoginTabType.Register) {
            setActiveTab(value)
        }
    }
    const handleChangePassword = (e) => {
        setPassword(e.target.value)
        if (showTooShortPasswordNotice) {
            setShowTooShortPasswordNotice(false)
        }
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
        setShowInvalidEmailNotice(false)

        validateEmail(email)
    }

    const validateEmail = (currentEmail) => {
        let emailValidates = EmailValidator.validate(currentEmail)
        setIsValidEmail(emailValidates)
        if (emailValidates) {
            setShowInvalidEmailNotice(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // if autofilled by chrome or password keepers
        let elements = e.target.elements
        let fieldEmail = elements?.length > 0 ? elements[0].value : ''
        let fieldPassword = elements?.length > 2 ? elements[2].value : ''

        // keep a local state of the email and validation to overwrite in the case the fields have been filled in
        let submitEmail = email.trim()
        let submitPassword = password.trim()
        let submitIsValidEmail = isValidEmail

        if (!submitEmail && fieldEmail) {
            submitEmail = fieldEmail
        }

        if (EmailValidator.validate(submitEmail)) {
            submitIsValidEmail = true
        }

        if (!submitPassword && fieldPassword) {
            submitPassword = fieldPassword
        }

        if (submitEmail && submitPassword && submitIsValidEmail && submitPassword.length >= 7) {
            if (activeTab === LoginTabType.Login) {
                props.login(submitEmail, submitPassword)
            }
            if (activeTab === LoginTabType.Register) {
                props.register(submitEmail, submitPassword, 1, 2, true)
            }
        }
        if (submitPassword && submitPassword.length < 7) {
            setShowTooShortPasswordNotice(true)
        }
        if (submitEmail && !submitIsValidEmail) {
            setShowInvalidEmailNotice(true)
        }
    }

    const changeLocalizedLanguage = (e) => {
        props.setDashboardLangauge(e.target.value)
    }

    return (
        <div>
            <Grid container alignItems="center" justify="center" className={classes.gridContainer}>
                <Grid item xs={12} sm={10} md={5}>
                    <Card className={classes.loginCard} style={{ borderRadius: '20px' }}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                style={{
                                    backgroundColor: CapeeshColors.PinkBright,
                                }}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flext-end',
                                    }}>
                                    <Select
                                        value={
                                            props.user.userDashboardLanguageId ? props.user.userDashboardLanguageId : 1
                                        }
                                        style={{
                                            width: '180px',
                                            height: '40px',
                                            backgroundColor: 'white',
                                            marginTop: '20px',
                                            marginBottom: '20px',
                                            marginRight: '20px',
                                            borderRadius: '5px',
                                            marginLeft: 'auto',
                                        }}
                                        // autoWidth
                                        onChange={changeLocalizedLanguage}>
                                        <MenuItem value={1} key={1}>
                                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                                <Grid item xs={4}>
                                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                                        <img
                                                            src={getFlagAssetForLanguageId(1)}
                                                            width="30"
                                                            alt={'English'}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{}}>
                                                    <Typography
                                                        style={{
                                                            width: '120px',
                                                            textAlign: 'left',
                                                            marginLeft: '1px',
                                                        }}>
                                                        English
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>

                                        <MenuItem value={3} key={3}>
                                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                                <Grid item xs={4}>
                                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                                        <img
                                                            src={getFlagAssetForLanguageId(3)}
                                                            width="30"
                                                            alt={'Norsk Bokmål'}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{}}>
                                                    <Typography
                                                        style={{
                                                            width: '120px',
                                                            textAlign: 'left',
                                                            marginLeft: '1px',
                                                        }}>
                                                        Norsk bokmål
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>

                                        <MenuItem value={6} key={6}>
                                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                                <Grid item xs={4}>
                                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                                        <img
                                                            src={getFlagAssetForLanguageId(6)}
                                                            width="30"
                                                            alt={'Deutsch'}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography
                                                        style={{
                                                            width: '120px',
                                                            textAlign: 'left',
                                                            marginLeft: '1px',
                                                        }}>
                                                        Deutsch
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.centerMidDiv}>
                                <CapeeshLogo width={550} height={150} className={classes.capeeshLogo} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                <FormattedMessage
                                    id="dashboard.loginpage.title"
                                    defaultMessage="Capeesh user administration portal"
                                />
                            </Typography>
                        </Grid>
                        <Tabs
                            value={activeTab}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{
                                marginTop: '40px',
                            }}
                            centered>
                            <Tab
                                value={LoginTabType.Register}
                                label={<FormattedMessage id="dashboard.loginpage.register" defaultMessage="Register" />}
                                className={activeTab === LoginTabType.Register ? 'active' : ''}
                            />
                            <Tab
                                value={LoginTabType.Login}
                                label={<FormattedMessage id="dashboard.loginpage.signin" defaultMessage="Sign in" />}
                                className={activeTab === LoginTabType.Login ? 'active' : ''}
                            />
                        </Tabs>

                        <CardContent>
                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                                className={classes.textFieldHeader}>
                                                <FormattedMessage
                                                    id="dashboard.loginpage.email"
                                                    defaultMessage="Email:"
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="username"
                                                variant="outlined"
                                                value={email}
                                                onChange={handleChangeEmail}
                                                fullWidth
                                                error={showInvalidEmailNotice}
                                                className={classes.inputField}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {showInvalidEmailNotice && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    style={{
                                                        marginTop: '10px',
                                                    }}
                                                    className={classes.textFieldHeader}>
                                                    <FormattedMessage
                                                        id="dashboard.loginpage.notvalidemailmsg"
                                                        defaultMessage="Not valid email"
                                                    />
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            {activeTab === LoginTabType.Register && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    style={{
                                                        marginTop: '25px',
                                                    }}
                                                    className={classes.textFieldHeader}>
                                                    <FormattedMessage
                                                        id="dashboard.loginpage.createnewpassword"
                                                        defaultMessage="Create new password:"
                                                    />
                                                </Typography>
                                            )}
                                            {activeTab === LoginTabType.Login && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    style={{
                                                        marginTop: '25px',
                                                    }}
                                                    className={classes.textFieldHeader}>
                                                    <FormattedMessage
                                                        id="dashboard.loginpage.password"
                                                        defaultMessage="Password:"
                                                    />
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="password"
                                                id="password"
                                                variant="outlined"
                                                label={
                                                    activeTab === LoginTabType.Login ? (
                                                        <FormattedMessage
                                                            id="globalwords.password"
                                                            defaultMessage="Password"
                                                        />
                                                    ) : (
                                                        'Create new password'
                                                    )
                                                }
                                                type="password"
                                                value={password}
                                                onChange={handleChangePassword}
                                                fullWidth
                                                error={showTooShortPasswordNotice}
                                                className={classes.inputField}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {showTooShortPasswordNotice && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    style={{
                                                        marginTop: '10px',
                                                    }}
                                                    className={classes.textFieldHeader}>
                                                    <FormattedMessage
                                                        id="dashboard.loginpage.passwordtoshortmsg"
                                                        defaultMessage="Password too short"
                                                    />
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {activeTab === LoginTabType.Login && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    style={{
                                                        marginTop: '25px',
                                                    }}
                                                    className={classes.textFieldHeader}>
                                                    <b>NB!</b>{' '}
                                                    <FormattedMessage
                                                        id="dashboard.loginpage.passwordintheappthesame"
                                                        defaultMessage="Your password here is the same as in the Capeesh App"></FormattedMessage>
                                                </Typography>
                                            )}
                                            {activeTab === LoginTabType.Register && (
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    style={{
                                                        marginTop: '25px',
                                                    }}
                                                    className={classes.textFieldHeader}>
                                                    <FormattedMessage
                                                        id="dashboard.loginpage.tooshortpasswordmsg"
                                                        defaultMessage="Your password needs to be at least 7 letters."
                                                    />
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {auth.registering || auth.loggingIn ? (
                                            <CircularProgress className={classes.loader} size={30} />
                                        ) : (
                                            <div className={classes.centerMidDiv}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className={classes.callToActionButton}
                                                    disabled={!email || !password}>
                                                    {activeTab === LoginTabType.Login && (
                                                        <FormattedMessage
                                                            id="globalwords.sign_in"
                                                            defaultMessage="Sign in"
                                                        />
                                                    )}
                                                    {activeTab === LoginTabType.Register && (
                                                        <FormattedMessage
                                                            id="dashboard.login.activateUser"
                                                            defaultMessage="Activate user"
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {activeTab === LoginTabType.Login && (
                                            <Typography
                                                variant="caption"
                                                align="center"
                                                className={classes.forgotpassword}
                                                component={Link}
                                                to="/forgot">
                                                <FormattedMessage
                                                    id="dashboard.loginpage.forgottenpasswordlink"
                                                    defaultMessage="Forgotten password?"
                                                />
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </div>
    )
}

LoginPage.propTypes = {}

const mapToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(authActions.logout()),
        register: (username, password, l1Id, l2Id, allowEmailNotifications) =>
            dispatch(authActions.register(username, password, l1Id, l2Id, allowEmailNotifications)),
        login: (username, password) => dispatch(authActions.login(username, password)),
        setDashboardLangauge: (languageId) => dispatch(userActions.setDashboardLanguage(languageId)),
    }
}
export default connect(mapToProps, mapDispatchToProps)(LoginPage)
