import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Button, Checkbox } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
}))

const DataPickerLessonItem = (props) => {
    const handleToggleAddItem = () => {
        props.onToggleAddItem(props.item, 'id')
    }
    const classes = useStyles()
    return (
        <Button
            onClick={handleToggleAddItem}
            sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '20px 20px 20px 5px',
                textTransform: 'none',
                width: '458px',
                height: '57px',
                background: '#FFFFFF',
                borderBottom: '1px solid #D1D1D6',
                flex: 'none',
                order: 2,
                flexGrow: 0,
            }}>
            <Typography
                style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '14px',
                    /* identical to box height, or 90% */
                    display: 'flex',
                    alignItems: 'left',
                    textAlign: 'left',
                    letterSpacing: '0.5px',
                    width: '370px',
                    color: '#000000',
                }}>
                {props.item?.text}
            </Typography>

            <Checkbox
                checked={props.isAdded}
                onChange={handleToggleAddItem}
                sx={{
                    visibility: props.isHover || props.isAdded ? 'visible' : 'hidden',
                    visible: false,
                    hide: true,
                }}
                checkedIcon={
                    <span
                        style={{
                            height: '25px',
                            width: '25px',
                            backgroundColor: '#003C58',
                            color: 'white',
                            borderRadius: '50%',
                            display: 'inlineBlock',
                        }}>
                        {props.selectionPosition}
                    </span>
                }
            />
        </Button>
    )
}

function mapStateToProps(state) {
    const {} = state
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPickerLessonItem)
