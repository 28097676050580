import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Button, makeStyles } from '@material-ui/core'

import IconButton from '@mui/material/IconButton'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import Dialog from '@mui/material/Dialog'

import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import HubspotContactForm from '../../../../Components/NewCapeeshModals/CapeeshHubspotForm'

const useStyles = makeStyles((theme) => ({
    body: {
        minWidth: '400px',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
    },

    dialogTitle: {
        backgroundColor: CapeeshColors.DarkText,
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
    },

    dialogMessage: {
        backgroundColor: CapeeshColors.DarkText,
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
}))

const RequestAccessCreateForm = (props) => {
    // expected props
    // props.shouldOpen
    // props.onClose
    // props.maxWidth
    // props.titleText
    // props.messageText
    // props.okButtonText
    // props.buttonColor

    const classes = useStyles()

    const email = props.user.info.email
    let currentOrganization = props.organization.myOrganizations.find(
        (x) => x.id === props.organization.selectedOrganization
    )
    const organizationName = currentOrganization.name

    return (
        <Dialog
            minWidth={props.minWidth}
            maxWidth={props.maxWidth}
            open={props.shouldOpen}
            onClose={() => props.onClose()}>
            <div className={classes.body}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <IconButton
                        onClick={() => props.onClose()}
                        style={{ justifyContent: 'flex-start', display: 'flex' }}>
                        <CancelRoundedIcon style={{ fill: CapeeshColors.Gray1, width: 30, height: 30 }} size="medium" />
                    </IconButton>
                </div>

                <HubspotContactForm
                    region={'na1'}
                    portalId={'5121404'}
                    formId={'ced27a1c-ca17-45a7-947b-d0bf0298a9cd'}
                    email={email}
                    organizationName={organizationName}
                    onSubmit={() => props.onClose()}
                />
            </div>
        </Dialog>
    )
}

RequestAccessCreateForm.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAccessCreateForm)
