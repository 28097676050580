import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import { ListItem, ListItemText, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import deburr from 'lodash/deburr'

import { Grid } from '@mui/material'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    search: {
        marginTop: '20px',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },

    timeZoneLocationLabel: {
        marginTop: '20px',
    },
}))

const CapeeshTimeZonePicker = (props) => {
    const classes = useStyles()
    const [suggestions, setSuggestions] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [totalSearchableList, setTotalSearchableList] = useState([])

    const [shouldChangeTimeZoneLocation, setShouldChangeTimeZoneLocation] = useState(false)
    const [newTimeZoneLocation, setNewTimeZoneLocation] = useState('')
    const [timeZoneLocationHasChanged, setTimeZoneLocationHasChanged] = useState(false)

    useEffect(() => {
        const { organization } = props

        let totalSearchableList = []
        if (props.timeZoneLocations?.length > 0) {
            props.timeZoneLocations.map((timeZoneLocation) => {
                totalSearchableList.push(timeZoneLocation.name)
            })
        }
        setTotalSearchableList(totalSearchableList)
    }, [])

    const handleSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, totalSearchableList))
    }

    function getSuggestionValue(suggestion) {
        return suggestion
    }

    function getSuggestions(value, list) {
        if (!value) {
            return []
        }
        const inputValue = deburr(value.trim()).toLowerCase()
        const inputLength = inputValue.length
        let count = 0

        if (inputLength === 0) return []

        return list.filter((suggestion) => suggestion.toLowerCase().includes(inputValue.toLowerCase()))
    }

    const onSearchValueChanged = (event, { newValue, method }) => {
        setSearchValue(newValue)
    }

    const handleSuggestionSelected = (
        event,
        { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) => {
        setNewTimeZoneLocation(suggestion)
        if (suggestion !== props.currentTimeZoneLocation) {
            setTimeZoneLocationHasChanged(true)
        }
    }

    function renderSuggestion(suggestion, { query, isHighlighted }) {
        return <span>{suggestion}</span>
    }

    const handleChangeLocation = () => {
        setShouldChangeTimeZoneLocation(!shouldChangeTimeZoneLocation)
    }

    const handleUseChangedLocation = () => {
        setShouldChangeTimeZoneLocation(false)
        props.onTimeZoneLocationChanged(newTimeZoneLocation)
        setTimeZoneLocationHasChanged(false)
        setSearchValue('')
    }
    const handleCancelChangedLocation = () => {
        setShouldChangeTimeZoneLocation(false)
        setTimeZoneLocationHasChanged(false)
        setSearchValue('')
    }

    const handleSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    return (
        <div style={{ height: '80px' }}>
            {shouldChangeTimeZoneLocation && (
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="body1" className={classes.timeZoneLocationLabel}>
                            Choose new Location:
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <div className={classes.search}>
                            <React.Fragment>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={handleSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    onSuggestionSelected={handleSuggestionSelected}
                                    inputProps={{
                                        placeholder: 'Search...',
                                        value: searchValue,
                                        onChange: onSearchValueChanged,
                                    }}
                                />
                            </React.Fragment>
                        </div>
                    </Grid>
                    {timeZoneLocationHasChanged && (
                        <Grid item xs={4}>
                            <Button variant="contained" color={'primary'} onClick={handleUseChangedLocation}>
                                Use new
                            </Button>
                            <Button
                                variant="contained"
                                color={'primary'}
                                style={{
                                    marginLeft: '10px',
                                }}
                                onClick={handleCancelChangedLocation}>
                                Cancel
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}

            {!shouldChangeTimeZoneLocation && (
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="body1" className={classes.timeZoneLocationLabel}>
                            Time Zone Location :
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="body1" className={classes.timeZoneLocationLabel}>
                            {props.currentTimeZoneLocation}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color={'primary'} onClick={handleChangeLocation}>
                            Change Location
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CapeeshTimeZonePicker)
