import React, { Fragment, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({}))

const ACCScrollingTexts = (props) => {
    // props.text
    // props.textStyle

    const classes = useStyles()

    const animationLength = 1

    const [textToUse, setTextToUse] = useState('')
    const [playAnimation, setPlayAnimation] = useState(false)

    useEffect(() => {
        setPlayAnimation(true)

        let delay = setTimeout(() => setTextToUse(props.text), animationLength * 1000)

        return () => clearTimeout(delay)
    }, [props.text])

    useEffect(() => {
        setTextToUse(props.text)
    }, [])

    return (
        <Fragment>
            <motion.div
                animate={playAnimation ? { opacity: [1, 0], y: [0, -10] } : { opacity: [0, 1], y: [10, 0] }}
                transition={{ duration: animationLength }}
                onAnimationComplete={() => setPlayAnimation(false)}>
                <Typography className={props.textStyle}>{textToUse}</Typography>
            </motion.div>
        </Fragment>
    )
}

ACCScrollingTexts.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCScrollingTexts)
