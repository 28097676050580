import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { GeniusTypes } from '../../../Logic/ScenarioConstants'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import TextField from '@mui/material/TextField'
import { lessonActions } from '_state/actions'
import 'react-image-crop/dist/ReactCrop.css'
import { useLocation } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        paddingLeft: '100px',
        paddingRight: '100px',
    },

    button: {
        marginTop: '20px',
        marginBottom: '20px',
        marginRight: '10px',
    },

    middlePanel: {
        marginTop: '20px',
        paddingLeft: '20px',
    },

    scenario: {
        paddingTop: '30px',
    },

    scenarioDescription: {
        paddingTop: '8px',
    },

    scenarioDescriptionText: {
        color: '#8e8e93',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
    },

    scenarioTextField: {
        borderRadius: '10px',
        paddingTop: '8px',
    },

    input: {
        display: 'none',
    },

    sectionHeaderStyle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '22px',
        paddingBottom: '8px',
        color: '#35323C',
        marginTop: '10px',
        marginBottom: '5px',
    },
}))

const ScenarioGenius = (props) => {
    const { computedMatch } = props
    const preambleRoleplay = 'Create a long conversation based on the text. Make the conversation at least 10 parts.'
    const preambleVocabularyLesson = 'Teach at least ten suitable words and phrases about '
    const [title, setTitle] = React.useState('')
    const [smartDescription, setSmartDescription] = React.useState('')
    const [smartRules, setSmartRules] = React.useState('')
    const [smartPreamble, setSmartPreamble] = React.useState(preambleRoleplay)
    const [smartPromptRole, setSmartPromptRole] = React.useState('Employee')
    const [smartCompanyName, setSmartCompanyName] = React.useState('')
    const [placeholderTextRolePlay, setPlaceholderTextRolePlay] = React.useState(
        'you play Cabin Crew serving a customer. The customer asks for a free upgrade.'
    )
    const [placeholderTextVocabulary, setPlaceholderTextVocabulary] = React.useState(
        'the language related to working as airline Cabin Crew.'
    )
    const [placeholderTextMultipleLessons, setPlaceholderTextMultipleLessons] = React.useState(
        'working as Cabin Crew on a flight.'
    )

    const [lessonType, setLessonType] = React.useState(GeniusTypes.Roleplay)

    const createARoleplayWhereEnglish = 'Create a role play where'
    const createARoleplayWhereNorwegian = 'Lag et rollespill hvor'
    const [createARoleplayWhereTextfield, setCreateARoleplayWhereTextfield] = React.useState('')

    const createVocabAboutEnglish = 'Create a vocabulary lesson about'
    const createVocabAboutNorwegian = 'Lag en vokabularleksjon om'
    const [createVocabAboutTextfield, setCreateVocabAboutTextfield] = React.useState('')

    const createComplianceAboutEnglish = 'Create a compliance training lesson about'
    const createComplianceAboutNorwegian = 'Lag en opplæringsleksjon om'
    const [createComplianceAboutTextfield, setCreateComplianceAboutTextfield] = React.useState('')

    const otherInfoRoleplayEnglish = 'Our policy does not give free upgrades.'
    const otherInfoRoleplayNorwegian = 'Reglene våre tillater ikke gratis oppgraderinger.'
    const otherInfoVocabEnglish = "'Aeroplane' or 'Welcome to American Airlines!'"

    const descriptionVocabularyExplanationEnglish = "'Aeroplane' or 'Welcome to American Airlines!'"
    const descriptionVocabularyExplanationNorwegian = "'Fly' eller 'Velkommen til American Airlines!'."
    const [descriptionVocabularyExplanationTextfield, setDescriptionVocabularyExplanationTextfield] = React.useState('')

    const descriptionRoleplayEnglish =
        'Describe anything important regarding the lesson, or just paste any training documentation here. Maximum 500 words.'
    const descriptionRoleplayNorwegian =
        'Beskriv noe viktig angående leksjonen, eller lim inn opplæringsdokumentasjon. Maks 500 ord til sammen.'
    const [descriptionRoleplayTextfield, setDescriptionRoleplayTextfield] = React.useState('')

    const descriptionVocabularyEnglish =
        'This can be a word or a phrase depending on what type of lesson you are creating.'
    const descriptionVocabularyNorwegian =
        'Dette kan være et ord eller en frase, avhengig av hvilken type leksjon du lager.'
    const [descriptionVocabularyTextfield, setDescriptionVocabularyTextfield] = React.useState('')

    const descriptionExampleComplianceEnglish = 'our uniform policy.'
    const descriptionExampleComplianceNorwegian = 'uniformsreglementet vårt'
    const [descriptionExampleComplianceTextfield, setDescriptionExampleComplianceTextfield] = React.useState('')

    const descriptionExampleVocabularyEnglish = 'the language related to working as airline Cabin Crew.'
    const descriptionExampleVocabularyNorwegian = 'språk knyttet til å arbeide som kabinmannskap.'
    const [descriptionExampleVocabularyTextfield, setDescriptionExampleVocabularyTextfield] = React.useState('')

    const descriptionExplanationComplianceEnglish =
        "Type or paste in a few words or up to 500 words of the company's training documents that might be relevant for this lesson."
    const descriptionExplanationComplianceNorwegian =
        'Skriv eller lim inn noen ord, opp til 500 ord til sammen, av selskapets opplæringsdokumentasjon som kan være relevant for denne leksjonen.'
    const [descriptionExplanationComplianceTextfield, setDescriptionExplanationComplianceTextfield] = React.useState('')

    const descriptionExampleRoleplayEnglish =
        'you play Cabin Crew serving a customer. The customer asks for a free upgrade.'
    const descriptionExampleRoleplayNorwegian =
        'du spiller kabinmannskap og serverer en kunde. Kunden spør om en gratis oppgradering.'
    const [descriptionExampleRoleplayTextfield, setDescriptionExampleRoleplayTextfield] = React.useState('')

    const otherInfoComplianceEnglish =
        'E.g. McDonalds has certain guidelines for each of our employees to follow. Employee Uniforms New employees will be provided:  Uniform shirts - Hat - Nametag You must provide your own pants. Acceptable uniform pants are black dress pants. Unacceptable pants include, but are not limited to: - Jencos - Wide-legged jeans - Jeans with wide stitching on the seams - Warm-up pants - Running suit pants You are responsible for cleaning and maintaining your uniform. You may not come in to work if you are not completely dressed in uniform (shirt, pants, hat, and nametag).'
    const otherInfoComplianceNorwegian =
        'McDonalds har visse retningslinjer som alle våre ansatte må følge. Nye ansatte vil få: uniform - skjorter - lue - navneskilt. Du må selv sørge for bukser. Som uniformsbukse aksepterer vi svarte dressbukser. Uakseptable bukser inkluderer, men er ikke begrenset til: Jencos, jeans med vid legg, jeans med brede sømmer, treningsbukser av ulike slag. Du er selv ansvarlig for å vaske og vedlikeholde uniformen din. Du kan ikke stille på jobb dersom du ikke er fullt kledd i uniform (skjorte, bukser, lue og navneskilt)'
    const [otherInfoComplianceTextfield, setOtherInfoComplianceTextfield] = React.useState('')

    const [otherInformationTextfield, setOtherInformationTextfield] = React.useState('')

    const [fileExtension, setFileExtension] = React.useState(null)
    const [fileType, setFileType] = React.useState(null)
    const [startedCreation, setStartedCreation] = React.useState(false)
    const [brief, setBrief] = React.useState('')
    const [shortDescription, setShortDescription] = React.useState('')
    const [selectedImageUrl, setSelectedImageUrl] = React.useState(null)
    const [backgroundImageBlob, setBackgroundImageBlob] = React.useState(null)
    const [serverImageUrl, setServerImageUrl] = React.useState('')
    const [scenarioImages, setScenarioImages] = React.useState([])
    const [languageId, setLanguageId] = React.useState(1)
    const [defaultLanguageId, setDefaultLanguageId] = React.useState(3)

    const [titleLanguageSelectionOption, setTitleLanguageSelectionOption] = React.useState(1)
    const [briefLanguageSelectionOption, setBriefLanguageSelectionOption] = React.useState(0)
    const [shortDescriptionLanguageSelectionOption, setShortDescriptionLanguageSelectionOption] = React.useState(0)

    // auto attatch to course (added from a course)
    const queryParamsSearch = useLocation().search
    const [attachToTopicId, setAttachToTopicId] = React.useState(null)

    useEffect(() => {
        const createFromTopic = new URLSearchParams(queryParamsSearch).get('createFromTopic')
        if (createFromTopic === 'true') {
            const l2Id = new URLSearchParams(queryParamsSearch).get('l2Id')
            const defaultL1Id = new URLSearchParams(queryParamsSearch).get('defaultL1Id')
            const topicId = new URLSearchParams(queryParamsSearch).get('topicId')
            if (l2Id && defaultL1Id && topicId) {
                setAttachToTopicId(topicId)
                setLanguageId(l2Id)
                setDefaultLanguageId(defaultL1Id)
                switch (l2Id) {
                    case '1':
                    case '26':
                        setOtherInformationTextfield(otherInfoRoleplayEnglish)
                        setCreateARoleplayWhereTextfield(createARoleplayWhereEnglish)
                        setCreateVocabAboutTextfield(createVocabAboutEnglish)
                        setOtherInfoComplianceTextfield(otherInfoComplianceEnglish)
                        setCreateComplianceAboutTextfield(createComplianceAboutEnglish)
                        setDescriptionRoleplayTextfield(descriptionRoleplayEnglish)
                        setDescriptionVocabularyTextfield(descriptionVocabularyEnglish)
                        setDescriptionExampleComplianceTextfield(descriptionExampleComplianceEnglish)
                        setDescriptionExampleVocabularyTextfield(descriptionExampleVocabularyEnglish)
                        setDescriptionExampleRoleplayTextfield(descriptionExampleRoleplayEnglish)
                        setDescriptionVocabularyExplanationTextfield(descriptionVocabularyExplanationEnglish)
                        setDescriptionExplanationComplianceTextfield(descriptionExplanationComplianceEnglish)
                        break
                    case '3':
                        setOtherInformationTextfield(otherInfoRoleplayNorwegian)
                        setCreateARoleplayWhereTextfield(createARoleplayWhereNorwegian)
                        setCreateVocabAboutTextfield(createVocabAboutNorwegian)
                        setOtherInfoComplianceTextfield(otherInfoComplianceNorwegian)
                        setCreateComplianceAboutTextfield(createComplianceAboutNorwegian)
                        setDescriptionRoleplayTextfield(descriptionRoleplayNorwegian)
                        setDescriptionVocabularyTextfield(descriptionVocabularyNorwegian)
                        setDescriptionExampleComplianceTextfield(descriptionExampleComplianceNorwegian)
                        setDescriptionExampleVocabularyTextfield(descriptionExampleVocabularyNorwegian)
                        setDescriptionExampleRoleplayTextfield(descriptionExampleRoleplayNorwegian)
                        setDescriptionVocabularyExplanationTextfield(descriptionVocabularyExplanationNorwegian)
                        setDescriptionExplanationComplianceTextfield(descriptionExplanationComplianceNorwegian)
                        break
                }
            }
        }
        if (props.lesson.geniusCreationProgress === 1) {
            setStartedCreation(false)
        }
    }, [])

    const handleCreateScenario = (event, value) => {
        setStartedCreation(true)
        const { organization } = props
        var finalisedFile = null
        var briefLanguageId = briefLanguageSelectionOption === 0 ? defaultLanguageId : languageId
        var shortDescriptionLanguageId = shortDescriptionLanguageSelectionOption === 0 ? defaultLanguageId : languageId
        var titleLanguageId = titleLanguageSelectionOption === 0 ? defaultLanguageId : languageId
        let courseGroupId = computedMatch.params.courseGroupId

        // props.dispatch(lessonActions.geniusScenario(organization.selectedOrganization, title, titleLanguageId, brief, briefLanguageId, shortDescription, shortDescriptionLanguageId, serverImageUrl, finalisedFile, languageId, defaultLanguageId, attachToTopicId, courseGroupId, smartDescription, smartRules, smartPreamble, smartPromptRole, smartCompanyName, lessonType, props.metadata.metadata.languages));
    }

    const handleChangeLessonType = (e) => {
        setLessonType(e.target.value)
        switch (e.target.value) {
            case GeniusTypes.Roleplay:
                setSmartPreamble(preambleRoleplay)
                setSmartPromptRole('Employee')
                break
            case GeniusTypes.Vocabulary:
                setSmartPreamble(preambleVocabularyLesson)
                setSmartPromptRole('1')
                break
            default:
                return
        }
    }

    const smartCreate = () => {
        const { lesson } = props
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
                        <Typography variant="body" className={classes.scenarioDescriptionText}>
                            This is a beta service from Capeesh Research and Development.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.sectionHeaderStyle}>Company name</Typography>
                    </Grid>
                    <Grid item className={classes.scenarioTextField}>
                        <TextField
                            disabled={props.lesson?.geniusCreationProgress >= 0 && startedCreation === true}
                            hiddenLabel
                            id="filled-basic"
                            variant="filled"
                            placeholder={
                                lessonType === GeniusTypes.Compliance ? 'e.g. McDonalds' : 'e.g. American Airlines'
                            }
                            value={smartCompanyName}
                            onChange={(event) => {
                                setSmartCompanyName(event.target.value)
                            }}
                            InputProps={{ disableUnderline: true }}
                            style={{ minWidth: '200px' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                        <Typography className={classes.sectionHeaderStyle}>Describe the lesson</Typography>
                    </Grid>
                    <Grid container>
                        <Grid item style={{ minWidth: '250px' }}>
                            <FormControl sx={{ m: 1 }}>
                                <Select
                                    disabled={props.lesson?.geniusCreationProgress >= 0 && startedCreation === true}
                                    value={lessonType}
                                    onChange={handleChangeLessonType}
                                    displayEmpty
                                    inputProps={{
                                        'aria-label': 'Without label',
                                    }}>
                                    <MenuItem value={GeniusTypes.Roleplay}>{createARoleplayWhereTextfield}</MenuItem>
                                    <MenuItem value={GeniusTypes.Vocabulary}>{createVocabAboutTextfield}</MenuItem>
                                    <MenuItem value={GeniusTypes.Compliance}>{createComplianceAboutTextfield}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.scenarioTextField} xs>
                            <TextField
                                disabled={props.lesson?.geniusCreationProgress >= 0 && startedCreation === true}
                                hiddenLabel
                                id="filled-basic"
                                variant="filled"
                                multiline
                                placeholder={
                                    lessonType === GeniusTypes.Roleplay
                                        ? descriptionExampleRoleplayTextfield
                                        : lessonType === GeniusTypes.Vocabulary
                                          ? descriptionExampleVocabularyTextfield
                                          : lessonType === GeniusTypes.Compliance
                                            ? descriptionExampleComplianceTextfield
                                            : placeholderTextMultipleLessons
                                }
                                value={smartDescription}
                                onChange={(event) => {
                                    setSmartDescription(event.target.value)
                                }}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                        <Typography className={classes.sectionHeaderStyle}>
                            {lessonType === GeniusTypes.Roleplay
                                ? 'Other information'
                                : lessonType === GeniusTypes.Compliance
                                  ? 'Company training documentation'
                                  : 'One example'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioDescription}>
                        <Typography variant="body" className={classes.scenarioDescriptionText}>
                            {lessonType === GeniusTypes.Roleplay
                                ? descriptionRoleplayTextfield
                                : lessonType === GeniusTypes.Compliance
                                  ? descriptionExplanationComplianceTextfield
                                  : descriptionVocabularyTextfield}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.scenarioTextField}>
                        <TextField
                            disabled={props.lesson?.geniusCreationProgress >= 0 && startedCreation === true}
                            hiddenLabel
                            multiline
                            minRows={lessonType === GeniusTypes.Roleplay ? 5 : 1}
                            id="filled-basic"
                            variant="filled"
                            placeholder={
                                lessonType === GeniusTypes.Roleplay
                                    ? otherInformationTextfield
                                    : lessonType === GeniusTypes.Compliance
                                      ? otherInfoComplianceTextfield
                                      : descriptionVocabularyExplanationTextfield
                            }
                            value={smartRules}
                            onChange={(event) => {
                                setSmartRules(event.target.value)
                            }}
                            fullWidth
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                </Grid>
                {props.lesson?.geniusCreationProgress >= 0 && startedCreation === true && (
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                        <Box sx={{ width: '100%' }}>
                            <Typography
                                variant="body"
                                className={classes.scenarioDescriptionText}
                                style={{ marginBottom: '20px' }}>
                                Building your lesson. This may take up to 60 seconds, please wait...
                            </Typography>
                            <LinearProgress
                                variant="indeterminate"
                                value={props.lesson.geniusCreationProgress}
                                style={{ marginTop: '20px' }}
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                    <Button
                        disabled={
                            smartCompanyName === '' ||
                            smartDescription === '' ||
                            (props.lesson?.geniusCreationProgress >= 0 && startedCreation === true)
                        }
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleCreateScenario}
                        className={classes.margin}
                        style={{
                            maxWidth: '240px',
                            minWidth: '240px',
                            fontSize: '26px',
                            textTransform: 'none',
                        }}>
                        Create lesson
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }

    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            <Grid className={classes.middlePanel} item xs={12} sm={12} md={12}>
                <Grid container className={classes.stepperSection}>
                    {smartCreate()}
                </Grid>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization, topic, lesson, metadata } = state
    return {
        organization,
        topic,
        lesson,
        metadata,
    }
}

ScenarioGenius.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioGenius)
