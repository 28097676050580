import { configWithAuth, configWithoutAuth } from '../helpers'
import {
    makeGetRequest,
    makePostRequest,
    makePutRequest,
    makeDeleteRequest,
    makeRanddPostRequest,
} from '../helpers/api'

export const randdService = {
    sentenceSimilarity: (payload) =>
        makeRanddPostRequest(
            'https://devapi.capeesh.com/sentence/similarity/' + payload.l2Lang + '/',
            JSON.stringify(payload),
            configWithAuth()
        ),
    sentenceSuitable: (payload) =>
        makeRanddPostRequest('https://devapi.capeesh.com/suitability/', JSON.stringify(payload), configWithAuth()),
}
