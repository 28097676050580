import { authConstants, metadataConstants } from '../actions'

const initialState = {
    metadata: null,
}

export function metadata(state = initialState, action) {
    switch (action.type) {
        case metadataConstants.GET_METADATA.REQUEST:
            return {
                ...state,
            }
        case metadataConstants.GET_METADATA.SUCCESS:
            return {
                ...state,
                metadata: action.data,
            }
        case metadataConstants.GET_METADATA.FAILURE:
            return {
                ...state,
            }

        case authConstants.LOGOUT.SUCCESS:
            return initialState
        default:
            return state
    }
}
