import { asyncRequest } from './asyncRequest'

export const randdConstants = {
    SENTENCE_SIMILARITY: new asyncRequest('SENTENCE_SIMILARITY'),
    SENTENCE_SUITABLE: new asyncRequest('SENTENCE_SUITABLE'),
    GENERAL_RESET: new asyncRequest('GENERAL_RESET'),
}

export const randdActions = {
    sentenceSimilarity(originalSentence, toCheckSentence, l2Lang) {
        return {
            type: randdConstants.SENTENCE_SIMILARITY.REQUEST,
            payload: {
                originalSentence,
                toCheckSentence,
                l2Lang,
            },
        }
    },

    sentenceSuitable(
        sentence,
        translation,
        language1,
        language2,
        maxNumberOfWords,
        maxWordLength,
        words,
        difficultyLevel
    ) {
        return {
            type: randdConstants.SENTENCE_SUITABLE.REQUEST,
            payload: {
                sentence,
                translation,
                language1,
                language2,
                maxNumberOfWords,
                maxWordLength,
                words,
                difficultyLevel,
            },
        }
    },
    generalReset() {
        return {
            type: randdConstants.GENERAL_RESET.REQUEST,
            payload: {},
        }
    },
}
