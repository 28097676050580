import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { TextField } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getFlagAssetForLanguageId } from '../../../Logic/FlagUnicodeLogic'
import { CapeeshLanguage } from '../../../Logic/CapeeshConstants'
import ImageUploadWithCropComponent from '../../../Components/ImageUpload/ImageUploadWithCropComponent'
import { ImageCropType } from '../../../Logic/CourseGroupConstants'
import TextFieldWithLanguageSelector from '../Scenarios/ScenarioComponents/TextFieldWithLanguageSelector'
import { ScenarioImageSelection } from '../../../Logic/ScenarioConstants'

const useStyles = makeStyles((theme) => ({
    courseGroupCreateTextField: {
        minWidth: '200px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
    },
    languageForm: {
        margin: '16px 0px',
        width: '300px',
    },
    baseClass: {
        width: '500px',
        backgroundColor: 'white',
        paddingRight: '36px',
        paddingLeft: 36,
        paddingBottom: '36px',
        borderRadius: '8px',
    },
    largeTexts: {
        alignSelf: 'stretch',
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 500,
        marginBottom: 8,
    },
    largeSubTexts: {
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
    },
}))

const CreateCourseGroupNameAndImageSelection = (props) => {
    const classes = useStyles()

    const [courseGroupName, setCourseGroupName] = useState(props.mainText)
    const [courseGroupDescription, setCourseGroupDescription] = useState(props.cardDescription)

    const [selectedImageId, setSelectedImageId] = useState(props.selectedCourseGroupImageId)
    const [selectedImageBlob, setSelectedImageBlob] = useState(props.selectedImageForCourseGroup)

    useEffect(() => {
        props.setSelectedImageForCourseGroup(selectedImageBlob)
    }, [selectedImageBlob])

    useEffect(() => {
        props.setMainText(courseGroupName)
    }, [courseGroupName])

    useEffect(() => {
        props.setCardDescription(courseGroupDescription)
    }, [courseGroupDescription])

    useEffect(() => {
        props.setSeletedGroupGroupImageId(selectedImageId)
    }, [selectedImageId])

    return (
        <div className={classes.baseClass}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            maxWidth: '600px',
                            textAlign: 'center',
                            color: '#232B35',
                            fontFamily: 'Rubik',
                            fontsize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginBottom: '16px',
                        }}>
                        Choose course name and image
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                    <div className={classes.largeTexts}>
                        {'What is the course called? '}
                        <span className={classes.largeSubTexts}>(required)</span>
                    </div>
                    <TextFieldWithLanguageSelector
                        fullWidth={true}
                        text={courseGroupName}
                        onChange={(e) => setCourseGroupName(e.target.value)}
                        style={{
                            borderRadius: '10px',
                            paddingRight: '5px',
                        }}
                        soloLanguageId={props.l2Id}
                        canChangeLanguage={false}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 32 }}>
                    <div className={classes.largeTexts}>
                        {'Give a short description of the course '}
                        <span className={classes.largeSubTexts}>(required)</span>
                    </div>
                    <TextFieldWithLanguageSelector
                        fullWidth={true}
                        text={courseGroupDescription}
                        onChange={(event) => setCourseGroupDescription(event.target.value)}
                        style={{
                            borderRadius: '10px',
                            paddingRight: '5px',
                        }}
                        soloLanguageId={props.l2Id}
                        canChangeLanguage={false}
                    />
                </Grid>
            </Grid>
            <div>
                <div className={classes.largeTexts}>
                    {'Add a course image '}
                    <span className={classes.largeSubTexts}>(required)</span>
                </div>
                <ImageUploadWithCropComponent
                    defaultSelectedOption={ScenarioImageSelection.Search}
                    aspectRatioType={ImageCropType.CourseGroup}
                    saveImage={setSelectedImageId}
                    setSelectedImageBlob={setSelectedImageBlob}
                />
            </div>
        </div>
    )
}

CreateCourseGroupNameAndImageSelection.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseGroupNameAndImageSelection)
