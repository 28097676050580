import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { organizationActions } from '../../../_state/actions'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { CapeeshColors } from '../../../assets/ColorPalette'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { FormattedMessage } from 'react-intl'
import TableBody from '@mui/material/TableBody'

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '15%',
        marginRight: '40px',
        marginTop: '40px',
    },
    tableHeaderCell: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'Rubik',
    },

    tableRow: {
        display: 'flex',
        paddingBottom: '8px',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
    },

    tableRowCellName: {
        color: CapeeshColors.Primary600,
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
    },
    userBody: {
        marginTop: '10px',
    },
}))

const AdministratorReporting = (props) => {
    const classes = useStyles()

    const [organizationStats, setOrganizationStats] = useState([])

    useEffect(() => {
        // fetch factboxes for all organizations
        if (props.organization.myOrganizations) {
            props.organization.myOrganizations.forEach((organization) => {
                props.dispatch(organizationActions.getOrganizationFactBoxStats(organization.id))
            })
        }
    }, [])

    const formatMinutes = (min) => {
        const hours = min / 60
        const flooredHours = Math.floor(hours)
        const minutes = (hours - flooredHours) * 60
        const roundedMinutes = Math.round(minutes)

        if (flooredHours === 0 && roundedMinutes === 0) return ''

        let minutesString = roundedMinutes + ' m'

        if (flooredHours === 0) {
            return minutesString + '.'
        }

        let rhoursString = flooredHours + ' h'

        return rhoursString + ' ' + minutesString + '.'
    }

    useEffect(() => {
        const tempOrganziations = []
        if (!props.organization.myOrganizations) {
            return
        }
        props.organization.myOrganizations.forEach((org) => {
            const orgData = {
                organizationId: org.id,
                name: org.name,
                numberOfStudents: org.usedLicenses,
                minutesPlayed: -1,
                numberOfOnboardedUsers: -1,
                numberOfLessonsPassed: -1,
            }

            if (org.id in props.organization.organizationIdToFactBoxStats) {
                const orgFactBoxData = props.organization.organizationIdToFactBoxStats[org.id]

                orgData.minutesPlayed = orgFactBoxData.sessionMinutes
                orgData.numberOfOnboardedUsers = orgFactBoxData.numberOfOnboardedUsers
                orgData.numberOfLessonsPassed = orgFactBoxData.numberOfLessonsPassed
            }
            tempOrganziations.sort((a, b) => a.name.localeCompare(b.name))
            tempOrganziations.push(orgData)
        })

        setOrganizationStats(tempOrganziations)
    }, [props.organization])

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ marginTop: '25px' }}>
                        <FormattedMessage id="admin.org.stats.header" defaultMessage="Organization statistics" />
                    </Typography>
                </Grid>

                <Grid xs={12}>
                    <Typography variant="body2" className={classes.userBody}>
                        <FormattedMessage
                            id="admin.org.stats.subHeader"
                            defaultMessage="Here you can see a overall usage of the organizations you have access to"
                        />
                    </Typography>
                </Grid>
            </Grid>

            <div style={{ marginTop: '20px', marginBottom: '40px' }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 400, backgroundColor: CapeeshColors.OffWhite }}
                        size="small"
                        aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell width="40%">
                                    <Typography className={classes.tableHeaderCell}>
                                        <FormattedMessage
                                            id="dashboard.classroom.table.organization"
                                            defaultMessage="Organization"
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    <Typography className={classes.tableHeaderCell}>
                                        <FormattedMessage
                                            id="dashboard.classroom.table.students"
                                            defaultMessage="Number of Students"
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    <Typography className={classes.tableHeaderCell}>
                                        <FormattedMessage
                                            id="dashboard.classroom.table.apptime"
                                            defaultMessage="App time"
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    <Typography className={classes.tableHeaderCell}>
                                        <FormattedMessage
                                            id="dashboard.classroom.table.courses"
                                            defaultMessage="Onboarded Students"
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%" align="right">
                                    <Typography className={classes.tableHeaderCell}>
                                        <FormattedMessage
                                            id="dashboard.classroom.table.courses"
                                            defaultMessage="Lessons passed"
                                        />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizationStats.map((orgData, index) => (
                                <TableRow
                                    key={orgData.id}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? CapeeshColors.Gray5 : CapeeshColors.OffWhite,
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        height: '45px',
                                    }}>
                                    <TableCell width="40%" component="th" scope="row">
                                        <Typography className={classes.tableRowCellName}>{orgData.name}</Typography>
                                    </TableCell>
                                    <TableCell width="20%" align="right">
                                        {orgData.numberOfStudents}
                                    </TableCell>
                                    <TableCell width="20%" align="right">
                                        {orgData.minutesPlayed > -1
                                            ? formatMinutes(orgData.minutesPlayed)
                                            : 'No activity'}
                                    </TableCell>
                                    <TableCell width="20%" align="right">
                                        {orgData.numberOfOnboardedUsers > -1 ? orgData.numberOfOnboardedUsers : ''}
                                    </TableCell>
                                    <TableCell width="20%" align="right">
                                        {orgData.numberOfLessonsPassed > -1 ? orgData.numberOfLessonsPassed : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

AdministratorReporting.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorReporting)
