import axios from 'axios'
import { AUTO_COURSE_CREATION_API_ROOT } from './api-config'

export const makeACCPostRequest = (urlExtension, data = {}, config) =>
    axios.post(AUTO_COURSE_CREATION_API_ROOT + urlExtension, data, config)
export const makeACCGetRequest = (urlExtension, config) =>
    axios.get(AUTO_COURSE_CREATION_API_ROOT + urlExtension, config)
export const makeACCPutRequest = (urlExtension, data = {}, config) =>
    axios.put(AUTO_COURSE_CREATION_API_ROOT + urlExtension, data, config)
export const makeACCDeleteRequest = (urlExtension, data = {}, config) =>
    axios.delete(AUTO_COURSE_CREATION_API_ROOT + urlExtension, { ...config, data: data })
export const makeACCPatchRequest = (urlExtension, data = {}, config) =>
    axios.patch(AUTO_COURSE_CREATION_API_ROOT + urlExtension, data, config)
