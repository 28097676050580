import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Button, makeStyles } from '@material-ui/core'
import CapeeshCreateFromPage from '../Assets/creator_front_page.png'
import { ReactComponent as CapeeshCreateLogo } from '../Assets/capeesh_create_logo.svg'
import { FormattedMessage } from 'react-intl'
import { CapeeshColors } from '../../../../assets/ColorPalette'
import { AddOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        gap: '64px',
        paddingLeft: '10%',
        paddingRight: '10%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '5%',
            paddingRight: '5%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '2%',
            paddingRight: '2%',
        },
        minHeight: '95vh',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '50%',
    },
    rightSideContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
        gap: '16px',
        color: CapeeshColors.DarkText,
        width: '50%',
    },
    welcomeTitle: {
        color: CapeeshColors.DarkText,
        fontFamily: 'Rubik',
        fontsize: '32px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    welcomeDescription: {
        color: CapeeshColors.DarkText,
        fontFamily: 'Rubik',
        fontsize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    leftSideButtons: {
        background: CapeeshColors.Primary600,
        color: 'white',
        textTransform: 'none',
        borderRadius: '12px',
        width: '300px',
    },
}))

const CreatorNoCourse = ({ onCreateNewCourseGroup }) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div className={classes.imageContainer}>
                <img width={'100%'} src={CapeeshCreateFromPage} alt="Capeesh create welcome image" />
            </div>
            <div className={classes.rightSideContainer}>
                <CapeeshCreateLogo />
                <Typography variant="h4" className={classes.welcomeTitle}>
                    Welcome to the Capeesh Authoring Platform!
                </Typography>
                <Typography variant="body2" className={classes.welcomeDescription}>
                    From here you can create fantastic learning content. Your list of courses is shown below. Each
                    course contains topics, which themselves contain lessons.
                </Typography>
                <Button
                    className={classes.leftSideButtons}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => onCreateNewCourseGroup()}>
                    <AddOutlined
                        sx={{
                            marginRight: '15px',
                            fontSize: '15px',
                            color: 'white',
                        }}
                    />
                    <FormattedMessage
                        id="dashboard.creator.button.start.cretnig.new.courses"
                        defaultMessage="Start creating new courses"
                    />
                </Button>
            </div>
        </div>
    )
}

CreatorNoCourse.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorNoCourse)
