import React, { useState } from 'react'
import { Grid, TextField, Select, Typography, MenuItem } from '@material-ui/core'
import Box from '@mui/material/Box'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import MultiChoiceAlternativeEditor from './MultiChoiceAlternativeEditor'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextFieldWithLanguageSelector from '../ScenarioComponents/TextFieldWithLanguageSelector'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '98px',
        top: 0,
        position: 'fixed',
        zIndex: 100,
    },
    grow: {
        flexGrow: 1,
    },
    imageViewer: {
        maxWidth: '150px',
        minWidth: '50px',
        height: 'auto',
    },

    textFields: {
        width: '428px',
        textColor: 'white',
    },
    textFieldAnswers: {
        width: '348px',
        textColor: 'white',
    },

    feedback: {
        width: '428px',
        textColor: 'white',
        marginBottom: '20px',
    },

    answerHeaders: {
        paddingTop: '12px',
        paddingBottom: '8px',
        marginLeft: '13px',
    },
    answersSection: {
        marginBottom: '10px',
    },
    tabs: {
        backgroundColor: 'white',
    },

    cardPlayButton: {
        height: '40px',
        backgroundColor: '#143349',
        display: 'flex',
        justify: 'flex-start',
        alignItems: 'center',
        width: '363px',
    },
    formControl: {
        borderRadius: '15px',
    },

    playIcon: {
        color: '#eee',
        fontSize: 36,
        marginLeft: '15px',
        marginRight: '15px',
        cursor: 'pointer',
    },

    nodeTitle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '30px',
        lineHeight: '22px',
        paddingBottom: '8px',
        color: '#35323C',
    },

    l1ChooserStyle: {
        marginLeft: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '60px',
        height: '60px',
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '7px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },

    buttonAnnouncement: {
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '40px',
        maxHeight: '40px',
        minWidth: '40px',
        minHeight: '40px',
        marginTop: '7px',
    },

    circularProgress: {
        color: '#eee',
        fontSize: 36,
        margin: '15px',
    },

    closeIcon: {
        color: 'white',
        paddingRight: '5px',
        cursor: 'pointer',
    },

    interactions: {
        paddingTop: '10px',
        marginLeft: '10px',
    },

    scenario: {
        paddingTop: '30px',
    },

    saveButton: {
        backgroundColor: '#143349',
        fontColor: 'white',
        color: 'white',
    },

    scenarioDescription: {
        paddingTop: '3px',
    },

    multilineColor: {
        backgroundColor: 'white',
        border: '1px solid #E5E5EA',
        borderRadius: '5px',
    },

    scenarioDescriptionText: {
        color: '#8e8e93',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
    },

    scenarioScenesPreviewSection: {
        marginTop: '20px',
    },

    scenarioMentorPreviewComponent: {
        marginRight: '13px',
    },

    divList: {
        maxHeight: 430,
        overflow: 'auto',
    },

    list: {
        height: '1000px',
    },

    ruleTextField: {
        marginBottom: '10px',
    },
    ruleTitle: {
        paddingTop: '30px',
        marginBottom: '10px',
    },
    definitionText: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    testImageText: {
        fontSize: 11,
    },
    explanationText: {
        fontSize: 12,
    },
    typographySpeakout: {
        marginBottom: '5px',
        color: '#8e8e93',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    editNodeSectionTitle: {
        width: '70px',
        boxShadow: 'none',
        flex: 1,
        fontSize: 36,
        color: 'black',
        fontFamily: 'Lato',
    },
    editNodeSectionDescription: {
        color: '#8E8E93',
        fontFamily: 'Lato',
        marginBottom: '10px',
    },

    languageDropDown: {
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '60px',
        height: '60px',
        border: '5px solid #143349',
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxWidth: '7px',
        maxHeight: '40px',
        minWidth: '78px',
        minHeight: '40px',
    },
}))

const MultiChoiceNodeQuestion = (props) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Box
                onMouseOver={(e) => {
                    props.onSetQuizQuestionInFocus(props.index)
                }}
                style={{
                    background: '#F2F2F2',
                    border: '2px solid #E5E5EA',
                    boxSizing: 'border-box',
                    borderRadius: '5px',
                    marginBottom: '10px',
                }}
                sx={{ boxShadow: 0 }}>
                <Grid container>
                    {props.shouldMinimise && (
                        <Grid item xs={1} sm={1} md={1} style={{ background: '#EDEDED' }}>
                            <DragIndicatorIcon
                                style={{
                                    width: 32,
                                    height: 32,
                                    marginTop: '5px',
                                }}
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={props.shouldMinimise ? 10 : 11}
                        sm={props.shouldMinimise ? 10 : 11}
                        md={props.shouldMinimise ? 10 : 11}
                        style={{ background: '#EDEDED' }}>
                        <Typography
                            style={{
                                fontFamily: 'Lato',
                                fontStyle: 'normal',
                                fontWeight: props.shouldMinimise ? 'normal' : 'bold',
                                fontSize: props.shouldMinimise ? '12px' : '22px',
                                lineHeight: '22px',
                                marginTop: '10px',
                                marginLeft: props.shouldMinimise ? '-10px' : '15px',
                                marginBottom: '10px',
                                color: '#35323C',
                            }}>
                            {props.shouldMinimise
                                ? props.testOption.text === ''
                                    ? 'No Question text added'
                                    : props.testOption.text
                                : 'Question ' + (props.index + 1)}
                        </Typography>

                        <FormControlLabel
                            style={{
                                marginTop: '-40px',
                                left: '500px',
                                minWidth: '200px',
                                display: 'flex',
                                textAlign: 'right',
                                alignItems: 'right',
                                justifyContent: 'right',
                                boxShadow: 'none',
                                fontSize: 6,
                                color: 'black',
                                fontFamily: 'Lato',
                            }}
                            control={
                                <Switch
                                    disabled={!props.canEditLesson}
                                    checked={
                                        props.node.testOptions[props.index].interactionScoringDynamicConfigurationV1Dto
                                            ?.shouldBeScored
                                    }
                                    onChange={(e, checked) => {
                                        props.onNodeInteractionScoringChanged(props.index, checked)
                                    }}
                                />
                            }
                            label={
                                props.node.testOptions[props.index].interactionScoringDynamicConfigurationV1Dto
                                    ?.shouldBeScored
                                    ? 'Scoring Enabled'
                                    : 'Scoring Disabled'
                            }
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} style={{ background: '#EDEDED' }}>
                        <IconButton
                            aria-label="delete"
                            style={{}}
                            onClick={(e) => {
                                props.handleMultiChoiceQuestionDeleted(props.index)
                            }}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                    {props.shouldMinimise === false && (
                        <React.Fragment>
                            <Grid container style={{ alignItems: 'center' }}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{
                                        marginBottom: '10px',
                                        marginLeft: '15px',
                                        marginRight: '15px',
                                    }}>
                                    <TextFieldWithLanguageSelector
                                        text={props.testOption ? props.testOption.text : ''}
                                        fullWidth={true}
                                        disabled={!props.canEditLesson}
                                        onChange={(e) =>
                                            props.handleUpdateMultiChoiceQuestionChanged(e.target.value, props.index)
                                        }
                                        onChangeLanguageLanguage={(event) =>
                                            props.onTestOptionTextLanguageTypeChange(
                                                event.target.value,
                                                props.testOption,
                                                props.index
                                            )
                                        }
                                        l2Id={props.languageId}
                                        l1Id={props.l1Id}
                                        soloLanguageId={props.l1Id}
                                        canChangeLanguage={true}
                                        displayL1OrL2={props.testOption.textLanguageId === props.languageId ? 1 : 0}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{
                                    marginBottom: '20px',
                                    marginLeft: '15px',
                                    marginRight: '15px',
                                }}>
                                <MultiChoiceAlternativeEditor
                                    node={props.node}
                                    testOption={props.testOption}
                                    testOptionIndex={props.index}
                                    canEditLesson={props.canEditLesson}
                                    handleUpdateMultiChoiceAlternativeChanged={
                                        props.handleUpdateMultiChoiceAlternativeChanged
                                    }
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Box>
        </React.Fragment>
    )
}

MultiChoiceNodeQuestion.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(MultiChoiceNodeQuestion)
