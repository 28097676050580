import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles((theme) => ({}))

const AutoCourseSuggestLessonDetailsFruitMachineColumn = (props) => {
    const classes = useStyles()
    const handleOnChange = (target) => {
        props.onChangeFruitMachineSelection(props.fruitMachineType, target.id)
    }

    const handleScroll = (scrollAmount) => {}

    const handleOnClick = (event, selection) => {
        props.onChangeFruitMachineSelection(props.fruitMachineType, selection)
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <IconButton
                    onMouseDown={(event) => handleScroll(-1)}
                    sx={{ display: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <KeyboardArrowUpIcon sx={{ fontSize: '28px' }} />
                </IconButton>
            </Grid>
            {props.textsArray
                .filter((item) => item.name !== props.selectedtext)
                .map(
                    (text, index) =>
                        index == 0 && (
                            <Grid
                                onClick={(event) => handleOnClick(event, text.name)}
                                item
                                xs={12}
                                style={{ textAlign: 'center', marginBottom: '7px' }}>
                                <Typography
                                    style={{
                                        color: '#C7C7CC',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '23.789px',
                                    }}>
                                    {text.friendlyName}
                                </Typography>
                            </Grid>
                        )
                )}
            <Grid
                item
                xs={12}
                style={{ textAlign: 'center', marginBottom: '7px' }}
                onClick={(event) =>
                    handleOnClick(event, props.textsArray.find((type) => type.name === props.selectedtext).name)
                }>
                <Typography
                    style={{
                        color: '#35323C',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '23.789px',
                    }}>
                    {props.textsArray.find((type) => type.name === props.selectedtext).friendlyName}
                </Typography>
            </Grid>
            {props.textsArray
                .filter((item) => item.name !== props.selectedtext)
                .map(
                    (text, index) =>
                        index > 0 && (
                            <Grid
                                item
                                xs={12}
                                style={{ textAlign: 'center', marginBottom: '7px' }}
                                onClick={(event) => handleOnClick(event, text.name)}>
                                <Typography
                                    style={{
                                        color: '#C7C7CC',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '23.789px',
                                    }}>
                                    {text.friendlyName}
                                </Typography>
                            </Grid>
                        )
                )}

            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <IconButton
                    onMouseDown={(event) => handleScroll(-1)}
                    sx={{ display: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <KeyboardArrowDownIcon sx={{ fontSize: '28px' }} />
                </IconButton>
            </Grid>
        </Grid>
    )
}

AutoCourseSuggestLessonDetailsFruitMachineColumn.propTypes = {}

function mapStateToProps(state) {
    const { autoCourseCreation } = state
    return {
        autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseSuggestLessonDetailsFruitMachineColumn)
