import { configWithAuth } from '../helpers'
import { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } from '../helpers/api'

export const overallService = {
    getGraphDataDailyUsageAggregated: (paylod) =>
        makeGetRequest('admin/stats/graph/daily/usage/aggregate', configWithAuth()),
    getGraphDataDailyUserOnboarding: (paylod) => makeGetRequest('admin/stats/graph/user/onboarding', configWithAuth()),

    getOverallSearchResult: (paylod) => makeGetRequest('admin/search' + paylod.queryParamAsText, configWithAuth()),
}
