import { Unity, useUnityContext } from 'react-unity-webgl'
import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle, useCallback, useRef } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { API_ROOT } from '../../../../_state/helpers'
import { connect } from 'react-redux'
import Dictaphone from './UnityLessonPreviewVoice'
import { GlobalEvents } from '../../../../assets/GlobalEvents'
import { lessonActions } from '../../../../_state/actions'

const useStyles = makeStyles((theme) => ({
    unitySizeStyle: {
        height: '75vh',
        width: 'calc(75vh * 0.62)',
    },
}))

const cacheControl = (url) => {
    // Caching enabled for .data and .bundle files.
    // Revalidate if file is up to date before loading from cache
    if (url.match(/\.data/) || url.match(/\.bundle/) || url.match(/\.wasm/)) {
        return 'must-revalidate'
    }

    // Caching enabled for .mp4 and .custom files
    // Load file from cache without revalidation.
    if (url.match(/\.mp3/) || url.match(/\.jpeg/) || url.match(/\.png/) || url.match(/\.jpg/)) {
        return 'immutable'
    }

    // Disable explicit caching for all other files.
    // Note: the default browser cache may cache them anyway.
    return 'no-store'
}

// Expected props
// props.customLessonId
// props.clickableSkipButton
// props.closeDialog
// props.disableMic

// Optional props
// props.onNodeIdChanged
// props.nodeId

const UnityLessonPreview = forwardRef((props, ref) => {
    const classes = useStyles()
    const [initUnity, setInitUnity] = useState(false)
    const [unityIsLoaded, setUnityIsLoaded] = useState(false)

    const path = '/build/unity/'
    const buildName = 'CapeeshWebGLBuild_v19'
    const unityConfig = {
        loaderUrl: path + buildName + '.loader.js',
        dataUrl: path + buildName + '.data',
        frameworkUrl: path + buildName + '.framework.js',
        codeUrl: path + buildName + '.wasm',
        productName: 'Capeesh',
        productVersion: '3.1.0',
        companyName: 'Capeesh',
        cacheControl: cacheControl,
    }
    const { unityProvider, isLoaded, loadingProgression, sendMessage, unload, addEventListener, removeEventListener } =
        useUnityContext(unityConfig)

    const unityRef = useRef(null)

    const unloadUnity = async () => {
        setUnityIsLoaded(false)

        OnQuitApp()

        try {
            unload().finally(() => {
                document.dispatchEvent(GlobalEvents.UnityLessonPreviewDialogClose)
            })
        } catch (e) {
            document.dispatchEvent(GlobalEvents.UnityLessonPreviewDialogClose)
        }
    }

    const InitUnityLessonPreview = () => {
        if (!isLoaded) {
            return
        }

        if (!initUnity) {
            setInitUnity(true)
            setUnityIsLoaded(true)
        }
    }

    const InitUnityLessonPreviewForCustomLessonId = (customLessonId, l1Id, nodeId, clickableSkipButton) => {
        let authTokenJson = localStorage.getItem('authToken')
        let authTokenObject = JSON.parse(authTokenJson)
        let authToken = authTokenObject?.token
        let validAuthToken = authToken != null && authToken !== ''
        if (validAuthToken === false) return

        let previewLessonDto = {
            environment: API_ROOT,
            authToken: authToken,
            customLessonId: customLessonId,
            l1Id: l1Id,
            nodeId: nodeId,
            clickableSkipButton: clickableSkipButton,
        }
        sendMessage('LessonPreviewController', 'Init', JSON.stringify(previewLessonDto).toString())
    }

    const OnUnfocus = () => {
        console.log('Happens unfocus')
        //sendMessage('VirtualKeyboard(Clone)', 'DisableKeyboardFromExternal');

        sendMessage('LessonPreviewController', 'OnUnFocusExternal')
    }

    const OnFocus = () => {
        //sendMessage('VirtualKeyboard(Clone)', 'EnableKeyboardFromExternal');
        console.log('Happens focus')

        sendMessage('LessonPreviewController', 'OnFocusExternal')
    }

    const OnQuitApp = () => {
        sendMessage('LessonPreviewController', 'CloseApp')
    }

    const InitUnityLessonFromParams = (customLessonId, l1Id, nodeId, clickableSkipButton, l2Id) => {
        props.dispatch(lessonActions.setSpeechRecognitionL2(l2Id))
        InitUnityLessonPreviewForCustomLessonId(customLessonId, l1Id, nodeId, clickableSkipButton)
    }

    const handleCloseDialog = () => {
        unloadUnity()
    }

    const handleReceiveNodeId = (nodeId) => {
        if (props.onNodeIdChanged != null) {
            props.onNodeIdChanged(nodeId)
        }
    }

    useEffect(() => {
        if (isLoaded && !initUnity) {
            InitUnityLessonPreview()
        }
    }, [isLoaded])

    useEffect(() => {
        if (initUnity) {
            document.dispatchEvent(GlobalEvents.UnityLessonPreviewOnLoad)
        }
    }, [initUnity])

    useEffect(() => {
        if (unityIsLoaded === false) {
            removeEventListener('ReceiveNodeId', handleReceiveNodeId)
        } else {
            addEventListener('ReceiveNodeId', handleReceiveNodeId)
        }
    }, [unityIsLoaded])

    useImperativeHandle(ref, () => {
        return {
            unloadUnity: unloadUnity,
            InitUnityLessonFromParams: InitUnityLessonFromParams,
            OnFocus: OnFocus,
            OnUnfocus: OnUnfocus,
        }
    })
    return (
        <Fragment>
            <Unity
                unityProvider={unityProvider}
                className={props.unitySizeStyle ? props.unitySizeStyle : classes.unitySizeStyle}
                style={{
                    visibility: isLoaded ? 'visible' : 'hidden',
                    height: 748,
                    width: 400,
                }}
                ref={unityRef}
            />

            {!props.disableMic && (
                <Dictaphone
                    addEventListener={addEventListener}
                    removeEventListener={removeEventListener}
                    sendMessage={sendMessage}
                    unityIsLoaded={unityIsLoaded}></Dictaphone>
            )}

            {!isLoaded && <Typography>Loading Application... {Math.round(loadingProgression * 100)}%</Typography>}
        </Fragment>
    )
})

function mapStateToProps(state) {
    const { lesson } = state
    return {
        lesson,
    }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(UnityLessonPreview)
