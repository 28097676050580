import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { TextField } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getFlagAssetForLanguageId } from '../../../Logic/FlagUnicodeLogic'
import { CapeeshLanguage } from '../../../Logic/CapeeshConstants'
import ImageUploadWithCropComponent from '../../../Components/ImageUpload/ImageUploadWithCropComponent'
import { ImageCropType } from '../../../Logic/CourseGroupConstants'

const useStyles = makeStyles((theme) => ({
    courseGroupCreateTextField: {
        minWidth: '200px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
    },
    languageForm: {
        margin: '16px 0px',
        width: '100%',
    },
    baseClass: {
        width: '500px',
        backgroundColor: 'white',
        paddingBottom: 36,
        paddingLeft: 36,
        paddingRight: 36,
        borderRadius: '8px',
    },
    largeTexts: {
        alignSelf: 'stretch',
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 500,
    },
    largeSubTexts: {
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    bottomContainer: {
        opacity: 0.5,
    },
    explanationText: {
        paddingTop: 8,
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    explanationSubText: {
        color: '#232B35',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 700,
    },
}))

const CreateCourseGroupLanguageSelection = (props) => {
    const classes = useStyles()

    const [l2Id, setL2Id] = useState(props.l2Id)
    const [defaultL1Id, setDefaultL1Id] = useState(props.l1Id)

    const availableL1LanguagesForNonEnglishL2 = [
        CapeeshLanguage.German,
        CapeeshLanguage.Spanish,
        CapeeshLanguage.French,
        CapeeshLanguage.Norwegian,
    ]

    const setL2IdAndRecheckL1Id = (newl2Id) => {
        setL2Id(newl2Id)
        if (newl2Id !== CapeeshLanguage.EnglishUK) {
            setDefaultL1Id(CapeeshLanguage.EnglishUK)
        }

        if (newl2Id === defaultL1Id) {
            setDefaultL1Id(-1)
        }
    }

    useEffect(() => {
        props.setL1Id(defaultL1Id)
    }, [defaultL1Id])

    useEffect(() => {
        props.setL2Id(l2Id)
    }, [l2Id])

    return (
        <div className={classes.baseClass}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <Typography
                        style={{
                            maxWidth: '600px',
                            textAlign: 'center',
                            color: '#232B35',
                            fontFamily: 'Rubik',
                            fontsize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                        }}>
                        Choose language to teach, and default translation language
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.languageForm}>
                        <div className={classes.largeTexts}>
                            {'Which language are you teaching? '}
                            <span className={classes.largeSubTexts}>(required)</span>
                        </div>
                        <InputLabel htmlFor="language-simple"></InputLabel>
                        <Select
                            value={l2Id}
                            onChange={(event) => setL2IdAndRecheckL1Id(event.target.value)}
                            inputProps={{
                                name: 'languageTarget',
                                id: 'languageTarget',
                            }}
                            style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: 'white',
                                marginTop: '20px',
                                marginBottom: '20px',
                                marginRight: '20px',
                                borderRadius: '5px',
                                marginLeft: 'auto',
                            }}
                            variant={'outlined'}>
                            {props.metadata?.metadata?.languages
                                ?.filter((x) => x.canBeUsedAsL2)
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((language, index) => (
                                    <MenuItem key={language.id} value={language.id}>
                                        <Grid container direction="row" className={classes.languageSelectorItem}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        fontSize: '32px',
                                                        marginLeft: '5px',
                                                    }}>
                                                    <img
                                                        width="30"
                                                        src={getFlagAssetForLanguageId(language.id)}
                                                        style={{ borderRadius: '50%' }}
                                                    />
                                                </Typography>
                                            </div>
                                            <Grid item xs={8} style={{}}>
                                                <Typography
                                                    style={{
                                                        maxWidth: '600px',
                                                        textAlign: 'left',
                                                        marginLeft: '16px',
                                                        marginTop: '10px',
                                                    }}>
                                                    {language.friendlyName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>

                <div className={l2Id === -1 ? classes.bottomContainer : ''}>
                    <Grid item xs={12}>
                        <div className={classes.largeTexts}>
                            {'Which language do you want translations in by\n' +
                                '                            default? '}
                            <span className={classes.largeSubTexts}>(required)</span>
                        </div>
                        <div className={classes.explanationText}>
                            <span className={classes.explanationSubText}>{'NB! '}</span>
                            The additional translations will be translated from the default translation language. You
                            can add as many additional translation languages as you wish later.
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {defaultL1Id !== CapeeshLanguage.EnglishUK && (
                            <FormControl className={classes.languageForm}>
                                <InputLabel htmlFor="language-simple"></InputLabel>
                                <Select
                                    value={defaultL1Id}
                                    onChange={(event) => setDefaultL1Id(event.target.value)}
                                    inputProps={{
                                        name: 'languageDefault',
                                        id: 'languageDefault',
                                    }}
                                    autoWidth
                                    variant={'outlined'}
                                    style={{ height: '50px' }}
                                    disabled={l2Id === -1}>
                                    {props.metadata?.metadata?.languages
                                        ?.filter(
                                            (x) =>
                                                x.id !== l2Id &&
                                                availableL1LanguagesForNonEnglishL2.indexOf(x.id) !== -1
                                        )
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((language, index) => (
                                            <MenuItem key={language.id} value={language.id}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    className={classes.languageSelectorItem}>
                                                    <div>
                                                        <Typography
                                                            style={{
                                                                fontSize: '32px',
                                                                marginLeft: '5px',
                                                            }}>
                                                            <img
                                                                width="30"
                                                                src={getFlagAssetForLanguageId(language.id)}
                                                                style={{ borderRadius: '50%' }}
                                                            />
                                                        </Typography>
                                                    </div>
                                                    <Grid item xs={8} style={{}}>
                                                        <Typography
                                                            style={{
                                                                width: '200px',
                                                                textAlign: 'left',
                                                                marginLeft: '16px',
                                                                marginTop: '10px',
                                                            }}>
                                                            {language.friendlyName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}

                        {defaultL1Id === CapeeshLanguage.EnglishUK && (
                            <FormControl className={classes.languageForm}>
                                <InputLabel htmlFor="language-simple"></InputLabel>
                                <Select
                                    value={defaultL1Id}
                                    disabled={true}
                                    inputProps={{
                                        name: 'languageDefault',
                                        id: 'languageDefault',
                                    }}
                                    autoWidth
                                    variant={'outlined'}
                                    style={{ height: '50px' }}>
                                    <MenuItem key={CapeeshLanguage.EnglishUK} value={CapeeshLanguage.EnglishUK}>
                                        <Grid container direction="row" className={classes.languageSelectorItem}>
                                            <div>
                                                <Typography
                                                    style={{
                                                        fontSize: '32px',
                                                        marginLeft: '5px',
                                                    }}>
                                                    <img
                                                        width="30"
                                                        src={getFlagAssetForLanguageId(CapeeshLanguage.EnglishUK)}
                                                        style={{ borderRadius: '50%' }}
                                                    />
                                                </Typography>
                                            </div>
                                            <Grid item xs={8} style={{}}>
                                                <Typography
                                                    style={{
                                                        width: '120px',
                                                        textAlign: 'left',
                                                        marginLeft: '16px',
                                                        marginTop: '10px',
                                                    }}>
                                                    English
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </div>
            </Grid>
        </div>
    )
}

CreateCourseGroupLanguageSelection.propTypes = {}

const mapStateToProps = (state) => {
    const { metadata } = state
    return {
        metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseGroupLanguageSelection)
