import { configWithAuth } from '../helpers'
import { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } from '../helpers/api'

export const organizationService = {
    createSchool: (payload) => makePostRequest('admin/organization', JSON.stringify(payload), configWithAuth()),
    getMyOrganizations: () => makeGetRequest('admin/organization/list', configWithAuth()),
    setOrganizationInfo: (payload) => makePutRequest('admin/organization', JSON.stringify(payload), configWithAuth()),
    inviteStudentsToSchool: (payload) =>
        makePostRequest('admin/organization/user', JSON.stringify(payload), configWithAuth()),
    getOrganizationUsers: (organizationId) =>
        makeGetRequest('admin/organization/' + organizationId + '/users', configWithAuth()),
    getInviteRolesAvailable: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/user/give/roles', configWithAuth()),
    getAllUserGroups: (organizationId) =>
        makeGetRequest('admin/usergroup/organization/' + organizationId, configWithAuth()),
    addUserGroupUser: (payload) => makePostRequest('admin/usergroup/user', JSON.stringify(payload), configWithAuth()),
    addUserGroupTopic: (payload) => makePostRequest('admin/usergroup/topic', JSON.stringify(payload), configWithAuth()),
    deleteUserGroupTopic: (payload) =>
        makeDeleteRequest('admin/usergroup/topic', JSON.stringify(payload), configWithAuth()),
    deleteOrganizationUser: (payload) =>
        makeDeleteRequest(
            'admin/organization/' + payload.organizationId + '/user/' + payload.userId,
            null,
            configWithAuth()
        ),
    getOrganizationFileAsset: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/assets', configWithAuth()),
    updateOrganizationFileAsset: (payload) =>
        makePostRequest(
            'admin/organization/' + payload.organizationId + '/asset',
            JSON.stringify(payload),
            configWithAuth()
        ),
    deleteOrganizationFileAsset: (payload) =>
        makeDeleteRequest(
            'admin/organization/' + payload.organizationId + '/delete/asset/' + payload.organizationFileAssetId,
            null,
            configWithAuth()
        ),
    excelExportFromTemplateOrgStats: (payload) =>
        makeGetRequest(
            'admin/organization/' + payload.organizationId + '/excel/template/metadata?useBase64=true',
            configWithAuth()
        ),
    excelExportFromTemplateClasssoomStats: (payload) =>
        makeGetRequest(
            'admin/organization/' +
                payload.organizationId +
                '/excel/usergroup/' +
                payload.classroomId +
                '/template/metadata?useBase64=true',
            configWithAuth()
        ),
    getAvailableCourseGroups: (organizationId) =>
        makeGetRequest('admin/course/group/available/organization/' + organizationId, configWithAuth()),

    // graph data
    getGraphDataHourlyOrganization: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/stats/graph/hourly', configWithAuth()),
    getGraphDataHourlyUserGroup: (payload) =>
        makeGetRequest(
            'admin/organization/' +
                payload.organizationId +
                '/user/group/' +
                payload.userGroupId +
                '/stats/graph/hourly',
            configWithAuth()
        ),

    getGraphDataDayOfWeekOrganization: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/stats/graph/dayofweek', configWithAuth()),
    getGraphDataDayOfWeekUserGroup: (payload) =>
        makeGetRequest(
            'admin/organization/' +
                payload.organizationId +
                '/user/group/' +
                payload.userGroupId +
                '/stats/graph/dayofweek',
            configWithAuth()
        ),

    getGraphDataDailyUsageAggregatedOrganization: (payload) =>
        makeGetRequest(
            'admin/organization/' + payload.organizationId + '/stats/graph/daily/usage/aggregate',
            configWithAuth()
        ),
    getGraphDataDailyUsageAggregatedUserGroup: (payload) =>
        makeGetRequest(
            'admin/organization/' +
                payload.organizationId +
                '/user/group/' +
                payload.userGroupId +
                '/stats/graph/daily/usage/aggregate',
            configWithAuth()
        ),

    getGraphDataDailyLessonAggregatedOrganization: (payload) =>
        makeGetRequest(
            'admin/organization/' + payload.organizationId + '/stats/graph/daily/lesson/aggregate',
            configWithAuth()
        ),
    getGraphDataDailyLessonAggregatedUserGroup: (payload) =>
        makeGetRequest(
            'admin/organization/' +
                payload.organizationId +
                '/user/group/' +
                payload.userGroupId +
                '/stats/graph/daily/lesson/aggregate',
            configWithAuth()
        ),

    getOrganizationFactBoxStats: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/stats', configWithAuth()),
    getOrganizationCourseGroupUsage: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/stats/course/group', configWithAuth()),

    // time zone location endpoints
    getOrganizationTimeZoneLocations: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/time/zone/list', configWithAuth()),
    setOrganizationTimeZoneLocations: (payload) =>
        makePostRequest(
            'admin/organization/' + payload.organizationId + '/time/zone',
            JSON.stringify(payload),
            configWithAuth()
        ),

    // organization course groups
    getOrganizationCourseGroups: (payload) =>
        makeGetRequest('admin/organization/' + payload.organizationId + '/course/group', configWithAuth()),
    addCourseGroupToOrganization: (payload) =>
        makePostRequest(
            'admin/organization/' + payload.organizationId + '/course/group/' + payload.courseGroupId,
            null,
            configWithAuth()
        ),
    removeCourseGroupFromOrganization: (payload) =>
        makeDeleteRequest(
            'admin/organization/' + payload.organizationId + '/course/group/' + payload.courseGroupId,
            null,
            configWithAuth()
        ),

    // organziation information
    updateOrganizationInformation: (payload) =>
        makePostRequest(
            'admin/organization/' + payload.organizationId + '/information',
            JSON.stringify(payload.updateOrganizationInformationDto),
            configWithAuth()
        ),

    // skills to show
    getSkillsCourseGroups: (payload) =>
        makeGetRequest('admin/course/group/skills/organization/' + payload.organizationId, configWithAuth()),
}
