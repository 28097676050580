import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import {
    AutoCourseCreateDialogStepType,
    AutoCourseCreatorProcessStages,
} from '../../../../Logic/AutoCourseCreationConstants'
import ACCScrollingTexts from '../AutoCourseCreateLanguage/Components/ACCScrollingTexts'
import { ReactComponent as FullLogoLanguage } from '../Images/capeesh_create_logo.svg'

const useStyles = makeStyles((theme) => ({
    topTextStyle: {
        color: 'white',
        textAlign: 'center',
        fontSize: 24,
        fontFamily: 'Rubik',
        fontWeight: '300',
        alignSelf: 'center',
        marginBottom: '4px',
    },
    infoTextFirstPage: {
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'italic',
        fontWeight: 400,
    },
    inputFieldStyle: {
        borderRadius: '16px 16px 0px 0',
        borderTop: '2px solid rgba(255, 255, 255, 0.32)',
        borderRight: '2px solid rgba(255, 255, 255, 0.32)',
        borderLeft: '2px solid rgba(255, 255, 255, 0.32)',
        background: 'rgba(255, 255, 255, 0.12)',
        padding: '15px',
        textAlign: 'center',
        width: '634px',
    },
    helpTextBox: {
        width: '605px',
        padding: '15px',
        textAlign: 'center',
        borderRadius: '0px 0px 8px 8px',
        background: 'rgba(255, 255, 255, 0.24)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    secondaryText: {
        width: '100%',
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: '18px',
    },
}))

const AutoCourseCreateIntroScreen = (props) => {
    // props.handleSetAuthorInputMetaData
    // props.authorMetadataDto

    const classes = useStyles()

    const texts = ['Help Text 1', 'Help Text 2', 'Help Text 3']

    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
        }, 7000)

        return () => clearInterval(timer)
    }, [texts.length])

    return (
        <div style={{ justifyContent: 'center' }}>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'center', zIndex: 1 }}>
                    <FullLogoLanguage style={{ width: '380px', zIndex: 1 }}></FullLogoLanguage>
                </div>
            </Grid>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 1 }}>
                <Grid
                    container
                    justify="center"
                    style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginBottom: 32 }}>
                    <Typography
                        style={{
                            color: '#FFF',
                            textAlign: 'center',
                            justifySelf: 'center',
                            fontFamily: 'Rubik',
                            width: '530px',
                            fontSize: 26,
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '100%',
                        }}>
                        Compliance
                    </Typography>
                </Grid>
            </Grid>
            <Box className={classes.topTextStyle}>What should the course teach?</Box>
            <Typography className={classes.secondaryText}>
                To start creating your course, please write what you would like to teach the learners.
            </Typography>
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                        <TextField
                            disabled={
                                props.autoCourseCreation.processingState !==
                                AutoCourseCreatorProcessStages.StartSequence
                            }
                            multiline
                            className={classes.inputFieldStyle}
                            sx={{
                                '& .MuiInputBase-input::placeholder': {
                                    color: 'white',
                                },
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                    marginLeft: '16px',
                                    marginTop: '8px',
                                },
                            }}
                            autoFocus
                            id="name"
                            variant="standard"
                            value={props.authorMetadataDto?.authorWhatText}
                            onChange={(e) =>
                                props.handleSetAuthorInputMetaData(AutoCourseCreateDialogStepType.What, e.target.value)
                            }
                            rows={5}
                            placeholder="This course should teach..."
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </Grid>
                    <div className={classes.helpTextBox}>
                        <Box>
                            <ACCScrollingTexts text={texts[currentIndex]} textStyle={classes.infoTextFirstPage} />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

AutoCourseCreateIntroScreen.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCourseCreateIntroScreen)
