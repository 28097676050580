export const CustomLessonSupportedLanguageState = {
    NoContent: 0,
    PreviousGenerationContent: 1,
    PublishedLatestContent: 2,
}

Object.freeze(CustomLessonSupportedLanguageState)

export const TopicLessonType = {
    NotSet: 0,
    Vocabulary: 1,
    Quiz: 2,
    Scenario: 3,
}
Object.freeze(TopicLessonType)
