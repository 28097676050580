import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { organizationActions } from '_state/actions'
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    CardHeader,
    TextField,
    Typography,
    CircularProgress,
    List,
    ListItem,
    MenuItem,
    MenuList,
    Select,
    makeStyles,
} from '@material-ui/core'

import OrganizationCourseGroupComponent from './Components/OrganizationCourseGroupComponent'
import OrganizationOverviewSidebar from '../Organization/Components/OrganizationOverviewSidebar'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    divider: {
        margin: '0 30',
    },
    dropZone: {
        position: 'relative',
        height: '100px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        margin: '15px auto',
        padding: 15,
        transition: 'all 200ms ease-out',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
}))

const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
}

const EditOrganizationCourses = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.root} style={{ padding: '20px' }}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <OrganizationCourseGroupComponent keepVisibleAfterAdd={true} />
                </Grid>
            </Grid>
        </div>
    )
}

EditOrganizationCourses.propTypes = {}

const mapStateToProps = (state) => {
    const { organization } = state
    return {
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganizationCourses)
