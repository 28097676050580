import React, { useEffect, useState } from 'react'
import { Typography, makeStyles, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import { Add, CreateNewFolder, Group, Person, School, Settings } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import { QueryStats } from '@mui/icons-material'
import { CapeeshOrganizationType } from '../../../../Logic/CapeeshConstants'
import { CapeeshColors } from '../../../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
    verticalContainer: {
        flex: 1,
    },
    organizationCard: {
        display: 'flex',
        paddingTop: '20px',
        paddingBottom: '20px',
        // padding: '20px',
        transition: 'all .25s ease',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
        },
    },
    courseGroupCardAction: {
        right: '15px',
        height: 'fit-content',
        margin: 'auto',
    },
    button: {
        backgroundColor: 'primary',
        fontColor: 'white',
        color: 'white',
        borderRadius: 4,
        marginRight: '10px',
        textTransform: 'none',
    },

    borderedGrid: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderWidth: 4,
    },
}))

const OrganizationCard = (props) => {
    // expected props
    // props.organizationDto
    // props.handleOrganizationOpenClicked

    const classes = useStyles()

    const handleCardClick = () => {
        if (props.onOrganizationOpenClicked) {
            props.onOrganizationOpenClicked(props.organizationDto.id)
        }
    }

    const getOrganizationTypeAsName = (organizationType) => {
        switch (organizationType) {
            case CapeeshOrganizationType.Normal:
                return 'Default'
            case CapeeshOrganizationType.SelfServeSingleUserOrganization:
                return 'Self serve single user'

            case CapeeshOrganizationType.SelfServeOrganization:
                return 'Self Serve'

            case CapeeshOrganizationType.Demo:
                return 'Demo'

            case CapeeshOrganizationType.Trial:
                return 'Trial'
            case CapeeshOrganizationType.Customer:
                return 'Customer'

            case CapeeshOrganizationType.Churned:
                return 'Churned'
            case CapeeshOrganizationType.ClosedLost:
                return 'Closed Lost'
            case CapeeshOrganizationType.CapeeshInternal:
                return 'Capeesh Internal'
            case CapeeshOrganizationType.Partner:
                return 'Partner'
        }
        return 'Not set'
    }
    const getOrganizationTypeColor = (organizationType) => {
        switch (organizationType) {
            case CapeeshOrganizationType.Normal:
                return CapeeshColors.Gray5
            case CapeeshOrganizationType.SelfServeSingleUserOrganization:
                return CapeeshColors.DeepBlue
            case CapeeshOrganizationType.SelfServeOrganization:
                return CapeeshColors.DeepBlue
            case CapeeshOrganizationType.Demo:
                return CapeeshColors.Yellow

            case CapeeshOrganizationType.Trial:
                return CapeeshColors.Yellow
            case CapeeshOrganizationType.Customer:
                return CapeeshColors.GreenBright

            case CapeeshOrganizationType.Churned:
                return CapeeshColors.Pink

            case CapeeshOrganizationType.ClosedLost:
                return CapeeshColors.Pink
            case CapeeshOrganizationType.CapeeshInternal:
                return CapeeshColors.DeepBlue

            case CapeeshOrganizationType.Partner:
                return CapeeshColors.DeepBlue
        }
        return CapeeshColors.Gray5
    }

    return (
        <div>
            <Card
                key={props.organizationDto?.id}
                onClick={() => props.onOrganizationSwapToDashboard(props.organizationDto?.id)}
                className={classes.organizationCard}>
                <div className={classes.cardContent}>
                    <Grid
                        container
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                        }}>
                        <Grid item lg={8} md={8} sm={8} xs={8} style={{}}>
                            <Grid container style={{ marginLeft: '20px' }}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {props.showOrganizationType && (
                                            <Grid item xs={2}>
                                                <Typography
                                                    variant={'h6'}
                                                    color={'primary'}
                                                    style={{
                                                        marginBottom: '5px',
                                                        color: getOrganizationTypeColor(
                                                            props.organizationDto?.organizationType
                                                        ),
                                                    }}>
                                                    {getOrganizationTypeAsName(props.organizationDto?.organizationType)}
                                                </Typography>
                                            </Grid>
                                        )}

                                        <Grid item xs={props.showOrganizationType ? 10 : 12}>
                                            <Typography
                                                variant={'h4'}
                                                color={'primary'}
                                                style={{ marginBottom: '5px' }}>
                                                {props.organizationDto?.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*{*/}
                        {/*  props.onOrganizationSwapToCap &&*/}
                        {/*  <Grid item md={2} sm={12} xs={12} style={{*/}
                        {/*    display: 'flex',*/}
                        {/*    alignItems: 'center',*/}
                        {/*    justifyContent: 'center'*/}
                        {/*  }}*/}
                        {/*  >*/}
                        {/*    <Button onClick={() => props.onOrganizationSwapToCap(props.organizationDto?.id)}*/}
                        {/*            style={{*/}

                        {/*              height:"50px",*/}
                        {/*              background: '#228B22'*/}
                        {/*            }}*/}
                        {/*            className={classes.button} variant="contained" size="medium"*/}
                        {/*    >*/}
                        {/*      <CreateNewFolder style={{marginRight:"5px"}}/>*/}
                        {/*      <FormattedMessage id="globalwords.open" defaultMessage="Go to CAP home"/>*/}
                        {/*    </Button>*/}
                        {/*  </Grid>*/}
                        {/*}*/}

                        {props.onOrganizationSwapToDashboard && (
                            <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'right',
                                    justifyContent: 'right',
                                }}>
                                <Button
                                    onClick={() => props.onOrganizationSwapToDashboard(props.organizationDto?.id)}
                                    style={{
                                        height: '50px',
                                        background: '#228B22',
                                    }}
                                    className={classes.button}
                                    variant="contained"
                                    size="medium">
                                    <QueryStats style={{ marginRight: '5px' }} />
                                    <FormattedMessage id="globalwords.open" defaultMessage="Switch organization" />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Card>
        </div>
    )
}

OrganizationCard.propTypes = {}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCard)
