import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { UserStatisticsVisibility } from '../CapeeshConstants'
import { makeGetRequest } from '../../../_state/helpers/api'
import { configWithAuth, history } from '../../../_state/helpers'
import SGRadioDialogGeneric from '../../../Components/SGRadioDialog/SGRadioDialogGeneric'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import MailIcon from '@material-ui/icons/Mail'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { lighten, Menu, MenuItem, Select } from '@material-ui/core'
import moment from 'moment/moment'
import UserRoleView from './UserRoleView'
import { Settings } from '@material-ui/icons'
import {
    organizationActions as organziationActions,
    userActions,
    usergroupActions as userGroupActions,
} from '../../../_state/actions'
import { InviteRolesToDisplayName, ReverseInviteRolesToDisplayName } from '../../../Logic/OrganizationAssetConstants'

const useStyles = makeStyles((theme) => ({
    avatarProgress: {
        width: '80px',
        height: '80px',
        margin: '5px auto',
        position: 'relative',
        overflow: 'visible',
    },
    progressBarBackground: {
        position: 'absolute',
        zIndex: 1,
        color: lighten(theme.palette.primary.main, 0.5),
    },
    avatar: {
        backgroundColor: '#dfdfdf',
        color: '#262626',
        width: '100%',
        height: '100%',
    },
}))

const StudentInformation = (props) => {
    // expected props:

    // props.userId
    // props.classroomId

    const classes = useStyles()

    const [showUserUsageStatistics, setShowUserUsageStatistics] = useState(false)

    const [openUserRoles, setOpenUserRoles] = useState(false)

    const [userInfo, setUserInfo] = useState(null)
    const [isOnline, setIsOnline] = useState(false)
    const [lastActiveText, setLastActiveText] = useState('')
    const [createdAtText, setCreatedAtText] = useState('')

    const [classroomsAsString, setClassroomAsString] = useState('')

    const [userPictureBlob, setUserPictureBlob] = useState(null)

    const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null)

    const [userOrganizations, setUserOrganizations] = useState([])
    const [userMasterOrganizationId, setUserMasterOrganizationId] = useState(null)

    useEffect(() => {
        var currentOrganization = props.organization.myOrganizations.find(
            (x) => x.id === props.organization.selectedOrganization
        )
        if (
            props.user.organizationRole !== 'reporting' ||
            currentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode
        ) {
            setShowUserUsageStatistics(true)
        }

        const tempUserInfo = props.organization.organizationUsers.find((x) => x.id == props.userId)
        setUserInfo(tempUserInfo)

        let tempClassroomsAsString = null
        if (props.user?.userStats?.userStatsUserGroupDtos) {
            let classroomNames = props.user.userStats.userStatsUserGroupDtos.map((x) => x['userGroupName'])
            tempClassroomsAsString = classroomNames.join(', ')
            setClassroomAsString(tempClassroomsAsString)
        }

        var tempOrganizations = []
        var masterOrgId = ''
        if (tempUserInfo && tempUserInfo.userOrganizationIds) {
            for (var index = 0; index < tempUserInfo.userOrganizationIds.length; index++) {
                var tempOrg = props.organization.myOrganizations.find(
                    (x) => tempUserInfo.userOrganizationIds[index] === x.id
                )
                let orgName = 'Other organziation'
                if (tempOrg) {
                    orgName = tempOrg.name
                    var isMasterOrganization = tempOrg.id === tempUserInfo.masterOrganizationId
                    if (isMasterOrganization) {
                        orgName += ' (master)'
                        masterOrgId = tempOrg.id
                    }
                    tempOrganizations.push({
                        id: tempOrg['id'],
                        name: orgName,
                        master: isMasterOrganization,
                    })
                }
            }
        }
        setUserOrganizations(tempOrganizations)
        setUserMasterOrganizationId(masterOrgId)
    }, [])

    useEffect(() => {
        if (props.user?.userStats?.hasProfilePicture) {
            makeGetRequest(
                `admin/stats/organization/${props.organization.selectedOrganization}/user/${props.userId}/picture/base64`,
                configWithAuth()
            )
                .then((result) => {
                    setUserPictureBlob(result.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let tempIsOnline = false
        let tempLastActiveText = 'No last active date'
        if (props.user.userStats && props.user.userStats.lastActive) {
            setIsOnline(moment().diff(props.user.userStats.lastActive, 'seconds') < 60)
            tempLastActiveText = moment(props.user.userStats.lastActive).fromNow()
            setLastActiveText(tempLastActiveText)
        }

        let tempCreatedAtText = ''
        if (props.user.userStats && props.user.userStats.createdAt) {
            tempCreatedAtText = moment(props.user.userStats.createdAt).format('lll')
        }
        setCreatedAtText(tempCreatedAtText)
    }, [props.user.userStats])

    const handleMasterOrganizationChange = (event) => {
        var newMasterOrganizationId = event.target.value
        var currentOrganizationId = props.organization.selectedOrganization
        props.dispatch(
            userActions.adminUpdateUserMasterOrganization(props.userId, newMasterOrganizationId, currentOrganizationId)
        )
    }

    const handleUserRolesChanged = (newRole) => {
        let oldStyleRole = ReverseInviteRolesToDisplayName[newRole]
        props.dispatch(userActions.updateUserRole(props.organization.selectedOrganization, props.userId, oldStyleRole))

        setOpenUserRoles(false)
    }

    const handleRemoveStudentFromClassroom = (event) => {
        event.stopPropagation()
        event.preventDefault()

        let userIds = []
        userIds.push(props.userId)
        props.dispatch(
            userGroupActions.deleteUserGroupUsers(props.classroomId, userIds, props.organization.selectedOrganization)
        )

        if (props.classroomId) {
            history.push('/dashboard/classroom/' + props.classroomId + '/students')
        } else {
            const pathName = window.location.pathname
            if (pathName.startsWith('/dashboard/admin/organization/admins')) {
                history.push('/dashboard/admin/organization/admins')
            } else {
                history.push('/dashboard/')
            }
        }
    }
    const resendInvite = (event) => {
        props.dispatch(userActions.resendInvite(props.organization.selectedOrganization, props.userId))
    }

    const handleUserRoleWillChange = (event) => {
        event.stopPropagation()
        event.preventDefault()

        setOpenUserRoles(true)
        setAnchorSettingsMenu(null)
    }

    const handleDeleteUser = (event) => {
        event.stopPropagation()
        event.preventDefault()

        props.dispatch(
            organziationActions.deleteOrganizationUser(
                props.organization.selectedOrganization,
                props.userId,
                props.classroomId
            )
        )

        if (props.classroomId) {
            history.push('/dashboard/classroom/' + props.classroomId + '/students')
        } else {
            const pathName = window.location.pathname
            if (pathName.startsWith('/dashboard/admin/organization/admins')) {
                history.push('/dashboard/admin/organization/admins')
            } else {
                history.push('/dashboard/')
            }
        }
    }

    const handleCancelEditUserRole = () => {
        setOpenUserRoles(false)
    }

    return (
        <React.Fragment>
            {props.user?.userStats?.userId === props.userId && (
                <React.Fragment>
                    {props.user.organizationRole === 'admin' && (
                        <SGRadioDialogGeneric
                            open={openUserRoles}
                            dialogTitle="Select the user role"
                            dialogContentText="Change the role for a user for this organization."
                            handleCancel={handleCancelEditUserRole}
                            handleButton1={handleUserRolesChanged}
                            handleButton2={handleCancelEditUserRole}
                            button1Text="Update"
                            button2Text="Cancel"
                            list={['Admin', 'Teacher', 'Reporting', 'QA', 'Student'].map(
                                (x) => InviteRolesToDisplayName[x]
                            )}
                            startValue={
                                props.user?.userStats?.organizationRole
                                    ? props.user.userStats.organizationRole
                                    : 'Student'
                            }
                            fullScreen={false}
                        />
                    )}
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={2}
                            sm={1}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.avatarProgress}>
                                        <CircularProgress
                                            thickness={3}
                                            variant={'static'}
                                            value={100}
                                            size={'100%'}
                                            className={classes.progressBarBackground}
                                        />
                                        <Avatar className={classes.avatar} src={userPictureBlob}>
                                            {' '}
                                        </Avatar>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="center" variant="subtitle2">
                                        {`Lv. ${props.user.userStats.level}`}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        variant="caption"
                                        style={{ display: 'flex', justifyContent: 'center' }}>
                                        {`${props.user.userStats.experience} LP`.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={7} sm={10}>
                            <Typography variant={'h6'}>{userInfo?.name}</Typography>
                            <Typography
                                variant={'body2'}
                                style={{ display: 'flex', alignItems: 'center', color: '#8E8E93', margin: '5px auto' }}>
                                <MailIcon style={{ margin: '0px 10px 0px 0px', height: '20px', width: '20px' }} />
                                {userInfo?.email}
                            </Typography>
                            {showUserUsageStatistics && (
                                <Typography
                                    variant={'body2'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#8E8E93',
                                        margin: '5px auto',
                                    }}>
                                    <AccessTimeIcon
                                        style={{
                                            margin: '0px 10px 0px 0px',
                                            height: '20px',
                                            width: '20px',
                                        }}
                                    />
                                    {isOnline ? 'Online now' : lastActiveText}
                                </Typography>
                            )}

                            <Typography
                                variant={'body2'}
                                style={{ display: 'flex', alignItems: 'center', color: '#8E8E93', margin: '5px auto' }}>
                                <PersonAddIcon style={{ margin: '0px 10px 0px 0px', height: '20px', width: '20px' }} />
                                <FormattedMessage id="dashboard.student.overview.createdat" defaultMessage="Created at">
                                    {(createdatlocalized) => createdatlocalized + ' ' + createdAtText}
                                </FormattedMessage>
                            </Typography>

                            {/*{*/}
                            {/*  showUserUsageStatistics &&*/}
                            {/*  <Typography variant={"body2"}*/}
                            {/*              style={{display: 'flex', alignItems: 'center', color: '#8E8E93', margin: '5px auto'}}>*/}
                            {/*    {*/}
                            {/*      user.userStats.totalTimeSpentPlayingInAppSeconds === 0 ?*/}
                            {/*        <FormattedMessage id="dashboard.student.overview.noactivity" defaultMessage="No activity"/>*/}
                            {/*        :*/}
                            {/*        <FormattedMessage id="dashboard.student.overview.sessionlength" defaultMessage="App usage" >*/}

                            {/*          {appusagelocalized => appusagelocalized + ": " + formatSeconds(user.userStats.totalTimeSpentPlayingInAppSeconds)}*/}
                            {/*        </FormattedMessage>*/}
                            {/*    }*/}
                            {/*  </Typography>*/}

                            {/*}*/}
                            {classroomsAsString && (
                                <Typography
                                    variant={'body2'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#8E8E93',
                                        margin: '5px auto',
                                    }}>
                                    <FormattedMessage
                                        id="dashboard.student.overview.userispartoftheseclassrooms"
                                        defaultMessage="User is part of these classrooms">
                                        {(userispartoflocalized) => userispartoflocalized + ': ' + classroomsAsString}
                                    </FormattedMessage>
                                </Typography>
                            )}

                            <UserRoleView></UserRoleView>
                            {/*{*/}
                            {/*  user.organizationRole === 'admin' && user?.userStats?.classroomOnlyReportingUser && reportingUserForClassroomString &&*/}
                            {/*  <Typography variant={"body2"}*/}
                            {/*              style={{display: 'flex', alignItems: 'center', color: '#8E8E93', margin: '5px auto'}}>*/}
                            {/*    <FormattedMessage id="dashboard.student.overview.userisadminoftheseclassrooms" defaultMessage="Admin user for">*/}
                            {/*      {userisadminoflocalized => userisadminoflocalized + ": " + reportingUserForClassroomString }*/}
                            {/*    </FormattedMessage>*/}

                            {/*  </Typography>*/}
                            {/*}*/}
                        </Grid>

                        <Grid
                            item
                            xs={2}
                            sm={1}
                            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}>
                            <Menu
                                id="add-menu"
                                anchorEl={anchorSettingsMenu}
                                open={Boolean(anchorSettingsMenu)}
                                onClose={() => setAnchorSettingsMenu(null)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                {props.classroomId && (
                                    <MenuItem onClick={(event) => handleRemoveStudentFromClassroom(event)}>
                                        <FormattedMessage
                                            id="dashboard.student.overview.removeFromClassroom"
                                            defaultMessage="Remove from classroom"
                                        />
                                    </MenuItem>
                                )}

                                <MenuItem onClick={(event) => handleDeleteUser(event)}>
                                    <FormattedMessage
                                        id="dashboard.student.overview.removefromallclassrooms"
                                        defaultMessage="Remove from all classrooms"
                                    />
                                </MenuItem>
                                {props.user.organizationRole === 'admin' && (
                                    <MenuItem onClick={(event) => resendInvite(event)}>
                                        <FormattedMessage
                                            id="dashboard.student.overview.resendInvitation"
                                            defaultMessage="Resend invitation"
                                        />
                                    </MenuItem>
                                )}
                                {props.user.organizationRole === 'admin' && (
                                    <MenuItem onClick={(event) => handleUserRoleWillChange(event)}>
                                        <FormattedMessage
                                            id="dashboard.student.overview.editUserRole"
                                            defaultMessage="Edit roles"
                                        />
                                    </MenuItem>
                                )}
                            </Menu>
                            <Button
                                id="settings-button"
                                disableRipple={true}
                                disableFocusRipple={true}
                                onClick={(event) => setAnchorSettingsMenu(event.currentTarget)}>
                                <Settings />
                            </Button>

                            {props.user.organizationRole === 'admin' && (
                                <Select
                                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}
                                    value={userMasterOrganizationId}
                                    disableUnderline
                                    autoWidth
                                    onChange={(e) => handleMasterOrganizationChange(e)}>
                                    {userOrganizations?.map((userOrg, index) => (
                                        <MenuItem key={userOrg.id} value={userOrg.id}>
                                            {userOrg.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

StudentInformation.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        user: state.user,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(StudentInformation)
