import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'

const SGRadioDialog = ({
    handleCancel,
    handleButton1,
    handleButton2,
    fullScreen,
    startValue,
    dialogContentText,
    dialogTitle,
    open,
    button1Text,
    button2Text,
    list,
    emptyMessage = 'None available',
}) => {
    const [selected, setSelected] = useState('')
    const [hasSet, setHasSet] = useState(false)

    useEffect(() => {
        if (!hasSet && startValue) {
            setHasSet(true)
            setSelected(startValue)
        }
    }, [])

    const handleButton1Clicked = () => {
        handleButton1(selected)
    }

    const handleButton2Clicked = (event) => {
        handleButton2(event)
    }

    const handleCancelClicked = (event) => {
        handleCancel()
    }

    const handleEnterClicked = () => {}

    const handleChange = (event) => {
        setSelected(event.target.value)
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => handleCancelClicked()}
            aria-labelledby="responsive-dialog-title"
            onEnter={() => handleEnterClicked()}>
            <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{list.length == 0 ? emptyMessage : dialogContentText}</DialogContentText>
                <div id="contained" style={{ visibility: 'visible' }}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1" value={selected} onChange={handleChange}>
                            {list.map((word, index) => (
                                <FormControlLabel key={word} value={word} control={<Radio />} label={word} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions style={{ visibility: 'visible' }}>
                <Button onClick={handleButton1Clicked} color="primary">
                    {button1Text}
                </Button>
                <Button onClick={handleButton2Clicked} color="primary" autoFocus>
                    {button2Text}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SGRadioDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
}

export default SGRadioDialog
