import { DAY_OF_WEEK_HIGH_CONTRAST_COLORS, DAY_OF_WEEK_HIGH_CONTRAST_COLORS_BRIGHT } from './ColorPalette'

export const getHighContrastPieChartColorForIndex = (index, activeIndex) => {
    if (activeIndex === index) {
        let list_index = index % DAY_OF_WEEK_HIGH_CONTRAST_COLORS.length
        return DAY_OF_WEEK_HIGH_CONTRAST_COLORS[list_index]
    }
    let list_index = index % DAY_OF_WEEK_HIGH_CONTRAST_COLORS_BRIGHT.length
    return DAY_OF_WEEK_HIGH_CONTRAST_COLORS_BRIGHT[list_index]
}
