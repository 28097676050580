import React, { useEffect, useRef, useState } from 'react'
import { Menu, MenuItem, MenuButton, ControlledMenu, SubMenu, useHover, useMenuState } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { connect } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'

import { history } from '../../_state/helpers'

const useStyles = makeStyles((theme) => ({
    singleElementStyle: {
        fontSize: '20px',
        height: '50px',
    },
    hoverButtonStyle: {
        marginLeft: '20px',
        fontSize: '20px',
        cursor: 'pointer',
    },
}))

const CreateMenu = (props) => {
    const classes = useStyles()

    const ref = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen)

    const [courseGroups, setCourseGroups] = useState([])

    useEffect(() => {
        setCourseGroups(props.organization.availableCourseGroups)
    }, [props.organization.availableCourseGroups])

    const subMenuLabel = (menuLabel) => {
        return <Typography style={{ fontSize: '20px' }}>{menuLabel}</Typography>
    }

    return (
        <React.Fragment>
            {!(props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') && (
                <div
                    className={classes.hoverButtonStyle}
                    // ref={ref} {...anchorProps}
                    style={{ textDecorationLine: props.underline ? 'underline' : 'none', textUnderlineOffset: '10px' }}
                    onClick={() => history.push('/creator/overview')}>
                    Create
                </div>
            )}
            {(props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher') && (
                <div
                    className={classes.hoverButtonStyle}
                    // ref={ref} {...anchorProps}
                    style={{ textDecorationLine: props.underline ? 'underline' : 'none', textUnderlineOffset: '10px' }}
                    onClick={() => history.push('/creator/')}>
                    Create
                </div>
            )}

            {/*<ControlledMenu*/}
            {/*  {...hoverProps}*/}
            {/*  state={isOpen ? 'open' : 'closed'}*/}
            {/*  anchorRef={ref}*/}
            {/*  onClose={() => setOpen(false)}*/}
            {/*>*/}
            {/*  <MenuItem*/}
            {/*    disabled={!(props.user.organizationRole === "admin" || props.user.organizationRole === "teacher")} */}
            {/*    onClick={() => history.push("/creator/")} */}
            {/*    className={classes.singleElementStyle}>CAP Home*/}
            {/*  </MenuItem>*/}
            {/*  <MenuItem */}
            {/*    disabled={!(props.user.organizationRole === "admin" || props.user.organizationRole === "teacher")} */}
            {/*    onClick={() => history.push("/creator/course/list")} */}
            {/*    className={classes.singleElementStyle}>Course List*/}
            {/*  </MenuItem>*/}
            {/*  */}
            {/*  /!*<SubMenu*!/*/}
            {/*  /!*  className={classes.singleElementStyle}*!/*/}
            {/*  /!*  label={subMenuLabel("Content")}*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*  *!/*/}
            {/*  /!*  {*!/*/}
            {/*  /!*    courseGroups?.map(courseGroup => {*!/*/}
            {/*  /!*      return <MenuItem key={courseGroup.id} onClick={() => history.push("/creator/course/group/" + courseGroup.id)} className={classes.singleElementStyle}>{courseGroup.name}</MenuItem>*!/*/}
            {/*  /!*    })*!/*/}
            {/*  /!*  }*!/*/}
            {/*  */}
            {/*  /!*</SubMenu>*!/*/}
            {/*</ControlledMenu>*/}
        </React.Fragment>
    )
}

CreateMenu.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMenu)
