import React from 'react'
import { Typography, Grid, IconButton, Card } from '@material-ui/core'
import { connect } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RuleWizard from '../../../assets/images/RuleWizard.png'
import TestImageWizard from '../../../assets/images/TestImageWizard.png'
import QuizWizardMockup from '../../../assets/images/QuizWizardMockup.png'
import PuzzleWizardMockup from '../../../assets/images/PuzzleWizardMockup.png'
import WatchWizardMockup from '../../../assets/images/WatchWizardMockup.png'
import ListenWizardMockup from '../../../assets/images/ListenWizardMockup.png'
import SpeakWizardMockup from '../../../assets/images/SpeakWizardMockup.png'
import DialogueWizardMockup from '../../../assets/images/DialogueWizardMockup.png'
import AudiostoryWizard from '../../../assets/images/AudiostoryWizard.png'
import DefinitionWizardMockup from '../../../assets/images/DefinitionWizardMockup.png'
import BriefWizard from '../../../assets/images/698wBriefDebrief.png'
import InstructionWizardMockup from '../../../assets/images/InstructionWizardMockup.png'
import { ScenarioNodeType } from '../../../Logic/ScenarioConstants'
import { Drawer } from '@material-ui/core'
import { history } from '_state/helpers'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        borderRadius: 6.52365,
        width: 195.58,
        height: 195.58,
    },

    info: {
        position: 'absolute',
        backgroundColor: 'rgba(10,10,10,0.5)',
        //opacity: 0.5,
        maxWidth: 345,
        borderRadius: 6.52365,
        width: 50,
        height: 29.6,
        left: 18,
        top: 18,
    },
    image: {
        height: 145.58,
    },
    sceneTitle: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        paddingTop: '5px',
    },
    lessonCard: {
        backgroundColor: '#EFEFF4',
        width: '115px',
        marginLeft: '3px',
        marginRight: '3px',
    },
    drawerPaper: {
        height: '300px',
    },
    lessonCardTitleText: {
        fontSize: 12,
        color: 'black',
        textAlign: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        paddingTop: '50px',
    },
    tutorialButton: {
        position: 'absolute',
        top: '-11px',
        right: '-5px',
    },

    headerText: {
        width: '110px',
        position: 'relative',
        top: '4px',
    },

    imageStyle: {
        flex: 1,
        color: 'black',
        width: '90px',
        textAlign: 'center',
        alignItems: 'center',
    },
}))

const ScenarioAddNodeSelector = (props) => {
    const classes = useStyles()
    const handleAddNode = (type) => {
        props.onCreateNode(props.parentNodeId, type)
    }

    const handleOpenTutorial = (e, urlString) => {
        e.preventDefault()
        history.push(
            '/creator/tutorials?section=' +
                urlString +
                '&visitedFrom=' +
                history.location.pathname +
                '&previousName=Lesson%20Maker'
        )
    }

    return (
        <Drawer
            variant="temporary"
            anchor="bottom"
            open={true}
            style={{ height: '500px' }}
            onClose={props.onHandleClose}
            onCancel={props.onHandleClose}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true,
            }}>
            <Grid container direction="row" justify="space-around" alignItems="center" style={{ marginTop: '20px' }}>
                <Grid item>
                    <Card
                        className={classes.lessonCard}
                        style={{
                            flex: 1,
                            fontSize: 14,
                            color: 'black',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}>
                        <div className={classes.headerText}>
                            <Typography
                                variant="body2"
                                style={{ display: 'inline' }}
                                className={classes.lessonCardTitleText}>
                                Cutscene
                            </Typography>
                            <IconButton
                                size="medium"
                                className={classes.tutorialButton}
                                onClick={(e) => handleOpenTutorial(e, 'watch')}>
                                <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                            </IconButton>
                        </div>
                        <IconButton
                            onClick={() => handleAddNode(ScenarioNodeType.Watch)}
                            style={{ backgroundColor: 'transparent' }}>
                            <img alt="Add a Cutscene Stage" className={classes.imageStyle} src={WatchWizardMockup} />
                        </IconButton>
                    </Card>
                </Grid>
                <Grid item>
                    <Card
                        className={classes.lessonCard}
                        style={{
                            flex: 1,
                            fontSize: 14,
                            color: 'black',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}>
                        <div className={classes.headerText}>
                            <Typography
                                variant="body2"
                                style={{ display: 'inline' }}
                                className={classes.lessonCardTitleText}>
                                Listen
                            </Typography>
                            <IconButton
                                size="medium"
                                className={classes.tutorialButton}
                                onClick={(e) => handleOpenTutorial(e, 'listen')}>
                                <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                            </IconButton>
                        </div>
                        <IconButton
                            onClick={() => handleAddNode(ScenarioNodeType.Listen)}
                            style={{ backgroundColor: 'transparent' }}>
                            <img alt="Add a Listen Stage" className={classes.imageStyle} src={ListenWizardMockup} />
                        </IconButton>
                    </Card>
                </Grid>
                <Grid item>
                    <Card
                        className={classes.lessonCard}
                        style={{
                            flex: 1,
                            fontSize: 14,
                            color: 'black',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}>
                        <div className={classes.headerText}>
                            <Typography
                                variant="body2"
                                style={{ display: 'inline' }}
                                className={classes.lessonCardTitleText}>
                                Branching
                            </Typography>
                            <IconButton
                                size="medium"
                                className={classes.tutorialButton}
                                onClick={(e) => handleOpenTutorial(e, 'speak')}>
                                <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                            </IconButton>
                        </div>
                        <IconButton
                            onClick={() => handleAddNode(ScenarioNodeType.Speak)}
                            style={{ backgroundColor: 'transparent' }}>
                            <img alt="Add a Branching Stage" className={classes.imageStyle} src={SpeakWizardMockup} />
                        </IconButton>
                    </Card>
                </Grid>

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline', fontSize: 11 }}
                                    className={classes.lessonCardTitleText}>
                                    Quiz
                                </Typography>
                                <IconButton
                                    size="medium"
                                    className={classes.tutorialButton}
                                    onClick={(e) => handleOpenTutorial(e, 'multichoice')}>
                                    <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                                </IconButton>
                            </div>
                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.MultiChoice)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img
                                    alt="Add a Multiple Choice Stage"
                                    className={classes.imageStyle}
                                    src={QuizWizardMockup}
                                />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Definition
                                </Typography>
                                <IconButton
                                    size="medium"
                                    className={classes.tutorialButton}
                                    onClick={(e) => handleOpenTutorial(e, 'definition')}>
                                    <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                                </IconButton>
                            </div>
                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.TestDefinition)}
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                }}>
                                <img
                                    alt="Add a Definition Stage"
                                    className={classes.imageStyle}
                                    src={DefinitionWizardMockup}
                                />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Test
                                </Typography>
                                <IconButton
                                    size="medium"
                                    className={classes.tutorialButton}
                                    onClick={(e) => handleOpenTutorial(e, 'test')}>
                                    <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                                </IconButton>
                            </div>

                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.TestImage)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img alt="Add a Test Stage" className={classes.imageStyle} src={TestImageWizard} />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Rule Card
                                </Typography>
                                <IconButton
                                    size="medium"
                                    className={classes.tutorialButton}
                                    onClick={(e) => handleOpenTutorial(e, 'rulecard')}>
                                    <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                                </IconButton>
                            </div>

                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.TestRule)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img alt="Add a Rule Card stage" className={classes.imageStyle} src={RuleWizard} />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Puzzle
                                </Typography>
                                <IconButton
                                    size="medium"
                                    className={classes.tutorialButton}
                                    onClick={(e) => handleOpenTutorial(e, 'puzzle')}>
                                    <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                                </IconButton>
                            </div>

                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.TestAssociationPuzzle)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img alt="Add a Puzzle stage" className={classes.imageStyle} src={PuzzleWizardMockup} />
                            </IconButton>
                        </Card>
                    </Grid>
                )}
                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            disabled={true}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Instruction
                                </Typography>
                            </div>

                            <IconButton
                                disabled={false}
                                onClick={() => handleAddNode(ScenarioNodeType.Instruction)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img
                                    alt="Add an Instruction Stage"
                                    className={classes.imageStyle}
                                    src={InstructionWizardMockup}
                                />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Dialogue
                                </Typography>
                            </div>

                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.TestDialog)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img
                                    alt="Add a Dialogue Stage"
                                    className={classes.imageStyle}
                                    src={DialogueWizardMockup}
                                />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                {!props.isSketchModeSelected && (
                    <Grid item>
                        <Card
                            className={classes.lessonCard}
                            style={{
                                flex: 1,
                                fontSize: 14,
                                color: 'black',
                                textAlign: 'center',
                                alignItems: 'center',
                            }}>
                            <div className={classes.headerText}>
                                <Typography
                                    variant="body2"
                                    style={{ display: 'inline' }}
                                    className={classes.lessonCardTitleText}>
                                    Audio Story
                                </Typography>
                            </div>

                            <IconButton
                                onClick={() => handleAddNode(ScenarioNodeType.TestListenWithSlider)}
                                style={{ backgroundColor: 'transparent' }}>
                                <img alt="Add a Dialog Stage" className={classes.imageStyle} src={AudiostoryWizard} />
                            </IconButton>
                        </Card>
                    </Grid>
                )}

                <Grid item>
                    <Card
                        className={classes.lessonCard}
                        style={{
                            flex: 1,
                            fontSize: 14,
                            color: 'black',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}>
                        <div className={classes.headerText}>
                            <Typography
                                variant="body2"
                                style={{ display: 'inline' }}
                                className={classes.lessonCardTitleText}>
                                End
                            </Typography>
                            <IconButton
                                size="medium"
                                className={classes.tutorialButton}
                                onClick={(e) => handleOpenTutorial(e, 'debrief')}>
                                <HelpOutlineIcon style={{ width: 18, height: 18 }} size="medium" />
                            </IconButton>
                        </div>

                        <IconButton
                            onClick={() => handleAddNode(ScenarioNodeType.Debrief)}
                            style={{ backgroundColor: 'transparent' }}>
                            <img alt="Add an End Stage" className={classes.imageStyle} src={BriefWizard} />
                        </IconButton>
                    </Card>
                </Grid>
            </Grid>
        </Drawer>
    )
}

function mapStateToProps(state) {
    const {} = state
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioAddNodeSelector)
