export const FileReferenceAudioUsageType = {
    NotDefined: 0,
    TextAudio: 1,
    BackgroundAudio: 2,
    SfxAudio: 3,
}
Object.freeze(FileReferenceAudioUsageType)

export const FileReferenceGenderType = {
    NotDefined: 0,
    Female: 1,
    Male: 2,
}
Object.freeze(FileReferenceGenderType)

export const FileReferenceImageUsageType = {
    NotDefined: 0,
    WatchStageBackground: 1,
    ListenStageBackground: 2,
    SpeakStageBackground: 3,
    BriefStageBackground: 4,
    TestImage: 5,
}
Object.freeze(FileReferenceImageUsageType)
