import React, { memo } from 'react'
import Zoom from '@mui/material/Zoom'
import PlaceIcon from '@mui/icons-material/Place'

export default memo(({ data }) => {
    const handleDuplicate = (event) => {
        data.onDuplicate(data.id, 3)

        event.stopPropagation()
        event.preventDefault()
    }

    const handleAdd = (event) => {
        data.onAdd(data.id, 2)
        event.stopPropagation()
        event.preventDefault()
    }

    const handleAddOther = (event) => {
        data.onAddOther(data.id, 2)
        event.stopPropagation()
        event.preventDefault()
    }

    const handleSuggest = (event) => {
        data.onSuggest(data.id)
        event.stopPropagation()
        event.preventDefault()
    }

    return (
        <>
            <Zoom in={true}>
                <div>
                    <PlaceIcon
                        style={{
                            fill: 'black',
                            width: '150px',
                            height: '150px',
                        }}
                    />
                </div>
            </Zoom>
        </>
    )
})
