import { configWithAuth } from '../helpers'
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest, makePatchRequest } from '../helpers/api'

export const topicService = {
    updateTopic: (payload) => makePutRequest('admin/topic', JSON.stringify(payload), configWithAuth()),
    updateTopicLanguageStatus: (payload) => makePutRequest('admin/topic', JSON.stringify(payload), configWithAuth()),
    createTopic: (payload) => makePostRequest('admin/topic', JSON.stringify(payload), configWithAuth()),
    publishTopic: (payload) =>
        makePostRequest(
            'admin/topic/' + payload.topicId + '/publish/' + payload.l1Id,
            JSON.stringify(payload),
            configWithAuth()
        ),
    getAllTopics: (organizationId) => makeGetRequest('admin/topic/organization/' + organizationId, configWithAuth()),
    deleteTopic: (payload) => makeDeleteRequest('admin/topic/' + payload.topicId, null, configWithAuth()),
    deleteTopicSnippet: (payload) =>
        makeDeleteRequest(
            'admin/topic/' + payload.topicId + '/snippet/' + payload.ignoreSnippetIds,
            null,
            configWithAuth()
        ),
    getAllUserGroupsForTopics: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/groups', configWithAuth()),
    addSupportedLanguage: (payload) =>
        makePostRequest(
            'admin/topic/' + payload.topicId + '/supportedLanguage/' + payload.l1Id,
            JSON.stringify(payload),
            configWithAuth()
        ),
    cloneCourse: (payload) =>
        makePostRequest('admin/topic/' + payload.topicId + '/duplicate/', JSON.stringify(payload), configWithAuth()),
    getTopic: (payload) => makeGetRequest('admin/topic/' + payload.topicId, configWithAuth()),
    changeTranslation: (payload) =>
        makePutRequest('admin/topic/' + payload.topicId + '/translate', JSON.stringify(payload), configWithAuth()),
    getSharableCourses: (payload) => makeGetRequest('admin/sharable/topic', configWithAuth()),
    getCurrentTopic: (payload) => makeGetRequest('admin/topic/' + payload.topicId, configWithAuth()),
    getRequestedL1s: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/language/requested', configWithAuth()),
    changeDescription: (payload) =>
        makePutRequest(
            'admin/topic/' + payload.topicId + '/word/information',
            JSON.stringify(payload),
            configWithAuth()
        ),
    getTopicDifficulties: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/lesson/order/difficulty/supported', configWithAuth()),
    getLessonOrderForDifficulty: (payload) =>
        makeGetRequest(
            'admin/topic/' + payload.topicId + '/lesson/order/difficulty/' + payload.topicLessonDifficultyId,
            configWithAuth()
        ),
    updateLessonOrderForDifficulty: (payload) =>
        makePostRequest(
            'admin/topic/' + payload.topicId + '/lesson/order/difficulty/' + payload.topicLessonDifficultyId,
            JSON.stringify(payload),
            configWithAuth()
        ),
    attachCustomLessonToTopic: (payload) =>
        makePostRequest(
            'admin/topic/' +
                payload.topicId +
                '/lesson/order/difficulty/' +
                payload.topicLessonDifficultyId +
                '/custom/lesson/' +
                payload.customLessonId,
            JSON.stringify(payload),
            configWithAuth()
        ),

    importExcelTopicTranslation: (payload) =>
        makePostRequest(
            'admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/excel/import',
            payload.formData,
            configWithAuth('multipart/form-data')
        ),
    importExcelTopicTranslationV2: (payload) =>
        makePostRequest(
            'admin/topic/' + payload.topicId + '/language/' + payload.l1Id + '/translation/excel/import/v2',
            payload.formData,
            configWithAuth('multipart/form-data')
        ),

    importCsvTopicMachineTranslationV1: (payload) =>
        makePostRequest(
            'admin/topic/' +
                payload.topicId +
                '/language/' +
                payload.l1Id +
                '/translation/csv/import/machine/translation/v1',
            payload.formData,
            configWithAuth('multipart/form-data')
        ),

    exportExcelTopicTranslation: (payload) =>
        makeGetRequest(
            'admin/topic/' +
                payload.topicId +
                '/language/' +
                payload.l1Id +
                '/translation/excel/export' +
                payload.queryParams,
            configWithAuth()
        ),
    exportExcelTopicTranslationV2: (payload) =>
        makeGetRequest(
            'admin/topic/' +
                payload.topicId +
                '/language/' +
                payload.l1Id +
                '/translation/excel/export/V2' +
                payload.queryParams,
            configWithAuth()
        ),

    exportSentenceSuggestionsFromFile: (payload) =>
        makePostRequest(
            'admin/content/nlp/file/sentence/selector/' + payload.l2Id + '?useBase64=true',
            payload.formData,
            configWithAuth('multipart/form-data')
        ),

    // data picking for lesson builder type endpoints
    getDataPickerContentForLessonData: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/data/picker/lesson/data', configWithAuth()),
    getDataPickerContentForNLP: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/data/picker/nlp/data', configWithAuth()),
    getDataPickerContentForSentenceSuggestion: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/data/picker/sentence/suggestion/data', configWithAuth()),
    getDataPickerContentForNLPDistractors: (payload) =>
        makeGetRequest('admin/topic/' + payload.topicId + '/data/picker/nlp/distractors', configWithAuth()),
}
