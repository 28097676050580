import { configWithAuth } from '../helpers'
import { makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest } from '../helpers/api'

export const usergroupService = {
    getAllUserGroups: (organizationId) =>
        makeGetRequest('admin/usergroup/organization/' + organizationId, configWithAuth()),
    createUserGroup: (payload) => makePostRequest('admin/usergroup', JSON.stringify(payload), configWithAuth()),
    editUserGroup: (payload) => makePutRequest('admin/usergroup', JSON.stringify(payload), configWithAuth()),
    deleteUserGroup: (userGroupId) => makeDeleteRequest('admin/usergroup/' + userGroupId, null, configWithAuth()),
    getUserGroupUsers: (userGroupId) => makeGetRequest('admin/usergroup/' + userGroupId + '/user', configWithAuth()),
    getUserGroupTopics: (userGroupId) => makeGetRequest('admin/usergroup/' + userGroupId + '/topics', configWithAuth()),
    addUserGroupUsers: (payload) => makePostRequest('admin/usergroup/user', JSON.stringify(payload), configWithAuth()),
    deleteUserGroupUsers: (payload) =>
        makeDeleteRequest('admin/usergroup/user', JSON.stringify(payload), configWithAuth()),
    addUserGroupTopic: (payload) => makePostRequest('admin/usergroup/topic', JSON.stringify(payload), configWithAuth()),
    deleteUserGroupTopic: (payload) =>
        makeDeleteRequest('admin/usergroup/topic', JSON.stringify(payload), configWithAuth()),
    updateUserGroup: (payload) => makePutRequest('admin/usergroup', JSON.stringify(payload), configWithAuth()),
    getUserGroupStats: (payload) =>
        makeGetRequest(
            'admin/stats/organization/' + payload.organizationId + '/usergroup/' + payload.userGroupId,
            configWithAuth()
        ),
    addCourseGroupToUserGroup: (payload) =>
        makePostRequest(
            'admin/usergroup/' + payload.userGroupId + '/course/group/' + payload.courseGroupId,
            JSON.stringify({}),
            configWithAuth()
        ),
    removeCourseGroupToUserGroup: (payload) =>
        makeDeleteRequest(
            'admin/usergroup/' + payload.userGroupId + '/course/group/' + payload.courseGroupId,
            JSON.stringify({}),
            configWithAuth()
        ),
    getCourseGroupsForUserGroup: (payload) =>
        makeGetRequest('admin/usergroup/' + payload.userGroupId + '/course/group/', configWithAuth()),
    addUserGroupReportingUser: (payload) =>
        makePostRequest('admin/usergroup/reporting/', JSON.stringify(payload), configWithAuth()),
    removeUserGroupReportingUser: (payload) =>
        makeDeleteRequest(
            'admin/usergroup/reporting/' + payload.userGroupId + '/user/' + payload.userId,
            JSON.stringify({}),
            configWithAuth()
        ),
}
