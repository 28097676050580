import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
    },
    cancelButton: {
        display: 'flex',
        height: 40,
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        backgroundColor: 'white',
        border: '1px solid var(--grey--200, #D7DDE4)',
        borderRadius: 8,
        cursor: 'pointer',
    },
    nextButton: {
        display: 'flex',
        height: 40,
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        backgroundColor: '#186ED7',
        borderRadius: 8,
        cursor: 'pointer',
        color: 'white',
    },
    nextButtonDisabled: {
        display: 'flex',
        height: 40,
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        backgroundColor: 'gray',
        borderRadius: 8,
        color: 'white',
    },
    buttonContainer: {
        display: 'flex',
        padding: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderTop: '1px solid var(--grey--200, #D7DDE4)',
    },
    subButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    previousButton: {
        display: 'flex',
        height: 40,
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        backgroundColor: '#186ED7',
        borderRadius: 8,
        cursor: 'pointer',
        marginRight: 8,
        color: 'white',
    },
    previousButtonDisabled: {
        display: 'flex',
        height: 40,
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        backgroundColor: 'gray',
        borderRadius: 8,
        marginRight: 8,
        color: 'white',
    },
}))

const ComponentStepper = (props) => {
    const classes = useStyles()

    const [step, setStep] = useState(0)

    const handleNext = () => {
        if (step >= props.stepperComponents.length - 1) return
        if (!props.stepperComponents[step].stepCondition) return

        setStep(step + 1)
    }

    const handlePrev = () => {
        if (step <= 0) return

        setStep(step - 1)
    }

    return (
        <div className={classes.mainContainer}>
            {props.stepperComponents[step].component}
            <div className={classes.buttonContainer}>
                <div className={classes.cancelButton} onClick={() => props.onCancel()}>
                    Cancel
                </div>
                <div>{props.stepperComponents.length > 1 && `${step + 1} / ${props.stepperComponents.length}`}</div>
                <div className={classes.subButtonContainer}>
                    {props.stepperComponents.length > 1 && (
                        <div
                            onClick={() => handlePrev()}
                            className={step <= 0 ? classes.previousButtonDisabled : classes.previousButton}>
                            Previous
                        </div>
                    )}
                    <div
                        className={
                            (step >= props.stepperComponents.length - 1 &&
                                !props.stepperComponents[props.stepperComponents.length - 1].stepCondition) ||
                            !props.stepperComponents[step].stepCondition
                                ? classes.nextButtonDisabled
                                : classes.nextButton
                        }
                        onClick={
                            props.stepperComponents[props.stepperComponents.length - 1].stepCondition &&
                            step >= props.stepperComponents.length - 1
                                ? () => props.onFinishStepper()
                                : () => handleNext()
                        }>
                        {(props.stepperComponents[props.stepperComponents.length - 1].stepCondition &&
                            step >= props.stepperComponents.length - 1) ||
                        props.stepperComponents.length <= 1
                            ? 'Finish'
                            : 'Next'}
                    </div>
                </div>
            </div>
        </div>
    )
}

ComponentStepper.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentStepper)
