import React, { Fragment, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import UnityLessonPreview from '../Views/Private/Scenarios/UnityLessonPreview/UnityLessonPreview'

const useStyles = makeStyles((theme) => ({}))

const LessonPreview = (props) => {
    const classes = useStyles()

    const unityStagePreviewControllerRef = useRef(null)

    const handleResetUnityState = async () => {
        if (unityStagePreviewControllerRef.current) {
            console.log('handleResetUnityState called')
            await unityStagePreviewControllerRef.current.unloadUnity()
        }
    }

    useEffect(() => {
        document.addEventListener('closeLessonPreview', () => handleResetUnityState())
        document.addEventListener('initLessonPreviewWithInfo', (e) => RunInitUnityLessonFromParams(e))
        document.addEventListener('unityLessonPreviewOnHide', () => unfocusCurrentUnityStagePreviewController())
        document.addEventListener('unityLessonPreviewOnShow', () => focusunfocusCurrentUnityStagePreviewController())

        return () => {
            document.removeEventListener('closeLessonPreview', () => handleResetUnityState())
            document.removeEventListener('initLessonPreviewWithInfo', (e) => RunInitUnityLessonFromParams(e))
            document.removeEventListener('unityLessonPreviewOnHide', () => unfocusCurrentUnityStagePreviewController())
            document.removeEventListener('unityLessonPreviewOnShow', () =>
                focusunfocusCurrentUnityStagePreviewController()
            )
        }
    }, [])

    const RunInitUnityLessonFromParams = (e) => {
        if (unityStagePreviewControllerRef != null && unityStagePreviewControllerRef.current) {
            unityStagePreviewControllerRef.current.InitUnityLessonFromParams(
                e.detail.customLessonId,
                e.detail.l1Id,
                e.detail.nodeId,
                e.detail.clickableSkipButton,
                e.detail.l2Id
            )
        } else {
            console.log('failed to InitUnityLessonFromParams since unityStagePreviewControllerRef is null')
        }
    }

    const unfocusCurrentUnityStagePreviewController = () => {
        if (unityStagePreviewControllerRef != null && unityStagePreviewControllerRef.current) {
            unityStagePreviewControllerRef.current.OnUnfocus()
        } else {
            console.log('failed to run onUnfocus')
        }
    }

    const focusunfocusCurrentUnityStagePreviewController = () => {
        if (unityStagePreviewControllerRef != null && unityStagePreviewControllerRef.current) {
            unityStagePreviewControllerRef.current.OnFocus()
        } else {
            console.log('failed to run onFocus')
        }
    }

    return (
        <div tabIndex={1}>
            <UnityLessonPreview
                customLessonId={''}
                ref={unityStagePreviewControllerRef}
                nodeId={''}
                clickableSkipButton={true}
                dispatch={props.dispatch}
            />
        </div>
    )
}

LessonPreview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonPreview)
