import React, { useState, useRef, useEffect } from 'react'
import { TextField, makeStyles, Typography } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { connect } from 'react-redux'
import { Editor, EditorState, ContentState, CompositeDecorator } from 'draft-js'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add'
import { ChapterTextRowType } from '../../../../../Logic/AutoCourseCreationConstants'

const useStyles = makeStyles((theme) => ({}))

const StoryScriptDialogue = (props) => {
    const [wordsToUse, setWordsToUse] = useState([])
    const wordsToUseRef = React.useRef(null)
    const [scriptIsHovered, setScriptIsHovered] = useState(-1)
    const [showNewRoleTextField, setShowNewRoleTextField] = useState(false)
    const [newRoleTextField, setNewRoleTextField] = useState('')

    wordsToUseRef.current = wordsToUse
    const Decorated = ({ children }) => {
        return (
            <span
                style={{
                    borderBottom: '1px dotted var(--Blue, #44A6EF)',
                    color: 'rgba(53, 50, 60, 0.80)',
                    fontFamily: 'Rubik',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '140%',
                }}>
                {children}
            </span>
        )
    }

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        // only accept the word, remove if it's part of another word or whatever
    }
    const handleAddNewNarrativeRole = () => {
        let newRole = {
            name: newRoleTextField,
            gender: 'Male',
        }
        props.onAddScriptRole(newRole)
        setShowNewRoleTextField(false)
        setNewRoleTextField('')
    }

    const handleRemoveNarrativeScriptRow = (index) => {
        props.onRemoveNarrativeScriptRow(index)
    }
    const handleAddNarrativeScriptRow = (index) => {
        // the pre selected role will be the next available role to the previous row's role, if a next role is available
        setAnchorEl(null)
        let newRowRole = 'Narrator'
        if (props.lessonPlanChapters[0].chapterDialogue.length > 0 && index >= 0) {
            props.lessonPlanChapters[0].chapterRoles.forEach((role, roleIndex) => {
                if (role === props.lessonPlanChapters[0].chapterDialogue[index].speaker.name) {
                    if (roleIndex < props?.lessonPlanChapters[0].chapterRoles.length - 1) {
                        newRowRole = props.lessonPlanChapters[0].chapterRoles[roleIndex + 1]
                    } else {
                        newRowRole = props.lessonPlanChapters[0].chapterRoles[roleIndex - 1]
                    }
                }
            })
        }
        const contentState = ContentState.createFromText('')
        const newEditorState = EditorState.createWithContent(contentState, createDecorator())
        let tempEditorsState = [...editorsState]
        let emptyRow = {
            chapterTextRowType: 'DialogueRow',
            conceptIds: [],
            speaker: newRowRole,
            text: '',
            translation: '',
        }
        tempEditorsState.splice(index + 1, 0, newEditorState)

        //tempEditorsState.push(newEditorState)
        setEditorsState(tempEditorsState)
        props.onAddNarrativeScriptRow(index, newRowRole)
    }

    const handleUpdateRoleName = (newName, index) => {
        props.onEditScriptRole(newName, index)
    }

    const handleUpdateRoleGender = (gender, index) => {
        props.onEditRoleGender(gender, index)
    }

    const findWithRegex = (words, contentBlock, callback) => {
        const arrayOfWordsToHighlight = props.vocabularyItems.reduce((acc, item) => {
            acc.push(item.text) // Add the text

            // add inflectedForms if it's an array (e.g. not null) TODO when that is fixed on dev
            if (Array.isArray(item.inflectedForms)) {
                acc.push(...item.inflectedForms)
            }
            return acc
        }, [])

        const text = contentBlock.getText()
        arrayOfWordsToHighlight.forEach((word) => {
            let wholeWordOnly = new RegExp(`\\b${escapeRegExp(word)}(?=[.,!?;:]*($|\\s))`, 'gi')
            const matches = [...text.matchAll(wholeWordOnly)]
            matches.forEach((match) => callback(match.index, match.index + match[0].length))
        })
    }

    const findConceptIdsInText = (text) => {
        // need a second pass since the first only highlights the words at indexes but does not keep track of the concept id's that match
        let conceptIds = []

        props.vocabularyItems.forEach((concept) => {
            let found = false
            let wholeWordOnly = new RegExp(`\\b${escapeRegExp(concept.text)}\\b`, 'gi') // whole word and not case sensitive
            const matches = [...text.matchAll(wholeWordOnly)]

            if (!found && matches.length > 0) {
                conceptIds.push(concept.conceptId)
                found = true
            }

            if (!found && Array.isArray(concept.inflectedForms)) {
                concept.inflectedForms.forEach((inflectedForm) => {
                    if (!found) {
                        let wholeWordOnlyInflectedForm = new RegExp(`\\b${escapeRegExp(inflectedForm)}\\b`, 'gi')
                        const matches = [...text.matchAll(wholeWordOnlyInflectedForm)]
                        if (matches.length > 0) {
                            conceptIds.push(concept.conceptId)
                        }
                    }
                })
            }
        })
        return conceptIds
    }

    const handleStrategy = (contentBlock, callback) => {
        findWithRegex(wordsToUseRef.current, contentBlock, callback)
    }

    const createDecorator = () =>
        new CompositeDecorator([
            {
                strategy: handleStrategy,
                component: Decorated,
            },
        ])

    const [editorsState, setEditorsState] = useState(
        props.dialogueScript.map((item) => EditorState.createEmpty(createDecorator()))
    )

    const handleReturn = () => {
        // if author presses Enter, lose focus rather than going multiline on the dialogue
        const activeElement = document.activeElement
        if (activeElement) {
            activeElement.blur()
        }
        return 'handled' // Prevent draftjs from inserting a new line
    }

    const updateDynamicEditorWithText = (newEditorState, index1) => {
        const newEditorsState = editorsState.map((editorState, index) => {
            if (index === index1) {
                editorState = newEditorState
            }
            return editorState
        })
        const newScriptText = newEditorState.getCurrentContent().getPlainText()

        if (newScriptText !== props.dialogueScript[index1].text) {
            const conceptIds = findConceptIdsInText(newScriptText)
            props.onUpdateScriptText(newScriptText, index1, conceptIds)
        }
        setEditorsState(newEditorsState)
    }

    useEffect(() => {
        const newEditorsState = editorsState.map((editorState, index) => {
            const contentState = ContentState.createFromText(props.dialogueScript[index].text)
            const newEditorState = EditorState.createWithContent(contentState, createDecorator())
            return newEditorState
        })
        setEditorsState(newEditorsState)
    }, [wordsToUse])

    React.useEffect(() => {
        setWordsToUse(props.vocabularyItems) // TODO Needs to be wired up to be vocab items and their various forms as given from backend
    }, [])

    const styles = {
        editor: {
            color: 'rgba(53, 50, 60, 0.80)',
            fontFamily: 'Rubik',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
        },
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [scriptElementSelected, setScriptElementSelected] = React.useState(-1)

    const open = Boolean(anchorEl)
    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget)
        setScriptElementSelected(index)
    }
    const handleClose = (index) => {
        setAnchorEl(null)
    }

    const scriptElement = (indexRow, actorName, type) => {
        const localRow = indexRow

        return (
            <div
                style={{
                    padding: '5px',
                    borderRadius: 8,
                    background: scriptIsHovered === indexRow ? 'var(--Off-white, #F8F8F8)' : '',
                }}
                key={indexRow}
                onMouseEnter={() => setScriptIsHovered(indexRow)}
                onMouseLeave={() => setScriptIsHovered(-1)}>
                {type === ChapterTextRowType.Break && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ flexGrow: 1, textAlign: 'center' }}>
                                        <Typography>End of part</Typography>
                                    </div>
                                    <IconButton
                                        onClick={() => handleRemoveNarrativeScriptRow(indexRow)}
                                        style={{ marginLeft: 'auto' }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <Divider style={{ width: '100%' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {props.showRoles && type === ChapterTextRowType.DialogueRow && (
                    <Grid container spacing={2}>
                        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <FormControl
                                variant="outlined"
                                sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={actorName}
                                    onChange={(e) => props.onUpdateScriptRoleSelection(e.target.value, indexRow)}
                                    sx={{
                                        height: '32px', // Set height
                                        border: 'none',
                                        color: 'var(--Dark-text, #35323C)',
                                        fontFamily: 'Rubik',
                                        fontSize: 14,
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '140%',
                                        '.MuiSelect-select': {
                                            paddingLeft: '0',
                                            textAlign: 'left',
                                        },
                                    }}>
                                    {props?.lessonPlanChapters[0].chapterRoles.map((role, index) => (
                                        <MenuItem
                                            key={index}
                                            value={role.name}
                                            sx={{
                                                textAlign: 'left',
                                                p: '4 !important',
                                                color: 'var(--Dark-text, #35323C)',
                                                fontFamily: 'Rubik',
                                                fontSize: 16,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '140%',
                                            }}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                id="demo-positioned-button"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => handleClick(e, indexRow)}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onMouseEnter={() => setScriptIsHovered(indexRow)}
                                onClick={(e) => handleClose(indexRow)}
                                index={indexRow}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                PaperProps={{
                                    style: {
                                        boxShadow: 'none', //remove massive shadow
                                    },
                                }}>
                                <MenuItem
                                    onMouseEnter={() => setScriptIsHovered(scriptElementSelected)}
                                    onClick={(e) => handleAddNarrativeScriptRow(scriptElementSelected - 1)}>
                                    Insert dialogue above
                                </MenuItem>
                                <MenuItem
                                    onMouseEnter={() => setScriptIsHovered(scriptElementSelected)}
                                    onClick={(e) => handleAddNarrativeScriptRow(scriptElementSelected)}>
                                    Insert dialogue below
                                </MenuItem>
                            </Menu>
                            <IconButton onClick={(e) => handleRemoveNarrativeScriptRow(indexRow)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div
                        style={{
                            color: 'rgba(53, 50, 60, 0.80)',
                            fontFamily: 'Rubik',
                            fontSize: 14,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%',
                        }}>
                        <Editor
                            style={styles.editor}
                            handleReturn={handleReturn} // Use the handleReturn function
                            index={indexRow}
                            editorState={editorsState[indexRow]}
                            onChange={(editorState) => updateDynamicEditorWithText(editorState, indexRow)}
                        />
                    </div>
                </Grid>
            </div>
        )
    }

    return (
        <Grid item xs={12} style={{ marginTop: '9px' }}>
            <Grid container>
                {props.showRoles && (
                    <React.Fragment>
                        <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '5px' }}>
                            <Divider />
                        </Grid>
                        {props?.lessonPlanChapters[0].chapterRoles.map(
                            (role, index) =>
                                role.name !== 'Narrator' && (
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ marginTop: '10px', paddingLeft: '10px', paddingRight: '20px' }}>
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box
                                                        style={{
                                                            color: 'var(--Dark-text, #35323C)',
                                                            fontFamily: 'Rubik',
                                                            fontSize: 16,
                                                            fontStyle: 'normal',
                                                            fontWeight: 500,
                                                            lineHeight: '118%',
                                                            width: '100px',
                                                            marginBottom: '10px',
                                                        }}>
                                                        Character {String.fromCharCode(64 + index)}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={9} style={{ paddingRight: '10px' }}>
                                                            <TextField
                                                                disabled={role === 'Narrator' && true}
                                                                style={{
                                                                    borderRadius: 8,
                                                                    border: '1px solid white',
                                                                    background: 'var(--White, #FFF)',
                                                                    minWidth: 200,
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    paddingTop: '5px',
                                                                    paddingRight: '5px',
                                                                    paddingBottom: '5px',
                                                                }}
                                                                sx={{ '& .MuiInputBase-input': { color: 'white' } }}
                                                                value={role.name}
                                                                onChange={(e) =>
                                                                    handleUpdateRoleName(e.target.value, index)
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                disableUnderline
                                                                value={role.gender}
                                                                onChange={(e) =>
                                                                    handleUpdateRoleGender(e.target.value, index)
                                                                }
                                                                sx={{
                                                                    height: 44,
                                                                    '& .MuiOutlinedInput-input': {
                                                                        boxSizing: 'border-box',
                                                                    },
                                                                    background: 'white',
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                }}>
                                                                <MenuItem value={'Male'}>Male</MenuItem>
                                                                <MenuItem value={'Female'}>Female</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                )
                        )}
                    </React.Fragment>
                )}
                <Grid
                    item
                    xs={6}
                    style={{
                        marginTop: '10px',
                        paddingLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}>
                    {!!!showNewRoleTextField ? (
                        <ButtonBase
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                justifyContent: 'left',
                            }}
                            onClick={() => setShowNewRoleTextField(true)}>
                            <Box
                                style={{
                                    color: 'var(--blue, #44A6EF)',
                                    textAlign: 'center',
                                    fontFamily: 'Rubik',
                                    fontSize: 16,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '140%',
                                    textTransform: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '8px',
                                }}>
                                <AddIcon />
                                Add a new character
                            </Box>
                        </ButtonBase>
                    ) : (
                        <React.Fragment>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box
                                            style={{
                                                color: 'var(--Dark-text, #35323C)',
                                                fontFamily: 'Rubik',
                                                fontSize: 16,
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '118%',
                                                width: '100px',
                                                marginBottom: '10px',
                                            }}>
                                            Character{' '}
                                            {String.fromCharCode(64 + props?.lessonPlanChapters[0].chapterRoles.length)}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            autoFocus
                                            style={{
                                                borderRadius: 8,
                                                border: '1px solid var(--Shadow, rgba(102, 67, 160, 0.12))',
                                                background: 'var(--White, #FFF)',
                                                minWidth: 200,
                                                alignItems: 'center',
                                                display: 'flex',
                                                paddingTop: '5px',
                                                paddingRight: '5px',
                                                paddingBottom: '5px',
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    color: 'white',
                                                },
                                            }}
                                            value={newRoleTextField}
                                            onChange={(e) => setNewRoleTextField(e.target.value)}
                                            onBlur={handleAddNewNarrativeRole}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    // Check if the key is the Enter key
                                                    e.target.blur() // Remove focus from the TextField
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            onChange={(e) => handleUpdateRoleGender(e.target.value)}
                                            disableUnderline
                                            value={'Male'}
                                            sx={{
                                                height: 44,
                                                '& .MuiOutlinedInput-input': {
                                                    boxSizing: 'border-box',
                                                },
                                                background: 'white',
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                    border: 0,
                                                },
                                                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                    {
                                                        border: 0,
                                                    },
                                            }}>
                                            <MenuItem value={'Male'}>Male</MenuItem>
                                            <MenuItem value={'Female'}>Female</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </div>
                        </React.Fragment>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        borderRadius: 8,
                        border: '1px solid var(--Shadow, rgba(102, 67, 160, 0.12))',
                        background: 'var(--White, #FFF)',
                        marginTop: '20px',
                        padding: '16px',
                    }}>
                    {props.lessonPlanChapters[0].chapterDialogue?.length === 0 && (
                        <React.Fragment>
                            <Box
                                style={{
                                    color: 'rgba(53, 50, 60, 0.80)',
                                    fontFamily: 'Rubik',
                                    fontSize: 16,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '140%',
                                }}>
                                This lesson has no script. Please add lines below to build your story.
                            </Box>
                            <ButtonBase
                                style={{ marginLeft: '15px', marginBottom: '10px', marginTop: '10px' }}
                                onClick={(e) => handleAddNarrativeScriptRow(0)}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'var(--Blue, #44A6EF)',
                                        textAlign: 'center',
                                        fontFamily: 'Rubik',
                                        fontSize: 16,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '140%',
                                        textTransform: 'none',
                                        gap: '8px',
                                    }}>
                                    <AddIcon />
                                    Add to the script
                                </Box>
                            </ButtonBase>
                        </React.Fragment>
                    )}
                    {props.lessonPlanChapters[0].chapterDialogue.map((line, index) => {
                        return <div>{scriptElement(index, line.speaker.name, line.chapterTextRowType)}</div>
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

StoryScriptDialogue.propTypes = {}

const mapStateToProps = (state) => {
    return {
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryScriptDialogue)
