import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { ReactComponent as FocusAreaListeningIcon } from '../../Images/FocusAreaListeningIcon.svg'
import FocusAreaListeningToken from '../../Images/FocusAreaListeningToken.jsx'
import FocusAreaReadingToken from '../../Images/FocusAreaReadingToken.jsx'
import FocusAreaSpokenProductionToken from '../../Images/FocusAreaSpokenProductionToken.jsx'
import FocusAreaWritingToken from '../../Images/FocusAreaWritingToken.jsx'
import FocusAreaSpokenInteractionToken from '../../Images/FocusAreaSpokenInteractionToken.jsx'
import { ReactComponent as FocusAreaReadingIcon } from '../../Images/FocusAreaReadingIcon.svg'
import { ReactComponent as FocusAreaSpokenInteractionIcon } from '../../Images/FocusAreaSpokenInteractionIcon.svg'
import { ReactComponent as FocusAreaWritingIcon } from '../../Images/FocusAreaWritingIcon.svg'
import { ReactComponent as FocusAreaSpokenProductionIcon } from '../../Images/FocusAreaSpokenProductionIcon.svg'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
    AutoCourseCreatorFocusAreas,
    AutoCourseCreatorPointsPerFocusArea,
} from '../../../../../Logic/AutoCourseCreationConstants'
const useStyles = makeStyles((theme) => ({}))

const ACCFocusArea = (props) => {
    const handleUpdateFocusPoints = (amount, type) => {
        props.onUpdateFocusPoints(amount, type)
    }
    const [hover, setHover] = useState('')

    const classes = useStyles()

    useEffect(() => {
        if (props.disabled && props.number > 0) {
            props.onUpdateFocusPoints(-props.number, props.focusAreaType)
        }
    }, [props.disabled, props.number])

    return (
        <Grid container style={{ marginBottom: '20px' }}>
            <Grid item xs={1}>
                {props.focusAreaType === AutoCourseCreatorFocusAreas.Listening && (
                    <FocusAreaListeningIcon style={props.disabled ? { filter: 'grayscale(100%)' } : null} />
                )}
                {props.focusAreaType === AutoCourseCreatorFocusAreas.Reading && (
                    <FocusAreaReadingIcon style={props.disabled ? { filter: 'grayscale(100%)' } : null} />
                )}
                {props.focusAreaType === AutoCourseCreatorFocusAreas.SpokenInteraction && (
                    <FocusAreaSpokenInteractionIcon style={props.disabled ? { filter: 'grayscale(100%)' } : null} />
                )}
                {props.focusAreaType === AutoCourseCreatorFocusAreas.SpokenProduction && (
                    <FocusAreaSpokenProductionIcon style={props.disabled ? { filter: 'grayscale(100%)' } : null} />
                )}
                {props.focusAreaType === AutoCourseCreatorFocusAreas.Writing && (
                    <FocusAreaWritingIcon style={props.disabled ? { filter: 'grayscale(100%)' } : null} />
                )}
            </Grid>
            <Grid item xs={6} style={{}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                color: !props.disabled ? 'var(--Dark-text, #35323C)' : 'rgba(53, 50, 60, 0.40)',
                                fontFamily: 'Rubik',
                                fontSize: 20,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '118%',
                            }}>
                            {props.focusAreaText}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                color: !props.disabled ? 'rgba(53, 50, 60, 0.80)' : 'rgba(53, 50, 60, 0.10)',
                                fontFamily: 'Rubik',
                                fontSize: 14,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '140%',
                            }}>
                            {props.description}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5} style={{ alignItems: 'center' }}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        {!props.disabled && (
                            <ButtonBase
                                onClick={(e) => handleUpdateFocusPoints(-1, props.focusAreaType)}
                                style={{
                                    borderRadius: 8,
                                    background: 'var(--Off-white, #F8F8F8)',
                                    display: 'inline',
                                }}>
                                <ChevronLeftIcon />
                            </ButtonBase>
                        )}
                        <React.Fragment>
                            {[...Array(AutoCourseCreatorPointsPerFocusArea)].map((_, index) => (
                                <div
                                    onClick={
                                        !props.disabled
                                            ? props.number < index + 1
                                                ? (e) =>
                                                      handleUpdateFocusPoints(
                                                          index + 1 - props.number,
                                                          props.focusAreaType
                                                      )
                                                : (e) =>
                                                      handleUpdateFocusPoints(
                                                          (props.number - (index + 1)) * -1,
                                                          props.focusAreaType
                                                      )
                                            : null
                                    }
                                    onMouseEnter={
                                        !props.disabled ? () => setHover(props.focusAreaType + (index + 1)) : null
                                    }
                                    onMouseLeave={!props.disabled ? () => setHover('') : null}
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        position: 'relative',
                                        top: '11px',
                                        cursor: !props.disabled ? 'pointer' : 'auto',
                                    }}>
                                    {props.focusAreaType === AutoCourseCreatorFocusAreas.SpokenInteraction && (
                                        <FocusAreaSpokenInteractionToken
                                            hover={hover >= props.focusAreaType + (index + 1)}
                                            selected={props.number < index + 1}
                                            disabled={props.disabled}
                                        />
                                    )}
                                    {props.focusAreaType === AutoCourseCreatorFocusAreas.Listening && (
                                        <FocusAreaListeningToken
                                            hover={hover >= props.focusAreaType + (index + 1)}
                                            selected={props.number < index + 1}
                                            disabled={props.disabled}
                                        />
                                    )}
                                    {props.focusAreaType === AutoCourseCreatorFocusAreas.SpokenProduction && (
                                        <FocusAreaSpokenProductionToken
                                            hover={hover >= props.focusAreaType + (index + 1)}
                                            selected={props.number < index + 1}
                                            disabled={props.disabled}
                                        />
                                    )}
                                    {props.focusAreaType === AutoCourseCreatorFocusAreas.Writing && (
                                        <FocusAreaWritingToken
                                            hover={hover >= props.focusAreaType + (index + 1)}
                                            selected={props.number < index + 1}
                                            disabled={props.disabled}
                                        />
                                    )}
                                    {props.focusAreaType === AutoCourseCreatorFocusAreas.Reading && (
                                        <FocusAreaReadingToken
                                            hover={hover >= props.focusAreaType + (index + 1)}
                                            selected={props.number < index + 1}
                                            disabled={props.disabled}
                                        />
                                    )}
                                </div>
                            ))}
                        </React.Fragment>
                        {!props.disabled && (
                            <ButtonBase
                                onClick={(e) => handleUpdateFocusPoints(1, props.focusAreaType)}
                                style={{ borderRadius: 8, background: 'var(--Off-white, #F8F8F8)', display: 'inline' }}>
                                <ChevronRightIcon />
                            </ButtonBase>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ACCFocusArea.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCFocusArea)
