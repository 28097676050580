import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {
    AutoCourseCreatorLanguageProcessStages,
    AutoCourseCreatorProcessStages,
} from '../../../../Logic/AutoCourseCreationConstants'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles((theme) => ({
    root: {
        //width: '20%',
    },
}))

const ACCLanguageSidebarCompliance = (props) => {
    const classes = useStyles()

    const arrowAndLine = (isActiveOrCompleted) => {
        return (
            <React.Fragment>
                <span style={{ marginLeft: '16px' }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill={isActiveOrCompleted === true ? 'white' : 'none'}>
                        <circle cx="6" cy="6" r="5" stroke="white" stroke-width="2" />
                    </svg>
                </span>
                <div
                    style={{
                        marginLeft: '21px',
                        width: '1px',
                        height: '68px',
                        background: 'white',
                        marginTop: '4px',
                        marginBottom: '4px',
                    }}></div>
            </React.Fragment>
        )
    }

    const step = (title, description, margin, isActive, isActiveOrCompleted) => {
        return (
            <Grid item xs={12} style={{ marginTop: margin, zIndex: 0 }}>
                <Grid item xs={12} style={{ marginRight: '15px' }}>
                    <Box
                        style={{
                            display: 'inline',
                            color: isActiveOrCompleted
                                ? 'var(--White, #FFF)'
                                : 'var(--White60, rgba(255, 255, 255, 0.60))',
                            fontFamily: 'Rubik',
                            fontSize: 14,
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '140%',
                        }}>
                        {title}
                    </Box>
                </Grid>
                <Grid item xs={12} style={{}}>
                    <Box
                        style={{
                            color: isActiveOrCompleted
                                ? 'var(--White, #FFF)'
                                : 'var(--White60, rgba(255, 255, 255, 0.60))',
                            fontFamily: 'Rubik',
                            fontSize: 14,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%',
                        }}>
                        {description}
                    </Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container flexDirection="column" justifyContent="spaceBetween" alignItems="flexStart">
            <Grid item xs={12} style={{ marginLeft: '3px' }}>
                <IconButton
                    onMouseDown={(event) => props.onStartAgain()}
                    sx={{
                        fontSize: '17px',
                        //marginTop: '100px',
                        height: '34px',
                        color: '#FF8D45',
                        width: '100%',
                    }}>
                    <Grid container>
                        <Grid item style={{ marginTop: '-4px' }}>
                            <ArrowUpwardIcon sx={{ fontSize: '24px' }} />
                        </Grid>
                        <Grid item>
                            <Box
                                style={{
                                    color: '#FFF',
                                    textAlign: 'center',
                                    fontFamily: 'Rubik',
                                    fontSize: 14,
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    marginTop: '0px',
                                    marginLeft: '12px',
                                }}>
                                Start again
                            </Box>
                        </Grid>
                    </Grid>
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={3}>
                        <div style={{ marginTop: '9px' }}>
                            {arrowAndLine(props.currentStage >= AutoCourseCreatorProcessStages.StartSequence)}
                            {arrowAndLine(props.currentStage >= AutoCourseCreatorProcessStages.ConfirmTeachingMaterial)}
                            {arrowAndLine(props.currentStage >= AutoCourseCreatorProcessStages.ReviewLessonPlans)}
                            <span style={{ marginLeft: '16px' }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill={
                                        props.currentStage === AutoCourseCreatorProcessStages.Finished
                                            ? 'white'
                                            : 'none'
                                    }>
                                    <circle cx="6" cy="6" r="5" stroke="white" stroke-width="2" />
                                </svg>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container>
                            {step(
                                'Step 1',
                                'Set course goals',
                                '0px',
                                props.currentStage === AutoCourseCreatorProcessStages.StartSequence,
                                props.currentStage >= AutoCourseCreatorProcessStages.StartSequence
                            )}
                            {step(
                                'Step 2',
                                'Add teaching material',
                                '55px',
                                props.currentStage === AutoCourseCreatorProcessStages.ConfirmTeachingMaterial,
                                props.currentStage >= AutoCourseCreatorProcessStages.ConfirmTeachingMaterial
                            )}
                            {step(
                                'Step 3',
                                'Review the teaching material',
                                '40px',
                                props.currentStage === AutoCourseCreatorProcessStages.ReviewLessonPlans,
                                props.currentStage >= AutoCourseCreatorProcessStages.ReviewLessonPlans
                            )}
                            {step(
                                'Step 4',
                                'Edit and preview your lessons',
                                '35px',
                                props.currentStage === AutoCourseCreatorProcessStages.Finished,
                                props.currentStage >= AutoCourseCreatorProcessStages.Finished
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ACCLanguageSidebarCompliance.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCLanguageSidebarCompliance)
