import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import quitImage from '../../../../../assets/images/Navigation.png'
import { CapeeshColors } from '../../../../../assets/ColorPalette'
import ACCFocusArea from './ACCFocusArea'
import {
    AutoCourseCreatorFocusAreas,
    AutoCourseCreatorPointsPerFocusArea,
    AutoCourseCreatorTotalPointsFocusAreas,
    AutoCourseCreatorFocusAreas2,
} from '../../../../../Logic/AutoCourseCreationConstants'
import Divider from '@mui/material/Divider'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
    dialogContentStyle: {
        width: 664,
        height: '100%',
        padding: 32,
        borderRadius: 16,
        gap: 16,
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    topDivWithCloseButton: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'end',
    },
    topDivWithText: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        alignSelf: 'start',
        flexDirection: 'column',
        marginBottom: 16,
    },
    topText: {
        textAlign: 'center',
        color: CapeeshColors.DarkText,
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: 4,
    },
    selectionBox: {
        width: '100%',
        marginBottom: 16,
    },
    focusAreaTitleText: {
        color: CapeeshColors.DarkText,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
    },
    pointsRemainingText: {
        color: 'rgba(52.93, 49.83, 59.50, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    focusAreaTextDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        width: '100%',
    },
    buttonContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        gap: 10,
        display: 'inline-flex',
    },
    cancelButton: {
        width: 51,
        height: 48,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        overflow: 'hidden',
        border: '2px rgba(96, 96, 96, 0.30) solid',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'flex',
        marginRight: 10,
        cursor: 'pointer',
    },
    saveButton: {
        width: 139,
        height: 48,
        paddingLeft: 16,
        paddingRight: 16,
        background: CapeeshColors.Blue,
        borderRadius: 8,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'flex',
        cursor: 'pointer',
    },
    cancelButtonText: {
        textAlign: 'center',
        color: CapeeshColors.DarkText,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    saveButtonText: {
        textAlign: 'center',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    selectComponentMainText: {
        color: CapeeshColors.DarkText,
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
    },
    selectComponentSecondaryText: {
        color: 'rgba(52.93, 49.83, 59.50, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    topTextSubExplanation: {
        color: 'rgba(52.93, 49.83, 59.50, 0.80)',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
}))

const ACCLessonTypeSelectionModal = (props) => {
    // props.setOpen
    // props.closeDialog
    // props.lessonPlanId
    // props.currentFocusDistribution
    // props.currentTeachingStyleId
    // props.availableTeachingStyles
    // props.saveChanges(lessonPlanId, focusAreaObject)
    // props.focusAreaPoints

    const classes = useStyles()

    const initialStateFocusAreas = {
        reading: props.focusAreaPoints?.reading > 0 ? props.focusAreaPoints.reading : 0,
        listening: props.focusAreaPoints?.listening > 0 ? props.focusAreaPoints.listening : 0,
        spokenProduction: props.focusAreaPoints?.spokenProduction > 0 ? props.focusAreaPoints.spokenProduction : 0,
        spokenInteraction: props.focusAreaPoints?.spokenInteraction > 0 ? props.focusAreaPoints.spokenInteraction : 0,
        writing: props.focusAreaPoints?.writing > 0 ? props.focusAreaPoints.writing : 0,
    }

    const [tempFocusAreas, setTempFocusAreas] = useState(initialStateFocusAreas)
    const [shouldShake, setShouldShake] = useState(false)

    const shakeAnimation = {
        start: { x: -7, color: 'white' },
        end: { x: 7, color: 'pink' },
    }

    const handleCloseModal = () => {
        props.closeDialog(false)
    }

    const handleSaveChanges = () => {
        props.storeLessonPlanFocusAreaAndRegenerate(props.lessonPlanId, tempFocusAreas)
        handleCloseModal()
    }

    useEffect(() => {
        if (shouldShake) {
            const timer = setTimeout(() => {
                setShouldShake(false)
            }, 1000)

            return () => clearTimeout(timer) // Clear timer when component unmounts or shouldShake state changes
        }
    }, [shouldShake])

    const onUpdateFocusPoints = (changeValue, type) => {
        const updateFocusArea = { ...tempFocusAreas }
        switch (type) {
            case AutoCourseCreatorFocusAreas.Reading:
                updateFocusArea.reading = changeValue + updateFocusArea.reading
                if (updateFocusArea.reading < 0) {
                    updateFocusArea.reading = 0
                }
                if (updateFocusArea.reading > 5) {
                    updateFocusArea.reading = 5
                }
                break
            case AutoCourseCreatorFocusAreas.Listening:
                updateFocusArea.listening = changeValue + updateFocusArea.listening
                if (updateFocusArea.listening < 0) {
                    updateFocusArea.listening = 0
                }
                if (updateFocusArea.listening > 5) {
                    updateFocusArea.listening = 5
                }
                break
            case AutoCourseCreatorFocusAreas.SpokenProduction:
                updateFocusArea.spokenProduction = changeValue + updateFocusArea.spokenProduction
                if (updateFocusArea.spokenProduction < 0) {
                    updateFocusArea.spokenProduction = 0
                }
                if (updateFocusArea.spokenProduction > 5) {
                    updateFocusArea.spokenProduction = 5
                }
                break
            case AutoCourseCreatorFocusAreas.SpokenInteraction:
                updateFocusArea.spokenInteraction = changeValue + updateFocusArea.spokenInteraction
                if (updateFocusArea.spokenInteraction < 0) {
                    updateFocusArea.spokenInteraction = 0
                }
                if (updateFocusArea.spokenInteraction > 5) {
                    updateFocusArea.spokenInteraction = 5
                }
                break
            case AutoCourseCreatorFocusAreas.Writing:
                updateFocusArea.writing = changeValue + updateFocusArea.writing
                if (updateFocusArea.writing < 0) {
                    updateFocusArea.writing = 0
                }
                if (updateFocusArea.writing > 5) {
                    updateFocusArea.writing = 5
                }
                break
        }

        setTempFocusAreas(updateFocusArea)

        // if(Object.values(tempFocusAreas).reduce((prev, curr) => prev + curr, 0) + newValue > AutoCourseCreatorTotalPointsFocusAreas) {
        //     // not enough focus points available, so let the author know and add what points are available, if any
        //     const nearestValue = AutoCourseCreatorTotalPointsFocusAreas - (Object.values(tempFocusAreas).reduce((prev, curr) => prev + curr, 0) + newValue )
        //     setTempFocusAreas((prevTempFocusAreas) => ({
        //         ...prevTempFocusAreas,
        //         [type]: tempFocusAreas[type] + (newValue + nearestValue)
        //     }))
        //     setShouldShake(true)
        //     return
        // }
        // if(tempFocusAreas[type] + newValue < 0) return
        // // current focus area already at 0 so don't remove more
        //
        // if(tempFocusAreas[type] + newValue > AutoCourseCreatorPointsPerFocusArea) return
        // // current focus area already at max of 5 so don't add another
        //
        // setTempFocusAreas((prevTempFocusAreas) => ({
        //     ...prevTempFocusAreas,
        //     [type]: tempFocusAreas[type] + newValue
        // }))
    }

    useEffect(() => {
        const tempPoints = {
            reading: props.focusAreaPoints?.reading > 0 ? props.focusAreaPoints.reading : 0,
            listening: props.focusAreaPoints?.listening > 0 ? props.focusAreaPoints.listening : 0,
            spokenProduction: props.focusAreaPoints?.spokenProduction > 0 ? props.focusAreaPoints.spokenProduction : 0,
            spokenInteraction:
                props.focusAreaPoints?.spokenInteraction > 0 ? props.focusAreaPoints.spokenInteraction : 0,
            writing: props.focusAreaPoints?.writing > 0 ? props.focusAreaPoints.writing : 0,
        }
        setTempFocusAreas(tempPoints)
    }, [props.setOpen])

    return (
        <Dialog open={props.setOpen} onClose={() => handleCloseModal()} maxWidth={'lg'}>
            <DialogContent className={classes.dialogContentStyle}>
                <div className={classes.topDivWithCloseButton}>
                    <img src={quitImage} style={{ cursor: 'pointer' }} onClick={() => handleCloseModal()} />
                </div>
                <div className={classes.topDivWithText}>
                    <Typography className={classes.topText}>Change the focus area for this lesson</Typography>
                    <Typography className={classes.topTextSubExplanation}>
                        Changes will regenerate the lesson, ...
                    </Typography>
                </div>
                <div className={classes.focusAreaTextDiv}>
                    <Typography className={classes.focusAreaTitleText}>Focus area</Typography>
                </div>
                <div>
                    <ACCFocusArea
                        disabled={false}
                        onUpdateFocusPoints={onUpdateFocusPoints}
                        number={tempFocusAreas.listening}
                        focusAreaType={AutoCourseCreatorFocusAreas.Listening}
                        focusAreaText="Listening"
                        description="Understanding spoken language through different listening comprehension exercises."
                    />
                    <ACCFocusArea
                        disabled={false}
                        onUpdateFocusPoints={onUpdateFocusPoints}
                        number={tempFocusAreas.reading}
                        focusAreaType={AutoCourseCreatorFocusAreas.Reading}
                        focusAreaText="Reading"
                        description="Understanding different types of written texts in different reading comprehension exercises."
                    />
                    <ACCFocusArea
                        disabled={false}
                        onUpdateFocusPoints={onUpdateFocusPoints}
                        number={tempFocusAreas.spokenProduction}
                        focusAreaType={AutoCourseCreatorFocusAreas.SpokenProduction}
                        focusAreaText="Spoken Production"
                        description="Expressing oneself orally, in exercises using speech recognition."
                    />
                    <ACCFocusArea
                        disabled={false}
                        onUpdateFocusPoints={onUpdateFocusPoints}
                        number={tempFocusAreas.spokenInteraction}
                        focusAreaType={AutoCourseCreatorFocusAreas.SpokenInteraction}
                        focusAreaText="Spoken Interaction"
                        description="Engaging in simulated conversations in our voice-driven role plays."
                    />
                    <ACCFocusArea
                        disabled={false}
                        onUpdateFocusPoints={onUpdateFocusPoints}
                        number={tempFocusAreas.writing}
                        focusAreaType={AutoCourseCreatorFocusAreas.Writing}
                        focusAreaText="Writing"
                        description="Writing and spelling of words and short phrases and sentences in different writing exercises."
                    />
                </div>
                <div style={{ marginBottom: 16 }} />
                <Divider fullWidth />
                <div style={{ marginBottom: 16 }} />
                <div className={classes.buttonContainer}>
                    <div className={classes.cancelButton} onClick={() => handleCloseModal()}>
                        <Typography className={classes.cancelButtonText}>Cancel</Typography>
                    </div>
                    <div className={classes.saveButton} onClick={() => handleSaveChanges()}>
                        <Typography className={classes.saveButtonText}>Regenerate lesson</Typography>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

ACCLessonTypeSelectionModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCLessonTypeSelectionModal)
