import React, { useState, useRef } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { AutoCourseCreatorQuizPlotTypes } from '../../../../../Logic/AutoCourseCreationConstants'
import Button from '@mui/material/Button'
import { CircularProgress } from '@material-ui/core'

import ACCStageSettingsQuizContainer from './ACCStageSettingsQuizContainer'

const useStyles = makeStyles((theme) => ({
    stagePlotType: {
        display: 'inline-block',
        color: '#35323C',
        fontFamily: 'Nunito',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.41,
        borderRadius: 5,
        border: '1px solid var(--Gray-1, #8E8E93)',
        marginTop: '10px',
        padding: '5px',
    },
    stagePlotTypeSelected: {
        display: 'inline-block',
        color: 'white',
        fontFamily: 'Nunito',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.41,
        borderRadius: 5,
        border: '1px solid var(--Gray-1, #8E8E93)',
        marginTop: '10px',
        padding: '5px',
        background: '#44A6EF',
    },
}))

const ACCStageSettingsQuiz = (props) => {
    const classes = useStyles()
    let circleStyle = {
        padding: 10,
        margin: 20,
        //display:"inline",
        // backgroundColor: 'green',
        borderRadius: '50%',
        width: 30,
        height: 30,
    }

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Typography
                    style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        color: 'black',
                        letterSpacing: '0.31px',
                        textAlign: 'left',
                    }}>
                    A multiple choice quiz. We have suggested questions below. You can create your own or ask us to
                    suggest some more. Ask as many questions as you wish.
                </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginTop: '5px', marginBottom: '5px', paddingTop: '12px' }}>
                <Button
                    onClick={(e) => props.onSuggestQuiz(e)}
                    style={{
                        textTransform: 'none',
                        fontSize: '11px',
                        background: '#A0439F',
                        color: 'white',
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        padding: '10px',
                        lineHeight: '12px',
                    }}>
                    {props.isQuizGenerating === true ? (
                        <CircularProgress style={{ color: 'white', height: '20px', width: '20px' }} />
                    ) : props?.selectedLesson?.lessonPlanConfigurationDto?.quizQuestions?.length > 0 ? (
                        'Generate more questions'
                    ) : (
                        'Generate questions'
                    )}
                </Button>
                <Button
                    onClick={() => props.onWriteQuizQuestion()}
                    style={{
                        marginLeft: '20px',
                        textTransform: 'none',
                        fontSize: '11px',
                        background: '#A0439F',
                        color: 'white',
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        padding: '10px',
                        lineHeight: '12px',
                    }}>
                    Write my own question
                </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px', borderRadius: 5, background: 'var(--White, #FFF)' }}>
                <ACCStageSettingsQuizContainer
                    onDeleteQuizQuestion={props.onDeleteQuizQuestion}
                    selectedLesson={props.selectedLesson}
                    onReorderQuiz={props.onReorderQuiz}
                    onSuggestQuiz={props.onSuggestQuiz}
                    onWriteQuizQuestion={props.onWriteQuizQuestion}
                />
            </Grid>
        </React.Fragment>
    )
}

ACCStageSettingsQuiz.propTypes = {}

const mapStateToProps = (state) => {
    return {
        languages: state.metadata.metadata.languages,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsQuiz)
