import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import createRootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const { composeWithDevTools } = require('redux-devtools-extension')

const history = createBrowserHistory()
const middlewareHistory = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const middlewares = [middlewareHistory]

if (
    process.env.REACT_APP_REDUX_ENVIRONMENT === 'development' ||
    process.env.REACT_APP_REDUX_ENVIRONMENT === 'localhost'
) {
    const reduxLogger = createLogger()
    middlewares.push(reduxLogger)
}
middlewares.push(sagaMiddleware)

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

export default function configureStore(preloadedState) {
    const store = createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware(...middlewares)))

    sagaMiddleware.run(rootSaga)

    return store
}
