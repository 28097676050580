import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as Plus } from '../Images/Plus.svg'
import TeachingMaterialSectionACC from './TeachingMaterialSectionACC'
import TeachingMaterialEditModal from './TeachingMaterialEditModal'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
    mainText: {
        width: '896px',
        textAlign: 'center',
        color: 'white',
        fontSize: 32,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    secondaryText: {
        width: '896px',
        height: 53,
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
        marginBottom: 16,
    },
    whiteContainer: {
        width: 896,
        height: '100%',
        padding: 16,
        background: 'white',
        borderRadius: 16,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'inline-flex',
    },
    addMaterialButtonDiv: {
        width: '100%',
        height: 40,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        gap: 8,
        display: 'inline-flex',
    },
    addNewMaterialButton: {
        width: 158,
        height: '100%',
        paddingLeft: 8,
        paddingRight: 16,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        display: 'inline-flex',
        cursor: 'pointer',
    },
    addNewMaterialText: {
        textAlign: 'center',
        color: '#022755',
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    lessonGenerationLoadingBar: {
        width: 920,
        height: 40,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 8,
        background: '#09284F',
        boxShadow: '0px 4px 16px rgba(9, 40, 79, 0.32)',
        borderRadius: 16,
        justifyContent: 'right',
        alignItems: 'end',
        gap: 8,
        display: 'inline-flex',
        zIndex: 4,
        bottom: 0,
        position: 'fixed',
        marginBottom: 16,
    },
    lessonGenerationBarContainer: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    lessonGenerationButton: {
        width: 129,
        height: 40,
        paddingLeft: 16,
        paddingRight: 16,
        background: '#186ED7',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        display: 'inline-flex',
        cursor: 'pointer',
    },
    lessonGenerationButtonText: {
        textAlign: 'center',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
}))

const ReviewTeachingMaterialACC = (props) => {
    // props.suggestSectionsReturnDto
    // props.save

    const classes = useStyles()

    return (
        <Fragment>
            <Typography className={classes.mainText}>Review the teaching material</Typography>
            <Typography className={classes.secondaryText}>
                We have created teaching material based on your text. Check that the information is correct and
                relevant. When you are happy, click generate lessons.
            </Typography>
            <div className={classes.whiteContainer}>
                <div className={classes.addMaterialButtonDiv}>
                    <div className={classes.addNewMaterialButton} onClick={() => props.handleOpen(false)}>
                        <Plus />
                        <Typography className={classes.addNewMaterialText}>Add new material</Typography>
                    </div>
                </div>
                {props.suggestSectionsReturnDto?.sections?.map((sectionDto, index) => (
                    <TeachingMaterialSectionACC sectionDto={sectionDto} onEdit={props.handleOpen} />
                ))}
            </div>
            <div className={classes.lessonGenerationBarContainer}>
                <motion.div
                    className={classes.lessonGenerationLoadingBar}
                    initial={{ y: 250 }}
                    animate={{ y: [250, 0] }}
                    transition={{ delay: 1, duration: 0.5 }}>
                    <div className={classes.lessonGenerationButton} onClick={() => props.generate()}>
                        <Typography className={classes.lessonGenerationButtonText}>Generate lessons</Typography>
                    </div>
                </motion.div>
            </div>
        </Fragment>
    )
}

ReviewTeachingMaterialACC.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTeachingMaterialACC)
