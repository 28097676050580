import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { Button } from '@mui/material'
import { ReactComponent as BetaTag } from '../../../assets/images/betaTag.svg'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        borderRadius: 16,
        border: '1px solid',
        borderColor: CapeeshColors.Gray2,
    },
    topContainer: {
        display: 'flex',
        height: 180,
        padding: 32,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        alignSelf: 'stretch',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    titleText: {
        alignSelf: 'stretch',
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 500,
    },
    subtitleText: {
        alignSelf: 'stretch',
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    contentContainer: {
        display: 'flex',
        height: 380,
        padding: 32,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    descriptionText: {
        color: CapeeshColors.Gray900,
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    paddingContainer: {
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 0 0',
        alignSelf: 'stretch',
        position: 'relative',
    },
}))

const LessonCreationTypeCard = ({
    topColor,
    image,
    descriptions,
    onClickButton,
    buttonText,
    title,
    subTitle,
    shouldShowBetaTag,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.paddingContainer}>
            {shouldShowBetaTag && <BetaTag style={{ position: 'absolute', top: 20, left: 2 }} />}
            <div className={classes.mainContainer}>
                <div className={classes.topContainer} style={{ backgroundColor: topColor }}>
                    {image}
                    <div className={classes.titleText}>{title}</div>
                    <div className={classes.subTitleText}>{subTitle}</div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.descriptionText}>
                        {descriptions.map((description, i) => (
                            <div key={i} style={{ marginBottom: 16 }}>
                                {description}
                            </div>
                        ))}
                    </div>
                    <Button variant="contained" size="large" onClick={() => onClickButton()}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </div>
    )
}

LessonCreationTypeCard.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonCreationTypeCard)
