import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
}))

const DataPickerBlock = (props) => {
    const handleOnClick = (customLessonId) => {
        props.onClick(customLessonId)
    }

    const classes = useStyles()
    return (
        <Button
            onClick={handleOnClick}
            style={{
                boxSizing: 'border-box',

                /* Auto layout */

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
                alignItems: 'center',
                padding: '10px 20px',
                gap: '10px',

                width: '100%',
                height: '105px',

                /* Off white */

                background: props.selected === true ? '#86C4F0' : '#F8F8F8',
                /* Gray 4 */

                borderBottom: '1px solid #E5E5EA',

                /* Inside auto layout */

                flex: 'none',
                order: 1,
                alignSelf: 'stretch',
                '&:hover': {
                    borderColor: 'black',
                    color: 'black',
                },
                flexGrow: 0,
            }}>
            <Typography
                style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '18px',
                    /* identical to box height, or 90% */
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'none',
                    color: '#000000',
                }}>
                {props.block.blockName} {props.selected && ' >'} {props.addedItems > 0}
            </Typography>
        </Button>
    )
}

function mapStateToProps(state) {
    const {} = state
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPickerBlock)
