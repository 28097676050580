import React from 'react'
import { Typography, Button, Menu, MenuItem, Grid } from '@material-ui/core'
import { Settings, Add } from '@material-ui/icons'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { history } from '_state/helpers'
import { topicActions } from '../../../_state/actions'
import Card from '@material-ui/core/Card'
import { FormattedMessage } from 'react-intl'

const styles = (theme) => ({
    card: {
        padding: '10px',
        position: 'relative',
        cursor: 'pointer',
        transition: 'all .25s ease',
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
        },
    },
    cardBackgroundButton: {
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    header: {
        display: 'table-row',
        overflow: '-webkit-paged-x',
        textAlign: 'center',
        margin: 'auto',
    },
    title: {
        marginLeft: '10px',
        fontSize: '1.7em',
    },
    cardActionArea: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        //padding: '10px',
        width: '-webkit-fill-available',
    },
    cardAction: {
        color: '#cdcdcd',
        width: '40px',
        height: '40px',
        minWidth: '40px',
        minHeight: '40px',
        float: 'right',
        transition: 'all .25s ease',
        '&:hover': {
            color: '#262626',
            background: 'rgba(0, 0, 0, 0.0)',
        },
    },
    footer: {},
    metrics: {
        marginTop: '3px',
    },
    metricBox: {
        height: '40px',
        textAlign: 'left',
    },

    goToClassroomButton: {
        marginTop: -7,
        height: '40px',
        align: 'right',
        minWidth: '120px',
        textAlign: 'center',
    },

    metricTitle: {
        fontSize: '2em',
        margin: 'auto',
        color: '#151515',
        lineHeight: '1em',
    },
    metricSubtitle: {
        fontSize: '1em',
        margin: 'auto',
        color: '#151515',
    },
    graph: {
        marginTop: 20,
    },
})

class ClassroomCard extends React.Component {
    state = {
        anchorSettingsMenu: null,
        anchorAddMenu: null,
    }

    handleClickedClassroom = (event) => {
        event.stopPropagation()
        event.preventDefault()

        history.push('/dashboard/classroom/' + this.props.usergroup.id + '/')
    }

    handleClickedClassroomSettings = (event) => {
        event.stopPropagation()
        event.preventDefault()

        history.push('/dashboard/classroom/' + this.props.usergroup.id + '/settings')
    }

    handleClickedCreateCourse = (event) => {
        const { dispatch } = this.props

        event.stopPropagation()
        event.preventDefault()

        history.push('/dashboard/classroom/' + this.props.usergroup.id + '/courses/')
    }

    handleClickedAddStudents = (event) => {
        event.stopPropagation()
        event.preventDefault()

        history.push('/dashboard/classroom/' + this.props.usergroup.id + '/students/')
    }

    handleClickedSettingsMenu = (event) => {
        event.stopPropagation()
        event.preventDefault()

        this.setState({
            anchorSettingsMenu: event.currentTarget,
        })
    }

    handleClickedAddMenu = (event) => {
        event.stopPropagation()
        event.preventDefault()

        this.setState({
            anchorAddMenu: event.currentTarget,
        })
    }

    handleCloseMenus = (event) => {
        event.stopPropagation()
        event.preventDefault()

        this.setState({
            anchorSettingsMenu: null,
            anchorAddMenu: null,
        })
    }

    render() {
        const { anchorAddMenu, anchorSettingsMenu } = this.state
        const { usergroup, animateDuration, classes, organization, theme } = this.props

        const formatSecondsForClassroom = (seconds) => {
            if (seconds === 0) {
                return <FormattedMessage id="dashboard.classroom.card.noactivity" defaultMessage="No activity" />
            }
            const min = seconds / 60
            const hours = min / 60
            const rhours = Math.floor(hours)
            const minutes = (hours - rhours) * 60
            const rminutes = Math.round(minutes)

            let secondString = seconds + ' ' + 'sec'

            if (seconds < 60) return secondString

            let minutesString = rminutes + ' min'

            if (rhours === 0) {
                return minutesString
            }

            let minutesString2 = rminutes + ' min'

            let rhoursString = rhours + ' h'

            return rhoursString + ' ' + minutesString2
        }

        return (
            <Card className={classes.card} onClick={this.handleClickedClassroom}>
                <Menu
                    id="settings-menu"
                    anchorEl={anchorSettingsMenu}
                    open={Boolean(anchorSettingsMenu)}
                    onClose={this.handleCloseMenus}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <MenuItem onClick={this.handleClickedClassroomSettings}>Settings</MenuItem>
                </Menu>
                {(this.props.showCourseOptions || this.props.showAddStudentsOption) && (
                    <Menu
                        id="add-menu"
                        anchorEl={anchorAddMenu}
                        open={Boolean(anchorAddMenu)}
                        onClose={this.handleCloseMenus}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        {this.props.showAddStudentsOption && (
                            <MenuItem onClick={this.handleClickedAddStudents}>
                                <FormattedMessage
                                    id="dashboard.classroom.card.addstudents"
                                    defaultMessage="Add students"
                                />
                            </MenuItem>
                        )}
                        {this.props.showCourseOptions && (
                            <MenuItem onClick={this.handleClickedCreateCourse}>
                                <FormattedMessage id="dashboard.classroom.card.addcourse" defaultMessage="Add course" />
                            </MenuItem>
                        )}
                    </Menu>
                )}

                <div className={classes.cardActionArea}>
                    {this.props.showSettingsCogWheel && (
                        <Button
                            id="test"
                            className={classes.cardAction}
                            disableRipple={true}
                            disableFocusRipple={true}
                            onClick={this.handleClickedSettingsMenu}>
                            <Settings />
                        </Button>
                    )}

                    <Button
                        id="test"
                        className={classes.cardAction}
                        disableRipple={true}
                        disableFocusRipple={true}
                        onClick={this.handleClickedAddMenu}>
                        <Add />
                    </Button>
                </div>

                <div className={classes.header}>
                    <Typography variant="h2" color="primary" className={classes.title}>
                        {usergroup.name}
                    </Typography>
                </div>

                <div>
                    <div className={classes.metrics}>
                        <Grid container>
                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                <div
                                    className={classes.metricBox}
                                    style={{
                                        paddingTop: 10,
                                        position: 'absolute',
                                        left: 30,
                                    }}>
                                    <Typography className={classes.metricSubtitle}>
                                        {usergroup.numberOfUsers === 1 ? (
                                            <FormattedMessage
                                                id="dashboard.classroom.card.student"
                                                defaultMessage="student">
                                                {(studentastext) => usergroup.numberOfUsers + ' ' + studentastext}
                                            </FormattedMessage>
                                        ) : (
                                            <FormattedMessage
                                                id="dashboard.classroom.card.students"
                                                defaultMessage="students">
                                                {(studentastext) => usergroup.numberOfUsers + ' ' + studentastext}
                                            </FormattedMessage>
                                        )}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                sx={{
                                    display: { xs: 'none', lg: 'block' },
                                }}>
                                <div className={classes.metricBox}></div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <div className={classes.metricBox}>
                                    <Typography
                                        style={{
                                            paddingTop: 10,
                                            position: 'absolute',
                                            left: 30,
                                        }}
                                        className={classes.metricSubtitle}>
                                        {usergroup.appUsageSeconds === 0 ? (
                                            <FormattedMessage
                                                id="dashboard.classroom.card.noactivity"
                                                defaultMessage="No activity"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="dashboard.classroom.card.prefix"
                                                defaultMessage="App time ">
                                                {(prefix) =>
                                                    prefix + ' ' + formatSecondsForClassroom(usergroup.appUsageSeconds)
                                                }
                                            </FormattedMessage>
                                        )}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={9} sm={9} md={9} lg={4}>
                                <div className={classes.metricBox}>
                                    <Typography
                                        style={{
                                            paddingTop: 10,
                                            borderLeft: '1px solid #ccc',
                                            paddingLeft: '20px',
                                        }}
                                        className={classes.metricSubtitle}>
                                        {usergroup?.numberOfCourseGroups === 1 ? (
                                            <FormattedMessage
                                                id="dashboard.classroom.card.coursegroup"
                                                defaultMessage="course">
                                                {(coursegrouptext) =>
                                                    usergroup.numberOfCourseGroups + ' ' + coursegrouptext
                                                }
                                            </FormattedMessage>
                                        ) : (
                                            <FormattedMessage
                                                id="dashboard.classroom.card.coursegroups"
                                                defaultMessage="courses">
                                                {(coursegroupstext) =>
                                                    usergroup.numberOfCourseGroups + ' ' + coursegroupstext
                                                }
                                            </FormattedMessage>
                                        )}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                                <Button
                                    className={classes.goToClassroomButton}
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{ position: 'absolute', right: 20 }}
                                    onClick={(event) => this.handleClickedClassroom(event)}>
                                    <FormattedMessage
                                        id="dashboard.classroom.card.gotoclassroom"
                                        defaultMessage="Go to classroom"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Card>
        )
    }
}

ClassroomCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {}
}

const enhance = compose(connect(mapStateToProps), withStyles(styles, { withTheme: true }))

export default enhance(ClassroomCard)
