import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { organizationActions } from '_state/actions'
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    CardHeader,
    TextField,
    Typography,
    MenuItem,
    Select,
    makeStyles,
} from '@material-ui/core'

import { FormattedMessage } from 'react-intl'

import { UserStatisticsVisibility } from '../CapeeshConstants'
import CapeeshTimeZonePicker from '../../../Components/Pickers/CapeeshTimeZonePicker'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { CapeeshOrganizationType } from '../../../Logic/CapeeshConstants'
import OrganizationOverviewSidebar from '../Organization/Components/OrganizationOverviewSidebar'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    divider: {
        margin: '0 30',
    },
    dropZone: {
        position: 'relative',
        height: '100px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        margin: '15px auto',
        padding: 15,
        transition: 'all 200ms ease-out',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
}))

const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
}

const EditOrganization = (props) => {
    const classes = useStyles()

    const [organizationDto, setOrganizationDto] = useState(null)

    const [tempName, setTempName] = useState('')
    const [tempWelcomeMessage, setTempWelcomeMessage] = useState('')
    const [tempNumberOfLicenses, setTempNumberOfLicenses] = useState(10)

    // org stuff
    const [originalUserUsageStatisticsVisibility, setOriginalUserUsageStatisticsVisibility] = useState(0)
    const [tempUserUsageStatisticsVisibility, setTempUserUsageStatisticsVisibility] = useState(0)

    const [originalOrganizationType, setOriginalOrganizationType] = useState(CapeeshOrganizationType.Normal)
    const [tempOrganizationType, setTempOrganizationType] = useState(CapeeshOrganizationType.Normal)

    // time zone locations
    const [timeZoneLocations, setTimeZoneLocations] = useState([])
    const [currentTimeZoneLocation, setCurrentTimeZoneLocation] = useState('')

    useEffect(() => {
        const { location } = props

        const organizationId =
            location.state && location.state.organizationId
                ? location.state.organizationId
                : props.organization.selectedOrganization

        props.dispatch(organizationActions.getMyOrganizations())

        if (!props.organization.timeZoneLocations || props.organization.timeZoneLocations.length === 0) {
            props.dispatch(organizationActions.getOrganizationTimeZoneLocations(organizationId))
        }
    }, [])

    useEffect(() => {
        if (
            props.organization.timeZoneLocations?.length > 0 &&
            timeZoneLocations.length !== props.organization.timeZoneLocations.length
        ) {
            setTimeZoneLocations(props.organization.timeZoneLocations)
        }
    }, props.organization.timeZoneLocations)

    useEffect(() => {
        const organizationId = props.organization.selectedOrganization
        const organizationData = props.organization.myOrganizations.find((x) => x.id === organizationId)

        if (organizationData) {
            setOrganizationDto(organizationData)
            setCurrentTimeZoneLocation(organizationData.timeZoneLocation)
            setOriginalUserUsageStatisticsVisibility(
                organizationData.userStatisticsVisibility
                    ? organizationData.userStatisticsVisibility
                    : UserStatisticsVisibility.MediumStrict
            )
            setOriginalOrganizationType(
                organizationData.organizationType ? organizationData.organizationType : CapeeshOrganizationType.Normal
            )

            setTempName(organizationData.name)
            setTempNumberOfLicenses(organizationData.numberOfLicenses)
            setTempWelcomeMessage(organizationData.welcomeMessageToUsers)
            setTempOrganizationType(organizationData.organizationType)
            setTempUserUsageStatisticsVisibility(organizationData.userStatisticsVisibility)
        }
    }, props.organization.myOrganizations)

    const handleChangeUerUsageStatisticsVisibility = (event) => {
        if (
            event.target.value === UserStatisticsVisibility.MediumStrict ||
            event.target.value === UserStatisticsVisibility.SchoolMode ||
            event.target.value === UserStatisticsVisibility.Strict
        ) {
            setTempUserUsageStatisticsVisibility(event.target.value)
        }
    }

    const handleChangeOrganizationType = (event) => {
        if (
            event.target.value === CapeeshOrganizationType.Customer ||
            event.target.value === CapeeshOrganizationType.Churned ||
            event.target.value === CapeeshOrganizationType.Demo ||
            event.target.value === CapeeshOrganizationType.Trial ||
            event.target.value === CapeeshOrganizationType.CapeeshInternal ||
            event.target.value === CapeeshOrganizationType.ClosedLost ||
            event.target.value === CapeeshOrganizationType.Partner
        ) {
            setTempOrganizationType(event.target.value)
        }
    }
    const handleDeleteOrganizationUser = (userToDelete) => {
        if (userToDelete?.id) {
            props.dispatch(
                organizationActions.deleteOrganizationUser(
                    props.organization.selectedOrganization,
                    userToDelete.id,
                    null
                )
            )
        }
    }

    const handleTimeZoneLocationChanged = (timeZoneLocation) => {
        props.dispatch(
            organizationActions.setOrganizationTimeZoneLocation(
                props.organization.selectedOrganization,
                timeZoneLocation
            )
        )
    }

    const handleUpdateChanges = (e) => {
        e.preventDefault()

        props.dispatch(
            organizationActions.setOrganizationInfo(
                organizationDto.id,
                tempName,
                '',
                '',
                tempWelcomeMessage,
                CapeeshColors.Purple,
                CapeeshColors.Purple,
                tempNumberOfLicenses,
                tempUserUsageStatisticsVisibility,
                tempOrganizationType
            )
        )
    }

    return (
        <div className={classes.root} style={{ padding: '20px' }}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader
                            title={tempName}
                            subheader={
                                <FormattedMessage
                                    id="admin.edit.org.companyinfo"
                                    defaultMessage="Company information"
                                />
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="name"
                                        label={<FormattedMessage id="globalwords.name1" defaultMessage="Name" />}
                                        value={tempName}
                                        onChange={(event) => setTempName(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    {timeZoneLocations?.length > 0 && (
                                        <CapeeshTimeZonePicker
                                            timeZoneLocations={timeZoneLocations}
                                            onTimeZoneLocationChanged={handleTimeZoneLocationChanged}
                                            currentTimeZoneLocation={organizationDto.timeZoneLocation}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="welcomeMessage"
                                        label={
                                            <FormattedMessage
                                                id="admin.edit.org.welcome"
                                                defaultMessage="Welcome message in app"
                                            />
                                        }
                                        multiline
                                        rows="4"
                                        value={tempWelcomeMessage}
                                        onChange={(event) => setTempWelcomeMessage(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <div className={classes.divider} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="country"
                                        label={'Number of Licenses'}
                                        value={tempNumberOfLicenses}
                                        onChange={(event) => setTempNumberOfLicenses(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.divider} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Select
                                        style={{
                                            paddingLeft: '40px',
                                            paddingRight: '10px',
                                            marginTop: '20px',
                                            height: '90px',
                                            border: '5px solid #143349',
                                            boxSizing: 'border-box',
                                            borderRadius: '10px',
                                            maxHeight: '40px',
                                            minHeight: '40px',
                                        }}
                                        disableUnderline
                                        label={'User usage visibility for reporting'}
                                        value={tempUserUsageStatisticsVisibility}
                                        autoWidth
                                        onChange={handleChangeUerUsageStatisticsVisibility}>
                                        <MenuItem
                                            key={UserStatisticsVisibility.MediumStrict}
                                            value={UserStatisticsVisibility.MediumStrict}>
                                            User Usage Statistics Visibility: Normal Mode
                                        </MenuItem>
                                        <MenuItem
                                            key={UserStatisticsVisibility.SchoolMode}
                                            value={UserStatisticsVisibility.SchoolMode}>
                                            User Usage Statistics Visibility: School Mode
                                        </MenuItem>
                                        <MenuItem
                                            key={UserStatisticsVisibility.Strict}
                                            value={UserStatisticsVisibility.Strict}>
                                            User Usage Statistics Visibility: Strict
                                        </MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="body1"
                                                style={{ marginTop: '25px', marginRight: '10px', textAlign: 'right' }}>
                                                Organization Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select
                                                style={{
                                                    paddingLeft: '40px',
                                                    paddingRight: '10px',
                                                    marginTop: '20px',
                                                    height: '90px',
                                                    border: '5px solid #143349',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '10px',
                                                    maxHeight: '40px',
                                                    minHeight: '40px',
                                                }}
                                                disableUnderline
                                                label={'Organization type'}
                                                value={tempOrganizationType}
                                                autoWidth
                                                onChange={handleChangeOrganizationType}>
                                                <MenuItem
                                                    disabled={true}
                                                    key={CapeeshOrganizationType.Normal}
                                                    value={CapeeshOrganizationType.Normal}>
                                                    (Not set)
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.CapeeshInternal}
                                                    value={CapeeshOrganizationType.CapeeshInternal}>
                                                    Capeesh Internal
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.Partner}
                                                    value={CapeeshOrganizationType.Partner}>
                                                    Partner
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.Customer}
                                                    value={CapeeshOrganizationType.Customer}>
                                                    Customer
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.Demo}
                                                    value={CapeeshOrganizationType.Demo}>
                                                    Demo
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.Trial}
                                                    value={CapeeshOrganizationType.Trial}>
                                                    Trial
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.Churned}
                                                    value={CapeeshOrganizationType.Churned}>
                                                    Churned
                                                </MenuItem>
                                                <MenuItem
                                                    key={CapeeshOrganizationType.ClosedLost}
                                                    value={CapeeshOrganizationType.ClosedLost}>
                                                    Closed Lost
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <div className={classes.divider} />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button
                                size="large"
                                onClick={handleUpdateChanges}
                                variant={'contained'}
                                color={'primary'}
                                disabled={
                                    !tempName ||
                                    tempNumberOfLicenses < 0 ||
                                    tempNumberOfLicenses === '' ||
                                    (organizationDto.name === tempName &&
                                        organizationDto.welcomeMessageToUsers === tempWelcomeMessage &&
                                        organizationDto.numberOfLicenses === tempNumberOfLicenses &&
                                        organizationDto.userStatisticsVisibility ===
                                            tempUserUsageStatisticsVisibility &&
                                        organizationDto.organizationType === tempOrganizationType)
                                }>
                                <FormattedMessage id="globalwords.save2" defaultMessage="Save" />
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

EditOrganization.propTypes = {}

const mapStateToProps = (state) => {
    const { organization } = state
    return {
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization)
