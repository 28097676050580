import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, TextField } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: '15%',
        marginRight: '25px',
    },
}))

const EditOrganizationInformation = (props) => {
    // expected props
    // props.organizationDto
    // props.saveBillingInformation

    const classes = useStyles()

    const [organizationNumber, setOrganizationNumber] = useState(null)
    const [companyContactEmail, setCompanyContactEmail] = useState(null)
    const [companyContactName, setCompanyContactName] = useState(null)

    const [street, setStreet] = useState(null)
    const [countyCode, setCountyCode] = useState(null)
    const [countyName, setCountyName] = useState(null)
    const [country, setCountry] = useState(null)

    useEffect(() => {
        setOrganizationNumber(props.organizationDto.organizationNumber)
        setCompanyContactEmail(props.organizationDto.contactEmail)
        setCompanyContactName(props.organizationDto.contactPersonName)
        setStreet(props.organizationDto.billingAddressStreet)
        setCountyCode(props.organizationDto.billingAddressCountyCode)
        setCountyName(props.organizationDto.billingAddressCountyName)
        setCountry(props.organizationDto.billingAddressCountry)
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="streetaddress"
                    label={
                        <FormattedMessage
                            id="admin.customer.org.organizationNumber"
                            defaultMessage="Organization number"
                        />
                    }
                    value={organizationNumber}
                    onChange={(event) => setOrganizationNumber(event.target.value)}
                    margin="normal"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactEmail"
                    label={<FormattedMessage id="admin.customer.org.contactEmail" defaultMessage="Contact email" />}
                    value={companyContactEmail}
                    onChange={(event) => setCompanyContactEmail(event.target.value)}
                    margin="normal"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="contactName"
                    label={<FormattedMessage id="admin.customer.org.contactName" defaultMessage="Contact name" />}
                    value={companyContactName}
                    onChange={(event) => setCompanyContactName(event.target.value)}
                    margin="normal"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    id="zipcode"
                    label={<FormattedMessage id="admin.customer.org.zipcode" defaultMessage="Zip Code" />}
                    value={countyCode}
                    onChange={(event) => setCountyCode(event.target.value)}
                    margin="normal"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={8}>
                <TextField
                    id="countyname"
                    label={<FormattedMessage id="admin.customer.org.countyname" defaultMessage="County name" />}
                    value={countyName}
                    onChange={(event) => setCountyName(event.target.value)}
                    margin="normal"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <TextField
                    id="country"
                    label={<FormattedMessage id="admin.customer.org.country" defaultMessage="Country" />}
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}
                    margin="normal"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <div className={classes.divider} />
            </Grid>

            <Button
                size="large"
                onClick={() =>
                    props.saveBillingInformation(
                        organizationNumber,
                        companyContactEmail,
                        companyContactName,
                        street,
                        countyCode,
                        countyName,
                        country
                    )
                }
                variant={'contained'}
                color={'primary'}
                disabled={
                    props.organizationDto.organizationNumber === organizationNumber &&
                    props.organizationDto.contactEmail === companyContactEmail &&
                    props.organizationDto.contactPersonName === companyContactName &&
                    props.organizationDto.billingAddressStreet === street &&
                    props.organizationDto.billingAddressCountyCode === countyCode &&
                    props.organizationDto.billingAddressCountyName === countyName &&
                    props.organizationDto.billingAddressCountry === country
                }>
                <FormattedMessage id="globalwords.save2" defaultMessage="Save" />
            </Button>
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganizationInformation)
