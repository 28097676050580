import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as Clapper } from 'assets/images/clapperSticks.svg'
import { ReactComponent as Slate } from 'assets/images/slate.svg'
import { ReactComponent as SlatePause } from 'assets/images/slate_pause.svg'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        flexDirection: 'column',
        cursor: 'pointer',
        width: 50,
    },
    mainContainerPaused: {
        flexDirection: 'column',
        width: 50,
    },
}))

const PreviewLessonClapper = (props) => {
    // props.onClickFunction(event)
    // props.isLoading

    const [isHovering, setIsHovering] = useState(false)
    const [hasClicked, setHasClicked] = useState(false)

    const classes = useStyles()

    const handleClick = (event) => {
        setHasClicked(true)
        setIsHovering(false)
        props.onClickFunction(event)
    }

    useEffect(() => {
        if (hasClicked) {
            setTimeout(() => setHasClicked(false), 5)
        }
    }, [hasClicked])

    return (
        <motion.div
            className={props.isLoading ? classes.mainContainerPaused : classes.mainContainer}
            onMouseEnter={() => (props.isLoading ? {} : setIsHovering(true))}
            onMouseLeave={() => (props.isLoading ? {} : setIsHovering(false))}
            onClick={(event) => (props.isLoading ? {} : handleClick(event))}
            animate={isHovering || hasClicked ? { scale: 1.15 } : { scale: 1 }}>
            <motion.div
                style={{ transformOrigin: '0% 65%' }}
                animate={isHovering || hasClicked ? { rotate: -15 } : { rotate: 0 }}>
                <Clapper />
            </motion.div>
            <motion.div
                style={{ transformOrigin: '0% 25%' }}
                animate={isHovering || hasClicked ? { rotate: 5 } : { rotate: 0 }}>
                {!props.isLoading ? <Slate /> : <SlatePause />}
            </motion.div>
        </motion.div>
    )
}

PreviewLessonClapper.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewLessonClapper)
