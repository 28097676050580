import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import SkillsLibrary from '../../../Components/Library/SkillsLibrary'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: '75px',
        marginRight: '75px',
        marginTop: '100px',
    },
}))

const CapeeshSkillsOverview = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'h4'}>
                        <FormattedMessage id="skills.dashboard.title" defaultMessage="Capeesh skills courses" />
                    </Typography>
                    <Typography stlye={{ marginTop: '40px' }} variant={'body1'}>
                        <FormattedMessage
                            id="skills.dashboard.description"
                            defaultMessage="Capeesh has a large selection of courses that are instantly available for your organizations users."
                        />
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '70px' }}>
                    <SkillsLibrary
                        filterOutCourseGroupIds={[]}
                        dispatch={props.dispatch}
                        fetchSkills={true}
                        canOpenRequestAccess={false}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization } = state
    return {
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CapeeshSkillsOverview)
