import React from 'react'
import ReactFlow, { MiniMap, Background, Controls, ControlButton } from 'react-flow-renderer'
import { connect } from 'react-redux'

const CapeeshReactFlow = (props) => {
    const handleOnNodesChange = (e) => {
        if (e[0].type === 'remove') return
        props.onNodesChange(e)
    }

    return (
        <ReactFlow
            elements={props.nodes}
            nodes={props.nodes}
            edges={props.edges}
            onConnect={props.onConnect}
            defaultPosition={[0, 0]}
            selectNodesOnDrag={true}
            onNodeContextMenu={props.onNodeContextMenu}
            edgeTypes={props.edgeTypes}
            nodeTypes={props.nodeTypes}
            nodesConnectable={props.nodesConnectable}
            nodesDraggable={props.nodesDraggable}
            panOnDrag={true}
            onNodeClick={props.onElementClick}
            snapToGrid={true}
            snapGrid={[15, 15]}
            onNodeDragStart={props.onNodeDragStart}
            onNodeDragStop={props.handleNodeDragStop}
            onNodeDrag={props.handleNodeDrag}
            onNodesChange={handleOnNodesChange}
            onEdgesChange={props.onEdgesChange}
            minZoom={0.1}
            fitView
            style={{
                reactFlow__node: {
                    zIndex: '-1 !important',
                },
            }}
            selectable={'false'}>
            <MiniMap
                nodeColor={(node) => {
                    switch (node.type) {
                        case 'input':
                            return 'red'
                        case 'default':
                            return '#00ff00'
                        case 'output':
                            return 'rgb(0,0,255)'
                        default:
                            return '#eee'
                    }
                }}
            />
            <Controls showInteractive={false}>
                {/*<ControlButton onClick={() => props.onToggleMultiSelect}>*/}
                {/*  <FilterNoneIcon />*/}
                {/*</ControlButton>*/}
            </Controls>
            <Background color="#aaa" gap={15} />
        </ReactFlow>
    )
}

CapeeshReactFlow.propTypes = {}

const mapToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(CapeeshReactFlow)
