import { Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import DataPickerBlock from './DataPickerBlock'
import DataPickerLessonItem from './DataPickerLessonItem'
import List from '@mui/material/List'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import DataPickerLessonNode from './DataPickerLessonNode'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Checkbox } from '@mui/material'

const useStyles = makeStyles((theme) => ({}))

const DataPickerNodeItemAdd = (props) => {
    const [tabValue, setTabValue] = React.useState(0)
    const [customLessonId, setCustomLessonId] = React.useState(null)
    const [block, setBlock] = React.useState(null)
    const [addedItems, setAddedItems] = React.useState([])
    const [hoveredItemId, setHoveredItemId] = React.useState(-1)
    const [modeSelect, setModeSelect] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [shouldConnect, setShouldConnect] = React.useState(false)

    const handleChangeTab = (e, value) => {
        setTabValue(value)
    }

    const toggleMode = (mode) => {
        if (addedItems.length > 0) {
            setOpen(true)
        } else {
            setModeSelect(mode)
        }
    }

    const handleAddItemsOrNodes = (e) => {
        props.onAddItemsOrNodes(addedItems, modeSelect, shouldConnect)
        setAddedItems([])
    }

    const handleItemClick = (block) => {
        setCustomLessonId(block.customLessonId)
        setBlock(block)
        props.onGetLessonStages(customLessonId)
    }

    const handleOK = () => {
        setOpen(false)
        setAddedItems([])
        setModeSelect(1 - modeSelect)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    const handleToggleAddItemOrNode = (item, uniqueKey) => {
        let shouldAdd = false
        let found = false
        addedItems.forEach((addedItem) => {
            if (addedItem[uniqueKey] === item[uniqueKey]) {
                found = true
            }
        })
        if (found === true) {
            setAddedItems(addedItems.filter((x) => x[uniqueKey] !== item[uniqueKey]))
        } else if (!!!shouldAdd) {
            setAddedItems([...addedItems, { ...item, id: item[uniqueKey] }])
        }
    }
    const classes = useStyles()
    return (
        <Grid container>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'Remove selected items?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {'You have selected ' +
                            addedItems.length +
                            ' items to add. Swapping tabs will unselect these items.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} autoFocus>
                        {' '}
                        Cancel{' '}
                    </Button>
                    <Button onClick={handleOK}>OK</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={4}>
                <List
                    style={{
                        maxHeight: '100vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}>
                    {props.topic.topicDataPickerForLessonData?.topicId == props.topicId &&
                        props.topic.topicDataPickerForLessonData?.blocks?.map((block, index) => {
                            return (
                                <DataPickerBlock
                                    key={block.customLessonId}
                                    addedItems={addedItems.length}
                                    selected={customLessonId === block.customLessonId}
                                    onClick={(event) => handleItemClick(block)}
                                    block={block}
                                />
                            )
                        })}
                </List>
            </Grid>
            <Grid item xs={8}>
                {customLessonId === null ? (
                    <p style={{ marginLeft: '10px' }}>No lesson selected</p>
                ) : (
                    <Grid container direction="column" justifyContent="space-between">
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '458px',
                                    flex: 'none',
                                    flexGrow: 0,
                                }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '48px',
                                                background: '#F8F8F8',
                                                flex: 'none',
                                                order: 1,
                                                flexGrow: 1,
                                            }}>
                                            <Button
                                                onClick={(event) => toggleMode(0)}
                                                variant="body2"
                                                style={{
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: '16px',
                                                    lineHeight: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecorationLine: modeSelect === 0 ? 'underline' : 'none',
                                                    color: modeSelect === 0 ? '#003C58' : 'black',
                                                    flex: 'none',
                                                    order: 0,
                                                    flexGrow: 0,
                                                    textTransform: 'none',
                                                }}>
                                                Make definition stages
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '48px',
                                                background: '#F8F8F8',
                                                flex: 'none',
                                                order: 1,
                                                flexGrow: 1,
                                            }}>
                                            <Button
                                                onClick={(event) => toggleMode(1)}
                                                style={{
                                                    fontFamily: 'Lato',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: '16px',
                                                    lineHeight: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textDecorationLine: modeSelect === 1 ? 'underline' : 'none',
                                                    color: modeSelect === 1 ? '#003C58' : 'black',
                                                    flex: 'none',
                                                    order: 0,
                                                    flexGrow: 0,
                                                    textTransform: 'none',
                                                }}>
                                                Duplicate entire stage
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {modeSelect === 0 && (
                            <Grid item xs={12}>
                                <List
                                    style={{
                                        height: addedItems.length > 0 ? 'calc(100vh - 270px)' : 'calc(100vh - 180px)',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}>
                                    {props.topic.topicDataPickerForLessonData?.topicId == props.topicId &&
                                        block &&
                                        block.items?.map((item, index) => {
                                            return (
                                                <div
                                                    onMouseEnter={() => setHoveredItemId(item.id)}
                                                    onMouseLeave={() => setHoveredItemId(-1)}>
                                                    <DataPickerLessonItem
                                                        selectionPosition={
                                                            addedItems.findIndex((x) => x.id === item.id) + 1
                                                        }
                                                        isAdded={addedItems.filter((x) => x.id === item.id).length > 0}
                                                        isHover={hoveredItemId === item.id}
                                                        onClick={(event) => handleItemClick(item)}
                                                        onToggleAddItem={handleToggleAddItemOrNode}
                                                        item={item}
                                                    />
                                                </div>
                                            )
                                        })}
                                </List>
                            </Grid>
                        )}
                        {modeSelect === 1 && (
                            <Grid item xs={12} style={{ padding: '0px' }}>
                                <List
                                    style={{
                                        height: addedItems.length > 0 ? 'calc(100vh - 270px)' : 'calc(100vh - 180px)',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}>
                                    {props.topic.topicDataPickerForLessonData?.topicId == props.topicId &&
                                        block &&
                                        block.nodes?.map((node, index) => {
                                            return (
                                                <div
                                                    style={{}}
                                                    onMouseEnter={() => setHoveredItemId(node.nodeId)}
                                                    onMouseLeave={() => setHoveredItemId(-1)}>
                                                    <DataPickerLessonNode
                                                        selectionPosition={
                                                            addedItems.findIndex((x) => x.id === node.nodeId) + 1
                                                        }
                                                        isAdded={
                                                            addedItems.filter((x) => x.id === node.nodeId).length > 0
                                                        }
                                                        isHover={hoveredItemId === node.nodeId}
                                                        onClick={(event) => handleItemClick(node)}
                                                        onToggleAddNode={handleToggleAddItemOrNode}
                                                        node={node}
                                                    />
                                                </div>
                                            )
                                        })}
                                </List>
                            </Grid>
                        )}
                        {addedItems.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <FormGroup
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '79px',
                                                background: '#F8F8F8',
                                            }}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        checked={shouldConnect}
                                                        onChange={(event) => setShouldConnect(!!!shouldConnect)}
                                                        value={shouldConnect}
                                                        color="primary"
                                                    />
                                                }
                                                label="Connect stages"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '45px',
                                                    flex: 'none',
                                                    order: 0,
                                                    flexGrow: 0,
                                                    color: '#003C58',
                                                    textTransform: 'none',
                                                }}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '10px',
                                                gap: '10px',
                                                height: '55px',
                                                width: '267px',
                                                background: '#F8F8F8',
                                                flex: 'none',
                                                order: 1,
                                                flexGrow: 0,
                                            }}>
                                            <Button
                                                onClick={handleAddItemsOrNodes}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '20px 43px',
                                                    gap: '10px',
                                                    height: '45px',
                                                    background: '#003C58',
                                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.11)',
                                                    borderRadius: '10px',
                                                    flex: 'none',
                                                    order: 0,
                                                    flexGrow: 0,
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#003C58',
                                                    },
                                                }}>
                                                {'Add ' + addedItems.length}{' '}
                                                {addedItems.length === 1 ? ' item to lesson' : 'items to lesson'}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const { topic } = state
    return {
        topic,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPickerNodeItemAdd)
