import React, { useState, useRef, useEffect, Fragment } from 'react'
import { makeStyles, CircularProgress, Typography } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import Zoom from '@mui/material/Zoom'
import Box from '@mui/material/Box'
import FocusAreaListeningToken from '../../Images/FocusAreaListeningToken.jsx'
import FocusAreaReadingToken from '../../Images/FocusAreaReadingToken.jsx'
import FocusAreaWritingToken from '../../Images/FocusAreaWritingToken.jsx'
import FocusAreaSpokenInteractionToken from '../../Images/FocusAreaSpokenInteractionToken.jsx'
import FocusAreaSpokenProductionToken from '../../Images/FocusAreaSpokenProductionToken.jsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ACCConceptChipsLessonPlan from '../Components/ACCConceptChipsLessonPlan'
import StoryScriptDialogue from './StoryScriptDialogue.jsx'
import { AutoCourseCreatorStoryTypes } from '../.././../../../Logic/AutoCourseCreationConstants.jsx'
import { ReactComponent as StoryIcon } from '../../Images/StoryIcon.svg'
import PreviewLessonClapper from '../Components/PreviewLessonClapper'
import { AnimatePresence, motion } from 'framer-motion'
import LessonPlanRegenBar from '../Components/LessonPlanRegenBar'

const useStyles = makeStyles((theme) => ({}))

const ACCLessonPlanIntroductionCard = (props) => {
    // expected props
    // props.lessonPlanDto
    // selectedLesson={props.selectedLesson}
    // onClick={(e)=>handleClick(e, index2)}
    // lessonSuggestionDto={lessonSuggestionDto}
    // key={index2}
    // onMakeLesson={props.onMakeLesson}
    // onLessonFromLessonPlan={props.onLessonFromLessonPlan}
    // onPreviewLessson={props.onPreviewLesson}
    // onRemoveLesson={props.onRemoveLesson}
    // isLastInTopic={lessonPlanDto.lessons.length === index2 + 1}
    // lessonPlansId={lessonPlanDto.lessonPlanId}
    // onOpenLessonTypeModal={setLessonTypeModalOpenOrClosed}

    const [focusedAttribute, setFocusedAttribute] = useState(null)
    const focusedAttributeRef = useRef()
    focusedAttributeRef.current = focusedAttribute
    const [focusedText, setFocusedText] = useState('')
    const focusedTextRef = useRef()
    focusedTextRef.current = focusedText
    const [makeLessonButtonText, setMakeLessonButtonText] = useState('Create Lesson')
    const [showStoryScript, setShowStoryScript] = useState(false)
    const [doRegenAnimation, setDoRegenAnimation] = useState(false)
    const [showRegenBar, setShowRegenBar] = useState(false)
    const [moveRegenBar, setMoveRegenBar] = useState(false)
    const [showRegenBarExtended, setShowRegenBarExtended] = useState(false)

    const [lessonPlanConcepts, setLessonPlanConcepts] = useState([])
    const [storyScript, setStoryScript] = useState([])

    const [focusDistribtion, setFocusDistribution] = useState(null)

    const isFirstRender = useRef(true)

    const [test, setTest] = useState(false)

    useEffect(() => {
        if (props.lessonPlanDto.createdLessonId) {
            setMakeLessonButtonText('Recreate Lesson')
        } else {
            setMakeLessonButtonText('Create Lesson')
        }

        if (props.lessonPlanDto.lessonPlanChapters) {
            const tempConcepts = []
            const tempStoryScript = []
            props.lessonPlanDto.lessonPlanChapters.forEach((lessonPlanChapterDto) => {
                lessonPlanChapterDto.concepts.forEach((concept) => tempConcepts.push(concept))
                if (lessonPlanChapterDto.chapterDialogue?.length > 0) {
                    lessonPlanChapterDto.chapterDialogue.forEach((row) => tempStoryScript.push(row))
                }
            })
            setLessonPlanConcepts(tempConcepts)
            setStoryScript(tempStoryScript)
        }

        if (props.lessonPlanDto.focusDistributionDto) {
            let tempFocusAreas = [
                { type: 'Reading', friendlyName: 'Reading', number: props.lessonPlanDto.focusDistributionDto.reading },
                { type: 'Writing', friendlyName: 'Writing', number: props.lessonPlanDto.focusDistributionDto.writing },
                {
                    type: 'Listening',
                    friendlyName: 'Listening',
                    number: props.lessonPlanDto.focusDistributionDto.listening,
                },
                {
                    type: 'SpokenProduction',
                    friendlyName: 'Spoken Production',
                    number: props.lessonPlanDto.focusDistributionDto.spokenProduction,
                },
                {
                    type: 'SpokenInteraction',
                    friendlyName: 'Spoken Interaction',
                    number: props.lessonPlanDto.focusDistributionDto.spokenInteraction,
                },
            ]
            setFocusDistribution(tempFocusAreas)
        }
    }, [props.lessonPlanDto])

    useEffect(() => {
        if (isFirstRender.current) return

        if (showStoryScript && props.lessonPlanDto.lastChange) {
            setShowRegenBar(true)
            setShowRegenBarExtended(true)
        } else {
            setShowRegenBar(false)
        }
    }, [showStoryScript, props.lessonPlanDto.lastChange])

    useEffect(() => {
        if (!props.showLesson) {
            setShowStoryScript(false)
        }
    }, [props.showLesson])

    useEffect(() => {
        if (isFirstRender.current) return

        if (props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(props.lessonPlanDto.lessonPlanId)) {
            setShowRegenBar(true)
            setShowRegenBarExtended(true)
        } else if (
            !props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(props.lessonPlanDto.lessonPlanId) &&
            !showStoryScript
        ) {
            setShowRegenBar(false)
        }
    }, [props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(props.lessonPlanDto.lessonPlanId)])

    useEffect(() => {
        if (isFirstRender.current) return

        if (!showRegenBar) {
            setTimeout(() => setShowRegenBarExtended(false), 2000)
        }
    }, [showRegenBar])

    useEffect(() => {
        isFirstRender.current = false
    }, [])

    const classes = useStyles()
    const handleUpdateScriptText = (text, index, conceptIds) => {
        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'narrativeText', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            chapterDialogueIndex: index,
            text: text,
            conceptIds: conceptIds,
        })
    }

    const handleUpdateScriptRoleSelection = (text, index) => {
        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'updateNarrativeRoleSelection', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            text: text,
            scriptIndex: index,
        })
    }

    const handleAddScriptRole = (text) => {
        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'addNarrativeRole', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            text: text,
        })
    }

    const handleRemoveNarrativeScriptRow = (index) => {
        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'removeNarrativeScriptRow', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            scriptIndex: index,
        })
    }

    const handleAddNarrativeScriptRow = (index, roleSelection) => {
        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'addNarrativeScriptRow', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            scriptIndex: index,
            emptyRow: {
                chapterTextRowType: 'DialogueRow',
                conceptIds: [],
                speaker: { name: roleSelection, gender: 'Male' },
                text: '',
                translation: '',
            },
        })
    }

    const handleEditScriptRole = (text, index) => {
        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'editNarrativeRole', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            text: text,
            roleIndex: index,
        })
    }

    const handleEditRoleGender = (text, index) => {
        console.log('indesdx', index)

        props.onUpdateLanguageLessonData('languageSectionPlanDto', 'editNarrativeRoleGender', {
            lessonPlanId: props.lessonPlanDto.lessonPlanId,
            chaptersIndex: 0,
            chapterId: props.lessonPlanDto.lessonPlanChapters[0].chapterId,
            text: text,
            roleIndex: index,
        })
    }

    const handleSelectLesson = () => {
        setShowStoryScript(false)
        props.onSelectLesson(props.lessonPlanDto.lessonPlanId)
    }
    const stopEvent = (event) => {
        event.stopPropagation()
        event.preventDefault()
    }

    const handleRemoveLesson = (e) => {
        e.stopPropagation()
        props.onRemoveLesson(props.lessonPlanDto.lessonPlanId, props.lessonPlanDto.lessonName)
    }

    const handleMakeLesson = (event) => {
        event.stopPropagation()

        if (props.lessonPlanDto.lessonPlanId) {
            props.onMakeLessonFromLessonPlan(props.lessonPlanDto.lessonPlanId)
        }
    }

    const storyType = (event) => {
        return (
            <Grid item xs={12} style={{ marginTop: '9px' }}>
                <div
                    style={{
                        marginLeft: '68px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        borderRadius: 8,
                        background: 'var(--Off-white, #F8F8F8)',
                        padding: '16px',
                    }}>
                    <div>
                        <Box
                            style={{
                                color: 'var(--Dark-text, #35323C)',
                                fontFamily: 'Rubik',
                                fontSize: 16,
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '118%',
                            }}>
                            {props.lessonPlanDto?.teachingStyleOutputDto?.name}
                        </Box>
                        <Box
                            style={{
                                color: 'rgba(53, 50, 60, 0.80)',
                                fontFamily: 'Rubik',
                                fontSize: 14,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '140%',
                                marginBottom: '1px',
                            }}>
                            Modify focus areas by selecting{' '}
                            <span
                                style={{
                                    color: 'rgba(53, 50, 60, 0.80)',
                                    fontFamily: 'Rubik',
                                    fontSize: 14,
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '140%',
                                }}>
                                {' '}
                                Change{' '}
                            </span>{' '}
                            on the right
                        </Box>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            height: 32,
                            padding: '0px 16',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 4,
                            color: 'var(--Blue, #44A6EF)',
                            textAlign: 'center',
                            fontFamily: 'Rubik',
                            fontSize: 16,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '140%',
                            cursor: 'pointer',
                            marginTop: '1px',
                        }}
                        onClick={() => props.onOpenLessonTypeModal(true)}>
                        Change
                    </div>
                </div>
            </Grid>
        )
    }

    const focusAreas = (event) => {
        return (
            <Grid item xs={12} style={{ marginTop: '9px' }}>
                <div
                    style={{
                        marginLeft: '68px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}>
                    <div>
                        {focusDistribtion &&
                            focusDistribtion.map((focusArea, index) => {
                                return (
                                    <div style={{ display: 'inline' }}>
                                        {focusArea.type === 'Reading' && focusArea.number > 0 && (
                                            <div style={{ display: 'inline' }}>
                                                <FocusAreaReadingToken />{' '}
                                            </div>
                                        )}
                                        {focusArea.type === 'Listening' && focusArea.number > 0 && (
                                            <div style={{ display: 'inline' }}>
                                                <FocusAreaListeningToken />{' '}
                                            </div>
                                        )}
                                        {focusArea.type === 'Writing' && focusArea.number > 0 && (
                                            <div style={{ display: 'inline' }}>
                                                <FocusAreaWritingToken />{' '}
                                            </div>
                                        )}
                                        {focusArea.type === 'SpokenProduction' && focusArea.number > 0 && (
                                            <div style={{ display: 'inline' }}>
                                                <FocusAreaSpokenProductionToken />{' '}
                                            </div>
                                        )}
                                        {focusArea.type === 'SpokenProduction' && focusArea.number > 0 && (
                                            <div style={{ display: 'inline' }}>
                                                <FocusAreaSpokenInteractionToken />{' '}
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#EFEFF4',
                            marginRight: '5px',
                            borderRadius: '4px 4px 4px 4px',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleSelectLesson()}>
                        {props.showLesson ? <ExpandLessIcon color="action" /> : <ExpandMoreIcon />}
                    </div>
                </div>
            </Grid>
        )
    }

    const chapterDetails = (event) => {
        return (
            <Grid item xs={12} style={{ marginTop: '9px' }}>
                <div
                    style={{
                        marginLeft: '68px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        borderRadius: 8,
                        background: 'var(--Off-white, #F8F8F8)',
                        padding: '16px',
                    }}>
                    {props.lessonPlanDto.lessonType === 'Introduction' && (
                        <div>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        alignSelf: 'stretch',
                                        marginLeft: '4px',
                                        marginBottom: '25px',
                                        marginTop: '3px',
                                    }}>
                                    <Box
                                        style={{
                                            color: 'var(--Dark-text, #35323C)',
                                            fontFamily: 'Rubik',
                                            fontSize: 16,
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            lineHeight: '118%',
                                        }}>
                                        The vocabulary will be introduced in the context of this story
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            padding: '8px 16px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 4,
                                            borderRadius: 16,
                                            background: 'rgba(204, 133, 198, 0.50)',
                                            color: 'var(--Dark-text, #35323C)',
                                            fontFamily: 'Rubik',
                                            fontSize: 14,
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            textAlign: 'center',
                                        }}>
                                        {props.lessonPlanDto.lessonPlanChapters[0].name}
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        alignSelf: 'stretch',
                                        marginLeft: '4px',
                                    }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '20px' }}>
                                            <StoryIcon />
                                        </span>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box
                                                    style={{
                                                        color: 'var(--Dark-text, #35323C)',
                                                        fontFamily: 'Rubik',
                                                        fontSize: 16,
                                                        fontStyle: 'normal',
                                                        fontWeight: 500,
                                                        lineHeight: '118%',
                                                        marginBottom: '5px',
                                                    }}>
                                                    Chapter summary
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box
                                                    style={{
                                                        color: 'rgba(53, 50, 60, 0.80)',
                                                        fontFamily: 'Rubik',
                                                        fontSize: 14,
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        lineHeight: '140%',
                                                        paddingRight: '80px',
                                                    }}>
                                                    {props.lessonPlanDto.lessonPlanChapters[0].chapterSummary}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <div
                                            onClick={() => setShowStoryScript(!!!showStoryScript)}
                                            style={{
                                                display: 'flex',
                                                width: 287,
                                                height: 32,
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderRadius: 8,
                                                background: 'var(--White, #FFF)',
                                                color: 'var(--Dark-text, #35323C)',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                paddingTop: '3px',
                                                paddingBottom: '3px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                //font:
                                                fontFamily: 'Rubik',
                                                fontSize: 16,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '140%',
                                            }}>
                                            {showStoryScript ? 'Hide story details ' : 'Show story details'}
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                {showStoryScript ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    )}
                    <Grid item xs={12}>
                        {showStoryScript && storyScriptSection()}
                    </Grid>
                </div>
            </Grid>
        )
    }

    const storyScriptSection = () => {
        switch (props.storyType) {
            case AutoCourseCreatorStoryTypes.DialogueStory:
                return (
                    <StoryScriptDialogue
                        onUpdateScriptText={handleUpdateScriptText}
                        onAddScriptRole={handleAddScriptRole}
                        onRemoveNarrativeScriptRow={handleRemoveNarrativeScriptRow}
                        onAddNarrativeScriptRow={handleAddNarrativeScriptRow}
                        onEditScriptRole={handleEditScriptRole}
                        onEditRoleGender={handleEditRoleGender}
                        onUpdateScriptRoleSelection={handleUpdateScriptRoleSelection}
                        vocabularyItems={lessonPlanConcepts}
                        showRoles={true}
                        dialogueScript={storyScript}
                        lessonPlanChapters={props.lessonPlanDto.lessonPlanChapters}
                        actors={[
                            { name: 'Doctor', gender: 'Male' },
                            { name: 'Patient', gender: 'Female' },
                        ]}
                    />
                )
            case AutoCourseCreatorStoryTypes.InstructionStory:
                return (
                    <StoryScriptDialogue
                        onUpdateScriptText={handleUpdateScriptText}
                        onAddScriptRole={handleAddScriptRole}
                        onRemoveNarrativeScriptRow={handleRemoveNarrativeScriptRow}
                        onAddNarrativeScriptRow={handleAddNarrativeScriptRow}
                        onEditScriptRole={handleEditScriptRole}
                        onUpdateScriptRoleSelection={handleUpdateScriptRoleSelection}
                        vocabularyItems={lessonPlanConcepts}
                        showRoles={false}
                        dialogueScript={storyScript}
                        lessonPlanChapters={props.lessonPlanDto.lessonPlanChapters}
                        actors={[{}]}
                    />
                )
        }
    }

    const handlePreviewLesson = (event) => {
        event.stopPropagation()
        if (props.lessonPlanDto.createdLessonIds?.length > 0) {
            let numberOfLessons = props.lessonPlanDto.createdLessonIds.length
            let lastCustomLessonId = props.lessonPlanDto.createdLessonIds[numberOfLessons - 1]
            props.onPreviewLessson(lastCustomLessonId)
        }
    }

    return (
        <Fragment>
            <div
                style={{
                    borderRadius: 8,
                    border: '1px solid rgba(160, 67, 159, 0.32)',
                    minWidth: 200,
                    margin: '16px',
                    paddingBottom: '12px',
                    paddingRight: '12px',
                }}>
                <Zoom in={true}>
                    <Grid container>
                        <Grid item xs={10}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'top' }}>
                                    <div
                                        style={{
                                            borderRadius: 16,
                                            background: 'rgba(204, 133, 198, 0.50)',
                                            display: 'flex',
                                            width: 32,
                                            height: 32,
                                            padding: '0px 12',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 6,
                                            flexShrink: 0,
                                            marginRight: '16px',
                                            marginTop: '16px',
                                            marginLeft: '16px',
                                        }}>
                                        <Box
                                            style={{
                                                color: 'var(--Dark-text, #35323C)',
                                                fontFamily: 'Rubik',
                                                fontSize: 16,
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '140%',
                                            }}>
                                            {props.lessonNumber}
                                        </Box>
                                    </div>
                                    <div component="div">
                                        <Box
                                            style={{
                                                color: 'rgba(53, 50, 60, 0.80)',
                                                fontFamily: 'Rubik',
                                                fontSize: 14,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '140%',
                                                marginTop: '10px',
                                            }}>
                                            {props.lessonPlanDto.lessonType}
                                        </Box>
                                        <Box
                                            style={{
                                                color: 'var(--Dark-text, #35323C)',
                                                fontFamily: 'Rubik',
                                                fontSize: 20,
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '118%',
                                                alignSelf: 'stretch',
                                                marginBottom: '8px',
                                            }}>
                                            {props.lessonPlanDto.lessonName}
                                        </Box>
                                        {props.showLesson === true && (
                                            <Box
                                                style={{
                                                    color: 'var(--Dark-text, #35323C)',
                                                    fontFamily: 'Rubik',
                                                    fontSize: 16,
                                                    fontStyle: 'normal',
                                                    fontWeight: 500,
                                                    lineHeight: '118%',
                                                    marginTop: '16px',
                                                    marginBottom: '6px',
                                                }}>
                                                {props.lessonPlanDto.lessonType + ' of the following vocabulary'}
                                            </Box>
                                        )}
                                        <ACCConceptChipsLessonPlan concepts={lessonPlanConcepts} />
                                        {props.autoCourseCreation.isGeneratingNameAndDescriptionForLessonPlanIds?.includes(
                                            props.lessonPlanDto.lessonPlanId
                                        ) && (
                                            <React.Fragment>
                                                <CircularProgress
                                                    style={{
                                                        marginLeft: '10px',
                                                        color: 'grey',
                                                        height: '18px',
                                                        width: '18px',
                                                    }}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {props.lessonPlanDto.createdLessonIds && (
                                <PreviewLessonClapper
                                    onClickFunction={handlePreviewLesson}
                                    isLoading={props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(
                                        props.lessonPlanDto.lessonPlanId
                                    )}
                                />
                            )}

                            {/*{*/}
                            {/*  props.lessonPlanDto?.lessonPlanId &&*/}
                            {/*  <Button*/}
                            {/*    onClick={(event) => handleMakeLesson(event)}*/}
                            {/*    style={{*/}
                            {/*      textTransform: 'none',*/}
                            {/*      fontSize: '11px',*/}
                            {/*      background: CapeeshColors.Green,*/}
                            {/*      color: 'white',*/}
                            {/*      fontFamily: 'Rubik',*/}
                            {/*      fontStyle: 'normal',*/}
                            {/*      fontWeight: '400',*/}
                            {/*      padding: '10px',*/}
                            {/*      lineHeight: '12px',*/}
                            {/*      marginRight: '15px',*/}
                            {/*      cursor:  "pointer"*/}

                            {/*    }}*/}
                            {/*  >*/}
                            {/*    {props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(props.lessonPlanDto.lessonPlanId) ? <CircularProgress style={{color: 'white', height: '11px', width: '11px'}}/> : "Recreate lesson"}*/}
                            {/*  </Button>*/}
                            {/*}*/}
                        </Grid>
                        {props.showLesson === true && storyType()}
                        {props.showLesson === true &&
                            props.lessonPlanDto.lessonType === 'Introduction' &&
                            chapterDetails()}
                        {focusAreas()}
                        {props.selectedLesson?.lessonPlanId === props.lessonPlanDto.lessonPlanId && (
                            <React.Fragment>
                                <Grid container onClick={stopEvent}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            padding: '30px',
                                            paddingTop: '10px',
                                            maxHeight: '490px',
                                            overflowY: 'auto',
                                            alignContent: 'flex-start',
                                        }}></Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Zoom>
            </div>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {showRegenBarExtended && (
                    <AnimatePresence>
                        <LessonPlanRegenBar
                            onMakeLessonFromLessonPlan={props.onMakeLessonFromLessonPlan}
                            isInGenerationList={props.autoCourseCreation.isCreatingFromLessonPlanIds?.includes(
                                props.lessonPlanDto.lessonPlanId
                            )}
                            lessonName={props.lessonPlanDto.lessonName}
                            lessonPlanId={props.lessonPlanDto.lessonPlanId}
                            shouldChangeToFinishMode={!showRegenBar}
                            hasChanges={props.lessonPlanDto.lastChange}
                        />
                    </AnimatePresence>
                )}
            </div>
        </Fragment>
    )
}

ACCLessonPlanIntroductionCard.propTypes = {}

const mapStateToProps = (state) => {
    return {
        languages: state.metadata.metadata.languages,
        autoCourseCreation: state.autoCourseCreation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCLessonPlanIntroductionCard)
