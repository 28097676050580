import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { snackbarActions } from '../_state/actions'

const Notifier = (props) => {
    const { notifications, enqueueSnackbar, removeSnackbar } = props
    const [displayed, setDisplayed] = useState([])

    const storeDisplayed = (key) => {
        setDisplayed([...displayed, key])
    }

    useEffect(() => {
        notifications.forEach((notification) => {
            setTimeout(() => {
                // If notification already displayed, abort
                if (displayed.indexOf(notification.key) > -1) return
                // Display notification using notistack
                enqueueSnackbar(notification.message, notification.options)
                // Add notification's key to the local state
                storeDisplayed(notification.key)
                // Dispatch action to remove the notification from the redux store
                removeSnackbar(notification.key)
            }, 1)
        })
    }, [notifications])

    return null
}

Notifier.propTypes = {}

const mapStateToProps = (state) => {
    return {
        notifications: state.snackbar.notifications,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeSnackbar: (key) => dispatch(snackbarActions.removeSnackbar(key)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier))
