import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { ReactComponent as IPhoneFrame } from '../../../assets/images/iphone_14_clay_3.svg'

const useStyles = makeStyles((theme) => ({
    customDialog: {
        display: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
    },
    open: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        display: 'flex',
    },
    dialog: {
        backgroundColor: 'transparent',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        overflow: 'hidden',
        maxWidth: 'fit-content',
        width: '80%',
        maxHeight: '80%',
        minHeight: 'fit-content',
        overflowY: 'auto',
        placeSelf: 'center',
    },
    header: {
        padding: theme.spacing(2),
        background: 'transparent',
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'end',
        top: 0,
        position: 'sticky',
        zIndex: 2,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    closeButton: {
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
    },
    content: {
        padding: theme.spacing(2),
    },
    horizontalContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    verticalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentContainer: {
        borderRadius: 16,
        backgroundColor: 'white',
    },
}))

const AdjustableModal = (props) => {
    const classes = useStyles()

    const handleClose = (event) => {
        if (event.target.id === 'overlay') {
            props.closeDialog()
        }
    }

    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }

        return () => {
            document.body.style.overflow = ''
        }
    }, [props.isOpen])

    return (
        <div
            hidden={!props.isOpen}
            className={`${classes.customDialog} ${props.isOpen ? classes.open : ''}`}
            onClick={handleClose}>
            <div className={classes.overlay} id={'overlay'}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}>
                    <div className={classes.verticalContainer}>
                        <div className={classes.contentContainer}>{props.topContent ?? <Fragment />}</div>
                        <div className={classes.horizontalContainer}>
                            <div className={classes.contentContainer}>{props.leftContent ?? <Fragment />}</div>
                            <div className={classes.content}>
                                <div className={classes.header}>
                                    <div className={classes.closeButton}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <IconButton
                                                onClick={() => props.closeDialog()}
                                                style={{
                                                    justifyContent: 'flex-end',
                                                    display: 'flex',
                                                }}>
                                                <CancelOutlinedIcon
                                                    style={{
                                                        fill: 'black',
                                                        width: 24,
                                                        height: 24,
                                                    }}
                                                    size="medium"
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <div
                                        style={{
                                            zIndex: 1,
                                            position: 'relative',
                                            pointerEvents: 'none',
                                        }}>
                                        <IPhoneFrame />
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            position: 'absolute',
                                            top: '26px',
                                            right: 53,
                                            height: 735,
                                            width: 380,
                                            zIndex: 0,
                                            padding: 8,
                                        }}>
                                        {props.centerContent ?? <Fragment />}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.contentContainer}>{props.rightContent ?? <Fragment />}</div>
                        </div>
                        <div className={classes.contentContainer}>{props.bottomContent ?? <Fragment />}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AdjustableModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustableModal)
