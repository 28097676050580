import React, { useEffect, useState } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Button,
    withWidth,
    Avatar,
    Paper,
    TextField,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    List,
    makeStyles,
    useScrollTrigger,
    Grid,
} from '@material-ui/core'
import { Search, AccountCircle, Person, Group, School, Settings } from '@material-ui/icons'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { ReactComponent as CapeeshLogo } from 'assets/images/capeesh_logo.svg'
import { ReactComponent as CapeeshIcon } from 'assets/images/capeesh_icon.svg'

import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import deburr from 'lodash/deburr'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { history } from '../_state/helpers'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'
import { UserStatisticsVisibility } from '../Views/Private/CapeeshConstants'
import Select from '@material-ui/core/Select'
import { userActions } from '../_state/actions'

import CreateMenu from './CDropdownMenu/CreatorMenu'
import DashboardMenu from './CDropdownMenu/DashboardMenu'
import AdminMenu from './CDropdownMenu/AdminMenu'
import { CapeeshColors } from '../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '98px',
        top: 0,
        position: 'fixed',
        zIndex: 100,
    },
    appBar: {
        position: 'relative',
        padding: '0px 20px 0px 20px',
        //boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
    },
    toolBar: {
        padding: '0px',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    capeeshLogo: {
        position: 'relative',
        height: '64px',
        width: '150px',
        cursor: 'pointer',
        transition: 'all 150ms',
        boxShadow: 'none',
        fill: theme.palette.primary.contrastText,
    },
    capeeshIcon: {
        position: 'relative',
        height: '42px',
        width: '42px',
        cursor: 'pointer',
        transition: 'all 150ms',
        boxShadow: 'none',
        overflow: 'visible',
        fill: theme.palette.primary.contrastText,
    },
    logoButton: {
        height: '64px',
        padding: '0',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    username: {
        margin: 'auto 5px',
        color: theme.palette.primary.contrastText,
    },
    orgName: {
        margin: 'auto 5px',
        color: theme.palette.primary.contrastText,
    },
    dropdownMenu: {
        marginTop: 35,
    },
    profileButton: {
        borderRadius: '5px',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(1),
        marginLeft: '20px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        position: 'relative',
        paddingTop: '5px',
        paddingRight: theme.spacing(1),
        paddingBottom: '5px',
        paddingLeft: theme.spacing(5),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 250,
        },
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        maxHeight: '280px',
        overflow: 'auto',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    breadcrumbs: {
        position: 'relative',
        padding: '5px 20px 5px 20px',
    },
    breadcrumb: {
        letterSpacing: '0.41px',
        textTransform: 'none',
        color: 'inherit',
        alignItems: 'center',
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    languageSelectorItem: {
        display: 'flex',
        alignItems: 'center',
    },
}))

const menuOptionsInternalAdmin = [
    { link: '/admin/overall/stats', text: 'Overall Stats' },
    { link: '/admin/overall/search', text: 'Overall Search' },
    { link: '/research-and-development', text: 'Research and Development' },
    { link: '/settings', text: 'Your Profile' },
    { link: '/help', text: 'Help & Guides' },
    { link: '/login', text: 'Sign out' },
]

const menuOptionsAdmin = [
    { link: '/research-and-development', text: 'Research and Development' },
    { link: '/settings', text: 'Your Profile' },
    { link: '/help', text: 'Help & Guides' },
    { link: '/login', text: 'Sign out' },
]

const menuOptionsTeacher = [{ link: '/login', text: 'Sign out' }]

const menuOptionsReporting = [{ link: '/login', text: 'Sign out' }]

const menuOptionsReportingClassroomOnly = [{ link: '/login', text: 'Sign out' }]

const menuOptionsQa = [{ link: '/login', text: 'Sign out' }]

const menuOptionDefault = [{ link: '/login', text: 'Sign out' }]

function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: (node) => {
                    ref(node)
                    inputRef(node)
                },
                disableUnderline: true,
            }}
            {...other}
        />
    )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)

    return (
        <ListItem selected={isHighlighted}>
            <ListItemText inset>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    )
                })}
            </ListItemText>
        </ListItem>
    )
}

function renderSectionTitle(section) {
    let icon = <Settings />

    switch (section.title) {
        case 'Users':
            icon = <Person />
            break
        case 'Courses':
            icon = <Group />
            break
        case 'Classrooms':
            icon = <School />
            break
        case 'Actions & links':
            icon = <Settings />
            break
    }

    return (
        <ListItem style={{ height: '50px' }} divider>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListSubheader>{section.title}</ListSubheader>
        </ListItem>
    )
}

function getSuggestions(value, list) {
    if (!value) {
        return []
    }
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    if (inputLength === 0) return []

    let suggestions = []

    //With sections
    list.map((section) => {
        let sectionSuggestions = {
            title: section.title,
            suggestions: section.suggestions.filter((suggestion) => {
                return suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
            }),
        }
        sectionSuggestions.suggestions.length > 0 && suggestions.push(sectionSuggestions)
    })

    return suggestions
}

function getSuggestionValue(suggestion) {
    return suggestion.label
}

function getSectionSuggestions(section) {
    return section.suggestions
}

const PrimarySearchAppBar = (props) => {
    const { user, organization, topic, usergroup, lesson } = props
    const classes = useStyles()
    const location = useLocation()

    const pathName = window.location.pathname
    let isAdminPath = false
    let isCreatorPath = false
    let isDashboardPath = false
    let isTopDashboardPath = false
    let primaryColor = CapeeshColors.Primary600

    if (pathName === '/' || pathName === '') {
        isTopDashboardPath = true
    } else if (pathName.startsWith('/creator')) {
        isCreatorPath = true
    } else if (pathName.startsWith('/admin')) {
        isAdminPath = true
    } else if (pathName.startsWith('/dashboard')) {
        isDashboardPath = true
    }

    let courseGroupMatch = useRouteMatch({
        path: '/creator/course/group/:courseGroupId',
        strict: true,
        sensitive: true,
    })

    let courseGroupCourseMatch = useRouteMatch({
        path: '/creator/course/group/:courseGroupId/topic/:topicId',
        strict: true,
        sensitive: true,
    })

    let courseGroupCourseLessonBuilderMatch = useRouteMatch({
        path: '/creator/course/group/:courseGroupId/topic/:topicId/lesson/edit/:lessonId',
        strict: true,
        sensitive: true,
    })

    let classroomMatch = useRouteMatch({
        path: '/dashboard/classroom/:classroomId',
        strict: true,
        sensitive: true,
    })
    let classroomStudentMatch = useRouteMatch({
        path: '/dashboard/classroom/:classroomId/students/user/:userId',
        strict: true,
        sensitive: true,
    })
    let studentMatch = useRouteMatch({
        path: '/dashboard/students/user/:userId',
        strict: true,
        sensitive: true,
    })
    let adminUserMatch = useRouteMatch({
        path: '/dashboard/admin/organization/admins/:userId',
        strict: true,
        sensitive: true,
    })

    let courseMatch = useRouteMatch({
        path: '/creator/topic/list/:topicId',
        strict: true,
        sensitive: true,
    })

    let courseLessonMatch = useRouteMatch({
        path: '/creator/topic/list/:topicId/lessons/edit/:customLessonId',
        strict: true,
        sensitive: true,
    })

    let autoCourseCreatorLanguageMatch = useRouteMatch({
        path: '/creator/course/group/:courseGroupId/topic/:topicId/acc/language',
        strict: true,
        sensitive: true,
    })

    let autoCourseCreatorMatch = useRouteMatch({
        path: '/creator/course/group/:courseGroupId/topic/:topicId/acc',
        strict: true,
        sensitive: true,
    })

    let creatorOverviewMatch = useRouteMatch({
        path: '/creator/overview',
        strict: true,
        sensitive: true,
    })

    let orgStatsMatch = useRouteMatch({
        path: '/org/stats',
        strict: true,
        sensitive: true,
    })

    let useBlue = autoCourseCreatorLanguageMatch !== null || autoCourseCreatorMatch !== null

    const [anchorEl, setAnchorEl] = useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
    const [totalSearchableList, setTotalSearchableList] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [suggestions, setSuggestions] = useState([])

    const theme = useTheme()
    const smDownMatch = useMediaQuery(theme.breakpoints.down('sm'))

    const pathNames = location.pathname.split('/').filter((x) => x)
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    let selectedOrganization = null
    if (organization.selectedOrganization) {
        selectedOrganization = organization.myOrganizations.find((e) => e.id === organization.selectedOrganization)
    }

    let breadcrumbs = []
    breadcrumbs.push(
        <Link color="inherit" to={'/'} key={'mainDashboard'} className={classes.breadcrumb}>
            <HomeIcon className={classes.icon} />
            {selectedOrganization ? selectedOrganization.name : ''}
        </Link>
    )

    pathNames.map((path, index) => {
        const last = index === pathNames.length - 1
        const paths = pathNames.slice(0, index + 1)
        let to = `/${paths.join('/')}`

        document.title = 'Capeesh Dashboard - ' + paths[paths.length - 1]

        if (creatorOverviewMatch && creatorOverviewMatch.url === to) {
            breadcrumbs.pop()
            path = 'create'
            document.title = 'Create'
        }

        if (courseGroupMatch && courseGroupMatch.url === to) {
            breadcrumbs.pop()
            breadcrumbs.pop()
            const courseGroup = organization.availableCourseGroups?.find(
                (x) => x.id === courseGroupMatch.params.courseGroupId
            )
            if (courseGroup) {
                path = courseGroup.name
                document.title = courseGroup.name
            } else {
                path = 'coursegroup'
                document.title = 'CourseGroup'
            }
        }

        if (courseGroupCourseMatch && courseGroupCourseMatch.url === to) {
            breadcrumbs.pop()

            let course = topic.topics?.find((x) => x.id == courseGroupCourseMatch.params.topicId)

            if (course == null) {
                path = courseGroupCourseMatch.params.topicId
                document.title += 'Course'
            } else {
                path = course.name
                document.title += course.name
            }
        }

        if (courseGroupCourseLessonBuilderMatch && courseGroupCourseLessonBuilderMatch.url === to) {
            breadcrumbs.pop()
            breadcrumbs.pop()
            const lessonInstance = lesson.organizationLessons?.find(
                (x) => x.id === courseGroupCourseLessonBuilderMatch.params.lessonId
            )
            if (lessonInstance == null) {
                path = courseGroupCourseLessonBuilderMatch.params.customLessonId
                document.title += 'Lesson'
            } else {
                path = 'Editing ' + lessonInstance.name
                document.title += lessonInstance.name
            }
        }

        if (classroomMatch && classroomMatch.url === to) {
            breadcrumbs.pop()
            const classroom = usergroup.usergroups?.find((x) => x.id === classroomMatch.params.classroomId)
            to += '/dashboard'

            if (classroom) {
                path = classroom.name
                document.title = classroom.name
            } else {
                path = 'classroom'
                document.title = 'Classroom'
            }
        }
        if (studentMatch && studentMatch.url === to) {
            breadcrumbs.pop()
            const student = organization.organizationUsers?.find((x) => x.id === studentMatch.params.userId)
            if (student == null) {
                path = studentMatch.params.studentId
                document.title += 'Student'
            } else {
                path = student.name
                document.title += student.name
            }
        }

        if (adminUserMatch && adminUserMatch.url === to) {
            const adminUser = organization.organizationUsers?.find((x) => x.id === adminUserMatch.params.userId)
            if (adminUser == null) {
                path = studentMatch.params.studentId
                document.title += 'Admin user'
            } else {
                path = adminUser.name
                document.title += adminUser.name
            }
        }

        if (classroomStudentMatch && classroomStudentMatch.url === to) {
            breadcrumbs.pop()
            const student = organization.organizationUsers?.find((x) => x.id === classroomStudentMatch.params.userId)
            if (student == null) {
                path = classroomStudentMatch.params.userId
                document.title += 'Student'
            } else {
                path = student.name
                document.title += student.name
            }
        }

        if (courseMatch && courseMatch.url === to) {
            breadcrumbs.pop()
            breadcrumbs.pop()
            let course = topic.topics?.find((x) => x.id == courseMatch.params.topicId)

            if (course == null) {
                path = courseMatch.params.topicId
                document.title += 'Course'
            } else {
                path = course.name
                document.title += course.name
            }
        }

        if (courseLessonMatch && courseLessonMatch.url === to) {
            breadcrumbs.pop()
            breadcrumbs.pop()

            if (lesson.selectedScenario?.id === courseLessonMatch.params.customLessonId) {
                path = 'Editing ' + lesson.selectedScenario.name
                document.title += lesson.selectedScenario.name
            } else {
                path = courseLessonMatch.params.customLessonId
                document.title += 'scenario'
            }
        }

        if (autoCourseCreatorLanguageMatch && autoCourseCreatorLanguageMatch.url === to) {
            breadcrumbs.pop()

            document.title += 'language'
        }

        if (orgStatsMatch && orgStatsMatch.url === to) {
            breadcrumbs.pop()
            document.title = 'Organization statistics'
        }

        const extraStyling = index === pathNames.length - 1 ? { opacity: '0.75', textDecoration: 'none' } : null

        breadcrumbs.push(
            <Link color="inherit" to={to} key={to} className={classes.breadcrumb} style={extraStyling}>
                {path}
            </Link>
        )
    })

    useEffect(() => {
        indexSearchableList()
    }, [])

    useEffect(() => {
        if (organization.selectedOrganization) {
            selectedOrganization = organization.myOrganizations.find((e) => e.id === organization.selectedOrganization)
        }
        indexSearchableList()
    }, [organization.selectedOrganization])

    useEffect(() => {
        indexSearchableList()
    }, [topic.topics])

    useEffect(() => {
        indexSearchableList()
    }, [usergroup.usergroups])

    useEffect(() => {
        indexSearchableList()
    }, [lesson.organizationLessons])

    const indexSearchableList = () => {
        if (!isDashboardPath) {
            return
        }
        // let selectedOrganization = null;
        // if (organization.selectedOrganization) {
        //     selectedOrganization = organization.myOrganizations.find(e => e.id === organization.selectedOrganization);
        // }
        //
        // let totalSearchableList = [];
        // let organizationUsers = organization.organizationUsers;
        // let strictMode = false;
        // if (selectedOrganization?.userStatisticsVisibility === UserStatisticsVisibility.Strict) {
        //     strictMode = true;
        // }
        // if (user.organizationRole === "reporting") {
        //     if (strictMode && !user.classroomOnlyReportingUser) {
        //         // for org reporting user in strict mode, make no organization users searchable
        //         organizationUsers = []
        //     } else {
        //         organizationUsers = organizationUsers.filter(x => !x.email.includes('@itsoundsgood.no') && !x.email.includes('@capeesh.com'))
        //     }
        // }
        //
        // organizationUsers && totalSearchableList.push({
        //     title: 'Users by email',
        //     suggestions: organizationUsers.map(user => {
        //         return {
        //             label: user.email,
        //             link: '/dashboard/user/' + user.id,
        //         }
        //     })
        // });
        // organizationUsers && totalSearchableList.push({
        //     title: 'Users by name',
        //     suggestions: organizationUsers.map(user => {
        //         return {
        //             label: user.name,
        //             link: '/dashboard/user/' + user.id,
        //         }
        //     })
        // });
        //
        // {
        //     (user.organizationRole === "admin" || user.organizationRole === "teacher" || user.organizationRole === "qa") &&
        //     topic.topics && totalSearchableList.push({
        //         title: 'Courses',
        //         suggestions: topic.topics.map(topic => {
        //             return {
        //                 label: topic.name,
        //                 link: '/creator/topic/list/' + topic.id,
        //             }
        //         })
        //     });
        // }
        //
        // usergroup.usergroups && totalSearchableList.push({
        //     title: 'Classrooms',
        //     suggestions: usergroup.usergroups.map(classroom => {
        //         return {
        //             label: classroom.name,
        //             link: '/dashboard/classroom/' + classroom.id + '/dashboard',
        //         }
        //     })
        // });
        //
        // setTotalSearchableList(totalSearchableList);
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const [isScrolled, setIsScrolled] = useState(false)

    const handleScroll = () => {
        // Set isScrolled true if scroll position is more than 0
        const scrolled = window.scrollY > 20
        setIsScrolled(scrolled)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        // Cleanup the listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleSuggestionsFetchRequested = ({ value }) => {
        // setSuggestions(getSuggestions(value, totalSearchableList));
    }

    const handleSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    const handleSuggestionSelected = (
        event,
        { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) => {
        history.push(suggestion.link)
    }

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
    }

    let menuOption = menuOptionDefault
    if (user.organizationRole === 'admin') {
        if (user.info?.showOverallFunctionality) {
            menuOption = menuOptionsInternalAdmin
        } else {
            menuOption = menuOptionsAdmin
        }
    } else if (user.organizationRole === 'teacher') {
        menuOption = menuOptionsTeacher
    } else if (user.organizationRole === 'reporting' && user.classroomOnlyReportingUser === false) {
        menuOption = menuOptionsReporting
    } else if (user.organizationRole === 'reporting' && user.classroomOnlyReportingUser === true) {
        menuOption = menuOptionsReportingClassroomOnly
    } else if (user.organizationRole === 'qa') {
        menuOption = menuOptionsQa
    }

    const autosuggestProps = {
        renderInputComponent,
        suggestions: suggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        onSuggestionSelected: handleSuggestionSelected,
        getSuggestionValue,
        getSectionSuggestions,
        renderSuggestion,
        renderSectionTitle,
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className={classes.dropdownMenu}>
            {menuOption.map((option, index) => (
                <MenuItem key={index} id={index} onClick={handleMenuClose} component={Link} to={option.link}>
                    {option.text}
                </MenuItem>
            ))}
        </Menu>
    )

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            className={classes.dropdownMenu}>
            {menuOption.map((option, index) => (
                <MenuItem key={index} id={index} onClick={handleMenuClose} component={Link} to={option.link}>
                    {option.text}
                </MenuItem>
            ))}
        </Menu>
    )
    const changeLocalizedLanguage = (e) => {
        props.dispatch(userActions.setDashboardLanguage(e.target.value))
    }

    return (
        <div className={classes.root}>
            <AppBar
                className={classes.appBar}
                style={{
                    background: CapeeshColors.CorporateBlue2024,
                    boxShadow: 'none',
                }}>
                <Toolbar className={classes.toolBar}>
                    <Button
                        component={Link}
                        to="/"
                        disableFocusRipple={true}
                        disableRipple={true}
                        className={classes.logoButton}>
                        {smDownMatch ? (
                            <CapeeshIcon width={42} height={42} className={classes.capeeshIcon} />
                        ) : (
                            <CapeeshLogo width={150} height={64} className={classes.capeeshLogo} />
                        )}
                    </Button>

                    <DashboardMenu userGroups={props.usergroup.usergroups} underline={isDashboardPath} />
                    <CreateMenu underline={isCreatorPath} />
                    {props.user.organizationRole === 'admin' && <AdminMenu underline={isAdminPath} />}

                    {/*{*/}
                    {/*  isDashboardPath &&*/}
                    {/*  <div className={classes.search}>*/}
                    {/*      <React.Fragment>*/}
                    {/*          <div className={classes.searchIcon}>*/}
                    {/*              <Search/>*/}
                    {/*          </div>*/}
                    {/*          <Autosuggest*/}
                    {/*            {...autosuggestProps}*/}
                    {/*            inputProps={{*/}
                    {/*                classes,*/}
                    {/*                placeholder: 'Search...',*/}
                    {/*                value: searchValue,*/}
                    {/*                onChange: handleSearchChange,*/}
                    {/*            }}*/}
                    {/*            theme={{*/}
                    {/*                container: classes.container,*/}
                    {/*                suggestionsContainerOpen: classes.suggestionsContainerOpen,*/}
                    {/*                suggestionsList: classes.suggestionsList,*/}
                    {/*                suggestion: classes.suggestion,*/}
                    {/*                input: classes.searchInput,*/}
                    {/*            }}*/}
                    {/*            renderSuggestionsContainer={options => (*/}
                    {/*              <Paper {...options.containerProps} square>*/}
                    {/*                  {options.children}*/}
                    {/*              </Paper>*/}
                    {/*            )}*/}
                    {/*            multiSection*/}
                    {/*          />*/}
                    {/*      </React.Fragment>*/}
                    {/*  </div>*/}
                    {/*}*/}

                    <div className={classes.grow} />
                    <Select
                        value={props.user.userDashboardLanguageId ? props.user.userDashboardLanguageId : 1}
                        style={{
                            width: '180px',
                            height: '40px',
                            backgroundColor: 'white',
                            borderRadius: theme.shape.borderRadius,
                        }}
                        // autoWidth
                        onChange={changeLocalizedLanguage}>
                        <MenuItem value={1} key={1}>
                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                        <img width="30" src="https://flagcdn.com/64x48/gb.png"></img>
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{}}>
                                    <Typography style={{ width: '120px', textAlign: 'left', marginLeft: '1px' }}>
                                        English
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>

                        <MenuItem value={3} key={3}>
                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                        <img width="30" src="https://flagcdn.com/64x48/no.png" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{}}>
                                    <Typography style={{ width: '120px', textAlign: 'left', marginLeft: '1px' }}>
                                        Norsk bokmål
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>

                        <MenuItem value={6} key={6}>
                            <Grid container direction="row" className={classes.languageSelectorItem}>
                                <Grid item xs={4}>
                                    <Typography style={{ fontSize: '32px', marginLeft: '5px' }}>
                                        <img width="30" src="https://flagcdn.com/64x48/de.png" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={{ width: '120px', textAlign: 'left', marginLeft: '1px' }}>
                                        Deutsch
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Select>

                    <div className={classes.sectionDesktop}>
                        <IconButton
                            aria-owns={isMenuOpen ? 'material-appbar' : null}
                            aria-haspopup="true"
                            className={classes.profileButton}
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            disableRipple>
                            <Typography className={classes.username} color="primary">
                                {user.info.name}
                            </Typography>
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                            {user.info && user.info.profilePictureUrl ? (
                                <Avatar alt={user.info.name} src={user.info.profilePictureUrl} />
                            ) : (
                                <AccountCircle />
                            )}
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
            {renderMobileMenu}
            {!isTopDashboardPath && breadcrumbs.length > 1 && props.showBreadcrumb === true && (
                <Breadcrumbs
                    separator="›"
                    aria-label="breadcrumb"
                    style={{
                        color: useBlue ? 'white' : '#222222',
                        backgroundColor: useBlue
                            ? isScrolled
                                ? CapeeshColors.CorporateBlue2024
                                : 'transparent'
                            : '#FFFFFF',
                        boxShadow: useBlue ? null : '0px 1px 10px rgba(0,0,0,0.1)',
                    }}
                    className={classes.breadcrumbs}>
                    {breadcrumbs.map((breadcrumb, index) => {
                        return breadcrumb
                    })}
                </Breadcrumbs>
            )}
        </div>
    )
}

PrimarySearchAppBar.propTypes = {}

const mapStateToProps = (state) => {
    const { user, organization, topic, usergroup, auth, lesson } = state
    return {
        user,
        organization,
        topic,
        usergroup,
        auth,
        lesson,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimarySearchAppBar)
