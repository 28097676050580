import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import DailyUsageGraph from '../../../Components/Graphs/DailyUsageGraph'
import DailySessionGraph from '../../../Components/Graphs/DailySessionsGraph'
import { overallActions } from '../../../_state/actions/overall.actions'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: '25px',
    },
    button: {
        margin: theme.spacing(1),
    },
    graph: {
        marginTop: 20,
    },
    leaderBoardIndex: {
        background: theme.palette.primary.main,
    },
}))

const OverallStatistics = (props) => {
    const [showUserOnboardingAndCreatedAtGraphs, setShowUserOnboardingAndCreatedAtGraphs] = useState(false)
    const [showUsageGraphs, setShowUsageGraphs] = useState(false)

    const [dailyOnboardedUsers, setDailyOnboardedUsers] = useState([])
    const [dailyCreatedUsers, setDailyCreatedUsers] = useState([])
    const [dailyNumberOfUsers, setDailyNumberOfUsers] = useState([])
    const [dailyActiveUsers, setDailyActiveUsers] = useState([])
    const [dailyMinutesSpent, setDailyMinutesSpent] = useState([])
    const [dailyAggregatedMinutesSpent, setDailyAggregatedMinutesSpent] = useState([])
    const [dailyNumberOfSessions, setDailyNumberOfSessions] = useState([])

    useEffect(() => {
        const { dispatch } = props
        dispatch(overallActions.getGraphDataDailyUsageAggregated())
        dispatch(overallActions.getGraphDataDailyUserOnboarding())
    }, [])

    useEffect(() => {
        const { dispatch } = props

        const interval = setInterval(
            () => {
                dispatch(overallActions.getGraphDataDailyUsageAggregated())
                dispatch(overallActions.getGraphDataDailyUserOnboarding())
            },
            1000 * 60 * 2
        )
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        const { overall } = props

        if (
            overall?.dataDailyUsageAggregated?.canShowGraphData &&
            overall?.dataDailyUsageAggregated?.overallGraphStatsAggregateDayItemDtos?.length > 0
        ) {
            var tempDailyActiveUsers = overall.dataDailyUsageAggregated.overallGraphStatsAggregateDayItemDtos.map(
                (x) => ({ name: x.day, value: x.activeUsers })
            )
            setDailyActiveUsers(tempDailyActiveUsers)
            var tempDailyMinutesSpent = overall.dataDailyUsageAggregated.overallGraphStatsAggregateDayItemDtos.map(
                (x) => ({ name: x.day, value: x.minutes })
            )
            setDailyMinutesSpent(tempDailyMinutesSpent)

            var tempDailyAggregatedMinutesSpent =
                overall.dataDailyUsageAggregated.overallGraphStatsAggregateDayItemDtos.map((x) => ({
                    name: x.day,
                    value: x.aggregatedMinutes,
                }))
            setDailyAggregatedMinutesSpent(tempDailyAggregatedMinutesSpent)

            var tempDailyNumberOfSessions = overall.dataDailyUsageAggregated.overallGraphStatsAggregateDayItemDtos.map(
                (x) => ({ name: x.day, value: x.numberOfSessions })
            )
            setDailyNumberOfSessions(tempDailyNumberOfSessions)
            setShowUsageGraphs(true)
        }
    }, [props.overall.dataDailyUsageAggregated])

    useEffect(() => {
        const { overall } = props
        if (
            overall?.dataDailyUserOnboarding?.canShowGraphData &&
            overall?.dataDailyUserOnboarding?.overallUserStatsDayItemDtos?.length > 0
        ) {
            var tempDailyOnboardedUsers = overall.dataDailyUserOnboarding.overallUserStatsDayItemDtos.map((x) => ({
                name: x.day,
                value: x.numberOfNewOnboardedUsers,
            }))
            setDailyOnboardedUsers(tempDailyOnboardedUsers)
            var tempDailyCreatedUsers = overall.dataDailyUserOnboarding.overallUserStatsDayItemDtos.map((x) => ({
                name: x.day,
                value: x.numberOfNewUsers,
            }))
            setDailyCreatedUsers(tempDailyCreatedUsers)

            var tempDailyNumberOfUsers = overall.dataDailyUserOnboarding.overallUserStatsDayItemDtos.map((x) => ({
                name: x.day,
                value: x.numberOfUsers,
            }))
            setDailyNumberOfUsers(tempDailyNumberOfUsers)

            setShowUserOnboardingAndCreatedAtGraphs(true)
        }
    }, [props.overall.dataDailyUserOnboarding])

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={3}>
                    <DailyUsageGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how many users onboarded per day'}
                        toolTipPrefix={'Number of onboarded Users'}
                        graphData={dailyOnboardedUsers}
                        canShowChart={showUserOnboardingAndCreatedAtGraphs}
                        showReferenceLine={false}
                        title={'Onboared Users'}
                        yLabel={'users'}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <DailyUsageGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how many users were added per day'}
                        toolTipPrefix={'Number of created users'}
                        graphData={dailyCreatedUsers}
                        canShowChart={showUserOnboardingAndCreatedAtGraphs}
                        showReferenceLine={false}
                        title={'Added Users'}
                        yLabel={'users'}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <DailyUsageGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how many users are on the platform'}
                        toolTipPrefix={'Number of users'}
                        graphData={dailyNumberOfUsers}
                        canShowChart={showUserOnboardingAndCreatedAtGraphs}
                        showReferenceLine={false}
                        title={'All Users'}
                        yLabel={'users'}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <DailyUsageGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how many users are active per day'}
                        toolTipPrefix={'Number of active users'}
                        graphData={dailyActiveUsers}
                        canShowChart={showUsageGraphs}
                        showReferenceLine={false}
                        title={'Daily active users'}
                        yLabel={'users'}
                        isMinutes={false}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <DailyUsageGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how much time is spent in the app'}
                        toolTipPrefix={'Time spent in app '}
                        graphData={dailyMinutesSpent}
                        canShowChart={showUsageGraphs}
                        showReferenceLine={false}
                        title={'Usage for all users'}
                        yLabel={'minutes'}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <DailyUsageGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how much time is spent in the app'}
                        toolTipPrefix={'Aggregated time spent in app: '}
                        graphData={dailyAggregatedMinutesSpent}
                        canShowChart={showUsageGraphs}
                        showReferenceLine={false}
                        title={'Usage aggregated'}
                        yLabel={'minutes'}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <DailySessionGraph
                        defaultHeaderMessage={'Waiting for graph data....'}
                        headerMessage={'Shows how many sessions users have in the app over time'}
                        toolTipPrefix={'Aggregate sessions'}
                        graphData={dailyNumberOfSessions}
                        canShowChart={showUsageGraphs}
                        showReferenceLine={false}
                        title={'Sessions'}
                        yLabel={'number of sessions'}
                        isMinutes={false}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

OverallStatistics.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { overall } = state
    return {
        overall,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OverallStatistics)
