import { authConstants, randdConstants } from '../actions'

const initialState = {
    adjectiveAntonymCheck: false,
    adjectives: false,
    articles: false,
    negation: false,
    nounChunks: false,
    nounsSimilarity: false,
    nounspluralpass: false,
    personalPronouns: false,
    probableNonWords: false,
    properNouns: false,
    sentenceContent: false,
    similarity: 0,
    summary: 'Not searched yet. ',
    tense: false,
    verbs: false,
    suitableForWritingExercise: false,
    long_words: [],
    hard_words: [],
    difficulty: 0,
    comment: '',
    isCorrect: false,
    mistakes: [],
}

export function randd(state = initialState, action) {
    switch (action.type) {
        case randdConstants.SENTENCE_SIMILARITY.REQUEST:
            return {
                ...state,
            }
        case randdConstants.SENTENCE_SIMILARITY.SUCCESS:
            const randdResults = action.data
            return {
                ...randdResults,
            }
        case randdConstants.SENTENCE_SIMILARITY.FAILURE:
            return {
                ...state,
            }
        case randdConstants.SENTENCE_SUITABLE.REQUEST:
            return {
                ...state,
            }
        case randdConstants.SENTENCE_SUITABLE.SUCCESS:
            const randdSimilarResults = action.data
            return {
                ...state,
                ...randdSimilarResults,
            }
        case randdConstants.SENTENCE_SUITABLE.FAILURE:
            return {
                ...state,
            }
        case randdConstants.GENERAL_RESET.REQUEST:
            return {
                ...state,
                suitableForWritingExercise: false,
                long_words: [],
                hard_words: [],
                difficulty: 0,
                comment: '',
            }
        case randdConstants.GENERAL_RESET.SUCCESS:
            return {
                ...state,
            }
        case randdConstants.GENERAL_RESET.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        default:
            return state
    }
}
