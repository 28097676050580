import { asyncRequest } from './asyncRequest'

export const organizationConstants = {
    GET_ORGANIZATION_INFO: new asyncRequest('GET_ORGANIZATION_INFO'),
    SET_ORGANIZATION_INFO: new asyncRequest('SET_ORGANIZATION_INFO'),
    GET_MY_ORGANIZATIONS: new asyncRequest('GET_MY_ORGANIZATONS'),
    SET_CURRENT_ORGANIZATION: new asyncRequest('SET_CURRENT_ORGANIZATION'),
    GET_SCHOOL: new asyncRequest('ORGANIZATION_GET_SCHOOL'),
    CREATE_SCHOOL: new asyncRequest('ORGANIZATION_CREATE_SCHOOL'),
    INVITE_STUDENTS_SCHOOL: new asyncRequest('ORGANIZATION_INVITE_STUDENTS_SCHOOL'),

    GET_ORGANIZATION_USERS: new asyncRequest('GET_ORGANIZATION_USERS'),
    GET_INVITE_ROLES_AVAILABLE: new asyncRequest('GET_INVITE_ROLES_AVAILABLE'),

    CREATE_USERGROUP: new asyncRequest('CREATE_USERGROUP'),
    DELETE_USERGROUP: new asyncRequest('DELETE_USERGROUP'),
    GET_ALL_USERGROUPS: new asyncRequest('GET_ALL_USERGROUPS'),
    GET_USERGROUP_USERS: new asyncRequest('GET_USERGROUP_USERS'),
    GET_USERGROUP_TOPICS: new asyncRequest('GET_USERGROUP_TOPICS'),
    ADD_USERGROUP_USER: new asyncRequest('ADD_USERGROUP_USER'),
    ADD_USERGROUP_TOPIC: new asyncRequest('ADD_USERGROUP_TOPIC'),
    DELETE_USERGROUP_TOPIC: new asyncRequest('DELETE_USERGROUP_TOPIC'),
    DELETE_ORGANIZATION_USER: new asyncRequest('DELETE_ORGANIZATION_USER'),

    EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS: new asyncRequest('EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS'),
    EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS: new asyncRequest('EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS'),

    GET_ORGANIZATION_ASSETS: new asyncRequest('GET_ORGANIZATION_ASSETS'),
    UPDATE_ORGANIZATION_ASSET: new asyncRequest('UPDATE_ORGANIZATION_ASSET'),
    DELETE_ORGANIZATION_ASSET: new asyncRequest('DELETE_ORGANIZATION_ASSET'),

    GET_AVAILABLE_COURSE_GROUPS: new asyncRequest('AVAILABLE_COURSE_GROUPS'),

    GET_GRAPH_DATA_HOURLY_ORG: new asyncRequest('GET_GRAPH_DATA_HOURLY_ORG'),
    GET_GRAPH_DATA_HOURLY_USER_GROUP: new asyncRequest('GET_GRAPH_DATA_HOURLY_USER_GROUP'),
    GET_GRAPH_DATA_DAY_OF_WEEK_ORG: new asyncRequest('GET_GRAPH_DATA_DAY_OF_WEEK_ORG'),
    GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP: new asyncRequest('GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP'),

    GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG: new asyncRequest('GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG'),
    GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP: new asyncRequest(
        'GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP'
    ),

    GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG: new asyncRequest('GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG'),
    GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP: new asyncRequest(
        'GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP'
    ),

    GET_ORGANIZATION_FACT_BOX_STATS: new asyncRequest('GET_ORGANIZATION_FACT_BOX_STATS'),
    GET_ORGANIZATION_COURSE_GROUP_USAGE: new asyncRequest('GET_ORGANIZATION_COURSE_GROUP_USAGE'),

    GET_ORGANIZATION_TIME_ZONE_LOCATIONS: new asyncRequest('GET_ORGANIZATION_TIME_ZONE_LOCATIONS'),
    SET_ORGANIZATION_TIME_ZONE_LOCATION: new asyncRequest('SET_ORGANIZATION_TIME_ZONE_LOCATION'),

    GET_ORGANIZATION_COURSE_GROUPS: new asyncRequest('GET_ORGANIZATION_COURSE_GROUPS'),
    ADD_COURSE_GROUP_TO_ORGANIZATION: new asyncRequest('ADD_COURSE_GROUP_TO_ORGANIZATION'),
    REMOVE_COURSE_GROUP_FROM_ORGANIZATION: new asyncRequest('REMOVE_COURSE_GROUP_FROM_ORGANIZATION'),

    UPDATE_ORGANIZATION_INFO: new asyncRequest('UPDATE_ORGANIZATION_INFO'),

    SKILLS_COURSES_FOR_ORG: new asyncRequest('SKILLS_COURSES_FOR_ORG'),
}

export const organizationActions = {
    getOrganizationInfo(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_INFO.REQUEST,
            payload: {
                organizationId: organizationId,
            },
        }
    },

    getOrganizationAssets(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_ASSETS.REQUEST,
            payload: {
                organizationId: organizationId,
            },
        }
    },

    updateOrganizationAsset(organizationId, fileReferenceId, assetType) {
        return {
            type: organizationConstants.UPDATE_ORGANIZATION_ASSET.REQUEST,
            payload: {
                organizationId: organizationId,
                fileReferenceId: fileReferenceId,
                organizationFileAssetTypes: [assetType],
            },
        }
    },

    updateOrgnaizationInfo(organizationId, updateOrganizationInformationDto) {
        return {
            type: organizationConstants.UPDATE_ORGANIZATION_INFO.REQUEST,
            payload: {
                organizationId: organizationId,
                updateOrganizationInformationDto: updateOrganizationInformationDto,
            },
        }
    },

    deleteOrganizationAsset(organizationId, organizationFileAssetId, assetType) {
        return {
            type: organizationConstants.DELETE_ORGANIZATION_ASSET.REQUEST,
            payload: {
                organizationId: organizationId,
                organizationFileAssetId: organizationFileAssetId,
                organizationFileAssetType: assetType,
            },
        }
    },

    setOrganizationInfo(
        organizationId,
        name,
        country,
        region,
        welcomeMessage,
        primaryColor,
        secondaryColor,
        numberOfLicenses,
        userStatisticsVisibility,
        organizationType
    ) {
        return {
            type: organizationConstants.SET_ORGANIZATION_INFO.REQUEST,
            payload: {
                id: organizationId,
                name: name,
                country: country,
                region: region,
                welcomeMessageToUsers: welcomeMessage,
                themePrimaryColor: primaryColor,
                themeSecondaryColor: secondaryColor,
                numberOfLicenses: numberOfLicenses,
                userStatisticsVisibility: userStatisticsVisibility,
                organizationType: organizationType,
            },
        }
    },

    setCurrentOrganization(organizationId, goToDashboardAfterChange) {
        return {
            type: organizationConstants.SET_CURRENT_ORGANIZATION.REQUEST,
            payload: {
                organizationId: organizationId,
                goToDashboardAfterChange: goToDashboardAfterChange,
            },
        }
    },

    getOrganizationUsers(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_USERS.REQUEST,
            payload: {
                organizationId: organizationId,
            },
        }
    },

    getMyOrganizations() {
        return {
            type: organizationConstants.GET_MY_ORGANIZATIONS.REQUEST,
        }
    },

    createSchool(name, organizationType, welcomeMessageToUsers, numberOfLicenses) {
        return {
            type: organizationConstants.CREATE_SCHOOL.REQUEST,
            payload: {
                name: name,
                organizationType: organizationType,
                welcomeMessageToUsers: welcomeMessageToUsers,
                numberOfLicenses: numberOfLicenses,
            },
        }
    },

    getSchool(organizationId) {
        return {
            type: organizationConstants.GET_SCHOOL.REQUEST,
            payload: {
                organizationId: organizationId,
            },
        }
    },

    inviteStudentsToSchool(organizationId, emails, inviteRoles, userGroupId) {
        return {
            type: organizationConstants.INVITE_STUDENTS_SCHOOL.REQUEST,
            payload: {
                organizationId: organizationId,
                userEmails: emails,
                schoolRoles: inviteRoles,
                userGroupId: userGroupId,
            },
        }
    },

    getInviteRolesAvailable(organizationId) {
        return {
            type: organizationConstants.GET_INVITE_ROLES_AVAILABLE.REQUEST,
            payload: {
                organizationId: organizationId,
            },
        }
    },
    deleteOrganizationUser(organizationId, userId, userGroupId) {
        return {
            type: organizationConstants.DELETE_ORGANIZATION_USER.REQUEST,
            payload: {
                organizationId: organizationId,
                userId: userId,
                userGroupId: userGroupId,
            },
        }
    },

    exportExcelFromTemplateOrganizationStats(organizationId, filename) {
        return {
            type: organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_ORG_STATS.REQUEST,
            payload: {
                organizationId,
                filename,
            },
        }
    },

    exportExcelFromTemplateClassroomStats(organizationId, classroomId, filename) {
        return {
            type: organizationConstants.EXPORT_EXCEL_FROM_TEMPLATE_CLASSROOM_STATS.REQUEST,
            payload: {
                organizationId,
                classroomId,
                filename,
            },
        }
    },

    getAvailableCourseGroups(organizationId) {
        return {
            type: organizationConstants.GET_AVAILABLE_COURSE_GROUPS.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    getGraphDataHourlyOrganization(organizationId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_HOURLY_ORG.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    getGraphDataHourlyUserGroupId(organizationId, userGroupId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_HOURLY_USER_GROUP.REQUEST,
            payload: {
                organizationId,
                userGroupId,
            },
        }
    },

    getGraphDataDayOfWeekOrganization(organizationId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_ORG.REQUEST,
            payload: {
                organizationId,
            },
        }
    },
    getGraphDataDayOfWeekUserGroup(organizationId, userGroupId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_DAY_OF_WEEK_USER_GROUP.REQUEST,
            payload: {
                organizationId,
                userGroupId,
            },
        }
    },

    getGraphDataDailyUsageAggregatedOrganization(organizationId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_ORG.REQUEST,
            payload: {
                organizationId,
            },
        }
    },
    getGraphDataDailyUsageAggregatedUserGroup(organizationId, userGroupId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_DAILY_USAGE_AGGREGATED_USER_GROUP.REQUEST,
            payload: {
                organizationId,
                userGroupId,
            },
        }
    },

    getGraphDataDailyLessonAggregatedOrganization(organizationId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_ORG.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    getGraphDataDailyLessonAggregatedUserGroup(organizationId, userGroupId) {
        return {
            type: organizationConstants.GET_GRAPH_DATA_DAILY_LESSON_AGGREGATED_USER_GROUP.REQUEST,
            payload: {
                organizationId,
                userGroupId,
            },
        }
    },

    getOrganizationTimeZoneLocations(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_TIME_ZONE_LOCATIONS.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    setOrganizationTimeZoneLocation(organizationId, timeZoneLocation) {
        return {
            type: organizationConstants.SET_ORGANIZATION_TIME_ZONE_LOCATION.REQUEST,
            payload: {
                organizationId,
                timeZoneLocation,
            },
        }
    },

    getOrganizationCourseGroups(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_COURSE_GROUPS.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    addCourseGroupToOrganization(organizationId, courseGroupId) {
        return {
            type: organizationConstants.ADD_COURSE_GROUP_TO_ORGANIZATION.REQUEST,
            payload: {
                organizationId,
                courseGroupId,
            },
        }
    },

    removeCourseGroupFromOrganization(organizationId, courseGroupId) {
        return {
            type: organizationConstants.REMOVE_COURSE_GROUP_FROM_ORGANIZATION.REQUEST,
            payload: {
                organizationId,
                courseGroupId,
            },
        }
    },

    getOrganizationFactBoxStats(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_FACT_BOX_STATS.REQUEST,
            payload: {
                organizationId,
            },
        }
    },

    getOrganizationCourseGroupUsage(organizationId) {
        return {
            type: organizationConstants.GET_ORGANIZATION_COURSE_GROUP_USAGE.REQUEST,
            payload: {
                organizationId,
            },
        }
    },
    getOrganizationSkillsCourses(organizationId) {
        return {
            type: organizationConstants.SKILLS_COURSES_FOR_ORG.REQUEST,
            payload: {
                organizationId,
            },
        }
    },
}
