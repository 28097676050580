import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { organizationActions } from '../../../_state/actions'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import OrganizationCard from './Components/OrganizationCard'
import { history } from '_state/helpers'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import { Add } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import { CapeeshOrganizationType } from '../../../Logic/CapeeshConstants'
import { CapeeshColors } from '../../../assets/ColorPalette'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import CreateOrganization from './CreateOrganization'

const useStyles = makeStyles((theme) => ({
    newCourseButton: {
        color: '#8080802e',
        margin: 'auto auto',
        display: 'inline-grid',
        padding: '25px',
        animation: 'all 200ms linear',
        border: 'dashed 2px #8080802e',
        '&:hover': {
            border: 'dashed 2px',
            color: theme.palette.primary.main,
        },
    },
    newCourseIcon: {
        height: '50px',
        width: '50px',
        margin: 'auto auto',
    },
}))

const OrganizationsOverview = (props) => {
    const classes = useStyles()

    const [searchToken, setSearchToken] = useState('')
    const [filteredOrganizations, setFilteredOrganizations] = useState([])

    const [myOrganizations, setMyOrganizations] = useState([])

    const currentOrganization = props.organization.myOrganizations.find(
        (x) => x.id === props.organization.selectedOrganization
    )

    const [canCreateOrgainzation, setCanCreateOrgainzation] = useState(false)
    const [showCreateOrganizationPane, setShowCreateOrganizationPane] = useState(false)

    const [filterOrganizationType, setFilterOrganizationType] = useState(null)

    useEffect(() => {
        props.dispatch(organizationActions.getMyOrganizations())

        if (props.user.organizationRole === 'admin') {
            setCanCreateOrgainzation(true)
        }
    }, [])

    useEffect(() => {
        setMyOrganizations(props.organization.myOrganizations)
        let sortedFilteredOrganizations = getFilteredOrganizations(
            searchToken,
            filterOrganizationType,
            props.organization.myOrganizations
        )
        setFilteredOrganizations(sortedFilteredOrganizations)
    }, [props.organization.myOrganizations])

    const filterSearch = (eventSearchToken) => {
        setSearchToken(eventSearchToken)
        let sortedFilteredOrganizations = getFilteredOrganizations(
            eventSearchToken,
            filterOrganizationType,
            myOrganizations
        )
        setFilteredOrganizations(sortedFilteredOrganizations)
    }

    const getFilteredOrganizations = (searchToken, filterOrgType, searchOrganizations) => {
        if (searchToken === '') {
            if (filterOrgType != null) {
                return searchOrganizations?.filter((organization) => {
                    return organization.organizationType === filterOrgType
                })
            }
        }

        let lowercaseSearchToken = searchToken.toLowerCase()
        let tempFilteredOrganizations = searchOrganizations?.filter((organization) => {
            return organization.name.toLowerCase().includes(lowercaseSearchToken)
        })
        if (filterOrgType != null) {
            tempFilteredOrganizations = tempFilteredOrganizations?.filter((organization) => {
                return organization.organizationType === filterOrgType
            })
        }
        return tempFilteredOrganizations.sort((a, b) => a.name.localeCompare(b.name, 'no'))
    }
    const handleCreate = () => {
        history.push({
            pathname: '/admin/create-organization',
        })
    }

    const handleChangeOrganizationTypeFilter = (organizationType) => {
        setFilterOrganizationType(organizationType)

        let lowercaseSearchToken = searchToken.toLowerCase()
        var tempFilteredOrganizatinos = myOrganizations?.filter((organization) => {
            return organization.name.toLowerCase().includes(lowercaseSearchToken)
        })
        if (organizationType != null) {
            tempFilteredOrganizatinos = tempFilteredOrganizatinos?.filter((organization) => {
                return organization.organizationType === organizationType
            })
        }

        let sortedFilteredOrganizations = tempFilteredOrganizatinos.sort((a, b) => a.name.localeCompare(b.name, 'no'))

        setFilteredOrganizations(sortedFilteredOrganizations)
    }
    const resetOrganizationTypeFilter = () => {
        setFilterOrganizationType(null)
        let lowercaseSearchToken = searchToken.toLowerCase()
        var tempFilteredOrganizatinos = myOrganizations?.filter((organization) => {
            return organization.name.toLowerCase().includes(lowercaseSearchToken)
        })

        let sortedFilteredOrganizations = tempFilteredOrganizatinos.sort((a, b) => a.name.localeCompare(b.name, 'no'))
        setFilteredOrganizations(sortedFilteredOrganizations)
    }

    const handleOrganizationOpenClicked = (organizationId) => {
        props.dispatch(organizationActions.setCurrentOrganization(organizationId, false))
        props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(organizationId))
        props.dispatch(organizationActions.getGraphDataHourlyOrganization(organizationId))
        props.dispatch(organizationActions.getAvailableCourseGroups(organizationId))
    }

    const handleOrganizationSwapToCap = (organizationId) => {
        props.dispatch(organizationActions.setCurrentOrganization(organizationId, false))
        props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(organizationId))
        props.dispatch(organizationActions.getGraphDataHourlyOrganization(organizationId))
        props.dispatch(organizationActions.getAvailableCourseGroups(organizationId))
        history.push('/creator/')
    }

    const handleOrganizationSwapToDashboard = (organizationId) => {
        props.dispatch(organizationActions.setCurrentOrganization(organizationId, false))
        props.dispatch(organizationActions.getGraphDataDayOfWeekOrganization(organizationId))
        props.dispatch(organizationActions.getGraphDataHourlyOrganization(organizationId))
        props.dispatch(organizationActions.getAvailableCourseGroups(organizationId))

        history.push('/')
    }

    const createOrganization = (tempName, tempOrganizationType, tempWelcomeMessage, tempNumberOfLicenses) => {
        props.dispatch(
            organizationActions.createSchool(tempName, tempOrganizationType, tempWelcomeMessage, tempNumberOfLicenses)
        )
    }

    const handleCloseCreateOrganizationPane = () => {
        setShowCreateOrganizationPane(false)
    }

    return (
        <div style={{ marginLeft: '25px', width: '95%' }}>
            {showCreateOrganizationPane && (
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={showCreateOrganizationPane}
                    onClose={handleCloseCreateOrganizationPane}>
                    <DialogContent>
                        <CreateOrganization onCreateOrganization={createOrganization} />
                    </DialogContent>
                </Dialog>
            )}

            <Grid container>
                <Grid items xs={12}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h4" style={{ marginTop: '15px' }}>
                                Currently viewing {currentOrganization?.name}
                            </Typography>
                        </Grid>

                        {canCreateOrgainzation && (
                            <Grid item xs={4}>
                                <Button color="primary" onClick={() => setShowCreateOrganizationPane(true)}>
                                    <Add className={classes.newCourseIcon} />
                                    <FormattedMessage
                                        id="admin.edit.org.orgview.createneworg"
                                        defaultMessage="Create new organization"
                                    />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {props.user.organizationRole === 'admin' && props.user.info?.showOverallFunctionality && (
                    <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Button
                            onClick={() => resetOrganizationTypeFilter()}
                            style={{
                                height: '40px',
                                background: filterOrganizationType === null ? CapeeshColors.Blue : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            All
                        </Button>

                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.Customer)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.Customer
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Customers
                        </Button>

                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.Trial)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.Trial
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Trial
                        </Button>
                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.Demo)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.Demo
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Demo
                        </Button>
                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.Churned)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.Churned
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Churned
                        </Button>
                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.CapeeshInternal)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.CapeeshInternal
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Capeesh Internal
                        </Button>
                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.Partner)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.Partner
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Partner
                        </Button>
                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.ClosedLost)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.ClosedLost
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Closed Lost
                        </Button>
                        <Button
                            onClick={() => handleChangeOrganizationTypeFilter(CapeeshOrganizationType.Normal)}
                            style={{
                                height: '40px',
                                marginLeft: '20px',
                                background:
                                    filterOrganizationType === CapeeshOrganizationType.Normal
                                        ? CapeeshColors.Blue
                                        : CapeeshColors.Gray5,
                            }}
                            className={classes.button}
                            variant="contained"
                            size="medium">
                            Not tagged
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={6} style={{ marginTop: '15px', marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            label="Search for organizations by name"
                            fullWidth
                            value={searchToken}
                            onChange={(event) => filterSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                {filteredOrganizations.map((organizationDto, index) => (
                    <Grid item xs={12} key={index}>
                        <OrganizationCard
                            organizationDto={organizationDto}
                            slideDirection={'right'}
                            onOrganizationOpenClicked={handleOrganizationOpenClicked}
                            onOrganizationSwapToCap={handleOrganizationSwapToCap}
                            onOrganizationSwapToDashboard={handleOrganizationSwapToDashboard}
                            showOrganizationType={props.user.info?.showOverallFunctionality}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

OrganizationsOverview.propTypes = {}

const mapStateToProps = (state) => {
    const { organization, user } = state

    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsOverview)
