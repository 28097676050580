import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Checkbox,
    IconButton,
    Tooltip,
    Button,
    LinearProgress,
} from '@material-ui/core'
import { Delete, PanoramaFishEye, Restore, CheckCircleOutline } from '@material-ui/icons'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { lighten } from '@material-ui/core/styles/colorManipulator'

function getSorting(order, orderBy, thenOrderBy) {
    return order === 'desc'
        ? (a, b) =>
              b[orderBy] < a[orderBy] ? -1 : b[orderBy] == a[orderBy] && b[thenOrderBy] < a[thenOrderBy] ? -1 : 1
        : (a, b) =>
              a[orderBy] < b[orderBy] ? -1 : a[orderBy] == b[orderBy] && a[thenOrderBy] < b[thenOrderBy] ? -1 : 1
}

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property)
    }

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, columnData, showTableOptions } = this.props
        return (
            <TableHead>
                <TableRow>
                    {showTableOptions && (
                        <TableCell padding="checkbox" style={{ width: '50px' }}>
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                color="primary"
                            />
                        </TableCell>
                    )}
                    {columnData.map((column) => {
                        return (
                            <TableCell
                                key={column.id}
                                align={column.numeric ? 'right' : 'left'}
                                padding={column.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === column.id ? order : false}>
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}>
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        )
                    }, this)}
                </TableRow>
            </TableHead>
        )
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.primary.contrastText,
    },
    title: {
        flex: '0 0 auto',
    },
    fab: {
        margin: theme.spacing(1),
    },
})

let EnhancedTableToolbar = (props) => {
    const { numSelected, classes, title, onDeleteClick, buttonMessage, disableDelete } = props

    return (
        <div>
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}>
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {numSelected} selected
                        </Typography>
                    ) : (
                        <Typography variant="h5" id="tableTitle">
                            {title}
                        </Typography>
                    )}
                </div>
                <div className={classes.spacer} />
                {!disableDelete && numSelected > 0 && (
                    <div className={classes.actions}>
                        <Tooltip title="Delete">
                            <IconButton onClick={onDeleteClick} aria-label={buttonMessage}>
                                {buttonMessage === 'Delete' ? <Delete /> : <Restore />}
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </Toolbar>
        </div>
    )
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        // minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
})

class EnhancedTable extends React.Component {
    state = {
        page: 0,
        rowsPerPageOverride: null,
        selectedIds: [],
        selectedLessons: [],
    }

    handleRequestSort = (event, property) => {
        if (this.props.orderBy === property && this.state.order) {
            // has been toggled by user since component launch. Avoids having cumbersome calling methods
            this.state.order === 'asc' ? this.setState({ order: 'desc' }) : this.setState({ order: 'asc' })
        } else {
            if (this.props.orderBy === property) {
                if (this.props.order === 'desc') {
                    this.setState({ order: 'asc' })
                } else {
                    this.setState({ order: 'desc' })
                }
            }
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPageOverride: event.target.value })
    }

    handleSelectClick = (event, row) => {
        const { selectedIds } = this.state
        const { onSelectionUpdated, singleSelect } = this.props
        let updatedSelectedIds = selectedIds

        updatedSelectedIds = [row.id]
        this.setState({
            selectedIds: updatedSelectedIds,
        })

        if (onSelectionUpdated) onSelectionUpdated(updatedSelectedIds)
    }

    handleButtonClick = (row, column) => {
        const { onButtonClicked, singleSelect } = this.props
        onButtonClicked(row, column)
    }

    handleExportClick = (row, column) => {
        const { onExportClicked, singleSelect } = this.props
        onExportClicked(row, column)
    }

    handleCheckboxClick = (event, row) => {
        const { selectedIds, selectedLessons } = this.state
        const { onSelectionUpdated, singleSelect, onLessonSelectionUpdated } = this.props
        let updatedSelectedIds = selectedIds
        let localRow = row
        if (row.topicId) localRow.id = row.topicId
        event.stopPropagation()
        event.preventDefault()
        if (singleSelect) {
            updatedSelectedIds = [localRow.id]
        } else {
            const selectedIndex = selectedIds.indexOf(localRow.id)

            if (selectedIndex > -1) {
                updatedSelectedIds.splice(selectedIndex, 1)
            } else {
                updatedSelectedIds = [...selectedIds, localRow.id]
            }
        }

        if (row.customLessonRelationShipTypeName) {
            this.setState({
                selectedLessons: [...this.state.selectedLessons, localRow],
            })
        }

        this.setState({
            selectedIds: updatedSelectedIds,
        })

        if (onSelectionUpdated) onSelectionUpdated(updatedSelectedIds)
    }

    handleSelectAllClick = () => {
        const { selectedIds } = this.state
        const { onSelectionUpdated, onLessonSelectionUpdated, data, singleSelect } = this.props
        let isLesson = false

        if (singleSelect) return

        let updatedSelectedIds = []
        let parsedData = []

        data.forEach((element) => {
            let localRow = element
            if (element.customLessonTypeName) {
                localRow.id = element.topicId
                isLesson = true
            }
            parsedData.push(localRow)
        })

        if (selectedIds.length >= 0 && selectedIds.length < parsedData.length) {
            updatedSelectedIds = parsedData.map((x) => x.id)
        }

        if (selectedIds.length >= 0 && selectedIds.length < parsedData.length) {
            updatedSelectedIds = parsedData.map((x) => x.id)
        }

        this.setState({
            selectedIds: updatedSelectedIds,
        })
        if (isLesson) {
            onLessonSelectionUpdated(parsedData)
            this.setState({ selectedLessons: parsedData })
        } else if (onSelectionUpdated) onSelectionUpdated(updatedSelectedIds)
    }

    handleDeleteClick = () => {
        const { onDeleteClicked, onSelectionUpdated } = this.props
        if (this.state.selectedLessons.length > 0) {
            onDeleteClicked(this.state.selectedLessons)
        } else if (onDeleteClicked) onDeleteClicked(this.state.selectedIds)

        this.setState({
            selectedIds: [],
            selectedLessons: [],
        })

        if (onSelectionUpdated) onSelectionUpdated([])
    }

    renderCellValue = (row, column) => {
        if (column.isProgress) return <LinearProgress variant="determinate" value={row[column.id]} />

        if (column.isBool) {
            return <Checkbox checked={row[column.id] == 'true'} disabled />
        }
        if (column.isTranslation) {
            return (
                <Button variant="outlined" onClick={(event) => this.handleButtonClick(row, column)} color="primary">
                    {row[column.id]}
                </Button>
            )
        }
        if (column.isTranslatorButton) {
            return (
                <Button variant="outlined" onClick={(event) => this.handleButtonClick(row, column)} color="primary">
                    {column.buttonText}
                </Button>
            )
        }
        if (column.isTranslationButton) {
            return (
                <Button variant="outlined" onClick={(event) => this.handleExportClick(row, column)} color="primary">
                    {'Export'}
                </Button>
            )
        }
        if (column.isBadge) {
            return row[column.id] == 'True' || row[column.id] == true ? (
                <CheckCircleOutline style={{ color: 'green' }} />
            ) : (
                <PanoramaFishEye style={{ color: 'red' }} />
            )
        }
        if (column.isColorCoded) {
            return row[column.id] == 0 ? (
                <Typography variant="caption" style={{ color: 'red' }}>
                    {' '}
                    {row[column.id]}
                </Typography>
            ) : (
                <Typography variant="caption"> {row[column.id]}</Typography>
            )
        }
        if (column.isDefaultLanguage) {
            return row.isDefaultLanguage ? row[column.id] + ' (default)' : row[column.id]
        }
        if (column.translationStatus) {
            return row.translationStatus == 'Finished' ? (
                <CheckCircleOutline style={{ color: 'green' }} />
            ) : (
                <PanoramaFishEye style={{ color: 'red' }} />
            )
        }
        return '' + row[column.id]
    }

    isSelected = (id) => this.state.selectedIds.indexOf(id) > -1

    render() {
        const {
            classes,
            data,
            showBottomBar = true,
            columnData,
            order,
            orderBy,
            thenOrderBy,
            title,
            showTableOptions,
            disableDelete,
            buttonMessage = 'Delete',
            singleSelect = false,
            rowsPerPage = 25,
            tableTitle = '',
        } = this.props
        const { rowsPerPageOverride, page, selectedIds } = this.state

        const rowsPerPageFinal = rowsPerPageOverride ? rowsPerPageOverride : rowsPerPage
        // const rowsPerPageClamped = data.length < rowsPerPageFinal ? data.length : rowsPerPageFinal;

        const length = data ? data.length : 0
        // const emptyRows = rowsPerPageFinal - Math.min(rowsPerPageFinal, length - page * rowsPerPageFinal);
        const orderFinal = this.state.order || order

        return (
            <div>
                {showTableOptions && (
                    <EnhancedTableToolbar
                        numSelected={selectedIds.length}
                        onDeleteClick={this.handleDeleteClick}
                        disableDelete={disableDelete}
                        title={title}
                        buttonMessage={buttonMessage}
                    />
                )}
                <div className={classes.tableWrapper}>
                    <Typography variant="h6" id="tableTitle">
                        {tableTitle}
                    </Typography>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        {
                            <EnhancedTableHead
                                showTableOptions={showTableOptions}
                                numSelected={selectedIds.length}
                                order={orderFinal}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={length}
                                columnData={columnData}
                            />
                        }
                        <TableBody>
                            {data &&
                                data
                                    .sort(getSorting(orderFinal, orderBy, thenOrderBy))
                                    .slice(page * rowsPerPageFinal, page * rowsPerPageFinal + rowsPerPageFinal)
                                    .map((row, rowIndex) => {
                                        const isSelected = this.isSelected(row.id)
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => this.handleSelectClick(event, row)}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={row.id ? row.id : rowIndex}
                                                selected={isSelected}>
                                                {showTableOptions && (
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isSelected}
                                                            onClick={(event) => this.handleCheckboxClick(event, row)}
                                                        />
                                                    </TableCell>
                                                )}
                                                {columnData.map((column, index) => (
                                                    <TableCell
                                                        key={index}
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        style={{ width: '85px' }}>
                                                        {this.renderCellValue(row, column)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        )
                                    })}
                            {/*{*/}
                            {/*emptyRows > 0 &&*/}
                            {/*<TableRow style={{height: 49 * emptyRows}}>*/}
                            {/*<TableCell colSpan={6}/>*/}
                            {/*</TableRow>*/}
                            {/*}*/}
                        </TableBody>
                    </Table>
                </div>
                {showBottomBar && (
                    <TablePagination
                        component="div"
                        count={length}
                        rowsPerPage={rowsPerPageFinal}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                )}
            </div>
        )
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EnhancedTable)
