import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import {
    Area,
    AreaChart,
    BarChart,
    CartesianGrid,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import Card from '@material-ui/core/Card'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { CapeeshColors } from '../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'

const SesssionYLabel2 = (props) => {
    return (
        <g>
            <text x={0} y={0} width={100} height={100}>
                Label2
            </text>
        </g>
    )
}

const SesssionYLabel = (props) => {
    return (
        <g>
            <foreignObject x={0} y={0} width={100} height={100}>
                <div>Label</div>
            </foreignObject>
        </g>
    )
}

const DailySessionGraph = (props) => {
    const getNormalColor = () => {
        return CapeeshColors.Purple
    }
    const getBrightColor = () => {
        return CapeeshColors.PurpleBright
    }

    return (
        <Card style={{ overflow: 'visible' }}>
            <CardHeader
                title={props.title}
                subheader={props.canShowChart ? props.headerMessage : props.defaultHeaderMessage}
            />
            <CardContent>
                <Grid item xs={12}>
                    {props.canShowChart && (
                        <div style={{ width: '100%', height: 400 }}>
                            <ResponsiveContainer>
                                <AreaChart
                                    data={props.graphData}
                                    margin={{
                                        top: 50,
                                        right: 30,
                                        left: 30,
                                        bottom: 80,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="name"
                                        tickFormatter={(tick) => moment(tick).format('MMM Do')}
                                        angle={-60}
                                        dx={-20}
                                        dy={30}></XAxis>
                                    <YAxis
                                        dataKey={'value'}
                                        label={{ value: props.yLabel, angle: -90, position: 'left' }}
                                    />
                                    {props.showReferenceLine && (
                                        <ReferenceLine
                                            y={(
                                                props.graphData.reduce((a, b) => a + b['value'], 0) /
                                                props.graphData.length
                                            ).toFixed(2)}
                                            stroke="grey"
                                            label="Average"
                                        />
                                    )}

                                    <Tooltip
                                        content={({ active, payload, label }) => {
                                            if (active && payload) {
                                                return (
                                                    <Card>
                                                        <CardContent>
                                                            <Typography variant={'h6'}>
                                                                {`${moment(label).format('LL')}`}
                                                            </Typography>
                                                            {
                                                                <Typography variant={'body1'}>
                                                                    <FormattedMessage
                                                                        id="dashboard.classroom.graphs.dailysesssions.title"
                                                                        defaultMessage="Sessions">
                                                                        {(sessionsText) =>
                                                                            props.graphData.find(
                                                                                (x) => x.name === label
                                                                            ).value +
                                                                            ' ' +
                                                                            sessionsText
                                                                        }
                                                                    </FormattedMessage>
                                                                </Typography>
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                )
                                            }
                                            return null
                                        }}
                                    />
                                    {
                                        <Area
                                            type="monotone"
                                            dataKey="value"
                                            stackId="1"
                                            strokeWidth={2}
                                            stroke={getNormalColor()}
                                            fillOpacity={0.8}
                                            fill={getBrightColor()}
                                        />
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DailySessionGraph)
