import React from 'react'
import { Grid } from '@material-ui/core'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import GuideListenBasics from '../../../../assets/images/GuideListenBasics.png'

const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
        •
    </Box>
)

const useStyles = makeStyles((theme) => ({
    playIcon: {
        color: '#eee',
        fontSize: 36,
        marginLeft: '15px',
        //marginRight: '15px',
        cursor: 'pointer',
    },
    leftBox: {
        /* Gray 3 */
        background: '#EFEFEF',
        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
        borderRadius: '20px',
    },
    leftPanel: {
        minWidth: '400px',
    },
    mainContainer: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    rightPaper: {
        /* White */
        //  marginLeft: '20px',
        marginTop: '30px',
        background: '#FFFFFF',
        /* Gray 3 */

        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        borderRadius: '15px',
    },
}))

const TutorialDefinition = (props) => {
    const classes = useStyles()
    const tutorialParagraph = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        marginBottom: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialSubHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '10px',
                        marginTop: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '32px',
                        marginBottom: '20px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid
            container
            style={{
                paddingLeft: '50px',
                paddingTop: '40px',
                paddingRight: '20px',
            }}>
            {tutorialHeader('The Definition Stage')}
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        marginBottom: '35px',
                    }}>
                    <iframe
                        src="https://player.vimeo.com/video/677684714?h=c3156a80f2"
                        style={{ alignItems: 'center' }}
                        width="768"
                        height="432"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </Grid>
            {tutorialSubHeader('How it works')}
            {tutorialParagraph(
                'The Definition Stage is used for teaching words, phrases or simple concepts. It is a great stage to use for a first introduction of a new vocabulary item or important sentence.'
            )}
            {tutorialParagraph(
                'It contains four core areas: An image of what is being taught, the definition itself, the explanation of the definition, and finally a voice sample of the definition being spoken out.'
            )}
            {tutorialParagraph(
                'For the voice sample you can record your own voice, upload a sound file, or do nothing to have the text read by the automated voice.'
            )}

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '5px' }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '400px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideListenBasics}
                />
            </Grid>
            <div style={{ marginTop: '10px' }}>
                {tutorialHeader('Definition stage examples')}
                {tutorialSubHeader('A typical example')}
                {tutorialParagraph(
                    "A typical example is to teach a word or phrase. For example the Definition L2 would be 'Hello' and the L1 Explanation would be 'hallo'."
                )}
                {tutorialParagraph(
                    "Another example would be 'How are you?' as the Definition, with 'Hvordan går det?' as the L1 Explanation."
                )}
                {tutorialSubHeader('Explanations can also be in the L2')}
                {tutorialParagraph(
                    'For more advanced courses, it may be desirable to also have the explanation appear in the L2.'
                )}
                {tutorialParagraph(
                    'An example of this may be teaching the English L2 phrase. In this case, the Explanation text would typically be a description of what the word or phrase means, rather than a translation. '
                )}
                {tutorialParagraph(
                    "For instance, let's say you wanted to teach the English business phrase 'Hitting your sales rates'. In this case, the Explanation may also be in English, and read something like, 'Reaching your weekly sales targets within the company."
                )}
            </div>
        </Grid>
    )
}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(TutorialDefinition)
