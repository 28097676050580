import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import CourseViewCard from '../CourseView/CourseViewCard'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { organizationActions } from '../../_state/actions'
import { makeGetRequest } from '../../_state/helpers/api'
import { configWithAuth } from '../../_state/helpers'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CourseViewTopicList from '../CourseView/CourseViewTopicList'
import CourseDialogWithLessonPreview from '../CustomDialog/CourseDialogWithLessonPreview'
import RequestAccessSkillsForm from '../../Views/Private/Creator/Forms/RequestAccessSkillsForm'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 273px)',
        alignContent: 'space-evenly',
        justifyItems: 'center',
        paddingTop: '20px',
    },
}))

const SkillsLibrary = (props) => {
    // expected props
    // props.dispatch
    // props.closeModal
    // props.canOpenRequestAccess
    // props.filterOutCourseGroupIds (optional)
    const classes = useStyles()

    const [courseGroupsToDisplay, setCourseGroupsToDisplay] = useState([])

    const [openCourseGroup, setOpenCourseGroup] = useState(null)

    const [openCourseGroupModal, setOpenCourseGroupModal] = useState(false)
    const [openRequestAccessModal, setOpenRequestAccessModal] = useState(false)

    useEffect(() => {
        if (props.fetchSkills) {
            props.dispatch(organizationActions.getOrganizationSkillsCourses(props.organization.selectedOrganization))
        }
    }, [])

    useEffect(() => {
        if (props.organization.skillsCourses?.length > 0) {
            const tempCourseGroups = []
            props.organization.skillsCourses.forEach((courseGroup) => {
                if (!props.filterOutCourseGroupIds) {
                    tempCourseGroups.push(courseGroup)
                } else if (!props.filterOutCourseGroupIds.includes(courseGroup.id)) {
                    tempCourseGroups.push(courseGroup)
                }
            })
            setCourseGroupsToDisplay(tempCourseGroups)
        }
    }, [props.organization])

    const handleCourseGroupOpen = (courseGroupId) => {
        // fetch course group dto and then display

        if (openCourseGroup?.id === courseGroupId) {
            setOpenCourseGroupModal(true)
            return
        }
        makeGetRequest(`admin/course/group/${courseGroupId}`, configWithAuth())
            .then((result) => {
                setOpenCourseGroup(result.data)
            })
            .catch((error) => {
                console.log(error)
            })
        setOpenCourseGroupModal(true)
    }

    const handleCloseCourseGroupModal = () => {
        setOpenCourseGroupModal(false)

        if (props.closeModal !== undefined) {
            props.closeModal()
        }
    }

    const handleOpenRequestAccess = (courseGroupId) => {
        if (openCourseGroup?.id === courseGroupId) {
            setOpenRequestAccessModal(true)
            return
        }

        makeGetRequest(`admin/course/group/${courseGroupId}`, configWithAuth())
            .then((result) => {
                setOpenCourseGroup(result.data)
                setOpenRequestAccessModal(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Grid container>
            {openCourseGroupModal && (
                <CourseDialogWithLessonPreview
                    isOpen={openCourseGroupModal}
                    onClose={handleCloseCourseGroupModal}
                    title={openCourseGroup?.name}
                    dispatch={props.dispatch}
                    openCourseGroup={openCourseGroup}></CourseDialogWithLessonPreview>
            )}
            {openRequestAccessModal && (
                <RequestAccessSkillsForm
                    shouldOpen={openRequestAccessModal}
                    onClose={() => setOpenRequestAccessModal(false)}
                    maxWidth={'800px'}
                    minWidth={'800px'}
                    courseName={openCourseGroup?.name}></RequestAccessSkillsForm>
            )}

            <Grid item xs={12}>
                {props.showTitle && (
                    <Typography variant={'h4'}>
                        <FormattedMessage
                            id="dashboard.courses.your.courses.skills.available.courses.description"
                            defaultMessage="Available courses from Capeesh"
                        />
                    </Typography>
                )}
                <Grid container className={classes.cardContainer}>
                    {courseGroupsToDisplay
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((courseGroup, index) => (
                            <CourseViewCard
                                key={courseGroup.id}
                                courseGroup={courseGroup}
                                index={index}
                                openCourseGroup={handleCourseGroupOpen}
                                showProgression={false}
                                courseGroupProgression={null}
                                handleRequestAccess={props.canOpenRequestAccess ? handleOpenRequestAccess : null}
                            />
                        ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const { organization } = state
    return {
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillsLibrary)
