import { authConstants } from '../actions'

let authToken = JSON.parse(localStorage.getItem('authToken'))
const initialState = authToken
    ? { loggedIn: true, authToken, sendingPin: false, sendingEmail: false, resetStep: 0, teacherDebug: 0 }
    : { loggingIn: false, registering: false }

export function auth(state = initialState, action) {
    switch (action.type) {
        case authConstants.TEACHER_DEBUG.REQUEST:
            let teacherDebug = state.teacherDebug
            if (isNaN(state.teacherDebug)) teacherDebug = 1
            const newDebug = (teacherDebug + 1) % 3
            return {
                ...state,
                teacherDebug: newDebug,
            }
        case authConstants.TEACHER_DEBUG.SUCCESS:
            return {
                ...state,
            }
        case authConstants.TEACHER_DEBUG.FAILURE:
            return {
                message: 'Could not update teacher debug mode.',
            }
        case authConstants.LOGIN.REQUEST:
            return {
                loggingIn: true,
            }
        case authConstants.LOGIN.SUCCESS:
            return {
                loggedIn: true,
                loggingIn: false,
                teacherDebug: 0,
                authToken: action.data,
            }
        case authConstants.LOGIN.FAILURE:
            return {
                loggingIn: false,
                message: 'The email or password was incorrect. Please try again.',
            }
        case authConstants.REGISTER.REQUEST:
            return {
                ...state,
                registering: true,
            }
        case authConstants.REGISTER.SUCCESS:
            return {
                ...state,
                registering: false,
                loggedIn: true,
                authToken: action.data,
                teacherDebug: 0,
            }
        case authConstants.REGISTER.FAILURE:
            return {
                ...state,
                registering: false,
                error: action.message,
            }
        case authConstants.FORGOT_PASSWORD_PIN.REQUEST:
            return {
                ...state,
                sendingEmail: true,
                sendingPin: false,
                resetStep: 0,
                error: '',
                errorMessage: '',
                //sendingEmail: true
            }
        case authConstants.FORGOT_PASSWORD_PIN.SUCCESS:
            return {
                ...state,
                sentPin: false,
                sendingEmail: false,
                sendingPin: false,
                resetStep: 1,
                error: '',
                //sendingEmail: false
            }
        case authConstants.FORGOT_PASSWORD_PIN.FAILURE:
            return {
                ...state,
                sendingEmail: false,
                notifyError: true,
                resetStep: 0,
                //sendingEmail: false,
                error: action.message,
                errorMessage: 'We could not find that email address. Please check and try again.',
            }
        case authConstants.UPDATE_FORGOT_PASSWORD.REQUEST:
            return {
                ...state,
                errorMessage: '',
            }
        case authConstants.UPDATE_FORGOT_PASSWORD.SUCCESS:
            let resetStep = 1
            if (action.data.success) {
                return {
                    ...state,
                    resetStep: 2,
                }
            }
            return {
                ...state,
                errorMessage: 'The PIN code does not seem to be correct. Please check and try again.',
                notifyError: true,
            }
        case authConstants.UPDATE_FORGOT_PASSWORD.FAILURE:
            return {
                ...state,
                errorMessage: 'We could not update the password. Please ensure the password has at least 8 characters.',
                notifyError: true,
            }
        case authConstants.START_OVER.REQUEST:
            return {
                ...state,
                resetStep: 0,
            }
        case authConstants.CLEAR_ERRORS.REQUEST:
            return {
                ...state,
                notifyError: false,
            }
        case authConstants.LOGOUT.SUCCESS:
            return initialState
        default:
            return state
    }
}
