import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import UnityLessonPreview from '../Views/Private/Scenarios/UnityLessonPreview/UnityLessonPreview'
import LessonPreview from './LessonPreview'
import { GlobalEvents } from '../assets/GlobalEvents'

const useStyles = makeStyles((theme) => ({}))

const BasicLessonPreviewDialog = (props) => {
    // props.showLessonPreview
    // props.l1Id
    // props.l2Id
    // props.customLessonIdToPreview
    // props.nodeId

    const classes = useStyles()

    document.addEventListener('unityLessonPreviewOnLoad', () => {
        document.dispatchEvent(
            new CustomEvent('changeUnityState', {
                detail: {
                    customLessonId: props.customLessonId,
                    l1Id: props.l1Id,
                    nodeId: props.nodeId,
                    clickableSkipButton: props.clickableSkipButton,
                },
            })
        )
    })

    return (
        <Fragment>
            {props.showLessonPreview && (
                <Dialog
                    maxWidth={'400px'}
                    open={props.showLessonPreview}
                    onClose={async () => {
                        document.dispatchEvent(GlobalEvents.UnityLessonPreviewClose)
                    }}>
                    <DialogTitle
                        style={{
                            backgroundColor: '#143349',
                            color: 'white',
                            height: '10px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <div className={classes.dialogTitleDiv}>
                            <IconButton
                                onClick={async () => {
                                    document.dispatchEvent(GlobalEvents.UnityLessonPreviewClose)
                                }}
                                size="small">
                                <CancelOutlinedIcon style={{ fill: 'white', width: 18, height: 18 }} size="medium" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <LessonPreview />
                </Dialog>
            )}
        </Fragment>
    )
}

BasicLessonPreviewDialog.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicLessonPreviewDialog)
