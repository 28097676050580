import { call, put, takeEvery, takeLatest, fork } from 'redux-saga/effects'
import {
    authConstants,
    userConstants,
    organizationConstants,
    lessonConstants,
    topicConstants,
    snackbarActions,
} from '../actions'
import { authService, userService, organizationService, topicService, lessonService } from '../services'
import { history } from '../helpers'

export function* loginUser(action) {
    try {
        yield put(snackbarActions.clearSnackbar())
        const authResponse = yield call(authService.login, action.payload)
        localStorage.setItem('authToken', JSON.stringify(authResponse.data))
        const userResponse = yield call(userService.getUserInfo)
        yield put({ type: userConstants.GETUSERINFO.SUCCESS, data: userResponse.data })
        const myOrgsResponse = yield call(organizationService.getMyOrganizations)

        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS, data: myOrgsResponse.data })

        let organizationId = myOrgsResponse.data[0].id
        if (!organizationId) {
            yield put(
                snackbarActions.enqueueSnackbar({
                    message: 'User not connected to any organizations',
                    options: {
                        variant: 'error',
                    },
                })
            )
            yield put({ type: authConstants.LOGIN.FAILURE, message: 'User not connected to any organizations' })
            return
        }

        yield put({ type: userConstants.GETUSERINFO.REQUEST, payload: organizationId })
        const myOrgUsers = yield call(organizationService.getOrganizationUsers, organizationId)
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: myOrgUsers.data.users })

        yield put({
            type: organizationConstants.GET_INVITE_ROLES_AVAILABLE.REQUEST,
            payload: { organizationId: organizationId },
        })
        if (
            userResponse.data.organizationRole === 'admin' ||
            userResponse.data.organizationRole === 'teacher' ||
            userResponse.data.organizationRole === 'qa'
        ) {
            const myOrgTopics = yield call(topicService.getAllTopics, organizationId)
            yield put({ type: topicConstants.GET_ALL_TOPICS.SUCCESS, data: myOrgTopics.data })
            const availableCourseGroups = yield call(organizationService.getAvailableCourseGroups, organizationId)
            yield put({
                type: organizationConstants.GET_AVAILABLE_COURSE_GROUPS.SUCCESS,
                data: availableCourseGroups.data,
            })
        }
        const myUserGroups = yield call(organizationService.getAllUserGroups, organizationId)
        yield put({ type: organizationConstants.GET_ALL_USERGROUPS.SUCCESS, data: myUserGroups.data })

        yield put({ type: authConstants.LOGIN.SUCCESS, data: authResponse.data })
        history.push('/')
    } catch (e) {
        yield put({ type: authConstants.LOGIN.FAILURE, message: e.message })
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'It was not possible to login. Please check your email and password and try again.',
                options: {
                    variant: 'warning',
                },
            })
        )
    }
}

export function* registerUser(action) {
    try {
        const authResponse = yield call(authService.register, action.payload)
        localStorage.setItem('authToken', JSON.stringify(authResponse.data))
        const userResponse = yield call(userService.getUserInfo)
        yield put({ type: userConstants.GETUSERINFO.SUCCESS, data: userResponse.data })
        const myOrgsResponse = yield call(organizationService.getMyOrganizations)
        yield put({ type: organizationConstants.GET_MY_ORGANIZATIONS.SUCCESS, data: myOrgsResponse.data })

        let organizationId = myOrgsResponse.data[0].id
        if (!organizationId) {
            yield put(
                snackbarActions.enqueueSnackbar({
                    message: 'User not connected to any organizations',
                    options: {
                        variant: 'error',
                    },
                })
            )
            yield put({ type: authConstants.LOGIN.FAILURE, message: 'User not connected to any organizations' })
            return
        }

        const myOrgUsers = yield call(organizationService.getOrganizationUsers, organizationId)
        yield put({ type: organizationConstants.GET_ORGANIZATION_USERS.SUCCESS, data: myOrgUsers.data.users })
        yield put({
            type: organizationConstants.GET_INVITE_ROLES_AVAILABLE.REQUEST,
            payload: { organizationId: organizationId },
        })
        if (
            userResponse.data.organizationRole === 'admin' ||
            userResponse.data.organizationRole === 'teacher' ||
            userResponse.data.organizationRole === 'qa'
        ) {
            const myOrgTopics = yield call(topicService.getAllTopics, myOrgsResponse.data[0].id)
            yield put({ type: topicConstants.GET_ALL_TOPICS.SUCCESS, data: myOrgTopics.data })
            const sharedCourses = yield call(topicService.getSharableCourses, null)
            yield put({ type: topicConstants.GET_SHARABLE_COURSES.SUCCESS, data: sharedCourses.data })
            const availableCourseGroups = yield call(organizationService.getAvailableCourseGroups, organizationId)
            yield put({
                type: organizationConstants.GET_AVAILABLE_COURSE_GROUPS.SUCCESS,
                data: availableCourseGroups.data,
            })
        }

        const myUserGroups = yield call(organizationService.getAllUserGroups, organizationId)
        yield put({ type: organizationConstants.GET_ALL_USERGROUPS.SUCCESS, data: myUserGroups.data })
        yield put({ type: organizationConstants.GET_ALL_USERGROUPS.SUCCESS, data: myUserGroups.data })

        yield put({ type: authConstants.REGISTER.SUCCESS, data: authResponse.data })
        history.push('/')
    } catch (e) {
        let usefulInformation = ''
        if (e.response.data != 'Server error') {
            usefulInformation = e.response.data
        }
        yield put(
            snackbarActions.enqueueSnackbar({
                message: 'We were unable to register the user. ' + usefulInformation,
                options: {
                    variant: 'warning',
                },
            })
        )
        yield put({ type: authConstants.REGISTER.FAILURE, message: e.message })
    }
}

export function* logoutUser() {
    try {
        localStorage.removeItem('authToken')
        yield put({ type: authConstants.LOGOUT.SUCCESS })
    } catch (e) {
        yield put({ type: authConstants.LOGOUT.FAILURE, message: e.message })
    }
}

export function* forgotPasswordPin(action) {
    try {
        const response = yield call(authService.forgotPasswordPin, action.payload)
        yield put({ type: authConstants.FORGOT_PASSWORD_PIN.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: authConstants.FORGOT_PASSWORD_PIN.FAILURE, message: e.message })
    }
}

export function* updateForgotPassword(action) {
    try {
        const response = yield call(authService.updateForgotPassword, action.payload)
        yield put({ type: authConstants.UPDATE_FORGOT_PASSWORD.SUCCESS, data: response.data })
    } catch (e) {
        yield put({ type: authConstants.FORGOT_PASSWORD_PIN.FAILURE, message: e.message })
    }
}

export function* teacherDebug(action) {
    try {
    } catch (e) {
        yield put({ type: authConstants.FORGOT_PASSWORD_PIN.FAILURE, message: e.message })
    }
}

//Listeners
export function* authSagas() {
    yield takeLatest(authConstants.LOGIN.REQUEST, loginUser)
    yield takeLatest(authConstants.REGISTER.REQUEST, registerUser)
    yield takeLatest(authConstants.LOGOUT.REQUEST, logoutUser)
    yield takeLatest(authConstants.FORGOT_PASSWORD_PIN.REQUEST, forgotPasswordPin)
    yield takeLatest(authConstants.UPDATE_FORGOT_PASSWORD.REQUEST, updateForgotPassword)
    yield takeLatest(authConstants.TEACHER_DEBUG.REQUEST, teacherDebug)
}
