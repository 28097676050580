import React, { memo } from 'react'
import { Handle } from 'react-flow-renderer'
import BriefNoText from '../../../../assets/images/698wBriefDebrief.png'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'

export default memo(({ data }) => {
    return (
        <>
            <div>
                <img
                    src={data.imageUrl}
                    style={{
                        position: 'absolute',
                        top: 18,
                        left: 5,
                        width: 90,
                        height: 'auto',
                    }}
                />

                <img
                    src={BriefNoText}
                    style={{
                        position: 'relative',
                        width: '100px',
                        height: 'auto',
                    }}
                />
                <Grid style={{ position: 'absolute', top: 75, left: 0 }} container>
                    {data?.options?.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body1"
                                style={{
                                    marginTop: '6px',
                                    width: '70px',
                                    boxShadow: 'none',
                                    fontWeight: '500',
                                    letterSpacing: 0.1,
                                    fontFamily: [
                                        'Tahoma',
                                        'Rubik',
                                        'Roboto',
                                        '-apple-system',
                                        'BlinkMacSystemFont',
                                        '"Segoe UI"',
                                        '"Helvetica Neue"',
                                        'Arial',
                                        'sans-serif',
                                        '"Apple Color Emoji"',
                                        '"Segoe UI Emoji"',
                                        '"Segoe UI Symbol"',
                                    ].join(','),
                                    flex: 1,
                                    fontSize: 4.5,
                                    color: 'white',
                                    justifyContent: 'center',
                                    paddingLeft: '16px',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                }}>
                                {data.options[0]}
                            </Typography>
                        </Grid>
                    )}
                    {data.options.length > 1 && (
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body2"
                                style={{
                                    marginTop: '3px',
                                    width: '71px',
                                    boxShadow: 'none',
                                    flex: 1,
                                    letterSpacing: 0.1,
                                    fontSize: 3.5,
                                    color: 'white',
                                    justifyContent: 'center',
                                    paddingLeft: '15px',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                }}>
                                {data.options[1]}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </div>
            <Handle type="source" position="right" id="exit0" style={{ top: '98.5px', background: '#555' }} />
        </>
    )
})
