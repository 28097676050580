import React, { useState, useRef } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import {
    AutoCourseCreatorLessonTeachingStage,
    AutoCourseCreatorPuzzlePlotTypes,
    AutoCourseCreatorRuleCardPlotTypes,
} from '../../../../../Logic/AutoCourseCreationConstants'
import ButtonBase from '@mui/material/ButtonBase'

const useStyles = makeStyles((theme) => ({
    stagePlotType: {
        display: 'inline-block',
        color: '#35323C',
        fontFamily: 'Nunito',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.41,
        borderRadius: 5,
        border: '1px solid var(--Gray-1, #8E8E93)',
        marginTop: '10px',
        padding: '5px',
    },
    stagePlotTypeSelected: {
        display: 'inline-block',
        color: 'white',
        fontFamily: 'Nunito',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.41,
        borderRadius: 5,
        border: '1px solid var(--Gray-1, #8E8E93)',
        marginTop: '10px',
        padding: '5px',
        background: '#44A6EF',
    },
}))

const ACCStageSettingsDialogue = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    const [focusedAttribute, setFocusedAttribute] = useState(null)
    const focusedAttributeRef = useRef()
    focusedAttributeRef.current = focusedAttribute
    const [focusedText, setFocusedText] = useState('')
    const focusedTextRef = useRef()
    focusedTextRef.current = focusedText
    const [openGenericDialog, setOpenGenericDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogMessage, setDialogMessage] = useState('')
    const [dialogExistingText, setDialogExistingText] = useState('')
    const [dialogType, setDialogType] = useState(null)
    const [openRoleDialog, setOpenRoleDialog] = useState(false)
    const [dalogueText, setDialogueText] = useState(false)

    const [newQuestion, setNewQuestion] = useState({
        questionId: '',
        conceptId: '',
        correctAnswer: '',
        incorrectAnswer1: '',
        incorrectAnswer2: '',
        incorrectAnswer3: '',
        question: '',
    })

    const classes = useStyles()

    let circleStyle = {
        padding: 10,
        margin: 20,
        //display:"inline",
        // backgroundColor: 'green',
        borderRadius: '50%',
        width: 30,
        height: 30,
    }

    const handleTextChange = (event) => {
        setDialogueText(event.target.value)
    }

    const handleSelectChangePlot = (element) => {
        console.log('change plot text', element.target.value)
        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, 'plotText', element.target.value)
    }

    const handleClickOpen = (roleType, title, message, existingText = '') => {
        setFocusedAttribute(roleType)
        setOpenRoleDialog(true)
        setDialogTitle(title)
        setDialogMessage(message)
        setDialogExistingText(existingText)
        //handleTextFocus(roleType, props.selectedLesson.lessonPlanConfigurationDto.dialogue., roleType)
    }

    const handleCloseModal = () => {
        setOpenRoleDialog(false)
        setDialogExistingText('')
    }

    const handleSaveModal = () => {
        setOpenRoleDialog(false)
        console.log('change plot text', dalogueText)

        props.onUpdateLessonSuggestionDto(props.selectedLesson.lessonPlanId, 'plotText', dalogueText)
    }

    console.log('props', props.selectedLesson)

    return (
        <React.Fragment>
            <Typography
                style={{
                    marginTop: '2px',
                    color: '#35323C',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '19px',
                    letterSpacing: '0.41px',
                    marginBottom: '10px',
                }}>
                Add pairs of puzzle blocks. The learner must line up the matching blocks to clear the puzzle.
            </Typography>
            <Typography
                style={{
                    marginTop: '2px',
                    color: '#35323C',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '19px',
                    letterSpacing: '0.41px',
                    marginBottom: '20px',
                }}>
                The puzzle can be used in many ways: Answering if a statement is true or false, putting steps in the
                correct sequence or matching up two halves of a sentence.
            </Typography>
            <Typography
                style={{
                    fontFamily: 'Rubik',
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'black',
                    letterSpacing: '0.31px',
                    textAlign: 'left',
                    marginTop: '20px',
                }}>
                Select which types of puzzles you'd like your lesson to contain.
            </Typography>
            <Grid container>
                {AutoCourseCreatorPuzzlePlotTypes.map((exercise, index) => (
                    <Grid item xs={12} style={{}}>
                        <ButtonBase
                            onClick={() =>
                                props.onToggleStagePlot(
                                    'Puzzle',
                                    exercise.plotType,
                                    props.selectedLesson.lessonPlanConfigurationDto.exercises.some(
                                        (ex) => ex.exerciseType === 'Puzzle' && ex.plotType === exercise.plotType
                                    ),
                                    true
                                )
                            }>
                            <Typography
                                className={
                                    props.selectedLesson.lessonPlanConfigurationDto.exercises.some(
                                        (ex) => ex.exerciseType === 'Puzzle' && ex.plotType === exercise.plotType
                                    )
                                        ? classes.stagePlotTypeSelected
                                        : classes.stagePlotType
                                }>
                                {exercise.friendlyText}
                            </Typography>
                        </ButtonBase>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}

ACCStageSettingsDialogue.propTypes = {}

const mapStateToProps = (state) => {
    return {
        languages: state.metadata.metadata.languages,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCStageSettingsDialogue)
