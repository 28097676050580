import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { TutorialSection } from '../../../../Logic/ScenarioConstants'
import GuidePassFailBranch from '../../../../assets/images/GuidePassFailBranch.png'
import GuideScoring from '../../../../assets/images/GuideScoring.png'
import GuideScoreReasoning from '../../../../assets/images/GuideScoreReasoning.png'

const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
        •
    </Box>
)

const useStyles = makeStyles((theme) => ({
    playIcon: {
        color: '#eee',
        fontSize: 36,
        marginLeft: '15px',
        //marginRight: '15px',
        cursor: 'pointer',
    },
    leftBox: {
        /* Gray 3 */
        background: '#EFEFEF',
        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
        borderRadius: '20px',
    },
    leftPanel: {
        minWidth: '400px',
    },
    mainContainer: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    rightPaper: {
        /* White */
        //  marginLeft: '20px',
        marginTop: '30px',
        background: '#FFFFFF',
        /* Gray 3 */

        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        borderRadius: '15px',
    },
}))

const TutorialSpeak = (props) => {
    const classes = useStyles()
    const [selectedSection, setSelectedSection] = useState(TutorialSection.Home)

    const tutorialParagraph = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        marginBottom: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialSubHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '10px',
                        marginTop: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '32px',
                        marginBottom: '20px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid
            container
            style={{
                paddingLeft: '50px',
                paddingTop: '40px',
                paddingRight: '20px',
            }}>
            {tutorialHeader('The Branching Stage')}
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        marginBottom: '35px',
                    }}>
                    <iframe
                        src="https://player.vimeo.com/video/687958781?h=f8dcc0ba59"
                        style={{ alignItems: 'center' }}
                        width="768"
                        height="432"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </Grid>
            {tutorialSubHeader('How it works')}
            {tutorialParagraph(
                "Together with the Listen stage, the Branching stage represents the role play's conversational feature. It allows the learner to engage in conversation within the app."
            )}
            {tutorialSubHeader('Choose your own path')}
            {tutorialParagraph(
                'In order to provide learner agency and to encourage the learner to really listen and take part in the conversation, the Branching stage can offer up to three different choices for the learner to speak. If you as a course author wish, each speaking option can take the story in a different direction.'
            )}
            {tutorialSubHeader('Hands-free immersion')}
            {tutorialParagraph(
                'To help create an immersive experience, the Branching stage is a hands-free experience. The learner does not need to tap a button to answer: they simply speak the answer that they wish to select.'
            )}
            {tutorialSubHeader('Tap to hear pronunciation')}
            {tutorialParagraph(
                'If the learner would like help with pronouncing a speak option, all they need to do is tap on the text to hearing it spoken out loud.'
            )}

            <div style={{ marginTop: '10px' }}>{tutorialHeader('Branching stage examples')}</div>
            {tutorialParagraph(
                'Scroll through the carousel below to look at different examples of how Branching stages.'
            )}

            <div style={{ marginTop: '10px' }}>{tutorialHeader('Branching lessons')}</div>
            {tutorialParagraph(
                "Each answer in a speaking stage can take the story in a different direction. This adds consequences to the learner's decisions and massively improves replayability. This simple mechanic can create hugely sophisticated role plays."
            )}
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '5px' }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '400px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuidePassFailBranch}
                />
            </Grid>
            {tutorialSubHeader('A simple example - Choose what drink to order in a coffee shop.')}
            {tutorialParagraph(
                "In this example, the learner is asked what drink they would like. They receive three different speaking answers to choose between: 'Can I have a coffee?', 'A cola, please', or 'Can I view the menu?'.  "
            )}
            {tutorialParagraph(
                'Depending on which answer the user speaks, the role play will continue accordingly. This is achieved by each Branching stage answer pointing to different subsequent stages. Watch the video at the top of this page for a demonstration of this.'
            )}

            {tutorialSubHeader('A more complex example: Where shall we visit on the train?')}
            {tutorialParagraph(
                'In this example, the learner begins the role play at a train station. A Cutscene stage shows the ticket booth. Next, a listen stage plays, asking the user where they would like a ticket to.'
            )}
            {tutorialParagraph(
                'The learn is given three speaking options: London, Liverpool and Edinburgh. The rest of the role play takes the learner to the destination they chose, where they learn about the culture of the region and speak to locals.'
            )}

            {tutorialSubHeader('Different answers leading to the same next stage')}
            {tutorialParagraph(
                "Different role play answers don't have to lead to different next stages. If the course author wishes, all answers can lead to the same stage. "
            )}
            {tutorialParagraph(
                "This gives the illusion of choice, even though the outcome is the same. It also gives the learner the opportunity of speaking out different but equivalent phrases. Such as, 'I am well, thanks' and 'I feel great, thanks'."
            )}

            {tutorialHeader('Scoring: Perfect, Pass and Fail')}
            {tutorialParagraph(
                'Role plays have a simple scoring mechanic. The user can achieve zero to three stars. Zero stars is classed as failing the role play. One to three stars are classed as passing, with three stars being a perfect performance. But how does the scoring work?'
            )}
            {tutorialSubHeader('Each speaking option can be defined as correct, suboptimal, or causing a failure')}
            {tutorialParagraph(
                'This determines how many stars the user receives at the end of the role play.  A failure guarantees that the user receives zero stars. A failure is typically used to teach workplace compliance failures rather than a language error.'
            )}
            {tutorialParagraph(
                'An example of using a failure: A waiter telling the customer they are too busy to speak to them is something a company is training their staff not to do. Therefore, this may be classed as a critical failure.'
            )}

            {tutorialSubHeader('How to control the scoring of each stage')}
            {tutorialParagraph(
                "For each speaking answer in a stage, it is possible to specify how choosing that answer influences the learner's score for the lesson as a whole. The scoring always defaults to good, so if you don't wish to alter scoring, you can ignore scoring and leave as the default setting."
            )}
            {tutorialParagraph(
                'In addition to a speaking answer being classified as good, it can also be classed as suboptimal or critical failure.'
            )}
            {tutorialParagraph(
                'To set a speaking answer to suboptimal, simply click the smiley drop-down and choose the sad face, as shown in the screenshot.'
            )}
            {tutorialParagraph(
                "To set as a critical failure, change the smiley to 'Fail', also as shown in the screenshot."
            )}

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '15px' }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '400px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideScoring}
                />
            </Grid>
            {tutorialSubHeader(
                'I set a speak answer as triggering a failure. I want to give the learner some feedback. How can I do this?'
            )}
            {tutorialParagraph(
                "In Capeesh, the default way for the learner to receive feedback is within the 'Debrief' stage that appears at the end of a role play."
            )}
            {tutorialParagraph(
                'Each Speak answer has the opportunity to trigger feedback text that appears in the Debrief stage.'
            )}
            {tutorialSubHeader(' To add feedback text for a speak answer:')}
            {tutorialParagraph('Click the ! icon by the speak answer that you want to give feedback on.')}
            {tutorialParagraph(
                'This makes the blue feedback text field appear as shown in the screenshot below. Type your feedback here.'
            )}
            {tutorialParagraph(
                'If the learner speaks that answer during a lesson, they will receive the text feedback at the end of the lesson in the Debrief stage.'
            )}

            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '15px' }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '400px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideScoreReasoning}
                />
            </Grid>
            {tutorialParagraph('')}
        </Grid>
    )
}

TutorialSpeak.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(TutorialSpeak)
