import React from 'react'
import { Typography } from '@material-ui/core'

const LimitedTypography = ({ text, limit, customStyle }) => {
    let limitedText = text

    if (text.length > limit) {
        limitedText = text.substring(0, limit) + '...'
    }

    return <Typography style={customStyle}>{limitedText}</Typography>
}

export default LimitedTypography
