import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserSearchResultCard from './UserSearchResultCard'
import Button from '@material-ui/core/Button'
import { overallActions } from '../../../_state/actions/overall.actions'

const useStyles = makeStyles((theme) => ({
    searchIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const maxNumberOfResult = 20
const offset = 0

const OverallSearch = (props) => {
    const { overall } = props
    const classes = useStyles()
    const [searchValue, setSearchValue] = useState([])

    useEffect(() => {
        const { dispatch } = props
        dispatch(overallActions.resetOverallSearch())
    }, [])

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
    }

    const handleSearchButtonClicked = (event) => {
        const { dispatch } = props
        dispatch(overallActions.getOverallSearchResult(searchValue, maxNumberOfResult, offset))
    }

    return (
        <div style={{ marginLeft: '25px' }}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={7}>
                    <TextField
                        id="search"
                        label="Search for users by id, name or email"
                        fullWidth
                        value={searchValue}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        className={classes.inviteButton}
                        onClick={handleSearchButtonClicked}
                        color={'primary'}>
                        Search
                    </Button>
                </Grid>

                <Grid container spacing={2}>
                    {overall.searching && (
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    )}
                    {!overall.searching &&
                        overall.searchResult?.map((user, index) => (
                            <Grid item xs={12} key={user.userId}>
                                <UserSearchResultCard user={user} index={index} />
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </div>
    )
}

OverallSearch.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        overall: state.overall,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OverallSearch)
