import { connect } from 'react-redux'
import React from 'react'
import InteractionInstruction from '../Interactions/InteractionInstruction'
import InteractionImage from '../Interactions/InteractionImage'

const InstructionStageEditNode = (props) => {
    return (
        <React.Fragment>
            <InteractionInstruction
                canEditLesson={props.canEditLesson}
                audioLibrary={props.audioLibrary}
                onAudioLibrary={props.onAudioLibrary}
                testOption={props.node.testOptions[2]}
                l1Id={props.l1Id}
                l2Id={props.l2Id}
                onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                onAutoTranslation={props.onAutoTranslation}
            />
            <InteractionImage
                canEditLesson={props.canEditLesson}
                node={props.node}
                lesson={props.lesson}
                updateImageDrop={props.updateImageDrop}
                scenarioImages={props.scenarioImages}
                testOption={props.node.testOptions[0]}
                onUpdateTestOptionOnCurrentNode={props.onUpdateTestOptionOnCurrentNode}
                onUpdatePreviewImage={props.onUpdatePreviewImage}></InteractionImage>
        </React.Fragment>
    )
}

InstructionStageEditNode.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(InstructionStageEditNode)
