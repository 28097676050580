import { autoCourseCreationConstants } from '../actions/autocoursecreation.actions'
import { authConstants } from '../actions'

import { v4 as uuidv4 } from 'uuid'
import {
    AutoCourseCreatorLanguageProcessStages,
    AutoCourseCreatorProcessStages,
} from '../../Logic/AutoCourseCreationConstants'
import { Cases } from '@mui/icons-material'
import { produce } from 'immer' // means we can deal with immutability of Reducer without such bloated coade when updating arrays

const initialState = {
    parseTextStatus: false,
    authorInputMetadata: null,
    suggestSectionsReturnDto: null,
    lessonPlansDtos: [],
    newlyCreateTopicId: null,

    isSuggestingTextSections: false,
    suggestingTextSectionsFailed: false,
    isSuggestingLessonPlans: [],

    isQuizGenerating: false,
    isDialogueGenerating: false,
    isScenarioGenerating: false,
    creationStage: 1,
    processingState: 1,
    processingStateLanguage: 1,

    isCreatingFromLessonPlanIds: [],
    isGeneratingQuizForLessonPlanIds: [],
    isGeneratingDialogForLessonPlanIds: [],
    isGeneratingScenarioForLessonPlanIds: [],
    isGeneratingNameAndDescriptionForLessonPlanIds: [],

    // language

    isSuggestingChapters: false,
    nextChapterOptions: [],
    chapterIdToChapterOptionsMapper: [],
    isSuggestingForChapterIds: [],
    selectedChapterOptions: [],

    // lesson plan chapter generation
    expandingChapterIds: [],
    lessonPlanChapterDtos: [],

    // lesson distribution

    isDistributingLessons: false,
    lessonDistributionFailed: false,
    languageSectionPlanDto: null,
    accLanguageSuggestingImages: false,
}

export function autoCourseCreation(state = initialState, action) {
    switch (action.type) {
        case autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.REQUEST:
            let tempIsCreatingFromLessonPlans = state.isCreatingFromLessonPlanIds
                ? state.isCreatingFromLessonPlanIds
                : []
            // already creating?

            if (action.payload.lesson.lessonPlanId) {
                if (tempIsCreatingFromLessonPlans.filter((x) => x == action.payload.lesson.lessonPlanId).length == 0) {
                    tempIsCreatingFromLessonPlans.push(action.payload.lesson.lessonPlanId)
                }
            }

            return {
                ...state,
                isCreatingFromLessonPlanIds: tempIsCreatingFromLessonPlans,
            }

        case autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.SUCCESS:
            state.lessonPlansDtos.forEach((lessonPlan) => {
                lessonPlan.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        if (lesson.createdLessonId) {
                            lesson.createdLessonId.push(action.data.customLessonId)
                        } else {
                            lesson.createdLessonId = [action.data.customLessonId]
                        }
                    }
                })
            })

            if (state.isCreatingFromLessonPlanIds?.length > 0 && action.data.lessonPlanId) {
                return {
                    ...state,
                    isCreatingFromLessonPlanIds: state.isCreatingFromLessonPlanIds.filter(
                        (x) => x !== action.data.lessonPlanId
                    ),
                }
            } else {
                return {
                    ...state,
                }
            }

        case autoCourseCreationConstants.ACC_CREATE_LESSON_WITH_SELECTED_STAGES.FAILURE:
            if (state.isCreatingFromLessonPlanIds?.length > 0 && action.data.lessonPlanId) {
                return {
                    ...state,
                    isCreatingFromLessonPlanIds: state.isCreatingFromLessonPlanIds.filter(
                        (x) => x !== action.data.lessonPlanId
                    ),
                }
            } else {
                return {
                    ...state,
                }
            }

        case autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.REQUEST:
            return {
                ...state,
                isSuggestingTextSections: true,
                suggestingTextSectionsFailed: false,
                creationStage: 1,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.SUCCESS:
            return {
                ...state,
                suggestSectionsReturnDto: action.data,
                isSuggestingTextSections: false,
                suggestingTextSectionsFailed: false,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_SECTIONS.FAILURE:
            return {
                ...state,
                isSuggestingTextSections: false,
                suggestingTextSectionsFailed: true,
            }

        case autoCourseCreationConstants.ACC_RESET_ALL_LESSON_PLANS.REQUEST:
            return {
                ...state,
                lessonPlansDtos: [],
            }

        case autoCourseCreationConstants.ACC_RESET_ALL_SECTIONS.REQUEST:
            return {
                ...state,
                suggestSectionsReturnDto: null,
            }

        case autoCourseCreationConstants.ACC_RESET_ALL.REQUEST:
            return {
                ...state,
                authorInputMetadata: null,
                isSuggestingLessonPlans: [],
                isGeneratingQuizForLessonPlanIds: [],
                isGeneratingDialogForLessonPlanIds: [],
                isGeneratingScenarioForLessonPlanIds: [],
                isGeneratingNameAndDescriptionForLessonPlanIds: [],
                isCreatingFromLessonPlanIds: [],
                isSuggestingTextSections: false,
                suggestingTextSectionsFailed: false,
                creationStage: 1,
                processingState: 1,
                processingStateLanguage: 1,
                chapterIdToChapterOptionsMapper: [],
                nextChapterOptions: [],
                isSuggestingForChapterIds: [],
                selectedChapterOptions: [],
                expandingChapterIds: [],
                lessonPlanChapterDtos: [],
                isDistributingLessons: false,
                languageSectionPlanDto: null,
                sections: null,
                lessonDistributionFailed: false,
                accLanguageSuggestingImages: false,
            }

        case autoCourseCreationConstants.ACC_REFRESH_RESET_SOME.REQUEST:
            return {
                ...state,
                isSuggestingLessonPlans: [],
                isGeneratingQuizForLessonPlanIds: [],
                isGeneratingDialogForLessonPlanIds: [],
                isGeneratingScenarioForLessonPlanIds: [],
                isGeneratingNameAndDescriptionForLessonPlanIds: [],
                isCreatingFromLessonPlanIds: [],
                isSuggestingTextSections: false,
                suggestingTextSectionsFailed: false,
                isSuggestingForChapterIds: [],
                isDistributingLessons: false,
                accLanguageSuggestingImages: false,
                expandingChapterIds: [],
            }

        case autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.REQUEST:
            const tempIsSuggestingLessonPlans = state.isSuggestingLessonPlans ? state.isSuggestingLessonPlans : []

            return {
                ...state,
                isSuggestingLessonPlans: [
                    ...tempIsSuggestingLessonPlans,
                    action.payload.parseTextRequestDto.section.sectionId,
                ],
                creationStage: 3,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.SUCCESS:
            let copiedsuggestSectionsReturnDto = JSON.parse(JSON.stringify(state.suggestSectionsReturnDto))
            copiedsuggestSectionsReturnDto.sections.forEach((section) => {
                if (section.sectionId === action.data.sectionId) {
                    section.concepts.forEach((concept) => {
                        action.data.concepts.forEach((conceptWithImage) => {
                            if (concept.conceptId === conceptWithImage.conceptId) {
                                concept.imageDescription = conceptWithImage.imageDescription
                                concept.imageUrl = conceptWithImage.imageUrl
                            }
                        })
                    })
                }
            })

            const tempLessonPlanDtos = state.lessonPlansDtos ? state.lessonPlansDtos : []
            return {
                ...state,
                isSuggestingLessonPlans: state.isSuggestingLessonPlans.filter((item) => item !== action.data.sectionId),
                lessonPlansDtos: [...tempLessonPlanDtos, action.data.data],
                suggestSectionsReturnDto: copiedsuggestSectionsReturnDto,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_LESSON_PLANS.FAILURE:
            return {
                ...state,
                isSuggestingLessonPlans: state.isSuggestingLessonPlans.filter((item) => item !== action.data.sectionId),
            }

        case autoCourseCreationConstants.ACC_SUGGEST_IMAGES.REQUEST:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_IMAGES.SUCCESS:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_IMAGES.FAILURE:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_IMAGE.REQUEST:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_IMAGE.SUCCESS:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_IMAGE.FAILURE:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_STORE_AUTHOR_INPUT_METADATA.REQUEST:
            return {
                ...state,
                authorInputMetadata: action.payload.authorInputMetadata,
            }
        case autoCourseCreationConstants.ACC_TRANSITION_STAGE.REQUEST:
            return {
                ...state,
                creationStage: action.payload.parseTextRequestDto.stage,
                processingState: action.payload.parseTextRequestDto.stage,
                processingStateLanguage: action.payload.parseTextRequestDto.stage,
            }
        case autoCourseCreationConstants.ACC_TRANSITION_LANGUAGE_STAGE.REQUEST:
            if (action.payload.parseTextRequestDto.stage === 1) {
                if (state.authorInputMetadata !== null) {
                    state.authorInputMetadata.useOnlyText = false
                    state.authorInputMetadata.authorWhatText = ''
                    state.authorInputMetadata.supportingText = ''
                }
            }
            return {
                ...state,
                processingStateLanguage: action.payload.parseTextRequestDto.stage,
            }
        case autoCourseCreationConstants.ACC_STORE_AUTHOR_DIALOG_METADATA.REQUEST:
            return {
                ...state,
                dialogSteps: action.payload.dialogSteps,
            }

        case autoCourseCreationConstants.ACC_CREATE_TOPIC.REQUEST:
            return {
                ...state,
                newlyCreateTopicId: null,
            }

        case autoCourseCreationConstants.ACC_CREATE_TOPIC.SUCCESS:
            return {
                ...state,
                newlyCreateTopicId: action.data.topicId,
            }

        case autoCourseCreationConstants.ACC_CREATE_TOPIC.FAILURE:
            return {
                ...state,
                newlyCreateTopicId: null,
            }

        case autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.REQUEST:
            let tempIsGeneratingNameAndDescriptionForLessonPlanIds =
                state.isGeneratingNameAndDescriptionForLessonPlanIds
                    ? state.isGeneratingNameAndDescriptionForLessonPlanIds
                    : []

            if (action.payload.localLessonSuggestionDto.objectToSend.lesson.lessonPlanId) {
                if (
                    tempIsGeneratingNameAndDescriptionForLessonPlanIds.filter(
                        (x) => x == action.payload.localLessonSuggestionDto.objectToSend.lesson.lessonPlanId
                    ).length == 0
                ) {
                    tempIsGeneratingNameAndDescriptionForLessonPlanIds.push(
                        action.payload.localLessonSuggestionDto.objectToSend.lesson.lessonPlanId
                    )
                }
            }

            return {
                ...state,
                //isSuggestingLessonPlans: [...tempIsGeneratingNameAndDescriptionForLessonPlanIds, action.payload.parseTextRequestDto.section.sectionId]
            }

        case autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.SUCCESS:
            const copiedLessonPlansDtos = JSON.parse(JSON.stringify(state.lessonPlansDtos))
            copiedLessonPlansDtos.forEach((lessonPlan) => {
                lessonPlan.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        lesson.manuallyEdited = true
                        lesson.lessonName = action.data.lessonName
                        lesson.lessonDescription = action.data.lessonDescription
                        lesson.lessonPlanConfigurationDto = {
                            ...lesson.lessonPlanConfigurationDto,
                            ...action.data.lessonPlanConfigurationDto,
                        }
                    }
                })
            })
            return {
                ...state,
                lessonPlansDtos: copiedLessonPlansDtos,
                isGeneratingNameAndDescriptionForLessonPlanIds:
                    state.isGeneratingNameAndDescriptionForLessonPlanIds.filter(
                        (item) => item !== action.data.lessonPlanId
                    ),
            }

        case autoCourseCreationConstants.ACC_UPDATE_TITLE_AND_DESCRIPTION.FAILURE:
            return {
                ...state,
                isGeneratingNameAndDescriptionForLessonPlanIds:
                    state.isGeneratingNameAndDescriptionForLessonPlanIds.filter(
                        (item) => item !== action.data.lessonPlanId
                    ),
            }
        case autoCourseCreationConstants.ACC_CREATE_LESSON.REQUEST:
            let tempIsCreatingFromLessonPlans2 = state.isCreatingFromLessonPlanIds
                ? state.isCreatingFromLessonPlanIds
                : []

            if (action.payload.lesson.lessonPlanId) {
                if (tempIsCreatingFromLessonPlans2.filter((x) => x == action.payload.lesson.lessonPlanId).length == 0) {
                    tempIsCreatingFromLessonPlans2.push(action.payload.lesson.lessonPlanId)
                }
            }

            return {
                ...state,
                isCreatingFromLessonPlanIds: tempIsCreatingFromLessonPlans2,
            }

        case autoCourseCreationConstants.ACC_CREATE_LESSON.SUCCESS:
            state.lessonPlansDtos.forEach((lessonPlan) => {
                lessonPlan.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        if (lesson.createdLessonId) {
                            lesson.createdLessonId.push(action.data.customLessonId)
                        } else {
                            lesson.createdLessonId = [action.data.customLessonId]
                        }
                    }
                })
            })

            if (state.isCreatingFromLessonPlanIds?.length > 0 && action.data) {
                return {
                    ...state,
                    isCreatingFromLessonPlanIds: state.isCreatingFromLessonPlanIds.filter(
                        (x) => x !== action.data.lessonPlanId
                    ),
                }
            } else {
                return {
                    ...state,
                }
            }

        case autoCourseCreationConstants.ACC_CREATE_LESSON.FAILURE:
            if (state.isCreatingFromLessonPlanIds?.length > 0 && action.data?.lessonPlanId) {
                return {
                    ...state,
                    isCreatingFromLessonPlanIds: state.isCreatingFromLessonPlanIds.filter(
                        (x) => x !== action.data.lessonPlanId
                    ),
                }
            } else {
                return {
                    ...state,
                }
            }

        case autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.REQUEST:
            return {
                ...state,
            }
        case autoCourseCreationConstants.ACC_CREATE_STORY.REQUEST:
            return {
                ...state,
            }
        case autoCourseCreationConstants.ACC_CREATE_STORY.SUCCESS:
            return {
                ...state,
                newlyCreateTopicId: action.data.topicId,
            }
        case autoCourseCreationConstants.ACC_CREATE_STORY.FAILURE:
            return {
                ...state,
            }
        case autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.SUCCESS:
            if (action.data.localLessonSuggestionDto.attribute === 'removeLesson') {
                const copiedArray = JSON.parse(JSON.stringify(state.lessonPlansDtos))
                const filteredArray = state.lessonPlansDtos.map((item) => {
                    const filteredInnerArray = item.lessons.filter(
                        (innerItem) => innerItem.lessonPlanId !== action.data.localLessonSuggestionDto.lessonPlanId
                    ) // Filter out element with specified lessonId
                    return { ...item, lessons: filteredInnerArray } // Update the inner array in the item
                })
                let filteredLessonDtos = filteredArray.filter((innerItem) => innerItem.lessons.length !== 0) // Filter out element with specified lessonId
                return {
                    ...state,
                    lessonPlansDtos: filteredLessonDtos,
                }
            }

            if (action.data.localLessonSuggestionDto.attribute === 'newLesson') {
                let sectionId = action.data.localLessonSuggestionDto.text.sectionId

                const l1Id = action.data.localLessonSuggestionDto.l1Id
                const l2Id = action.data.localLessonSuggestionDto.l2Id
                if (action.data.localLessonSuggestionDto.text.sectionId === null) {
                    let newSectionId = uuidv4()
                    sectionId = newSectionId

                    let newLessonPlanId = uuidv4()

                    let newLessonsArrayforNewSection = {
                        lessonPlanId: newLessonPlanId,
                        sectionId: state.suggestSectionsReturnDto.sections[0].sectionId,
                        lessons: [],
                    }

                    newLessonsArrayforNewSection.lessons.push({
                        concepts: [],
                        exercises: null,
                        l1Id: l1Id,
                        l2Id: l2Id,
                        lengthInMinutes: 10,
                        lessonDescription: '',
                        lessonName: '',
                        lessonNameL1: '',
                        lessonPlanConfigurationDto: {
                            configurationType: 'Traditional',
                            exercises: [],
                            interact: true,
                            introduce: true,
                            learningMode: 'Reading',
                            quizQuestions: null,
                            story: { alternativePlots: [], plot: '', storyMode: 'Listening' },
                            summarise: true,
                            welcome: true,
                        },
                        lessonPlanId: uuidv4(),
                        lessonPlanSimulationConfigurationDto: null,
                        lessonSubType: 'Normal',
                        lessonToneOfVoiceType: 'Serious',
                        lessonType: action.data.localLessonSuggestionDto.text.type,
                        manuallyEdited: false,
                        sectionId: state.suggestSectionsReturnDto.sections[0].sectionId,
                        sectionIndex: 0,
                    })
                    state.lessonPlansDtos.push(newLessonsArrayforNewSection)

                    return {
                        ...state,
                    }
                } else {
                    let copiedArray = JSON.parse(JSON.stringify(state.lessonPlansDtos))

                    let newLessonsArray = null
                    copiedArray.forEach((lessonPlanDto) => {
                        if (lessonPlanDto.sectionId === action.data.localLessonSuggestionDto.text.sectionId) {
                            newLessonsArray = JSON.parse(JSON.stringify(lessonPlanDto.lessons))
                            newLessonsArray.push({
                                concepts: [],
                                exercises: null,
                                l1Id: l1Id,
                                l2Id: l2Id,
                                lengthInMinutes: 10,
                                lessonDescription: '',
                                lessonName: '',
                                lessonNameL1: '',
                                lessonPlanConfigurationDto: {
                                    configurationType: 'Traditional',
                                    exercises: [],
                                    interact: true,
                                    introduce: true,
                                    learningMode: 'Reading',
                                    quizQuestions: null,
                                    story: { alternativePlots: [], plot: '', storyMode: 'Listening' },
                                    summarise: true,
                                    welcome: true,
                                },
                                lessonPlanId: uuidv4(),
                                lessonPlanSimulationConfigurationDto: null,
                                lessonSubType: 'Normal',
                                lessonToneOfVoiceType: 'Serious',
                                lessonType: action.data.localLessonSuggestionDto.text.type,
                                manuallyEdited: false,
                                sectionId: sectionId,
                                sectionIndex: 0,
                            })
                            lessonPlanDto.lessons = newLessonsArray
                        }
                    })
                    if (newLessonsArray !== null) {
                    }
                    return {
                        ...state,
                        lessonPlansDtos: copiedArray,
                    }
                }
            } else if (state.lessonPlansDtos && state.lessonPlansDtos.length > 0) {
                state.lessonPlansDtos.forEach((lessonPlan) => {
                    lessonPlan.lessons.forEach((lesson) => {
                        if (lesson.lessonPlanId === action.data.localLessonSuggestionDto.lessonPlanId) {
                            switch (action.data.localLessonSuggestionDto.attribute) {
                                case 'newLesson':
                                    break
                                case 'removeLesson':
                                    break
                                //lesson.lessonName = action.data.localLessonSuggestionDto.text
                                case 'interactionType':
                                    lesson.lessonPlanConfigurationDto.exercises.forEach((exercise) => {
                                        if (
                                            action.data.localLessonSuggestionDto.text.exerciseType ===
                                            exercise.exerciseType
                                        ) {
                                            exercise.interactionType =
                                                action.data.localLessonSuggestionDto.text.interactionType
                                        }
                                    })
                                    break
                                case 'lessonName':
                                    lesson.lessonName = action.data.localLessonSuggestionDto.text
                                    break
                                case 'lengthInMinutes':
                                    lesson.lengthInMinutes = parseInt(action.data.localLessonSuggestionDto.text)
                                    break
                                case 'lessonDescription':
                                    lesson.lessonDescription = action.data.localLessonSuggestionDto.text
                                    break
                                case 'removeQuizQuestion':
                                    lesson.lessonPlanConfigurationDto.quizQuestions =
                                        lesson.lessonPlanConfigurationDto.quizQuestions.filter((quiz) => {
                                            return (
                                                quiz.questionId !== action.data.localLessonSuggestionDto.text.questionId
                                            )
                                        })
                                    break
                                case 'addNewStagesToComplianceLesson':
                                    action.data.localLessonSuggestionDto.text.newLessonPlans.forEach((stage) => {
                                        let interaction = 'Reading'
                                        let coversMultpleConcepts = false
                                        let plotType = 'TestYourKnowledge'
                                        action.data.localLessonSuggestionDto.text.defaultInteractions.forEach(
                                            (stageInteraction) => {
                                                if (stageInteraction.type === stage) {
                                                    interaction = stageInteraction.interaction
                                                    coversMultpleConcepts = stageInteraction.coversMultpleConcepts
                                                    plotType = stageInteraction.plotType
                                                }
                                            }
                                        )
                                        if (
                                            lesson.lessonPlanConfigurationDto.exercises === null ||
                                            typeof lesson.lessonPlanConfigurationDto.exercises === 'undefined'
                                        ) {
                                            lesson.lessonPlanConfigurationDto.exercises = []
                                        }
                                        lesson.lessonPlanConfigurationDto.exercises.push({
                                            coversMultpleConcepts: coversMultpleConcepts,
                                            exerciseType: stage,
                                            interactionType: interaction,
                                            plotType: plotType,
                                        })
                                    })
                                    break
                                case 'updateExercises':
                                    if (action.data.localLessonSuggestionDto.text.shouldRemove) {
                                        lesson.lessonPlanConfigurationDto.exercises =
                                            lesson.lessonPlanConfigurationDto.exercises.filter((exercise) => {
                                                return (
                                                    exercise.exerciseType !==
                                                        action.data.localLessonSuggestionDto.text.stageType ||
                                                    (exercise.plotType !==
                                                        action.data.localLessonSuggestionDto.text.plotType &&
                                                        action.data.localLessonSuggestionDto.text.plotType !== null)
                                                )
                                            })
                                    } else {
                                        lesson.lessonPlanConfigurationDto.exercises.push({
                                            coversMultpleConcepts:
                                                action.data.localLessonSuggestionDto.text.coversMultpleConcepts,
                                            exerciseType: action.data.localLessonSuggestionDto.text.stageType,
                                            interactionType: action.data.localLessonSuggestionDto.text.interactionType,
                                            plotType: action.data.localLessonSuggestionDto.text.plotType,
                                        })
                                    }
                                    break
                                case 'lessonType':
                                    lesson.lessonType = action.data.localLessonSuggestionDto.text
                                    break
                                case 'learnerRole':
                                    let foundRoleLearner = false
                                    lesson.lessonPlanSimulationConfigurationDto.roleLearner =
                                        action.data.localLessonSuggestionDto.text
                                    lesson.lessonPlanSimulationConfigurationDto.possibleRoles.forEach((role) => {
                                        if (role === action.data.localLessonSuggestionDto.text) {
                                            foundRoleLearner = true
                                        }
                                    })
                                    if (foundRoleLearner === false) {
                                        lesson.lessonPlanSimulationConfigurationDto.possibleRoles.push(
                                            action.data.localLessonSuggestionDto.text
                                        )
                                    }
                                    break
                                case 'aiRole':
                                    let foundRole = false
                                    lesson.lessonPlanSimulationConfigurationDto.roleAi =
                                        action.data.localLessonSuggestionDto.text
                                    lesson.lessonPlanSimulationConfigurationDto.possibleRoles.forEach((role) => {
                                        if (role === action.data.localLessonSuggestionDto.text) {
                                            foundRole = true
                                        }
                                    })
                                    if (foundRole === false) {
                                        lesson.lessonPlanSimulationConfigurationDto.possibleRoles.push(
                                            action.data.localLessonSuggestionDto.text
                                        )
                                    }
                                    break

                                case 'storyText':
                                    lesson.lessonPlanConfigurationDto.story.plot =
                                        action.data.localLessonSuggestionDto.text
                                    lesson.lessonPlanConfigurationDto.story.alternativePlots.push(
                                        action.data.localLessonSuggestionDto.text
                                    )
                                    break
                                case 'storyMode':
                                    lesson.lessonPlanConfigurationDto.story.storyMode =
                                        action.data.localLessonSuggestionDto.text
                                    break
                                case 'plotText':
                                    let localLesson = lesson
                                    let possiblePlots = null
                                    let plot = null
                                    if (lesson.lessonSubType === 'Simulation') {
                                        possiblePlots = lesson.lessonPlanSimulationConfigurationDto.possiblePlots
                                        //plot = lesson.lessonPlanSimulationConfigurationDto.plot
                                    } else {
                                        if (lesson.lessonPlanConfigurationDto.dialogue) {
                                            possiblePlots = lesson.lessonPlanConfigurationDto.dialogue.possiblePlots
                                        } else {
                                            possiblePlots = []
                                        }
                                        //plot = lesson.lessonPlanConfigurationDto.dialogue.plot
                                    }

                                    let found = false
                                    let closestFound = 1000
                                    let closestFoundIndex = -1
                                    let isExactMatch = false
                                    let newTextLength = action.data.localLessonSuggestionDto.text.length
                                    plot = action.data.localLessonSuggestionDto.text
                                    possiblePlots.forEach((plot, index) => {
                                        if (plot === action.data.localLessonSuggestionDto.text) {
                                            isExactMatch = true
                                        } else {
                                            // Levenshtein Distance to decide whether to add new plot to the plots array or update the nearest related plot if only a small alteration was made.
                                            // Always adds new plot if text is very short.
                                            var matrix = []
                                            var sIlen = action.data.localLessonSuggestionDto.text.length
                                            var tLen = plot.length
                                            matrix[0] = []
                                            for (var b = 0; b <= tLen; ++b) {
                                                matrix[0][b] = b
                                            }
                                            for (var a = 1; a <= sIlen; ++a) {
                                                matrix[a] = []
                                                matrix[a][0] = a
                                                for (var b = 1; b <= tLen; ++b) {
                                                    var cost =
                                                        action.data.localLessonSuggestionDto.text[a - 1] == plot[b - 1]
                                                            ? 0
                                                            : 1
                                                    matrix[a][b] = Math.min(
                                                        matrix[a - 1][b] + 1,
                                                        matrix[a][b - 1] + 1,
                                                        matrix[a - 1][b - 1] + cost
                                                    )
                                                }
                                            }
                                            if (matrix[sIlen][tLen] < closestFound) {
                                                closestFound = matrix[sIlen][tLen]
                                                closestFoundIndex = index
                                            }
                                            if (matrix[sIlen][tLen] <= 5) {
                                                found = true
                                            }
                                        }
                                    })

                                    if (isExactMatch === true) {
                                        // do nothing
                                    } else {
                                        if (closestFound > 6 || newTextLength < 6) {
                                            possiblePlots.push(action.data.localLessonSuggestionDto.text)

                                            //lesson.lessonPlanSimulationConfigurationDto.possiblePlots.push(action.data.localLessonSuggestionDto.text)
                                        } else {
                                            //lesson.lessonPlanSimulationConfigurationDto.possiblePlots[closestFoundIndex] = action.data.localLessonSuggestionDto.text
                                            possiblePlots[closestFoundIndex] = action.data.localLessonSuggestionDto.text
                                        }
                                    }

                                    if (lesson.lessonSubType === 'Simulation') {
                                        localLesson.lessonPlanSimulationConfigurationDto.possiblePlots = possiblePlots
                                        localLesson.lessonPlanSimulationConfigurationDto.plot = plot
                                    } else {
                                        if (localLesson.lessonPlanConfigurationDto.dialogue) {
                                            localLesson.lessonPlanConfigurationDto.dialogue.possiblePlots =
                                                possiblePlots
                                            localLesson.lessonPlanConfigurationDto.dialogue.plot = plot
                                        } else {
                                            localLesson.lessonPlanConfigurationDto.dialogue = {
                                                possiblePlots: possiblePlots,
                                                plot: plot,
                                            }
                                        }
                                    }
                                    break
                                case 'lessonToneOfVoiceType':
                                    lesson.lessonToneOfVoiceType = action.data.localLessonSuggestionDto.text
                                    break
                                case 'normalType':
                                    lesson.lessonSubType = 'Normal'
                                    if (lesson.lessonPlanConfigurationDto === null) {
                                        let lessonPlanConfigurationDtoPlaceholder = {
                                            configurationType: 'Traditional',
                                        }
                                        lesson.lessonPlanConfigurationDto = lessonPlanConfigurationDtoPlaceholder
                                    } else {
                                        lesson.lessonPlanConfigurationDto.configurationType = 'Traditional'
                                    }
                                    lesson.lessonPlanConfigurationDto.configurationType =
                                        action.data.localLessonSuggestionDto.text
                                    break
                                case 'quizReorder':
                                    lesson.lessonPlanConfigurationDto.quizQuestions =
                                        action.data.localLessonSuggestionDto.text
                                    break
                                case 'quizQuestion':
                                    if (lesson.lessonPlanConfigurationDto?.quizQuestions) {
                                        lesson.lessonPlanConfigurationDto.quizQuestions.push(
                                            action.data.localLessonSuggestionDto.text
                                        )
                                    } else {
                                        lesson.lessonPlanConfigurationDto.quizQuestions = [
                                            action.data.localLessonSuggestionDto.text,
                                        ]
                                    }
                                    break
                                case 'simulationType':
                                    //////

                                    lesson.lessonSubType = 'Simulation'
                                    if (lesson.lessonPlanSimulationConfigurationDto === null) {
                                        let lessonPlanSimulationConfigurationDtoPlaceholder = {
                                            plot: '',
                                            possiblePlots: [],
                                            possibleRoles: [],
                                            roleAi: '',
                                            roleLearner: '',
                                            simulationType: action.data.localLessonSuggestionDto.text,
                                        }
                                        lesson.lessonPlanSimulationConfigurationDto =
                                            lessonPlanSimulationConfigurationDtoPlaceholder
                                    } else {
                                        lesson.lessonPlanSimulationConfigurationDto.simulationType =
                                            action.data.localLessonSuggestionDto.text
                                    }
                                    break
                                case 'lessonSubType':
                                    lesson.lessonSubType = action.data.localLessonSuggestionDto.text
                                    if (action.data.localLessonSuggestionDto.text === 'Simulation') {
                                        let lessonPlanSimulationConfigurationDtoPlaceholder = {
                                            plot: '',
                                            possiblePlots: [],
                                            possibleRoles: [],
                                            roleAi: '',
                                            roleLearner: '',
                                            simulationType: 'ConversationWithSomeone',
                                        }
                                        lesson.lessonPlanSimulationConfigurationDto =
                                            lessonPlanSimulationConfigurationDtoPlaceholder
                                    } else if (action.data.localLessonSuggestionDto.text === 'Normal') {
                                        let lessonPlanConfigurationDtoPlaceholder = {
                                            configurationType: 'Traditional',
                                        }
                                        lesson.lessonPlanConfigurationDto = lessonPlanConfigurationDtoPlaceholder
                                    } else {
                                        console.debug(
                                            'Found unsupported lesson subtype',
                                            action.data.localLessonSuggestionDto.text
                                        )
                                    }

                                    break
                                case 'exercises':
                                    lesson.lessonPlanConfigurationDto.exercises =
                                        action.data.localLessonSuggestionDto.text

                                    break
                                    return {
                                        ...state,
                                    }
                            }
                        }
                    })
                })
            }

        case autoCourseCreationConstants.ACC_LOCAL_UPDATE_LESSON_SUGGESTION_DTO.FAILURE:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.REQUEST:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.SUCCESS:
            let found = false
            if (state.suggestSectionsReturnDto && state.suggestSectionsReturnDto.sections.length > 0) {
                state.suggestSectionsReturnDto.sections.forEach((section) => {
                    if (section.title === 'Other teaching material') {
                        found = true
                        if (action.data.suggestSectionsReturnDto.attribute === 'conceptNew') {
                            section.concepts.push({
                                conceptId: uuidv4(),
                                explanation: action.data.suggestSectionsReturnDto.text,
                                text: action.data.suggestSectionsReturnDto.otherInformation,
                            })
                        }
                    }
                    if (section.sectionId === action.data.suggestSectionsReturnDto.sectionId) {
                        switch (action.data.suggestSectionsReturnDto.attribute) {
                            case 'title':
                                section.title = action.data.suggestSectionsReturnDto.text
                                break
                            case 'conceptText':
                                section.concepts.forEach((concept) => {
                                    if (concept.conceptId === action.data.suggestSectionsReturnDto.conceptId) {
                                        concept.text = action.data.suggestSectionsReturnDto.text
                                    }
                                })
                                break
                            case 'conceptExplanation':
                                section.concepts.forEach((concept) => {
                                    if (concept.conceptId === action.data.suggestSectionsReturnDto.conceptId) {
                                        concept.explanation = action.data.suggestSectionsReturnDto.text
                                        concept.text = action.data.suggestSectionsReturnDto.otherInformation
                                    }
                                })
                                break
                        }
                    }
                })
            }
            if (action.data.suggestSectionsReturnDto.attribute === 'conceptNew') {
                if (found === false) {
                    state.suggestSectionsReturnDto.sections.push({
                        concepts: [
                            {
                                conceptId: uuidv4(),
                                explanation: action.data.suggestSectionsReturnDto.text,
                                text: action.data.suggestSectionsReturnDto.otherInformation,
                            },
                        ],
                        paragraphs: [],
                        title: 'Other teaching material',
                        topicSummary: '',
                        translatedTitle: '',
                        sectionId: uuidv4(),
                    })
                }
            }

            return {
                ...state,
            }
        case autoCourseCreationConstants.ACC_CREATE_QUIZ.REQUEST:
            let tempIsGeneratingQuizForLessonPlanIds = state.isGeneratingQuizForLessonPlanIds
                ? state.isGeneratingQuizForLessonPlanIds
                : []
            if (action.payload.lesson.lessonPlanId) {
                if (!tempIsGeneratingQuizForLessonPlanIds.includes(action.payload.lesson.lessonPlanId)) {
                    tempIsGeneratingQuizForLessonPlanIds.push(action.payload.lesson.lessonPlanId)
                }
            }
            return {
                ...state,
                isGeneratingQuizForLessonPlanIds: tempIsGeneratingQuizForLessonPlanIds,
            }

        case autoCourseCreationConstants.ACC_CREATE_QUIZ.SUCCESS:
            state.lessonPlansDtos.forEach((lessonPlanDto) => {
                lessonPlanDto.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        if (
                            lesson.lessonPlanConfigurationDto?.quizQuestions &&
                            action.data.responseData?.questions?.length > 0
                        ) {
                            let orginialTextQuestions = lesson.lessonPlanConfigurationDto.quizQuestions.map(
                                (x) => x.question
                            )
                            action.data.responseData.questions.forEach((x) => {
                                if (!orginialTextQuestions.includes(x.question)) {
                                    // simple duplicate removal
                                    lesson.lessonPlanConfigurationDto.quizQuestions.push(x)
                                }
                            })
                        } else if (action.data.responseData?.questions?.length > 0) {
                            lesson.lessonPlanConfigurationDto.quizQuestions = action.data.responseData.questions
                        }
                    }
                })
            })

            if (state.isGeneratingQuizForLessonPlanIds?.length > 0 && action.data.lessonPlanId) {
                return {
                    ...state,
                    isGeneratingQuizForLessonPlanIds: state.isGeneratingQuizForLessonPlanIds.filter(
                        (x) => x !== action.data.lessonPlanId
                    ),
                }
            } else {
                return {
                    ...state,
                }
            }

        case autoCourseCreationConstants.ACC_CREATE_QUIZ.FAILURE:
            if (state.isGeneratingQuizForLessonPlanIds?.length > 0 && action.data.lessonPlanId) {
                return {
                    ...state,
                    isGeneratingQuizForLessonPlanIds: state.isGeneratingQuizForLessonPlanIds.filter(
                        (x) => x !== action.data.lessonPlanId
                    ),
                }
            } else {
                return {
                    ...state,
                }
            }

        case autoCourseCreationConstants.ACC_CREATE_DIALOGUE.REQUEST:
            var tempIsGeneratingDialogForLessonPlanIds = state.isGeneratingDialogForLessonPlanIds
                ? state.isGeneratingDialogForLessonPlanIds
                : []
            if (action.payload.lesson.lessonPlanId) {
                if (
                    tempIsGeneratingDialogForLessonPlanIds.filter((x) => x == action.payload.lesson.lessonPlanId)
                        .length == 0
                ) {
                    tempIsGeneratingDialogForLessonPlanIds.push(action.payload.lesson.lessonPlanId)
                }
            }

            return {
                ...state,
                isGeneratingDialogForLessonPlanIds: tempIsGeneratingDialogForLessonPlanIds,
            }

        case autoCourseCreationConstants.ACC_CREATE_DIALOGUE.SUCCESS:
            state.lessonPlansDtos.forEach((lessonPlanDto) => {
                lessonPlanDto.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        if (lesson.lessonPlanConfigurationDto?.dialogue?.possiblePlots) {
                            if (action.data?.possiblePlots?.length > 0) {
                                action.data.possiblePlots.forEach((x) => {
                                    if (!lesson.lessonPlanConfigurationDto.dialogue.possiblePlots.includes(x)) {
                                        lesson.lessonPlanConfigurationDto.dialogue.possiblePlots.push(x)
                                    }
                                })
                            }
                            if (action.data?.possibleRoles?.length > 0) {
                                action.data.possibleRoles.forEach((x) => {
                                    if (!lesson.lessonPlanConfigurationDto.dialogue.possibleRoles.includes(x)) {
                                        lesson.lessonPlanConfigurationDto.dialogue.possibleRoles.push(x)
                                    }
                                })
                            }
                        } else {
                            let newDialogue = {
                                possiblePlots: action.data.possiblePlots,
                                possibleRoles: action.data.possibleRoles,
                                selectedRoles: action.data.selectedRoles,
                                plot: action.data.plot,
                                manuallyEdited: false,
                            }

                            lesson.lessonPlanConfigurationDto.dialogue = newDialogue
                        }
                    }
                })
            })

            return {
                ...state,
                isGeneratingDialogForLessonPlanIds: state.isGeneratingDialogForLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                ),
            }

        case autoCourseCreationConstants.ACC_CREATE_DIALOGUE.FAILURE:
            return {
                ...state,
                isGeneratingDialogForLessonPlanIds: state.isGeneratingDialogForLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                ),
            }

        case autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.REQUEST:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.SUCCESS:
            state.lessonPlansDtos.forEach((lessonPlanDto) => {
                lessonPlanDto.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        lesson.lessonPlanConfigurationDto.fillInBlanksQuestions = action.data.fillInTheBlanksQuestions
                    }
                })
            })
            //const copiedObject = JSON.parse(JSON.stringify(state.lessonPlansDtos));

            return {
                ...state,
            }
        case autoCourseCreationConstants.ACC_CREATE_FILL_IN_BLANKS.FAILURE:
            return {
                ...state,
                isDialogueGenerating: false,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.REQUEST:
            let tempIsGeneratingScenarioForLessonPlanIds = state.isGeneratingScenarioForLessonPlanIds
                ? state.isGeneratingScenarioForLessonPlanIds
                : []

            if (action.payload.lesson.lessonPlanId) {
                if (
                    tempIsGeneratingScenarioForLessonPlanIds.filter((x) => x == action.payload.lesson.lessonPlanId)
                        .length == 0
                ) {
                    tempIsGeneratingScenarioForLessonPlanIds.push(action.payload.lesson.lessonPlanId)
                }
            }
            return {
                ...state,
                isGeneratingScenarioForLessonPlanIds: tempIsGeneratingScenarioForLessonPlanIds,
            }

        case autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.SUCCESS:
            state.lessonPlansDtos.forEach((lessonPlanDto) => {
                lessonPlanDto.lessons.forEach((lesson) => {
                    if (lesson.lessonPlanId === action.data.lessonPlanId) {
                        // update the selected plot if no existing plots
                        if (lesson?.lessonPlanSimulationConfigurationDto?.plot !== '') {
                        } else {
                            lesson.lessonPlanSimulationConfigurationDto.plot =
                                action.data.lessonPlanSimulationConfigurationDto.plot
                        }

                        // update the selected AI role if no existing AI role
                        if (lesson?.lessonPlanSimulationConfigurationDto?.roleAi !== '') {
                        } else {
                            lesson.lessonPlanSimulationConfigurationDto.roleAi =
                                action.data.lessonPlanSimulationConfigurationDto.roleAi
                        }

                        // update the selected learner role if no existing learner role
                        if (lesson?.lessonPlanSimulationConfigurationDto?.roleLearner !== '') {
                        } else {
                            lesson.lessonPlanSimulationConfigurationDto.roleLearner =
                                action.data.lessonPlanSimulationConfigurationDto.roleLearner
                        }

                        // add plots to existing plots array (if any)
                        let newPlots = action.data.lessonPlanSimulationConfigurationDto.possiblePlots
                        if (lesson.lessonPlanSimulationConfigurationDto?.possiblePlots) {
                            let existingPlots = lesson.lessonPlanSimulationConfigurationDto.possiblePlots
                            existingPlots.push(...newPlots)
                        } else {
                            lesson.lessonPlanSimulationConfigurationDto.possiblePlots = newPlots
                        }

                        // add roles to existing roles arrays (if any)
                        let newRoles = action.data.lessonPlanSimulationConfigurationDto.possibleRoles
                        if (lesson.lessonPlanSimulationConfigurationDto?.possibleRoles) {
                            let existingPlots = lesson.lessonPlanSimulationConfigurationDto.possibleRoles
                            existingPlots.push(...newRoles)
                        } else {
                            lesson.lessonPlanSimulationConfigurationDto.possibleRoles = newRoles
                        }
                    }
                })
            })

            return {
                ...state,
                isGeneratingScenarioForLessonPlanIds: state.isGeneratingScenarioForLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                ),
            }

        case autoCourseCreationConstants.ACC_SUGGEST_SCENARIO.FAILURE:
            return {
                ...state,
                isGeneratingScenarioForLessonPlanIds: state.isGeneratingScenarioForLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                ),
            }

        case autoCourseCreationConstants.ACC_LOCAL_UPDATE_SUGGEST_SECTIONS_RETURN_DTO.FAILURE:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.REQUEST:
            return {
                ...state,
                isSuggestingTextSections: true,
                suggestingTextSectionsFailed: false,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.SUCCESS:
            return {
                ...state,
                suggestSectionsReturnDto: action.data,
                isSuggestingTextSections: false,
                processingStateLanguage: AutoCourseCreatorLanguageProcessStages.SelectVocabularyAndGrammar,
                suggestingTextSectionsFailed: false,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_VOCAB_SECTION.FAILURE:
            return {
                ...state,
                isSuggestingTextSections: false,
                processingStateLanguage: AutoCourseCreatorLanguageProcessStages.SelectVocabularyAndGrammar,
                suggestingTextSectionsFailed: true,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.REQUEST:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.SUCCESS:
            let localSections = [...state.suggestSectionsReturnDto.sections] // fixme todo: if we ever use > section then this logic needs improving
            localSections[0].concepts = action.data.concepts
            // let conceptsInSection =  [...tempIsSuggestingLessonPlans, action.payload.parseTextRequestDto.section.sectionId],
            state.suggestSectionsReturnDto.sections = localSections
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_ADD_VOCAB_ITEM.FAILURE:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_TOGGLE_CONCEPT_IN_USE.REQUEST:
            let tempSections = [...state.suggestSectionsReturnDto.sections]
            tempSections[0].concepts.forEach((conceptDto) => {
                if (conceptDto.conceptId === action.payload.conceptId) {
                    conceptDto.usedInLesson = action.payload.usedInLesson
                }
            })
            state.suggestSectionsReturnDto.sections = tempSections
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.REQUEST:
            let tempIsSuggestingForChapterIdsRequest = state.isSuggestingForChapterIds
                ? state.isSuggestingForChapterIds
                : []
            let tempChapterIdToChapterOptionsRequest = state.chapterIdToChapterOptionsMapper
                ? state.chapterIdToChapterOptionsMapper
                : []

            if (
                tempIsSuggestingForChapterIdsRequest.filter(
                    (x) => x === action.payload.narrativeRequestDto.chapterRequestId
                ).length === 0
            ) {
                tempIsSuggestingForChapterIdsRequest.push(action.payload.narrativeRequestDto.chapterRequestId)
            }

            return {
                ...state,
                isSuggestingForChapterIds: tempIsSuggestingForChapterIdsRequest,
                chapterIdToChapterOptionsMapper: tempChapterIdToChapterOptionsRequest,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.SUCCESS:
            let tempChapterIdToChapterOptions = state.chapterIdToChapterOptionsMapper
                ? state.chapterIdToChapterOptionsMapper
                : []

            let tempOption = tempChapterIdToChapterOptions.find((item) => item.chapterId === action.data.chapterId)
            let filteredList = [...tempChapterIdToChapterOptions]
            if (tempOption !== undefined) {
                filteredList = tempChapterIdToChapterOptions.filter((item) => item.chapterId !== action.data.chapterId)
            }

            filteredList.push(action.data)

            let tempIsSuggestingForChapterIdsSuccess = state.isSuggestingForChapterIds
                ? state.isSuggestingForChapterIds
                : []

            let filteredChapterIdsSuccess = tempIsSuggestingForChapterIdsSuccess.filter(
                (x) => x !== action.data.chapterId
            )

            return {
                ...state,
                chapterIdToChapterOptionsMapper: filteredList,
                nextChapterOptions: action.data,
                isSuggestingForChapterIds: filteredChapterIdsSuccess,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_CHAPTER_OPTIONS.FAILURE:
            let tempIsSuggestingForChapterIdsFailure = state.isSuggestingForChapterIds
                ? state.isSuggestingForChapterIds
                : []

            let filteredChapterIdsFailure = tempIsSuggestingForChapterIdsFailure.filter(
                (x) => x !== action.data.chapterId
            )

            return {
                ...state,
                isSuggestingForChapterIds: filteredChapterIdsFailure,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_STORE_CHAPTER_OPTIONS.REQUEST:
            return {
                ...state,
                selectedChapterOptions: action.payload.selectedChapters,
            }
        case autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.REQUEST:
            return {
                ...state,
                languageSectionPlanDto: null,
                isDistributingLessons: true,
                lessonDistributionFailed: false,
            }
        case autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.SUCCESS:
            return {
                ...state,
                languageSectionPlanDto: action.data,
                isDistributingLessons: false,
                lessonDistributionFailed: false,
            }
        case autoCourseCreationConstants.ACC_LANGUAGE_LESSON_DISTRIBUTION.FAILURE:
            return {
                ...state,
                languageSectionPlanDto: null,
                isDistributingLessons: false,
                lessonDistributionFailed: true,
            }

        case autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.REQUEST:
            //TODO: add to list of chapters being post processed

            let tempExpandingChapterIds = state.expandingChapterIds ? state.expandingChapterIds : []

            if (action.payload.chapterDto?.chapterId) {
                if (tempExpandingChapterIds.filter((x) => x == action.payload.chapterDto.chapterId).length == 0) {
                    tempExpandingChapterIds.push(action.payload.chapterDto.chapterId)
                }
            }

            return {
                ...state,
                expandingChapterIds: tempExpandingChapterIds,
            }

        case autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.SUCCESS:
            let tempLessonPlanChapterDtos = state.lessonPlanChapterDtos ? state.lessonPlanChapterDtos : []
            tempLessonPlanChapterDtos = tempLessonPlanChapterDtos.filter((x) => x.chapterId !== action.data.chapterId)

            tempLessonPlanChapterDtos.push(action.data)

            return {
                ...state,
                expandingChapterIds: state.expandingChapterIds.filter((x) => x !== action.data.chapterId),
                lessonPlanChapterDtos: tempLessonPlanChapterDtos,
            }
        case autoCourseCreationConstants.ACC_LESSON_PLAN_CHAPTER_EXPAND.FAILURE:
            return {
                ...state,
                expandingChapterIds: state.expandingChapterIds.filter((x) => x !== action.data.chapterId),
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.REQUEST:
            return {
                ...state,
                accLanguageSuggestingImages: true,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.SUCCESS:
            const conceptsWithImagesGenerated = action.data
            state.suggestSectionsReturnDto.sections[0].concepts.forEach((x) => {
                if (x.usedInLesson) {
                    conceptsWithImagesGenerated.forEach((y) => {
                        if (y.conceptId === x.conceptId) {
                            x.imageDescription = y.imageDescription
                            x.imageUrl = y.imageUrl
                        }
                    })
                }
            })

            return {
                ...state,
                accLanguageSuggestingImages: false,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_SUGGEST_IMAGES_FOR_SELECTED.FAILURE:
            return {
                ...state,
                accLanguageSuggestingImages: false,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.REQUEST:
            return {
                ...state,
            }
        case autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.SUCCESS:
            switch (action.data.mainElement) {
                case 'languageSectionPlanDto':
                    switch (action.data.attributeToUpdate) {
                        case 'narrativeText': // update the narrative text from the story
                            const nextStateUpdateNarrativeText = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapterDialogue =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                            .chapterDialogue[action.data.updateData.chapterDialogueIndex]
                                    if (chapterDialogue) {
                                        chapterDialogue.text = action.data.updateData.text
                                        chapterDialogue.translation = ''
                                        const dialogueIntroducedConceptIds = action.data.updateData?.conceptIds?.filter(
                                            (x) => chapterDialogue.conceptIds.findIndex((y) => y === x) === -1
                                        )
                                        const dialogueRemovedConceptIds = chapterDialogue.conceptIds?.filter(
                                            (x) => action.data.updateData?.conceptIds.findIndex((y) => y === y) === -1
                                        )
                                        const majorChanges =
                                            dialogueIntroducedConceptIds.length > 0 ||
                                            dialogueRemovedConceptIds.length > 0
                                        if (!lessonPlanToEdit.majorChanges && majorChanges) {
                                            lessonPlanToEdit.majorChanges = true
                                        }
                                        lessonPlanToEdit.lastChange = new Date().valueOf()
                                        chapterDialogue.conceptIds = action.data.updateData.conceptIds
                                    }
                                }
                            })
                            return nextStateUpdateNarrativeText

                        case 'addNarrativeRole':
                            const nextStateAddNarrativeRole = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapter =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                    if (chapter) {
                                        chapter.chapterRoles.push(action.data.updateData.text)
                                    }
                                }
                            })
                            return nextStateAddNarrativeRole

                        case 'editNarrativeRole':
                            const nextStateEditNarrativeRole = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapter =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                    if (chapter) {
                                        let originalRoleName =
                                            chapter.chapterRoles[action.data.updateData.roleIndex].name
                                        chapter.chapterRoles[action.data.updateData.roleIndex].name =
                                            action.data.updateData.text
                                        chapter.chapterDialogue = chapter.chapterDialogue.map((dialogue) => {
                                            // update the role speaker in existing narrative dialogues
                                            if (dialogue.speaker.name === originalRoleName) {
                                                return {
                                                    ...dialogue,
                                                    speaker: {
                                                        name: action.data.updateData.text,
                                                        gender: dialogue.speaker.gender,
                                                    },
                                                }
                                            } else {
                                                return dialogue
                                            }
                                        })
                                        lessonPlanToEdit.lastChange = new Date().valueOf()
                                    }
                                }
                            })
                            return nextStateEditNarrativeRole

                        case 'editNarrativeRoleGender':
                            const nextStateEditNarrativeRoleGender = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapter =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                    if (chapter) {
                                        let originalRoleName = chapter.chapterRoles[action.data.updateData.roleIndex]
                                        chapter.chapterRoles[action.data.updateData.roleIndex].gender =
                                            action.data.updateData.text

                                        chapter.chapterDialogue.forEach((dialogue) => {
                                            // update the role speaker in existing narrative dialogues
                                            if (dialogue.speaker.name === originalRoleName.name) {
                                                dialogue.speaker.gender = action.data.updateData.text
                                            }
                                        })
                                        lessonPlanToEdit.lastChange = new Date().valueOf()
                                    }
                                }
                            })
                            return nextStateEditNarrativeRoleGender

                        case 'updateNarrativeRoleSelection':
                            const nextStateUpdateNarrativeRoleSelection = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapter =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                    if (chapter) {
                                        let gender = 'Male'
                                        chapter.chapterRoles.forEach((role) => {
                                            if (role === action.data.updateData.text) {
                                                gender = role.gender
                                            }
                                        })
                                        chapter.chapterDialogue[action.data.updateData.scriptIndex].speaker.name =
                                            action.data.updateData.text
                                        chapter.chapterDialogue[action.data.updateData.scriptIndex].speaker.gender =
                                            gender
                                    }
                                    lessonPlanToEdit.lastChange = new Date().valueOf()
                                }
                            })
                            return nextStateUpdateNarrativeRoleSelection

                        case 'removeNarrativeScriptRow':
                            const nextStateRemoveNarrativeScriptRow = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapter =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                    if (chapter) {
                                        if (
                                            chapter.chapterDialogue[action.data.updateData.scriptIndex].conceptIds
                                                ?.length > 0
                                        ) {
                                            lessonPlanToEdit.majorChanges = true
                                        }

                                        chapter.chapterDialogue.splice(action.data.updateData.scriptIndex, 1)
                                        lessonPlanToEdit.lastChange = new Date().valueOf()
                                    }
                                }
                            })
                            return nextStateRemoveNarrativeScriptRow

                        case 'addNarrativeScriptRow':
                            const nextStateAddNarrativeScriptRow = produce(state, (draftState) => {
                                const lessonPlanToEdit = draftState.languageSectionPlanDto.languageLessonPlanDtos.find(
                                    (x) => x.lessonPlanId === action.data.updateData.lessonPlanId
                                )
                                if (lessonPlanToEdit) {
                                    const chapter =
                                        lessonPlanToEdit.lessonPlanChapters[action.data.updateData.chaptersIndex]
                                    if (chapter) {
                                        //chapter.chapterDialogue.splice(action.data.updateData.scriptIndex, 1)

                                        chapter.chapterDialogue.splice(
                                            action.data.updateData.scriptIndex + 1,
                                            0,
                                            action.data.updateData.emptyRow
                                        )
                                        //chapter.chapterDialogue = [
                                        //  ...chapter.chapterDialogue.slice(0, action.data.updateData.scriptIndex),
                                        //  action.data.updateData.emptyRow,
                                        //  ...chapter.chapterDialogue.slice(action.data.updateData.scriptIndex),
                                        //];
                                    }
                                }
                            })
                            return nextStateAddNarrativeScriptRow
                    }
                    break
            }
            return state

        case autoCourseCreationConstants.ACC_LANGUAGE_UPDATE_DATA.FAILURE:
            return {
                ...state,
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.REQUEST:
            const nextStateLessons = produce(state, (draftState) => {
                let temp2IsCreatingFromLessonPlans = draftState.isCreatingFromLessonPlanIds
                    ? draftState.isCreatingFromLessonPlanIds
                    : []
                if (action.payload.languageLessonPlanDto.lessonPlanId) {
                    if (
                        temp2IsCreatingFromLessonPlans.filter(
                            (x) => x == action.payload.languageLessonPlanDto.lessonPlanId
                        ).length == 0
                    ) {
                        temp2IsCreatingFromLessonPlans.push(action.payload.languageLessonPlanDto.lessonPlanId)
                    }
                }
                draftState.isCreatingFromLessonPlanIds = temp2IsCreatingFromLessonPlans
            })
            return nextStateLessons

        case autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.SUCCESS:
            const nextStateLessonsSuccess = produce(state, (draftState) => {
                draftState.languageSectionPlanDto.languageLessonPlanDtos.forEach((languageLessonPlan) => {
                    if (languageLessonPlan.lessonPlanId === action.data.lessonPlanId) {
                        if (languageLessonPlan.createdLessonIds) {
                            languageLessonPlan.createdLessonIds.push(action.data.customLessonId)
                        } else {
                            languageLessonPlan.createdLessonIds = [action.data.customLessonId]
                        }
                        languageLessonPlan.majorChanges = false
                        languageLessonPlan.lastChange = undefined
                    }
                })
                draftState.isCreatingFromLessonPlanIds = draftState.isCreatingFromLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                )
            })
            return nextStateLessonsSuccess

        case autoCourseCreationConstants.ACC_LANGUAGE_LESSON_GENERATION.FAILURE:
            return {
                ...state,
                isCreatingFromLessonPlanIds: state.isCreatingFromLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                ),
            }

        case autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.REQUEST:
            const nextStateLessons2 = produce(state, (draftState) => {
                let temp2IsCreatingFromLessonPlans = draftState.isCreatingFromLessonPlanIds
                    ? draftState.isCreatingFromLessonPlanIds
                    : []
                if (action.payload.languageLessonPlanDto.lessonPlanId) {
                    if (
                        temp2IsCreatingFromLessonPlans.filter(
                            (x) => x == action.payload.languageLessonPlanDto.lessonPlanId
                        ).length == 0
                    ) {
                        temp2IsCreatingFromLessonPlans.push(action.payload.languageLessonPlanDto.lessonPlanId)
                    }
                }
                draftState.isCreatingFromLessonPlanIds = temp2IsCreatingFromLessonPlans
            })
            return nextStateLessons2

        case autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.SUCCESS:
            const nextStateLessonsSuccess2 = produce(state, (draftState) => {
                const previousCreatedLessonIds = []

                draftState.languageSectionPlanDto.languageLessonPlanDtos.forEach((languageLessonPlan) => {
                    if (
                        languageLessonPlan.lessonPlanId === action.data.lessonPlanId &&
                        languageLessonPlan.createdLessonIds?.length > 0
                    ) {
                        languageLessonPlan.createdLessonIds.forEach((x) => previousCreatedLessonIds.push(x))
                    }
                })
                // swap out the language lesson plan dto
                previousCreatedLessonIds.push(action.data.customLessonId)

                const newLangaugeLessonPlanDto = action.data.languageLessonPlanDto
                newLangaugeLessonPlanDto.createdLessonIds = previousCreatedLessonIds

                newLangaugeLessonPlanDto.majorChanges = false
                newLangaugeLessonPlanDto.lastChange = undefined

                const replaceAtIndex = draftState.languageSectionPlanDto.languageLessonPlanDtos.findIndex(
                    (languageLessonPlan) => languageLessonPlan.lessonPlanId === newLangaugeLessonPlanDto.lessonPlanId
                )
                if (replaceAtIndex > -1) {
                    draftState.languageSectionPlanDto.languageLessonPlanDtos[replaceAtIndex] = newLangaugeLessonPlanDto
                }

                draftState.isCreatingFromLessonPlanIds = draftState.isCreatingFromLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                )
            })
            return nextStateLessonsSuccess2

        case autoCourseCreationConstants.ACC_LANGUAGE_RECREATE_SINGLE_LESSON_PLAN.FAILURE:
            return {
                ...state,
                isCreatingFromLessonPlanIds: state.isCreatingFromLessonPlanIds.filter(
                    (x) => x !== action.data.lessonPlanId
                ),
            }

        case authConstants.LOGOUT.SUCCESS:
            return initialState

        default:
            return state
    }
}
