import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as Edit } from '../Images/edit.svg'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width: '828px',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'inline-flex',
    },
    mainTextContainer: {
        width: '100%',
        height: '100%',
        borderBottom: '1px #D7DDE4 solid',
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'inline-flex',
    },
    mainText: {
        color: '#394553',
        fontSize: 14,
        fontFamily: 'Rubik',
        fontWeight: '500',
        textTransform: 'uppercase',
        letterSpacing: 0.42,
        wordWrap: 'break-word',
    },
    subSectionContainer: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'inline-flex',
        padding: 16,
    },
    subSectionTitleText: {
        width: '100%',
        color: '#232B35',
        fontSize: 20,
        fontFamily: 'Rubik',
        fontWeight: '500',
        wordWrap: 'break-word',
    },
    subSectionExplanationText: {
        width: 822,
        color: '#232B35',
        fontSize: 16,
        fontFamily: 'Rubik',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    subSectionTextAndEditContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    editButton: {
        cursor: 'pointer',
    },
}))

const TeachingMaterialSectionACC = (props) => {
    // props.sectionDto
    // props.onEdit

    const classes = useStyles()

    return (
        <div className={classes.mainContainer}>
            <div className={classes.mainTextContainer}>
                <Typography className={classes.mainText}>{props.sectionDto.title}</Typography>
            </div>
            {props.sectionDto?.concepts?.map((concept, index) => (
                <div className={classes.subSectionContainer} key={concept.conceptId}>
                    <div className={classes.subSectionTextAndEditContainer}>
                        <Typography className={classes.subSectionTitleText}>{concept.text}</Typography>
                        <Edit
                            className={classes.editButton}
                            onClick={() => props.onEdit(true, concept, props.sectionDto)}
                        />
                    </div>
                    <Typography className={classes.subSectionExplanationText}>{concept.explanation}</Typography>
                </div>
            ))}
        </div>
    )
}

TeachingMaterialSectionACC.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TeachingMaterialSectionACC)
