import { topicConstants } from '../actions'
import { authConstants } from '../actions'

const initialState = {
    error: '',
    topics: [],
    creatingTopic: false,
    notifyPublished: false,
    usergroupsForTopic: [],
    cloneCompleted: true,
    clonedCourseId: null,
    sharedCourses: [],
    currentTopicId: null,
    loadingCurrentTopic: false,
    currentTopicRequestedL1s: [],
    topicDifficulties: [],
    lessonOrder: [],

    fetchingCurrentLessonOrder: false,
    exportingTranslations: false,
    topicLessonOrderIndex: {},
}

export function topic(state = initialState, action) {
    switch (action.type) {
        case topicConstants.PUBLISH_TOPIC.REQUEST:
            return {
                ...state,
                notifyPublished: false,
            }
        case topicConstants.PUBLISH_TOPIC.SUCCESS:
            return {
                ...state,
                notifyPublished: true,
            }
        case topicConstants.PUBLISH_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.CLONE_COURSE.REQUEST:
            return {
                ...state,
            }
        case topicConstants.CLONE_COURSE.SUCCESS:
            return {
                ...state,
                cloneCompleted: true,
                clonedCourseId: action.data.id,
            }
        case topicConstants.CLONE_COURSE.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.UPDATE_TOPIC.REQUEST:
            return {
                ...state,
            }
        case topicConstants.UPDATE_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.UPDATE_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.CREATE_TOPIC.REQUEST:
            return {
                ...state,
                creatingTopic: true,
            }
        case topicConstants.CREATE_TOPIC.SUCCESS:
            return {
                ...state,
                creatingTopic: false,
                topics: [...state.topics, action.data],
            }
        case topicConstants.CREATE_TOPIC.FAILURE:
            return {
                ...state,
                creatingTopic: false,
                error: action.message,
            }
        case topicConstants.GET_ALL_TOPICS.REQUEST:
            return {
                ...state,
                loadingTopics: true,
            }
        case topicConstants.GET_ALL_TOPICS.SUCCESS:
            return {
                ...state,
                loadingTopics: false,
                topics: action.data.topics,
            }
        case topicConstants.GET_ALL_TOPICS.FAILURE:
            return {
                ...state,
                loadingTopics: false,
                error: action.message,
            }
        case topicConstants.ADD_SUPPORTED_LANGUAGE.REQUEST:
            return {
                ...state,
            }
        case topicConstants.ADD_SUPPORTED_LANGUAGE.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.ADD_SUPPORTED_LANGUAGE.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.UPDATE_TOGGLE.SUCCESS:
            return {
                ...state,
            }

        case topicConstants.DELETE_TOPIC.REQUEST:
            return {
                ...state,
            }
        case topicConstants.DELETE_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.DELETE_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.CLEAR_TOPIC_DATA.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.SUCCESS:
            return {
                ...state,
                usergroupsForTopic: action.data,
            }
        case topicConstants.GET_ALL_USERGROUPS_FOR_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
                usergroups: [],
            }

        case topicConstants.GET_TOPIC.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_TOPIC.SUCCESS: {
            let topicsCopy = []
            const map = new Map()
            for (const item of state.topics) {
                if (!map.has(item.id)) {
                    map.set(item.id, true)
                    topicsCopy.push(item)
                }
            }
            const existingTopicIndex = topicsCopy.findIndex((x) => x.id == action.data.id)
            if (existingTopicIndex >= 0) {
                topicsCopy[existingTopicIndex] = action.data
            } else {
                topicsCopy.push(action.data)
            }

            return {
                ...state,
                topics: topicsCopy,
            }
        }
        case topicConstants.GET_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_SHARABLE_COURSES.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_SHARABLE_COURSES.SUCCESS:
            return {
                ...state,
                sharedCourses: action.data,
            }
        case topicConstants.GET_SHARABLE_COURSES.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        case topicConstants.GET_CURRENT_TOPIC.REQUEST:
            return {
                ...state,
                loadingCurrentTopic: true,
                currentTopicId: null,
            }
        case topicConstants.GET_CURRENT_TOPIC.SUCCESS:
            let topicsCopy = []
            const map = new Map()
            for (const item of state.topics) {
                if (!map.has(item.id)) {
                    map.set(item.id, true)
                    topicsCopy.push(item)
                }
            }
            const existingTopicIndex = topicsCopy.findIndex((x) => x.id == action.data.id)
            if (existingTopicIndex >= 0) {
                topicsCopy[existingTopicIndex] = action.data
            } else {
                topicsCopy.push(action.data)
            }

            return {
                ...state,
                topics: topicsCopy,
                currentTopicId: action.data.id,
                loadingCurrentTopic: false,
            }
        case topicConstants.GET_CURRENT_TOPIC.FAILURE:
            return {
                ...state,
                loadingCurrentTopic: false,
                error: action.message,
            }

        case topicConstants.UPDATE_LOCAL_COURSE_NAME.REQUEST:
            return {
                ...state,
            }
        case topicConstants.UPDATE_LOCAL_COURSE_NAME.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.UPDATE_LOCAL_COURSE_NAME.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        case topicConstants.GET_REQUESTED_L1S.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_REQUESTED_L1S.SUCCESS:
            return {
                ...state,
                currentTopicRequestedL1s: action.data,
            }
        case topicConstants.GET_REQUESTED_L1S.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.CHANGE_DESCRIPTION.REQUEST:
            return {
                ...state,
            }
        case topicConstants.CHANGE_DESCRIPTION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.CHANGE_DESCRIPTION.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        case topicConstants.GET_TOPIC_DIFFICULTIES.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_TOPIC_DIFFICULTIES.SUCCESS:
            return {
                ...state,
                topicDifficulties: action.data,
            }
        case topicConstants.GET_TOPIC_DIFFICULTIES.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        case topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.REQUEST:
            return {
                ...state,
                fetchingCurrentLessonOrder: true,
                currentTopicEmpty: false,
            }
        case topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS:
            return {
                ...state,
                lessonOrder: action.data,
                fetchingCurrentLessonOrder: false,
                currentTopicEmpty: action.data?.length === 0,
            }
        case topicConstants.GET_LESSON_ORDER_FOR_DIFFICULTY.FAILURE:
            return {
                ...state,
                error: action.message,
                fetchingCurrentLessonOrder: false,
            }

        case topicConstants.GET_LESSON_ORDER_FOR_INDEXING.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_LESSON_ORDER_FOR_INDEXING.SUCCESS:
            let topicLessonOrderIndex = state.topicLessonOrderIndex ? state.topicLessonOrderIndex : {}
            topicLessonOrderIndex[action.data.topicId] = action.data.topicLessonOrder
            return {
                ...state,
                topicLessonOrderIndex: topicLessonOrderIndex,
            }
        case topicConstants.GET_LESSON_ORDER_FOR_INDEXING.FAILURE:
            return {
                ...state,
            }

        case topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.REQUEST:
            return {
                ...state,
                fetchingCurrentLessonOrder: true,
            }
        case topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.SUCCESS:
            return {
                ...state,
                lessonOrder: action.data,
                fetchingCurrentLessonOrder: false,
            }
        case topicConstants.UPDATE_LESSON_ORDER_FOR_DIFFICULTY.FAILURE:
            return {
                ...state,
                error: action.message,
                fetchingCurrentLessonOrder: false,
            }

        case topicConstants.UPDATE_LOCAL_LESSON_ORDER.REQUEST:
            return {
                ...state,
                error: action.message,
            }
        case topicConstants.UPDATE_LOCAL_LESSON_ORDER.SUCCESS:
            return {
                ...state,
                lessonOrder: action.data.payload.lessonArray,
            }
        case topicConstants.UPDATE_LOCAL_LESSON_ORDER.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.REQUEST:
            return {
                ...state,
            }

        case topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.ATTACH_CUSTOM_LESSON_TO_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.PUBLISH_TOPIC_LESSON.REQUEST:
            return {
                ...state,
            }

        case topicConstants.PUBLISH_TOPIC_LESSON.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.PUBLISH_TOPIC_LESSON.FAILURE:
            return {
                ...state,
                error: action.message,
            }


        case topicConstants.ATTACH_MANY_CUSTOM_LESSON_TO_TOPIC.REQUEST:
            return {
                ...state,
            }

        case topicConstants.ATTACH_MANY_CUSTOM_LESSON_TO_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.ATTACH_MANY_CUSTOM_LESSON_TO_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.REQUEST:
            return {
                ...state,
            }

        case topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.IMPORT_EXCEL_TOPIC_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.REQUEST:
            return {
                ...state,
            }

        case topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EXPORT_EXCEL_TOPIC_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.REQUEST:
            return {
                ...state,
            }

        case topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EXPORT_EXCEL_ALL_LANGUAGES_TOPIC_TRANSLATION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.REQUEST:
            return {
                ...state,
            }
        case topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EXPORT_SENTENCE_SUGGESTIONS_FROM_FILE.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.SUCCESS:
            return {
                ...state,
                topicDataPickerForLessonData: action.data,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_LESSON_DATA.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.SUCCESS:
            return {
                ...state,
                topicDataPickerForNLP: action.data,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.SUCCESS:
            return {
                ...state,
                topicDataPickerForNLPDistractors: action.data,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_NLP_DISTRACTORS.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.REQUEST:
            return {
                ...state,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.SUCCESS:
            return {
                ...state,
                topicDataPickerForSentenceSuggestion: action.data,
            }
        case topicConstants.GET_TOPIC_DATA_PICKER_FOR_SENTENCE_SUGGESTION.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case topicConstants.EDIT_TOPIC_RESET.REQUEST:
            return {
                ...state,
                creatingTopic: false,
                clonedCourseId: null,
                cloneCompleted: false,
                topicDifficulties: [],
                lessonOrder: [],
                topicLessonOrderIndex: {},
                currentTopicId: null,
                currentTopicEmpty: false,
            }
        case topicConstants.EDIT_TOPIC_RESET.SUCCESS:
            return {
                ...state,
            }
        case topicConstants.EDIT_TOPIC_RESET.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case authConstants.LOGOUT.SUCCESS:
            return initialState
        default:
            return state
    }
}
