import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    licenseCard: {
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.25)',
        height: '190px',
        marginBottom: '20px',
    },
    licenseBox: {
        borderRadius: '1em',
        height: '70px',
        flow: 'right',
        marginTop: '15px',
    },
    mainSection: {
        marginBottom: '20px',
    },
    licenseBoxHeader: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#000',
        textAlign: 'center',
        align: 'center',
    },
    licenseBoxNumber: {
        fontSize: '24px',
        fontWeight: 600,
        color: '#000',
        textAlign: 'center',
    },
}))
const OrganizationLicenses = (props) => {
    const classes = useStyles()

    const [numberOfLicenses, setNumberOfLicenses] = useState(0)
    const [numberOfUsedLicenses, setNumberOfUsedLicenses] = useState(0)
    const [remainingLicenses, setRemainingLicenses] = useState(0)
    const [showLicenseBox, setShowLicenseBox] = useState(false)

    useEffect(() => {
        let currentOrganization

        if (props.organization.myOrganizations && props.organization.selectedOrganization)
            currentOrganization = props.organization.myOrganizations.find(
                (x) => x.id === props.organization.selectedOrganization
            )

        const tempNumberOfLicenses =
            currentOrganization?.numberOfLicenses && currentOrganization.numberOfLicenses > 0
                ? currentOrganization.numberOfLicenses
                : 0
        const tempNumberOfUsedLicenses = currentOrganization?.usedLicenses ? currentOrganization.usedLicenses : 0

        let tempRemainingLicenses = tempNumberOfLicenses - tempNumberOfUsedLicenses

        if (tempRemainingLicenses < 0) {
            tempRemainingLicenses = 0
        }

        let tempShowLicenseBox = true
        if (
            (props.user.organizationRole === 'reporting' && props.user.classroomOnlyReportingUser) ||
            tempNumberOfLicenses === 0
        ) {
            tempShowLicenseBox = false
        }

        setNumberOfUsedLicenses(tempNumberOfUsedLicenses)
        setNumberOfLicenses(tempNumberOfLicenses)
        setRemainingLicenses(tempRemainingLicenses)
        setShowLicenseBox(tempShowLicenseBox)
    }, [])

    return (
        <div className={classes.root}>
            <OrganizationOverviewSidebar path={props.computedMatch?.path} />

            <Grid container spacing={5}>
                <Grid item xs={12} sm={6} style={{ marginTop: '10px' }}>
                    {showLicenseBox && (
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.mainSection}>
                                        <FormattedMessage
                                            id="dashboard.license.title"
                                            defaultMessage="Licensing information"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={11} sm={12} className={classes.licenseCard}>
                                <Grid container spacing={1}>
                                    <Grid item sm={4} className={classes.licenseBox}>
                                        <Grid item sm={12}>
                                            <Typography
                                                className={classes.licenseBoxHeader}
                                                style={{ marginLeft: '20px' }}>
                                                <FormattedMessage
                                                    id="dashboard.license.totallicenses"
                                                    defaultMessage="Total licenses"
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Typography
                                                className={classes.licenseBoxNumber}
                                                style={{ marginLeft: '20px' }}>
                                                {numberOfLicenses}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item sm={3} className={classes.licenseBox}>
                                        <Grid item sm={12}>
                                            <Typography className={classes.licenseBoxHeader}>
                                                <FormattedMessage
                                                    id="dashboard.license.usedlicenses"
                                                    defaultMessage="Used licenses"
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Typography className={classes.licenseBoxNumber}>
                                                {numberOfUsedLicenses}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item sm={3} className={classes.licenseBox}>
                                        <Grid item sm={12}>
                                            <Typography className={classes.licenseBoxHeader}>
                                                <FormattedMessage
                                                    id="dashboard.license.remaininglicenses"
                                                    defaultMessage="Remaining licenses"
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Typography className={classes.licenseBoxNumber}>
                                                {remainingLicenses}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                                    <div className={classes.divider} />
                                </Grid>

                                <Grid item xs={12} sm={12} style={{ marginTop: '15px' }}>
                                    <Typography variant="h6" style={{ marginLeft: '20px' }}>
                                        <FormattedMessage
                                            id="dashboard.license.needmorelicenses"
                                            defaultMessage="Do you need more licenses?"
                                        />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Typography style={{ marginLeft: '20px' }}>
                                        <FormattedMessage
                                            id="dashboard.license.contactus"
                                            defaultMessage="Contact us at sales@capeesh.com or call Sales at +47 92409439"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLicenses)
