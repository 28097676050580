import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Switch } from '@material-ui/core'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'black',
        color: 'black',
    },
    body: {
        fontSize: 28,
    },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
    root: {
        //width: 300,
    },
    chip: {
        marginRight: '5px',
        marginBottom: '5px',
    },
    scenario: {
        paddingTop: '30px',
    },
    textFields: {
        marginBottom: '5px',
    },
    slider: {
        marginLeft: '20px',
        marginRight: '50px',
    },
}))

const SentenceBuilderSlider = withStyles({
    root: {
        color: '#143349',
        height: 8,
        marginLeft: '10px',
        marginRight: '10px',
    },
    thumb: {
        height: 24,
        width: 24,
        background: '#F8F8F8',
        border: '7px solid #143349',
        boxSizing: 'border-box',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider)

const CapeeshHighlighter = (props) => {
    const classes = useStyles()
    const [value, setValue] = useState(2)
    const [firstWordInRange, setFirstWordInRange] = React.useState([0, 0])
    const [selectionStart, setSelectionStart] = React.useState(-1)
    const [selectionEnd, setSelectionEnd] = React.useState(-1)
    const [isSelection, setIsSelection] = React.useState(false)
    const [highlighter, setHighlighter] = React.useState([])
    const [newAnswer, setNewAnswer] = useState('')

    const [prevStartSelection, setPrevStartSelection] = React.useState(-1)
    const [prevEndSelection, setPrevEndSelection] = React.useState(-1)

    const editor = useRef(null)
    const [editorPlaceHolder, setEditorPlaceHolder] = React.useState(props.placeholder)

    // useEffect(() => {
    //     handleToggle(value, "props")
    // }, [props.text])

    useEffect(() => {
        setHighlighter(highlightIterator(props.alternatives))
    }, [props.alternatives])

    const firstInRange = (localStartIndex, localEndIndex) => {
        let firstInRangeStartIndex = localStartIndex
        let firstInRangeEndIndex = localEndIndex
        let isFound = false
        props.testOption.alternatives.forEach((alternative) => {
            if (
                !!!isFound &&
                localStartIndex >= alternative.startIndex &&
                localEndIndex <= alternative.startIndex + alternative.text.length
            ) {
                firstInRangeStartIndex = alternative.startIndex
                firstInRangeEndIndex = alternative.startIndex + alternative.text.length
                isFound = true
            }
        })
        return [firstInRangeStartIndex, firstInRangeEndIndex]
    }

    const checkExistingAlternative = (alternatives, selectionStart, selectionEnd) => {
        let isAvailableWord = true
        alternatives.forEach((alternative) => {
            if (
                selectionStart > alternative.startIndex &&
                selectionStart <= alternative.startIndex + alternative.text.length
            ) {
                isAvailableWord = false
            }
            if (
                selectionStart < alternative.startIndex &&
                selectionEnd > alternative.startIndex + alternative.text.length &&
                selectionEnd - selectionStart >
                    alternative.startIndex + alternative.text.length - alternative.startIndex
            ) {
                isAvailableWord = false
            }
            if (
                selectionEnd > alternative.startIndex &&
                selectionEnd < alternative.startIndex + alternative.text.length
            ) {
                isAvailableWord = false
            }
        })
        return isAvailableWord
    }

    const highlightIterator = (alternatives) => {
        let highlightArray = []
        let addedStartIndeces = []
        alternatives.forEach((alternative) => {
            if (!!!addedStartIndeces.includes(alternative.startIndex)) {
                highlightArray.push([alternative.startIndex, alternative.startIndex + alternative.text.length])
                addedStartIndeces.push(alternative.startIndex)
            }
        })
        return highlightArray
    }

    const isValidAlternativeToDisplay = (alternative) => {
        return (
            selectionStart >= alternative.startIndex &&
            selectionStart <= alternative.startIndex + alternative.text.length
        )
        //return selectionStart >= firstWordInRange[0] && selectionStart <= (firstWordInRange[1])
    }

    const handleHightlightAreaClicked = () => {
        if (editor && !props.disabled) {
            setEditorPlaceHolder('')
            editor.current.focus()
        }
    }

    const highLightOnChange = (event, selection) => {
        if (selection?.start === 0 && selection?.end === 0) {
            return
        }
        let hasNewSelection = true
        if (selection?.start === prevStartSelection && selection?.end === prevEndSelection) {
            hasNewSelection = false
        } else {
            setPrevStartSelection(selection?.start)
            setPrevEndSelection(selection?.end)
        }

        setValue(event)
        setFirstWordInRange(firstInRange(selection?.start, selection?.end))
        let firstInRangeStartEnd = firstInRange(selection?.start, selection?.end)

        setSelectionStart(firstInRangeStartEnd[0])
        setSelectionEnd(firstInRangeStartEnd[1])

        if (selection?.end - selection?.start > 0) {
            setIsSelection(checkExistingAlternative(props.testOption.alternatives, selection?.start, selection?.end))
        } else {
            setIsSelection(false)
        }
    }

    return (
        <div style={{ border: 'none', width: '450px' }} onClick={handleHightlightAreaClicked}>
            <HighlightWithinTextarea
                disabled={props.disabled}
                fullWidth
                ref={editor}
                multiline="true"
                placeholder={editorPlaceHolder}
                value={props.value}
                highlight={highlighter}
                onChange={highLightOnChange}
                onBlur={(event) => {
                    event.stopPropagation()
                }}
                autoCorrect={'false'}
                autoComplete={'false'}
                autoCapitalize={'false'}
                readOnly={props.disabled}
            />
            {isSelection && (
                <React.Fragment>
                    <Button
                        disabled={props.disabled}
                        variant="outlined"
                        size="small"
                        onClick={(event) => {
                            props.onAddAlternative(
                                selectionStart,
                                props.testOption.text.substring(selectionStart, selectionEnd),
                                props.testOption
                            )
                        }}
                        className={classes.button}>
                        Add distractor test
                    </Button>
                    <Button disabled={props.disabled} variant="outlined" size="small" className={classes.button}>
                        Add keyboard test
                    </Button>
                </React.Fragment>
            )}
            <React.Fragment>
                <Button
                    disabled={props.disabled}
                    onClick={() => props.onAddDistractorWithinSentence(newAnswer)}
                    style={{}}
                    variant="outlined">
                    Add
                </Button>

                <TableContainer>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Word</StyledTableCell>
                                <StyledTableCell>Correct</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.alternatives
                                .sort((a, b) => a.text > b.text)
                                .map(
                                    (alternative, index) =>
                                        isValidAlternativeToDisplay(alternative) && (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Input
                                                        value={alternative.text}
                                                        //name={name}
                                                        //onChange={e => onChange(e.target.value, alternative)}
                                                        className={classes.input}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        //checked={alternative.correct === true }
                                                        //onChange={e => onChange(e.target.value, alternative)}

                                                        //onChange={e => handleChangeCorrect(alternative)}
                                                        color="primary"
                                                        name="checkedB"
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        </div>
    )
}

CapeeshHighlighter.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(CapeeshHighlighter)
