import React, { useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import Dropzone from 'react-dropzone'

import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    cardPlayButton: {
        height: '40px',
        backgroundColor: '#143349',
        display: 'flex',
        justify: 'flex-start',
        alignItems: 'center',
        width: '363px',
    },
    scenarioDescriptionText: {
        color: '#8e8e93',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    closeIcon: {
        color: 'white',
        paddingRight: '5px',
        cursor: 'pointer',
    },

    circularProgress: {
        color: '#eee',
        fontSize: 36,
        margin: '15px',
    },

    dropZone: {
        position: 'relative',
        marginTop: '10px',
        marginLeft: '10px',
        height: '115px',
        width: '500px',
        paddingRight: '10px',
        color: '#ccc',
        border: 'dashed 2px #ccc',
        borderRadius: '15px',
        backgroundColor: '#f9f9f9',
        transition: 'all 200ms ease-out',
        justifycontent: 'center',
        alignItems: 'center',
        display: 'block',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },

    dropzoneContainer: {
        marginTop: '20px',
    },
}))

const AudioFileUpload = (props) => {
    const classes = useStyles()
    const [newAnswer, setNewAnswer] = useState('')

    return (
        <Dropzone accept="audio/mp3,audio/mpeg,audio/mpeg3" onDrop={(file) => props.onVoiceDrop(file, null)}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()} className={classes.dropZone}>
                        <input {...getInputProps()} />
                        {
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: '#35323C',
                                            marginTop: '10px',
                                        }}>
                                        Drag your voice audio file here
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="body1" style={{ color: '#35323C' }}>
                                        Or
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        style={{
                                            textTransform: 'none',
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            background: '#FFFFFF',
                                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            borderRadius: '10px',
                                            marginBottom: '15px',
                                        }}>
                                        Browse files
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </section>
            )}
        </Dropzone>
    )
}

AudioFileUpload.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(AudioFileUpload)
