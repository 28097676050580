import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { userActions } from '../../../_state/actions'
import CourseViewCard from '../../../Components/CourseView/CourseViewCard'
import { Grid, Typography } from '@material-ui/core'
import { CapeeshColors } from '../../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({}))

const StudentCourseProgression = (props) => {
    // expected props:

    // props.organizationId
    // props.userId
    // props.onCourseGroupOpen
    /// props.dispatch
    const classes = useStyles()

    const [userCourseGroups, setUserCourseGroups] = useState([])
    const [userCourseGroupProgressions, setUserCourseGroupProgressions] = useState([])

    const [hasFetchedProgressions, setHasFetchedProgressions] = useState(false)
    const [fetchProgressions, setFetchProgressions] = useState(false)

    useEffect(() => {
        props.dispatch(userActions.getUserCourseGroups(props.organizationId, props.userId))
        props.dispatch(userActions.getUserLessonProgressions(props.organizationId, props.userId))

        updateUserCourseGroups()
        updateUserCourseGroupProgressions()
    }, [])

    useEffect(() => {
        updateUserCourseGroups()
    }, [props.user])

    useEffect(() => {
        updateUserCourseGroupProgressions()
    }, [props.user])

    const updateUserCourseGroupProgressions = () => {
        if (
            props.user?.userIdToCourseGroupProgressionMapper &&
            props.userId in props.user?.userIdToCourseGroupProgressionMapper &&
            !userCourseGroupProgressions?.length > 0
        ) {
            setUserCourseGroupProgressions(props.user.userIdToCourseGroupProgressionMapper[props.userId])
        } else {
            setUserCourseGroupProgressions([])
        }
    }

    const updateUserCourseGroups = () => {
        if (props.user?.userIdToCourseGroupMapper && props.userId in props.user?.userIdToCourseGroupMapper) {
            setUserCourseGroups(props.user.userIdToCourseGroupMapper[props.userId])
            setFetchProgressions(true)
        } else {
            setUserCourseGroups([])
        }
    }

    useEffect(() => {
        if (fetchProgressions && !hasFetchedProgressions) {
            let counter = 0
            props.user.userIdToCourseGroupMapper[props.userId].forEach((x) => {
                props.dispatch(userActions.getUserCourseGroupProgression(props.organizationId, props.userId, x.id))
                counter++
            })

            if (counter > 0) {
                setHasFetchedProgressions(true)
            }
        }
    }, [fetchProgressions])

    const handleCourseGroupClicked = (courseGroupId) => {
        props.onCourseGroupOpen(courseGroupId)
    }

    return (
        <div
            style={{
                borderRadius: '16px',
                background: '#F8F8F8',
                padding: '24px',
            }}>
            <div>
                <div style={{ flexDirection: 'column' }}>
                    <Typography
                        style={{
                            color: '#35323C',
                            fontFamily: 'Rubik',
                            FontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                        }}>
                        Course progression
                    </Typography>
                    <Typography
                        style={{
                            color: '#6E6C71',
                            fontFamily: 'Rubik',
                            FontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                        }}>
                        Shows progress on each individual course
                    </Typography>
                </div>
            </div>
            <Grid container>
                {props.user?.userIdToCourseGroupMapper &&
                    props.userId in props.user?.userIdToCourseGroupMapper &&
                    props.user.userIdToCourseGroupMapper[props.userId]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((courseGroup, index) => (
                            <div style={{ padding: '12px' }}>
                                <CourseViewCard
                                    courseGroup={courseGroup}
                                    index={index}
                                    openCourseGroup={handleCourseGroupClicked}
                                    showProgression={true}
                                    courseGroupProgression={
                                        userCourseGroupProgressions && courseGroup.id in userCourseGroupProgressions
                                            ? userCourseGroupProgressions[courseGroup.id]
                                            : null
                                    }
                                    handleRequestAccess={null}
                                />
                            </div>
                        ))}
            </Grid>
        </div>
    )
}

StudentCourseProgression.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(StudentCourseProgression)
