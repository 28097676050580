import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import Card from '@material-ui/core/Card'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getHighContrastPieChartColorForIndex } from '../../assets/ColorLogic'
import { FormattedMessage } from 'react-intl'

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    if (percent * 100 < 5) return null

    const radius = innerRadius + (outerRadius - innerRadius) * 0.55
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'middle' : 'middle'}
            dominantBaseline="central"
            style={{ zIndex: 10 }}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

const DayOfTheWeekPieChart = (props) => {
    const [activeIndex, setActiveIndex] = useState(-1)

    const onPieEnter = (data, index) => {
        setActiveIndex(index)
    }

    const onPieExit = (data, index) => {
        if (activeIndex !== -1) setActiveIndex(-1)
    }

    return (
        <Card style={{ overflow: 'visible' }}>
            <CardHeader
                title={<FormattedMessage id="dashboard.graphs.dayofweek.title" defaultMessage="Day of Week" />}
                subheader={
                    props.canShowChart ? (
                        <FormattedMessage
                            id="dashboard.graphs.dayofweek.subheader.withdata"
                            defaultMessage="Shows which days of the week the users are most active"
                        />
                    ) : (
                        <FormattedMessage
                            id="dashboard.graphs.dayofweek.subheader.missingdata"
                            defaultMessage="Add more users to see the day of week chart"
                        />
                    )
                }
            />
            <CardContent>
                <Grid item xs={12}>
                    {props.canShowChart && (
                        <div style={{ width: '100%', height: 350, display: 'flex', justifyContent: 'center' }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={props.dayOfWeekChartData}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        innerRadius={50}
                                        outerRadius={125}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={onPieEnter}
                                        onMouseLeave={onPieExit}>
                                        {props.dayOfWeekChartData.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={getHighContrastPieChartColorForIndex(index, activeIndex)}
                                            />
                                        ))}
                                    </Pie>
                                    <Legend verticalAlign="bottom" height={36} />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DayOfTheWeekPieChart)
