import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationOverviewSidebar from './Components/OrganizationOverviewSidebar'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import StudentListCard from '../Students/StudentListCard'
import { UserStatisticsVisibility } from '../CapeeshConstants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { organizationActions } from '../../../_state/actions'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { history } from '../../../_state/helpers'
import CapeeshStyledModal from '../../../Components/CapeeshStyledModal'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '300px',
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
        marginTop: '10px',
    },
}))

const OrganizationStudentsOverview = (props) => {
    const classes = useStyles()

    const [classroomUsers, setClassroomUsers] = useState(undefined)
    const [showUserUsageStatistics, setShowUserUsageStatistics] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [orderBy, setOrderBy] = useState('')
    const [orderByOptions, setOrderByOptions] = useState([])
    const [showOnlySignedUp, setShowOnlySignedUp] = useState(false)
    const [orderByOptionsFiltered, setOrderByOptionsFiltered] = useState([])
    const [orderByFiltered, setOrderByFiltered] = useState(null)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [userIdToDelete, setUserIdToDelete] = useState(null)

    const handleDeleteUserClicked = (event, userId) => {
        event.stopPropagation()
        event.preventDefault()

        setUserIdToDelete(userId)

        setShowDeleteConfirmation(true)
    }

    const handleDeleteUser = (event) => {
        event.stopPropagation()
        event.preventDefault()

        props.dispatch(
            organizationActions.deleteOrganizationUser(props.organization.selectedOrganization, userIdToDelete, null)
        )

        setShowDeleteConfirmation(false)
    }

    const StudentOrdering = () => {
        let tempClassroomUsers = []
        if (props.organization.organizationUsers) {
            tempClassroomUsers = removeDuplicates(props.organization.organizationUsers, 'id')
        }

        var isQaUser = props.user.organizationRole === 'qa'
        var isAdminuser = props.user.organizationRole === 'admin' || props.user.organizationRole === 'teacher'

        if (isQaUser) {
            tempClassroomUsers = classroomUsers.filter((x) => x.email === props.user.info?.email)
        } else if (
            !isAdminuser ||
            (!props.user.info.email.includes('@itsoundsgood.no') && !props.user.info.email.includes('@capeesh.com'))
        ) {
            tempClassroomUsers = tempClassroomUsers.filter((x) => !x.email.endsWith('@itsoundsgood.no'))
            tempClassroomUsers = tempClassroomUsers.filter((x) => !x.email.endsWith('@capeesh.com'))
        }

        if (searchInput.length > 0) {
            tempClassroomUsers = tempClassroomUsers.filter(
                (x) =>
                    x.email.toLowerCase().includes(searchInput.toLowerCase()) ||
                    x.name.toLowerCase().includes(searchInput.toLowerCase())
            )
        }
        if (showOnlySignedUp) {
            tempClassroomUsers = tempClassroomUsers.filter((x) => x.signUpDate != '0001-01-01T00:00:00+00:00')
        }

        let tempOrderByFiltered = orderBy
        let tempOrderByOptionsFiltered = orderByOptions

        if (classroomUsers) {
            switch (orderBy) {
                case 'Last active':
                    tempClassroomUsers = tempClassroomUsers.sort(
                        (a, b) => new Date(b.lastActive) - new Date(a.lastActive)
                    )
                    break
                case 'Name':
                    tempClassroomUsers = tempClassroomUsers.sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    break
                case 'Language points':
                    tempClassroomUsers = tempClassroomUsers.sort((a, b) => b.experience - a.experience)
                    break
                case 'Time spent':
                    tempClassroomUsers = tempClassroomUsers.sort((a, b) => b.appUseSeconds - a.appUseSeconds)
                    break
            }
        }

        setOrderByOptionsFiltered(tempOrderByOptionsFiltered)
        setOrderByFiltered(tempOrderByFiltered)
        setClassroomUsers(tempClassroomUsers)
    }

    const removeDuplicates = (myArr, prop) => {
        if (!myArr || myArr.length === 0) {
            return []
        }
        return myArr.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos
        })
    }

    const editStudent = (userId) => {
        history.push('/dashboard/students/user/' + userId)
    }

    useEffect(() => {
        props.dispatch(organizationActions.getOrganizationUsers(props.organization.selectedOrganization))

        let tempShowUserUsageStatistics = false
        let currentOrganization = props.organization.myOrganizations.find(
            (x) => x.id === props.organization.selectedOrganization
        )
        if (
            props.user.organizationRole !== 'reporting' ||
            currentOrganization?.userStatisticsVisibility === UserStatisticsVisibility.SchoolMode
        ) {
            tempShowUserUsageStatistics = true
        }

        setShowUserUsageStatistics(tempShowUserUsageStatistics)

        if (tempShowUserUsageStatistics) {
            setOrderBy('Name')
            setOrderByOptions(['Last active', 'Name', 'Language points'])
            setShowOnlySignedUp(false)
        } else {
            setOrderBy('Name')
            setOrderByOptions(['Name', 'Language points'])
        }
    }, [])

    useEffect(() => {
        StudentOrdering()
    }, [props.organization, searchInput, showOnlySignedUp, orderBy])

    return (
        <div className={classes.root}>
            <div>
                <Grid>
                    {showDeleteConfirmation && (
                        <CapeeshStyledModal
                            open={showDeleteConfirmation}
                            textTitle={
                                <FormattedMessage
                                    id="globalwords.deleteconfirmation"
                                    defaultMessage="Delete confirmation"
                                />
                            }
                            textLine1={
                                <FormattedMessage
                                    id="dashboard.students.doyouwanttodeletestudent"
                                    defaultMessage="Do you really want delete the student?"
                                />
                            }
                            onButton2={handleDeleteUser}
                            onButton1={() => setShowDeleteConfirmation(false)}
                            button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                            button1Variant={'outlined'}
                            button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes" />}
                            onClose={() => setShowDeleteConfirmation(false)}
                        />
                    )}
                </Grid>
                <OrganizationOverviewSidebar path={props.computedMatch?.path} />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '15px',
                            justifyContent: 'left',
                            width: '100%',
                        }}>
                        <div style={{ minWidth: '160px', maxWidth: '240px' }}>
                            <InputLabel htmlFor="orderBy">Order by</InputLabel>
                            <Select
                                value={orderBy}
                                onChange={(obj) => setOrderBy(obj.target.value)}
                                inputProps={{
                                    name: 'orderBy',
                                    id: 'orderBy',
                                }}>
                                {orderByOptionsFiltered.map((option, index) => (
                                    <MenuItem value={option} key={index}>
                                        <em>{option}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <TextField
                            id="search"
                            label=<FormattedMessage
                                id="dashboard.students.searchforstudentlabel"
                                defaultMessage="Search for student"
                            />
                            fullWidth
                            value={searchInput}
                            onChange={(obj) => setSearchInput(obj.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Button
                            variant="contained"
                            style={{ marginLeft: '30px', width: '360px', textTransform: 'none' }}
                            className={classes.inviteButton}
                            onClick={() => history.push('/dashboard/classrooms')}
                            color={'primary'}>
                            <FormattedMessage
                                id="dashboard.students.invitestudentsinclassrooms.button"
                                defaultMessage="Invite students in classrooms"
                            />
                            <ArrowForwardIosIcon
                                sx={{ marginLeft: '15px', fontSize: '15px', color: 'white', paddingRight: '5px' }}
                            />
                        </Button>
                    </div>

                    <div>
                        <FormControlLabel
                            style={{ width: '400px' }}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={showOnlySignedUp}
                                    onChange={(obj) => setShowOnlySignedUp(obj.target.checked)}
                                    value="showOnlySignedUp"
                                />
                            }
                            label=<FormattedMessage
                                id="dashboard.students.showonlysigneduptogglelabel"
                                defaultMessage="Show only signed up users"
                            />
                        />
                    </div>
                </div>
            </div>
            <div>
                {props.organization.isFetchingOrganizationUsers && (
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                )}
                {!props.organization.isFetchingOrganizationUsers &&
                    classroomUsers != null &&
                    classroomUsers.map((user, index) => (
                        <Grid item xs={12} key={user.id} style={{ marginTop: '10px' }}>
                            <StudentListCard
                                user={user}
                                index={index}
                                handleEdit={editStudent}
                                handleRemove={null}
                                handleDelete={handleDeleteUserClicked}
                                showUserUsageStatistics={showUserUsageStatistics}
                                showDeleteFromClassroom={false}
                                showAppUsage={false}
                            />
                        </Grid>
                    ))}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, user } = state
    return {
        organization,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationStudentsOverview)
