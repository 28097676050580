import React from 'react'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import PrivateLayout from '../Layouts/PrivateLayout'

export const PrivateRoute = ({ isAuthenticated, isUserExisting, component: Component, ...rest }) =>
    isAuthenticated === true ? <PrivateLayout {...rest} component={Component} /> : <Redirect to="/login" />

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.loggedIn,
        isUserExisting: state.user.info,
    }
}

export default connect(mapStateToProps)(PrivateRoute)
