import React, { memo } from 'react'
import { Handle } from 'react-flow-renderer'
import AssociationWizard from '../../../../assets/images/AssociationWizard.png'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'

export default memo(({ data }) => {
    let puzzleBlockInFocus = 0

    const determinePuzzleBlocksToDisplay = (index) => {
        return index <= puzzleBlockInFocus + 2 && index != puzzleBlockInFocus
    }

    const puzzleStageBlockPhoneDisplay = (alternative, middleBlock) => {
        return (
            <React.Fragment>
                <Grid item xs={6} sm={6} md={6}>
                    <Card
                        style={{
                            margin: '10px',
                            marginBottom: '32px',
                            marginLeft: middleBlock === true ? '10px' : '13px',
                            width: middleBlock === true ? '32px' : '27px',
                            height: middleBlock === true ? '32px' : '27px',
                            dropShadow: '(0px 3.57333px 0px rgba(0, 0, 0, 0.25))',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            borderRadius: '2px',
                        }}>
                        <Typography
                            variant="body1"
                            style={{
                                fontSize: 4,
                                fontFamily: 'Lato',
                                width: '50px',
                                textAlign: 'center',
                                color: '#44A6EF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {alternative.text}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Card
                        style={{
                            margin: '10px',
                            marginBottom: '32px',
                            marginLeft: middleBlock === true ? '6px' : '9px',
                            width: middleBlock === true ? '32px' : '27px',
                            height: middleBlock === true ? '32px' : '27px',
                            dropShadow: '(0px 3.57333px 0px rgba(0, 0, 0, 0.25))',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            borderRadius: '2px',
                        }}>
                        <Typography
                            variant="body1"
                            style={{
                                fontSize: 4,
                                fontFamily: 'Lato',
                                width: '50px',
                                textAlign: 'center',
                                color: '#44A6EF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {alternative.associatedText}
                        </Typography>
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }
    return (
        <>
            <div>
                <img
                    src={AssociationWizard}
                    style={{
                        position: 'relative',
                        width: '100px',
                        height: 'auto',
                    }}
                />
                <Grid style={{ position: 'absolute', top: 0, left: 0 }} container>
                    <React.Fragment>
                        <Grid style={{ position: 'absolute', top: 34, left: 10 }} container>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: 5,
                                        fontFamily: 'Lato',
                                        width: '80px',
                                        textAlign: 'center',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingBottom: '2px',
                                    }}>
                                    {data.texts[0].text}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            align="center"
                            justify="center"
                            alignItems="center"
                            style={{
                                position: 'absolute',
                                color: '#8E8E93',
                                border: 'none',
                                top: 64,
                                width: '80px',
                                left: '5px',
                                alignItems: 'center',
                            }}>
                            {data.testOptions[0]?.alternatives.length >= 1 &&
                                puzzleStageBlockPhoneDisplay(
                                    data.testOptions[0]?.alternatives[puzzleBlockInFocus],
                                    true
                                )}
                        </Grid>
                        <Grid
                            container
                            align="center"
                            justify="center"
                            alignItems="center"
                            style={{
                                position: 'absolute',
                                color: '#8E8E93',
                                marginLeft: '5px',
                                border: 'none',
                                top: data.testOptions[0].alternatives.length === 2 ? '15px' : '32px',
                                fontWeight: 900,
                                width: '80px',
                                alignItems: 'center',
                                height: '104px',
                                overflow: 'hidden',
                            }}>
                            {data.testOptions[0]?.alternatives?.length > 0 &&
                                data.testOptions[0].alternatives.map(
                                    (alternative, index) =>
                                        determinePuzzleBlocksToDisplay(index) === true &&
                                        puzzleStageBlockPhoneDisplay(alternative, false)
                                )}
                        </Grid>
                    </React.Fragment>
                </Grid>
            </div>
            <Handle type="target" position="left" id="target1" style={{ top: '98.5px', background: '#555' }} />
            <Handle type="source" position="right" id="exit2" style={{ top: '98.5px', background: '#555' }} />
        </>
    )
})
