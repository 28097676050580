import { authConstants, usergroupConstants } from '../actions'

const initialState = {
    loadingOrganizationInfo: false,
    usergroups: [],
    userGroupUsers: [],
    userGroupStats: null,
    newUsergroup: null,
    creatingUserGroup: false,
    fetchingUserGroupTopics: false,
    fetchingStudents: false,
    courseGroupsForUserGroup: [],
}

export function usergroup(state = initialState, action) {
    switch (action.type) {
        case usergroupConstants.CREATE_USERGROUP_RESET.REQUEST:
            return {
                ...state,
                creatingUserGroup: false,
            }

        case usergroupConstants.CREATE_USERGROUP.REQUEST:
            return {
                ...state,
                newUsergroup: null,
                creatingUserGroup: true,
            }
        case usergroupConstants.CREATE_USERGROUP.SUCCESS:
            return {
                ...state,
                newUsergroup: action.data,
                creatingUserGroup: false,
            }
        case usergroupConstants.CREATE_USERGROUP.FAILURE:
            return {
                ...state,
                error: action.message,
                creatingUserGroup: false,
            }
        case usergroupConstants.EDIT_USERGROUP.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.EDIT_USERGROUP.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.EDIT_USERGROUP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.DELETE_USERGROUP.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_USERGROUP.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_USERGROUP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.GET_USERGROUP_USERS.REQUEST:
            return {
                ...state,
                userGroupUsers: [],
                fetchingStudents: true,
            }
        case usergroupConstants.GET_USERGROUP_USERS.SUCCESS:
            return {
                ...state,
                userGroupUsers: action.data,
                fetchingStudents: false,
            }
        case usergroupConstants.GET_USERGROUP_USERS.FAILURE:
            return {
                ...state,
                error: action.message,
                fetchingStudents: false,
            }

        case usergroupConstants.RESET_CURRENT_ORGANIZATION.REQUEST:
            return {
                ...state,
                usergroups: [],
                userGroupUsers: [],
                userGroupStats: [],
            }
        case usergroupConstants.RESET_CURRENT_ORGANIZATION.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.RESET_CURRENT_ORGANIZATION.FAILURE:
            return {
                ...state,
            }

        case usergroupConstants.ADD_USERGROUP_USERS.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.ADD_USERGROUP_USERS.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.ADD_USERGROUP_USERS.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.DELETE_USERGROUP_USERS.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_USERGROUP_USERS.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_USERGROUP_USERS.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.ADD_USERGROUP_TOPIC.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.ADD_USERGROUP_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.ADD_USERGROUP_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.DELETE_USERGROUP_TOPIC.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_USERGROUP_TOPIC.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_USERGROUP_TOPIC.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.GET_USERGROUP_TOPICS.REQUEST:
            return {
                ...state,
                userGroupTopics: [],
                fetchingUserGroupTopics: true,
            }
        case usergroupConstants.GET_USERGROUP_TOPICS.SUCCESS:
            return {
                ...state,
                userGroupTopics: action.data,
                fetchingUserGroupTopics: false,
            }
        case usergroupConstants.GET_USERGROUP_TOPICS.FAILURE:
            return {
                ...state,
                error: action.message,
                fetchingUserGroupTopics: false,
            }

        case usergroupConstants.GET_ALL_USERGROUPS.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.GET_ALL_USERGROUPS.SUCCESS:
            return {
                ...state,
                usergroups: action.data,
            }
        case usergroupConstants.GET_ALL_USERGROUPS.FAILURE:
            return {
                ...state,
                error: action.message,
                usergroups: [],
            }

        case usergroupConstants.UPDATE_USERGROUP.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.UPDATE_USERGROUP.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.UPDATE_USERGROUP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.GET_USERGROUP_STATS.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.GET_USERGROUP_STATS.SUCCESS:
            return {
                ...state,
                userGroupStats: action.data,
            }
        case usergroupConstants.GET_USERGROUP_STATS.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.SUCCESS:
            return {
                ...state,
                courseGroupsForUserGroup: action.data,
            }
        case usergroupConstants.GET_COURSE_GROUPS_FOR_USER_GROUP.FAILURE:
            return {
                ...state,
                courseGroupsForUserGroup: [],
                error: action.message,
            }

        case usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.SUCCESS:
            return {
                ...state,
                courseGroupsForUserGroup: action.data,
            }
        case usergroupConstants.ADD_COURSE_GROUP_TO_USER_GROUP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.SUCCESS:
            return {
                ...state,
                courseGroupsForUserGroup: action.data,
            }
        case usergroupConstants.DELETE_COURSE_GROUP_FROM_USER_GROUP.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.ADD_USER_GROUP_REPORTING_USER.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.ADD_USER_GROUP_REPORTING_USER.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.ADD_USER_GROUP_REPORTING_USER.FAILURE:
            return {
                ...state,
                error: action.message,
            }

        case usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.REQUEST:
            return {
                ...state,
            }
        case usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.SUCCESS:
            return {
                ...state,
            }
        case usergroupConstants.REMOVE_USER_GROUP_REPORTING_USER.FAILURE:
            return {
                ...state,
                error: action.message,
            }
        case authConstants.LOGOUT.SUCCESS:
            return initialState
        default:
            return state
    }
}
