import React from 'react'
import ScenarioWatchNodeText from './ScenerioMapNodeViews/ScenarioWatchNodeText'
import ScenarioListenNodeText from './ScenerioMapNodeViews/ScenarioListenNodeText'
import ScenarioSpeakNodeText from './ScenerioMapNodeViews/ScenarioSpeakNodeText'
import ScenarioDebriefNodeText from './ScenerioMapNodeViews/ScenarioDebriefNodeText'
import ScenarioBriefNodeText from './ScenerioMapNodeViews/ScenarioBriefNodeText'
import RuleCardDisplayText from './ScenerioMapNodeViews/RuleCardDisplayText'
import RuleCardDisplay from './ScenerioMapNodeViews/RuleCardDisplay'
import ScenarioDefinitionNodeText from './ScenerioMapNodeViews/ScenarioDefinitionNodeText'
import ScenarioPuzzleNodeText from './ScenerioMapNodeViews/ScenarioPuzzleNodeText'
import ScenarioPuzzleNode from './ScenerioMapNodeViews/ScenarioPuzzleNode'
import ScenarioWatchNode from './ScenerioMapNodeViews/ScenarioWatchNode'
import ScenarioListenNode from './ScenerioMapNodeViews/ScenarioListenNode'
import ScenarioSpeakNode from './ScenerioMapNodeViews/ScenarioSpeakNode'
import ScenarioDebriefNode from './ScenerioMapNodeViews/ScenarioDebriefNode'
import ScenarioBriefNode from './ScenerioMapNodeViews/ScenarioBriefNode'
import ScenarioDefinitionNode from './ScenerioMapNodeViews/ScenarioDefinitionNode'
import ScenarioImageNode from './ScenerioMapNodeViews/ScenarioImageNode'
import ScenarioImageNodeText from './ScenerioMapNodeViews/ScenarioImageNodeText'
import ScenarioBranchingMultiChoiceNode from './ScenerioMapNodeViews/ScenarioBranchingMultiChoiceNode'
import ScenarioBranchingMultiChoiceNodeText from './ScenerioMapNodeViews/ScenarioBranchingMultiChoiceNodeText'
import ScenarioSketchSpeakNodeText from './ScenerioMapNodeViews/ScenarioSketchSpeakNodeText'
import ScenarioSketchListenNodeText from './ScenerioMapNodeViews/ScenarioSketchListenNodeText'
import ScenarioSketchWatchNodeText from './ScenerioMapNodeViews/ScenarioSketchWatchNodeText'

import ScenarioMultiChoiceNode from './ScenerioMapNodeViews/ScenarioMultiChoiceNode'
import ScenarioMultiChoiceNodeText from './ScenerioMapNodeViews/ScenarioMultiChoiceNodeText'
import ScenarioListenMultiChoiceNode from './ScenerioMapNodeViews/ScenarioListenMultiChoiceNode'
import ScenarioListenMultiChoiceNodeText from './ScenerioMapNodeViews/ScenarioListenMultiChoiceNodeText'
import ScenarioSketchDebriefNodeText from './ScenerioMapNodeViews/ScenarioSketchDebriefNodeText'
import ScenarioSketchBriefNodeText from './ScenerioMapNodeViews/ScenarioSketchBriefNodeText'
import PlacementNode from './ScenerioMapNodeViews/PlacementNode'
import ScenarioSketchMultichoiceNodeText from './ScenerioMapNodeViews/ScenarioSketchMultichoiceNodeText'
import InstructionStageNodeText from './ScenerioMapNodeViews/InstructionStageNodeText'
import InstructionStageNode from './ScenerioMapNodeViews/InstructionStageNode'
import DialogStageNodeText from './ScenerioMapNodeViews/DialogStageNodeText'
import DialogStageNode from './ScenerioMapNodeViews/DialogStageNode'
import ListenWithSliderNodeText from './ScenerioMapNodeViews/ListenWithSliderNodeText'
import ListenWithSliderNode from './ScenerioMapNodeViews/ListenWithSliderNode'

export const nodeTypes = {
    1: ScenarioWatchNodeText,
    2: ScenarioListenNodeText,
    3: ScenarioSpeakNodeText,
    4: ScenarioDebriefNodeText,
    5: ScenarioBranchingMultiChoiceNodeText,
    6: ScenarioBriefNodeText,
    8: RuleCardDisplayText,
    9: ListenWithSliderNodeText,
    10: ScenarioDefinitionNodeText,
    12: ScenarioImageNodeText,
    13: DialogStageNodeText,
    14: ScenarioPuzzleNodeText,
    15: ScenarioMultiChoiceNodeText,
    16: ScenarioListenMultiChoiceNodeText,
    17: InstructionStageNodeText,

    101: ScenarioWatchNode,
    102: ScenarioListenNode,
    103: ScenarioSpeakNode,
    104: ScenarioDebriefNode,
    105: ScenarioBranchingMultiChoiceNode,
    106: ScenarioBriefNode,
    108: RuleCardDisplay,
    109: ListenWithSliderNode,
    1010: ScenarioDefinitionNode,
    1012: ScenarioImageNode,
    1013: DialogStageNode,
    1014: ScenarioPuzzleNode,
    1015: ScenarioMultiChoiceNode,
    1016: ScenarioListenMultiChoiceNode,
    1017: InstructionStageNode,

    203: ScenarioSketchSpeakNodeText,
    202: ScenarioSketchListenNodeText,
    201: ScenarioSketchWatchNodeText,
    204: ScenarioSketchDebriefNodeText,
    206: ScenarioSketchBriefNodeText,
    215: ScenarioSketchMultichoiceNodeText,
    216: ScenarioSketchMultichoiceNodeText,

    999: PlacementNode,
}
