import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import GuideListenBasics from '../../../../assets/images/GuideListenBasics.png'

const useStyles = makeStyles((theme) => ({
    playIcon: {
        color: '#eee',
        fontSize: 36,
        marginLeft: '15px',
        //marginRight: '15px',
        cursor: 'pointer',
    },
    leftBox: {
        /* Gray 3 */
        background: '#EFEFEF',
        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.11)',
        borderRadius: '20px',
    },
    leftPanel: {
        minWidth: '400px',
    },
    mainContainer: {
        paddingLeft: '25px',
        paddingRight: '25px',
    },
    rightPaper: {
        /* White */
        //  marginLeft: '20px',
        marginTop: '30px',
        background: '#FFFFFF',
        /* Gray 3 */

        border: '1px solid #D1D1D6',
        boxSizing: 'border-box',
        borderRadius: '15px',
    },
}))

const TutorialListen = (props) => {
    const classes = useStyles()
    const tutorialParagraph = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        marginBottom: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialSubHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '10px',
                        marginTop: '10px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    const tutorialHeader = (text) => {
        return (
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                <Typography
                    variant="h6"
                    style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '32px',
                        marginBottom: '20px',
                    }}>
                    {text}
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid
            container
            style={{
                paddingLeft: '50px',
                paddingTop: '40px',
                paddingRight: '20px',
            }}>
            {tutorialHeader('The Listen Stage')}
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <div
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        marginBottom: '35px',
                    }}>
                    <iframe
                        src="https://player.vimeo.com/video/686713992?h=91d67a1c65"
                        style={{ alignItems: 'center' }}
                        width="768"
                        height="432"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </Grid>
            {tutorialSubHeader('How it works')}
            {tutorialParagraph(
                'The other side of a conversation to a Branching stage, the Listen stage gives the learner the opportunity to listen to somebody talking to them.'
            )}
            {tutorialParagraph(
                'This is typically a face-to-face conversation, but also be a telephone conversation. It might also be a case of the learner listening to the radio, for instance.'
            )}
            {tutorialSubHeader('The Listen stage basics')}
            {tutorialParagraph(
                'The core element of a Listen stage is the script. This is the text that is spoken out to the learner in the stage. Simply type or paste the script text in the Script textfield.'
            )}
            {tutorialSubHeader('Computer-generated voice or human voice')}
            {tutorialParagraph(
                'By default, the script will be spoken out by a computer-generated voice. To add a human voice insead, scroll down to the Voice section. Here, you can either record a voice here and now, or choose to upload an MP3 file of the voice.'
            )}
            {tutorialParagraph(
                'When you have a voice sample either recorded or uploaded, you can click the play icon to listen to it. If you are happy with it, click Save Changes at the bottom of the window, and the voice will be added to the stage.'
            )}
            {tutorialParagraph('You can see the Script and Voice sections of a listen stage in the screenshot below.')}
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '5px' }}>
                <img
                    style={{
                        flex: 1,
                        color: 'black',
                        height: '400px',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                    src={GuideListenBasics}
                />
            </Grid>
            {tutorialSubHeader('Subtitles')}
            {tutorialParagraph(
                "A Listen Stage can, if the author wishes, contain subtitles showing what is being spoken. The subtitles can appear in either the L2 or the learner's L1."
            )}
            {tutorialParagraph(
                'It really depends on the nature of the lesson and the skill level of the target audience as to whether it should contain subtitles or not, and if so, which language they appear in.'
            )}
            {tutorialSubHeader('How to switch on or off subtitles for the lesson')}
            {tutorialParagraph(
                'Subtitles are switch on or off for the entire lesson. To do this, click the Options button towards the top-left of the lesson viewer.'
            )}
            {tutorialParagraph(
                "Next, locate the 'Subtitle settings for listen stages' option. Here, choose between no subtitles, L1 subtitles or L2 subtitles."
            )}
            {tutorialParagraph(
                'Note that the language shown in the above selection box will be the L2 language and the default L1 language for the course. However the lesson will show the L1 subtitle for whichever language the learner has as their L1.'
            )}
        </Grid>
    )
}

TutorialListen.propTypes = {}

const mapToProps = (state) => {
    return {
        metadata: state.metadata,
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(TutorialListen)
