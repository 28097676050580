import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { Bar, BarChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Card from '@material-ui/core/Card'
import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { CapeeshColors } from '../../assets/ColorPalette'
import { FormattedMessage } from 'react-intl'

const HourOfDayGraph = (props) => {
    const getMinutesStringFromHourOfTheDay = (hourOfTheDay) => {
        if (props.graphDataIndex && hourOfTheDay in props.graphDataIndex) {
            return formatMinutes(props.graphDataIndex[hourOfTheDay])
        }
        return ''
    }

    const formatMinutes = (min) => {
        const hours = min / 60
        const flooredHours = Math.floor(hours)
        const minutes = (hours - flooredHours) * 60
        const roundedMinutes = Math.round(minutes)

        if (flooredHours === 0 && roundedMinutes === 0) return ''

        let minutesString = roundedMinutes + ' m'

        if (flooredHours === 0) {
            return minutesString + '.'
        }

        let rhoursString = flooredHours + ' h'

        return rhoursString + ' ' + minutesString + '.'
    }

    const formatMinutesYAxis = (min) => {
        if (!props.showYAxisLabel) {
            return ''
        }

        if (min < 5) {
            return min.toFixed(1) + ' m'
        }
        return min.toFixed(0) + ' m'
    }

    return (
        <Card style={{ overflow: 'visible' }}>
            <CardHeader
                title={
                    <FormattedMessage
                        id="dashboard.graphs.hourofday.title"
                        defaultMessage="Aggregated usage time of day"
                    />
                }
                subheader={
                    props.canShowChart ? (
                        <FormattedMessage
                            id="dashboard.graphs.hourofday.subheader.withdata"
                            defaultMessage="What time of the day the users are active"
                        />
                    ) : (
                        <FormattedMessage
                            id="dashboard.graphs.hourofday.subheader.nodata"
                            defaultMessage="Add more users to see the day of time of day chart"
                        />
                    )
                }
            />
            <CardContent>
                <Grid item xs={12}>
                    {props.canShowChart && (
                        <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={props.graphData}
                                    margin={{
                                        top: 50,
                                        right: 30,
                                        left: 30,
                                        bottom: 50,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="hourOfTheDay"></XAxis>
                                    <YAxis
                                        dataKey={'minutesCombined'}
                                        tickFormatter={(tick) => `${formatMinutesYAxis(tick)}`}></YAxis>
                                    <Tooltip
                                        content={({ active, payload, label }) => {
                                            if (active) {
                                                return (
                                                    <Card>
                                                        <CardContent>
                                                            <Typography variant={'h6'}>
                                                                <FormattedMessage
                                                                    id="dashboard.graphs.hourofday.tooltip.hourofday"
                                                                    defaultMessage="Hour of the day">
                                                                    {(hourOfTheDayText) =>
                                                                        hourOfTheDayText + ': ' + label
                                                                    }
                                                                </FormattedMessage>
                                                            </Typography>
                                                            {
                                                                <Typography variant={'body1'}>
                                                                    <FormattedMessage
                                                                        id="globalwords.usage"
                                                                        defaultMessage="Usage">
                                                                        {(usageText) =>
                                                                            usageText +
                                                                            ': ' +
                                                                            getMinutesStringFromHourOfTheDay(label)
                                                                        }
                                                                    </FormattedMessage>
                                                                </Typography>
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                )
                                            }

                                            return null
                                        }}
                                    />
                                    {
                                        <Bar
                                            type="monotone"
                                            dataKey="minutesCombined"
                                            stackId="1"
                                            strokeWidth={2}
                                            stroke={CapeeshColors.BlueBright}
                                            fill={CapeeshColors.BlueBright}
                                        />
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HourOfDayGraph)
