import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { Grid, Typography, Button, CircularProgress, makeStyles } from '@material-ui/core'
import { LibraryAdd, Create } from '@material-ui/icons'

import { history } from '_state/helpers'
import { organizationActions, topicActions, usergroupActions } from '_state/actions'
import ClassroomSidebar from './Components/ClassroomSidebar'
import CourseCard from '../Courses/CourseCard'
import CapeeshStyledModal from '../../../Components/CapeeshStyledModal'
import CourseGroupCard from '../Courses/CourseGroupCard'
import CourseGroupSelector from '../../../Components/CourseGroupSelector/CourseGroupSelector'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CourseViewTopicList from '../../../Components/CourseView/CourseViewTopicList'
import CourseDialogWithLessonPreview from '../../../Components/CustomDialog/CourseDialogWithLessonPreview'

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 'calc(15% + 25px)',
        marginRight: '25px',
    },
    center: {
        margin: 'auto auto',
        display: 'block',
    },
    newCourseButton: {
        color: '#8080802e',
        margin: 'auto auto',
        display: 'inline-grid',
        padding: '25px',
        animation: 'all 200ms linear',
        border: 'dashed 2px #8080802e',
        '&:hover': {
            border: 'dashed 2px',
            color: theme.palette.primary.main,
        },
    },
    newCourseIcon: {
        height: '50px',
        width: '50px',
        margin: 'auto auto',
    },
}))

const ClassroomCourses = (props) => {
    const { computedMatch, usergroup, topic, organization, user, metadata } = props

    const classes = useStyles()
    const [showAddCourseGroupModal, setShowAddCourseGroupModal] = useState(false)
    const [showRemoveCourseGroupNotification, setShowRemoveCourseGroupNotification] = useState(false)
    const [showRemoveCourseGroupErrorLastCourseGroup, setShowRemoveCourseGroupErrorLastCourseGroup] = useState(false)
    const [removeCourseGroupIdAfterConfirm, setRemoveCourseGroupIdAfterConfirm] = useState(null)

    // buttons to show
    const [showAddCourseGroupButton, setShowAddCourseGroupButton] = useState(false)
    const [showCourseGroupDeleteButton, setShowCourseGroupDeleteButton] = useState(false)

    // topics
    const [availableCourseGroups, setAvailableCourseGroups] = useState([])
    const [classroomCourses, setClassroomCourses] = useState([])

    const [classroomCoursesPublished, setClassroomCoursesPublished] = useState([])
    const [classroomCoursesDraft, setClassroomCoursesDraft] = useState([])

    const [hasDraftCourses, setHasDraftCourses] = useState(false)

    const [availableTopics, setAvailableTopics] = useState([])

    // course groups
    const [classroomCourseGroups, setClassroomCourseGroups] = useState([])
    const [classroomCourseGroupIds, setClassroomCourseGroupIds] = useState([])

    // hide or show drafts
    const [showDraftSection, setShowDraftSection] = useState(false)

    // show course content
    const [openCourseGroupId, setOpenCourseGroupId] = useState(null)
    const [openCourseGroup, setOpenCourseGroup] = useState(null)
    const [openCourseGroupProgression, setOpenCourseGroupProgression] = useState(null)
    const [openCourseGroupModal, setOpenCoursGroupModal] = useState(false)

    useEffect(() => {
        const { computedMatch, organization } = props
        props.dispatch(organizationActions.getAvailableCourseGroups(organization.selectedOrganization))

        if (computedMatch.params.classroomId)
            props.dispatch(usergroupActions.getCourseGroupsForUserGroup(computedMatch.params.classroomId))
        props.dispatch(
            usergroupActions.getUserGroupTopics(organization.selectedOrganization, computedMatch.params.classroomId)
        )

        props.dispatch(organizationActions.getOrganizationCourseGroups(organization.selectedOrganization))

        recalculateTopicData()
        recalculateAvailableCourseGroupData()

        if (user.organizationRole === 'admin') {
            setShowAddCourseGroupButton(true)
            setShowCourseGroupDeleteButton(true)
        }
        if (user.organizationRole === 'reporting' || user.organizationRole === 'teacher') {
            setShowAddCourseGroupButton(true)
        }
    }, [])

    const handleCourseGroupOpen = (courseGroupId) => {
        setOpenCourseGroupId(courseGroupId)

        if (classroomCourseGroups) {
            const courseGroupToShow = classroomCourseGroups.find((x) => x.id == courseGroupId)
            if (courseGroupToShow) {
                setOpenCourseGroup(courseGroupToShow)
                setOpenCoursGroupModal(true)
            } else {
                // TODO: handle failed to fetch course group?..
            }
        }
    }

    const handleCloseCourseGroupModal = () => {
        setOpenCourseGroupId(null)
        setOpenCoursGroupModal(false)
        setOpenCourseGroupProgression(null)
        setOpenCourseGroup(null)
    }

    const recalculateTopicData = () => {
        let allTopics = topic.topics
        let classroomCourses = []
        if (usergroup.userGroupTopics) {
            classroomCourses = usergroup.userGroupTopics
        }

        let tempClassroomCoursesDraft = []
        let tempClassroomCoursesPublished = []

        classroomCourses.forEach((element) => {
            if (element.published) {
                if (element.deletedAt === null) tempClassroomCoursesPublished.push(element)
            } else {
                if (element.deletedAt === null) tempClassroomCoursesDraft.push(element)
            }
        })

        setClassroomCoursesPublished(tempClassroomCoursesPublished)
        setClassroomCoursesDraft(tempClassroomCoursesDraft)
        if (tempClassroomCoursesDraft.length > 0) {
            setHasDraftCourses(true)
        }

        if (usergroup.userGroupTopics) {
            setAvailableTopics(allTopics.filter((e) => !usergroup.userGroupTopics.find((x) => x.id === e.id)))
        } else {
            setAvailableTopics([])
        }

        if (usergroup.courseGroupsForUserGroup) {
            setClassroomCourseGroups(usergroup.courseGroupsForUserGroup)
            setClassroomCourseGroupIds(usergroup.courseGroupsForUserGroup.map((x) => x.id))
        } else {
            setClassroomCourseGroups([])
            setClassroomCourseGroupIds([])
        }
    }

    const recalculateAvailableCourseGroupData = () => {
        let tempAvailableCourseGroups =
            organization.organizationCourseGroups?.length > 0 ? organization.organizationCourseGroups : []
        let tempCourseGroupIds = tempAvailableCourseGroups.map((x) => x.id)
        if (organization.availableCourseGroups?.length > 0) {
            organization.availableCourseGroups.forEach((x) => {
                if (x.organizationId === organization.selectedOrganization && !tempCourseGroupIds.includes(x.id)) {
                    tempAvailableCourseGroups.push(x)
                    tempCourseGroupIds.push(x.id)
                }
            })
        }
        setAvailableCourseGroups(tempAvailableCourseGroups)
    }
    useEffect(() => {
        recalculateAvailableCourseGroupData()
    }, [organization.organizationCourseGroups])

    useEffect(() => {
        recalculateAvailableCourseGroupData()
    }, [organization.availableCourseGroups])

    useEffect(() => {
        recalculateTopicData()
    }, [usergroup.courseGroupsForUserGroup])

    useEffect(() => {
        recalculateTopicData()
    }, [usergroup.userGroupTopics])

    useEffect(() => {
        recalculateTopicData()
    }, [topic.topics])

    const handleAddCourseGroup = () => {
        setShowAddCourseGroupModal(true)
    }

    const handleRemoveCourseGroup = (courseGroupId) => {
        if (classroomCourseGroups?.length === 1) {
            setShowRemoveCourseGroupErrorLastCourseGroup(true)
            return
        }

        setShowRemoveCourseGroupNotification(true)
        setRemoveCourseGroupIdAfterConfirm(courseGroupId)
    }

    const handleCloseRemoveLastCourseGroupErrorMessage = () => {
        setShowRemoveCourseGroupErrorLastCourseGroup(false)
    }

    const handleUnenrollCourseGroupConfirmed = (courseGroupId) => {
        if (classroomCourseGroups?.length === 1) {
            return
        }

        setShowRemoveCourseGroupNotification(false)
        const { computedMatch } = props
        props.dispatch(usergroupActions.removeCourseGroupFromUserGroup(computedMatch.params.classroomId, courseGroupId))

        setRemoveCourseGroupIdAfterConfirm(null)
    }

    const handleCancelRemoveCourseGroupNotification = () => {
        setShowRemoveCourseGroupNotification(false)
        setRemoveCourseGroupIdAfterConfirm(null)
    }

    const sortAlphabetically = (a, b) => {
        let textA = a.name.toUpperCase()
        let textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
    }

    const handleAddCourseGroupId = (courseGroupId) => {
        setShowAddCourseGroupModal(false)
        props.dispatch(usergroupActions.addCourseGroupToUserGroup(computedMatch.params.classroomId, courseGroupId))
    }

    const handleAddCourseGroupFromModal = (event) => {
        const { usergroup, organization, computedMatch } = props
        setShowAddCourseGroupModal(false)

        if (
            event.length === 0 ||
            !organization.availableCourseGroups ||
            organization.availableCourseGroups?.length === 0
        ) {
            return
        }

        let newCourseGroupIds = []

        organization.availableCourseGroups.map((organizationCourseGroup) => {
            event.forEach((selectedCourseGroupId) => {
                if (selectedCourseGroupId != null && selectedCourseGroupId === organizationCourseGroup.id) {
                    // check if already selected
                    if (
                        !usergroup.courseGroupsForUserGroup.some(
                            (classroomCourseGroup) => classroomCourseGroup.id === selectedCourseGroupId
                        )
                    ) {
                        newCourseGroupIds.push(selectedCourseGroupId)
                    }
                }
            })
        })
        newCourseGroupIds.forEach((selectedCourseGroupId) => {
            props.dispatch(
                usergroupActions.addCourseGroupToUserGroup(computedMatch.params.classroomId, selectedCourseGroupId)
            )
        })
    }

    const handleAddCourseGroupCancel = () => {
        setShowAddCourseGroupModal(false)
    }

    return (
        <div className={classes.root}>
            <ClassroomSidebar userGroupId={computedMatch.params.classroomId} path={computedMatch.path} />
            {openCourseGroupModal && (
                <CourseDialogWithLessonPreview
                    isOpen={openCourseGroupModal}
                    onClose={handleCloseCourseGroupModal}
                    title={openCourseGroup?.name}
                    dispatch={props.dispatch}
                    openCourseGroup={openCourseGroup}></CourseDialogWithLessonPreview>
            )}

            <CapeeshStyledModal
                open={showRemoveCourseGroupNotification}
                textTitle={
                    <FormattedMessage
                        id="dashboard.classroom.courses.deletecourseconfirmationtitle"
                        defaultMessage="Do you want to remove this course?"
                    />
                }
                textLine1={
                    <FormattedMessage
                        id="dashboard.classroom.courses.thiscoursewillberemoved"
                        defaultMessage="This course will be removed for all users in the classroom."
                    />
                }
                onButton1={() => handleCancelRemoveCourseGroupNotification()}
                onButton2={() => handleUnenrollCourseGroupConfirmed(removeCourseGroupIdAfterConfirm)}
                button1Variant={'outlined'}
                button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel" />}
                button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes" />}
                onClose={() => handleCancelRemoveCourseGroupNotification()}
            />

            <CapeeshStyledModal
                open={showRemoveCourseGroupErrorLastCourseGroup}
                textTitle={
                    <FormattedMessage
                        id="dashboard.classroom.courses.lastcoursecannotberemoed"
                        defaultMessage="The last course cannot be removed."
                    />
                }
                textLine1={
                    <FormattedMessage
                        id="dashboard.classroom.courses.lastcoursecannotberemoeddesc"
                        defaultMessage="This action would remove all content in the app for users. Add another course before removing this one."
                    />
                }
                onButton2={() => handleCloseRemoveLastCourseGroupErrorMessage()}
                button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Ok" />}
                onClose={() => handleCloseRemoveLastCourseGroupErrorMessage()}
            />

            <CourseGroupSelector
                open={showAddCourseGroupModal}
                dialogTitle={
                    <FormattedMessage
                        id="dashboard.classroom.courses.courselibrarytitle"
                        defaultMessage="Capeesh Course Library"
                    />
                }
                dialogContentText={
                    <FormattedMessage
                        id="dashboard.classroom.courses.courselibrarydesc"
                        defaultMessage="Select Courses to add to this classroom."
                    />
                }
                handleCancel={handleAddCourseGroupCancel}
                onAddCourseGroupId={handleAddCourseGroupId}
                availableCourseGroups={availableCourseGroups}
                classroomCourseGroupIds={classroomCourseGroupIds}
            />
            <Grid container spacing={5} style={{ marginTop: '25px' }}>
                <Grid item xs={12}>
                    {showAddCourseGroupButton && (
                        <Button
                            color="primary"
                            disabled={availableCourseGroups?.length === classroomCourseGroups?.length}
                            onClick={handleAddCourseGroup}>
                            <LibraryAdd className={classes.newCourseIcon} />
                            {availableCourseGroups?.length === classroomCourseGroups?.length && (
                                <FormattedMessage
                                    id="dashboard.classroom.courses.add_course_group_all_content_added"
                                    defaultMessage="All content added"
                                />
                            )}
                            {availableCourseGroups?.length !== classroomCourseGroups?.length && (
                                <FormattedMessage
                                    id="dashboard.classroom.courses.add_course_group"
                                    defaultMessage="Assign more content"
                                />
                            )}
                        </Button>
                    )}
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center" gutterBottom>
                                <FormattedMessage
                                    id="dashboard.classroom.courses.assignedcourses"
                                    defaultMessage="Assigned Courses"
                                />
                            </Typography>
                        </Grid>
                        {classroomCourseGroups.map((courseGroup, index) => (
                            <Grid item xs={12} key={courseGroup.id}>
                                <CourseGroupCard
                                    courseGroup={courseGroup}
                                    index={index}
                                    slideDirection={'right'}
                                    handleRemoveCourseGroup={handleRemoveCourseGroup}
                                    onCourseGroupOpen={handleCourseGroupOpen}
                                />
                            </Grid>
                        ))}
                        {(topic.loadingTopics || usergroup.fetchingUserGroupTopics) && (
                            <CircularProgress size={30} className={classes.center} />
                        )}
                        {!(topic.loadingTopics || usergroup.fetchingUserGroupTopics) &&
                            classroomCoursesPublished.map((topic, index) => (
                                <Grid item xs={12} key={topic.id}>
                                    <CourseCard
                                        topic={topic}
                                        index={index}
                                        slideDirection={'right'}
                                        disabled={user?.organizationRole === 'reporting'}
                                        selectedOrganization={organization.selectedOrganization}
                                    />
                                </Grid>
                            ))}
                        {classroomCoursesPublished.length === 0 && classroomCourseGroups.length === 0 && (
                            <Grid item xs={12}>
                                <Typography variant="caption" align="center" display="block">
                                    <FormattedMessage
                                        id="dashboard.classroom.courses.nocourses"
                                        defaultMessage="No courses assigned. Try assigning one."
                                    />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

function mapStateToProps(state) {
    const { organization, topic, usergroup, user, metadata } = state
    return {
        organization,
        topic,
        usergroup,
        user,
        metadata,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomCourses)
