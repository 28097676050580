import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CapeeshColors } from '../../../assets/ColorPalette'
import { ReactComponent as Manual } from '../../../assets/images/CreateManualImage.svg'
import { ReactComponent as Language } from '../../../assets/images/CreateLanguageLessonsImage.svg'
import { ReactComponent as Knowledge } from '../../../assets/images/CreateKnowledgeLessonsImage.svg'
import Dialog from '@mui/material/Dialog'
import LessonCreationTypeCard from './LessonCreationTypeCard'
import xImage from '../../../assets/images/Navigation.png'

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 16,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleText: {
        alignSelf: 'stretch',
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0.64,
        textTransform: 'uppercase',
    },
    subTitleText: {
        color: CapeeshColors.Gray900,
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    closeIcon: {
        width: 24,
        height: 24,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 16,
        marginTop: 16,
        display: 'flex',
        cursor: 'pointer',
    },
}))

const LessonCreationTypeModal = ({ isOpen, onClose, onCreateManual, onCreateKnowledge, onCreateLanguage }) => {
    const classes = useStyles()

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
            <img onClick={() => onClose()} src={xImage} className={classes.closeIcon} />
            <div className={classes.modalContainer}>
                <div className={classes.titleContainer}>
                    <div className={classes.titleText}>Create a new lesson</div>
                    <div className={classes.subTitleText}>Choose what type of lessons you wish to create.</div>
                </div>
                <div className={classes.contentContainer}>
                    <LessonCreationTypeCard
                        image={<Manual />}
                        title={'Create Manually'}
                        subTitle={''}
                        descriptions={[
                            'Start with a blank canvas and bring your ' +
                                'ideas to life, craft unique lessons from ' +
                                'scratch. Perfect for those who want ' +
                                'complete creative freedom, allowing' +
                                ' you to build fully customized ' +
                                'experiences tailored to your specific needs.',
                            'Ideal for experienced users looking to' +
                                ' explore every aspect of Capeesh ' +
                                "Create's capabilities.",
                        ]}
                        buttonText={'Create Manually'}
                        onClickButton={onCreateManual}
                        topColor={'rgba(248, 239, 226, 1)'}
                    />
                    <LessonCreationTypeCard
                        image={<Language />}
                        title={'Create Language Lessons'}
                        subTitle={'with the help of AI'}
                        descriptions={[
                            'Perfect for teaching highly domain' +
                                ' related words, phrases, and sentences,' +
                                ' directly targeting industry specific ' +
                                'language learning.',
                            ' Helps you create your own immersive' + ' stories to reinforce vocabulary learning!',
                        ]}
                        buttonText={'Create Language Lessons'}
                        onClickButton={onCreateLanguage}
                        topColor={'rgba(238, 243, 247, 1)'}
                    />
                    <LessonCreationTypeCard
                        image={<Knowledge />}
                        title={'Create Knowledge Lessons'}
                        subTitle={'with the help of AI'}
                        descriptions={[
                            'Ideal for teaching extended content like' +
                                ' rules, instructions, or explanations — ' +
                                'perfect for job training! Language' +
                                ' support ensures everyone understands' +
                                ' and learns the language indirectly.',
                            ' Helps you create voice-drive interactive' +
                                ' role plays to practice workplace ' +
                                'language and scenarios.',
                        ]}
                        buttonText={'Create Knowledge Lessons'}
                        onClickButton={onCreateKnowledge}
                        topColor={'rgba(245, 233, 250, 1)'}
                        shouldShowBetaTag={true}
                    />
                </div>
            </div>
        </Dialog>
    )
}

LessonCreationTypeModal.propTypes = {}

const mapStateToProps = (state) => {
    return {
        organization: state.organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonCreationTypeModal)
