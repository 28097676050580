import React, { useRef, useState } from 'react'
import { Menu, MenuItem, MenuButton, ControlledMenu, SubMenu, useHover, useMenuState } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { makeStyles, Typography } from '@material-ui/core'
import { history } from '../../_state/helpers'
import { CapeeshColors } from '../../assets/ColorPalette'

const useStyles = makeStyles((theme) => ({
    singleElementStyle: {
        fontSize: '20px',
        height: '50px',
    },
    hoverButtonStyle: {
        marginLeft: '20px',
        fontSize: '20px',
        cursor: 'pointer',
    },
}))

const DashboardMenu = (props) => {
    const classes = useStyles()

    const ref = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen)

    const subMenuLabel = (menuLabel) => {
        return <Typography style={{ fontSize: '20px' }}>{menuLabel}</Typography>
    }

    return (
        <React.Fragment>
            <div
                className={classes.hoverButtonStyle}
                // ref={ref} {...anchorProps}
                onClick={() => history.push('/dashboard/')}
                style={{ textDecorationLine: props.underline ? 'underline' : 'none', textUnderlineOffset: '10px' }}>
                Dashboard
            </div>

            {/*<ControlledMenu*/}
            {/*  {...hoverProps}*/}
            {/*  state={isOpen ? 'open' : 'closed'}*/}
            {/*  anchorRef={ref}*/}
            {/*  onClose={() => setOpen(false)}*/}
            {/*>*/}
            {/*  <MenuItem onClick={() => history.push("/dashboard/")} className={classes.singleElementStyle}>Dashboard</MenuItem>*/}
            {/*  */}
            {/*  /!*<SubMenu style={{fontSize: '20px'}} label={subMenuLabel("Classrooms")}>*!/*/}
            {/*  /!*  {*!/*/}
            {/*  /!*    props.userGroups?.map(userGroup => {*!/*/}
            {/*  /!*      return <MenuItem key={userGroup.id} onClick={() => history.push("/dashboard/classroom/" + userGroup.id)} style={{fontSize: '20px'}}>{userGroup.name}</MenuItem>*!/*/}
            {/*  /!*    })*!/*/}
            {/*  /!*  }*!/*/}
            {/*  /!*  *!/*/}
            {/*  /!*</SubMenu>*!/*/}
            {/*</ControlledMenu>*/}
        </React.Fragment>
    )
}
export default DashboardMenu
