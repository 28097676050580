import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { Button, Checkbox } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    cardContent: {
        flex: 'auto',
    },
}))

const NodeView = (props) => {
    const handleToggleAddNode = () => {
        props.onToggleAddNode(props.node, 'nodeId')
    }
    const classes = useStyles()
    return (
        <Button onClick={handleToggleAddNode} style={{ textTransform: 'none', color: 'black', width: '100%' }}>
            <Grid container style={{ marginLeft: '3px', borderBottom: '1px solid #D1D1D6' }}>
                <Grid
                    item
                    xs={1}
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingTop: '8px',
                        paddingBottom: '12px',
                        paddingLeft: '5px',
                        alignItems: 'center',
                        width: '20px',
                        flex: 'none',
                        order: 0,
                        flexGrow: 0,
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '3px',
                            height: '100%',
                            backgroundColor: props.color,
                        }}></div>
                </Grid>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    padding: '0px',
                                    fontFamily: 'Lato',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '20px',
                                    lineHeight: '18px',
                                    paddingTop: '14px',
                                    marginBottom: '5px',
                                }}>
                                {props.title}
                            </Typography>
                        </Grid>
                        <Grid container style={{ marginBottom: '3px' }}>
                            {props.l2Array?.map((l2Text, index) => {
                                return (
                                    <Grid item key={index}>
                                        <div
                                            style={{
                                                minHeight: '16px',
                                                fontFamily: 'Lato',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                lineHeight: '16px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                letterSpacing: '0.5px',
                                                background: '#F8F8F8',
                                                borderRadius: '5px',
                                                color: '#000000',
                                                padding: '6px',
                                                marginRight: '7px',
                                                marginBottom: '7px',
                                            }}>
                                            {l2Text.text}
                                        </div>
                                    </Grid>
                                )
                            })}
                            {props.showInteraction === true && (
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            fontFamily: 'Lato',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            display: 'flex',
                                            alignItems: 'top',
                                            letterSpacing: '0.5px',
                                            color: '#616167',
                                            paddingBottom: '8px',
                                            marginLeft: '3px',
                                        }}>
                                        {props.interaction}
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Checkbox
                checked={props.isAdded}
                onChange={handleToggleAddNode}
                sx={{
                    visibility: props.isHover || props.isAdded ? 'visible' : 'hidden',
                    visible: false,
                    hide: true,
                }}
                checkedIcon={
                    <span
                        style={{
                            height: '25px',
                            width: '25px',
                            backgroundColor: '#003C58',
                            color: 'white',
                            borderRadius: '50%',
                            display: 'inlineBlock',
                        }}>
                        {props.selectionPosition}
                    </span>
                }
            />
        </Button>
    )
}

function mapStateToProps(state) {
    const {} = state
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeView)
