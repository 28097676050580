import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import makeStyles from '@material-ui/core/styles/makeStyles'
import { Grid, lighten } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import StudentInformation from './StudentInformation'
import { userActions } from '../../../_state/actions'
import StudentCourseProgression from './StudentCourseProgression'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import DialogContent from '@mui/material/DialogContent'

import CourseViewTopicList from '../../../Components/CourseView/CourseViewTopicList'

const useStyles = makeStyles((theme) => ({
    nameField: {
        width: 'auto',
    },
    languageForm: {
        margin: '25px 0px',
        width: '200px',
    },
    nameInput: {
        fontSize: '4rem',
        fontWeight: '200',
        color: theme.palette.primary.main,
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        margin: theme.spacing(1),
    },
    topBar: {
        display: 'inline-flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    flag: {},
    wordCloudContent: {
        padding: '30px 15px',
    },
    wordCloud: {
        textAlign: 'center',
    },
    card: {
        height: '185px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#0000003d',
        boxShadow: 'none',
    },
    content: {
        backgroundColor: 'primasarysd',
        alignContent: 'space-evenly',
        height: '-webkit-fill-available',
        display: 'grid',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    courses: {
        display: 'inline-flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootPanel: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '20%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    tertiaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    progress: {
        width: '200px',
    },
    inline: {
        display: 'inline',
    },
    progressBarParent: {
        position: 'relative',
        width: '40px',
        height: '40px',
    },
    progressBarBackground: {
        position: 'absolute',
        zIndex: 1,
        color: lighten(theme.palette.primary.main, 0.5),
    },
    progressBar: {
        position: 'absolute',
        color: theme.palette.primary.main,
        animationDuration: '550ms',
        zIndex: 1,
    },
    dateRangeHotKey: {
        cursor: 'pointer',
        color: '#888888',
        display: 'inline',
        width: 'fit-content',
        userSelect: 'none',
        '&:hover': {
            color: '#333333',
        },
    },
    avatar: {
        backgroundColor: '#dfdfdf',
        color: '#262626',
        width: '100%',
        height: '100%',
    },
    avatarProgress: {
        width: '80px',
        height: '80px',
        margin: '5px auto',
        position: 'relative',
        overflow: 'visible',
    },
}))

const StudentOverview = (props) => {
    const classes = useStyles()
    const { computedMatch } = props

    const userId = computedMatch.params.userId
    const classroomId = computedMatch.params.classroomId

    const [userLoaded, setUserLoaded] = useState(false)

    const [openCourseGroupId, setOpenCourseGroupId] = useState(null)
    const [openCourseGroup, setOpenCourseGroup] = useState(null)
    const [openCourseGroupProgression, setOpenCourseGroupProgression] = useState(null)
    const [openCourseGroupModal, setOpenCoursGroupModal] = useState(false)

    useEffect(() => {
        props.dispatch(userActions.getUserStats(props.organization.selectedOrganization, userId))
        if (props.user?.userStats?.userId === userId) {
            setUserLoaded(true)
        }
    }, [])

    useEffect(() => {
        if (props.user?.userStats?.userId === userId) {
            setUserLoaded(true)
        }
    }, [props.user.userStats])

    const handleCourseGroupOpen = (courseGroupId) => {
        setOpenCourseGroupId(courseGroupId)

        if (props.user?.userIdToCourseGroupMapper && userId in props.user?.userIdToCourseGroupMapper) {
            const courseGroupToShow = props.user.userIdToCourseGroupMapper[userId].find((x) => x.id === courseGroupId)
            setOpenCourseGroup(courseGroupToShow)

            if (
                userId in props.user.userIdToCourseGroupProgressionMapper &&
                courseGroupToShow.id in props.user.userIdToCourseGroupProgressionMapper[userId]
            ) {
                const courseGroupProgressionToShow =
                    props.user.userIdToCourseGroupProgressionMapper[userId][courseGroupToShow.id]
                setOpenCourseGroupProgression(courseGroupProgressionToShow)
            }
        }
        setOpenCoursGroupModal(true)
    }

    const handleCloseCourseGroupModal = () => {
        setOpenCourseGroupId(null)
        setOpenCoursGroupModal(false)
        setOpenCourseGroupProgression(null)
        setOpenCourseGroup(null)
    }

    return (
        <div>
            {openCourseGroupModal && (
                <Dialog maxWidth={'md'} open={openCourseGroupModal} onClose={() => handleCloseCourseGroupModal()}>
                    <div style={{ width: '856px', padding: '16px' }}>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row-reverse',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <IconButton
                                onClick={() => handleCloseCourseGroupModal()}
                                style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <CancelOutlinedIcon style={{ fill: 'black', width: 24, height: 24 }} size="medium" />
                            </IconButton>
                        </div>
                        <DialogTitle className={classes.dialogTitle}>
                            <div className={classes.dialogTitleDiv}>
                                <Typography
                                    variant="h1"
                                    style={{
                                        boxShadow: 'none',
                                        flex: 1,
                                        fontSize: 24,
                                        color: '#35323C',
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        fontFamily: 'Rubik',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        marginBottom: 6,
                                    }}>
                                    {openCourseGroup?.name}
                                </Typography>
                                <div>
                                    <Typography
                                        variant="h1"
                                        style={{
                                            boxShadow: 'none',
                                            flex: 1,
                                            fontSize: 14,
                                            color: '#6E6C71',
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            fontFamily: 'Rubik',
                                            fontStyle: 'normal',
                                            fontWeight: 300,
                                        }}>
                                        {openCourseGroup?.description}
                                    </Typography>
                                </div>
                            </div>
                        </DialogTitle>
                        <div>
                            <DialogContent>
                                <CourseViewTopicList
                                    courseGroup={openCourseGroup}
                                    courseGroupProgression={openCourseGroupProgression}
                                    dispatch={props.dispatch}
                                    userId={userId}
                                    showPassedOnLessons={true}></CourseViewTopicList>
                            </DialogContent>
                        </div>
                    </div>
                </Dialog>
            )}
            {!userLoaded && (
                <div>
                    <CircularProgress></CircularProgress>
                    Loading user...
                </div>
            )}

            {userLoaded && (
                <Grid container>
                    <Grid item xs={12} style={{ marginLeft: '40px', marginTop: '20px' }}>
                        <StudentInformation
                            userId={userId}
                            classroomId={classroomId}
                            dispatch={props.dispatch}></StudentInformation>
                    </Grid>

                    <Grid item xs={8} style={{ marginLeft: '40px', marginTop: '20px' }}>
                        <StudentCourseProgression
                            userId={userId}
                            onCourseGroupOpen={handleCourseGroupOpen}
                            organizationId={props.organization.selectedOrganization}
                            dispatch={props.dispatch}></StudentCourseProgression>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

StudentOverview.propTypes = {}

const mapToProps = (state) => {
    return {
        organization: state.organization,
        user: state.user,
        metadata: state.metadata,
        usergroup: state.usergroup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapToProps, mapDispatchToProps)(StudentOverview)
